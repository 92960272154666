import React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { TabPage } from '../../components/tabs';
import { Insured, InsuredCreate } from '../../views';
import { PageHeader, PageTitle, PageBody } from '../../components/page-components';
import { Button } from '@oforce/global-components';
import { useActiveAccount } from '../../shared/activeAccount';
import { HideFromRolesAndTypes, RouteHiddenFromRolesAndTypes } from '../../shared/activeAccount/components';
import { InsuredsTable } from './components';

export default function Insureds() {
  const { path } = useRouteMatch();
  const history = useHistory();
  const {
    ROLES: { OBSERVER },
    TYPES: { CLAIMS_ADJUSTER }
  } = useActiveAccount();

  return (
    <Switch>
      <Redirect from="/insureds/overview" to="/insureds" />
      <Route exact path={`${path}`}>
        <Helmet>
          <title>Insureds • IC Insure</title>
        </Helmet>
        <PageHeader>
          <PageTitle>Insureds</PageTitle>
        </PageHeader>
        <PageBody>
          <TabPage
            title=""
            buttons={
              <HideFromRolesAndTypes roles={[OBSERVER]} types={[CLAIMS_ADJUSTER]}>
                <Button
                  color="info"
                  icon={['far', 'plus']}
                  className="ml-2"
                  onClick={() => history.push('/insureds/new')}
                >
                  Create
                </Button>
              </HideFromRolesAndTypes>
            }
          >
            <InsuredsTable />
          </TabPage>
        </PageBody>
      </Route>

      <RouteHiddenFromRolesAndTypes types={[CLAIMS_ADJUSTER]} roles={[OBSERVER]} path={`${path}/new`}>
        <Helmet>
          <title>Create Insured • IC Insure</title>
        </Helmet>
        <PageHeader>
          <PageTitle>Insureds</PageTitle>
        </PageHeader>
        <PageBody>
          <InsuredCreate />
        </PageBody>
      </RouteHiddenFromRolesAndTypes>

      <Route path={`${path}/:insuredId`}>
        <Insured />
      </Route>
    </Switch>
  );
}
