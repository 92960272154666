import React from 'react';
import { debounce } from 'throttle-debounce';
import { EXPOSURE_ELECTION_STATUSES } from '../../../views/exposureElections/constants';
import { FilterDropdown, FilterLabel, FiltersContainer } from '../../../components';
import { CoveragesSearchBy } from '../../coverages/components';

const { ACTIVE, PENDING_TERMINATION, TERMINATED } = EXPOSURE_ELECTION_STATUSES;

export const orderOptions = [
  { label: 'First Name', value: 'FIRST_NAME' },
  { label: 'Last Name', value: 'LAST_NAME' },
  { label: 'Insured Created', value: 'NEWEST' },
  { label: 'Election Created', value: 'NEWEST_ELECTION' }
];

export default function PolicyInsuredsTableFilters({ onChange, filterValue, policyId }) {
  const setOrdering = debounce(500, false, value => onChange({ ordering: !value ? null : value }));
  const setElectionStatus = value => onChange({ electionStatus: !value ? null : value });
  const setInComplianceFilter = inCompliance => onChange({ inCompliance });

  const statusOptions = [
    { label: 'All', value: null },
    { label: 'Active', value: ACTIVE },
    { label: 'Pending Termination', value: PENDING_TERMINATION },
    { label: 'Terminated', value: TERMINATED }
  ];

  return (
    <>
      <FiltersContainer>
        <FilterLabel>Sort by</FilterLabel>
        <FilterDropdown
          id="ordering"
          className="mr-3"
          headerText="Sort ascending..."
          icon={['far', 'sort-alpha-down']}
          options={orderOptions}
          defaultOption={orderOptions.find(({ value }) => value === filterValue?.ordering)}
          setFilter={option => setOrdering(option.value)}
          isClearable
        />
        <FilterLabel>Status</FilterLabel>
        <FilterDropdown
          id="election-status"
          headerText="Status..."
          options={statusOptions}
          defaultOption={statusOptions.find(({ value }) => value === filterValue?.electionStatus)}
          setFilter={option => {
            if (option?.label === 'All') {
              setElectionStatus(null);
              setInComplianceFilter(null);
            } else {
              setElectionStatus(option.value);
              setInComplianceFilter(true);
            }
          }}
          isClearable
        />
        <CoveragesSearchBy onChange={onChange} policyId={policyId} />
      </FiltersContainer>
    </>
  );
}
