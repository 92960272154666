import React from 'react';
import { Alert } from 'reactstrap';

import { FormSection } from '../../../../components';

export default function SubmitStep() {
  return (
    <FormSection title="Ready to Submit">
      <Alert color="success">
        We've got everything we need to submit your coverages!
        <br />
        Click Submit to continue!
      </Alert>
    </FormSection>
  );
}
