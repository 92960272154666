import { Modal, ReactSelectField } from '@oforce/global-components';
import { Formik } from 'formik';
import { GraphQLErrors } from '../../../components';
import { useDocumentUpload } from '../hooks';
import DocumentFields from './DocumentFields';
import * as Document from '../model';
import { useState } from 'react';

export default function UploadDocumentsModal({ show, toggle, election, electionOptions = [] }) {
  const [selectedElectionId, setSelectedElectionId] = useState(
    electionOptions?.length === 0 ? election?.id : electionOptions[0]?.value
  );

  const { uploadFile, uploading, error } = useDocumentUpload({
    electionId: selectedElectionId,
    onUploadStarted: () => {},
    onUploadCompleted: toggle
  });

  const handleSubmit = async ({ document, name, type: { value } }, actions) => {
    await uploadFile(document[0], { name, type: value });
    actions.resetForm();
  };

  return (
    <Formik
      validationSchema={Document.validationSchema()}
      initialValues={Document.initialValues()}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {formik => (
        <Modal
          isOpen={show}
          toggle={toggle}
          title="Upload Document"
          submitButtonText="Upload Document"
          onSubmit={formik.handleSubmit}
          loading={uploading}
          submitButtonProps={{ loadingText: 'Uploading...' }}
        >
          {!election && (
            <ReactSelectField
              required
              label="Choose Exposure Election"
              name="election"
              options={electionOptions}
              placeholder="Select..."
              value={electionOptions?.find(election => election?.value === selectedElectionId)}
              onChange={electionOption => setSelectedElectionId(electionOption?.value)}
            />
          )}

          <GraphQLErrors error={error} />
          <DocumentFields uploading={uploading} formik={formik} />
        </Modal>
      )}
    </Formik>
  );
}
