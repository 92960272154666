import React, { useState } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Alert, FormGroup } from 'reactstrap';
import {
  FieldLabel,
  ReactAsyncSelectField,
  ReactSelectField,
  Render,
  Badge,
  DatePickerField,
  Button,
  FieldMessage
} from '@oforce/global-components';
import { AddOnSelectFormik } from '../../../../addOns/components';
import useCoveragesFormOptions from './useCoveragesFormOptions';
import { ShowForRoles, useActiveAccount } from '../../../../../shared/activeAccount';
import moment from 'moment';
import { FormSection } from '../../../../../components';
import { backDateMessage, useRenewedFromPolicy } from '../../../../exposureElections/hooks';
import { Link } from 'react-router-dom/cjs/react-router-dom';

function castPolicy(policy) {
  return {
    value: policy?.id,
    label: policy?.name,
    ...policy
  };
}

export default function CoverageForm({
  onSubmit,
  coverage,
  onCancel,
  insured,
  vehicle,
  policyInsuranceTypes,
  selectedPolicyIds = [],
  policy: incomingPolicy
}) {
  const hasIncomingPolicy = !!incomingPolicy;
  const initialPolicy = incomingPolicy ? castPolicy(incomingPolicy) : coverage?.policy
  const [policy, setPolicy] = useState(initialPolicy);
  const [pricingOption, setPricingOption] = useState(coverage?.pricingOption);
  const [client, setClient] = useState(coverage?.client);
  const [effectiveOnDate, setEffectiveOnDate] = useState(moment?.utc()?.format(moment.HTML5_FMT.DATE));
  const [selectedIds, setSelectedIds] = useState(selectedPolicyIds);

  const policyId = policy?.id;
  const {
    ROLES: { ADMIN, COLLABORATOR, OWNER }
  } = useActiveAccount();

  const validationSchema = yup.object().shape({
    client: yup.object().nullable(),
    policy: yup.object().nullable().required('Required'),
    pricingOption: yup.object().nullable().required('Required'),
    addOns: yup
      .array()
      .nullable()
      .of(yup.object().shape({ label: yup.string(), value: yup.string() })),
    startDate: yup.string()
  });

  const initialValues = {
    policy: policy || null,
    client: client || null,
    pricingOption: coverage?.pricingOption || null,
    addOns: coverage?.addOns || [],
    requiredAddOns: coverage?.requiredAddons || [],
    startDate: coverage?.startDate || moment().format('YYYY-MM-DD')
  };

  const { formOptions } = useCoveragesFormOptions({
    policy,
    pricingOption,
    selectedPolicyIds: selectedIds,
    insured,
    vehicle,
    policyInsuranceTypes,
    client,
    effectiveOnDate
  });

  const { renewedFromPolicy, loading: renewLoading, currentPolicyEffectiveDate } = useRenewedFromPolicy({ policy });

  const setFormPolicyPricingOption = (formik, selectedOption) => {
    formik?.setFieldValue('addOns', []);
    formik?.setFieldValue('pricingOption', selectedOption);
    formik?.validateField('pricingOption');
    setPricingOption(selectedOption);
  };

  const setFormPolicy = (formik, selectedPolicy) => {
    formik?.setFieldValue('policy', selectedPolicy);
    formik?.validateField('policy');
    setFormPolicyPricingOption(formik, null);
    setSelectedIds(selectedIds.filter(id => id !== policy?.id));
    setPolicy(selectedPolicy);
  };

  const setFormClient = (formik, selectedClient) => {
    setClient(selectedClient);
    formik?.setFieldValue('client', selectedClient);
    setFormPolicy(formik, null);
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnChange={true}
    >
      {formik => (
        <FormSection title="Select Coverage">
        <div>
          <DatePickerField
            id="startDate"
            name="startDate"
            label="Start Date"
            inputReadOnly={true}
            format="MM/DD/YYYY"
            className="w-100"
            allowClear
            disabledDate={current =>
              currentPolicyEffectiveDate && current && current < moment(currentPolicyEffectiveDate)
            }
            messageAfterProps={{ icon: ['far', 'info-circle'] }}
            onChange={e =>
              !!e ? setEffectiveOnDate(moment(e).format(moment.HTML5_FMT.DATE)) : setEffectiveOnDate(null)
            }
          />
          { policy?.value &&
             <Alert color="info">
             {backDateMessage(policy?.startDateBackdatingMinimum, policy?.startDateBackdatingLimit)}
           </Alert>
          }
          
        </div>
          <div>
            <ReactAsyncSelectField
              id="client"
              name="client"
              label="Client"
              placeholder="Choose or search for client..."
              cacheOptions
              loadOptions={formOptions?.client?.loadOptions}
              defaultOptions={formOptions?.client?.defaultOptions}
              defaultValue={null}
              loading={formOptions?.client?.loading}
              onChange={option => setFormClient(formik, option)}
              onClear={() => setFormClient(formik, null)}
              isClearable
            />
          </div>
          <ReactAsyncSelectField
            id="policy"
            name="policy"
            label="Policy"
            placeholder="Choose or search for policy..."
            cacheOptions
            loadOptions={formOptions?.policy?.loadOptions}
            defaultOptions={formOptions?.policy?.defaultOptions}
            defaultValue={policy || formOptions?.policy?.defaultOptions[0]}
            loading={formOptions?.policy?.loading}
            disabled={hasIncomingPolicy}
            onChange={option => setFormPolicy(formik, option)}
            required
            isClearable
          />

          <ReactSelectField
            id="pricingOption"
            name="pricingOption"
            label="Coverage Option"
            options={formOptions?.policyPricing?.options}
            isLoading={formOptions?.policyPricing?.loading}
            isDisabled={!policyId}
            onChange={option => setFormPolicyPricingOption(formik, option)}
            required
            placeholder="Select a coverage option..."
          />

          <Render if={formOptions?.requiredAddOns?.options?.length > 0}>
            <FormGroup className="mb-4">
              <FieldLabel>Required Add-On(s)</FieldLabel>
              {formOptions?.requiredAddOns?.options?.map(({ id, label }) => (
                <Badge key={id} size="sm" className="mr-2" color="secondary">
                  {label}
                </Badge>
              ))}
            </FormGroup>
          </Render>
          <AddOnSelectFormik pricingOptionId={pricingOption?.id} disabled={!policyId} />

          <Render if={!renewLoading && policy?.enableCustomStartDates === false}>
            <ShowForRoles roles={[ADMIN, COLLABORATOR, OWNER]}>
              <StartDateInput
                policy={policy}
                renewedFromPolicy={renewedFromPolicy}
                currentPolicyEffectiveDate={currentPolicyEffectiveDate}
              />
            </ShowForRoles>
          </Render>
          <Render if={!renewLoading && policy?.enableCustomStartDates === true}>
            <StartDateInput
              policy={policy}
              renewedFromPolicy={renewedFromPolicy}
              currentPolicyEffectiveDate={currentPolicyEffectiveDate}
            />
          </Render>
          <div className="d-flex justify-content-between">
            <Button color="danger" type="button" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              color="success"
              type="submit"
              onClick={event => {
                formik?.setFieldValue('requiredAddOns', formOptions?.requiredAddOns?.options);
                formik.handleSubmit(event);
              }}
              disabled={!(formik.isValid && formik.dirty)}
            >
              Save
            </Button>
          </div>
        </FormSection>
      )}
    </Formik>
  );
}

const StartDateInput = ({ policy, renewedFromPolicy = {}, currentPolicyEffectiveDate }) => {
  const { backdate, id: renewedFromPolicyId, name: renewedFromPolicyName } = renewedFromPolicy;

  return (
    <>
      <Render if={!!backdate && !!renewedFromPolicyId}>
        <FieldMessage className="mb-2" icon={['far', 'info-circle']}>
          Backdating before the policy's effective date, requires changes in the previous policy:{' '}
          <Link to={`/policies/${renewedFromPolicyId}/insureds`}>{renewedFromPolicyName}</Link>
        </FieldMessage>
      </Render>
    </>
  );
};
