import React, { useState, useEffect } from 'react';
import {
  DatePickerField,
  InputDropzoneField,
  InputField,
  ReactSelectField,
  Render,
  SwitchField
} from '@oforce/global-components';
import { FormSection } from '../../../components';
import { defaultDocumentName } from '../utils';
import { useActiveAccount } from '../../../shared/activeAccount';

export default function ProductDocumentDetailsFields({ formik, formOptions, isCreateMode }) {
  const { isAdmin } = useActiveAccount();
  const [documentName, setDocumentName] = useState(null);

  useEffect(() => {
    if (documentName) {
      return;
    }

    if (formik?.values?.document?.length === 1 && !formik?.values?.name) {
      const name = defaultDocumentName(formik?.values?.document[0]?.name);
      setDocumentName(name);
      formik.setFieldValue('name', name);
    }
  }, [formik, documentName, setDocumentName]);

  return (
    <FormSection title="Document Details">
      <Render if={isCreateMode}>
        <InputDropzoneField required accept={['.pdf']} id="document" label="Document (pdf)" name="document" />
      </Render>
      <InputField required label="Name" name="name" placeholder="Enter a document name" />
      <ReactSelectField
        required
        label="Type"
        name="type"
        options={formOptions?.documentType.options}
        placeholder="Select..."
      />
      <DatePickerField
        required
        fullWidth
        name="effectiveDate"
        label="Effective Date"
        format="MM/DD/YYYY"
        allowClear={false}
        inputReadOnly={true}
        messageAfterProps={{ icon: ['far', 'info-circle'] }}
      />
      <DatePickerField fullWidth name="expirationDate" label="Expiration Date" format="MM/DD/YYYY" allowClear={true} />
      <Render if={isAdmin}>
        <SwitchField
          id="populateOnPending"
          className="mb-3"
          label="Populate on Pending"
          name="populateOnPending"
          size="sm"
          switchLabel={formik?.values?.populateOnPending ? 'Enabled' : 'Disabled'}
        />
      </Render>
    </FormSection>
  );
}
