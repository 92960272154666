import { useLazyQuery } from '@apollo/client';
import { gql } from '@apollo/client';

export const GET_SUBCONTRACT_INFO_MESSAGE = gql`
  query GetSubcontractInfoMessage(
    $masterExposureElectionId: ID!
    $subExposureElectionId: ID!
    $action: InfoMessageAction!
  ) {
    getSubcontractInfoMessage(
      masterExposureElectionId: $masterExposureElectionId
      subExposureElectionId: $subExposureElectionId
      action: $action
    )
  }
`;

export default function useGetInfoMessage({ onCompleted, action }) {
  const [load, { data, loading, error }] = useLazyQuery(GET_SUBCONTRACT_INFO_MESSAGE, {
    onCompleted: ({ getSubcontractInfoMessage: message }) => {
      onCompleted && onCompleted(message);
    }
  });

  const getMessage = ({ masterExposureElectionId, subExposureElectionId }) => {
    if (masterExposureElectionId && subExposureElectionId) {
      load({ variables: { masterExposureElectionId, subExposureElectionId, action } });
    }
  };

  return {
    getMessage,
    infoMessage: data?.getSubcontractInfoMessage,
    loading,
    error
  };
}
