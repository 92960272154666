import React from 'react';
import { MultiStepForm } from '../../../components';
import { useGenerateCombinedInvoicesXslJobContext } from '../../../context';
import { Steps } from 'react-step-builder';
import { InvoicesSelection } from '../components';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function CombinedInvoicesReport() {
  const history = useHistory();
  const { startGenerateCombinedInvoicesXslJob, loading, error } = useGenerateCombinedInvoicesXslJobContext();
  const formSteps = [{ name: 'Select Invoices', initialValues: { invoiceIds: [] } }];

  return (
    <MultiStepForm
      formSteps={formSteps}
      formSubmit={({ invoiceIds }) => {
        startGenerateCombinedInvoicesXslJob({ invoiceIds });
      }}
      maxWidth={'800px'}
      title={`Combined Invoices Report`}
      onCancel={() => history.push(`/invoices`)}
      submitButtonText={'Generate Combined Invoices Report'}
      submitButtonProps={{ loading, loadingText: 'Generating Combined Invoices Report...' }}
      showButtonsOnBottom
      error={error}
    >
      {formProps => (
        <Steps>
          <InvoicesSelection {...formProps} />
        </Steps>
      )}
    </MultiStepForm>
  );
}
