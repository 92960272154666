import moment from 'moment';
import { COUNTRY_OPTIONS, US_VERSIONS, CANADA_VERSIONS, CANADA, USA } from '../hooks/forms/useCountries';
import { US_STATES, US_TERRITORIES } from '../hooks/forms/useUSStates';
import { uuid } from './regex';

export const validateVin = yupSchema => {
  return yupSchema.test('len', 'Must be exactly 17 characters', val => {
    if (val === undefined) {
      return true;
    }
    return val.length === 0 || val.length === 17;
  });
};

export const validateBoolean = yupSchema => {
  return yupSchema
    .transform(value => transformBoolean(value))
    .test({
      name: uuid(),
      message: `Invalid Boolean`,
      test: value => isValidBoolean(value)
    });
};

export const validateVehicleType = (yupSchema, options) => {
  return yupSchema
    .transform(value => transformVehicleType(value, options))
    .test({
      name: uuid(),
      message: `Invalid Vehicle Type`,
      test: value => isValidVehicleType(value, options)
    });
};

export const validateWeightClass = (yupSchema, options) => {
  return yupSchema
    .transform(value => transformWeightClass(value, options))
    .test({
      name: uuid(),
      message: `Invalid Weight Class`,
      test: value => isValidWeightClass(value, options)
    });
};

export const validateCommercialAutoCoverageType = (yupSchema, options) => {
  return yupSchema
    .transform(value => transformCommercialAutoCoverageType(value, options))
    .test({
      name: uuid(),
      message: `Invalid Commercial Auto Coverage Type`,
      test: value => isValidCommercialAutoCoverageType(value, options)
    });
};

export const validateDate = yupSchema => {
  return yupSchema
    .transform(value => transformDate(value))
    .test({
      name: uuid(),
      message: `Invalid Date`,
      test: value => isValidDate(value)
    });
};

export const validateCountry = yupSchema => {
  return yupSchema
    .transform(value => transformCountry(value))
    .test({
      name: uuid(),
      message: `Invalid Country`,
      test: value => isValidCountry(value)
    });
};

const upperCase = value => {
  if (!value) return value;
  if (typeof value === 'string') return value.toUpperCase();
  return value;
};

export const validateState = yupSchema => {
  return yupSchema
    .transform(value => transformState(value))
    .test({
      name: uuid(),
      message: `Invalid State`,
      test: value => isValidState(value)
    });
};

const findOption = (options, incomingValue) => {
  const findMe = incomingValue && upperCase(incomingValue);
  if (!incomingValue) return null;
  return options.find(({ value, label }) => upperCase(value) === findMe || upperCase(label) === findMe);
};

export const transformVehicleType = (option, options) => {
  if (!option) return null;
  return findOption(options, option?.value);
};

const isValidVehicleType = (value, options) => {
  const valueC = transformCommercialAutoCoverageType(value, options);
  if (!value) return true;
  return !!valueC;
};

export const transformWeightClass = (option, options) => {
  if (!option) return null;
  return findOption(options, option?.value);
};

const isValidWeightClass = (value, options) => {
  const valueC = transformCommercialAutoCoverageType(value, options);
  if (!value) return true;
  return !!valueC;
};

export const transformCommercialAutoCoverageType = (option, options) => {
  if (!option) return null;
  return findOption(options, option?.value);
};

const isValidCommercialAutoCoverageType = (value, options) => {
  const valueC = transformCommercialAutoCoverageType(value, options);
  if (!value) return true;
  return !!valueC;
};

export const transformDate = value => {
  const dob1 = value && moment(value, 'MM/DD/YYYY', true).format('YYYY-MM-DD');
  const dob2 = value && moment(value, 'MM-DD-YYYY', true).format('YYYY-MM-DD');
  const dob3 = value && moment(value, 'YYYY-MM-DD', true).format('YYYY-MM-DD');
  const dob4 = value && moment(value, 'YYYY/MM/DD', true).format('YYYY-MM-DD');

  if (!value) return '';
  if (value && dob1 !== 'Invalid date') return dob1;
  if (value && dob2 !== 'Invalid date') return dob2;
  if (value && dob3 !== 'Invalid date') return dob3;
  if (value && dob4 !== 'Invalid date') return dob4;

  return '';
};

const isValidDate = value => {
  const valueC = transformDate(value);
  if (!value) return true;
  return !!valueC;
};

const stateOptions = [...US_STATES, ...US_TERRITORIES];
const stateAbbreviations = stateOptions.map(({ value }) => upperCase(value));
const stateLabels = stateOptions.map(({ label }) => upperCase(label));

export const transformState = option => {
  const valueC = option && option.value && upperCase(option.value);

  if (stateAbbreviations.includes(valueC)) return stateOptions.find(({ value }) => value === valueC);
  if (stateLabels.includes(valueC)) return stateOptions.find(({ label }) => label === valueC);
  return null;
};

const isValidState = value => {
  const valueC = transformState(value);
  if (!value) return true;
  return !!valueC;
};

export const transformCountry = option => {
  const valueC = option && option.value && upperCase(option.value);

  if (US_VERSIONS.includes(valueC)) return COUNTRY_OPTIONS.find(({ value }) => value === USA);
  if (CANADA_VERSIONS.includes(valueC)) return COUNTRY_OPTIONS.find(({ value }) => value === CANADA);
  return null;
};

export const transformCountryValue = value => {
  const valueC = upperCase(value);
  if (US_VERSIONS.includes(valueC)) return USA;
  if (CANADA_VERSIONS.includes(valueC)) return CANADA;
  return null;
};

const isValidCountry = value => {
  const valueC = transformCountry(value);

  if (!value) return true;
  return !!valueC;
};

export const transformBoolean = value => {
  const valueC = value && upperCase(value);

  if (!value) return false;
  if (['TRUE', '1', 'YES'].includes(valueC)) return true;
  if (['FALSE', '0', 'NO'].includes(valueC)) return false;
  return null;
};

const isValidBoolean = value => {
  const valueC = value && upperCase(value);

  if (!value) return true;
  if (['TRUE', '1', 'YES'].includes(valueC)) return true;
  if (['FALSE', '0', 'NO'].includes(valueC)) return true;
  return false;
};
