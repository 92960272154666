import { gql } from '@apollo/client';

export const VEHICLE_CORE_FIELDS = gql`
  fragment VehicleCoreFields on Vehicle {
    id
    vehicleYear
    vehicleMake
    vehicleModel
    vin
    mileage
    value
    vehicleWeight
    weightClass {
      id
      name
    }
    vehicleType {
      id
      name
    }
    commercialAutoCoverageType {
      id
      name
    }
    fleetNumber
    unitNumber
    isTractor
    notes
    stateOfRegistration
    serialNumber
    garagedZip
    garagedState
    status
  }
`;
