import { gql, useQuery } from '@apollo/client';

export const COVERAGE_PARAMETERS_FIELDS = gql`
  fragment CoverageParametersFields on CoverageParameter {
    id
    label
    name
    type
    valueType
    validate
    isPredefined: predefined
  }
`;

export const GET_PRODUCT_COVERAGE_PARAMETERS = gql`
  query GetProductCoverageParameters($id: ID!) {
    product(id: $id) {
      id
      coverageParameters {
        ...CoverageParametersFields
      }
    }
  }
  ${COVERAGE_PARAMETERS_FIELDS}
`;

export const GET_POLICY_COVERAGE_PARAMETERS = gql`
  query GetPolicyCoverageParameters($id: ID!) {
    policy(id: $id) {
      id
      policyCoverageParameters {
        ...CoverageParametersFields
      }
    }
  }
  ${COVERAGE_PARAMETERS_FIELDS}
`;

export default function useGetCoverageParameters({ productId, policyId }) {
  const isProduct = !!productId;
  const isPolicy = !!policyId;

  const { data, loading, error } = useQuery(
    isProduct ? GET_PRODUCT_COVERAGE_PARAMETERS : GET_POLICY_COVERAGE_PARAMETERS,
    {
      variables: { id: isProduct ? productId : policyId }
    }
  );

  return { data, loading, error, isProduct, isPolicy };
}
