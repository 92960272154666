export default function useSyncPricingOptionsFormSteps({ allProductPricingOptionIds, allPolicyIds }) {
  const formSteps = [
    {
      name: 'Instructions',
      initialValues: {}
    },
    {
      name: 'Select Product Pricing Options',
      initialValues: { productPricingOptionIds: allProductPricingOptionIds }
    },
    {
      name: 'Select Policies',
      initialValues: { policyIds: allPolicyIds }
    }
  ];

  return { formSteps };
}
