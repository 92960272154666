import { gql, useQuery } from '@apollo/client';

export const PRODUCT_INSURANCE_TYPE_FIELDS = gql`
  fragment ProductInsuranceTypeFields on Product {
    insuranceType {
      id
      type
      name
      requireVehicle
      stateFees {
        id
      }
      premiumFactors {
        coverageParameters
        rateModes
      }
    }
  }
`;

const GET_PRODUCT_INSURANCE_TYPE = gql`
  query GetProductInsuranceType($id: ID!) {
    product(id: $id) {
      id
      ...ProductInsuranceTypeFields
    }
  }
  ${PRODUCT_INSURANCE_TYPE_FIELDS}
`;

export default function useInsuranceType({ productId }) {
  const { data, loading, error } = useQuery(GET_PRODUCT_INSURANCE_TYPE, {
    variables: { id: productId }
  });

  return { insuranceType: data?.product?.insuranceType, loading, error };
}
