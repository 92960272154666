import * as yup from 'yup';
import moment from 'moment';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { GET_EXPOSURE_ELECTION_SUBCONTRACTS } from './useCoverageSubcontracts';
import { LIST_EXPOSURE_ELECTIONS_FOR_CONTRACT } from './useExposureElectionsForContract';

export const ASSIGN_SUBCONTRACT_RELATIONSHIP = gql`
  mutation AssignSubcontractRelationship($masterId: ID!, $subId: ID!, $subStartDate: Date) {
    assignSubcontractRelationship(
      masterExposureElectionId: $masterId
      subExposureElectionId: $subId
      subStartDate: $subStartDate
    ) {
      id
    }
  }
`;

export const sendStartDate = (subcontractor, masterElection) => {
  const { startDate: masterStartDate } = masterElection;

  return (
    (subcontractor && subcontractor?.hasInvoiced) ||
    (subcontractor && moment(subcontractor?.startDate) < moment(masterStartDate))
  );
};

export default function useAssignSubcontractRelationship({ election: masterElection, toggle }) {
  const [assign, { loading, error, reset }] = useMutation(ASSIGN_SUBCONTRACT_RELATIONSHIP, {
    onCompleted: toggle,
    onError: () => {},
    refetchQueries: [
      { query: GET_EXPOSURE_ELECTION_SUBCONTRACTS, variables: { id: masterElection.id } },
      {
        query: LIST_EXPOSURE_ELECTIONS_FOR_CONTRACT,
        fetchPolicy: 'network-only',
        variables: { masterExposureElectionId: masterElection.id, first: 5, policyId: masterElection?.policyId }
      }
    ]
  });

  const handleSubmit = ({ subcontractor, startDate }) => {
    assign({
      variables: {
        masterId: masterElection?.id,
        subId: subcontractor.value,
        subStartDate: sendStartDate(subcontractor, masterElection) ? moment(startDate).format('YYYY-MM-DD') : null
      }
    });
  };

  const initialValues = {
    subcontractor: null,
    startDate: moment(masterElection?.startDate)
  };

  const validationSchema = yup.object({
    subcontractor: yup.object().nullable().required('Required'),
    startDate: yup.date()
  });

  return {
    initialValues,
    validationSchema,
    handleSubmit,
    loading,
    error,
    reset
  };
}
