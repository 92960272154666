import React, { useMemo } from 'react';
import { SearchBy } from '../../components';
import { usePendingElectionsSearchByOptions } from './hooks';

export default function PendingElectionSearchBy({ onChange }) {
  const { pendingElectionSearchByOptions, loading } = usePendingElectionsSearchByOptions();
  const defaultValue = useMemo(
    () => pendingElectionSearchByOptions?.find(option => option.value === 'LAST_NAME'),
    [pendingElectionSearchByOptions]
  );

  const onFilterChange = incomingFilters => {
    const searchBy = incomingFilters?.reduce((acc, { field: { value: searchField }, value: searchValue }) => {
      if (!!searchValue) return [...acc, { searchField, searchValue }];
      return acc;
    }, []);

    if (searchBy?.length > 0) {
      onChange({ searchBy });
    } else {
      onChange({ searchBy: [] });
    }
  };

  if (loading) return null;
  return (
    <SearchBy
      label={'Search by'}
      onFilterChange={onFilterChange}
      name="coverages-search-by"
      fieldOptions={pendingElectionSearchByOptions}
      defaultValue={defaultValue}
    />
  );
}
