import { useEffect } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import useCursorPagination from '../../../hooks/useCursorPagination';

export const GET_INVOICES = gql`
  query invoices(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $serviceStartDate: Date
    $serviceEndDate: Date
    $searchBy: [InvoiceSearchByInput] = []
    $ordering: ListInvoicesOrdering
  ) {
    invoices(
      first: $first
      last: $last
      after: $after
      before: $before
      serviceStartDate: $serviceStartDate
      serviceEndDate: $serviceEndDate
      searchBy: $searchBy
      ordering: $ordering
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          serviceStartDate
          serviceEndDate
          client {
            name
          }
          clientProduct {
            name
            insuranceType {
              name
            }
          }
        }
      }
    }
  }
`;

export default function useInvoices({
  numberToDisplay = 10,
  searchBy,
  serviceStartDate,
  serviceEndDate,
  first,
  last,
  after,
  before
}) {
  const [loadInvoices, { data, loading, error }] = useLazyQuery(GET_INVOICES);

  useEffect(() => {
    const variables = {
      first: numberToDisplay,
      ordering: 'SERVICE_START_DATE',
      searchBy,
      serviceStartDate,
      serviceEndDate,
      after: null,
      before: null,
      last: null
    };
    loadInvoices({ variables });
  }, [loadInvoices, numberToDisplay, searchBy, serviceStartDate, serviceEndDate]);

  const { loadNextPage, loadPreviousPage } = useCursorPagination(loadInvoices, {
    count: numberToDisplay
  });

  const pageInfo = data?.invoices?.pageInfo || {};
  const { hasNextPage, hasPreviousPage } = pageInfo;

  return {
    invoices: data?.invoices?.edges?.map(x => x.node),
    loading,
    error,
    hasNextPage,
    hasPreviousPage,
    fetchMore: loadInvoices,
    loadNextPage: () => {
      loadNextPage(pageInfo, {
        serviceStartDate,
        serviceEndDate,
        searchBy,
        first,
        last,
        after,
        before
      });
    },
    loadPreviousPage: () =>
      loadPreviousPage(pageInfo, {
        serviceStartDate,
        serviceEndDate,
        searchBy,
        first,
        last,
        after,
        before
      })
  };
}
