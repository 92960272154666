import debounce from 'debounce-promise';
import { useFormOptions } from '../../../hooks';
import { normalizeInsuredFullName } from '../../insured/utils';
import useExposureElectionsForContract from './useExposureElectionsForContract';

function castCoverageOptions(coverages) {
  return coverages?.map(coverage => {
    return {
      label: `${normalizeInsuredFullName(coverage?.insured)} (${coverage?.pricingOption?.label})`,
      startDate: coverage?.startDate,
      hasInvoiced: coverage?.hasInvoiced,
      value: coverage?.id
    };
  });
}

export default function useSubcontractedCoverageFormOptions({ election, policyId }) {
  const { coveragesForContract, loading, error, fetchMore } = useExposureElectionsForContract({
    numberToDisplay: 5,
    policyId,
    exposureElectionId: election?.id
  });
  const coverageOptions = castCoverageOptions(coveragesForContract);

  const formOptions = useFormOptions({
    options: [{ name: 'coverage', options: coverageOptions, loading, error }]
  });

  const getFilteredCoverages = async (searchby, setOptions) => {
    const coverages = await fetchMore({ fullName: searchby });
    const coverageOptions = castCoverageOptions(coverages);
    setOptions(coverageOptions);
  };

  const loadOptions = debounce(getFilteredCoverages);

  return {
    loading,
    formOptions,
    loadOptions
  };
}
