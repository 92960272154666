import React from 'react';
import { useHistory } from 'react-router-dom';
import { useToggle, Render } from '@oforce/global-components';
import { dateToClientSideFormat } from '../../../utils/dates';

import { backendNameToLabel } from '../../../utils/formatting';
import { Td, Tr, ActionDropdown } from '../../../components/table-2.0';
import { ArchivePolicy, DeletePolicy } from '../../policy/components';
import { useActiveAccount } from '../../../shared/activeAccount';
import Placeholder from '../../../components/PlaceholderText';

export default function PolicyRow({ policy }) {
  const [showConfirm, toggleConfirm] = useToggle(false);
  const [showConfirmDelete, toggleConfirmDelete] = useToggle(false);

  const { id, client, effectiveStatus, pricingRateType } = policy;
  const history = useHistory();
  const {
    roleIsAtLeast,
    ROLES: { EXPOSURE_MANAGER }
  } = useActiveAccount();

  return (
    <Tr onClick={() => history.push(`/policies/${id}`)}>
      <Td>
        <span className="font-weight-500">{policy?.name}</span>
      </Td>
      <Td>{client?.name}</Td>
      <Td>{policy?.externalId}</Td>
      <Td>{backendNameToLabel(effectiveStatus)}</Td>
      <Td>
        <PolicyTerm policy={policy} />
      </Td>
      <Td>{pricingRateType}</Td>
      <Render if={roleIsAtLeast(EXPOSURE_MANAGER)}>
        <Td align="right">
          <>
            <Render if={policy?.archived}>
              <ActionDropdown
                onDelete={e => {
                  e.stopPropagation();
                  toggleConfirmDelete();
                }}
              />
            </Render>
            <Render if={!policy?.archived}>
              <ActionDropdown
                onArchive={e => {
                  e.stopPropagation();
                  toggleConfirm();
                }}
                onDelete={e => {
                  e.stopPropagation();
                  toggleConfirmDelete();
                }}
              />
            </Render>
            <ArchivePolicy policy={policy} showConfirm={showConfirm} toggleConfirm={toggleConfirm} />
            <DeletePolicy policy={policy} showConfirm={showConfirmDelete} toggleConfirm={toggleConfirmDelete} />
          </>
        </Td>
      </Render>
    </Tr>
  );
}

const PolicyTerm = ({ policy }) => {
  const { effectiveDate, expirationDate } = policy;

  if (!effectiveDate && !expirationDate) return <None />;
  else if (!!effectiveDate && !expirationDate) {
    return (
      <>
        {`${dateToClientSideFormat(effectiveDate)} - `}
        <None />
      </>
    );
  } else if (!!expirationDate && !effectiveDate) {
    return (
      <>
        <None />
        {` - ${dateToClientSideFormat(expirationDate)}`}
      </>
    );
  } else if (!!expirationDate && !!effectiveDate) {
    return <>{`${dateToClientSideFormat(effectiveDate)} - ${dateToClientSideFormat(expirationDate)}`}</>;
  }
};

const None = () => {
  return <Placeholder className="fs-xs text-gray-500 fs-xs font-weight-400">None</Placeholder>;
};
