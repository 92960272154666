import { gql, useQuery } from '@apollo/client';

export const GET_PRICING_OPTION_ADD_ONS = gql`
  query pricingOptionRequiredAddOns($pricingOptionId: ID!) {
    pricingOption(id: $pricingOptionId) {
      id
      addOns {
        required {
          id
          label
        }
        optional {
          id
          label
        }
      }
    }
  }
`;

export default function usePricingOptionAddOns({ pricingOptionId }) {
  const { data, loading, error } = useQuery(GET_PRICING_OPTION_ADD_ONS, {
    variables: { pricingOptionId },
    skip: !pricingOptionId,
    fetchPolicy: !pricingOptionId ? 'cache-only' : 'cache-first'
  });

  return {
    requiredAddOns: data?.pricingOption?.addOns?.required,
    optionalAddOns: data?.pricingOption?.addOns?.optional,
    loading,
    error
  };
}
