import React from 'react';

import { BoxItem, BoxItemTitle } from '../../../components';
import { useVehicleMakeModelYear } from '../../vehicle/hooks';

export default function VehicleBoxItem({ onClick, vehicle }) {
  const { hasMakeModelYear, makeModelYear } = useVehicleMakeModelYear(vehicle);
  const placeholder = 'None';
  return (
    <BoxItem size="md" onClick={onClick} style={{ 'overflow-x': 'scroll' }}>
      <BoxItemTitle title="Unit#:" subtitle={vehicle.unitNumber ? vehicle.unitNumber : placeholder} />
      <BoxItemTitle
        title={hasMakeModelYear ? makeModelYear : vehicle?.vin.toUpperCase()}
        subtitle={hasMakeModelYear ? vehicle?.vin.toUpperCase() : placeholder}
      />
      <BoxItemTitle title="Value:" subtitle={vehicle.value ? vehicle.value : placeholder} />
      <BoxItemTitle
        title="State of Registration:"
        subtitle={vehicle.stateOfRegistration ? vehicle.stateOfRegistration : placeholder}
      />
      <BoxItemTitle title="Equipment Type:" subtitle={vehicle.vehicleType ? vehicle.vehicleType.name : placeholder} />
      <BoxItemTitle
        title="Gross Vehicle Weight:"
        subtitle={vehicle.vehicleWeight ? vehicle.vehicleWeight : placeholder}
      />
    </BoxItem>
  );
}
