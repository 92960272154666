import { gql } from '@apollo/client';
export const POLICY_INSURED_FIELDS = gql`
  fragment PolicyInsuredFields on Insured {
    id
    businessName
    email
    mobilePhone
    firstName
    middleName
    lastName
    dateOfBirth
    electionCount
    dispatchLocation
    vehicleCount
  }
`;
