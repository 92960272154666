import React from 'react';

import { Alert } from 'reactstrap';
import { Formik, Form } from 'formik';
import { gql, useMutation } from '@apollo/client';
import { Modal } from '@oforce/global-components';
import { GraphQLErrors } from '../../../components';
import { HideFromRoles, useActiveAccount } from '../../../shared/activeAccount';

const VOID_INVOICE = gql`
  mutation void($id: Int!) {
    voidInvoice(invoiceId: $id, regeneratingInvoice: false) {
      id
      status
    }
  }
`;

export default function VoidInvoiceModal({ invoice, isOpen, toggle, onError = () => {} }) {
  const {
    ROLES: { EXPOSURE_MANAGER }
  } = useActiveAccount();

  const [voidInvoice, { loading: voiding, error, reset }] = useMutation(VOID_INVOICE, {
    onCompleted: toggle,
    onError
  });

  const handleSubmit = values => {
    voidInvoice({ variables: { id: values?.id } });
  };

  const regenerationTime = new Date(Date.UTC(null, null, null, 3, 0, 0)).toLocaleTimeString();
  const baseInvoiceMessage = `Are you sure you want to void invoice #${invoice?.id}?`;
  const automatedInvoiceMessage = `This invoice will be automatically regenerated at ${regenerationTime} if you don't generate it manually. If you void this invoice within one hour of the next scheduled invoice generation, it will be regenerated immediately.`;
  const invoiceMessage = invoice?.clientProduct?.automateInvoiceGeneration
    ? baseInvoiceMessage + '\n' + automatedInvoiceMessage
    : baseInvoiceMessage;

  return (
    <HideFromRoles roles={[EXPOSURE_MANAGER]}>
      <Formik enableReinitialize initialValues={{ id: invoice?.id }} onSubmit={handleSubmit}>
        {formik => (
          <Modal
            isOpen={isOpen}
            toggle={toggle}
            title={`Void Invoice #${invoice?.id}`}
            onSubmit={formik.handleSubmit}
            onOpened={() => {
              formik.handleReset();
              reset();
            }}
            submitButtonText="Void Invoice"
            submitButtonProps={{
              color: 'info',
              loading: voiding,
              loadingText: 'Voiding invoice...'
            }}
          >
            <GraphQLErrors error={error} />
            <Form>
              <Alert color="warning">{invoiceMessage}</Alert>
            </Form>
          </Modal>
        )}
      </Formik>
    </HideFromRoles>
  );
}
