import { gql, useQuery } from '@apollo/client';

const GET_COMMERCIAL_AUTO_COVERAGE_TYPES = gql`
  query GetCommercialAutoCoverageTypes {
    commercialAutoCoverageTypes {
      id
      name
    }
  }
`;

export default function useCommercialAutoCoverageTypes() {
  const { data, loading, error } = useQuery(GET_COMMERCIAL_AUTO_COVERAGE_TYPES);

  const options = data?.commercialAutoCoverageTypes?.reduce(
    (acc, option) => [...acc, { value: option.id, label: option.name }],
    []
  );

  return { commercialAutoCoverageTypes: data?.commercialAutoCoverageTypes, loading, error, options };
}
