import { Button, RenderData } from '@oforce/global-components';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { VehicleElectionCard } from '../../../components/exposureElection';
import { useActiveAccount } from '../../../shared/activeAccount';
import { BoxContainer } from '../../../components';
import moment from 'moment';
import { HideFromRolesAndTypes } from '../../../shared/activeAccount/components';

export default function VehicleCoveragesDetails({ coverages: elections }) {
  const history = useHistory();
  const { url } = useRouteMatch();
  const isFutureDate = ({ terminationDate: date }) => {
    if (!!date) return moment(date) > moment();
    return true;
  };
  const coverages = elections?.filter(
    election => election?.compliance?.inCompliance === true && isFutureDate(election)
  );

  const {
    ROLES: { OBSERVER },
    TYPES: { CLAIMS_ADJUSTER }
  } = useActiveAccount();

  return (
    <div className="d-flex flex-column flex-grow-1">
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="my-3">Vehicle Coverages</h5>
        <HideFromRolesAndTypes roles={[OBSERVER]} types={[CLAIMS_ADJUSTER]}>
          <Button color="info" size="sm" onClick={() => history.push(`${url}/add-coverage`)} icon={['far', 'plus']}>
            Create
          </Button>
        </HideFromRolesAndTypes>
      </div>
      <RenderData
        data={coverages}
        placeholderComponent={
          <BoxContainer className={'mb-3 text-muted p-3 font-italic'}>No Vehicle Coverages</BoxContainer>
        }
      >
        {coverages =>
          coverages?.map(coverage => {
            return <VehicleElectionCard key={coverage?.id} election={coverage} className="mb-4" />;
          })
        }
      </RenderData>
    </div>
  );
}
