import download from 'js-file-download';
import { useCallback, useState } from 'react';

export default function useDownloadFile({ url, filename }) {
  const [loading, setLoading] = useState(false);

  const downloadFile = useCallback(async () => {
    setLoading(true);
    await fetch(url)
      .then(response => response.blob())
      .then(file => download(file, filename));
    setLoading(false);
  }, [url, filename]);

  const downloadSpecificFile = useCallback(async ({ url, filename }) => {
    setLoading(true);

    await fetch(url)
      .then(response => response.blob())
      .then(file => download(file, filename));
    setLoading(false);
  }, []);

  return {
    download: downloadFile,
    downloadFile: downloadSpecificFile,
    loading
  };
}
