import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { BoxContainer } from '../../components';
import { TabPage } from '../../components/tabs';
import PolicyNotificationForm from './components/PolicyNotificationForm';

export default function PolicyNotifications({ policy }) {
  const { path } = useRouteMatch();

  if (!policy) {
    return null;
  }

  return (
    <Switch>
      <Route exact path={path}>
        <TabPage title="Notifications" showBackButton>
          <BoxContainer className="mb-4">
            <PolicyNotificationForm
              policy={policy}
              notificationGroup={'INVOICING'}
              title={'Invoice Recipients'}
              description={'These emails will be notificed whenever an invoice is sent or created.'}
            />
            <PolicyNotificationForm
              policy={policy}
              notificationGroup={'COMPLIANCE_VIOLATIONS'}
              title={'Needs approval'}
              description={
                'Notify these email addresses whenever this policy is moved into a state of Pending Approval.'
              }
            />
          </BoxContainer>
        </TabPage>
      </Route>
    </Switch>
  );
}
