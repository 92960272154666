import React, { useState } from 'react';
import { useToggle } from '@oforce/global-components';

import { AddOns, AddOnCreate, AddOnEdit, AddOnRemove } from '../../views/addOns/components';
import { usePolicyAddOns } from '../../views/addOns/hooks';

import { usePolicyPricingOptions } from '../../components/sharedComponents/pricing';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

export default function PolicyAddOns({ policy }) {
  const history = useHistory();
  const { url, path } = useRouteMatch();
  const [showRemove, toggleShowRemove] = useToggle();
  const [removingAddOn, setRemovingAddOn] = useState(null);

  const { addOns, loading, error } = usePolicyAddOns({ policyId: policy?.id });
  const { policyPricingOptions } = usePolicyPricingOptions({ policyId: policy?.id });

  const toggleRemove = addOn => {
    setRemovingAddOn(addOn);
    toggleShowRemove();
  };

  return (
    <Switch>
      <Route exact path={path}>
        <AddOns
          addOns={addOns}
          loading={loading}
          error={error}
          toggleEdit={addOn => {
            history.push(`${url}/update-addon/${addOn.id}`);
          }}
          toggleRemove={toggleRemove}
          toggleCreate={() => history.push(`${url}/create-addon`)}
          pricingOptions={policyPricingOptions}
        />
        {showRemove && <AddOnRemove isOpen={showRemove} toggle={toggleRemove} addOn={removingAddOn} />}
      </Route>
      <Route path={`${path}/create-addon`}>
        <AddOnCreate policy={policy} />
      </Route>

      <Route path={`${path}/update-addon/:addOnId`}>
        <AddOnEdit policy={policy} addOns={addOns} />
      </Route>
    </Switch>
  );
}
