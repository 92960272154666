import { gql, useQuery } from "@apollo/client";
import { toHeaderCase } from "js-convert-case";

const GET_ZONE_SOURCES = gql`
  query ratingZoneSources {
    ratingZoneSources
  }
`;

export default function useRatingZoneSources() {
    const { loading, error, data } = useQuery(GET_ZONE_SOURCES);
  
    return {
      loading,
      error,
      ratingZoneSources: data?.ratingZoneSources,
      options: data?.ratingZoneSources?.map(ratingZoneSource => ({
        label: toHeaderCase(ratingZoneSource),
        value: ratingZoneSource
      }))
    };
  }