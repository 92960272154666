import { useLazyQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { useEffect } from 'react';

export const GET_EXPOSURE_ELECTION_ACTION_PERMISSIONS = gql`
  query exposureElectionActionPermissions($id: ID!) {
    getExposureElection(id: $id) {
      id
      actionPermissions {
        actionName
        allowed
        reason
      }
    }
  }
`;

export default function useQuickActionPermissions({ exposureElectionId, makeCall }) {
  const [loadActionPermissions, { data, loading, error }] = useLazyQuery(GET_EXPOSURE_ELECTION_ACTION_PERMISSIONS, {
    variables: { id: exposureElectionId }
  });

  useEffect(() => {
    if (!!makeCall && !!exposureElectionId && !data) {
      loadActionPermissions();
    }
  }, [exposureElectionId, makeCall, loadActionPermissions, data]);

  const actionPermissions =
    data?.getExposureElection?.actionPermissions?.reduce((acc, { actionName, allowed, reason }) => {
      return Object.assign({}, acc, { [actionName]: { allowed, reason } });
    }, {}) || {};

  const actionAllowed = actionName => {
    const { allowed } = actionPermissions[actionName] || {};
    return allowed;
  };

  const noAllowedActions = data?.getExposureElection?.actionPermissions?.filter(x => x.allowed === true)?.length === 0;

  return {
    actionPermissions: actionPermissions || {},
    noAllowedActions,
    actionAllowed,
    loading,
    error
  };
}
