import React from 'react';
import { Button } from '@oforce/global-components';

import { useToggle } from '../../hooks';
import { TabPage } from '../../components/tabs';
import { BoxContainer } from '../../components';
import { HideFromRoles, ShowForTypes, useActiveAccount } from '../../shared/activeAccount';

import { FeesTable, CreateFee } from './components';

export default function ProductFees({ product }) {
  const {
    TYPES: { CARRIER },
    ROLES: { EXPOSURE_MANAGER }
  } = useActiveAccount();
  const [showCreateFee, toggleCreateFee] = useToggle();

  return (
    <TabPage
      title="Fees"
      showBackButton
      buttons={
        <ShowForTypes types={[CARRIER]}>
          <HideFromRoles roles={[EXPOSURE_MANAGER]}>
            <Button color="info" icon={['far', 'plus']} className="ml-2" onClick={toggleCreateFee}>
              Create
            </Button>
          </HideFromRoles>
        </ShowForTypes>
      }
    >
      <CreateFee isOpen={showCreateFee} toggle={toggleCreateFee} product={product} />
      <BoxContainer>
        <FeesTable product={product} />
      </BoxContainer>
    </TabPage>
  );
}
