import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { gql, useQuery } from '@apollo/client';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { ReportGenerate } from '../../views';
import { useActiveAccount } from '../../shared/activeAccount';
import { PageBody, PageTitle, PageHeader } from '../../components/page-components';

import { ReportsList, ReportCreate, Dashboard } from './containers';
import Select from 'react-select';
import { HideFromRolesAndTypes } from '../../shared/activeAccount/components';

const GET_ACCOUNT_REPORTS = gql`
  query GetAccountReports($activeAccountId: ID!) {
    account(id: $activeAccountId) {
      id
      reports {
        id
        name
        query
        variables {
          name
          type
          required
          isListType
          description
          possibleValues
        }
      }
    }
  }
`;

export default function Reports() {
  const { path } = useRouteMatch();
  const {
    TYPES: { CLIENT },
    id,
    type,
    hasClientCumulioReports
  } = useActiveAccount();
  const { data, loading, error, refetch } = useQuery(GET_ACCOUNT_REPORTS, { variables: { activeAccountId: id } });
  const reports = data?.account?.reports;

  const defaultDashboardsList = [
    { dashboardId: 'b0e0be89-d177-4c18-bfe9-eb74358bfb97', dashboardSlug: 'Select Report' }
  ];

  const [selectedDashboard, setSelectedDashboard] = useState({ ...defaultDashboardsList[0] });
  const [dashboards, setDashboards] = useState([...defaultDashboardsList]);
  const [accessibleDashboardsLoading, setAccessibleDashboardsLoading] = useState(true);

  const dashboardOptions = dashboards.map(dashboard => ({
    label: dashboard?.dashboardSlug,
    value: dashboard?.dashboardId
  }));

  const hideFrom = type === 'CLIENT' && hasClientCumulioReports ? [] : [CLIENT];

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Helmet>
          <title>Reports • IC Insure</title>
        </Helmet>
        <PageHeader>
          <PageTitle>Reports</PageTitle>
        </PageHeader>
        <PageBody>
          <ReportsList reports={reports} loading={loading} error={error} />
          <HideFromRolesAndTypes types={hideFrom}>
            <h5>Custom Reports</h5>
            <Select
              className="mb-5"
              options={dashboardOptions}
              defaultValue={dashboardOptions[0] || ''}
              name="dashboard"
              label="Dashboard"
              placeholder="Choose Dashboard..."
              isLoading={accessibleDashboardsLoading}
              isDisabled={accessibleDashboardsLoading}
              onChange={e => {
                setSelectedDashboard({ dashboardId: e?.value, dashboardSlug: e?.label });
              }}
            />
            {!!selectedDashboard && (
              <Dashboard
                key={selectedDashboard?.dashboardId}
                dashboardId={selectedDashboard?.dashboardId}
                setDashboards={setDashboards}
                setAccessibleDashboardsLoading={setAccessibleDashboardsLoading}
              />
            )}
          </HideFromRolesAndTypes>
        </PageBody>
      </Route>
      <Route path={`${path}/create`}>
        <Helmet>
          <title>Create Report • IC Insure</title>
        </Helmet>
        <PageHeader>
          <PageTitle>Reports</PageTitle>
        </PageHeader>
        <PageBody>
          <ReportCreate refetchReports={refetch} />
        </PageBody>
      </Route>
      <Route path={`${path}/:reportId/generate`}>
        <Helmet>
          <title>Generate Report • IC Insure</title>
        </Helmet>
        <PageHeader>
          <PageTitle>Reports</PageTitle>
        </PageHeader>
        <PageBody>
          <ReportGenerate reports={reports} />
        </PageBody>
      </Route>
    </Switch>
  );
}
