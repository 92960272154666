import { gql, useQuery } from '@apollo/client';

const GET_WEIGHT_CLASSES = gql`
  query GetWeightClasses {
    weightClasses {
      id
      name
    }
  }
`;

export default function useVehicleWeightClasses() {
  const { data, loading, error } = useQuery(GET_WEIGHT_CLASSES);

  return {
    vehicleWeightClasses: data?.weightClasses,
    options: data?.weightClasses?.reduce((acc, option) => [...acc, { value: option.id, label: option.name }], []),
    loading,
    error
  };
}
