import { GraphiQL } from 'graphiql';
import { Helmet } from 'react-helmet';
import Explorer from 'graphiql-explorer';
import root from 'react-shadow/styled-components';
import { buildClientSchema, getIntrospectionQuery } from 'graphql';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { StyledGraphiQLContainer } from './styled';

import useAccessToken from '../../context/apollo/useAccessToken';
import { useCurrentUser } from '../../shared/currentUser';

const DEFAULT_QUERY = `query Invoice {
  invoice(id: 1000) {
    serviceStartDate
    serviceEndDate
    brokerTotal
    clientTotal
    transactions {
      total
      insured {
        firstName
        lastName
      }
    }
  }
}`;

export default function ApiExplorer() {
  const graphiql = useRef(null);
  const [schema, setSchema] = useState(null);
  const [query, setQuery] = useState(localStorage.getItem('graphiql:query') || DEFAULT_QUERY);

  // const [showCreateReport, toggleCreateReport] = useToggle();

  const { accessToken } = useAccessToken();
  const { isAdmin } = useCurrentUser();

  const fetcher = useCallback(
    async params => {
      const url = isAdmin ? '/api' : '/api/user';
      const data = await fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify(params),
        credentials: 'same-origin'
      });
      return data.json().catch(() => data.text());
    },
    [accessToken, isAdmin]
  );

  useEffect(() => {
    if (accessToken) {
      fetcher({ query: getIntrospectionQuery() }).then(result => {
        setSchema(buildClientSchema(result.data));
      });
    }
  }, [fetcher, accessToken]);

  useEffect(() => {
    localStorage.setItem('graphiql:query', query);
  }, [query]);

  return (
    <>
      <Helmet>
        <title>API Explorer • IC Insure</title>
      </Helmet>
      <root.div style={{ display: 'flex', height: 'calc(100vh - 60px)', width: 'calc(100vw - 250px)' }}>
        <link rel="stylesheet" href="https://unpkg.com/graphiql/graphiql.min.css" />
        <StyledGraphiQLContainer>
          <Explorer title="API Explorer" schema={schema} explorerIsOpen={true} query={query} onEdit={setQuery} />
        </StyledGraphiQLContainer>
        <GraphiQL fetcher={fetcher} schema={schema} query={query} style={{ height: 'calc(100vh - 60px) !important' }}>
          <GraphiQL.Toolbar>
            <GraphiQL.Button
              onClick={() => graphiql.current.handlePrettifyQuery()}
              label="Prettify"
              title="Prettify Query (Shift-Ctrl-P)"
            />
          </GraphiQL.Toolbar>
        </GraphiQL>
      </root.div>
    </>
  );
}
