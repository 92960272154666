import * as yup from 'yup';

export default function useRegenerateInvoiceFormSteps({ invoice }) {
  const formSteps = [
    {
      name: 'Reenerate Invoice',
      initialValues: {
        invoiceId: invoice?.id,
        notes: ''
      },
      validationSchema: yup.object({
        notes: yup.string().required('Required').max(255, 'Must be 255 characters or less')
      })
    }
  ];

  return { formSteps };
}
