import React from 'react';

import { GraphQLErrors } from '..';
import { FullPage, MessageBox, LargeIcon, Title } from '../../components/message-box';

export default function GraphQLErrorsPage({ error }) {
  return (
    <FullPage>
      <MessageBox className="align-self-center justify-self-center">
        <LargeIcon icon={['far', 'exclamation-triangle']} color="danger" />
        <Title className="mb-0">Oops, something went wrong</Title>
        <GraphQLErrors error={error} />
      </MessageBox>
    </FullPage>
  );
}
