import DynamicCoveragesForm from '../../coverages/components/DynamicCoveragesForm';
import { useInsuredCreateFormSubmit } from '../../coverages/components/steps/hooks';
import { useHistory } from 'react-router-dom';

export default function VehicleCreate({ insured }) {
  const history = useHistory();
  const { formSubmit, loading, error } = useInsuredCreateFormSubmit({
    insured,
    onCompleted: () => history.push(`/insureds/${insured?.id}/vehicles`)
  });

  return (
    <DynamicCoveragesForm
      title="Create Vehicle"
      insured={insured}
      policyInsuranceTypes={['VEHICLE']}
      onCancel={() => history.push(`/insureds/${insured?.id}/vehicles`)}
      onSubmit={formSubmit}
      error={error}
      loading={loading}
    />
  );
}
