import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { theme } from '@oforce/global-styles';
const { gray500, gray600, gray800 } = theme;

const StyledInputContainer = styled.div.attrs({
  className: 'border border-gray-300 border-width-2 d-flex align-items-stretch'
})`
  border-radius: 0px !important;
  position: relative;
`;

const StyledInput = styled.input`
  flex: 1 1 0%;
  display: block;
  border: 0;
  padding: 5px 10px;
  border-radius: 0px !important;
  outline: 0;
  height: 33px !important;

  &::placeholder {
    color: ${gray500} !important;
  }
`;

const StyledButton = styled.button`
  background: white;
  border: 0;
  padding: 4px 10px;
  height: 33px !important;
  border-radius: 0px !important;
  color: ${gray500};
  &:hover {
    color: ${gray600};
  }
  outline: 0;
  &:active,
  &:focus {
    outline: 0 !important;
  }
  &:active {
    color: ${gray800};
  }
`;

const StyledIcon = styled(FontAwesomeIcon)``;

export default function SearchInput({
  placeholder,
  value,
  onClick,
  onClear,
  clearable,
  onFocus,
  onBlur,
  ...rest
}) {
  return (
    <>
      <StyledInputContainer>
        <StyledInput
          value={value}
          type="text"
          placeholder={placeholder}
          {...rest}
        />
        {clearable && value && value.length > 0 && (
          <StyledButton type="button" onClick={onClear}>
            <StyledIcon icon={['far', 'times']} />
          </StyledButton>
        )}
        <StyledButton type="submit" onClick={onClick}>
          <StyledIcon icon={['far', 'search']} />
        </StyledButton>
      </StyledInputContainer>
    </>
  );
}
