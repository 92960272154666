import React from 'react';
import { Button, useToggle } from '@oforce/global-components';

import { MappableFields, UploadInvoiceTemplate } from './components';

import { useDownloadFile } from '../../hooks';
import { BoxContainer, BoxContainerTitle, BoxItem, BoxItemPlaceholder, Render } from '../../components';
import { useCurrentUser } from '../../shared/currentUser';

export default function InvoiceTemplate({ invoiceTemplate, clientProductId, policy }) {
  const { isAdmin } = useCurrentUser();

  const [showUploadTemplate, toggleUploadTemplate] = useToggle();
  const [showMappableFields, toggleMappableFields] = useToggle();

  const { download, loading } = useDownloadFile({
    url: invoiceTemplate?.url,
    filename: invoiceTemplate?.filename
  });

  if (!isAdmin) return null;

  const shouldShowDocumentUploadButton = policy => {
    if (policy?.archived) return false;
    else if (policy?.expirationDate !== null && new Date(policy?.expirationDate) <= new Date()) return false;
    else return true;
  };

  return (
    <>
      <BoxContainerTitle>
        <div className="d-flex align-items-center justify-content-between">
          Invoice Template
          <Render if={shouldShowDocumentUploadButton(policy)}>
            <Button color="link" icon={['far', 'file-upload']} className="p-0" onClick={toggleUploadTemplate}>
              {invoiceTemplate ? 'Replace' : 'Upload'}
            </Button>
          </Render>
        </div>
      </BoxContainerTitle>
      <BoxContainer>
        <Render if={!!invoiceTemplate}>
          <BoxItem>
            <Button
              color="link"
              icon={['far', 'arrow-to-bottom']}
              onClick={download}
              loading={loading}
              loadingText="Downloading..."
            >
              {invoiceTemplate?.filename}
            </Button>
          </BoxItem>
        </Render>
        <Render if={!invoiceTemplate}>
          <BoxItemPlaceholder>None</BoxItemPlaceholder>
        </Render>
      </BoxContainer>

      <Button color="link" icon={['far', 'info-circle']} className="p-0 fs-xs mt-2" onClick={toggleMappableFields}>
        View Mappable Fields
      </Button>

      <UploadInvoiceTemplate
        isOpen={showUploadTemplate}
        toggle={toggleUploadTemplate}
        clientProductId={clientProductId}
        hasInvoiceTemplate={invoiceTemplate}
      />

      <MappableFields open={showMappableFields} toggle={toggleMappableFields} />
    </>
  );
}
