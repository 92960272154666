import React from 'react';
import pluralize from 'pluralize';
import classNames from 'classnames';
import { Col, Row } from 'reactstrap';
import { Loader, RenderData } from '@oforce/global-components';

import { BoxContainer } from '../../../components';

export default function AccountMembersDashboard({
  members,
  loading,
  membersCount,
  pendingInvitesCount,
  declinedInvitesCount
}) {
  return (
    <BoxContainer className="mb-5">
      <div className="my-4">
        <RenderData data={members} loading={loading} loadingComponent={<Loader />}>
          <Row>
            <DashboardColumn count={membersCount} label={pluralize('Member', membersCount)} />
            <DashboardColumn
              count={pendingInvitesCount}
              label={`Pending ${pluralize('invites', pendingInvitesCount)}`}
            />
            <DashboardColumn count={declinedInvitesCount} label="Declined invites" showRightBorder={false} />
          </Row>
        </RenderData>
      </div>
    </BoxContainer>
  );
}

const DashboardColumn = ({ showRightBorder = true, count, label }) => (
  <Col
    className={classNames('d-flex flex-column align-items-center border-width-2', { 'border-right': showRightBorder })}
  >
    <h1 className="mb-0">{count}</h1>
    <span className="text-muted">{label}</span>
  </Col>
);
