import useRemoveFee from './useRemoveFee';

export default function useRemoveFeeForm({
  productId,
  feeId,
  onCompleted,
  onError
}) {
  const { removeFee, loading, error } = useRemoveFee({
    feeId,
    productId,
    onCompleted,
    onError
  });

  const initialValues = {};

  const handleSubmit = () => removeFee();

  return {
    initialValues,
    handleSubmit,
    loading,
    error
  };
}
