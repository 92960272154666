import React from 'react';
import { Link } from 'react-router-dom';
import { ContactInfoCell } from '../../../components/tables';
import { normalizeInsuredFullName } from '../../insured/utils';

export default function CellOwnerContact({ insured }) {
  if (!insured) return <EmptyCell />;

  return (
    <div className="d-flex flex-column align-items-start">
      <Link to={`/insureds/${insured?.id}`}>
        <span className="fs-sm">{normalizeInsuredFullName(insured)}</span>
      </Link>
      <div>
        <ContactInfoCell className="fs-xs" email={insured?.email} mobilePhone={insured?.mobilePhone} />
      </div>
    </div>
  );
}

const EmptyCell = ({ text = 'None' }) => <div className="font-weight-300 fs-xs font-italic text-muted">{text}</div>;
