import { Label, ReactSelect, Badge, FieldMessage, useToggle } from '@oforce/global-components';
import React from 'react';
import styled from 'styled-components';
import { Row, Col, Button, Tooltip } from 'reactstrap';
import { Render } from '../';
import { DynamicInput } from '.';
import { usePricingOptionAddOns } from '../../views/policyInsureds/hooks';
import { backendNameToCamelCaseLabel } from '../../utils/formatting';

const Line = styled.div`
  height: 15px;
  width: 100%;
  border-bottom: ${props => `1px solid ${props.theme.gray300}`};
`;

export default function RowUpdate({ onChange, row, onCancel, formOptions, onSave, lineNumber, loading }) {
  const [showTooltip, toggleTooltip] = useToggle();
  const hasPricingOption = row['policyPricingOptionId'];

  const { optionalAddOns: addOns = [], requiredAddOns = [] } = usePricingOptionAddOns({
    pricingOptionId: row['policyPricingOptionId']
  });
  const addOnOptions = addOns.reduce((acc, option) => [...acc, { value: option.id, label: option.label }], []);
  const selectedAddOns = addOnOptions.filter(({ value }) => row['addOnIds'].includes(value));

  const requiredAddOnLabels = requiredAddOns?.map(({ label }) => (
    <span style={overflowStyles({ maxWidth: '110px' })}>{label} </span>
  ));

  return (
    <div className="w-100 ml-1">
      <Row className="w-100 mb-1">
        <LineNumberBadge lineNumber={lineNumber} />
      </Row>
      <Row className="w-100 d-flex flex-wrap flex-nowrap">
        <Col xl="2" lg="2" md="4" className="mt-2">
          <SummaryTitle row={row} />
        </Col>
        {hasPricingOption && (
          <Col xxl="7" xl="7" className="d-flex flex-row flex-wrap w-100 justify-content-between">
            <div style={{ width: 'fit-content' }}>
              <Label className="font-weight-500 mb-0 mt-2 fs-sm">Pricing Option</Label>
              <ReactSelect
                options={formOptions?.pricingOptions?.options}
                value={formOptions?.pricingOptions?.options?.find(
                  ({ value }) => value === row['policyPricingOptionId']
                )}
                onChange={option => {
                  onChange({ addOnIds: [], policyPricingOptionId: option?.value });
                }}
                required
                placeholder="Select a default policy option..."
              />
            </div>
            <div style={{ width: 'fit-content' }}>
              <Label className="font-weight-500 mb-0  mt-2 fs-sm">Optional Add-On(s)</Label>
              <ReactSelect
                options={addOnOptions}
                value={selectedAddOns}
                onChange={values => {
                  onChange({ addOnIds: values?.map(x => x.value) });
                }}
                placeholder="Select an add-on..."
                isMulti
              />
              <Render if={requiredAddOnLabels?.length > 0}>
                <div id="r-add-on-message">
                  <FieldMessage icon={['far', 'info-circle']} className="fs-xs text-muted">
                    Required Add-Ons
                  </FieldMessage>
                </div>
                <Tooltip target="r-add-on-message" placement="bottom" isOpen={showTooltip} toggle={toggleTooltip}>
                  {requiredAddOns?.map(({ label }) => (
                    <div key={`r-add-on-label-${label}`}>{label}</div>
                  ))}
                </Tooltip>
              </Render>
            </div>
          </Col>
        )}
      </Row>

      <Render if={row?.allBrokenFields?.length > 0}>
        <Row>
          <Line className="mb-3 mt-3" />
        </Row>
        <Row className="ml-1 mb-2">
          <LabelValueTitle label={'Invalid Fields'} />
        </Row>
        <Row className="ml-3">
          <div className="d-flex flex-row flex-wrap mb-2 h-100 align-items-start">
            {row?.allBrokenFields?.map(field => (
              <DynamicInput
                required
                className="mr-3 mb-3"
                key={`dynamic-input-${row?.rowId}-${field}`}
                row={row}
                field={backendNameToCamelCaseLabel(field)}
                onChange={onChange}
                formOptions={formOptions}
              />
            ))}
          </div>
        </Row>
      </Render>

      <Row className="justify-content-end p-3">
        <Button className="mr-3" color="success" onClick={onSave} loading={loading ? !!loading : undefined}>
          Save
        </Button>
        <Button className="mr-2" color="danger" onClick={onCancel}>
          Cancel
        </Button>
      </Row>
    </div>
  );
}

function LineNumberBadge({ lineNumber }) {
  return <Badge size="xs">Line: {lineNumber}</Badge>;
}

function SummaryTitle({ row = {} }) {
  const { firstName, lastName, businessName } = row;

  const label = () => {
    if (!!firstName && !!lastName) return `${firstName} ${lastName}`;
    if (!!firstName) return `${firstName}`;
    if (businessName) return `${businessName}`;
    return `Unknown name`;
  };

  const value = () => {
    if (row?.vin) return row?.vin;
    if (!!firstName && !!lastName && !!businessName) return businessName;
    if (!!firstName && !!businessName) return businessName;
    return '';
  };

  return <LabelValueTitle label={label()} value={value()} />;
}

function LabelValueTitle({ label, value }) {
  return (
    <div className="d-flex flex-column">
      <div className="font-weight-500">{label}</div>
      <div className="font-weight-400 fs-sm text-muted">{value}</div>
    </div>
  );
}

const overflowStyles = (attrs = {}) => {
  return { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '180px', ...attrs };
};
