import React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { MultiStepForm } from '../../components';
import { Steps } from 'react-step-builder';
import {
  BulkDownloadDocumentsDateSelection,
  BulkDownloadDocumentsDocsSelection,
  BulkDownloadDocumentsInsuredsSelection
} from './components/';
import { useListClientDocumentRelationships, useBulkDownloadDocumentsFormSteps } from './hooks';
import { useBulkDownloadDocumentsJobContext } from '../../context';
import { SERVER_SIDE_DATE_FORMAT } from '../../utils/dates';

export default function BulkDownloadDocuments({ clientId, documentIds, documents, redirectUrl }) {
  const history = useHistory();
  const { startBulkDownloadDocumentsJob, loading, error } = useBulkDownloadDocumentsJobContext();

  // TODO:
  // when implementing bulk download from policies page, check for `clientId` and `policyId` and call either the
  // below query or call a new one when implementing policy bulk download and map to same values
  const {
    allInsuredIds,
    allValidInsuredIds,
    allDocumentInsuredsMapping,
    allValidDocumentRelationships,
    loading: loadingRelationships,
    error: errorRelationships
  } = useListClientDocumentRelationships({ clientId, documentIds });

  const { formSteps } = useBulkDownloadDocumentsFormSteps(
    allInsuredIds,
    allValidInsuredIds,
    allDocumentInsuredsMapping,
    allValidDocumentRelationships,
    documentIds
  );

  return (
    <MultiStepForm
      formSteps={formSteps}
      formSubmit={values => {
        const { activeOn, documentIds, insuredIds } = values;

        const documentRelationshipIds = allValidDocumentRelationships
          ?.filter(relationship => documentIds.includes(relationship?.clientDocumentId))
          ?.filter(relationship => insuredIds.includes(relationship?.insuredId))
          ?.map(relationship => relationship?.id);

        startBulkDownloadDocumentsJob({
          activeOn: activeOn === '' ? null : moment(activeOn).format(SERVER_SIDE_DATE_FORMAT),
          clientId,
          documentRelationshipIds,
          redirectUrl
        });
      }}
      maxWidth={'800px'}
      title={`Bulk Download Documents`}
      onCancel={() => history.push(redirectUrl)}
      submitButtonText={'Download'}
      submitButtonProps={{ loading, loadingText: 'Downloading...' }}
      showButtonsOnBottom
      loading={loadingRelationships}
      error={error || errorRelationships}
    >
      {formProps => (
        <Steps>
          <BulkDownloadDocumentsDateSelection documents={documents} {...formProps} />
          <BulkDownloadDocumentsDocsSelection
            clientId={clientId}
            documentIds={documentIds}
            documents={documents}
            {...formProps}
          />
          <BulkDownloadDocumentsInsuredsSelection clientId={clientId} {...formProps} />
        </Steps>
      )}
    </MultiStepForm>
  );
}
