import ReactSelect from 'react-select';
import React from 'react';
import { FilterSearch } from '../filtering';
import { Render } from '@oforce/global-components';
import { useCanadianProvinces, useUSStates } from '../../hooks';

export default function SearchByInputRow({
  onFieldChange,
  onValueUpdate,
  value,
  fieldOptions,
  name,
  className,
  option
}) {
  const searchingByState = option && option?.value.includes('STATE');
  const { options: usStateOptions } = useUSStates();
  const { options: canadianProvinceOptions } = useCanadianProvinces();
  const stateOptions = [...usStateOptions, ...canadianProvinceOptions];

  return (
    <div className={`d-flex flex-row align-items-center justify-content-center ${className} flex-wrap`}>
      <div className="mr-1" style={{ width: '200px' }}>
        <ReactSelect
          options={fieldOptions}
          className={'fs-sm w-100'}
          name={name}
          id={`field-${name}`}
          placeholder="Search By..."
          value={option}
          onChange={onFieldChange}
        />
      </div>
      <Render if={searchingByState}>
        <div className="" style={{ width: '200px' }}>
          <ReactSelect
            id={`state-field-${name}`}
            className="w-100 fs-sm"
            placeholder={`${option?.label}`}
            options={stateOptions}
            value={stateOptions?.find(option => option.value === value)}
            onChange={option => onValueUpdate(option?.value)}
            isClearable
          />
        </div>
      </Render>
      <Render if={!searchingByState}>
        <div className="" style={{ width: '200px' }}>
          <FilterSearch
            id={`value-${name}`}
            iconSize="sm"
            style={overflowStyles()}
            className="fs-sm w-100"
            bottomMargin="mb-0"
            placeholder={`Search by ${option?.label}`}
            value={value}
            onChange={e => onValueUpdate(e.target.value)}
            onClear={e => onValueUpdate('')}
          />
        </div>
      </Render>
    </div>
  );
}

const overflowStyles = (attrs = {}) => {
  return { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '180px', ...attrs };
};
