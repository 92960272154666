import { gql, useQuery } from '@apollo/client';

export const POLICY_INSURANCE_TYPE_FIELDS = gql`
  fragment PolicyInsuranceTypeFields on Policy {
    insuranceType {
      id
      type
      name
      requireVehicle
      stateFees {
        id
      }
      premiumFactors {
        coverageParameters
        rateModes
      }
    }
  }
`;

const GET_POLICY_INSURANCE_TYPE = gql`
  query GetPolicyInsuranceType($id: ID!) {
    policy(id: $id) {
      id
      ...PolicyInsuranceTypeFields
    }
  }
  ${POLICY_INSURANCE_TYPE_FIELDS}
`;

export default function useInsuranceType({ policyId }) {
  const { data, loading, error, ...rest } = useQuery(GET_POLICY_INSURANCE_TYPE, {
    variables: { id: policyId }
  });

  return { insuranceType: data?.policy?.insuranceType, loading, error, ...rest };
}
