import { gql, useQuery } from '@apollo/client';

export const TOTAL_NOTIFICATIONS_COUNT = gql`
  query totalNotificationsCount($jobId: ID, $searchBy: [NotificationSearchByInput] = [], $status: NotificationStatus) {
    totalNotificationsCount(jobId: $jobId, searchBy: $searchBy, status: $status)
  }
`;

export default function useTotalNotificationsCount({ initialFilters = {} }) {
  const { data, loading, error } = useQuery(TOTAL_NOTIFICATIONS_COUNT, {
    variables: { ...initialFilters }
  });

  return {
    totalNotificationsCount: data?.totalNotificationsCount,
    loading,
    error
  };
}
