import { Button, FieldMessage, Render, useToggle } from '@oforce/global-components';
import React, { useState } from 'react';
import { BoxContainer, TabPage } from '../../../../components';
import { useUpdateExposures, useImportFormOptions, useUpdateRowValidation, useUpdateValidation } from './hooks';
import { ImportSummary } from '../../../../components/csvValidation';
import UploadExposureUpdateFileModal from './UploadExposureUpdateFileModal';
import { useRenewedFromPolicy } from '../../../exposureElections/hooks';
import { Link } from 'react-router-dom/cjs/react-router-dom';

export default function UpdateExposures({ policy }) {
  const [parsedRows, setParsedRows] = useState([]);
  const [reviewingRows, setReviewingRows] = useState(false);
  const [uploadModalIsOpen, toggleUploadModal] = useToggle(false);

  const { formOptions } = useImportFormOptions({ policy });

  const { onSubmit, loading: submitLoading, error, jobId } = useUpdateExposures({ policy });
  const submitAttrs = { loading: submitLoading, error, jobId };

  const { renewedFromPolicy } = useRenewedFromPolicy({ policy });
  const { backdate, id: renewedFromPolicyId, name: renewedFromPolicyName } = renewedFromPolicy;

  return (
    <>
      {!reviewingRows && (
        <TabPage centered size="lg" title={'Edit Exposures'} showBackButton>
          <BoxContainer className="p-4 p-md-5 mb-3">
            <h3 style={{ letterSpacing: '-1px' }}>Choose a file to begin the awesome update process!</h3>
            <div className="mb-5">
              Once you choose a file, we will parse it and give you the ability to change invalid fields before updating
              your exposures.
            </div>

            <FieldMessage icon={['far', 'info-circle']}>
              Start dates will reflect the policy's effective date or the passed in start date from your file, whichever
              is later.
            </FieldMessage>

            <FieldMessage icon={['far', 'info-circle']}>
              Termination dates will reflect the policy's expiration date or the passed in termination date from your
              file, whichever is earlier.
            </FieldMessage>

            <Render if={!!backdate && !!renewedFromPolicyId}>
              <FieldMessage icon={['far', 'info-circle']}>
                Backdating before the policy's effective date, requires changes in the previous policy:{' '}
                <Link to={`/policies/${renewedFromPolicyId}/insureds`}>{renewedFromPolicyName}</Link>.
              </FieldMessage>
            </Render>

            <div className="d-flex mt-3">
              <Button color="success" onClick={toggleUploadModal}>
                Choose Csv File
              </Button>
            </div>
          </BoxContainer>
        </TabPage>
      )}

      {reviewingRows && (
        <ImportSummary
          policy={policy}
          submitAttrs={submitAttrs}
          useRowValidation={useUpdateRowValidation}
          useValidation={useUpdateValidation}
          formOptions={formOptions}
          rows={parsedRows}
          onComplete={onSubmit}
          onBack={() => setReviewingRows(false)}
        />
      )}

      {uploadModalIsOpen && (
        <UploadExposureUpdateFileModal
          policy={policy}
          formOptions={formOptions}
          isOpen={uploadModalIsOpen}
          toggle={toggleUploadModal}
          onParseComplete={data => {
            setParsedRows(data);
            setReviewingRows(true);
            toggleUploadModal();
          }}
        />
      )}
    </>
  );
}
