import { updateClientSchema } from '../../../views/client/schema';

import { useUpdateClient } from '.';

export default function useUpdateClientForm({ client, onCompleted, formOptions }) {
  const { updateClient, loading, error } = useUpdateClient({
    onCompleted
  });

  const initialValues = {
    name: client?.name || '',
    externalId: client?.externalId || '',
    email: client?.email || '',
    address1: client?.address1 || '',
    address2: client?.address2 || '',
    city: client?.city || '',
    state: formOptions?.allStates?.options?.find(x => x.value === client?.state) || null,
    country: formOptions?.country?.options?.find(x => x.value === client?.country) || null,
    zip: client?.zip || ''
  };

  const handleSubmit = variables => {
    const input = {
      ...variables,
      state: variables?.state?.value,
      country: variables?.country?.value
    };
    updateClient(client?.id, input);
  };

  return {
    initialValues,
    validationSchema: updateClientSchema,
    handleSubmit,
    loading,
    error
  };
}
