import { gql, useMutation } from '@apollo/client';
import useCoverageParameterTypes from './useCoverageParameterTypes';
import {
  COVERAGE_PARAMETERS_FIELDS,
  GET_POLICY_COVERAGE_PARAMETERS,
  GET_PRODUCT_COVERAGE_PARAMETERS
} from './useGetCoverageParameters';

const CREATE_PRODUCT_COVERAGE_PARAMETER = gql`
  mutation CreateProductCoverageParameter(
    $name: String!
    $productId: ID!
    $type: CoverageParameterType!
    $validate: Boolean!
    $valueType: CoverageParameterValueType!
    $predefined: Boolean
  ) {
    createProductCoverageParameter(
      name: $name
      productId: $productId
      type: $type
      validate: $validate
      valueType: $valueType
      predefined: $predefined
    ) {
      ...CoverageParametersFields
    }
  }
  ${COVERAGE_PARAMETERS_FIELDS}
`;

const UPDATE_PRODUCT_COVERAGE_PARAMETER = gql`
  mutation UpdateProductCoverageParameter($id: ID!, $name: String!, $validate: Boolean!) {
    editProductCoverageParameter(id: $id, name: $name, validate: $validate) {
      ...CoverageParametersFields
    }
  }
  ${COVERAGE_PARAMETERS_FIELDS}
`;

const CREATE_POLICY_COVERAGE_PARAMETER = gql`
  mutation CreatePolicyCoverageParameter(
    $name: String!
    $policyId: ID!
    $type: CoverageParameterType!
    $validate: Boolean!
    $valueType: CoverageParameterValueType!
    $predefined: Boolean
  ) {
    createPolicyCoverageParameter(
      name: $name
      policyId: $policyId
      type: $type
      validate: $validate
      valueType: $valueType
      predefined: $predefined
    ) {
      policyCoverageParameters {
        ...CoverageParametersFields
      }
    }
  }
  ${COVERAGE_PARAMETERS_FIELDS}
`;

const UPDATE_POLICY_COVERAGE_PARAMETER = gql`
  mutation UpdatePolicyCoverageParameter($id: ID!, $name: String!, $validate: Boolean!) {
    editPolicyCoverageParameter(id: $id, name: $name, validate: $validate) {
      ...CoverageParametersFields
    }
  }
  ${COVERAGE_PARAMETERS_FIELDS}
`;

export default function useCoverageParametersFormSubmit({
  productId,
  policyId,
  isProduct,
  coverageParameter,
  isCreateMode,
  onCompleted = () => {},
  onError = () => {}
}) {
  const [create, { loading: creating, error: createError }] = useMutation(
    isProduct ? CREATE_PRODUCT_COVERAGE_PARAMETER : CREATE_POLICY_COVERAGE_PARAMETER,
    {
      onCompleted,
      onError,
      refetchQueries: [
        {
          query: isProduct ? GET_PRODUCT_COVERAGE_PARAMETERS : GET_POLICY_COVERAGE_PARAMETERS,
          variables: { id: isProduct ? productId : policyId }
        }
      ]
    }
  );

  const [update, { loading: updating, error: updateError }] = useMutation(
    isProduct ? UPDATE_PRODUCT_COVERAGE_PARAMETER : UPDATE_POLICY_COVERAGE_PARAMETER,
    {
      onCompleted,
      onError,
      refetchQueries: [
        {
          query: isProduct ? GET_PRODUCT_COVERAGE_PARAMETERS : GET_POLICY_COVERAGE_PARAMETERS,
          variables: { id: isProduct ? productId : policyId }
        }
      ]
    }
  );

  const { typesNeedingValueType } = useCoverageParameterTypes();

  function formSubmit(values) {
    const isPredefined = values?.isPredefined;
    const needsValueType = typesNeedingValueType.includes(values?.type?.value);

    const sharedInput = {
      name: isPredefined ? values?.predefinedCoverageParameter?.value : values?.name,
      type: needsValueType ? values?.type?.value : 'INDIVIDUAL',
      valueType: needsValueType ? values?.valueType?.value : values?.type?.value,
      predefined: isPredefined,
      ...(isPredefined ? { validate: values?.validate } : { validate: false }),
      ...(isProduct ? { productId } : { policyId })
    };

    if (isCreateMode) {
      create({
        variables: sharedInput
      });
    } else {
      update({
        variables: {
          id: coverageParameter?.id,
          ...sharedInput
        }
      });
    }
  }

  return {
    formSubmit,
    creating,
    createError,
    updating,
    updateError
  };
}
