import { useLazyQuery, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import { useCallback, useState } from 'react';
import { GET_ELECTION_DOCUMENTS } from '../../document/model';

const CREATE_UPLOADABLE = gql`
  mutation CreateDocumentUploadable($input: CreateDocumentUploadableInput!) {
    uploadable: createDocumentUploadable(input: $input) {
      id
      url
      headers
      name
      filename
    }
  }
`;

export default function useDocumentUpload({ electionId, onUploadStarted, onUploadCompleted } = {}) {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [createUploadable, { loading, error, reset }] = useMutation(CREATE_UPLOADABLE);

  const [refreshDocuments, { loading: refreshing }] = useLazyQuery(GET_ELECTION_DOCUMENTS, {
    fetchPolicy: 'network-only',
    onCompleted: onUploadCompleted
  });

  const uploadFile = useCallback(
    (file, { url, headers }) => {
      setUploading(true);

      return new Promise(resolve => {
        const request = new XMLHttpRequest();
        request.open('PUT', url);

        for (const header in headers) {
          request.setRequestHeader(header, headers[header]);
        }

        request.upload.addEventListener('progress', ({ loaded, total }) => {
          setProgress((loaded / total) * 100);
        });

        request.addEventListener('load', () => {
          setUploading(false);
          refreshDocuments({ variables: { id: electionId } });
          resolve();
        });

        request.send(file);
      });
    },
    [electionId, refreshDocuments]
  );

  return {
    uploading: uploading || loading || refreshing,
    progress,
    error,
    reset,
    uploadFile: async (file, { name, type }) => {
      const { type: contentType, name: filename } = file;
      const input = { contentType, filename, name, type, electionId };
      const { data } = await createUploadable({ variables: { input } });
      onUploadStarted && onUploadStarted(data.uploadable);

      if (data) {
        return uploadFile(file, data.uploadable);
      }
    }
  };
}
