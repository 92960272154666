export const INVOICING_SCHEDULE = {
  ADVANCE: 'ADVANCE',
  ARREARS: 'ARREARS'
};

export default function useInvoicingScheduleOptions() {
  const options = [
    {
      label: 'Current Period (Advance)',
      value: INVOICING_SCHEDULE.ADVANCE
    },
    {
      label: 'Previous Period (Arrears)',
      value: INVOICING_SCHEDULE.ARREARS
    }
  ];

  return { options, INVOICING_SCHEDULE };
}
