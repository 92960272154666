import React from 'react';
import { Loader } from '@oforce/global-components';

import { GraphQLErrors, Placeholder, Render } from '../../../components';
import { BoxContainer } from '../../../components';

import { CoverageParameters, StateFees } from '.';

export default function InsuranceTypesList({ insuranceTypes, loading, error }) {
  const totalColCount = 4;

  return (
    <>
      <GraphQLErrors colSpan={totalColCount} error={error} />
      <Render if={loading}>
        <Loader colSpan={totalColCount} />
      </Render>
      <Render if={!loading && !error && (!insuranceTypes || insuranceTypes?.length === 0)}>
        <Placeholder colSpan={totalColCount}>No insurance types</Placeholder>
      </Render>
      <Render if={!loading && !error && insuranceTypes?.length > 0}>
        <div>
          {insuranceTypes?.map(type => (
            <InsuranceTypeRow key={type?.id} type={type} />
          ))}
        </div>
      </Render>
    </>
  );
}

const InsuranceTypeRow = ({ type }) => {
  const coverageParams = type?.premiumFactors?.coverageParameters;
  const stateFees = type?.stateFees;
  const insuranceTypeId = type?.id;
  const isVehicleType = type?.type === 'VEHICLE';

  return (
    <BoxContainer className="p-3 mb-3">
      <div className="h6">{type?.name}</div>

      <CoverageParameters coverageParams={coverageParams} />
      {isVehicleType && <StateFees stateFees={stateFees} insuranceTypeId={insuranceTypeId} />}
    </BoxContainer>
  );
};
