import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';

export const GET_PRODUCT_ADD_ONS = gql`
  query product($id: ID!) {
    product(id: $id) {
      id
      addOns {
        id
        cost
        label
        name
        appliesToAllPricingOptions
        pricingOptionIds
        requiredOnAllPricingOptions
        requiredForPricingOptionIds
      }
    }
  }
`;

export default function useProductAddOns({ productId }) {
  const { data, loading, error } = useQuery(GET_PRODUCT_ADD_ONS, {
    variables: { id: productId },
    fetchPolicy: 'cache-and-network'
  });

  return { addOns: data?.product?.addOns, loading, error };
}
