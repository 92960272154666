import { useFormOptions } from '../../../hooks';
import { usePolicyInvoiceEmailAddresses } from '../../../views/policy/hooks';

export const INTERVAL_TYPES = {
  MONTHLY: {
    SPECIFIC_MONTH: 'SPECIFIC_MONTH',
    MONTH_RANGE: 'MONTH_RANGE'
  },
  WEEKLY: {
    SPECIFIC_WEEK: 'SPECIFIC_WEEK',
    WEEK_RANGE: 'WEEK_RANGE'
  }
};

export default function useGenerateInvoiceFormOptions({ policyId }) {
  const { options: emailAddressesOptions, loading: gettingEmailAddressesOptions } = usePolicyInvoiceEmailAddresses({
    policyId
  });

  const formOptions = useFormOptions({
    options: [
      { name: 'invoiceEmailAddresses', options: emailAddressesOptions, loading: gettingEmailAddressesOptions },
      {
        name: 'weeklyIntervalType',
        options: [
          { value: INTERVAL_TYPES.WEEKLY.SPECIFIC_WEEK, label: 'A specific  week' },
          { value: INTERVAL_TYPES.WEEKLY.WEEK_RANGE, label: 'Week range' }
        ]
      },
      {
        name: 'monthlyIntervalType',
        options: [
          { value: INTERVAL_TYPES.MONTHLY.SPECIFIC_MONTH, label: 'A specific month' },
          { value: INTERVAL_TYPES.MONTHLY.MONTH_RANGE, label: 'Month range' }
        ]
      }
    ]
  });

  return { formOptions };
}
