import React from 'react';
import { Tooltip } from 'reactstrap';
import useCopyToClipboard from '../hooks/useCopyToClipboard';
import { Badge } from '@oforce/global-components';

export default function CopyableBadge({ text, ...rest }) {
  const { copyToClipboard, copied } = useCopyToClipboard();

  return (
    <>
      <Badge {...rest}>
        <span id={`copied-${text}`} onClick={() => copyToClipboard(text)}>
          {text}
        </span>
      </Badge>
      <Tooltip target={`copied-${text}`} placement="bottom" isOpen={copied} toggle={() => {}}>
        Copied
      </Tooltip>
    </>
  );
}
