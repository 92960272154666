import { useCanadianProvinces, useUSStates } from '../../../../hooks';
import { formatCurrency, formatDate } from '../../../../utils/formatting';

export default function useUiValues() {
  const { options: stateOptions } = useUSStates();
  const { options: provinceOptions } = useCanadianProvinces();
  const options = [...stateOptions, ...provinceOptions];

  const prepareParameterValueForUi = ({ type, value, valueType }) => {
    switch (type) {
      case 'RANGE':
        return prepForRangeUI({ value, valueType });

      case 'LIST':
        return prepForListUI({ valueType, value });

      case 'INDIVIDUAL':
        switch (valueType) {
          case 'CURRENCY':
            return prepForCurrencyUI(value);

          case 'STATE':
            return prepForStateUI(value);

          case 'INTEGER':
            return prepForIntegerUI(value);

          case 'BOOLEAN':
            return prepForBooleanUI(value);

          case 'DATE':
            return prepForDateUI(value);

          default:
            return value || '';
        }

      default:
        return value || '';
    }
  };

  const prepForDateUI = value => {
    if (!value) return '';
    return formatDate(value);
  };

  const prepForBooleanUI = value => {
    if (value === true) return 'True';
    if (value === false) return 'False';
    return value;
  };

  const prepForIntegerUI = value => {
    if (!value) return '';
    return value;
  };

  const prepForStateUI = state => {
    if (!state) return '';
    const stateOption = options.find(({ value }) => value === state);
    return stateOption ? stateOption.label : '';
  };

  const prepForCurrencyUI = value => {
    if (!value) return '';
    return formatCurrency(value);
  };

  const prepForListUI = ({ value, valueType }) => {
    const values = (value && JSON.parse(value)) || [];
    if (Array.isArray(values)) {
      switch (valueType) {
        case 'DATE':
          return values
            .map(x => {
              if (!x) return '';
              return formatDate(x);
            })
            .join(', ');

        case 'STATE':
          return values
            .map(x => {
              if (!x) return '';
              const option = options.find(({ value }) => value === x);
              if (option) return option.label;
              return '';
            })
            .join(', ');

        case 'CURRENCY':
          return values
            .map(x => {
              return formatCurrency(x);
            })
            .join(', ');

        case 'INTEGER':
          return values.join(', ');

        case 'STRING':
          return values.join(', ');

        default:
          return value || '';
      }
    }
    return '';
  };

  const prepForRangeUI = ({ value, valueType }) => {
    const { min, max } = JSON.parse(value);

    switch (valueType) {
      case 'DATE':
        if (min && max) {
          if (min === max) {
            return `${formatDate(min)}`;
          } else {
            return `between ${formatDate(min)} and ${formatDate(max)}`;
          }
        } else if (!min && max) {
          return `before ${formatDate(max)}`;
        } else if (!max && min) {
          return `after ${formatDate(min)}`;
        } else if (!min && !max) {
          return 'None';
        }
        break;

      case 'CURRENCY':
        if (min && max) {
          if (min === max) {
            return `${formatCurrency(min)}`;
          } else {
            return `between ${formatCurrency(min)} and ${formatCurrency(max)}`;
          }
        } else if (!min && max) {
          return `less than or equal to ${formatCurrency(max)}`;
        } else if (!max && min) {
          return `greater than or equal to ${formatCurrency(min)}`;
        } else if (!min && !max) {
          return 'None';
        }
        break;

      default:
        if (min && max) {
          if (min === max) {
            return `${min}`;
          } else {
            return `between ${min} and ${max}`;
          }
        } else if (!min && max) {
          return `less than or equal to ${max}`;
        } else if (!max && min) {
          return `greater than or equal to ${min}`;
        } else if (!min && !max) {
          return 'None';
        }
        break;
    }
  };

  return {
    prepareParameterValueForUi
  };
}
