import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { VALIDATED_ROW_FIELDS } from '../model/constants';

const VALIDATE_ROW = gql`
  mutation ValidateRow($row: Json!, $masterContractRow: Json!, $requiredFields: [String]!) {
    validateExposureImportRow(row: $row, masterContractRow: $masterContractRow, requiredFields: $requiredFields) {
      ...validatedRowFields
    }
  }
  ${VALIDATED_ROW_FIELDS}
`;

export default function useRowValidation({ onCompleted = () => {}, onError = () => {} }) {
  const [loadingRowId, setLoadingRowId] = useState(null);

  const [validateRow, { loading, error }] = useMutation(VALIDATE_ROW, {
    onCompleted: ({ validateExposureImportRow }) => {
      onCompleted && onCompleted(validateExposureImportRow);
      setLoadingRowId(null);
    },
    onError
  });

  const validateSingleRow = (row, rows = [], validatedRows = []) => {
    const masterContractRow = rows?.find(
      masterRow => row?.masterId === masterRow?.masterId && masterRow?.contractType === 'master'
    );

    const masterContractRowValidated = validatedRows?.find(
      masterRow => row?.masterId === masterRow?.masterId && masterRow?.contractType === 'MASTER'
    );

    const masterRow = !!masterContractRowValidated ? masterContractRowValidated : masterContractRow;

    setLoadingRowId(row?.rowId);

    const variables = {
      requiredFields: row?.requiredFields,
      row: JSON.stringify(row),
      masterContractRow: masterRow ? JSON.stringify(masterRow) : '{}'
    };

    validateRow({ variables });
  };

  return {
    validateRow: validateSingleRow,
    loading: loading && loadingRowId,
    error
  };
}
