import React from 'react';
import { gql } from '@apollo/client';
import { InsuranceTypesList } from './components';
import { useInsuranceTypes } from './hooks';

export const GET_INSURANCE_TYPE = gql`
  query insuranceType($id: ID!) {
    insuranceType(id: $id) {
      id
      name
    }
  }
`;

export default function InsuranceTypes() {
  const { insuranceTypes, loading, error } = useInsuranceTypes();

  return <InsuranceTypesList insuranceTypes={insuranceTypes} loading={loading} error={error} />;
}

export { INSURANCE_TYPES, INSURANCE_TYPE_ASSIGNED } from './constants';
export { GET_INSURANCE_TYPES } from './hooks/useInsuranceTypes';
export { useInsuranceTypes } from './hooks';
