export const UPDATE_START_DATE = 'UPDATE_START_DATE';
export const ADD_TERM_DATE = 'ADD_TERM_DATE';
export const CHANGE_PRICING_OPTION = 'CHANGE_PRICING_OPTION';
export const CLEAR_TERM_DATE = 'CLEAR_TERM_DATE';
export const OVERRIDE = 'OVERRIDE';
export const REACTIVATE = 'REACTIVATE';
export const REJECT = 'REJECT';
export const UNREJECT = 'UNREJECT';
export const UPDATE_ELECTION_DATA = 'UPDATE_ELECTION_DATA';
export const UPDATE_CONTRACT_TYPE = 'UPDATE_CONTRACT_TYPE';
