import { gql, useMutation } from '@apollo/client';
import { GET_POLICY } from '../../policy/hooks/usePolicy';

export const BACKDATE_INVOICES = gql`
  mutation backdateInvoices($policyId: ID!) {
    backdateInvoices(policyId: $policyId) {
      id
      invoiceCount
      nextInvoiceServiceDates {
        generateAt
      }
    }
  }
`;

export default function useBackdateInvoices({ onCompleted = () => {}, policyId }) {
  const [backdateInvoices, { loading, error }] = useMutation(BACKDATE_INVOICES, {
    variables: { policyId },
    onCompleted,
    onError: () => {},
    refetchQueries: [{ query: GET_POLICY, variables: { id: policyId } }]
  });

  return { backdateInvoices, loading, error };
}
