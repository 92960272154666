import React from 'react';
import { Formik } from 'formik';
import { Modal } from '@oforce/global-components';
import { gql, useMutation } from '@apollo/client';

import { GraphQLErrors } from '../../../components';
import { formatCurrency } from '../../../utils/formatting';
import { convertDecimalToPercentage, convertPercentageToDecimal, convertToFloat } from '../../../utils';
import {
  useFeeRateModes,
  usePolicyFeeOptions,
  usePolicyPricingOptions
} from '../../../components/sharedComponents/pricing';

import { FeeForm } from '.';
import { useFeeValidationSchema, useFeeAppliesToOptions } from '../hooks';

import { useAccountTags } from '../../account/hooks';

import { GET_POLICY_PRICINGS } from '../../policyPricing/hooks/usePolicyPricings';
import { GET_ACCOUNT_TAGS } from '../../account/hooks/useAccountTags';
import { useActiveAccount } from '../../../shared/activeAccount';

const EDIT_POLICY_FEE = gql`
  mutation EditPolicyFee($input: EditPolicyFeeInput!) {
    editPolicyFee(input: $input) {
      id
      name
      rateMode
      rateModeType
      rate
      tags
      appliesToAllPricingOptions
      policyPricingIds
      multiplierFeeIds
      prorated
    }
  }
`;

export default function FeeEdit({ isOpen, toggle, policy, fee, onError = () => {} }) {
  const { id: activeAccountId } = useActiveAccount();

  const [editFee, { loading, error, reset }] = useMutation(EDIT_POLICY_FEE, {
    onCompleted: toggle,
    onError,
    refetchQueries: [
      { query: GET_ACCOUNT_TAGS, variables: { accountId: policy?.product?.accountId } },
      { query: GET_POLICY_PRICINGS, variables: { id: policy?.id } }
    ]
  });

  const { feeRateModeOptions: rateModeOptions } = useFeeRateModes();
  const { feeAppliesToOptions } = useFeeAppliesToOptions();
  const { tagOptions } = useAccountTags({ accountId: activeAccountId });
  const { selectOptions: policyPricingOptions } = usePolicyPricingOptions({ policy });
  const { selectOptions: feeOptions } = usePolicyFeeOptions({ policyId: policy?.id });

  const validationSchema = useFeeValidationSchema();

  const rateMode =
    fee?.rateModeType === 'PREMIUM_PLUS'
      ? rateModeOptions?.find(({ value }) => value === 'PREMIUM_PLUS')
      : rateModeOptions?.find(({ value }) => value === fee?.rateMode);

  const initialValues = {
    name: fee?.name || '',
    rate: fee?.rateMode !== 'FLAT' ? convertDecimalToPercentage(fee?.rate) : formatCurrency(fee?.rate),
    rateMode,
    appliesToAllPricingOptions: feeAppliesToOptions.find(x => x.value === fee?.appliesToAllPricingOptions),
    tags: tagOptions.filter(tag => fee?.tags?.includes(tag.value)),
    policyPricings: policyPricingOptions?.filter(x => fee?.policyPricingIds?.includes(x.value)),
    prorated: fee?.prorated,
    multipliers: feeOptions?.filter(x => fee?.multiplierFeeIds?.includes(x.value))
  };

  const handleSubmit = ({
    name,
    rate,
    rateMode,
    appliesToAllPricingOptions,
    policyPricings,
    multipliers,
    tags,
    prorated
  }) => {
    const rateModeValue = rateMode.value === 'PREMIUM_PLUS' ? 'PERCENTAGE' : rateMode.value;
    const rateModeType = rateMode.value === 'PREMIUM_PLUS' ? 'PREMIUM_PLUS' : 'PREMIUM';

    editFee({
      variables: {
        input: {
          policyId: policy?.id,
          feeId: fee?.id,
          name,
          rateModeType,
          rate: rateModeValue === 'PERCENTAGE' ? convertPercentageToDecimal(rate) : convertToFloat(rate),
          rateMode: rateModeValue,
          appliesToAllPricingOptions: appliesToAllPricingOptions.value,
          policyPricingIds: policyPricings?.map(pricing => pricing?.value),
          tags: tags?.map(tag => tag?.value),
          multiplierFeeIds: multipliers?.map(fee => fee?.value),
          prorated: prorated
        }
      }
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {formik => (
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          title="Edit Fee"
          onSubmit={formik.handleSubmit}
          submitButtonDisabled={formik.isSubmitting}
          submitButtonProps={{ loadingText: 'Saving...' }}
          submitButtonText="Save Changes"
          loading={loading}
          onOpened={() => {
            reset();
            formik.handleReset();
          }}
          onCancel={reset}
        >
          <GraphQLErrors error={error} />
          <FeeForm fee={fee} policy={policy} formik={formik} />
        </Modal>
      )}
    </Formik>
  );
}
