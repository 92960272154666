import React from 'react';
import { Button } from '@oforce/global-components';

export default function TablePagination({ loading = false, pageInfo, loadPreviousPage, loadNextPage }) {
  return (
    <div className="d-flex justify-content-between mt-3">
      <Button
        size="sm"
        color="link"
        icon={['far', 'long-arrow-left']}
        disabled={!pageInfo?.hasPreviousPage || loading}
        onClick={loadPreviousPage}
      >
        Previous
      </Button>

      <Button
        size="sm"
        color="link"
        iconAfter={['far', 'long-arrow-right']}
        disabled={!pageInfo?.hasNextPage || loading}
        onClick={loadNextPage}
      >
        Next
      </Button>
    </div>
  );
}
