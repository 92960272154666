import React from 'react';
import { Render } from '@oforce/global-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import parsePhoneNumberFromString from 'libphonenumber-js';

export default function ContactInfoCell({ mobilePhone, email, breakLines = false, className }) {
  const phoneNumber = parsePhoneNumberFromString(mobilePhone || '', 'US');
  const hasPhone = phoneNumber?.isValid() || false;

  return (
    <div className={className}>
      <Render if={!!email}>
        <Link href={`mailto:${email}`} icon={['fas', 'envelope']}>
          {email}
        </Link>
      </Render>

      <Render if={breakLines && hasPhone && !!email}>
        <br></br>
      </Render>
      <Render if={!breakLines && hasPhone && !!email}>
        <Separator />
      </Render>

      <Render if={hasPhone}>
        <Link href={phoneNumber?.getURI()} icon={['fas', 'phone']}>
          {phoneNumber?.format('NATIONAL', { nationalPrefix: false })}
        </Link>
      </Render>

      <Render if={!hasPhone && !email}>
        <span className="text-gray-500">No Contact Info</span>
      </Render>
    </div>
  );
}

const Separator = () => <span className="px-1 text-gray-400">•</span>;

const Link = ({ children, href, icon }) => (
  <a href={href} rel="noopener noreferrer" className="fs-xs d-inline-flex align-items-center">
    <FontAwesomeIcon icon={icon} size="xs" className="mr-1" />
    {children}
  </a>
);
