import { gql } from '@apollo/client';
import { useSubscription } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';

import { PolicyRenewalJobContext } from './context';
import { useResettableMutation } from '../../hooks/useResettableMutation';
import { GET_POLICIES } from '../../views/policies/hooks/usePolicies';

const START_JOB = gql`
  mutation startRenewalJob($policyId: ID!, $exposureElectionIds: [ID]) {
    renewPolicy(policyId: $policyId, exposureElectionIds: $exposureElectionIds) {
      id
      jobId
    }
  }
`;

const ON_POLICY_RENEWAL_JOB_PROGRESS = gql`
  subscription jobProgress($jobId: ID!) {
    job: policyRenewalJobProgress(jobId: $jobId) {
      jobId
      renewedPolicyId
      finished
      message
      errors
    }
  }
`;

export default function PolicyRenewalJobProvider({ children }) {
  const [jobId, setJobId] = useState(null);
  const [renewedPolicyId, setRenewedPolicyId] = useState(null);
  const [jobErrors, setJobErrors] = useState([]);
  const [finished, setJobFinished] = useState(true);
  const [message, setMessage] = useState(null);

  const clearJobId = useCallback(() => {
    setJobId(null);
    setJobErrors([]);
  }, []);

  useEffect(() => {
    if (!!jobId && !!finished) {
      setTimeout(() => clearJobId(), 10000);
    }
  }, [finished, clearJobId, jobId]);

  const [startRenewalJob, { loading, error, reset }] = useResettableMutation(START_JOB, {
    onCompleted: data => {
      setJobFinished(false);
      setJobId(data?.renewPolicy?.jobId);
      setRenewedPolicyId(data?.renewPolicy?.id);
    },
    refetchQueries: [{ query: GET_POLICIES, variables: { searchBy: [], first: 20, last: null, before: null } }],
    onError: () => setTimeout(() => reset(), 10000)
  });

  const startPolicyRenewalJob = data => {
    setJobFinished(false);
    startRenewalJob({ variables: { policyId: data?.policyId, exposureElectionIds: data?.exposureElectionIds } });
  };

  useSubscription(ON_POLICY_RENEWAL_JOB_PROGRESS, {
    skip: !jobId,
    variables: { jobId },
    onSubscriptionData: ({ client, subscriptionData: { data } }) => {
      const job = data?.job;
      const { message, finished, errors } = job;

      setJobErrors(errors);
      setJobFinished(finished);
      setMessage(message);

      if (errors.length !== 0) {
        client.refetchQueries({
          include: [{ query: GET_POLICIES, variables: { searchBy: [], first: 20, last: null, before: null } }]
        });
      }
    }
  });

  return (
    <PolicyRenewalJobContext.Provider
      value={{
        startPolicyRenewalJob,
        jobId,
        renewedPolicyId,
        clearJobId,
        loading,
        error,
        finished,
        message,
        jobErrors
      }}
    >
      {children}
    </PolicyRenewalJobContext.Provider>
  );
}
