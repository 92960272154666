import { FieldLabel, RangePicker } from '@oforce/global-components';
import React from 'react';
import { Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function InvoiceFilters({ setDateFilter }) {
  return (
    <Col md="6" xl="3" xs="12" className="mb-3 mr-0 pr-md-3">
      <FieldLabel name="Filter By Date" />
      <RangePicker
        format="MM/DD/YYYY"
        onChange={setDateFilter}
        separator={<FontAwesomeIcon icon={['far', 'long-arrow-alt-right']} className="text-gray-500 ml-0" />}
      />
    </Col>
  );
}
