import * as yup from 'yup';

export default function useYupValidation() {
  const coverageParametersYupValidation = ({ coverageParameters }) => {
    return coverageParameters?.reduce((acc, param) => {
      return Object.assign({}, acc, { [param.id]: parameterYupValidation(param) });
    }, {});
  };

  const parameterYupValidation = ({ type, valueType }) => {
    switch (type) {
      case 'RANGE':
        return rangeValidation({ valueType });

      case 'LIST':
        return listValidation({ valueType });

      case 'INDIVIDUAL':
        switch (valueType) {
          case 'CURRENCY':
            return currencyValidation();

          case 'STATE':
            return stateValidation();

          case 'INTEGER':
            return integerValidation();

          case 'BOOLEAN':
            return booleanValidation();

          case 'DATE':
            return dateValidation();

          case 'STRING':
            return stringValidation();

          default:
            break;
        }
        break;

      default:
        break;
    }
  };

  const currencyValidation = () => yup.string().nullable();
  const stateValidation = () => yup.object({ label: yup.string(), value: yup.string() }).nullable();
  const integerValidation = () => yup.number().typeError(`Expecting number`).nullable();
  const booleanValidation = () => yup.object({ label: yup.string(), value: yup.string() }).nullable();
  const dateValidation = () => yup.date().typeError(`Expecting valid date`).nullable();
  const stringValidation = () => yup.string().nullable();

  const listValidation = ({ valueType }) => {
    switch (valueType) {
      case 'INTEGER':
        return yup
          .array()
          .of(yup.object({ label: yup.string(), value: yup.number().typeError(`Expecting numbers only`) }))
          .nullable();

      case 'DATE':
        return yup.array().of(yup.date().typeError('Must be a valid date')).nullable();

      case 'CURRENCY':
        return yup
          .array()
          .of(yup.object({ label: yup.string(), value: yup.number().typeError(`Expecting a currency value`) }))
          .nullable();

      case 'STRING':
        return yup
          .array()
          .of(yup.object({ label: yup.string(), value: yup.string() }))
          .nullable();

      case 'STATE':
        return yup
          .array()
          .of(yup.object({ label: yup.string(), value: yup.string() }))
          .nullable();

      default:
        return yup
          .array()
          .of(yup.object({ label: yup.string(), value: yup.string() }))
          .nullable();
    }
  };

  const rangeValidation = ({ valueType }) => {
    switch (valueType) {
      case 'INTEGER':
        return yup
          .object({
            min: yup.number().nullable().typeError(`Expecting number`),
            max: yup.number().nullable().typeError(`Expecting number`)
          })
          .nullable();

      case 'DATE':
        return yup.array().of(yup.date()).nullable();

      case 'CURRENCY':
        return yup
          .object({
            min: yup.number().nullable().typeError(`Expecting a currency value`),
            max: yup.number().nullable().typeError(`Expecting a currency value`)
          })
          .nullable();

      default:
        return yup.object({ min: yup.string(), max: yup.string() }).nullable();
    }
  };

  return { coverageParametersYupValidation };
}
