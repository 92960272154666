import { gql, useQuery } from '@apollo/client';
import { useProductPricingOptions } from '../../../components/sharedComponents/pricing';
import { useCanadianProvinces, useFormOptions, useUSStates } from '../../../hooks';
import { backendNameToLabel } from '../../../utils/formatting';

const SELECT_OPTIONS = gql`
  query getStateSource {
    stateSource: documentStateSources
  }
`;

export default function useProductDocumentFormOptions({ productId }) {
  const { data, loading: loadingStateSourceOptions, error: errorStateSourceOptions } = useQuery(SELECT_OPTIONS);

  const allStateSourceOptions = data?.stateSource?.map(name => ({
    label: backendNameToLabel(name),
    value: name
  }));

  const documentTypeOptions = [
    { value: 'GENERAL', label: 'General' },
    { value: 'APPLICATION', label: 'Application' },
    { value: 'CERTIFICATE_OF_INSURANCE', label: 'Certificate of Insurance' }
  ];

  const documentAppliesToOptions = [
    {
      label: 'All Pricing Options',
      value: true
    },
    {
      label: 'Selected Pricing Options',
      value: false
    }
  ];

  const allOrSelectedOptions = [
    {
      label: 'All US States',
      value: 'ALL'
    },
    {
      label: 'Selected States',
      value: 'SELECTED'
    }
  ];

  const { options: usStateOptions } = useUSStates({ includeUSTerritories: true });
  const { options: canadianProvinceOptions } = useCanadianProvinces();
  const statesOptions = [...usStateOptions, ...canadianProvinceOptions];

  const {
    selectOptions: options,
    loading,
    error
  } = useProductPricingOptions({
    productId
  });

  const formOptions = useFormOptions({
    options: [
      { name: 'documentType', options: documentTypeOptions },
      { name: 'documentAppliesTo', options: documentAppliesToOptions },
      { name: 'productPricing', options, loading, error },
      { name: 'allOrSelected', options: allOrSelectedOptions },
      { name: 'states', options: statesOptions },
      { name: 'stateSource', options: allStateSourceOptions, loadingStateSourceOptions, errorStateSourceOptions }
    ]
  });

  return {
    formOptions
  };
}
