import { gql, useMutation } from '@apollo/client';

const SYNC_NOTIFICATIONS = gql`
  mutation sync($jobId: ID!) {
    syncNotifications(jobId: $jobId) {
      id
      notificationId
      accountId
      policyId
      insuredId
      vehicleId
      created
      failureReason
      jobId
      status
      subject
      templateGroup
    }
  }
`;

export default function useSyncNotifications({ onCompleted = () => {}, onError = () => {} }) {
  const [sync, { loading, error }] = useMutation(SYNC_NOTIFICATIONS, {
    onCompleted,
    onError
  });

  return {
    syncNotifications: jobId => sync({ variables: { jobId } }),
    loading,
    error
  };
}
