import { useEffect } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import download from 'js-file-download';

const GET_INVOICE_PDF = gql`
  query generateInvoicePdf($invoiceId: ID!) {
    invoicePdf(invoiceId: $invoiceId) {
      filename
      url
    }
  }
`;

function downloadFile({ url, filename }) {
  fetch(url)
    .then(response => response.blob())
    .then(file => download(file, filename));
}

export default function useDownloadInvoicePdf({ id: invoiceId }) {
  const [generatePdf, { data, loading, error }] = useLazyQuery(GET_INVOICE_PDF, {
    variables: { invoiceId },
    fetchPolicy: 'network-only',
    skip: !invoiceId,
    onError: () => {}
  });

  useEffect(() => {
    if (!error && !!data) {
      downloadFile(data?.invoicePdf);
    }
  }, [error, data]);

  return {
    generateInvoicePdf: generatePdf,
    error,
    loading
  };
}
