import buildValidationSchema from './validationSchema';
import initialValues from './initialValues';
import * as yup from 'yup';

export default function step(vehicle, requiredFields, formOptions) {
  const additionalInsuredValidationSchema = buildValidationSchema({
    onlyFields: true,
    stakeholderType: 'additionalInsured',
    requiredFields: requiredFields
  });
  const lossPayeeValidationSchema = buildValidationSchema({
    onlyFields: true,
    stakeholderType: 'lossPayee',
    requiredFields: requiredFields
  });
  const certHolderValidationSchema = buildValidationSchema({
    onlyFields: true,
    stakeholderType: 'certHolder',
    requiredFields: requiredFields
  });
  const lienholderValidationSchema = buildValidationSchema({
    onlyFields: true,
    stakeholderType: 'lienholder',
    requiredFields: requiredFields
  });

  var validationSchema = yup.object({
    ...additionalInsuredValidationSchema,
    ...lossPayeeValidationSchema,
    ...certHolderValidationSchema,
    ...lienholderValidationSchema
  });

  return {
    name: 'Vehicle Stakeholders',
    validationSchema: validationSchema,
    initialValues: {
      ...initialValues('additionalInsured', vehicle?.additionalInsured, formOptions),
      ...initialValues('lossPayee', vehicle?.lossPayee, formOptions),
      ...initialValues('certHolder', vehicle?.certHolder, formOptions),
      ...initialValues('lienholder', vehicle?.lienholder, formOptions)
    }
  };
}
