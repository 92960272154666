import React from 'react';
import { Badge, useToggle } from '@oforce/global-components';

import { ActionDropdown } from '../../../components/table-2.0';
import { useProductPricings } from '../../productPricing/hooks';
import { backendNameToLabel } from '../../../utils/formatting';

import { CreateUpdate, Remove } from '../../productRetailCommissions/containers';
import { RetailCommissionPricingOptionsTooltip } from '../../../components/sharedComponents/components';
import { HideFromRoles, ShowForTypes, useActiveAccount } from '../../../shared/activeAccount';
import { formatPricing } from '../../exposureElections/utils';

export default function RetailCommissionsTableRow({ productId, retailCommission }) {
  const {
    TYPES: { CARRIER },
    ROLES: { EXPOSURE_MANAGER }
  } = useActiveAccount();
  const [showEdit, toggleEdit] = useToggle();
  const [showRemove, toggleRemove] = useToggle();
  const { pricings: pricingOptions } = useProductPricings({ productId });

  const pricingOptionsBadgeText = retailCommission?.appliesToAllPricing
    ? `All Pricing Options (${pricingOptions?.length})`
    : `Some Pricing Options (${retailCommission?.pricingOptionIds?.length})`;

  return (
    <>
      <tr>
        <td>{retailCommission?.name}</td>
        <td>
          <div className="font-weight-500">{backendNameToLabel(retailCommission?.rateMode)}</div>
          <div className="font-weight-300 fs-sm text-muted">
            {formatPricing({
              rate: retailCommission?.rate,
              rateMode: retailCommission?.rateMode,
              isPercentageFactor: true
            })}
          </div>
        </td>
        <td>
          <Badge
            size="sm"
            id={`a-${retailCommission?.id}`}
            color="secondary"
            className="cursor-pointer"
            iconAfter={['far', 'info-circle']}
          >
            {pricingOptionsBadgeText}
          </Badge>
          <RetailCommissionPricingOptionsTooltip pricingOptions={pricingOptions} retailCommission={retailCommission} />
        </td>
        <ShowForTypes types={[CARRIER]}>
          <HideFromRoles roles={[EXPOSURE_MANAGER]}>
            <td align="right">
              <ActionDropdown onEdit={toggleEdit} onRemove={toggleRemove} />
            </td>
          </HideFromRoles>
        </ShowForTypes>
      </tr>

      <CreateUpdate isOpen={showEdit} toggle={toggleEdit} retailCommission={retailCommission} productId={productId} />
      <Remove isOpen={showRemove} toggle={toggleRemove} retailCommission={retailCommission} productId={productId} />
    </>
  );
}
