import React, { useState } from 'react';
import { FilterDropdown, FilterLabel, FiltersContainer } from '../../components';
import { Button } from '@oforce/global-components';
import PendingElectionSearchBy from './PendingElectionSearchBy';

export default function PendingElectionsFilters({ defaults, onChange, filterValue }) {
  const [filters, setFilters] = useState(defaults);

  const orderOptions = [
    { label: 'First Name', value: 'FIRST_NAME' },
    { label: 'Last Name', value: 'LAST_NAME' },
    { label: 'Insured Created', value: 'NEWEST_INSURED' },
    { label: 'Election Created', value: 'NEWEST_ELECTION' },
    { label: 'Insurance Type', value: 'INSURANCE_TYPE' },
    { label: 'Insurance Carrier', value: 'INSURANCE_CARRIER' },
    { label: 'Retail Broker', value: 'RETAIL_BROKER' },
    { label: 'Policy Name', value: 'POLICY_NAME' },
    { label: 'Pending Election Updated', value: 'PENDING_ELECTION_UPDATED' }
  ];

  const updateFilter = values => {
    const newFilters = { ...filters, ...values };
    setFilters(newFilters);
    onChange(newFilters);
  };

  return (
    <div>
      {' '}
      <FiltersContainer className="pb-0 mb-0 pt-3">
        <FilterLabel>Sort by</FilterLabel>
        <ArchivedFilter archived={filters?.archived} onChange={updateFilter} />
        <ClaimedFitler claimed={filters?.claimed} onChange={updateFilter} />
        <WithExternalIdsOnlyFitler withExternalId={filters?.withExternalId} onChange={updateFilter} />
        <FilterDropdown
          id="ordering"
          className="mr-3"
          headerText="Sort ascending..."
          icon={['far', 'sort-alpha-down']}
          options={orderOptions}
          defaultOption={orderOptions.find(({ value }) => value === filterValue?.ordering)}
          setFilter={option => updateFilter({ ordering: option.value })}
        />
        <PendingElectionSearchBy onChange={updateFilter} />
      </FiltersContainer>
    </div>
  );
}

const ArchivedFilter = ({ archived, onChange }) => {
  return (
    <Button
      color={archived ? 'secondary' : 'info'}
      size="sm"
      className="mr-3 rounded-pill"
      onClick={() => {
        onChange({ archived: !archived });
      }}
    >
      {archived ? 'Archived' : 'Active'}
    </Button>
  );
};

const WithExternalIdsOnlyFitler = ({ withExternalId, onChange }) => {
  return (
    <Button
      color={withExternalId ? 'info' : 'secondary'}
      size="sm"
      className="mr-3 rounded-pill"
      onClick={() => {
        onChange({ withExternalId: !withExternalId });
      }}
    >
      {withExternalId ? 'With External Id' : 'Without External Id'}
    </Button>
  );
};

const ClaimedFitler = ({ claimed, onChange }) => {
  return (
    <Button
      color={claimed ? 'secondary' : 'info'}
      size="sm"
      className="mr-3 rounded-pill"
      onClick={() => {
        onChange({ claimed: !claimed });
      }}
    >
      {claimed ? 'Claimed' : 'Unclaimed'}
    </Button>
  );
};
