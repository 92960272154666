import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { Modal } from '@oforce/global-components';

import { GraphQLErrors } from '../../../components';
import { GET_POLICY } from '../../../views/policy/hooks/usePolicy';

const REMOVE_POLICY_ACCOUNT_ASSIGNMENT = gql`
  mutation RemovePolicyAccountAssignment($id: ID!) {
    removePolicyAccountAssignment(id: $id) {
      id
    }
  }
`;

export default function RemoveActiveAccount({
  isOpen,
  toggle,
  policyAccountAssignmentId,
  policyId,
  onError = () => {}
}) {
  const [removePolicyAccountAssignment, { reset, loading, error }] = useMutation(REMOVE_POLICY_ACCOUNT_ASSIGNMENT, {
    variables: { id: policyAccountAssignmentId },
    onError,
    onCompleted: toggle,
    refetchQueries: [{ query: GET_POLICY, variables: { id: policyId } }]
  });

  return (
    <Modal
      centered
      onOpened={reset}
      onSubmit={removePolicyAccountAssignment}
      isOpen={isOpen}
      toggle={toggle}
      title="Remove Active Account"
      submitButtonProps={{ color: 'danger', loading }}
      submitButtonText="Remove Account"
    >
      <GraphQLErrors error={error} />
      Are you sure you want to remove this account from this policy?
    </Modal>
  );
}
