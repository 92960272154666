import { gql, useMutation } from '@apollo/client';
import { GET_PENDING_ELECTIONS } from './usePendingElections';

const ARCHIVE_PENDING_ELECTION = gql`
  mutation ArchivePendingElection($id: ID!) {
    election: archivePendingElection(pendingElectionId: $id) {
      id
    }
  }
`;

export default function useArchiveElection({ onCompleted = () => {}, onError = () => {}, pendingElectionId }) {
  const [archive, { loading, error }] = useMutation(ARCHIVE_PENDING_ELECTION, {
    variables: { id: pendingElectionId },
    onCompleted,
    refetchQueries: [{ query: GET_PENDING_ELECTIONS }],
    onError
  });

  return {
    archive,
    loading,
    error
  };
}
