import * as yup from 'yup';

export default function useFeeValidationSchema() {
  const validationSchema = yup.object({
    name: yup.string().required('Required'),
    rateMode: yup.object({
      value: yup.string().required('Required'),
      label: yup.string().required('Required')
    }),
    tags: yup.array().of(yup.object()).nullable().default([]),
    rate: yup.string().required('Required'),
    appliesToAllPricingOptions: yup.object({ label: yup.string(), value: yup.boolean() }).required('Required'),
    productPricings: yup
      .array()
      .when('appliesToAllPricingOptions', ({ value }, _schema) =>
        value ? yup.array().nullable() : yup.array().of(yup.object()).required('Required')
      ),
    multipliers: yup
      .array()
      .when('rateMode', ({ value }, _schema) =>
        value === 'PREMIUM_PLUS' ? yup.array().of(yup.object()).required('Required') : yup.array().nullable()
      ),
    prorated: yup.bool()
  });

  return validationSchema;
}
