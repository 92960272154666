import React from 'react';
import { Badge } from '@oforce/global-components';

export default function PolicyInvitationStatusBadge({ status }) {
  const badgeText = {
    CANCELED: 'Canceled',
    CLAIMED: 'Claimed',
    DECLINED: 'Declined',
    PENDING: 'Pending'
  }[status];

  const badgeColor = {
    CANCELED: 'secondary',
    CLAIMED: 'success',
    DECLINED: 'danger',
    PENDING: 'warning'
  }[status];

  return <Badge color={badgeColor}>{badgeText}</Badge>;
}
