import React from 'react';

export default function CoverageParameters({ coverageParams }) {
  return (
    <div className="mb-3">
      <label htmlFor="" className="fs-sm font-weight-500 d-block mb-1">
        Coverage Parameters
      </label>
      {!!coverageParams &&
        Object.keys(coverageParams).map((param, i) => (
          <span key={param} className="px-2 rounded-pill border border-gray-300 d-inline-block mb-1 mr-2 fs-sm">
            {coverageParams[param].friendly_name}
          </span>
        ))}
    </div>
  );
}
