import React from 'react';
import { VehicleFields } from '../../../insuredVehicles/components';

export default function VehicleStep({ disableVinUpdate = false, requiredVehicleFields, ...props }) {
  return (
    <VehicleFields
      disableVinUpdate={disableVinUpdate}
      requiredFields={requiredVehicleFields}
      disabledVehicleFields={props?.formik?.values?.VinApiFields}
      {...props}
    />
  );
}
