import { gql, useQuery } from '@apollo/client';
import { ALL_INSURED_STAKEHOLDER_FIELDS } from '../model/fragments';

export const GET_INSURED_STAKEHOLDERS = gql`
  query getInsuredStakeholders($id: ID!) {
    insured: insured(id: $id) {
      id
      beneficiary {
        ...allInsuredStakeholderFields
      }
    }
  }
  ${ALL_INSURED_STAKEHOLDER_FIELDS}
`;

export default function useInsuredStakeholders({ insuredId }) {
  const { data, loading, error } = useQuery(GET_INSURED_STAKEHOLDERS, {
    variables: { id: insuredId },
    skip: !insuredId,
    fetchPolicy: !insuredId ? 'cache-only' : 'cache-first'
  });

  let stakeholders = [];
  if (data?.insured?.beneficiary) stakeholders.push(data?.insured?.beneficiary);

  return { stakeholders, loading, error };
}
