import React from 'react';
import { Tooltip } from 'reactstrap';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Badge, useToggle } from '@oforce/global-components';
import { useDownloadFile } from '../../../hooks';
import { ActionDropdown } from '../../../components';
import { backendNameToLabel, formatDate } from '../../../utils/formatting';
import { DeleteDocument } from '.';
import { useActiveAccount } from '../../../shared/activeAccount';
import { EmptyCell } from '../../../components/tables';

function policyLabel({ name, effectiveStatus }) {
  if (effectiveStatus === 'ARCHIVED') return name + ' (archived)';
  if (effectiveStatus === 'EXPIRED') return name + ' (expired)';
  else return name;
}

export default function DocumentsTableRow({ clientDocument }) {
  const history = useHistory();
  const { url } = useRouteMatch();
  const {
    ROLES: { ADMIN },
    showForRoles
  } = useActiveAccount();

  const [showDelete, toggleDelete] = useToggle();

  const { policies, name, id, document, effectiveDate, expirationDate } = clientDocument;
  const { url: docUrl, filename, type } = document;

  const [showPoliciesPopover, togglePoliciesPopover] = useToggle();
  const policiesBadgeText = `Applies to Policies (${policies?.length})`;

  const { download, loading } = useDownloadFile({ url: docUrl, filename });

  return (
    <>
      <tr>
        <td>
          <div className="font-weight-500">{name}</div>
          <div className="font-weight-300 fs-sm text-muted">{backendNameToLabel(type)} • PDF</div>
        </td>
        <td>
          <div className="font-weight-400">{formatDate(effectiveDate)}</div>
        </td>
        <td>
          {!!expirationDate ? <div className="font-weight-400">{formatDate(expirationDate)}</div> : <EmptyCell />}
        </td>
        <td>{filename}</td>
        <td>
          <Badge
            id={`a-${id}`}
            color="secondary"
            size="sm"
            className="cursor-pointer"
            iconAfter={['far', 'info-circle']}
          >
            {policiesBadgeText}
          </Badge>
          <Tooltip target={`a-${id}`} placement="bottom" isOpen={showPoliciesPopover} toggle={togglePoliciesPopover}>
            {policies?.map(pp => (
              <div key={pp?.id}>{policyLabel(pp)}</div>
            ))}
          </Tooltip>
        </td>
        <td align="right">
          <>
            <ActionDropdown
              onEdit={() => history.push(`${url}/${id}/update`)}
              onDownload={download}
              downloading={loading}
              onHardDelete={showForRoles([ADMIN]) && toggleDelete}
            />
          </>
        </td>
      </tr>
      {showDelete && <DeleteDocument isOpen={showDelete} toggle={toggleDelete} doc={clientDocument} />}
    </>
  );
}
