import * as yup from 'yup';

export default function buildValidationSchema({ requiredFields = {}, onlyFields = false }) {
  const requireField = fieldName => (_value, schema) => {
    return requiredFields[fieldName] ? schema.required('Required') : schema;
  };

  let fields = {
    address: yup.string().when('$address', requireField('address')),
    city: yup.string().when('$city', requireField('city')),
    businessName: yup.string().when('$businessName', requireField('business_name')),
    businessName2: yup.string().when('$businessName2', requireField('business_name2')),
    country: yup.string().when('$country', requireField('country')).nullable(),
    dateOfBirth: yup.string().when('$dateOfBirth', requireField('date_of_birth')),
    dispatchLocation: yup.string().when('$dispatchLocation', requireField('dispatch_location')),
    email: yup.string().when('$email', requireField('email')),
    middleName: yup.string().when('$middleName', requireField('middle_name')),
    firstName: yup.string().when('$firstName', requireField('first_name')),
    lastName: yup.string().when('$lastName', requireField('last_name')),
    mobilePhone: yup.string().when('$mobile_phone', requireField('mobile_phone')),
    ssn: yup.string().when('$ssn', requireField('ssn')),
    encryptedSsn: yup.string().nullable(),
    state: yup
      .object()
      .shape({ label: yup.string(), value: yup.string() })
      .nullable()
      .when('$state', requireField('state')),
    zip: yup.string().when('$zip', requireField('zip')),
    driversLicenseState: yup
      .object()
      .shape({ label: yup.string(), value: yup.string() })
      .nullable()
      .when('$driversLicenseState', requireField('drivers_license_state')),
    driversLicenseNumber: yup.string().when('$driversLicenseNumber', requireField('drivers_license_number'))
  };

  if (onlyFields) return fields;

  return yup.object().shape(fields);
}
