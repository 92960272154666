import { createContext } from 'react';

export const InitialState = {
  startDownloadAllCertificatesOfInsuranceJob: _args => {},
  startDownloadAllProductExposuresJob: _productId => {},
  startDownloadFactorValueTemplateJob: _policyId => {},
  startExposureUpdateTemplateJob: _policyId => {},
  jobId: null,
  clearJobId: () => {},
  loading: false,
  jobErrors: [],
  url: null,
  message: null,
  filename: '',
  populatedDocument: {}
};

export const DocumentDownloadJobContext = createContext(InitialState);

DocumentDownloadJobContext.displayName = 'DocumentDownloadJobContext';
