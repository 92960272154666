import React from 'react';
import { Td } from '@oforce/global-components';

import { displayElectionTotal } from '../utils';

export default function CellTotal({ election, colSpan = '2' }) {
  return (
    <Td className="fs-xs" colSpan={colSpan}>
      {displayElectionTotal(election)}
    </Td>
  );
}
