import React from 'react';
import { FormSection, GraphQLErrors } from '../../../components';
import { LargeIcon } from '../../../components/message-box';
import { Button, FieldMessage } from '@oforce/global-components';
import { useRenewalAuditReport } from '../hooks';

export default function RenewPolicyAdvisory({ policyId }) {
  const { handleSubmit, loadingReport, errorReport } = useRenewalAuditReport();

  return (
    <FormSection title="Advisory">
      <GraphQLErrors error={errorReport} />
      <div className="fs-lg">
        <b>Please be advised</b>, renewing this policy will cause all{' '}
        <b>exposure elections selected in the next step</b> to be reissued onto the new policy.
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }} className="mt-3 mb-3">
        <LargeIcon icon="exclamation-triangle" color="danger" style={{ marginRight: '8px' }} />
        <FieldMessage color="danger">
          <div className="fs-md pt-1">
            We recommend you take this opportunity to do an audit of your elections on this policy to guarantee your
            insureds are up to date.
          </div>
        </FieldMessage>
      </div>
      <div className="d-flex justify-content-center">
        <Button
          color="info"
          disabled={loadingReport}
          loading={loadingReport}
          loadingText="Downloading"
          onClick={() => handleSubmit({ policyId })}
        >
          Download Audit Report
        </Button>
      </div>
    </FormSection>
  );
}
