import React, { useMemo } from 'react';
import { SearchBy } from '../../../components';
import useInvoiceSearchByOptions from '../hooks/useInvoiceSearchByOptions';

export default function InvoicesSearchBy({ onChange }) {
  const { invoiceSearchByOptions, loading } = useInvoiceSearchByOptions();
  const defaultValue = useMemo(
    () => invoiceSearchByOptions.find(option => option.value === 'CLIENT_NAME'),
    [invoiceSearchByOptions]
  );

  const onFilterChange = incomingFilters => {
    const searchBy = incomingFilters.reduce((acc, { field: { value: searchField }, value: searchValue }) => {
      if (!!searchValue) return [...acc, { searchField, searchValue }];
      return acc;
    }, []);

    if (searchBy.length > 0) {
      onChange({ searchBy });
    } else {
      onChange({ searchBy: [] });
    }
  };

  if (loading) return null;

  return (
    <>
      <SearchBy
        label={'Search by'}
        onFilterChange={onFilterChange}
        name="invoices-search-by"
        fieldOptions={invoiceSearchByOptions}
        defaultValue={defaultValue}
        maxFilters={2}
      />
    </>
  );
}
