import { gql, useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import useCursorPagination from '../../../hooks/useCursorPagination';

export const LIST_EXPOSURE_ELECTIONS = gql`
  query ExposureElections(
    $searchBy: [ElectionSearchByInput] = []
    $first: Int
    $last: Int
    $inCompliance: Boolean
    $complianceStatus: ComplianceViolationStatus
    $status: ExposureElectionStatus
    $after: String
    $before: String
    $exposureType: [ExposureType]
    $ordering: ExposureElectionsListOrder
    $fullName: String
    $policyId: ID
    $exposureElectionIds: [ID]
  ) {
    listExposureElections(
      exposureElectionIds: $exposureElectionIds
      searchBy: $searchBy
      first: $first
      last: $last
      inCompliance: $inCompliance
      complianceStatus: $complianceStatus
      status: $status
      after: $after
      before: $before
      exposureType: $exposureType
      ordering: $ordering
      fullName: $fullName
      policyId: $policyId
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        node {
          id
          contractType
          policyId
          productId
          startDate
          terminationDate
          status
          total
          electionData
          pricingRateType
          enableCustomStartDates
          insuredId
          insured {
            id
            firstName
            lastName
            businessName
            mobilePhone
            email
            ssnLastFour
            insertedAt
            dispatchLocation
          }
          vehicle {
            id
            vin
            unitNumber
          }
          policy {
            id
            apiOnlyExposureUpdates
            name
            effectiveDate
            enableCustomStartDates
            enableCustomTerminationDates
            status
            startDateBackdatingLimit
            startDateBackdatingMinimum
            terminationDateBackdatingLimit
            terminationDateBackdatingMinimum
            product {
              id
            }
          }
          addOns {
            id
            label
          }
          insuranceType {
            id
            type
          }
          compliance {
            inCompliance
            rejected
            violations {
              field
              id
              reason
              value
              source
              status
              type
            }
          }
          pricingOption {
            id
            label
            rate
            rateMode
            factoredField
            isPercentageFactor
          }
        }
      }
    }
  }
`;

export default function useExposureElections({
  numberToDisplay = 20,
  exposureElectionIds,
  inCompliance,
  complianceStatus,
  status,
  exposureType,
  ordering,
  searchBy,
  policyId,
  first,
  last,
  after,
  before
}) {
  const [loadExposureElections, { data, loading, error }] = useLazyQuery(LIST_EXPOSURE_ELECTIONS);

  useEffect(() => {
    const variables = {
      first: numberToDisplay,
      exposureElectionIds,
      searchBy,
      policyId,
      inCompliance,
      complianceStatus,
      status,
      exposureType,
      ordering,
      after: null,
      before: null,
      last: null
    };
    loadExposureElections({ variables });
  }, [
    loadExposureElections,
    exposureElectionIds,
    numberToDisplay,
    inCompliance,
    complianceStatus,
    status,
    exposureType,
    ordering,
    searchBy,
    policyId
  ]);

  const { loadNextPage, loadPreviousPage } = useCursorPagination(loadExposureElections, {
    count: numberToDisplay
  });

  const pageInfo = data?.listExposureElections?.pageInfo || {};
  const { hasNextPage, hasPreviousPage } = pageInfo;

  return {
    elections: data?.listExposureElections?.edges?.map(x => x.node),
    loading,
    error,
    hasNextPage,
    hasPreviousPage,
    fetchMore: loadExposureElections,
    loadNextPage: () => {
      loadNextPage(pageInfo, {
        exposureElectionIds,
        inCompliance,
        complianceStatus,
        status: status,
        exposureType: exposureType,
        ordering: ordering,
        searchBy,
        first,
        last,
        after,
        before
      });
    },
    loadPreviousPage: () =>
      loadPreviousPage(pageInfo, {
        exposureElectionIds,
        inCompliance,
        complianceStatus,
        status: status,
        exposureType: exposureType,
        ordering: ordering,
        searchBy,
        first,
        last,
        after,
        before
      })
  };
}
