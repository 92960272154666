import React from 'react';
import { Badge, Label } from '@oforce/global-components';

export default function AddOnsRequired({ addOns }) {
  return (
    <div className="mb-3">
      <Label size="xs" className="fs-xs text-muted font-weight-500">
        Required Add-On(s)
      </Label>
      <div className="d-flex flex-direction-row flex-wrap">
        {addOns?.map(({ id, label }) => (
          <Badge key={id} size="sm" className="m-1" color="secondary">
            {label}
          </Badge>
        ))}
      </div>
    </div>
  );
}
