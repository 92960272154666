import React from 'react';
import classNames from 'classnames';
import { TrLoader } from '@oforce/global-components';
import { BlankRow, Td, Tr } from '../../../components/table-2.0';
import { formatCurrency } from '../../../utils/formatting';
import { useTransactionBreakdown } from '../hooks';
import { pricingDefinitionLabel } from '../../exposureElections/utils';

export default function TransactionBreakdown({ transaction, colSpan }) {
  const { addOns, fees, premium, rate, factorLabel, factorValue, isPercentageFactor, loading, rateMode } =
    useTransactionBreakdown({ transaction });
  if (loading) return <TrLoader colSpan={colSpan} />;

  const premiumLabel = pricingDefinitionLabel({
    rate,
    isPercentageFactor,
    rateMode,
    factoredField: factorLabel,
    factorValue
  });

  return (
    <>
      <BreakdownRow type={premiumLabel} name={transaction?.label} amount={premium?.value} colSpan={colSpan} />
      {addOns?.map(({ label, value }, i) => (
        <BreakdownRow key={`addon-${i}`} type={'Addon'} name={label} amount={value} colSpan={colSpan} />
      ))}
      {fees?.map(({ label, value }, i) => (
        <BreakdownRow key={`fee-${i}`} type={'Fee'} name={label} amount={value} colSpan={colSpan} />
      ))}
      <BlankRow colSpan={colSpan} />
    </>
  );
}

const BreakdownRow = ({ type, name, amount, colSpan }) => {
  const amountIsNegative = amount < 0;
  const amountClasses = classNames('d-inline-block', { 'text-danger': amountIsNegative });
  const breakdownText = amountIsNegative ? 'Premium Credit' : type;

  return (
    <Tr className="fs-sm bg-gray-100">
      <Td colSpan={colSpan} align="right" className="text-muted" style={{ verticalAlign: 'middle' }}>
        <span className="m-3">{name}</span>
        <span className="m-3">{breakdownText}:</span>
        <span className={amountClasses} style={{ minWidth: '60px' }}>
          {formatCurrency(amount)}
        </span>
      </Td>
    </Tr>
  );
};
