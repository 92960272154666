import { gql, useQuery } from '@apollo/client';
import { ALL_VEHICLE_STAKEHOLDER_FIELDS } from '../../vehicleStakeholder/model';
import { VEHICLE_CORE_FIELDS} from '../constants';

export const GET_INSURED_VEHICLES = gql`
  query insured($id: ID!) {
    insured(id: $id) {
      id
      vehicles {
        ...VehicleCoreFields
        additionalInsured {
          ...allVehicleStakeholderFields
        }
        lossPayee {
          ...allVehicleStakeholderFields
        }
        certHolder {
          ...allVehicleStakeholderFields
        }
        lienholder {
          ...allVehicleStakeholderFields
        }
        requiredFields
      }
    }
  }
  ${VEHICLE_CORE_FIELDS}
  ${ALL_VEHICLE_STAKEHOLDER_FIELDS}
`;

export default function useInsuredVehicles({ insured }) {
  const { data, loading, error } = useQuery(GET_INSURED_VEHICLES, {
    fetchPolicy: 'cache-and-network',
    variables: { id: insured?.id }
  });

  return { vehicles: data?.insured.vehicles, loading, error };
}
