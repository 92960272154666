import React from 'react';
import styled from 'styled-components';

const StyledPageTitle = styled.h5.attrs({ className: 'd-flex align-items-center mb-0 px-3 px-md-5 py-3 py-md-4' })`
  font-size: 22px !important;
  font-weight: 500 !important;
`;

export default function PageTitle({ children, placeholder = 'None', ...rest }) {
  return <StyledPageTitle {...rest}>{children || <span className="text-muted">{placeholder}</span>}</StyledPageTitle>;
}
