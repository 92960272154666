import { gql, useMutation } from '@apollo/client';

const UPDATE_STATE_FEE = gql`
  mutation UpdateStateFee(
    $id: ID!
    $fee: Decimal
    $activeMode: StateFeeRateMode
    $state: ClientProductState
    $vehicleTypeIds: [Int]
  ) {
    updateStateFee(id: $id, fee: $fee, activeMode: $activeMode, state: $state, vehicleTypeIds: $vehicleTypeIds) {
      id
      fee
      activeMode
      insuranceTypeId
      state
      vehicleTypes {
        id
        name
      }
    }
  }
`;

export default function useUpdateStateFee({ onError = () => {}, onCompleted = () => {} }) {
  const [update, { loading, error, reset }] = useMutation(UPDATE_STATE_FEE, {
    onError,
    onCompleted
  });

  return {
    updateStateFee: ({ id, fee, activeMode, state, vehicleTypeIds }) =>
      update({ variables: { id, fee, activeMode, state, vehicleTypeIds } }),
    loading,
    error,
    reset
  };
}
