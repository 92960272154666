import React from 'react';
import * as yup from 'yup';
import { Button, InputField } from '@oforce/global-components';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { castViolationDetails } from './PendingApprovalsTable';
import { normalizeInsuredFullName } from '../../../insured/utils';
import { gql, useMutation } from '@apollo/client';

import { GraphQLErrors } from '../../../../components';
import { Formik } from 'formik';
import { GET_EXPOSURE_ELECTION_ACTION_PERMISSIONS } from '../../../../components/quickActions/useQuickActionPermissions';

const APPROVE_ELECTION = gql`
  mutation approve($exposureElectionId: ID!, $note: String!) {
    exposureElection: overrideAllComplianceViolations(exposureElectionId: $exposureElectionId, note: $note) {
      id
      compliance {
        inCompliance
      }
    }
  }
`;

export default function ApprovalModal({ isOpen, toggle, election }) {
  const [approveElection, { loading, error }] = useMutation(APPROVE_ELECTION, {
    onCompleted: toggle,
    refetchQueries: [{ query: GET_EXPOSURE_ELECTION_ACTION_PERMISSIONS, variables: { id: election?.id } }],
    onError: () => {}
  });
  const validationSchema = yup.object({
    note: yup.string().required('Required').max(255, 'Must be 255 characters or less')
  });
  const handleSubmit = ({ note }) => approveElection({ variables: { note, exposureElectionId: election.id } });

  return (
    <Formik validationSchema={validationSchema} enableReinitialize initialValues={{ note: '' }} onSubmit={handleSubmit}>
      {formik => (
        <Modal isOpen={isOpen} toggle={toggle} centered>
          <ModalBody>
            <h4 className="mb-4">Approve an Election</h4>
            <GraphQLErrors error={error} />
            <div className="d-flex">
              <FontAwesomeIcon icon={['far', 'exclamation-triangle']} className={`text-info mr-3`} />
              <div className="text-left">
                You've selected to approve coverage for {normalizeInsuredFullName(election.insured)} with the following
                compliance violations:
                <ul>
                  {castViolationDetails(election).map(violation => (
                    <li key={violation.id}>{violation.text}</li>
                  ))}
                </ul>
              </div>
            </div>
            <InputField type="textarea" label="Note" name="note" required placeholder="Enter a note..." messageAfter="255 characters max" />
          </ModalBody>
          <ModalFooter className="d-flex justify-content-between border-top-0">
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
            <Button color="success" disabled={loading} onClick={formik.handleSubmit}>
              Approve
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </Formik>
  );
}
