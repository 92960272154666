import { Alert } from 'reactstrap';
import React, { useState, useEffect } from 'react';

export default function GraphQLErrors({ error, className }) {
  const [errorString, setErrorString] = useState('');

  useEffect(() => {
    const errors = error?.graphQLErrors?.map(error => error.message);

    if (error?.message) {
      setErrorString(error?.message);
    } else if (error?.details) {
      setErrorString(error?.details);
    } else if (errors?.length > 0) {
      const isUnauthorizedError = errors.includes('not_authorized') || errors.includes('not authorized');

      if (isUnauthorizedError) {
        setErrorString('Unauthorized');
      } else {
        const flattenedErrors = errors?.reduce((acc, error, index) => {
          return index === 0 ? error : `${acc}, ${error}`;
        }, ``);

        setErrorString(flattenedErrors);
      }
    } else {
      setErrorString('');
    }
  }, [error, setErrorString]);

  return errorString ? (
    <Alert color="danger" className={className}>
      {errorString.split('\n').map((error, i) => (
        <span key={`error-${i}`}>
          {error} <br></br>
        </span>
      ))}
    </Alert>
  ) : null;
}
