import React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Tr } from '@oforce/global-components';
import { UncontrolledTooltip } from 'reactstrap';

import { formatCurrency, formatDate } from '../../../utils/formatting';
import { InvoiceStatus } from '../../../views/invoice/components';
import { HideFromTypes, ACCOUNT_TYPES } from '../../../shared/activeAccount';
import { Render } from '../../../components';

export default function PolicyInvoicesTableRow({ invoice, showBrokerTotal }) {
  const history = useHistory();
  const dueDateToolTipId = `due-date-${invoice?.id}-policy`;
  const { CLIENT } = ACCOUNT_TYPES;

  return (
    <Tr onClick={() => history.push(`/invoices/${invoice?.id}`)}>
      <td>
        <span className="font-weight-500">{invoice?.id}</span>
      </td>
      <td>{moment(invoice?.invoiceDate).format('MM-DD-YYYY')}</td>
      <td>
        {invoice?.type === 'OFF_CYCLE'
          ? 'Off Cycle'
          : `${formatDate(invoice?.serviceStartDate)} – ${formatDate(invoice?.serviceEndDate)}`}
      </td>

      <td>
        <UncontrolledTooltip target={dueDateToolTipId}>
          {moment(invoice?.dueDate).format('MM-DD-YYYY')}
        </UncontrolledTooltip>
        <span id={dueDateToolTipId}>{moment(invoice?.dueDate).fromNow()}</span>
      </td>
      <td>
        <span className="font-weight-500 mr-2">{formatCurrency(invoice?.clientTotal || 0)}</span>
        <InvoiceStatus status={invoice?.clientStatus} />
      </td>
      <HideFromTypes types={[CLIENT]}>
        <Render if={showBrokerTotal}>
          <td>
            <span className="font-weight-500 mr-2">{formatCurrency(invoice?.brokerTotal || 0)}</span>
            <InvoiceStatus status={invoice?.brokerStatus} />
          </td>
        </Render>
      </HideFromTypes>
      <td>{invoice?.clientProduct?.externalId}</td>
      <td>{invoice?.client?.name}</td>
    </Tr>
  );
}
