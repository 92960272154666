import { gql, useQuery } from '@apollo/client';
import { INSURANCE_TYPES } from '../../../../configurationInsuranceTypes';
import { EXPOSURE_ELECTION_STATUSES } from '../../../../exposureElections/constants';

export const GET_INSURED_ELECTIONS = gql`
  query insured($id: ID!) {
    insured(id: $id) {
      id
      elections {
        id
        status
        policy {
          id
          insuranceType {
            id
            type
            name
          }
        }
        vehicle {
          id
        }
      }
    }
  }
`;

export default function useExistingExposurePolicyIds({ insured, vehicle }) {
  const insuredId = insured?.id;
  const { data, loading, error } = useQuery(GET_INSURED_ELECTIONS, {
    skip: !insuredId,
    variables: { id: insuredId }
  });

  const insuredPolicyIds = data?.insured?.elections
    ? data?.insured?.elections
        .filter(
          election =>
            election?.policy?.insuranceType?.type === INSURANCE_TYPES.INSURED &&
            (election?.status === EXPOSURE_ELECTION_STATUSES.ACTIVE ||
              election?.status === EXPOSURE_ELECTION_STATUSES.PENDING_TERMINATION)
        )
        .map(election => election?.policy?.id)
    : [];

  const vehiclePolicyIds =
    data?.insured?.elections && vehicle?.id
      ? data?.insured?.elections
          .filter(
            election =>
              election?.policy?.insuranceType?.type === INSURANCE_TYPES.VEHICLE &&
              (election?.status === EXPOSURE_ELECTION_STATUSES.ACTIVE ||
                election?.status === EXPOSURE_ELECTION_STATUSES.PENDING_TERMINATION) &&
              election?.vehicle?.id === vehicle.id
          )
          .map(election => election?.policy?.id)
      : [];

  const existingExposurePolicyIds = [...insuredPolicyIds, ...vehiclePolicyIds];

  return { existingExposurePolicyIds, loading, error };
}
