import { ElectionDocumentRow } from '.';
import { BoxContainer, BoxItemPlaceholder, Table } from '../../../components';

import { useElectionDocuments } from '../hooks';
import { FieldMessage } from '@oforce/global-components';

export default function ElectionDocuments({ election }) {
  const { inCompliance, documents } = useElectionDocuments({ exposureElectionId: election?.id });

  if (documents?.length === 0)
    return (
      <BoxContainer className="px-3 py-2">
        <BoxItemPlaceholder>No Documents</BoxItemPlaceholder>
      </BoxContainer>
    );
  return (
    <>
      {!inCompliance && (
        <FieldMessage icon={['far', 'exclamation-triangle']} className="font-weight-semibold" color="danger">
          Document versions are not available for coverages with open violations
        </FieldMessage>
      )}

      <BoxContainer>
        <Table responsive className={'mt-0 mb-0'}>
          <tbody>
            {documents?.map(doc => (
              <ElectionDocumentRow key={doc.id} electionDocument={doc} />
            ))}
          </tbody>
        </Table>
      </BoxContainer>
    </>
  );
}
