import { gql, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { FLAGGED_FEATURES } from '../../../shared/activeAccount/constants';

export const CHECK_FEATURES_ENABLED = gql`
  mutation CheckFeaturesEnabled($features: [FlaggedFeatures]!) {
    checkFeaturesEnabled(features: $features)
  }
`;
export default function useFeatureEnabled() {
  const [enabledFeatures, setEnabledFeatures] = useState({});
  const features = Object.keys(FLAGGED_FEATURES);

  const [load, { data, loading, error }] = useMutation(CHECK_FEATURES_ENABLED, {
    variables: { features },
    onCompleted: ({ checkFeaturesEnabled }) => {
      setEnabledFeatures(checkFeaturesEnabled);
    }
  });

  useEffect(() => {
    if (!data && !error && !loading) {
      load();
    }
  }, [data, error, loading, load]);

  return enabledFeatures;
}
