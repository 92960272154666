import classNames from 'classnames';
import { Row, Col } from 'reactstrap';
import React, { useState } from 'react';
import { Button, InputField, Optional, Render, SwitchField } from '@oforce/global-components';

import { FormSection } from '../../../components';

export default function ProductMasterCertificateFields({ formik }) {
  const prefix = formik?.values?.masterCertificatePrefix;
  const suffix = formik?.values?.masterCertificateSuffix;
  const startNumber = formik?.values?.masterCertificateStartNumber;
  const endNumber = formik?.values?.masterCertificateEndNumber;
  const enableMasterCertNumbers = formik?.values?.enableMasterCertificateNumbers;

  const [incrementAmount, setIncrementAmount] = useState(0);
  const exampleNumber = Number(startNumber) + incrementAmount;
  const increment = () => setIncrementAmount(increment => increment + 1);
  const decrement = () => setIncrementAmount(decrement => decrement - 1);

  return (
    <FormSection title="Master Certificate Numbers" optional>
      <p className="font-weight-400">
        You can optionally set up a master certificate number. This number will be applied incrementally to every new
        election created in the system for this product.
      </p>
      <SwitchField
        id="enableMasterCertificateNumbers"
        name="enableMasterCertificateNumbers"
        label="Use Master Certificate Numbers"
        switchLabel={enableMasterCertNumbers ? 'Yes' : 'No'}
      />
      <Render if={enableMasterCertNumbers}>
        <h6>Range</h6>
        <Row form>
          <Col>
            <InputField
              type="number"
              name="masterCertificateStartNumber"
              required
              label="Start"
              placeholder="ex. 100"
            />
          </Col>
          <Col>
            <InputField type="number" name="masterCertificateEndNumber" required label="End" placeholder="ex. 100000" />
          </Col>
        </Row>
        <h6>
          Format <Optional />
        </h6>
        <Row form>
          <Col>
            <InputField name="masterCertificatePrefix" label="Prefix" placeholder="ex. abc-" />
          </Col>
          <Col>
            <InputField name="masterCertificateSuffix" label="Suffix" placeholder="ex. -efg" />
          </Col>
        </Row>
        <h6>Example Number</h6>
        <div className="p-3 bg-gray-200 text-gray-600 d-flex justify-content-between align-items-center rounded">
          <Render if={!!startNumber && !!endNumber}>
            <span>{`${prefix}${exampleNumber}${suffix}`}</span>
            <span>
              <PillButton
                onClick={decrement}
                disabled={incrementAmount === 0}
                icon={['far', 'minus']}
                className="mr-1"
              />
              <PillButton onClick={increment} icon={['far', 'plus']} />
            </span>
          </Render>
          <Render if={!startNumber || !endNumber}>None</Render>
        </div>
      </Render>
    </FormSection>
  );
}

const PillButton = ({ className, children, ...rest }) => (
  <Button size="sm" outline className={classNames('rounded-pill px-1 py-0', className)} {...rest}>
    {children}
  </Button>
);
