import download from 'js-file-download';
import { useCallback, useState } from 'react';

export default function useDownloadDocument() {
  const [loading, setLoading] = useState(false);

  const downloadFile = useCallback(async ({ url, filename }) => {
    setLoading(true);

    await fetch(url)
      .then(response => response.blob())
      .then(file => download(file, filename));
    setLoading(false);
  }, []);

  return { download: downloadFile, loading };
}
