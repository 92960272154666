import React from 'react';
import { Alert } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { Button, Render } from '@oforce/global-components';

import { BoxLoader, BoxErrors } from '../../../components/box';
import { RenderData, BoxContainer, BoxItem, BoxItemTitle, TabPage } from '../../../components';

import { useCurrentUser } from '../../../shared/currentUser';

export default function ReportsList({ reports, loading, error }) {
  const history = useHistory();
  const { isAdmin } = useCurrentUser();

  return (
    <>
      <TabPage
        title="Reports"
        buttons={
          <Render if={!!isAdmin}>
            <Button color="info" icon={['far', 'plus']} onClick={() => history.push(`/reports/create`)}>
              Create
            </Button>
          </Render>
        }
      >
        <BoxContainer>
          <RenderData
            data={reports}
            loading={loading}
            error={error}
            loadingComponent={<BoxLoader />}
            errorComponent={<BoxErrors error={error} />}
            placeholderComponent={
              <Alert color="info" className="m-3">
                Looks like you don't have any reports set up. You can contact us to set some up.
              </Alert>
            }
          >
            {reports =>
              reports?.map(report => (
                <BoxItem key={report?.id} size="lg">
                  <BoxItemTitle title={report?.name} />
                  <Button
                    color="info"
                    outline
                    size="sm"
                    icon={['far', 'file-chart-line']}
                    onClick={() => history.push(`/reports/${report?.id}/generate`)}
                  >
                    Generate Report
                  </Button>
                </BoxItem>
              ))
            }
          </RenderData>
        </BoxContainer>
      </TabPage>
    </>
  );
}
