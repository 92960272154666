import { gql } from '@apollo/client';
import { usePaginatedQuery } from '../../../hooks';
import { INSURED_CORE_FIELDS } from '../../insured/model';

export const LIST_INSUREDS = gql`
  query insureds(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $ordering: InsuredListOrder
    $status: ExposureStatus
    $searchBy: [InsuredSearchByInput] = []
    $name: String
    $ids: [ID]
    $policyId: ID
    $clientId: ID
  ) {
    allInsureds(
      first: $first
      after: $after
      last: $last
      before: $before
      ordering: $ordering
      status: $status
      searchBy: $searchBy
      name: $name
      ids: $ids
      policyId: $policyId
      clientId: $clientId
    ) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ...insuredCoreFields
          electionCount
          status
          insertedAt
        }
      }
    }
  }
  ${INSURED_CORE_FIELDS}
`;

export default function useListInsureds({ searchBy, filters, pageSize = 20 }) {
  const { data, loading, error, paginationProps, queryProps, fetchMore, loadFirstPage } = usePaginatedQuery(
    LIST_INSUREDS,
    {
      variables: { searchBy, first: pageSize, ...filters },
      pathToPageInfo: 'allInsureds',
      pageSize
    }
  );

  return {
    insureds: data?.allInsureds?.edges?.map(x => x.node),
    data,
    loading,
    error,
    paginationProps,
    queryProps,
    fetchMore,
    loadFirstPage
  };
}
