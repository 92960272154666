import React, { useState } from 'react';
import { Td, Tr, TrPlaceholder, TrLoader, RenderData } from '@oforce/global-components';
import { Table } from '../../../components/table-2.0';
import { TrGraphQLErrors } from '../../../components';
import { CustomInput } from 'reactstrap';
import { NameCell } from '../../../components/tables';

export default function BulkDownloadDocumentsInsuredsTable({ formik, insureds, allValidInsuredIds, loading, error }) {
  const colSpan = 2;
  const initialToggleState = !!formik?.values?.insuredIdsFromChosenDocuments.length;
  const [selectAll, setSelectAll] = useState(initialToggleState);

  const toggleSelectAll = selectAll => {
    !selectAll ? formik?.setFieldValue('insuredIds', allValidInsuredIds) : formik?.setFieldValue('insuredIds', []);
    setSelectAll(!selectAll);
  };

  return (
    <Table responsive>
      <thead>
        <tr>
          <th>
            <CustomInput
              id="toggleSelectAllInsuredIds"
              type="checkbox"
              checked={selectAll}
              onChange={() => toggleSelectAll(selectAll)}
              className="mb-1"
            />
          </th>
          <th>Insured</th>
        </tr>
      </thead>
      <tbody>
        <RenderData
          data={insureds}
          loading={loading}
          error={error}
          loadingComponent={<TrLoader colSpan={colSpan} />}
          errorComponent={<TrGraphQLErrors colSpan={colSpan} error={error} />}
          placeholderComponent={<TrPlaceholder colSpan={colSpan}>No insureds</TrPlaceholder>}
          style={{ border: '0px!important' }}
        >
          {insureds =>
            insureds?.map(insured => (
              <Tr
                key={insured?.id}
                style={formik?.values?.insuredIds.includes(insureds?.id) ? { backgroundColor: '#e6eaf2' } : {}}
              >
                <Td>
                  <CustomInput
                    id={insured?.id}
                    type="checkbox"
                    checked={formik?.values?.insuredIds.includes(insured?.id)}
                    onChange={() => {
                      if (formik?.values?.insuredIds?.includes(insured?.id)) {
                        formik?.setFieldValue(
                          'insuredIds',
                          formik?.values?.insuredIds.filter(insuredId => insuredId !== insured?.id)
                        );
                      } else {
                        formik?.setFieldValue('insuredIds', [...formik?.values?.insuredIds, insured?.id]);
                      }
                    }}
                  />
                </Td>
                <Td>
                  <NameCell insured={insured} skipLink={true}></NameCell>
                </Td>
              </Tr>
            ))
          }
        </RenderData>
      </tbody>
    </Table>
  );
}
