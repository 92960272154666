import React from 'react';
import { useLocation } from 'react-router-dom';
import { Render } from '@oforce/global-components';

const GlobalStyles = React.lazy(() => import('./GlobalStyles'));
const GraphiqlStyles = React.lazy(() => import('../graphiql/GraphiqlStyles'));

export default function GlobalStylesProvider({ children }) {
  const location = useLocation();
  const isGraphiql = location?.pathname === '/graphiql';

  return (
    <>
      <React.Suspense fallback={<></>}>
        {/* don't render our global styles if on graphiql interface */}
        <Render if={isGraphiql}>
          <GraphiqlStyles />
        </Render>
        {/* render our global styles if not graphiql */}
        <Render if={!isGraphiql}>
          <GlobalStyles />
        </Render>
        {children}
      </React.Suspense>
    </>
  );
}
