import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { useState } from 'react';
import { dropNullProps } from '../../../utils';
import debounce from 'debounce-promise';

export const GET_CLIENTS = gql`
  query clients($ordering: ListClientsOrdering, $searchBy: [ClientSearchByInput] = []) {
    allClients(ordering: $ordering, searchBy: $searchBy) {
      id
      name
      email
      externalId
      address1
      address2
      city
      state
      zip
      country
      updated
      created
    }
  }
`;

export default function useClients(initialFilters = {}) {
  const [clientFilter, setClientFilter] = useState({ ...initialFilters });

  const { data, loading, error, fetchMore } = useQuery(GET_CLIENTS, {
    variables: dropNullProps({ ...clientFilter }),
    fetchPolicy: 'cache-and-network'
  });

  const dataToOptions = data =>
    data?.allClients?.map(client => ({ label: client?.name, value: client?.id, ...client })) || [];

  const options = dataToOptions(data);

  const getFilteredClients = async (searchQuery, callback) => {
    const { data } = await fetchMore({
      variables: { searchBy: { searchField: 'NAME', searchType: 'CONTAINS', searchValue: searchQuery } }
    });
    const filteredOptions = dataToOptions(data);
    callback(filteredOptions);
  };

  const loadOptions = debounce(getFilteredClients, 500);

  return {
    clients: data?.allClients,
    options,
    loading,
    error,
    clientFilter,
    loadOptions,
    changeClientFilter(filters) {
      setClientFilter(state => {
        let newState = { ...state, ...filters };
        return newState;
      });
    }
  };
}
