import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import * as yup from 'yup';
import { GET_DEFAULT_DOCUMENTS } from './useDocuments';

const UPLOAD = gql`
  mutation upload($document: Upload!) {
    uploadDefaultInvoiceTemplate(fileData: $document) {
      filename
      id
      type
      url
    }
  }
`;

export default function useUploadInvoiceTemplate({ onCompleted = () => {}, onError = () => {} }) {
  const [upload, { loading, error }] = useMutation(UPLOAD, {
    onCompleted,
    onError,
    refetchQueries: [{ query: GET_DEFAULT_DOCUMENTS }]
  });

  const initialValues = {
    document: null,
    name: ''
  };

  const DOCUMENT_VALIDATION = {
    SUPPORTED_FORMATS: ['application/pdf'],
    MAX_FILE_SIZE: 50000000
  };

  const validationSchema = yup.object({
    document: yup.mixed().when('$document', (_value, schema) =>
      schema
        .required('Required')
        .test('fileType', 'File must be a PDF', value => DOCUMENT_VALIDATION.SUPPORTED_FORMATS.includes(value?.type))
        .test('fileSize', 'File must be less than 50 MB', value => value.size < DOCUMENT_VALIDATION.MAX_FILE_SIZE)
    )
  });

  return {
    uploadInvoiceTemplate: ({ document }) => upload({ variables: { document } }),
    loading,
    error,
    initialValues,
    validationSchema
  };
}
