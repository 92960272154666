import React from 'react';
import { Formik } from 'formik';
import { Modal } from '@oforce/global-components';

import { GraphQLErrors } from '../../../components';

import { InvoiceTemplateForm } from '.';
import { useUploadInvoiceTemplate } from '../hooks';

export default function UploadInvoiceTemplate({ isOpen, toggle }) {
  const { uploadInvoiceTemplate, loading, error, initialValues, validationSchema } = useUploadInvoiceTemplate({
    onCompleted: toggle
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={uploadInvoiceTemplate}
    >
      {formik => (
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          title={`Replace Default Invoice Template`}
          loading={loading}
          onSubmit={formik.handleSubmit}
          submitButtonText="Upload"
        >
          {error && <GraphQLErrors error={error} />}
          <InvoiceTemplateForm loading={loading} />
        </Modal>
      )}
    </Formik>
  );
}
