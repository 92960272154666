import React from 'react';
import { Loader, Button, useToggle } from '@oforce/global-components';

import { useVehicleTypes } from '../../views/configurationVehicleTypes/hooks';
import { GraphQLErrors, TabPage } from '../../components';

import { VehicleTypeTable, CreateVehicleType } from './components';

export default function ConfigurationVehicleTypes() {
  const { error, vehicleTypes, busy } = useVehicleTypes();
  const [showCreateVehicleType, toggleCreateVehicleType] = useToggle(false);

  if (busy) return <Loader />;
  if (error) return <GraphQLErrors error={error} />;

  return (
    <TabPage
      title="Vehicle Types"
      buttons={
        <Button color="info" icon={['far', 'plus']} onClick={toggleCreateVehicleType}>
          Create
        </Button>
      }
    >
      <CreateVehicleType
        showCreateVehicleType={showCreateVehicleType}
        toggleCreateVehicleType={toggleCreateVehicleType}
      />

      <VehicleTypeTable vehicleTypes={vehicleTypes} loading={busy} error={error} />
    </TabPage>
  );
}

export { GET_VEHICLE_TYPES } from './hooks/useVehicleTypes';
