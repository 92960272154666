import { gql } from '@apollo/client';
import { useSubscription } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';

import { SyncPricingOptionsJobContext } from './context';
import { useResettableMutation } from '../../hooks/useResettableMutation';
import { GET_PRODUCTS } from '../../views/products/hooks/useProducts';

const START_JOB = gql`
  mutation startSyncPricingOptionsJob($productId: ID!, $productPricingOptionIds: [ID]!, $policyIds: [ID]!) {
    syncProductPricingOptionsToPolicies(
      productId: $productId
      productPricingOptionIds: $productPricingOptionIds
      policyIds: $policyIds
    )
  }
`;

const ON_SYNC_PRICING_OPTIONS_JOB_PROGRESS = gql`
  subscription jobProgress($jobId: ID!) {
    job: syncPricingOptionsJobProgress(jobId: $jobId) {
      jobId
      finished
      message
      errors
    }
  }
`;

export default function SyncPricingOptionsJobProvider({ children }) {
  const [jobId, setJobId] = useState(null);
  const [productId, setProductId] = useState(null);
  const [jobErrors, setJobErrors] = useState([]);
  const [finished, setJobFinished] = useState(true);
  const [message, setMessage] = useState(null);

  const clearJobId = useCallback(() => {
    setJobId(null);
    setJobErrors([]);
  }, []);

  useEffect(() => {
    if (!!jobId && !!finished) {
      setTimeout(() => clearJobId(), 10000);
    }
  }, [finished, clearJobId, jobId]);

  const [startJob, { loading, error, reset }] = useResettableMutation(START_JOB, {
    onCompleted: data => {
      setJobFinished(false);
      setJobId(data?.syncProductPricingOptionsToPolicies);
    },
    refetchQueries: [{ query: GET_PRODUCTS, variables: { searchBy: [], first: 20, last: null, before: null } }],
    onError: () => setTimeout(() => reset(), 10000)
  });

  const startSyncPricingOptionsJob = (productId, productPricingOptionIds, policyIds) => {
    setJobFinished(false);
    setProductId(productId);
    startJob({ variables: { productId, productPricingOptionIds, policyIds } });
  };

  useSubscription(ON_SYNC_PRICING_OPTIONS_JOB_PROGRESS, {
    skip: !jobId,
    variables: { jobId },
    onSubscriptionData: ({ client, subscriptionData: { data } }) => {
      const job = data?.job;
      const { message, finished, errors } = job;

      setJobErrors(errors);
      setJobFinished(finished);
      setMessage(message);

      if (errors.length !== 0) {
        client.refetchQueries({
          include: [{ query: GET_PRODUCTS, variables: { searchBy: [], first: 20, last: null, before: null } }]
        });
      }
    }
  });

  return (
    <SyncPricingOptionsJobContext.Provider
      value={{
        startSyncPricingOptionsJob,
        productId,
        jobId,
        clearJobId,
        loading,
        error,
        finished,
        message,
        jobErrors
      }}
    >
      {children}
    </SyncPricingOptionsJobContext.Provider>
  );
}
