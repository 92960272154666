import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';

export const MARK_BROKER_INVOICE_AS_PAID = gql`
  mutation MarkBrokerInvoiceAsPaid($invoiceId: Int!) {
    markBrokerInvoiceAsPaid(invoiceId: $invoiceId) {
      id
      status: brokerStatus
    }
  }
`;

export const MARK_CLIENT_INVOICE_AS_PAID = gql`
  mutation MarkClientInvoiceAsPaid($invoiceId: Int!) {
    markClientInvoiceAsPaid(invoiceId: $invoiceId) {
      id
      status: clientStatus
    }
  }
`;

export default function useMarkInvoiceAsPaid({ onCompleted = () => {}, onError = () => {}, isClientInvoice }) {
  const MUTATION = isClientInvoice ? MARK_CLIENT_INVOICE_AS_PAID : MARK_BROKER_INVOICE_AS_PAID;

  const [markAsPaid, { loading, error, ...rest }] = useMutation(MUTATION, {
    onCompleted: ({ markInvoiceAsPaid }) => onCompleted && onCompleted(markInvoiceAsPaid),
    onError
  });

  return [
    markAsPaid,
    {
      loading,
      error,
      ...rest
    }
  ];
}
