import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';

export const GET_CLIENT_POLICY_OPTIONS = gql`
  query clientOptions($id: ID!) {
    client(id: $id) {
      id
      policiesForClientDocuments {
        id
        name
        archived
        renewedToPolicyId
        effectiveStatus
      }
    }
  }
`;

export default function useClientPolicyOptions({ clientId }) {
  const { data, loading, error } = useQuery(GET_CLIENT_POLICY_OPTIONS, {
    variables: { id: clientId }
  });

  const clientPolicyOptions =
    data?.client?.policiesForClientDocuments?.map(policy => ({ label: policyLabel(policy), value: policy?.id })) || [];

  return { clientPolicyOptions, loading, error };
}

function policyLabel({ name, effectiveStatus }) {
  if (effectiveStatus === 'ARCHIVED') return name + ' (archived)';
  if (effectiveStatus === 'EXPIRED') return name + ' (expired)';
  else return name;
}
