import { gql, useQuery } from '@apollo/client';
import { ALL_VEHICLE_STAKEHOLDER_FIELDS } from '../../vehicleStakeholder/model';
import { VEHICLE_CORE_FIELDS } from '../../insuredVehicles/constants';

export const GET_VEHICLE = gql`
  query getVehicle($id: ID!) {
    vehicle: getVehicle(id: $id) {
      ...VehicleCoreFields
      additionalInsured {
        ...allVehicleStakeholderFields
      }
      lossPayee {
        ...allVehicleStakeholderFields
      }
      certHolder {
        ...allVehicleStakeholderFields
      }
      lienholder {
        ...allVehicleStakeholderFields
      }
      requiredFields
    }
  }
  ${ALL_VEHICLE_STAKEHOLDER_FIELDS}
  ${VEHICLE_CORE_FIELDS}
`;

export default function useVehicle({ vehicleId }) {
  const { data, loading, error } = useQuery(GET_VEHICLE, {
    variables: { id: vehicleId },
    skip: !vehicleId,
    fetchPolicy: !vehicleId ? 'cache-only' : 'cache-first'
  });

  return { vehicle: data?.vehicle, loading, error };
}
