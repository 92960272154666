import { useMemo } from 'react';
import { gql, useMutation } from '@apollo/client';

const REMOVE_POLICY_ADD_ON = gql`
  mutation removeAddOn($id: ID!) {
    addOn: removePolicyAddOn(id: $id) {
      id
      policyId
    }
  }
`;

const REMOVE_PRODUCT_ADD_ON = gql`
  mutation removeAddOn($id: ID!) {
    addOn: removeProductAddOn(id: $id) {
      id
      productId
    }
  }
`;
const PRODUCT_ADDONS = gql`
  query product($parentId: ID!) {
    parent: product(id: $parentId) {
      id
      addOns {
        id
        cost
        label
        name
        appliesToAllPricingOptions
        pricingOptionIds
        requiredOnAllPricingOptions
        requiredForPricingOptionIds
      }
    }
  }
`;

const POLICY_ADDONS = gql`
  query policy($parentId: ID!) {
    parent: policy(id: $parentId) {
      id
      addOns {
        id
        cost
        label
        name
        appliesToAllPricingOptions
        pricingOptionIds
        requiredOnAllPricingOptions
        requiredForPricingOptionIds
      }
    }
  }
`;

function updateCache(cache, { data: { addOn } }) {
  const { productId, policyId, id: addOnId } = addOn;

  const query = !!productId ? PRODUCT_ADDONS : POLICY_ADDONS;
  const parentId = productId || policyId;

  const { parent } = cache.readQuery({
    query,
    variables: { parentId }
  });

  const addOns = parent.addOns.filter(({ id }) => id !== addOnId);

  cache.writeQuery({
    query,
    variables: { parentId },
    data: {
      parent: {
        ...parent,
        addOns
      }
    }
  });
}

export default function useRemoveAddOn({ addOn, onCompleted = () => {}, onError = () => {} }) {
  const { __typename, id } = addOn;

  const mutation = useMemo(() => {
    return __typename === 'PolicyAddOn' ? REMOVE_POLICY_ADD_ON : REMOVE_PRODUCT_ADD_ON;
  }, [__typename]);

  const [removeAddOn, { loading, error }] = useMutation(mutation, {
    variables: { id },
    update: updateCache,
    onCompleted,
    onError
  });

  return {
    removeAddOn,
    loading,
    error
  };
}
