import React from 'react';
import { Steps } from 'react-step-builder';
import { useHistory } from 'react-router-dom';
import { MultiStepForm } from '../../../components';
import {
  BulkEmailConfirmation,
  BulkEmailDateSelection,
  BulkEmailDocumentsSelection,
  BulkEmailInsuredsSelection
} from '../components';
import { useBulkEmailDocumentFormSteps } from '../hooks';
import { useSendDocumentsJobContext } from '../../../context';
import { SERVER_SIDE_DATE_FORMAT } from '../../../utils/dates';
import moment from 'moment';

export default function BulkEmail({ policyId, clientId, redirectUrl }) {
  const history = useHistory();
  const { startSendDocumentsJob, loading, error } = useSendDocumentsJobContext();
  const { formSteps, filterDocumentRelationships } = useBulkEmailDocumentFormSteps();

  return (
    <MultiStepForm
      formSteps={formSteps}
      formSubmit={values => {
        const { activeOn } = values;
        const documentRelationshipIds = filterDocumentRelationships(values);
        startSendDocumentsJob({
          activeOn: activeOn === '' ? null : moment(activeOn).format(SERVER_SIDE_DATE_FORMAT),
          redirectUrl,
          policyId,
          clientId,
          documentRelationshipIds
        });
      }}
      maxWidth={'800px'}
      title={`Send Bulk Email`}
      onCancel={() => history.push(redirectUrl)}
      submitButtonText={'Send'}
      submitButtonProps={{ loading, loadingText: 'Sending...' }}
      showButtonsOnBottom
      error={error}
    >
      {formProps => (
        <Steps>
          <BulkEmailDateSelection {...formProps} />
          <BulkEmailDocumentsSelection {...formProps} clientId={clientId} policyId={policyId} />
          <BulkEmailInsuredsSelection {...formProps} clientId={clientId} policyId={policyId} />
          <BulkEmailConfirmation {...formProps} />
        </Steps>
      )}
    </MultiStepForm>
  );
}
