import React, { useState } from 'react';
import { FormSection } from '../../../../components';
import { StakeholderBoxItem } from '../../../stakeholder/components';
import { VehicleStakeholderFormFields } from '../../../vehicleStakeholder/components';

export default function VehicleStakeholdersStep({ requiredVehicleFields = {}, formik, formOptions }) {
  formik.validateOnChange = true;

  const singleFormState = {
    lossPayee: null,
    additionalInsured: null,
    certHolder: null,
    lienholder: null
  };

  const boxesState = {
    lossPayee: 'box',
    additionalInsured: 'box',
    certHolder: 'box',
    lienholder: 'box'
  };

  const [renderState, setRenderState] = useState(boxesState);

  const setRenderedForm = key => {
    if (key) setRenderState({ ...singleFormState, ...{ [key]: 'form' } });
    else setRenderState(boxesState);
  };

  return (
    <FormSection title="Vehicle Stakeholders">
      {Object.keys(boxesState).map(key => {
        return (
          <StakeholderBoxItem
            key={key}
            setRenderedForm={setRenderedForm}
            renderState={renderState}
            formik={formik}
            requiredFields={requiredVehicleFields}
            stakeholderType={key}
          >
            <VehicleStakeholderFormFields
              formOptions={formOptions}
              stakeholderType={key}
              requiredFields={requiredVehicleFields}
            />
          </StakeholderBoxItem>
        );
      })}
    </FormSection>
  );
}
