import React from 'react';
import { Modal } from '@oforce/global-components';

import { CopyableBadge, Render } from '../../components';

import { useMappableExposureDocumentFields, useDocumentTooltips } from './hooks';
import FieldTooltip from './components/FieldTooltip';

export default function MappableFields({ open, toggle, productId, policyId, clientId }) {
  const {
    clientFields,
    insuredFields,
    vehicleFields,
    policyFields,
    pricingFields,
    otherFields,
    electionFields,
    insuranceTypeFields,
    loading
  } = useMappableExposureDocumentFields({ productId, policyId, clientId });

  const { tooltips } = useDocumentTooltips();

  return (
    <Modal isOpen={open} toggle={toggle} title="Mappable Fields" loading={loading} showFooter={false} scrollable>
      <Fields title="Client Fields" fields={clientFields} tooltips={tooltips} />
      <Fields title="Insured Fields" fields={insuredFields} tooltips={tooltips} />
      <Fields title="Vehicle Fields" fields={vehicleFields} tooltips={tooltips} />
      <Render if={!clientId}>
        <Fields title="Policy Fields" fields={policyFields} tooltips={tooltips} />
        <Fields title="Pricing Fields" fields={pricingFields} tooltips={tooltips} />
      </Render>
      <Fields title="Election Fields" fields={electionFields} tooltips={tooltips} />
      <Fields title="Insurance Type Fields" fields={insuranceTypeFields} tooltips={tooltips} />
      <Fields title="Other Fields" fields={otherFields} tooltips={tooltips} />
    </Modal>
  );
}

const Fields = ({ title, fields, tooltips }) => {
  return (
    <div className="mb-4">
      <div className="font-weight-500 mb-2">{title}</div>
      <div className="d-flex flex-wrap">
        {fields?.map((field, i) => (
          <div key={i}>
            <CopyableBadge
              key={i}
              id={`badge-${field}`}
              className="mb-2 mr-2 cursor-pointer"
              color={'blue'}
              text={field}
            />
            <FieldTooltip field={field} tooltips={tooltips} />
          </div>
        ))}
      </div>
    </div>
  );
};
