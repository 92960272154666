import React from 'react';
import { DeleteModal } from '../../../components/modals';

import { gql, useMutation } from '@apollo/client';
import { GET_PRODUCT_PRICINGS } from '../../productPricing/hooks/useProductPricings';

const REMOVE_PRODUCT_RETAIL_COMMISSION = gql`
  mutation RemoveProductRetailCommission($input: RemoveProductRetailCommissionInput!) {
    removeProductRetailCommission(input: $input) {
      id
      retailCommissions {
        id
        name
        rateMode
        rate
      }
    }
  }
`;

export default function Remove({ productId, isOpen, toggle, retailCommission, onError = () => {} }) {
  const [remove, { loading: removing, error: removeError }] = useMutation(REMOVE_PRODUCT_RETAIL_COMMISSION, {
    variables: { input: { retailCommissionId: retailCommission?.id } },
    onCompleted: toggle,
    onError,
    refetchQueries: [{ query: GET_PRODUCT_PRICINGS, variables: { id: productId } }]
  });

  return (
    <DeleteModal
      isOpen={isOpen}
      toggle={toggle}
      entityType="Retail Commission"
      entityName={retailCommission?.name}
      deleting={removing}
      error={removeError}
      onSubmit={remove}
    />
  );
}
