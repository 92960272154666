import React from 'react';
import { ReactSelectField, Render, SwitchField } from '@oforce/global-components';

import { FormSection } from '../../../components';

export const includeStatesOption = { label: 'Include', value: 'include' };
export const excludeStatesOption = { label: 'Exclude', value: 'exclude' };

export default function ProductDocumentRequiredStatesFields({ formik, formOptions }) {
  const enableRequiredStates = formik?.values?.enableRequiredStates;

  return (
    <FormSection title="State-Specific Requirements">
      <SwitchField
        id="enableRequiredStates"
        label="Require this document only in certain states"
        name="enableRequiredStates"
        onChange={() => {
          formik?.setFieldValue('stateSource', '');
          formik?.setFieldValue('selectedStates', []);
        }}
      />
      <Render if={!!enableRequiredStates}>
        <ReactSelectField
          required
          label="Validate State On"
          id="stateSource"
          name="stateSource"
          placeholder="Select..."
          options={formOptions?.stateSource?.options}
          isLoading={formOptions?.stateSource?.loading}
          isClearable
        />
        <ReactSelectField
          required
          label="Included or Excluded States"
          id="stateMode"
          name="stateMode"
          defaultValue={includeStatesOption}
          options={[includeStatesOption, excludeStatesOption]}
          onChange={({ value }) => {
            formik.setFieldValue('selectedStates', []);
            formik.setFieldValue('excludedStates', []);
          }}
        />
        <Render if={formik?.values?.stateMode?.value === 'include'}>
          <ReactSelectField
            required
            label="Included States"
            id="selectedStates"
            name="selectedStates"
            placeholder="Select..."
            isMulti
            options={formOptions?.states?.options}
            isLoading={formOptions?.states?.loading}
            isClearable
          />
        </Render>
        <Render if={formik?.values?.stateMode?.value === 'exclude'}>
          <ReactSelectField
            required
            label="Excluded States"
            id="excludedStates"
            name="excludedStates"
            placeholder="Select..."
            isMulti
            options={formOptions?.states?.options}
            isLoading={formOptions?.states?.loading}
            isClearable
          />
        </Render>
      </Render>
    </FormSection>
  );
}
