import React from 'react';
import { BoxContainer, BoxContainerTitle } from '../../../components';
import { POLICY } from '../../../utils/constants';

import ActiveAccount from './ActiveAccount';
import ActiveInvitation from './ActiveInvitation';
import NoAccountOrInvite from './NoAccountOrInvite';

export default function PolicyAssignedAccount({
  accountType,
  email,
  invitationRole,
  invitation,
  account,
  policyAccountAssignmentId,
  policyId
}) {
  const hasActiveInvitation = invitation && invitation?.status === POLICY.INVITATION.STATUSES.PENDING;

  return (
    <>
      <BoxContainerTitle>{accountType}</BoxContainerTitle>
      <BoxContainer className="mb-5">
        {hasActiveInvitation && <ActiveInvitation invitation={invitation} policyId={policyId} />}
        {account && (
          <ActiveAccount policyAccountAssignmentId={policyAccountAssignmentId} account={account} policyId={policyId} email={email}/>
        )}
        {!hasActiveInvitation && !account && (
          <NoAccountOrInvite accountType={accountType} invitationRole={invitationRole} policyId={policyId} />
        )}
      </BoxContainer>
    </>
  );
}
