import { gql } from '@apollo/client';

export const ALL_VEHICLE_STAKEHOLDER_FIELDS = gql`
  fragment allVehicleStakeholderFields on VehicleStakeholder {
    id
    name
    stakeholderType
    vehicleId
    address1
    address2
    city
    email
    phone
    state
    zip
  }
`;
