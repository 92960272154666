import React from 'react';
import { RenderData, TrGraphQLErrors } from '../../../../components';
import { TrLoader, TrPlaceholder } from '@oforce/global-components';
import { Table } from '../../../../components/table-2.0';
import InsuredCoverageTableRow from './InsuredCoverageTableRow';
import { FeatureFlag, HideFromRolesAndTypes } from '../../../../shared/activeAccount/components';
import { FLAGGED_FEATURES } from '../../../../shared/activeAccount/constants';
import { useActiveAccount } from '../../../../shared/activeAccount';

const { MASTER_SUB_RELATIONSHIP } = FLAGGED_FEATURES;

export default function InsuredPolicyCoveragesTable({ coverages, policy, fetchNewestCoverages, loading, error }) {
  const colSpan = 11;
  const premiumLabel = policy?.pricingRateType === 'MONTHLY' ? 'Monthly Premium' : 'Weekly Premium';
  const {
    ROLES: { OBSERVER },
    TYPES: { CLAIMS_ADJUSTER }
  } = useActiveAccount();

  return (
    <Table>
      <thead>
        <tr>
          <th colSpan="1">Status</th>
          <th colSpan="1">Insured</th>
          <FeatureFlag feature={MASTER_SUB_RELATIONSHIP}>
            <th colSpan="1">Contract Type</th>
          </FeatureFlag>
          <th colSpan="1">Coverage Option</th>
          <th colSpan="1">{premiumLabel}</th>
          <th colSpan="1">Coverage Dates</th>
          <HideFromRolesAndTypes roles={[OBSERVER]} types={[CLAIMS_ADJUSTER]}>
            <th colSpan="1">Actions</th>
          </HideFromRolesAndTypes>
        </tr>
      </thead>
      <tbody>
        <RenderData
          data={coverages}
          loading={loading}
          error={error}
          loadingComponent={<TrLoader colSpan={colSpan} className="py-0" />}
          errorComponent={<TrGraphQLErrors colSpan={colSpan} error={error} />}
          placeholderComponent={<TrPlaceholder colSpan={colSpan}>No Coverages</TrPlaceholder>}
        >
          {insuredCoverages =>
            insuredCoverages?.map(coverage => (
              <InsuredCoverageTableRow
                key={coverage?.id}
                fetchNewestCoverages={fetchNewestCoverages}
                coverage={coverage}
                policy={policy}
              />
            ))
          }
        </RenderData>
      </tbody>
    </Table>
  );
}
