import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { toHeaderCase } from 'js-convert-case';

import { GraphQLErrors } from '../../../components';
import { useActiveAccount } from '../../../shared/activeAccount';

import { InvitationTemplate } from '.';
import { GET_UNCLAIMED_INVITATIONS } from '..';

const OPEN_INVITATION = gql`
  mutation invitation($id: ID!) {
    invitation: openAccountInvitation(invitationId: $id) {
      id
      role
      account {
        name
      }
    }
  }
`;

const ACCEPT_ACCOUNT_INVITATION = gql`
  mutation AcceptAccountInvitation($invitationId: ID!) {
    invitation: acceptAccountInvitation(id: $invitationId) {
      id
      status
      account {
        id
      }
    }
  }
`;

const DECLINE_ACCOUNT_INVITATION = gql`
  mutation DeclineAccountInvitation($invitationId: ID!) {
    invitation: declineAccountInvitation(id: $invitationId) {
      id
      status
    }
  }
`;

export default function InvitationForAccount({ invitation }) {
  const history = useHistory();
  const activeAccount = useActiveAccount();
  const invitationId = invitation?.id;
  const opened = invitation?.opened;

  const [openInvitation] = useMutation(OPEN_INVITATION, {
    onError: () => {},
    refetchQueries: [{ query: GET_UNCLAIMED_INVITATIONS }],
    variables: { id: invitationId }
  });

  useEffect(() => {
    if (!opened) {
      openInvitation();
    }
  }, [opened, openInvitation]);

  const [acceptInvitation, { loading: accepting, error: acceptError }] = useMutation(ACCEPT_ACCOUNT_INVITATION, {
    variables: { invitationId },
    onError: () => {},
    onCompleted: async ({ invitation }) => {
      activeAccount?.setActiveAccountId(invitation?.account?.id, () => history.push('/insureds'));
    }
  });

  const [declineInvitation, { loading: declining, error: declineError }] = useMutation(DECLINE_ACCOUNT_INVITATION, {
    variables: { invitationId: invitation.id },
    onError: () => {},
    onCompleted: () => {},
    refetchQueries: [{ query: GET_UNCLAIMED_INVITATIONS }]
  });

  return (
    <InvitationTemplate
      date={invitation?.created}
      title="Join account"
      acceptButtonText="Join Account"
      onAccept={acceptInvitation}
      acceptButtonProps={{ loading: accepting, loadingText: 'Joining...' }}
      onDecline={declineInvitation}
      declineButtonProps={{ loading: declining, loadingText: 'Declining...' }}
    >
      <GraphQLErrors error={acceptError || declineError} />
      <p className="fs-xl">
        You've been invited to join <span className="font-weight-500">{invitation?.account?.name}</span>
        <br /> with a role of <span className="font-weight-500">{toHeaderCase(invitation?.role)}</span>
      </p>
    </InvitationTemplate>
  );
}
