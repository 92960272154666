import React from 'react';
import { Modal } from '@oforce/global-components';
import { GraphQLErrors } from '../../../components';
import { useEditElectionData } from '../hooks';
import useCustomData from '../../../components/custom-data/useCustomData';
import { CustomDataForm } from '../../../components/custom-data';

export default function ElectionData({ election, isOpen, toggle }) {
  const { handleSubmit, loading, error, reset } = useEditElectionData({ election, onCompleted: toggle });

  const { inputError, resetState, submit, ...rest } = useCustomData({
    data: election?.electionData,
    onSubmit: handleSubmit,
    reset
  });

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      onOpened={() => resetState(election?.electionData)}
      title="Edit Election Custom Data"
      onSubmit={submit}
      submitButtonDisabled={error || inputError}
      submitButtonProps={{ loadingText: 'Saving...' }}
      submitButtonText="Save"
      scrollable
      loading={loading}
    >
      <>
        <GraphQLErrors error={error} />
        <CustomDataForm {...rest} />
      </>
    </Modal>
  );
}
