import React from 'react';
import { Badge, Button, Render, useToggle } from '@oforce/global-components';
import { BoxItem, BoxItemTitle } from '../../../components';
import RemoveActiveAccount from './RemoveActiveAccount';
import EmailLabel from './EmailLabel';
import { toHeaderCase } from 'js-convert-case';

import {
  ACCOUNT_ROLES,
  ACCOUNT_TYPES,
  HideFromRoles,
  HideFromTypes,
  useActiveAccount,
} from '../../../shared/activeAccount';

export default function ActiveAccount({ account, email, policyAccountAssignmentId, policyId }) {
  const activeAccount = useActiveAccount();
  const [showRemoveActiveAccount, toggleRemoveActiveAccount] = useToggle();
  const { CLIENT } = ACCOUNT_TYPES;
  const { EXPOSURE_MANAGER } = ACCOUNT_ROLES;
  const isCurrentAccount = activeAccount?.id === account?.id;

  return (
    <>
      <BoxItem>
        <div className="d-flex justify-content-start align-items-center">
          <BoxItemTitle title={toHeaderCase(account?.name)} subtitle={`${toHeaderCase(account?.type)} Account`}>
            <EmailLabel>{email}</EmailLabel>
          </BoxItemTitle>
          <Render if={isCurrentAccount}>
            <Badge color="info" className="mr-2">
              Current Account
            </Badge>
          </Render>
          <Badge color="success">Active</Badge>
        </div>

        <HideFromTypes types={[CLIENT]}>
          <HideFromRoles roles={[EXPOSURE_MANAGER]}>
            <Button color="secondary" size="sm" onClick={toggleRemoveActiveAccount}>
              Remove Access
            </Button>
          </HideFromRoles>
        </HideFromTypes>
      </BoxItem>
      <HideFromTypes types={[CLIENT]}>
        <HideFromRoles roles={[EXPOSURE_MANAGER]}>
          <RemoveActiveAccount
            isOpen={showRemoveActiveAccount}
            toggle={toggleRemoveActiveAccount}
            policyAccountAssignmentId={policyAccountAssignmentId}
            policyId={policyId}
          />
        </HideFromRoles>
      </HideFromTypes>
    </>
  );
}
