import React, { useEffect, useState } from 'react';
import { Steps } from 'react-step-builder';
import { MultiStepForm } from '../../../components';
import { useHistory, useParams } from 'react-router-dom';
import { useVehicle, useVehicleFormSubmit, useVehicleFormOptions, useVehicleFormSteps } from '../hooks';
import { VehicleStakeholdersStep, VehicleStep } from '../../coverages/components/steps';
import { Loader, Render } from '@oforce/global-components';

export default function VehicleStakeholderUpdate({ onCompleted, onCancel }) {
  const { vehicleId } = useParams();
  const history = useHistory();

  const [disabledVehicleFields, setDisabledVehicleFields] = useState({});
  const [loadingSchemaValidation, setLoadingSchemaValidation] = useState(true);
  const [hasVehicleErrors, setHasVehicleErrors] = useState(false);

  const { vehicle } = useVehicle({ vehicleId });
  const requiredVehicleFields = vehicle?.requiredFields.reduce((acc, field) => {
    return { ...acc, [field]: true };
  }, {});

  const { formOptions } = useVehicleFormOptions();
  const { formSteps } = useVehicleFormSteps({ requiredVehicleFields, vehicle, formOptions, includeVinStep: false });

  const { formSubmit, loading, error } = useVehicleFormSubmit({
    vehicle,
    onCompleted: onCompleted ? onCompleted : () => history.goBack()
  });

  const coreVehicle = formSteps?.find(formStep => formStep?.stepName === 'VEHICLE_DETAILS');

  useEffect(() => {
    coreVehicle?.validationSchema
      .validate(coreVehicle?.initialValues)
      .then(() => {
        setHasVehicleErrors(false);
        setLoadingSchemaValidation(false);
      })
      .catch(() => {
        setHasVehicleErrors(true);
        setLoadingSchemaValidation(false);
      });
  }, [hasVehicleErrors, coreVehicle]);

  if (loadingSchemaValidation) return <Loader />;

  return (
    <MultiStepForm
      formOptions={formOptions}
      formSteps={formSteps}
      formSubmit={formSubmit}
      formProps={{
        isCreateMode: false,
        disabledVehicleFields,
        setDisabledVehicleFields
      }}
      title={hasVehicleErrors ? 'Edit Vehicle' : 'Edit Vehicle Stakeholders'}
      onCancel={() => (onCancel ? onCancel(vehicle) : history.goBack())}
      submitButtonText={'Edit'}
      submitButtonProps={{ loading, loadingText: 'Saving...' }}
      showButtonsOnBottom
      error={error}
    >
      {formProps => (
        <>
          <Render if={hasVehicleErrors}>
            <Steps>
              <VehicleStep {...formProps} requiredVehicleFields={requiredVehicleFields} disableVinUpdate={true} />
              <VehicleStakeholdersStep {...formProps} requiredVehicleFields={requiredVehicleFields} />
            </Steps>
          </Render>
          <Render if={!hasVehicleErrors}>
            <Steps>
              <VehicleStakeholdersStep {...formProps} requiredVehicleFields={requiredVehicleFields} />
            </Steps>
          </Render>
        </>
      )}
    </MultiStepForm>
  );
}
