import { useLazyQuery } from '@apollo/client';
import { GET_VEHICLE } from '../../../../vehicle/hooks/useVehicle';

export default function useLazyVehicle({ onCompleted }) {
  const [load, { data, loading, error }] = useLazyQuery(GET_VEHICLE, {
    onCompleted: ({ vehicle }) => {
      onCompleted && onCompleted(vehicle);
    }
  });

  const getVehicle = id => {
    if (!!id) {
      load({ variables: { id } });
    }
  };

  return {
    insured: data?.vehicle,
    getVehicle,
    loading,
    error
  };
}
