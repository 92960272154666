import { buildValidationSchema, initialValues } from '../model';
import * as InsuredStakeholder from '../../insuredStakeholder/model';
import { INSURED_DETAILS, INSURED_STAKEHOLDERS_DETAILS } from '../../coverages/components/steps/stepConstants';

export default function useInsuredFormSteps({ requiredFields, insured, formOptions, onlyStakeholders }) {
  const insuredStep = {
    name: insured ? 'Edit Insured' : 'Create Insured',
    stepName: INSURED_DETAILS,
    validationSchema: buildValidationSchema({ requiredFields }),
    initialValues: initialValues({ insured, formOptions })
  };

  const insuredStakeholdersStep = {
    stepName: INSURED_STAKEHOLDERS_DETAILS,
    ...InsuredStakeholder.step(insured, requiredFields, formOptions)
  };

  const steps = onlyStakeholders ? [insuredStakeholdersStep] : [insuredStep, insuredStakeholdersStep];

  return {
    formSteps: steps
  };
}
