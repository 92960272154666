import React from 'react';
import { InsuredStakeholderRow } from './';
import { BoxContainer, Table, TrGraphQLErrors } from '../../../components';
import { RenderData, TrLoader, TrPlaceholder } from '@oforce/global-components';
import { useInsuredStakeholders } from '../hooks';

export default function InsuredStakeholdersTable({ insured }) {
  const { stakeholders, loading, error } = useInsuredStakeholders({ insuredId: insured?.id });
  const colspan = 3;

  return (
    <BoxContainer className="px-3 py-2 mb-5">
      <Table responsive>
        <thead>
          <tr>
            <th>Relationship</th>
            <th>Contact</th>
            <th>Address</th>
          </tr>
        </thead>
        <tbody>
          <RenderData
            data={stakeholders}
            loading={loading}
            error={error}
            loadingComponent={<TrLoader colSpan={colspan} />}
            errorComponent={<TrGraphQLErrors colSpan={colspan} error={error} />}
            placeholderComponent={<TrPlaceholder colSpan={colspan}>None</TrPlaceholder>}
          >
            {data =>
              data.map(stakeholder => (
                <InsuredStakeholderRow key={`insured-stakeholder-row${stakeholder?.id}`} stakeholder={stakeholder} />
              ))
            }
          </RenderData>
        </tbody>
      </Table>
    </BoxContainer>
  );
}
