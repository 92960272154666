import React from 'react';
import { CustomDropdownItem, Loader, useToggle } from '@oforce/global-components';

import { ActionDropdown } from '../../../components/table-2.0';
import { Render } from '../../../components';

import {
  ExposureClearPendingTermination,
  ExposureEditStartDate,
  ExposureReactivate,
  ExposureTerminate
} from '../containers';
import { CellActionsEditStartDate, ExposureElectionChangePricing } from '.';
import {
  HideFromTypes,
  ACCOUNT_TYPES,
  useActiveAccount,
  ShowForTypes,
  ShowForRoles
} from '../../../shared/activeAccount';
import { ApprovalModal, RejectModal, UnRejectModal } from '../../coverages/components/pendingApproval';
import { useQuickActionPermissions } from '../../../components/quickActions';
import {
  ADD_TERM_DATE,
  CHANGE_PRICING_OPTION,
  CLEAR_TERM_DATE,
  OVERRIDE,
  REACTIVATE,
  REJECT,
  UNREJECT,
  UPDATE_START_DATE
} from '../../../components/quickActions/quickActionConstants';
const { CLIENT } = ACCOUNT_TYPES;

export default function CellActions({ policy, election, fetchNewestInsureds }) {
  const [showDropdown, toggleDropdown] = useToggle();
  const [showEditStartDate, toggleEditStartDate] = useToggle();
  const [showCancelTermination, toggleCancelTermination] = useToggle();
  const [showTerminateExposure, toggleTerminateExposure] = useToggle();
  const [showReactivateExposure, toggleReactivateExposure] = useToggle();
  const [showChangePricingOption, toggleChangePricingOption] = useToggle();
  const [approvalModalOpen, toggleApprovalModal] = useToggle(false);
  const [rejectModalOpen, toggleRejectModal] = useToggle(false);
  const [unRejectModalOpen, toggleUnRejectModal] = useToggle(false);
  const { actionAllowed, loading, noAllowedActions } = useQuickActionPermissions({
    exposureElectionId: election?.id,
    makeCall: showDropdown
  });

  const currentPricingOption = election?.pricingOption;
  const currentPricingOptionId = currentPricingOption?.id;

  const {
    roleIsAtLeast,
    ROLES: { ADMIN, COLLABORATOR, EXPOSURE_MANAGER },
    TYPES: { BROKER, CARRIER },
    isAdmin
  } = useActiveAccount();

  if (election?.policy?.apiOnlyExposureUpdates && !isAdmin) return null;

  return (
    <>
      <ActionDropdown onClick={toggleDropdown}>
        <Render if={loading}>
          <Loader />
        </Render>
        <Render if={!loading && noAllowedActions}>
          <div className={`text-muted fs-sm text-center `}>None</div>{' '}
        </Render>
        <Render if={!loading && !noAllowedActions}>
          <ShowForTypes types={[CARRIER, BROKER]}>
            <ShowForRoles roles={[COLLABORATOR, ADMIN]}>
              <Render if={actionAllowed(REJECT)}>
                <CustomDropdownItem color="danger" icon={['far', 'times']} onClick={toggleRejectModal}>
                  Reject
                </CustomDropdownItem>
              </Render>
              <Render if={actionAllowed(UNREJECT)}>
                <CustomDropdownItem color="warning" icon={['far', 'times']} onClick={toggleUnRejectModal}>
                  UnReject
                </CustomDropdownItem>
              </Render>
              <Render if={actionAllowed(OVERRIDE)}>
                <CustomDropdownItem color="success" icon={['far', 'check']} onClick={toggleApprovalModal}>
                  Approve
                </CustomDropdownItem>
              </Render>
            </ShowForRoles>
          </ShowForTypes>
          <CellActionsEditStartDate
            render={actionAllowed(UPDATE_START_DATE) && roleIsAtLeast(EXPOSURE_MANAGER)}
            election={election}
            onClick={toggleEditStartDate}
          />
          <Render if={actionAllowed(REACTIVATE) && roleIsAtLeast(EXPOSURE_MANAGER)}>
            <CustomDropdownItem color="success" icon={['far', 'sync-alt']} onClick={toggleReactivateExposure}>
              Reactivate
            </CustomDropdownItem>
          </Render>
          <Render if={actionAllowed(CLEAR_TERM_DATE) && roleIsAtLeast(EXPOSURE_MANAGER)}>
            <CustomDropdownItem color="danger" icon={['far', 'times']} onClick={toggleCancelTermination}>
              Clear Termination
            </CustomDropdownItem>
          </Render>
          <Render if={actionAllowed(ADD_TERM_DATE) && roleIsAtLeast(EXPOSURE_MANAGER)}>
            <CustomDropdownItem color="danger" icon={['far', 'times']} onClick={toggleTerminateExposure}>
              {election?.terminationDate ? 'Change Termination' : 'Terminate'}
            </CustomDropdownItem>
          </Render>
          <Render if={roleIsAtLeast(EXPOSURE_MANAGER) && actionAllowed(CHANGE_PRICING_OPTION)}>
            <HideFromTypes types={[CLIENT]}>
              <CustomDropdownItem
                color="info"
                icon={['far', 'pen']}
                disabled={!currentPricingOption}
                onClick={toggleChangePricingOption}
              >
                Change Pricing
              </CustomDropdownItem>
            </HideFromTypes>
          </Render>
        </Render>
      </ActionDropdown>

      {showEditStartDate && (
        <ExposureEditStartDate
          policy={policy}
          election={election}
          isOpen={showEditStartDate}
          toggle={toggleEditStartDate}
        />
      )}

      {showTerminateExposure && (
        <ExposureTerminate
          policy={policy}
          election={election}
          isOpen={showTerminateExposure}
          toggle={toggleTerminateExposure}
        />
      )}

      {showCancelTermination && (
        <ExposureClearPendingTermination
          exposureElectionId={election?.id}
          terminationDate={election?.terminationDate}
          isOpen={showCancelTermination}
          toggle={toggleCancelTermination}
        />
      )}

      {showReactivateExposure && (
        <ExposureReactivate
          policy={policy}
          exposureElection={election}
          onCompleted={fetchNewestInsureds}
          isOpen={showReactivateExposure}
          toggle={toggleReactivateExposure}
        />
      )}

      {showChangePricingOption && (
        <ExposureElectionChangePricing
          currentPricingOptionId={currentPricingOptionId}
          election={election}
          policy={policy}
          isOpen={showChangePricingOption}
          toggle={toggleChangePricingOption}
        />
      )}

      {approvalModalOpen && (
        <ApprovalModal
          isOpen={approvalModalOpen}
          toggle={toggleApprovalModal}
          election={election}
          electionId={election.id}
        ></ApprovalModal>
      )}
      {rejectModalOpen && (
        <RejectModal isOpen={rejectModalOpen} toggle={toggleRejectModal} election={election}></RejectModal>
      )}
      {unRejectModalOpen && (
        <UnRejectModal isOpen={unRejectModalOpen} toggle={toggleUnRejectModal} election={election}></UnRejectModal>
      )}
    </>
  );
}
