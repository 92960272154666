import { gql, useMutation } from '@apollo/client';

import { GET_POLICY_ADD_ONS } from './usePolicyAddOns';

import { useAddOnAppliesToOptions, useAddOnRequiredOnOptions } from '../../../views/addOns/hooks';

const CREATE_POLICY_ADD_ON = gql`
  mutation createPolicyAddOn($input: CreatePolicyAddOnInput!) {
    createPolicyAddOn(input: $input) {
      __typename
      id
      label
      name
      cost
      customData
      appliesToAllPricingOptions
      pricingOptionIds
      requiredOnAllPricingOptions
      requiredForPricingOptionIds
    }
  }
`;

export default function useAddOnCreate({ onCompleted = () => {}, policy, onError = () => {} }) {
  const [createPolicyAddOn, { data, loading, error, reset }] = useMutation(CREATE_POLICY_ADD_ON, {
    onCompleted,
    onError,
    refetchQueries: [{ query: GET_POLICY_ADD_ONS, variables: { policyId: policy?.id } }]
  });

  const { addOnAppliesToOptions } = useAddOnAppliesToOptions();
  const { addOnRequiredOnOptions } = useAddOnRequiredOnOptions();

  const handleSubmit = ({
    cost,
    label,
    name,
    customData,
    appliesToAllPricingOptions,
    pricings,
    requiredOnAllPricingOptions,
    requiredForPricings
  }) =>
    createPolicyAddOn({
      variables: {
        input: {
          policyId: policy?.id,
          customData: JSON.stringify(customData),
          name,
          label,
          cost: formatCost(cost),
          appliesToAllPricingOptions: appliesToAllPricingOptions.value,
          pricingOptionIds: pricings?.map(pricing => pricing?.value),
          requiredOnAllPricingOptions: requiredOnAllPricingOptions.value,
          requiredForPricingOptionIds: requiredForPricings?.map(pricing => pricing?.value)
        }
      }
    });

  function formatCost(cost) {
    if (typeof cost === 'number') return cost;
    return parseFloat(cost.replace('$', ''));
  }

  const initialValues = {
    name: '',
    label: '',
    cost: 0.0,
    customData: {},
    appliesToAllPricingOptions: addOnAppliesToOptions.find(x => x.value === true),
    pricings: [],
    requiredOnAllPricingOptions: addOnRequiredOnOptions.find(x => x.value === false),
    requiredForPricings: []
  };

  return {
    data,
    loading,
    error,
    reset,
    initialValues,
    handleSubmit
  };
}
