import { Button, Loader, useToggle } from '@oforce/global-components';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { BoxContainer, GraphQLErrors, LabelValueGroup, TabPage, Render } from '../../components';
import { BoxContainerTitle, BoxHeader } from '../../components/box';
import { formatAddonsList, formatBarePremium, formatDate, formatPremium, titleCase } from '../../utils/formatting';
import { ElectionDocuments, UploadDocumentsModal } from '../document/components';
import { ExposureElectionStatusBadge } from '../exposureElections/components';
import { QuickActions } from '../../components/quickActions';
import { useGetExposureElection } from './hooks';
import { ExposureElectionCoverageHistory } from './components';
import { useActiveAccount } from '../../shared/activeAccount';
import { SubContractedCoverages } from '../coverages/components';
import { normalizeInsuredFullName } from '../insured/utils';
import { FeatureFlag, HideFromRolesAndTypes } from '../../shared/activeAccount/components';
import { FLAGGED_FEATURES } from '../../shared/activeAccount/constants';
const { MASTER_SUB_RELATIONSHIP } = FLAGGED_FEATURES;

export default function Coverage() {
  const history = useHistory();
  const { exposureElectionId } = useParams();
  const { loading, error, election } = useGetExposureElection({ exposureElectionId });
  const [showDocumentUploadModal, toggleDocumentUploadModal] = useToggle();
  const {
    ROLES: { OBSERVER },
    TYPES: { CLAIMS_ADJUSTER },
    isAdmin
  } = useActiveAccount();

  const policy = election?.policy;
  const insuranceType = election?.insuranceType;
  const client = election?.client;
  const insured = election?.insured;

  const redirectToReactivatedExposure = ({ reactivateExposureElection: { id } }) => {
    history.push(`/insureds/${insured?.id}/elections/${id}`);
  };

  if (loading) return <Loader />;
  if (error) return <GraphQLErrors error={error} />;

  const shouldShowDocumentUploadButton = policy => {
    if (policy?.archived) return false;
    else if (policy?.expirationDate !== null && new Date(policy?.expirationDate) <= new Date()) return false;
    else return true;
  };

  return (
    <>
      <TabPage showBackButton title={'Coverage Details'}>
        <BoxContainer className={`px-3 py-2`}>
          <BoxHeader className="py-1">
            <Row noGutters>
              <Col>
                <Row noGutters>
                  <BoxContainerTitle fontSize="fs-xl">{insuranceType?.name}</BoxContainerTitle>
                  <ExposureElectionStatusBadge election={election} className="ml-3" />{' '}
                </Row>
                <Row noGutters>
                  <LabelValueGroup
                    labelWidth="100px"
                    pad={false}
                    showBorder={false}
                    className="text-muted ml-2"
                    label="Policy"
                    value={<Link to={`/policies/${election?.policyId}`}>{policy?.name}</Link>}
                  />
                </Row>
                <Row noGutters className="mb-3">
                  <LabelValueGroup
                    labelWidth="100px"
                    pad={false}
                    showBorder={false}
                    label="Client"
                    className="text-muted ml-2"
                    value={<Link to={`/clients/${client?.id}`}>{client?.name}</Link>}
                  />
                </Row>
              </Col>
              <HideFromRolesAndTypes roles={[OBSERVER]} types={[CLAIMS_ADJUSTER]}>
                <Render if={isAdmin || !policy?.apiOnlyExposureUpdates}>
                  <Col xs="4">
                    <BoxContainerTitle className="fs-sm mt-1">Quick Actions</BoxContainerTitle>
                    <QuickActions
                      className="ml-2 mt-0"
                      election={election}
                      onReactivated={redirectToReactivatedExposure}
                    />
                  </Col>
                </Render>
              </HideFromRolesAndTypes>
            </Row>
          </BoxHeader>
          <FeatureFlag feature={MASTER_SUB_RELATIONSHIP}>
            <LabelValueGroup
              pad={false}
              className={'mt-2'}
              label="Contract Type"
              value={titleCase(election?.contractType)}
            />
            <Render if={election?.contractType === 'SUB'}>
              <LabelValueGroup
                pad={false}
                className={'mt-2'}
                label="Master Contract"
                value={
                  <Link
                    to={`/insureds/${election?.masterElection?.insured?.id}/elections/${election?.masterElection?.id}`}
                  >
                    {normalizeInsuredFullName(election?.masterElection?.insured)}
                  </Link>
                }
              />
            </Render>
          </FeatureFlag>
          <LabelValueGroup pad={false} className={'mt-2'} label="Start Date" value={formatDate(election?.startDate)} />
          {election?.terminationDate && (
            <LabelValueGroup
              pad={false}
              className={'mt-1'}
              label="Termination Date"
              value={formatDate(election?.terminationDate)}
            />
          )}
          <LabelValueGroup pad={false} className={'mt-1'} label="Premium" value={formatBarePremium(election)} />
          <LabelValueGroup pad={false} className={'mt-1'} label="Premium Total" value={formatPremium(election)} />
          <LabelValueGroup
            pad={false}
            className={'mt-1'}
            label="Master Certificate Number"
            value={election?.masterCertificateNumber}
          />
          <LabelValueGroup
            pad={false}
            className={'mt-1'}
            label="Pricing Option"
            value={election?.pricingOption?.label}
          />
          <LabelValueGroup pad={false} className={'mt-1'} label="Unit Number" value={election?.vehicle?.unitNumber} />
          {formatAddonsList(election) && (
            <LabelValueGroup pad={false} className={'mt-1'} label="Add Ons" value={formatAddonsList(election)} />
          )}
        </BoxContainer>
      </TabPage>

      <FeatureFlag feature={MASTER_SUB_RELATIONSHIP}>
        <Render if={election?.contractType === 'MASTER'}>
          <SubContractedCoverages election={election} />
        </Render>
      </FeatureFlag>

      <Row>
        <Col>
          <TabPage title="Coverage History">
            <BoxContainer className="pt-2 pb-2">
              <ExposureElectionCoverageHistory election={election} />
            </BoxContainer>
          </TabPage>
        </Col>
        <Col>
          <TabPage
            title="Documents"
            buttons={
              <Render if={shouldShowDocumentUploadButton(election?.policy)}>
                <HideFromRolesAndTypes roles={[OBSERVER]} types={[CLAIMS_ADJUSTER]}>
                  <Button color="info" outline icon={['far', 'file-upload']} onClick={toggleDocumentUploadModal}>
                    Upload
                  </Button>
                </HideFromRolesAndTypes>
              </Render>
            }
          >
            <ElectionDocuments election={election} />
          </TabPage>
        </Col>
      </Row>

      {showDocumentUploadModal && (
        <UploadDocumentsModal show={showDocumentUploadModal} toggle={toggleDocumentUploadModal} election={election} />
      )}
    </>
  );
}
