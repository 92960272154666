import React from 'react';
import { ReactSelectField } from '@oforce/global-components';

export default function BooleanSelect({ name, label, required, className, isClearable }) {
  const options = [
    { label: 'True', value: true },
    { label: 'False', value: false }
  ];

  return (
    <ReactSelectField
      isClearable={isClearable}
      required={required}
      className={className}
      label={label}
      options={options}
      name={name}
    />
  );
}
