export const PRORATE_SETTINGS = {
  NO_PRORATE: 'NO_PRORATE',
  WEEKLY_DAILY: 'WEEKLY_DAILY',
  WEEKLY_ANNUAL_DAILY: 'WEEKLY_ANNUAL_DAILY',
  MONTHLY_DAILY: 'MONTHLY_DAILY',
  MONTHLY_ANNUAL_DAILY: 'MONTHLY_ANNUAL_DAILY'
};

export const WEEKLY = 'WEEKLY';
export const MONTHLY = 'MONTHLY';

export default function useProrateSettings() {
  const options = [
    {
      label: 'Weekly Annual Daily',
      value: PRORATE_SETTINGS.WEEKLY_ANNUAL_DAILY,
      category: WEEKLY
    },
    {
      label: 'Weekly Daily',
      value: PRORATE_SETTINGS.WEEKLY_DAILY,
      category: WEEKLY
    },
    {
      label: 'Monthly Annual Daily',
      value: PRORATE_SETTINGS.MONTHLY_ANNUAL_DAILY,
      category: MONTHLY
    },
    {
      label: 'Monthly Daily',
      value: PRORATE_SETTINGS.MONTHLY_DAILY,
      category: MONTHLY
    }
  ];

  return {
    options,
    PRORATE_SETTINGS,
    CATEGORIES: { WEEKLY, MONTHLY }
  };
}
