import React from 'react';
import { Td, Tr } from '../../../components/table-2.0';
import { backendNameToLabel } from '../../../utils/formatting';
import { NameCell } from '../../../components/tables';

export default function NotificationJobRow({ notification }) {
  return (
    <Tr>
      <Td>{backendNameToLabel(notification?.templateGroup)}</Td>
      <Td>
        {!!notification?.insured ? (
          <NameCell insured={notification?.insured} linkAddress={`/insureds/${notification?.insured?.id}`} />
        ) : (
          notification?.recipientName
        )}
      </Td>
      <Td>{notification?.recipientEmail}</Td>
      <Td>{notification?.subject}</Td>
      <Td>{notification?.status}</Td>
      <Td>{notification?.failureReason}</Td>
    </Tr>
  );
}
