import React from 'react';
import { useHistory } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import * as yup from 'yup';

import { PolicyCreateForm } from '../../policy/components';
import { Steps } from 'react-step-builder';
import { MultiStepForm } from '../../../components';

export const CREATE_POLICY = gql`
  mutation createPolicy($input: CreatePolicyInput!) {
    createPolicy(input: $input) {
      id
      name
      client {
        id
        name
      }
      product {
        id
        name
      }
    }
  }
`;

export default function PolicyCreate({ refetchPolicies, onError = () => {} }) {
  const history = useHistory();

  const [createPolicy, { loading, error }] = useMutation(CREATE_POLICY, {
    onCompleted: ({ createPolicy }) => {
      refetchPolicies();
      history.push(`/policies/${createPolicy.id}`);
    },
    onError
  });

  const initialValues = {
    name: '',
    product: null,
    client: null
  };

  const formSubmit = values => {
    createPolicy({
      variables: {
        input: {
          name: values?.name,
          clientId: values?.client.value,
          productId: values?.product.value
        }
      }
    });
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required('Required'),
    client: yup.object().nullable().required('Required'),
    product: yup.object().nullable().required('Required')
  });

  const formSteps = [
    {
      name: 'Create Policy',
      initialValues,
      validationSchema
    }
  ];

  return (
    <MultiStepForm
      title="Create Policy"
      formOptions={{}}
      formSteps={formSteps}
      formSubmit={formSubmit}
      onCancel={() => history.push(`/policies`)}
      submitButtonText="Save"
      submitButtonProps={{ loading, loadingText: 'Saving...' }}
      showButtonsOnBottom
      showProgressBar={false}
      loading={loading}
      error={error}
    >
      {formProps => (
        <Steps>
          <PolicyCreateForm {...formProps} />
        </Steps>
      )}
    </MultiStepForm>
  );
}
