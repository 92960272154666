import React from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { Button } from '@oforce/global-components';

import {
  ProductRequiredFields,
  ProductRetailCommissions,
  ProductFees,
  ProductAddOns,
  CoverageParameters
} from '../../views';
import { BoxContainer, BoxItem, BoxItemTitle } from '../../components';
import { HideFromRoles, useActiveAccount } from '../../shared/activeAccount';

// TODO: add counts in the subtitles of each of these sections

export default function ProductConfiguration({ product }) {
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const {
    ROLES: { EXPOSURE_MANAGER }
  } = useActiveAccount();

  return (
    <>
      <Switch>
        <Route exact path={path}>
          <ConfigurationBox
            title="Coverage Parameters"
            // subtitle="0 coverage parameters configured"
            onConfigure={() => history.push(`${url}/coverage-parameters`)}
          />
          <HideFromRoles roles={[EXPOSURE_MANAGER]}>
            <ConfigurationBox
              title="Retail Commissions"
              // subtitle="0 commissions configured"
              onConfigure={() => history.push(`${url}/retail-commissions`)}
            />
          </HideFromRoles>
          <ConfigurationBox
            title="Fees"
            // subtitle="0 fees configured"
            onConfigure={() => history.push(`${url}/fees`)}
          />
          <ConfigurationBox
            title="Add-Ons"
            // subtitle="0 add-ons configured"
            onConfigure={() => history.push(`${url}/add-ons`)}
          />
          <ConfigurationBox
            title="Required Fields"
            // subtitle="0 required fields configured"
            onConfigure={() => history.push(`${url}/required-fields`)}
          />
        </Route>
        <Route path={`${path}/coverage-parameters`}>
          <CoverageParameters productId={product?.id} />
        </Route>
        <Route path={`${path}/retail-commissions`}>
          <HideFromRoles roles={[EXPOSURE_MANAGER]}>
            <ProductRetailCommissions product={product} />
          </HideFromRoles>
        </Route>
        <Route path={`${path}/fees`}>
          <ProductFees product={product} />
        </Route>
        <Route path={`${path}/add-ons`}>
          <ProductAddOns product={product} />
        </Route>
        <Route path={`${path}/required-fields`}>
          <ProductRequiredFields product={product} />
        </Route>
      </Switch>
    </>
  );
}

const ConfigurationBox = ({ title, subtitle, onConfigure }) => (
  <BoxContainer className="mb-4">
    <BoxItem size="lg">
      <BoxItemTitle title={title} subtitle={subtitle} />
      <Button color="info" onClick={onConfigure}>
        Configure
      </Button>
    </BoxItem>
  </BoxContainer>
);
