import { uuid } from '../../../../../utils/regex';
import Papa from 'papaparse';
import { useState } from 'react';
import { backendNameToCamelCaseLabel } from '../../../../../utils/formatting';

export default function useParseExposureCsvFile({ policy, onComplete, policyPricingOptionId, addOnIds }) {
  const [loading, setIsLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [error, setError] = useState([]);

  const rowHasValues = row => {
    const rowKeys = Object.keys(row);
    const hasValues = rowKeys.reduce((boolean, key) => {
      if (!!row[key]) return true;
      return boolean;
    }, false);

    return hasValues;
  };

  const prepRows = data => {
    return data
      ?.filter(row => rowHasValues(row))
      .map((x, index) => {
        return Object.assign({}, x, { rowId: uuid(), policyPricingOptionId, addOnIds, index, policyId: policy?.id });
      });
  };

  const parseData = ({ document }) => {
    setIsLoading(true);

    Papa.parse(document[0], {
      header: true,
      transformHeader: header => backendNameToCamelCaseLabel(header),
      complete: results => {
        const rows = prepRows(results?.data);
        setResults(rows);
        setIsLoading(false);
        onComplete && onComplete(rows);
      },
      error: () => {
        setError('Error parsing file');
      }
    });
  };

  return { parseData, loading, error, results };
}
