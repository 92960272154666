import React from 'react';
import { Tooltip, UncontrolledTooltip } from 'reactstrap';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Badge, useToggle, Render } from '@oforce/global-components';
import { useDownloadFile } from '../../../hooks';
import { ActionDropdown, Td } from '../../../components';
import { backendNameToLabel, formatDate } from '../../../utils/formatting';
import { useActiveAccount } from '../../../shared/activeAccount';
import { DisableDocumentSwitch } from '.';
import { HardDeletePolicyDocument } from '../containers';
import { EmptyCell } from '../../../components/tables';

export default function DocumentsTableRow({ policy, pricingOptions, doc, disabled }) {
  const {
    ROLES: { ADMIN, EXPOSURE_MANAGER, OBSERVER },
    TYPES: { CLAIMS_ADJUSTER },
    hideFromRolesAndTypes,
    showForRoles
  } = useActiveAccount();
  const history = useHistory();
  const { url } = useRouteMatch();

  const [showPricingOptionsPopover, togglePricingOptionsPopover] = useToggle();
  const [showHardDeleteDocument, toggleHardDeleteDocument] = useToggle();

  const documentDisabled = doc?.excludeFromAllPolicyPricingOptions;

  const pricingOptionsBadgeText = doc?.appliesToAllPricingOptions
    ? `All Pricing Options (${pricingOptions?.length})`
    : `Some Pricing Options (${doc?.pricingOptionIds?.length})`;

  const assignedPricingOptions = doc?.appliesToAllPricingOptions
    ? pricingOptions
    : pricingOptions?.filter(x => doc?.pricingOptionIds?.includes(x?.id));

  const { download, loading } = useDownloadFile({
    url: doc?.url,
    filename: doc?.filename
  });

  const requiredStatesTooltipId = `required-states-${doc?.id}`;
  const openEdit = () => history.push(`${url}/${doc?.id}/update`);
  const allowAction = !hideFromRolesAndTypes({ types: [CLAIMS_ADJUSTER], roles: [EXPOSURE_MANAGER, OBSERVER] });

  return (
    <tr className={`${disabled ? 'disabled' : ''}`}>
      <td>
        <div className="font-weight-500">{doc?.name}</div>
        <div className="font-weight-300 fs-sm text-muted">{backendNameToLabel(doc?.type)} • PDF</div>
      </td>
      <td>
        <div className="font-weight-400">{formatDate(doc?.effectiveDate)}</div>
      </td>
      <td>
        {!!doc?.expirationDate ? (
          <div className="font-weight-400">{formatDate(doc?.expirationDate)}</div>
        ) : (
          <EmptyCell />
        )}
      </td>
      <td>
        <SourceBadge docId={doc?.id} source={doc?.source} />
      </td>
      <td>
        <Render if={doc?.source === 'CLIENT'}>
          <Badge
            id={`a-${doc?.id}`}
            color="secondary"
            size="sm"
            className="cursor-pointer"
            iconAfter={['far', 'info-circle']}
          >
            {pricingOptionsBadgeText}
          </Badge>
          <Tooltip
            target={`a-${doc?.id}`}
            placement="bottom"
            isOpen={showPricingOptionsPopover}
            toggle={togglePricingOptionsPopover}
          >
            {pricingOptions?.map(pp => (
              <div key={pp?.id}>{pp?.label}</div>
            ))}
          </Tooltip>
        </Render>
        <Render if={doc?.source !== 'CLIENT'}>
          <Badge
            id={`a-${doc?.id}`}
            color="secondary"
            size="sm"
            className="cursor-pointer"
            iconAfter={['far', 'info-circle']}
          >
            {pricingOptionsBadgeText}
          </Badge>
          <Tooltip
            target={`a-${doc?.id}`}
            placement="bottom"
            isOpen={showPricingOptionsPopover}
            toggle={togglePricingOptionsPopover}
          >
            {assignedPricingOptions?.map(pp => (
              <div key={pp?.id}>{pp?.label}</div>
            ))}
          </Tooltip>
        </Render>
      </td>
      <Td>
        <Render if={doc?.appliesToStates?.length > 0 && doc?.excludeFromStates?.length === 0}>
          <Badge
            size="sm"
            id={requiredStatesTooltipId}
            color="secondary"
            className="cursor-pointer"
            iconAfter={['far', 'info-circle']}
          >
            Applies to ({doc?.appliesToStates?.length}) States
          </Badge>
          <UncontrolledTooltip target={requiredStatesTooltipId} placement="bottom">
            {doc?.appliesToStates?.join(', ')}
          </UncontrolledTooltip>
        </Render>
        <Render if={doc?.excludeFromStates?.length > 0}>
          <Badge
            size="sm"
            id={requiredStatesTooltipId}
            color="secondary"
            className="cursor-pointer"
            iconAfter={['far', 'info-circle']}
          >
            Excluded From ({doc?.excludeFromStates?.length}) States
          </Badge>
          <UncontrolledTooltip target={requiredStatesTooltipId} placement="bottom">
            {doc?.excludeFromStates?.join(', ')}
          </UncontrolledTooltip>
        </Render>
        <Render if={!doc?.appliesToStates || doc?.appliesToStates?.length === 0}>
          <span className="fs-sm text-gray-500">None</span>
        </Render>
      </Td>
      <td align="right">
        <Render if={doc?.source === 'CLIENT'}>
          <span className="fs-sm text-gray-500">None</span>
        </Render>
        <Render if={doc?.source === 'PRODUCT'}>
          <DisableDocumentSwitch productDocumentId={doc?.id} disabled={documentDisabled} policyId={policy?.id} />
        </Render>
        <Render if={doc?.source === 'POLICY'}>
          <>
            <ActionDropdown
              onDownload={download}
              downloading={loading}
              onEdit={allowAction && openEdit}
              onHardDelete={showForRoles([ADMIN]) && toggleHardDeleteDocument}
            />
            <Render if={showHardDeleteDocument}>
              <HardDeletePolicyDocument doc={doc} isOpen={showHardDeleteDocument} toggle={toggleHardDeleteDocument} />
            </Render>
          </>
        </Render>
      </td>
    </tr>
  );
}

const SourceBadge = ({ source, docId }) => {
  const color = source === 'PRODUCT' ? 'info' : source === 'POLICY' ? 'primary' : 'success';
  const sourceText =
    source === 'PRODUCT' ? 'Product Document' : source === 'POLICY' ? 'Policy Document' : 'Client Document';

  return (
    <Badge id={`s-${docId}`} color={color} size="md">
      {sourceText}
    </Badge>
  );
};
