import React from 'react';
import { Button, useToggle } from '@oforce/global-components';

import { BoxItem, BoxItemPlaceholder } from '../../../components';

import InviteAccountToPolicy from './InviteAccountToPolicy';
import { ACCOUNT_ROLES, ACCOUNT_TYPES, HideFromRoles, HideFromTypes } from '../../../shared/activeAccount';

export default function NoAccountOrInvite({ accountType, invitationRole, policyId }) {
  const [showInviteAccountToPolicy, toggleInviteAccountToPolicy] = useToggle();
  const { CLIENT } = ACCOUNT_TYPES;
  const { EXPOSURE_MANAGER } = ACCOUNT_ROLES;

  return (
    <>
      <BoxItem size="lg">
        <BoxItemPlaceholder>No account assigned</BoxItemPlaceholder>

        <HideFromTypes types={[CLIENT]}>
          <HideFromRoles roles={[EXPOSURE_MANAGER]}>
            <Button color="info" size="sm" onClick={toggleInviteAccountToPolicy}>
              Invite {accountType} Account
            </Button>
          </HideFromRoles>
        </HideFromTypes>
      </BoxItem>
      <HideFromTypes types={[CLIENT]}>
        <HideFromRoles roles={[EXPOSURE_MANAGER]}>
          <InviteAccountToPolicy
            accountType={accountType}
            isOpen={showInviteAccountToPolicy}
            toggle={toggleInviteAccountToPolicy}
            invitationRole={invitationRole}
            policyId={policyId}
          />
        </HideFromRoles>
      </HideFromTypes>
    </>
  );
}
