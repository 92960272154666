import { useToggle } from '@oforce/global-components';
import React, { useState } from 'react';
import { Bold, BoxContainer, BoxItem, ConfirmModal, Render } from '../';
import { RowUpdate, SummaryHeader } from './';
import { backendNameToCamelCaseLabel } from '../../utils/formatting';

export default function RowSummary({
  row,
  updateRow,
  isUpdating,
  toggle,
  formOptions,
  rows,
  validatedRows,
  useRowValidation
}) {
  const [confirmOpen, toggleConfirm] = useToggle();
  const [rowValues, setRowValues] = useState(row);
  const { validateRow, loading } = useRowValidation({
    onCompleted: values => {
      setRowValues(values);
      updateRow(values);
    }
  });
  const { index } = row;

  const setRowValue = attrs => {
    const newValues = { ...rowValues, ...attrs };
    setRowValues(newValues);
  };

  const onSave = () => {
    if (submittingEmptyValues(rowValues)) {
      toggleConfirm();
    } else {
      validateRow(rowValues, rows, validatedRows);
      toggle();
    }
  };

  const onCancel = () => {
    setRowValues(row);
    toggle();
  };

  const submittingEmptyValues = values => {
    const previouslyBrokenFields = row?.allBrokenFields?.map(field => backendNameToCamelCaseLabel(field));
    const incomingEmptyFields = previouslyBrokenFields.reduce((acc, field) => {
      if (values[field] === '' || values[field] === null) {
        return [...acc, field];
      }
      return acc;
    }, []);

    return incomingEmptyFields.length > 0;
  };

  return (
    <>
      <BoxContainer className={'mb-2'}>
        <BoxItem
          size="md"
          onClick={isUpdating ? null : toggle}
          icon={isUpdating ? ['far', 'angle-down'] : ['far', 'angle-right']}
        >
          <Render if={!isUpdating}>
            <SummaryHeader loading={loading} row={rowValues} formOptions={formOptions} lineNumber={index + 1} />
          </Render>
          <Render if={isUpdating}>
            <RowUpdate
              lineNumber={index + 1}
              formOptions={formOptions}
              row={rowValues}
              rows={rows}
              onChange={setRowValue}
              onSave={onSave}
              loading={loading}
              onCancel={onCancel}
            />
          </Render>
        </BoxItem>
      </BoxContainer>
      <ConfirmModal
        title="Submitting Empty Values"
        isOpen={confirmOpen}
        toggle={toggleConfirm}
        onConfirm={() => {
          toggleConfirm();
          validateRow(rowValues, rows, validatedRows);
          toggle();
        }}
        confirmButtonProps={{ loading, loadingText: 'Saving...' }}
      >
        You are submitting this row with empty fields. If these fields pass validation they will{' '}
        <Bold> not be available to edit again</Bold>.
        <br />
        <br />
        Are you sure you want to submit empty values?
      </ConfirmModal>
    </>
  );
}
