import { Address, Render, Td, Tr } from '../../../components';
import { ContactInfoCell } from '../../../components/tables';

export default function InsuredStakeholderRow({ stakeholder }) {
  const { id, stakeholderType, name, phone, email } = stakeholder;

  return (
    <Tr key={id}>
      <Td>
        <div className="d-flex flex-column align-items-start">
          <Render if={stakeholderType === 'BENEFICIARY'}>
            <span className="text-muted fs-xs">
              {stakeholder?.relationship}
            </span>
          </Render>
        </div>
      </Td>
      <Td>
        <div className="d-flex flex-column align-items-start">
          <span className="fs-sm">{name}</span>
          <div>
            <ContactInfoCell email={email} mobilePhone={phone} />
          </div>
        </div>
      </Td>
      <Td>
        <Address stakeholder={stakeholder} />
      </Td>
    </Tr>
  );
}
