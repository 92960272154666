import React, { useState } from 'react';
import { Td, Tr } from '@oforce/global-components';

import { ActionDropdown } from '../../../components';
import { UpdateVehicleType, RemoveVehicleType } from '.';

export default function VehicleTypeRow({ vehicleType }) {
  const [showUpdateVehicleType, setShowUpdateVehicleType] = useState(false);
  const toggleUpdateVehicleType = () => setShowUpdateVehicleType(!showUpdateVehicleType);

  const [showRemoveVehicleType, setShowRemoveVehicleType] = useState(false);
  const toggleRemoveVehicleType = () => setShowRemoveVehicleType(!showRemoveVehicleType);

  return (
    <Tr>
      <Td>{vehicleType?.name}</Td>
      <Td align="right">
        <ActionDropdown onEdit={toggleUpdateVehicleType} onRemove={toggleRemoveVehicleType} />

        <UpdateVehicleType
          vehicleType={vehicleType}
          showUpdateVehicleType={showUpdateVehicleType}
          toggleUpdateVehicleType={toggleUpdateVehicleType}
        />
        <RemoveVehicleType
          vehicleType={vehicleType}
          showRemoveVehicleType={showRemoveVehicleType}
          toggleRemoveVehicleType={toggleRemoveVehicleType}
        />
      </Td>
    </Tr>
  );
}
