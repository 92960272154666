import { gql } from '@apollo/client';
import { useMemo, useState } from 'react';
import { useResettableMutation } from '../../../hooks/useResettableMutation';

export const SEND_INVOICE_EMAIL = gql`
  mutation SendInvoiceEmails($invoiceId: Int!, $emailAddresses: [String]!) {
    sendInvoiceEmail(invoiceId: $invoiceId, emailAddresses: $emailAddresses)
  }
`;

export default function useSendInvoiceEmails({ invoice, onCompleted, onError = () => {} }) {
  const [send, { loading, error }] = useResettableMutation(SEND_INVOICE_EMAIL, {
    onCompleted,
    onError
  });

  const [invoiceEmailAddresses, setInvoiceEmails] = useState([]);

  const configuredEmailAddresses = useMemo(() => {
    const addresses = invoice?.invoiceEmailAddresses?.map(email => ({ label: email, value: email }));
    setInvoiceEmails(addresses);
    return addresses;
  }, [invoice]);

  const handleSubmit = () => {
    send({
      variables: {
        invoiceId: invoice?.id,
        emailAddresses: invoiceEmailAddresses?.map(({ value }) => value) || []
      }
    });
  };

  return {
    handleSubmit,
    loading,
    error,
    reset: () => setInvoiceEmails(configuredEmailAddresses),
    invoiceEmailAddresses,
    setInvoiceEmails
  };
}
