import { useState } from 'react';
import { CustomInput } from 'reactstrap';
import { BoxContainer, FormSection, TrGraphQLErrors } from '../../../components';
import { TrLoader, TrPlaceholder, RenderData, Td, Tr } from '@oforce/global-components';
import { Table } from '../../../components/table-2.0';
import { useProductPricings } from '../../productPricing/hooks';

export default function SelectPricingOptionsStep({ formik, allProductPricingOptionIds, productId }) {
  const colSpan = 2;
  const [selectAll, setSelectAll] = useState(true);
  const { pricings: productPricingOptions, loading, error } = useProductPricings({ productId });

  const toggleSelectAll = selectAll => {
    !selectAll
      ? formik?.setFieldValue('productPricingOptionIds', allProductPricingOptionIds)
      : formik?.setFieldValue('productPricingOptionIds', []);
    setSelectAll(!selectAll);
  };

  return (
    <FormSection title="Select Product Pricing Options to Sync">
      <BoxContainer>
        <Table responsive>
          <thead>
            <tr>
              <th>
                <CustomInput
                  id="toggleSelectAllPricingOptionIds"
                  type="checkbox"
                  checked={selectAll}
                  onChange={() => toggleSelectAll(selectAll)}
                  className="mb-1"
                />
              </th>
              <th>Pricing Option</th>
            </tr>
          </thead>
          <tbody>
            <RenderData
              data={productPricingOptions}
              loading={loading}
              error={error}
              loadingComponent={<TrLoader colSpan={colSpan} />}
              errorComponent={<TrGraphQLErrors colSpan={colSpan} error={error} />}
              placeholderComponent={<TrPlaceholder colSpan={colSpan}>No Product Pricing Options</TrPlaceholder>}
            >
              {productPricingOptions =>
                productPricingOptions?.map((productPricingOption, i) => (
                  <Tr key={i}>
                    <Td>
                      <CustomInput
                        id={productPricingOption?.id}
                        type="checkbox"
                        checked={formik?.values?.productPricingOptionIds?.includes(productPricingOption?.id) || false}
                        onChange={() => {
                          if (formik?.values?.productPricingOptionIds?.includes(productPricingOption?.id)) {
                            formik?.setFieldValue(
                              'productPricingOptionIds',
                              formik?.values?.productPricingOptionIds?.filter(
                                productPricingOptionId => productPricingOptionId !== productPricingOption?.id
                              )
                            );
                          } else {
                            formik?.setFieldValue('productPricingOptionIds', [
                              ...formik?.values?.productPricingOptionIds,
                              productPricingOption?.id
                            ]);
                          }
                        }}
                        className="mb-1"
                      />
                    </Td>
                    <Td>{productPricingOption?.name || productPricingOption?.label}</Td>
                  </Tr>
                ))
              }
            </RenderData>
          </tbody>
        </Table>
      </BoxContainer>
    </FormSection>
  );
}
