import React from 'react';
import { Form } from 'formik';
import { ReactSelectField, Render } from '@oforce/global-components';

import { GraphQLErrors } from '../../../components';
import { AddOnsRequired } from '../../../views/addOns/components';
import { AddOnSelectFormik } from '../../../views/addOns/components';
import { usePricingOptionAddOns } from '../../../views/policyInsureds/hooks';

export default function ExposureElectionForm({ formik, formOptions, error }) {
  const selectedPricingOptionId = formik?.values?.pricingOption?.value;
  const { requiredAddOns = [] } = usePricingOptionAddOns({ pricingOptionId: selectedPricingOptionId });

  return (
    <Form>
      <GraphQLErrors error={error} />
      <ReactSelectField
        label="Pricing Option"
        name="pricingOption"
        onChange={() => formik.setFieldValue('addOns', [])}
        options={formOptions?.policyPricing?.options}
        isLoading={formOptions?.policyPricing?.loading}
      />

      <Render if={requiredAddOns?.length > 0}>
        <AddOnsRequired addOns={requiredAddOns} />
      </Render>
      <AddOnSelectFormik pricingOptionId={selectedPricingOptionId} />
    </Form>
  );
}
