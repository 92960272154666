import { useHistory } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';

import { isUUID } from '../../../utils/regex';
import { convertPercentageToDecimal, convertToFloat } from '../../../utils';
import { fragments } from '../../../views/policyPricing/hooks/usePolicyPricings';
import { GET_POLICY_PRICING_COUNT } from '../../../views/policy/hooks/usePolicy';
import { useParseCoverageParameters } from '../../../views/coverageParameters/hooks';

export const GET_POLICY_FACTORABLE_FIELDS = gql`
  query policy($id: ID!) {
    policy(id: $id) {
      id
      hasApiFactoredField
    }
  }
`;

export const CREATE_PRICING_OPTION = gql`
  mutation CreatePolicyPricingOption($input: CreatePolicyPricingOptionInput!) {
    createPricingOption: createPolicyPricingOption(input: $input) {
      id
      pricingOptions {
        ...PolicyPricingOption
      }
    }
  }
  ${fragments.policyPricingOption}
`;

export const UPDATE_PRICING_OPTION = gql`
  mutation UpdatePolicyPricingOption($input: EditPolicyPricingOptionInput!) {
    updatePricingOption: editPolicyPricingOption(input: $input) {
      id
      pricingOptions {
        ...PolicyPricingOption
      }
    }
  }
  ${fragments.policyPricingOption}
`;

export default function usePolicyPricingFormSubmit({ coverageParameters, isCreateMode, policyId, pricing }) {
  const history = useHistory();
  const { normalizeParameterValuesForSubmit } = useParseCoverageParameters();

  const normalizeParamValues = values => {
    return Object.keys(values).reduce(
      (acc, key) => {
        if (isUUID(key)) {
          const coverageParameters = [...acc.coverageParameters, { id: key, value: values[key].toString() }];
          return { ...acc, coverageParameters };
        }
        return { ...acc, [key]: values[key] };
      },
      { coverageParameters: [] }
    );
  };

  const [create, { loading: creating, error: createError }] = useMutation(CREATE_PRICING_OPTION, {
    onCompleted: () => history.push(`/policies/${policyId}/pricing`),
    onError: () => {},
    refetchQueries: [{ query: GET_POLICY_PRICING_COUNT, variables: { policyId } }]
  });

  const [update, { loading: updating, error: updateError }] = useMutation(UPDATE_PRICING_OPTION, {
    onCompleted: () => history.push(`/policies/${policyId}/pricing`),
    onError: () => {},
    refetchQueries: [{ query: GET_POLICY_FACTORABLE_FIELDS, variables: { id: policyId } }]
  });

  function formSubmit({
    percentageMinimum,
    percentageMaximum,
    rateMode,
    factoredField,
    vehicleTypes,
    isPercentageFactor,
    rate,
    label,
    name,
    ...values
  }) {
    const formValues = normalizeParameterValuesForSubmit(pricing ?? { coverageParameters }, { formValues: values });

    const normalizedValues = {
      ...formValues,
      label,
      name,
      percentageMaximum: percentageMaximum !== '' ? convertToFloat(percentageMaximum) : null,
      percentageMinimum: percentageMinimum !== '' ? convertToFloat(percentageMinimum) : null,
      rateMode: rateMode.value,
      factoredField: factoredField ? factoredField?.value : null,
      vehicleTypeIds: vehicleTypes?.map(type => type.value) || [],
      rate: isPercentageFactor ? convertPercentageToDecimal(rate) : convertToFloat(rate),
      isPercentageFactor
    };
    const pricingInput = normalizeParamValues(normalizedValues);

    if (isCreateMode) {
      create({
        variables: { input: { policyId, ...pricingInput } }
      });
    } else {
      update({
        variables: { input: { policyPricingOptionId: pricing?.id, ...pricingInput } }
      });
    }
  }

  return {
    formSubmit,
    loading: creating || updating,
    error: createError || updateError
  };
}
