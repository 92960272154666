import { useState } from 'react';

export default function useCopyToClipboard() {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = field => {
    navigator.clipboard.writeText(field)
    setCopied(true)

    setTimeout(() => setCopied(false), 2000)
  };

  return { copyToClipboard, copied, setCopied }
}
