import { useQuery } from '@apollo/client';
import { GET_ELECTION_DOCUMENTS } from '../model';

export default function useElectionDocuments({ exposureElectionId }) {
  const { data, loading, error } = useQuery(GET_ELECTION_DOCUMENTS, {
    skip: !exposureElectionId,
    variables: { id: exposureElectionId }
  });

  const electionDocuments =
    data?.election?.documents?.exposureDocuments.map(x => ({
      ...x,
      source: 'EXPOSURE_DOCUMENT',
      exposureDocumentId: x.id
    })) || [];
  const pricingOptionDocuments =
    data?.election?.documents?.documentRelationships
      .filter(x => x.source !== 'CLIENT')
      .map(x => ({
        ...x,
        source: 'DOCUMENT_RELATIONSHIP',
        documentRelationshipId: x.id
      })) || [];
  const documents = [...electionDocuments, ...pricingOptionDocuments];

  return {
    inCompliance: data?.election?.compliance?.inCompliance,
    documents,
    loading,
    error
  };
}
