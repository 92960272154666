import { gql, useQuery } from '@apollo/client';
import { backendNameToLabel } from '../../../utils/formatting';

export const GET_POLICY_FACTORABLE_FIELDS = gql`
  query policyFactorableFields($id: ID!) {
    policy(id: $id) {
      id
      insuranceType {
        id
        type
        requireVehicle
      }
      factorableFields
    }
  }
`;

export default function usePolicyFactorableFields({ policyId }) {
  const { data, loading, error } = useQuery(GET_POLICY_FACTORABLE_FIELDS, {
    variables: { id: policyId }
  });

  const startingOptions = {
    paramOptions:
      data?.policy?.factorableFields?.coverage_parameters?.map(value => ({
        label: backendNameToLabel(value),
        value
      })) || [],
    vehicleOptions:
      data?.policy?.factorableFields?.vehicle_fields?.map(value => ({ label: backendNameToLabel(value), value })) || [],
    apiOptions:
      data?.policy?.factorableFields?.api?.map(value => ({
        label: backendNameToLabel(value)?.replace("Api ", ""),
        value
      })) || []
  };

  const options =
    data?.policy?.insuranceType?.requireVehicle === true
      ? [
          { label: 'COVERAGE PARAMETERS', options: startingOptions?.paramOptions },
          { label: 'FACTORABLE VALUES', options: startingOptions?.vehicleOptions },
          { label: 'API OPTIONS', options: startingOptions?.apiOptions }
        ]
      : [
          { label: 'COVERAGE PARAMETERS', options: startingOptions?.paramOptions },
          { label: 'API OPTIONS', options: startingOptions?.apiOptions }
        ];

  const flattenedOptions = [
    {
      label: 'Coverage Parameter Based',
      value: 'coverageParameterBased',
      options: startingOptions?.paramOptions
    },
    {
      label: 'Usage Based (requires values to generate invoice)',
      value: 'usageBased',
      options: startingOptions?.apiOptions
    }
  ];

  if (data?.policy?.insuranceType?.requireVehicle === true)
    flattenedOptions.push({
      label: 'Vehicle Options',
      value: 'vehicleOptions',
      options: startingOptions?.vehicleOptions
    });

  return {
    options,
    flattenedOptions,
    loading,
    error
  };
}
