import React from 'react';
import { FieldMessage, Modal } from '@oforce/global-components';

import { GraphQLErrors } from '../../../components';
import { useRemoveAddOn } from '../hooks';

export default function AddOnRemove({ addOn, isOpen, toggle }) {
  const { removeAddOn, loading, error } = useRemoveAddOn({ addOn, onCompleted: toggle });

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        title="Remove Add-On"
        loading={loading}
        onSubmit={removeAddOn}
        submitButtonDisabled={loading}
        submitButtonText="Remove Add-On"
      >
        <GraphQLErrors error={error} />
        <div className="mb-2">Removing this add-on will remove it from any elections that have selected it.</div>
        <FieldMessage icon={['far', 'info-circle']}>
          Updating add on configurations of already selected pricing options will impact the premium on those options.
        </FieldMessage>
      </Modal>
    </div>
  );
}
