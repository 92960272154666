import React from 'react';
import styled from 'styled-components';

const StyledTable = styled.table`
  width: 100%;
  thead {
    tr th {
      font-weight: 600;
      font-size: 14px;
      padding: 12px;
      border-bottom: 2px solid ${props => props.theme.gray300};
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid ${props => props.theme.gray300};
      &.disabled {
        td {
          &:first-of-type {
            box-shadow: -2px 0px 0px 0px ${props => props.theme.gray400};
          }
          &:last-of-type {
            box-shadow: 2px 0px 0px 0px ${props => props.theme.gray400};
          }
          color: ${props => props.theme.gray600};
          background: ${props => props.theme.gray200};
        }
      }
      td {
        font-size: 14px;
        padding: 12px;
      }
    }
  }
`;

export default function Table({ className, children, ...rest }) {
  return (
    <StyledTable className={className} {...rest}>
      {children}
    </StyledTable>
  );
}
