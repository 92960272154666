import * as yup from 'yup';

import { useUpdateVehicleType } from '.';

export default function useUpdateVehicleTypeForm({ vehicleType, onUpdated }) {
  const { loading, error, updateVehicleType } = useUpdateVehicleType({
    onCompleted: onUpdated
  });

  const initialValues = { id: vehicleType?.id, name: vehicleType?.name || '' };

  const validationSchema = yup.object({
    name: yup.string().required('Required')
  });

  const handleSubmit = ({ id, name }) => updateVehicleType(id, name);

  return {
    initialValues,
    validationSchema,
    handleSubmit,
    loading,
    error
  };
}
