import React from 'react';
import * as yup from 'yup';
import moment from 'moment';
import { Formik } from 'formik';
import { FieldMessage, Modal } from '@oforce/global-components';
import { gql, useMutation } from '@apollo/client';

import { GraphQLErrors } from '../../../components';
import { DatePickerField } from '@oforce/global-components';
import { GET_EXPOSURE_ELECTION_COVERAGE_HISTORY } from '../../coverage/hooks/useElectionHistoryCoverage';
import { GET_EXPOSURE_ELECTION_ACTION_PERMISSIONS } from '../../../components/quickActions/useQuickActionPermissions';
import { FeatureFlag } from '../../../shared/activeAccount/components';
import { FLAGGED_FEATURES } from '../../../shared/activeAccount/constants';
import { usePolicyEffectiveDates } from '../hooks';
const { MASTER_SUB_RELATIONSHIP } = FLAGGED_FEATURES;

export const REACTIVATE_EXPOSURE_ELECTION = gql`
  mutation ReactivateExposureElection($exposureElectionId: ID!, $startDate: Date) {
    reactivateExposureElection: activateExposure(exposureElectionId: $exposureElectionId, startDate: $startDate) {
      id
      terminationDate
      status
    }
  }
`;

const REACTIVATE_FRAGMENT = gql`
  fragment ExposureReactivate on ExposureElection {
    id
    terminationDate
    status
  }
`;

export default function ExposureReactivate({
  exposureElection,
  policy,
  isOpen,
  toggle,
  onError = () => {},
  onCompleted = () => {}
}) {
  const { id: exposureElectionId, terminationDate } = exposureElection;
  const { expirationDate } = usePolicyEffectiveDates(policy);
  const [reactivateExposureElection, { loading, error, reset }] = useMutation(REACTIVATE_EXPOSURE_ELECTION, {
    variables: { exposureElectionId },
    onCompleted: data => {
      onCompleted(data);
      toggle();
    },
    onError,
    refetchQueries: [
      { query: GET_EXPOSURE_ELECTION_COVERAGE_HISTORY, variables: { id: exposureElectionId } },
      { query: GET_EXPOSURE_ELECTION_ACTION_PERMISSIONS, variables: { id: exposureElectionId } }
    ],

    update(cache, { data: { reactivateExposureElection } }) {
      const oldExposureElection = cache.readFragment({
        id: `ExposureElection:${exposureElectionId}`,
        fragment: REACTIVATE_FRAGMENT
      });

      if (oldExposureElection) {
        cache.writeFragment({
          id: `ExposureElection:${exposureElectionId}`,
          fragment: REACTIVATE_FRAGMENT,
          data: { ...reactivateExposureElection }
        });

        cache.evict({ id: `ExposureElection:${exposureElectionId}` });
        cache.gc();
      }
    }
  });

  const handleSubmit = values => {
    reactivateExposureElection({
      variables: {
        startDate: moment(values?.startDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
      }
    });
  };

  const initialValues = {
    startDate: moment().format('MM/DD/YYYY')
  };

  const validationSchema = yup.object({
    startDate: yup.string().required('Required')
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {formik => {
        return (
          <Modal
            isOpen={isOpen}
            toggle={toggle}
            title="Reactivate Exposure"
            submitButtonText="Reactivate Exposure"
            submitButtonProps={{ color: 'success', loading }}
            onSubmit={formik.handleSubmit}
            onOpened={() => {
              reset();
              formik.handleReset();
            }}
          >
            <GraphQLErrors error={error} />
            <DatePickerField
              required
              name="startDate"
              label="Reactivate on"
              disabledDate={current =>
                (current && current < moment(terminationDate)) ||
                (expirationDate && current && current >= moment(expirationDate))
              }
              messageAfter="This will add backdated transactions for all currently invoiced periods."
              messageAfterProps={{ icon: ['far', 'info-circle'] }}
              inputReadOnly={true}
            />
            <FeatureFlag feature={MASTER_SUB_RELATIONSHIP}>
              <FieldMessage icon={['far', 'info-circle']} className="fs-sm mb-3">
                Reactivating a sub election under a terminated master will reactivate the sub as a direct type.
              </FieldMessage>
            </FeatureFlag>
          </Modal>
        );
      }}
    </Formik>
  );
}
