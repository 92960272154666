import { useCanadianProvinces, useUSStates } from '../../../../hooks';
import { formatDate } from '../../../../utils/formatting';

export default function useInputValues() {
  const { options: stateOptions } = useUSStates();
  const { options: provinceOptions } = useCanadianProvinces();
  const options = [...stateOptions, ...provinceOptions];

  const prepareParametersForInputs = ({ coverageParameters = [] }) => {
    return coverageParameters?.reduce((acc, param) => {
      const value = prepareParameterForInput(param);
      return Object.assign({}, acc, { [param?.id]: value });
    }, {});
  };

  const prepareParameterForInput = ({ type, value, valueType }) => {
    switch (type) {
      case 'RANGE':
        return prepForRangeInput({ value, valueType });

      case 'LIST':
        return prepForListInput({ valueType, value });

      case 'INDIVIDUAL':
        switch (valueType) {
          case 'CURRENCY':
            return prepForCurrencyInput(value);

          case 'STATE':
            return prepForStateInput(value);

          case 'INTEGER':
            return prepForIntegerInput(value);

          case 'BOOLEAN':
            return prepForBooleanInput(value);

          case 'DATE':
            return prepForDateInput(value);

          default:
            return value || '';
        }

      default:
        return value || '';
    }
  };

  const prepForListInput = ({ value, valueType }) => {
    const values = (value && JSON.parse(value)) || [];
    if (Array.isArray(values)) {
      switch (valueType) {
        case 'DATE':
          if (values?.length === 0) return [''];
          return values.map(x => {
            return prepForDateInput(x);
          });

        case 'STATE':
          return values.map(x => {
            return prepForStateInput(x);
          });

        default:
          return values?.map(value => ({ label: value, value }));
      }
    }
    return [];
  };

  const prepForBooleanInput = value => {
    if (!value) return null;
    if (['true', true].includes(value)) {
      return { label: 'True', value: true };
    } else {
      return { label: 'False', value: false };
    }
  };

  const prepForStateInput = state => {
    if (!state) return null;
    return options.find(({ value }) => value === state);
  };

  const prepForDateInput = value => {
    if (!value) return '';
    return formatDate(value);
  };

  const prepForCurrencyInput = value => {
    if (!value) return '';
    return value;
  };

  const prepForIntegerInput = value => {
    if (!value) return '';
    return value;
  };

  const prepForRangeInput = ({ value, valueType }) => {
    const { min, max } = (value && JSON.parse(value)) || { min: '', max: '' };

    if (valueType === 'DATE') {
      const start = getDateValue(min);
      const end = getDateValue(max);
      if (!start && !end) return null;

      return [start, end];
    }

    return { min: getValue(min), max: getValue(max) };
  };

  const getDateValue = value => {
    if (value) return value;
    return '';
  };

  const getValue = value => {
    if (value) return value;
    return '';
  };

  return {
    prepareParametersForInputs
  };
}
