import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { Modal } from '@oforce/global-components';

import { GraphQLErrors } from '../../../components';
import { GET_PRODUCT_COVERAGE_PARAMETERS, GET_POLICY_COVERAGE_PARAMETERS } from '../hooks/useGetCoverageParameters';

const DELETE_PRODUCT_COVERAGE_PARAMETER = gql`
  mutation DeleteProductCoverageParameter($id: ID!) {
    removeProductCoverageParameter(id: $id) {
      id
      label
      name
      type
    }
  }
`;

const DELETE_POLICY_COVERAGE_PARAMETER = gql`
  mutation DeletePolicyCoverageParameter($id: ID!) {
    removePolicyCoverageParameter(id: $id) {
      id
      label
      name
      type
    }
  }
`;

export default function Delete({
  coverageParameter,
  productId,
  policyId,
  isProduct,
  isOpen,
  toggle,
  onError = () => {}
}) {
  const [deleteCoverageParameter, { loading: deleting, error: deleteError }] = useMutation(
    isProduct ? DELETE_PRODUCT_COVERAGE_PARAMETER : DELETE_POLICY_COVERAGE_PARAMETER,
    {
      onCompleted: toggle,
      onError,
      refetchQueries: [
        {
          query: isProduct ? GET_PRODUCT_COVERAGE_PARAMETERS : GET_POLICY_COVERAGE_PARAMETERS,
          variables: { id: isProduct ? productId : policyId }
        }
      ]
    }
  );

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      title="Delete Coverage Parameter"
      onSubmit={() => deleteCoverageParameter({ variables: { id: coverageParameter?.id } })}
      submitButtonProps={{ loading: deleting, loadingText: 'Deleting...', color: 'danger' }}
      submitButtonText={`Yes, Delete ${coverageParameter?.name}`}
      cancelButtonText="No, Cancel"
    >
      <GraphQLErrors error={deleteError} />
      <p>Are you sure you want to delete this coverage parameter?</p>
    </Modal>
  );
}
