import React from 'react';
import { Label, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const StyledUploader = styled(Label).attrs({
  className:
    'cursor-pointer bg-light border-dashed rounded border-width-1 px-2 mb-0 fs-sm'
})`
  color: ${props => props.theme.gray600};
  border-color: ${props => props.theme.gray400};
  &:hover {
    color: ${props => props.theme.gray700}
    border-color: ${props => props.theme.gray500};
  }
  &:active {
    border-color: ${props => props.theme.gray700}
    color: ${props => props.theme.gray700}
  }
`;

function Uploader({ onChange, id, loading, loadingText }) {
  const icon = loading ? 'spinner-third' : 'file-upload';

  return (
    <>
      <StyledUploader for={id} disabled={loading}>
        <FontAwesomeIcon
          icon={['far', icon]}
          spin={loading}
          className={loadingText ? 'mr-2' : ''}
        />
        {loadingText ? (loading ? 'Uploading...' : 'Upload document') : null}
      </StyledUploader>
      <Input
        id={id}
        type="file"
        onChange={onChange}
        className="d-none"
        disabled={loading}
      />
    </>
  );
}

export default Uploader;
