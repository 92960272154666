import React from 'react';
import { Helmet } from 'react-helmet';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import { TabPage } from '../../components/tabs';
import { PageHeader, PageTitle, PageBody } from '../../components/page-components';

import Vehicle from '../vehicle/Vehicle';
import { VehiclesTable } from './components/';
import { RouteHiddenFromRolesAndTypes, useActiveAccount } from '../../shared/activeAccount';
import { Button } from '@oforce/global-components';
import { CoverageForm } from '../coverages/components';
import { INSURANCE_TYPES } from '../configurationInsuranceTypes';
import { HideFromRolesAndTypes } from '../../shared/activeAccount/components';

export default function Vehicles() {
  const { path } = useRouteMatch();
  const history = useHistory();

  const {
    ROLES: { OBSERVER },
    TYPES: { CLAIMS_ADJUSTER }
  } = useActiveAccount();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Helmet>
          <title>Vehicles • IC Insure</title>
        </Helmet>
        <PageHeader>
          <PageTitle>Vehicles</PageTitle>
        </PageHeader>
        <PageBody>
          <TabPage
            title=""
            buttons={
              <HideFromRolesAndTypes roles={[OBSERVER]} types={[CLAIMS_ADJUSTER]}>
                <Button
                  color="info"
                  icon={['far', 'plus']}
                  className="ml-2"
                  onClick={() => history.push('/vehicles/new')}
                >
                  Create
                </Button>
              </HideFromRolesAndTypes>
            }
          >
            <VehiclesTable />
          </TabPage>
        </PageBody>
      </Route>
      <RouteHiddenFromRolesAndTypes types={[CLAIMS_ADJUSTER]} roles={[OBSERVER]} path={`${path}/new`}>
        <Helmet>
          <title>Create Vehicle • IC Insure</title>
        </Helmet>
        <PageHeader>
          <PageTitle>Vehicles</PageTitle>
        </PageHeader>
        <PageBody>
          <CoverageForm
            title="Create Vehicle"
            onCancel={() => history.push('/vehicles')}
            renderSelectOrCreateVehicleStep={false}
            policyInsuranceTypes={[INSURANCE_TYPES.VEHICLE]}
          />
        </PageBody>
      </RouteHiddenFromRolesAndTypes>

      <Route path={`${path}/:vehicleId`}>
        <Vehicle />
      </Route>
    </Switch>
  );
}
