import { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { getProp } from '../utils';

export default function usePaginatedQuery(query, { variables, pageSize = 20, pathToPageInfo, ...rest }) {
  const [pageInfo, setPageInfo] = useState({});

  const [paginationProps, setPaginationProps] = useState({
    first: pageSize,
    after: pageInfo?.startCursor,
    last: null,
    before: null
  });

  const loadFirstPage = useCallback(
    (variables = {}) => {
      setPaginationProps({ ...variables, first: pageSize, last: null, after: null, before: null });
    },
    [pageSize]
  );

  const loadNextPage = () => {
    pageInfo?.hasNextPage &&
      setPaginationProps(x => ({ ...x, first: pageSize, last: null, after: pageInfo?.endCursor, before: null }));
  };

  const loadPreviousPage = () => {
    pageInfo?.hasPreviousPage &&
      setPaginationProps(x => ({ ...x, first: null, last: pageSize, before: pageInfo?.startCursor, after: null }));
  };

  const queryVariables = {
    ...variables,
    ...paginationProps
  };

  const { data, loading, error, refetch, fetchMore } = useQuery(query, {
    variables: queryVariables,
    ...rest
  });

  useEffect(() => {
    if (data) {
      setPageInfo(getProp(data, `${pathToPageInfo}.pageInfo`));
    }
  }, [data, pathToPageInfo]);

  return {
    data,
    loading,
    error,
    refetch,
    fetchMore,
    queryProps: queryVariables,
    loadFirstPage,
    paginationProps: {
      pageInfo,
      loadNextPage,
      loadPreviousPage
    }
  };
}
