import React, { useEffect, useState } from 'react';
import Ide from './Ide';
import { useAuth0 } from '@auth0/auth0-react';
import useFetcher from './hooks/useFetcher';
import { Helmet } from 'react-helmet';

const API_NAME = process.env.REACT_APP_API_NAME || 'IC Insure';
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:4000/api';

export default function Graphiql({ logout }) {
  const [accessToken, setAccessToken] = useState(null);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { fetcher } = useFetcher({ apiUrl: API_URL, accessToken: accessToken });

  useEffect(() => {
    async function getToken() {
      const token = await getAccessTokenSilently({ scope: 'openid profile' });
      setAccessToken(token);
    }
    getToken();
  }, [getAccessTokenSilently, setAccessToken]);

  if (!isAuthenticated || !Boolean(accessToken)) return null;

  return (
    <>
      <Helmet>
        <title>GraphiQL • IC Insure</title>
      </Helmet>
      <Ide
        accessToken={accessToken}
        apiUrl={API_URL}
        apiName={API_NAME}
        logout={logout}
        fetcher={fetcher}
      />
    </>
  );
}
