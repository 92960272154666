import React from 'react';

import { FormSection } from '../../../../components';
import { InsuredFields } from '../../../insured/components';

export default function InsuredDetailsStep({ formik, requiredInsuredFields, hasSsn, ...props }) {
  return (
    <FormSection title="Insured Details">
      <InsuredFields
        requiredFields={requiredInsuredFields}
        isCreateMode={!formik?.values?.existingInsuredId}
        formik={formik}
        hasSsn={hasSsn}
        {...props}
      />
    </FormSection>
  );
}
