import React from 'react';
import { FilterLabel } from '../../../components';
import NotificationSearchBy from './NotificationSearchBy';
import { NotificationStatusFilter } from '.';

export default function NotificationJobFilter({ notificationStatusOptions, onChange }) {
  return (
    <div className="d-flex align-items-center flex-wrap">
      <FilterLabel>Status</FilterLabel>
      <NotificationStatusFilter notificationStatusOptions={notificationStatusOptions} onChange={onChange} />
      <FilterLabel>Search by</FilterLabel>
      <NotificationSearchBy className={'mr-2'} onChange={onChange} />
    </div>
  );
}
