import React from 'react';
import { Link } from 'react-router-dom';

export default function NameCell({ insured, linkAddress, className, skipLink = false }) {
  const { firstName, lastName, businessName, businessName2 } = insured;
  const actualBusinessName = businessName || businessName2;

  const label = () => {
    if (!!firstName && !!lastName) return `${lastName}, ${firstName}`;
    if (!!lastName) return lastName;
    if (!!firstName) return firstName;
    if (actualBusinessName) return actualBusinessName;
    return 'Unknown name';
  };

  const value = () => {
    if (!!firstName && !!lastName) return actualBusinessName;
    if (!!firstName) return actualBusinessName;
    return '';
  };

  return (
    <LabelValueTitle
      className={className}
      linkAddress={linkAddress}
      label={label()}
      value={value()}
      skipLink={skipLink}
    />
  );
}

function LabelValueTitle({ label, value, className, linkAddress, skipLink }) {
  return (
    <div className={`d-flex flex-column ${className}`}>
      {skipLink ? (
        <div className="font-weight-500">{label}</div>
      ) : (
        <Link to={linkAddress}>
          <div>{label}</div>
        </Link>
      )}
      <div className="font-weight-400 fs-sm text-muted">{value}</div>
    </div>
  );
}
