import { createContext } from 'react';

export const InitialState = {
  startPolicyRenewalJob: () => {},
  clearJobId: () => {},
  jobId: null,
  renewedPolicyId: null,
  message: null,
  loading: false,
  jobErrors: []
};

export const PolicyRenewalJobContext = createContext(InitialState);

PolicyRenewalJobContext.displayName = 'PolicyRenewalJobContext';
