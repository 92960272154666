import React from 'react';
import classNames from 'classnames';

export default function ContentLayout({ menuItems, children }) {
  return (
    <div className="d-flex flex-column flex-grow-1" style={{ maxHeight: '100vh', overflowY: 'auto' }}>
      <TopMargin alwaysShow={!menuItems || menuItems?.length === 0} />
      {children}
    </div>
  );
}

const TopMargin = ({ alwaysShow }) => {
  const topMarginClasses = classNames('d-block', { 'd-md-block': alwaysShow, 'd-md-none': !alwaysShow });

  return <div className={topMarginClasses} style={{ marginTop: '64px' }}></div>;
};
