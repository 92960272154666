import React from 'react';
import { darken } from 'polished';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SkeletonContent from './SkeletonContent';

const StyledSkeletonWrapper = styled.div`
  position: relative;
  background: ${props => darken(0.02, props.theme.gray200)};
  overflow: hidden;
`;

export default function SkeletonLoader({
  width = '50px',
  height = '25px',
  shimmerWidth = '35px',
  shape = 'squared',
  className
}) {
  const borderRadius = { squared: '0px', rounded: '8px', pill: '1000px' }[shape] || 'squared';

  return (
    <StyledSkeletonWrapper style={{ borderRadius, width, height }} className={className}>
      <SkeletonContent contentWidth={shimmerWidth} />
    </StyledSkeletonWrapper>
  );
}

SkeletonLoader.propTypes = {
  /**
   * The width of the loader, defaults to 50px.
   */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * The height of the loader, defaults to 25px.
   */
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * The width of the shimmer effect gradient that slides across the loader. Defaults to 35px.
   */
  shimmerWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * The shape of the loader.
   */
  shape: PropTypes.oneOf(['squared', 'rounded', 'pill'])
};
