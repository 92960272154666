import React from 'react';
import { Td, Tr, TrPlaceholder, TrLoader, RenderData } from '@oforce/global-components';

import { Table } from '../../../components/table-2.0';
import { TrGraphQLErrors } from '../../../components';
import { NameCell } from '../../../components/tables';
import { CellCoverageDates, ExposureElectionStatusBadge } from '../../exposureElections/components';
import { CellActions, CellContractType } from '../../exposureElections/components';

import { Link } from 'react-router-dom';
import { Render } from '../../../components';
import { displayElectionTotal } from '../../exposureElections';

import { useActiveAccount } from '../../../shared/activeAccount';
import { FeatureFlag, HideFromRolesAndTypes } from '../../../shared/activeAccount/components';
import { useCurrentUser } from '../../../shared/currentUser';
import { FLAGGED_FEATURES } from '../../../shared/activeAccount/constants';

const { MASTER_SUB_RELATIONSHIP } = FLAGGED_FEATURES;

export default function CoveragesTable({ elections, loading, error }) {
  const { enabledFeatures } = useCurrentUser();
  const isMasterSubEnabled = enabledFeatures.includes(MASTER_SUB_RELATIONSHIP);
  const colSpan = isMasterSubEnabled ? 8 : 7;
  const {
    ROLES: { OBSERVER },
    TYPES: { CLAIMS_ADJUSTER }
  } = useActiveAccount();

  return (
    <Table responsive>
      <thead>
        <tr>
          <th>Status</th>
          <th>Insured</th>
          <FeatureFlag feature={MASTER_SUB_RELATIONSHIP}>
            <th>Contract Type</th>
          </FeatureFlag>
          <th>VIN/Unit</th>
          <th>Policy</th>
          <th>Premium</th>
          <th>Coverage Dates</th>
          <HideFromRolesAndTypes roles={[OBSERVER]} types={[CLAIMS_ADJUSTER]}>
            <th>Actions</th>
          </HideFromRolesAndTypes>
        </tr>
      </thead>
      <tbody>
        <RenderData
          data={elections}
          loading={loading}
          error={error}
          loadingComponent={<TrLoader colSpan={colSpan} />}
          errorComponent={<TrGraphQLErrors colSpan={colSpan} error={error} />}
          placeholderComponent={<TrPlaceholder colSpan={colSpan}>No exposure elections</TrPlaceholder>}
        >
          {elections =>
            elections?.map(election => (
              <Tr key={election?.id}>
                <Td>
                  <ExposureElectionStatusBadge election={election} />
                </Td>
                <Td>
                  <NameCell insured={election?.insured} linkAddress={`/insureds/${election?.insured?.id}`} />
                </Td>
                <FeatureFlag feature={MASTER_SUB_RELATIONSHIP}>
                  <CellContractType election={election} />
                </FeatureFlag>
                <Td>
                  <Render if={!!election?.vehicle?.vin}>
                    <Link to={`/vehicles/${election?.vehicle?.id}`}>{election?.vehicle?.vin}</Link>
                    <br />
                  </Render>
                  <Render if={!!election?.vehicle?.unitNumber}>{election?.vehicle?.unitNumber}</Render>
                </Td>
                <Td>{election?.policy?.name}</Td>
                <Td>{displayElectionTotal(election)}</Td>
                <CellCoverageDates election={election} />
                <HideFromRolesAndTypes roles={[OBSERVER]} types={[CLAIMS_ADJUSTER]}>
                  <Td>
                    <CellActions election={election} policy={election.policy} />
                  </Td>
                </HideFromRolesAndTypes>
              </Tr>
            ))
          }
        </RenderData>
      </tbody>
    </Table>
  );
}
