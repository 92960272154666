import React from 'react';
import { Form, Formik} from 'formik';

import { GraphQLErrors } from '../../../components'
import { DatePickerField, FieldMessage, Label, Modal, InputField } from '@oforce/global-components';
import { useUpdatePolicyArchiveDate } from '../hooks';

export default function ArchivePolicy({ policy, showConfirm, toggleConfirm }) {
  const { status } = policy;
  const { error, handleSubmit, initialValues, loading, validationSchema } = useUpdatePolicyArchiveDate({ policy, toggleConfirm });

  if (status === 'ARCHIVED') return null;

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={ handleSubmit }>
      {formik => {
        return (
          <>
            <Modal
              isOpen={showConfirm}
              toggle={toggleConfirm}
              title="Archive Policy"
              loading={loading}
              submitButtonText="Archive Policy"
              submitButtonProps={{ color: 'info', loadingText: 'Archiving...' }}
              scrollable
              onSubmit={formik.handleSubmit}
            >
            <GraphQLErrors error={error} />
            <Form>
              <DatePickerField
                fullWidth
                name="archiveDate"
                label="Archive Date"
                format="MM/DD/YYYY"
                inputReadOnly={false}
                messageAfterProps={{ icon: ['far', 'info-circle' ] }}
              />
              <div className="fs-lg">Are you sure you want to archive this policy? This operation cannot be reversed.</div>
              <Label className="fs-sm mt-3 font-weight-500 mb-0 d-inline-flex align-items-center">Archive Notes</Label>
              <InputField
                type="text"
                bsSize="sm"
                className="mb-1"
                name="archiveNotes"
                placeholder="Notes..."
                style={{ minWidth: '200px' }}
              />
              <FieldMessage icon={['far', 'info-circle']}>
                These notes will be visible to anyone with access to the policy.
              </FieldMessage>
            </Form>
            </Modal>
          </>
        );
      }}
    </Formik>
  );
}
