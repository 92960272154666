import React from 'react';
import { TrLoader, TrPlaceholder } from '@oforce/global-components';

import { RenderData, TrGraphQLErrors } from '../../../components';

import { ShowForTypes, HideFromRoles, useActiveAccount } from '../../../shared/activeAccount';
import { ACCOUNT_TYPES, ACCOUNT_ROLES } from '../../../shared/activeAccount';

import { Table } from '../../../components/table-2.0';

import AddOnRow from './AddOnRow';

export default function AddOnsTable({ addOns, loading, error, toggleEdit, toggleRemove, pricingOptions }) {
  const { CARRIER, BROKER } = ACCOUNT_TYPES;
  const { EXPOSURE_MANAGER } = ACCOUNT_ROLES;
  const { showForTypes, hideFromRoles } = useActiveAccount();
  const showExtraColumn = showForTypes([CARRIER, BROKER]) && !hideFromRoles([EXPOSURE_MANAGER]);
  const colSpan = showExtraColumn ? 6 : 5;

  return (
    <Table>
      <thead>
        <tr>
          <th>Label</th>
          <th>Name</th>
          <th>Cost</th>
          <th>Applies To</th>
          <th>Required On</th>
          <ShowForTypes types={[CARRIER, BROKER]}>
            <HideFromRoles roles={[EXPOSURE_MANAGER]}>
              <th />
            </HideFromRoles>
          </ShowForTypes>
        </tr>
      </thead>
      <tbody>
        <RenderData
          data={addOns}
          loading={loading}
          error={error}
          loadingComponent={<TrLoader colSpan={colSpan} />}
          errorComponent={<TrGraphQLErrors colSpan={colSpan} error={error} />}
          placeholderComponent={
            <TrPlaceholder colSpan={colSpan} className="bg-gray-100 border-bottom border-width-2 border-gray-300">
              No Add-Ons
            </TrPlaceholder>
          }
        >
          {renderData =>
            renderData?.map(addOn => (
              <AddOnRow
                key={addOn?.id}
                addOn={addOn}
                pricingOptions={pricingOptions}
                toggleEdit={toggleEdit}
                toggleRemove={toggleRemove}
              />
            ))
          }
        </RenderData>
      </tbody>
    </Table>
  );
}
