import { gql, useMutation } from '@apollo/client';

import { useCurrentUser } from '../../../shared/currentUser';

export const UPDATE_ACCOUNT = gql`
  mutation updateAccount(
    $id: ID!
    $name: String!
    $notificationEmailAddresses: [String]
    $allowReportAccessToClient: Boolean
  ) {
    updateAccount(
      name: $name
      id: $id
      notificationEmailAddresses: $notificationEmailAddresses
      allowReportAccessToClient: $allowReportAccessToClient
    ) {
      id
      name
      notificationEmailAddresses
      allowReportAccessToClient
    }
  }
`;

const CREATE_ACCOUNT = gql`
  mutation createAccount($name: String!, $type: AccountType!) {
    account: createAccount(name: $name, type: $type) {
      __typename
      id
      name
      type
    }
  }
`;

export default function useFormSubmit({
  onCompleted = () => {},
  onError = () => {},
  refetchQueries,
  account,
  isCreateMode
}) {
  const { id: currentUserId } = useCurrentUser();

  const [create, { loading: creating, error: createError }] = useMutation(CREATE_ACCOUNT, {
    onCompleted: ({ account }) => {
      onCompleted(account);
    },
    refetchQueries,
    onError
  });

  const [update, { loading: updating, error: updateError }] = useMutation(UPDATE_ACCOUNT, {
    onCompleted: ({ account }) => {
      onCompleted(account);
    },
    onError,
    update: (cache, { data }) => {
      cache.writeFragment({
        id: `User:${currentUserId}`,
        fragment: gql`
          fragment ActiveAccountName on User {
            activeAccountName
          }
        `,
        data: { activeAccountName: data.updateAccount.name }
      });
    }
  });

  const formSubmit = values => {
    const sharedInput = {
      name: values?.name,
      allowReportAccessToClient: values?.allowReportAccessToClient
    };

    if (isCreateMode) {
      create({
        variables: {
          ...sharedInput,
          type: values?.type?.value
        }
      });
    } else {
      update({
        variables: {
          id: account?.id,
          ...sharedInput,
          notificationEmailAddresses: values?.notificationEmailAddresses?.filter(e => e !== '')
        }
      });
    }
  };

  return { formSubmit, creating, updating, createError, updateError };
}
