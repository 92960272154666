import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { Badge, Label, ReactSelect, Render } from '@oforce/global-components';
import { toTextCase } from 'js-convert-case';

import { useCurrentUser } from '../../../shared/currentUser';
import { useAcceptPolicyInvitation, useDeclinePolicyInvitation } from '../../account/hooks';

import { InvitationTemplate } from '.';
import { GET_UNCLAIMED_INVITATIONS } from '..';
import { GraphQLErrors } from '../../../components';
import { useActiveAccount } from '../../../shared/activeAccount';

const OPEN_POLICY_INVITATION = gql`
  mutation OpenPolicyInvitation($invitationId: ID!, $userId: ID!) {
    invitation: openPolicyInvitation(invitationId: $invitationId, userId: $userId) {
      id
      role
      policy {
        name
      }
    }
  }
`;

export default function InvitationForPolicy({ invitation }) {
  const history = useHistory();
  const activeAccount = useActiveAccount();
  const currentUser = useCurrentUser();
  const opened = invitation?.opened;
  const invitationId = invitation?.id;

  const [openInvitation] = useMutation(OPEN_POLICY_INVITATION, {
    variables: { invitationId, userId: currentUser?.id },
    onCompleted: () => {
      currentUser?.activeAccount?.refetchUser();
    },
    refetchQueries: [{ query: GET_UNCLAIMED_INVITATIONS }],
    onError: () => {}
  });

  useEffect(() => {
    if (!opened) {
      openInvitation();
    }
  }, [opened, openInvitation]);

  const [acceptInvitation, { loading: accepting, error: acceptError }] = useAcceptPolicyInvitation({
    invitationId,
    onCompleted: async ({
      invitation: {
        account: { id: accountId }
      }
    }) => {
      activeAccount?.setActiveAccountId(accountId, () => history.push('/insureds'));
    },
    onError: () => {}
  });
  const [declineInvitation, { loading: declining, error: declineError }] = useDeclinePolicyInvitation({
    invitationId,
    onCompleted: () => {},
    onError: () => {}
  });

  const accountOptions =
    currentUser?.userAccounts
      ?.filter(x => x?.account?.type === invitation?.role)
      ?.map(({ account }) => ({
        label: toTextCase(account?.name),
        value: account?.id
      })) || [];

  const noAccountOptions = !accountOptions || accountOptions?.length === 0;
  const firstAccountOption = accountOptions?.length > 0 && accountOptions[0];

  const [chosenAccountOption, setAccountOption] = useState(accountOptions[0]);

  useEffect(() => {
    if (!chosenAccountOption && !!firstAccountOption) {
      setAccountOption(firstAccountOption);
    }
  }, [chosenAccountOption, firstAccountOption, setAccountOption]);

  const role = toTextCase(invitation?.role);

  return (
    <InvitationTemplate
      date={invitation?.created}
      title="Manage policy"
      acceptButtonText="Accept Invitation"
      onAccept={() => acceptInvitation({ accountId: chosenAccountOption?.value })}
      acceptButtonProps={{
        disabled: noAccountOptions,
        loading: accepting,
        loadingText: 'Joining...'
      }}
      onDecline={declineInvitation}
      declineButtonProps={{ loading: declining, loadingText: 'Declining...' }}
    >
      <GraphQLErrors error={acceptError || declineError} />
      <p className="fs-xl">
        You've been invited to manage the policy <span className="font-weight-500">{invitation?.policy?.name}</span>
        <br />
        using a <span className="font-weight-500">{role} Account</span>
      </p>
      <Render if={noAccountOptions}>
        Oops, it looks like you don't have any accounts that match the role type for this invitation. You must have a{' '}
        <span className="font-weight-500">{role} account</span> in order to accept this invitation.
      </Render>
      <Render if={accountOptions?.length > 0}>
        <div className="w-30">
          <Label>{role} Account</Label>
          <div className="w-50">
            <ReactSelect
              options={accountOptions}
              value={chosenAccountOption}
              onChange={option => setAccountOption(option)}
            />
            <Badge size="sm" color="secondary" className="cursor-pointer mt-2" icon={['far', 'info-circle']}>
              If you want to create a new account, visit the
              <Link
                to={{ pathname: `/my-profile/accounts/create`, state: { roleFromInvitation: invitation?.role } }}
                className="text-nowrap ml-1"
              >
                create account page
              </Link>
            </Badge>
          </div>
        </div>
      </Render>
    </InvitationTemplate>
  );
}
