import React from 'react';
import { Button, Loader } from '@oforce/global-components';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { castViolationDetails } from './PendingApprovalsTable';
import { normalizeInsuredFullName } from '../../../insured/utils';
import { gql, useMutation, useQuery } from '@apollo/client';

import { Bold, GraphQLErrors, Render } from '../../../../components';
import { Formik } from 'formik';
import { GET_EXPOSURE_ELECTION_ACTION_PERMISSIONS } from '../../../../components/quickActions/useQuickActionPermissions';

const UN_REJECT_ELECTION = gql`
  mutation unreject($exposureElectionId: ID!) {
    UnrejectAllComplianceViolations(exposureElectionId: $exposureElectionId) {
      id
      compliance {
        inCompliance
        violations {
          id
          status
        }
      }
    }
  }
`;

export const GET_EXPOSURE_ELECTION_REJECTED_VIOLATIONS = gql`
  query exposureElection($id: ID!) {
    getExposureElection(id: $id) {
      id
      compliance(status: REJECTED) {
        inCompliance
        rejected
        violations {
          field
          id
          insertedAt
          reason
          source
          status
          type
          updatedAt
        }
      }
    }
  }
`;

export default function UnRejectModal({ isOpen, toggle, election }) {
  const { data, loading: violationsLoading } = useQuery(GET_EXPOSURE_ELECTION_REJECTED_VIOLATIONS, {
    skip: !election.id,
    variables: { id: election.id }
  });

  const [unRejectElection, { loading, error }] = useMutation(UN_REJECT_ELECTION, {
    onCompleted: toggle,
    refetchQueries: [{ query: GET_EXPOSURE_ELECTION_ACTION_PERMISSIONS, variables: { id: election?.id } }],
    onError: () => {}
  });

  const handleSubmit = () => unRejectElection({ variables: { exposureElectionId: election.id } });
  const compliance = data?.getExposureElection?.compliance;

  return (
    <Formik enableReinitialize onSubmit={handleSubmit} initialValues={{}}>
      {formik => (
        <Modal isOpen={isOpen} toggle={toggle} centered>
          <ModalBody>
            <h4 className="mb-4">UnReject Election</h4>
            <GraphQLErrors error={error} />
            <div className="d-flex">
              <FontAwesomeIcon icon={['far', 'exclamation-triangle']} className={`text-info mr-3`} />
              <div className="text-left">
                You've selected to <Bold> unreject</Bold> coverage for {normalizeInsuredFullName(election.insured)} with
                the following compliance violations:
                <br></br>
                <br></br>
                <Render if={violationsLoading}>
                  <Loader />
                </Render>
                <Render if={!!compliance && !violationsLoading && !error}>
                  {compliance && (
                    <ul>
                      {castViolationDetails({ compliance }, 'REJECTED').map(violation => (
                        <li key={violation.id}>{violation.text}</li>
                      ))}
                    </ul>
                  )}
                </Render>
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-between border-top-0">
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
            <Button color="warning" disabled={loading} onClick={formik.handleSubmit}>
              UnReject
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </Formik>
  );
}
