import React from 'react';
import moment from 'moment';
import { FormSection } from '../../../components';
import { DatePickerField } from '@oforce/global-components';

export default function BulkDownloadDocumentsDateSelection({ formik, documents }) {
  const updateFormikValues = activeOn => {
    const activeOnDate = activeOn.format(moment.HTML5_FMT.DATE);
    const { allDocumentInsuredsMapping, allValidDocumentRelationships } = formik?.values;

    const filteredInsuredIdsByDocumentRelationships = allValidDocumentRelationships
      ?.filter(documentRelationship =>
        documentRelationship?.exposureElections?.filter(
          exposureElection =>
            exposureElection?.startDate > activeOnDate || exposureElection?.terminationDate <= activeOnDate
        )?.length === 0
          ? true
          : false
      )
      ?.reduce((acc, documentRelationship) => {
        const ids = [...acc, documentRelationship?.insuredId];
        return [...new Set(ids)];
      }, []);

    const filteredDocumentIds = documents
      ?.filter(document => document?.effectiveDate <= activeOnDate)
      ?.map(document => document?.id);

    const filteredDocumentInsuredsMapping = Object.entries(allDocumentInsuredsMapping)?.reduce(
      (acc, [documentId, insuredIds]) => {
        const filteredInsuredIds = insuredIds?.filter(insuredId =>
          filteredInsuredIdsByDocumentRelationships?.includes(insuredId)
        );

        return !filteredInsuredIds?.length ? acc : { ...acc, [documentId]: filteredInsuredIds };
      },
      {}
    );

    const documentInsuredsMapping = Object.fromEntries(
      Object.entries(filteredDocumentInsuredsMapping)?.filter(([key, _]) => filteredDocumentIds.includes(key))
    );

    const allValidInsuredIds = Object.values(documentInsuredsMapping)?.reduce((acc, insuredIds) => {
      const ids = [...acc, ...insuredIds];
      return [...new Set(ids)];
    }, []);

    formik?.setFieldValue(
      'documentIds',
      Object.keys(allDocumentInsuredsMapping)?.filter(documentId => filteredDocumentIds.includes(documentId))
    );

    formik?.setFieldValue('documentInsuredsMapping', documentInsuredsMapping);
    formik?.setFieldValue('insuredIds', allValidInsuredIds);
    formik?.setFieldValue('allValidInsuredIds', allValidInsuredIds);
    formik?.setFieldValue('insuredIdsFromChosenDocuments', allValidInsuredIds);
  };

  return (
    <FormSection title="Select Active Date for Documents">
      <DatePickerField
        name="activeOn"
        label="Active On"
        format="MM/DD/YYYY"
        fullWidth
        required
        allowClear={false}
        disabledDate={current => current && current > moment()}
        onChange={activeOn => updateFormikValues(activeOn)}
      />
    </FormSection>
  );
}
