import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

const StyledToggleCell = styled.button`
  background: white;
  border: none;
  &:focus {
    outline: none;
  }
`;

export default function ToggleCell({ isOpen, toggle, text, className, disabled }) {
  const icon = isOpen ? 'angle-down' : 'angle-left';

  return (
    <StyledToggleCell size="sm" className={`p-0 text-info fs-sm ${className}`} disabled={disabled} onClick={toggle}>
      {text}
      <FontAwesomeIcon icon={['far', icon]} className="ml-2" />
    </StyledToggleCell>
  );
}
