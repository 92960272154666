import { createContext } from 'react';

export const InitialState = {
  startGenerateInvoicesReportJob: () => {},
  clearJobId: () => {},
  jobId: null,
  filename: null,
  url: null,
  message: null,
  loading: false,
  jobErrors: []
};

export const GenerateInvoicesReportJobContext = createContext(InitialState);

GenerateInvoicesReportJobContext.displayName = 'GenerateInvoicesReportJobContext';
