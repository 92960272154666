import * as AbsintheSocket from '@absinthe/socket';
import { createAbsintheSocketLink } from '@absinthe/socket-apollo-link';
import { Socket as PhoenixSocket } from 'phoenix';

const host = process.env.REACT_APP_WEBSOCKET_HOST;

const absintheSocketLink = createAbsintheSocketLink(AbsintheSocket.create(new PhoenixSocket(`${host}/socket`)));

export default function useWebSocketLink() {
  return absintheSocketLink;
}
