import { gql, useMutation } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';

const LOGOUT = gql`
  mutation logout {
    logOutUser {
      id
    }
  }
`;

export default function useLogout() {
  const { logout } = useAuth0();
  const clientLogout = () => logout({ returnTo: window.location.origin });
  const [serverLogout] = useMutation(LOGOUT, { onCompleted: clientLogout, onError: () => {} });
  return { logout: serverLogout };
}
