import React from 'react';
import { useParams } from 'react-router-dom';

import { PolicyPricingCreateUpdate } from '../../../views';

export default function PricingUpdate({ pricings, policyId }) {
  const { pricingId } = useParams();

  return (
    <PolicyPricingCreateUpdate policyId={policyId} pricing={pricings?.find(pricing => pricing.id === pricingId)} />
  );
}
