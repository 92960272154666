export default function useRenewPolicyFormSteps({ allValidExposureElectionIds }) {
  const formSteps = [
    {
      name: 'Advisory',
      initialValues: {}
    },
    {
      name: 'Select Exposure Elections',
      initialValues: { exposureElectionIds: allValidExposureElectionIds }
    }
  ];

  return { formSteps };
}
