import React from 'react';
import { Col, Row } from 'reactstrap';
import { Label, Button, useToggle } from '@oforce/global-components';

import { convertDecimalToPercentage } from '../../../utils';
import { formatExtendedCurrency } from '../../../utils/formatting';
import { useCurrentUser } from '../../../shared/currentUser';

import { RemoveStateFee, UpdateStateFee } from '.';

export default function StateFee({ stateFee }) {
  const [showUpdateStateFee, toggleUpdateStateFee] = useToggle();
  const [showRemoveStateFee, toggleRemoveStateFee] = useToggle();
  const { isAdmin } = useCurrentUser();

  return (
    <>
      <span className="d-inline-block bg-white border border-width-2 border-gray-300 rounded p-2 mb-1 mr-2">
        <Row>
          <Col>
            <Label>State</Label>
            <div className="fs-sm">{stateFee?.state}</div>
          </Col>
          <Col>
            <Label>Fee</Label>
            {stateFee?.activeMode === 'FLAT' && (
              <div className="fs-sm" key={stateFee?.id}>
                {formatExtendedCurrency(stateFee?.fee)}
              </div>
            )}
            {stateFee?.activeMode === 'PERCENTAGE' && (
              <div className="fs-sm" key={stateFee?.id}>
                {convertDecimalToPercentage(stateFee?.fee)}
              </div>
            )}
          </Col>
        </Row>
        {isAdmin && (
          <div className="d-flex justify-content-end">
            <Button color="link" size="sm" icon={['far', 'pen']} onClick={toggleUpdateStateFee} />
            <Button
              color="link"
              className="text-danger"
              size="sm"
              icon={['far', 'trash-alt']}
              onClick={toggleRemoveStateFee}
            />
          </div>
        )}
      </span>

      <UpdateStateFee
        stateFee={stateFee}
        showUpdateStateFee={showUpdateStateFee}
        toggleUpdateStateFee={toggleUpdateStateFee}
      />

      <RemoveStateFee
        id={stateFee?.id}
        state={stateFee?.state}
        toggleRemoveStateFee={toggleRemoveStateFee}
        showRemoveStateFee={showRemoveStateFee}
      />
    </>
  );
}
