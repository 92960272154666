import React from 'react';
import { Table, TrPlaceholder } from '../../../components/table-2.0';
import { ACCOUNT_ROLES, ACCOUNT_TYPES, HideFromRoles, HideFromTypes } from '../../../shared/activeAccount';
import PricingRow from './PricingRow';

export default function PricingsTable({ policy, pricings }) {
  const totalColCount = 7;
  const insuranceTypeRequiresVehicle = policy?.product?.insuranceType?.requireVehicle;
  const { EXPOSURE_MANAGER } = ACCOUNT_ROLES;
  const { CLIENT } = ACCOUNT_TYPES;

  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Price</th>
          <th>Min/Max</th>
          <HideFromTypes types={[CLIENT]}>
            <HideFromRoles roles={[EXPOSURE_MANAGER]}>
              <th>Commission</th>
            </HideFromRoles>
          </HideFromTypes>
          <th>Fees</th>
          {insuranceTypeRequiresVehicle && <th>Vehicle Types</th>}
          <th>Coverage Parameters</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {pricings?.length === 0 && (
          <TrPlaceholder colSpan={totalColCount} className="bg-gray-100 border-bottom border-width-2 border-gray-300">
            No Pricings
          </TrPlaceholder>
        )}
        {pricings?.map(pricing => (
          <PricingRow key={pricing?.id} pricing={pricing} policy={policy} />
        ))}
      </tbody>
    </Table>
  );
}
