import React from 'react';
import { ToastContainer } from 'react-toastify';
import DocumentDownloadJob from '../documentDownloadJob';
import ImportExposuresJob from '../importExposuresJob';
import PolicyRenewalJob from '../policyRenewalJob';
import SendDocumentsJob from '../sendDocumentsJob';
import GenerateInvoiceXslJob from '../generateInvoiceXslJob';
import GenerateInvoicesReportJob from '../generateInvoicesReportJob';
import NotificationStatusReportJob from '../notificationStatusReportJob';
import DownloadFailedDocumentsJob from '../downloadFailedDocumentsJob';
import UpdateExposuresJob from '../updateExposuresJob';
import {
  useExpiredPolicyInvoiceNotifications,
  useExposureComplianceChanges,
  useInvoiceNotifications
} from '../policy/hooks';
import SyncPricingOptionsJob from '../syncPricingOptionsJob';
import GenerateCombinedInvoicesXslJob from '../generateCombinedInvoicesXslJob';
import BulkDownloadDocumentsJob from '../bulkDownloadDocumentsJob';

export default function Subscriptions() {
  useExposureComplianceChanges();
  useInvoiceNotifications();
  useExpiredPolicyInvoiceNotifications();

  return (
    <>
      <DocumentDownloadJob />
      <ImportExposuresJob />
      <UpdateExposuresJob />
      <PolicyRenewalJob />
      <SendDocumentsJob />
      <GenerateInvoiceXslJob />
      <GenerateInvoicesReportJob />
      <NotificationStatusReportJob />
      <DownloadFailedDocumentsJob />
      <SyncPricingOptionsJob />
      <GenerateCombinedInvoicesXslJob />
      <BulkDownloadDocumentsJob />

      <ToastContainer position="bottom-left" />
    </>
  );
}
