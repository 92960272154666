import React from 'react';
import { InputField, InputCurrencyField, ReactSelectField, FieldMessage } from '@oforce/global-components';
import { useAddOnAppliesToOptions, useAddOnRequiredOnOptions } from '../hooks';
import { FormSection } from '../../../components';

export default function AddOnForm({ formOptions, formik, loading }) {
  const { addOnAppliesToOptions } = useAddOnAppliesToOptions();
  const { addOnRequiredOnOptions } = useAddOnRequiredOnOptions();

  return (
    <FormSection title={'Details'}>
      <InputField name="label" label="Label" required />
      <InputField name="name" label="Name" required />
      <InputCurrencyField name="cost" label="Cost" required options={{ integerLimit: 5 }} />
      <ReactSelectField
        required
        className="mt-3"
        label="Applies to"
        options={addOnAppliesToOptions}
        onChange={option => {
          if (!option.value) {
            formik.setFieldValue(
              'requiredOnAllPricingOptions',
              addOnRequiredOnOptions.find(o => o.value === false)
            );
          }
        }}
        name="appliesToAllPricingOptions"
      />
      {!formik?.values?.appliesToAllPricingOptions?.value && (
        <ReactSelectField
          required
          label="Applied to Pricing Options"
          name="pricings"
          options={formOptions.pricingSelectOptions?.options}
          placeholder="Select..."
          isMulti
          isLoading={loading}
          disabled={loading}
          clearable
          onChange={() => {
            formik.setFieldValue('requiredForPricings', []);
          }}
        />
      )}
      {formik?.values?.appliesToAllPricingOptions?.value && (
        <ReactSelectField
          required
          className="mt-3"
          label="Required on"
          options={addOnRequiredOnOptions}
          name="requiredOnAllPricingOptions"
        />
      )}
      {!formik?.values?.requiredOnAllPricingOptions?.value && (
        <ReactSelectField
          label="Required on Pricing Options"
          name="requiredForPricings"
          options={
            formik?.values?.appliesToAllPricingOptions?.value
              ? formOptions.pricingSelectOptions?.options
              : formik?.values?.pricings
          }
          placeholder="None"
          isMulti
          isLoading={loading}
          disabled={loading}
          clearable
        />
      )}
      <FieldMessage icon={['far', 'info-circle']}>
        Updating add on configurations of already selected pricing options will impact the premium on those options.
      </FieldMessage>
    </FormSection>
  );
}
