import { gql, useQuery } from '@apollo/client';

export const COUNT_EXPOSURE_ELECTIONS = gql`
  query CountExposureElections {
    getExposureElectionCounts {
      activeElections
      activeInsureds
      activeVehicles
      openViolations
    }
  }
`;

export default function useCountExposureElections() {
  const { loading, error, data } = useQuery(COUNT_EXPOSURE_ELECTIONS, {
    onCompleted: () => {},
    onError: () => {}
  });

  return {
    activeElections: data?.getExposureElectionCounts?.activeElections,
    activeInsureds: data?.getExposureElectionCounts?.activeInsureds,
    activeVehicles: data?.getExposureElectionCounts?.activeVehicles,
    openViolations: data?.getExposureElectionCounts?.openViolations,
    loading,
    error
  };
}
