import React from 'react';
import { Tooltip } from 'reactstrap';
import { Badge, useToggle } from '@oforce/global-components';

import { ActionDropdown, LabelValueCell } from '../../../components/table-2.0';
import { backendNameToLabel, formatCurrency } from '../../../utils/formatting';
import { convertDecimalToPercentage } from '../../../utils';

import { usePolicyPricings } from '../../policyPricing/hooks';

import FeeEdit from './FeeEdit';
import FeeRemove from './FeeRemove';
import { HideFromRoles, ShowForTypes, useActiveAccount } from '../../../shared/activeAccount';

export default function FeeRow({ policy, fee }) {
  const [showEditFee, toggleEditFee] = useToggle();
  const [showRemoveFee, toggleRemoveFee] = useToggle();
  const [showPricingOptionsPopover, togglePricingOptionsPopover] = useToggle();
  const {
    ROLES: { EXPOSURE_MANAGER },
    TYPES: { CARRIER, BROKER }
  } = useActiveAccount();
  const { pricings: pricingOptions } = usePolicyPricings({
    policyId: policy?.id
  });

  const pricingOptionsBadgeText = fee?.appliesToAllPricingOptions
    ? `All Pricing Options (${pricingOptions?.length || 0})`
    : `Some Pricing Options (${fee?.policyPricingIds?.length || 0})`;

  const pricings = fee?.appliesToAllPricingOptions
    ? pricingOptions
    : pricingOptions?.filter(x => fee?.policyPricingIds?.includes(x?.id));

  return (
    <>
      <tr>
        <td>{fee?.name}</td>
        <td>
          <PricingCell rate={fee?.rate} mode={fee?.rateMode} modeType={fee?.rateModeType} />
        </td>
        <td>
          <Badge
            size="sm"
            id={`a-${fee?.id}`}
            color="secondary"
            className="cursor-pointer"
            iconAfter={['far', 'info-circle']}
          >
            {pricingOptionsBadgeText}
          </Badge>
          <Tooltip
            target={`a-${fee?.id}`}
            placement="bottom"
            isOpen={showPricingOptionsPopover}
            toggle={togglePricingOptionsPopover}
          >
            {pricings?.map(p => (
              <div key={p?.id}>{p?.label}</div>
            ))}
          </Tooltip>
        </td>
        <td>{fee?.prorated ? 'Enabled' : 'Disabled'}</td>
        <td align="right">
          <ShowForTypes types={[CARRIER, BROKER]}>
            <HideFromRoles roles={[EXPOSURE_MANAGER]}>
              <ActionDropdown onEdit={toggleEditFee} onRemove={toggleRemoveFee} />
            </HideFromRoles>
          </ShowForTypes>
        </td>
      </tr>
      <FeeEdit isOpen={showEditFee} toggle={toggleEditFee} fee={fee} accountId={policy?.accountId} policy={policy} />
      <FeeRemove
        isOpen={showRemoveFee}
        toggle={toggleRemoveFee}
        policy={policy}
        accountId={policy?.accountId}
        fee={fee}
      />
    </>
  );
}

const PricingCell = ({ rate, mode, modeType }) => {
  const name = modeType === 'PREMIUM_PLUS' ? 'Percentage of Premium PLUS' : backendNameToLabel(mode);
  const label = `${name}`;
  const value = mode === 'PERCENTAGE' ? convertDecimalToPercentage(rate) : formatCurrency(rate);
  return <LabelValueCell label={label} value={value} />;
};
