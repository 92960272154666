import { gql, useMutation } from '@apollo/client';
import { GET_CLIENT_DOCUMENTS } from './useClientDocuments';
import { SERVER_SIDE_DATE_FORMAT } from '../../../utils/dates';

const UPLOAD_DOCUMENT = gql`
  mutation UploadDocument($input: AddClientDocumentInput) {
    addClientDocument(input: $input) {
      id
      documents {
        id
        name
        effectiveDate
        expirationDate
        document {
          id
          url
          type
          filename
        }
        policies {
          id
          name
        }
      }
    }
  }
`;

export default function useClientDocumentFormSubmit({ clientId, onCompleted = () => {}, onError = () => {} }) {
  const [upload, { loading, error }] = useMutation(UPLOAD_DOCUMENT, {
    onCompleted,
    onError,
    refetchQueries: [{ query: GET_CLIENT_DOCUMENTS, variables: { id: clientId } }]
  });

  const formSubmit = values => {
    const input = {
      clientId,
      fileData: values?.document[0],
      name: values?.name,
      type: values?.type?.value,
      policyIds: values?.policies?.map(option => option?.value),
      effectiveDate: values?.effectiveDate.format(SERVER_SIDE_DATE_FORMAT),
      expirationDate: !!values?.expirationDate ? values?.expirationDate.format(SERVER_SIDE_DATE_FORMAT) : null
    };

    upload({
      variables: { input }
    });
  };

  return { formSubmit, loading, error };
}
