import { gql, useMutation } from '@apollo/client';

const CREATE_VEHICLE_TYPE = gql`
  mutation create($name: String!) {
    addVehicleType(name: $name) {
      id
      name
    }
  }
`;

export default function useCreateVehicleType({ getVehicleTypesQuery, onCompleted = () => {}, onError = () => {} }) {
  const [create, { loading, error }] = useMutation(CREATE_VEHICLE_TYPE, {
    update(cache, { data: { addVehicleType } }) {
      const { vehicleTypes } = cache.readQuery({
        query: getVehicleTypesQuery
      });
      cache.writeQuery({
        query: getVehicleTypesQuery,
        data: { vehicleTypes: vehicleTypes.concat([addVehicleType]) }
      });
    },
    onCompleted,
    onError
  });

  return {
    createVehicleType: name => create({ variables: { name } }),
    loading,
    error
  };
}
