import { gql, useMutation } from '@apollo/client';

import { useAddOnAppliesToOptions, useAddOnRequiredOnOptions } from '../../../views/addOns/hooks';

import { GET_POLICY_ADD_ONS } from './usePolicyAddOns';

const UPDATE_POLICY_ADD_ON = gql`
  mutation editPolicyAddOn($input: EditPolicyAddOnInput!) {
    editPolicyAddOn(input: $input) {
      id
      label
      name
      cost
      customData
      appliesToAllPricingOptions
      pricingOptionIds
      requiredOnAllPricingOptions
      requiredForPricingOptionIds
    }
  }
`;

export default function useAddOnEdit({ addOn, onCompleted = () => {}, selectOptions, policyId, onError = () => {} }) {
  const [updatePolicyAddOn, { data, loading, error, reset }] = useMutation(UPDATE_POLICY_ADD_ON, {
    onCompleted,
    onError,
    refetchQueries: [{ query: GET_POLICY_ADD_ONS, variables: { policyId } }]
  });

  const { addOnAppliesToOptions } = useAddOnAppliesToOptions();
  const { addOnRequiredOnOptions } = useAddOnRequiredOnOptions();

  const handleSubmit = ({
    customData,
    cost,
    label,
    name,
    appliesToAllPricingOptions,
    pricings,
    requiredOnAllPricingOptions,
    requiredForPricings
  }) => {
    if (addOn?.id) {
      updatePolicyAddOn({
        variables: {
          input: {
            id: addOn?.id,
            customData: JSON.stringify(customData),
            label,
            name,
            cost: parseFloat(cost.replace('$', '')),
            appliesToAllPricingOptions: appliesToAllPricingOptions.value,
            pricingOptionIds: pricings?.map(pricing => pricing?.value),
            requiredOnAllPricingOptions: requiredOnAllPricingOptions.value,
            requiredForPricingOptionIds: requiredForPricings?.map(pricing => pricing?.value)
          }
        }
      });
    }
  };

  const initialValues = {
    name: addOn?.name || '',
    label: addOn?.label || '',
    cost: addOn?.cost || 0.0,
    customData: addOn?.customData || {},
    appliesToAllPricingOptions: addOnAppliesToOptions.find(x => x.value === addOn?.appliesToAllPricingOptions),
    pricings: selectOptions?.filter(x => addOn?.pricingOptionIds?.includes(x.value)),
    requiredOnAllPricingOptions: addOnRequiredOnOptions.find(x => x.value === addOn?.requiredOnAllPricingOptions),
    requiredForPricings: selectOptions?.filter(x => addOn?.requiredForPricingOptionIds?.includes(x.value))
  };

  return {
    data,
    loading: loading || !addOn,
    error,
    reset,
    initialValues,
    handleSubmit
  };
}
