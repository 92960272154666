import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export default function useAccessToken() {
  const { getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    async function getToken() {
      const token = await getAccessTokenSilently({ scope: 'openid profile' });
      setAccessToken(token);
    }

    if (!accessToken) {
      getToken();
    }
  }, [accessToken, getAccessTokenSilently, setAccessToken]);

  return { accessToken, setAccessToken };
}
