import { FieldMessage, InputDropzoneField } from '@oforce/global-components';
import { Formik } from 'formik';
import React from 'react';
import { Modal } from '@oforce/global-components';
import { useCsvImport } from '../../../policyInsureds/components/import/hooks';
import { useParseUpdateExposureCsvFile } from './hooks';
import { Bold } from '../../../../components';
import UpdateExposuresFileTemplateLink from '../UpdateExposuresFileTemplateLink';

export default function UploadExposureUpdateFileModal({ onParseComplete, isOpen, toggle, policy }) {
  const { parseData, loading } = useParseUpdateExposureCsvFile({
    policy,
    onComplete: onParseComplete
  });

  const { initialValues, validationSchema } = useCsvImport();

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} enableReinitialize onSubmit={parseData}>
      {formik => (
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          title="Upload Exposure Update CSV"
          submitButtonText="Upload CSV"
          onSubmit={formik.handleSubmit}
          loading={loading}
          submitButtonProps={{ loadingText: 'Parsing File...', disabled: !formik.values.document }}
        >
          <>
            <div className="mb-3">Use the steps below to provide us exposure updates.</div>
            <ol>
              <li className="mb-2">
                <UpdateExposuresFileTemplateLink policy={policy} />
              </li>
              <li className="mb-2">
                <Bold> Remove </Bold> any rows (exposures) you do not want updated.
                <FieldMessage className="fs-sm" icon={['far', 'info-circle']}>
                  Do NOT remove columns.
                </FieldMessage>
              </li>
              <li className="mb-2">
                Update the values in each row/column you want changed.
                <FieldMessage className="fs-sm" icon={['far', 'info-circle']}>
                  Do NOT remove the exposureElectionId column.
                </FieldMessage>
              </li>
              <li className="mb-2">
                <Bold>Upload </Bold> your finished file and we will do the rest.
              </li>
            </ol>
            <InputDropzoneField
              className="mb-2 mt-4"
              required
              accept={['.csv']}
              id="document"
              label="Upload Filled Out Template"
              name="document"
              disabled={loading}
              readOnly={loading}
              placeholder={'Choose CSV file'}
            />
          </>
        </Modal>
      )}
    </Formik>
  );
}
