import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { VALIDATED_FACTOR_VALUE_ROW_FIELDS } from '../../../../policyInsureds/components/import/model/constants';

const VALIDATE_ROW = gql`
  mutation ValidateRow($row: Json!) {
    validateFactorValueRow(row: $row) {
      ...validatedFactorValueRowFields
    }
  }
  ${VALIDATED_FACTOR_VALUE_ROW_FIELDS}
`;

export default function useRowValidation({ onCompleted = () => {}, onError = () => {} }) {
  const [loadingRowId, setLoadingRowId] = useState(null);

  const [validateRow, { loading, error }] = useMutation(VALIDATE_ROW, {
    onCompleted: ({ validateFactorValueRow }) => {
      onCompleted && onCompleted(validateFactorValueRow);
      setLoadingRowId(null);
    },
    onError
  });

  const validateSingleRow = row => {
    setLoadingRowId(row?.exposureElectionId);
    const variables = { row: JSON.stringify(row) };
    validateRow({ variables });
  };

  return {
    validateRow: validateSingleRow,
    loading: loading && loadingRowId,
    error
  };
}
