import React from 'react';
import { Tooltip } from 'reactstrap';
import { Badge, useToggle } from '@oforce/global-components';

import { formatCurrency } from '../../../utils/formatting';
import { ActionDropdown } from '../../../components/table-2.0';
import { HideFromRoles, ShowForTypes, useActiveAccount } from '../../../shared/activeAccount';

export default function AddOnRow({ pricingOptions, addOn, toggleEdit, toggleRemove }) {
  const [showPricingOptionsPopover, togglePricingOptionsPopover] = useToggle();
  const [showRequiredPricingOptionsPopover, toggleRequiredPricingOptionsPopover] = useToggle();
  const {
    TYPES: { CARRIER, BROKER },
    ROLES: { EXPOSURE_MANAGER }
  } = useActiveAccount();

  const pricingOptionsBadgeText = addOn?.appliesToAllPricingOptions
    ? `All Pricing Options (${pricingOptions?.length})`
    : `Some Pricing Options (${addOn?.pricingOptionIds?.length})`;

  const pricings = addOn?.appliesToAllPricingOptions
    ? pricingOptions
    : pricingOptions?.filter(x => addOn?.pricingOptionIds?.includes(x?.id));

  const requiredPricingOptionsBadgeText = addOn?.requiredOnAllPricingOptions
    ? `All Pricing Options (${pricingOptions?.length})`
    : `Some Pricing Options (${addOn?.requiredForPricingOptionIds?.length})`;

  const requiredForPricings = addOn?.requiredOnAllPricingOptions
    ? pricingOptions
    : pricingOptions?.filter(x => addOn?.requiredForPricingOptionIds?.includes(x?.id));

  return (
    <>
      <tr>
        <td>{addOn?.label}</td>
        <td>{addOn?.name}</td>
        <td>{formatCurrency(addOn?.cost)}</td>
        <td>
          <Badge
            size="sm"
            id={`a-${addOn?.id}`}
            color="secondary"
            className="cursor-pointer"
            iconAfter={['far', 'info-circle']}
          >
            {pricingOptionsBadgeText}
          </Badge>
          <Tooltip
            target={`a-${addOn?.id}`}
            placement="bottom"
            isOpen={showPricingOptionsPopover}
            toggle={togglePricingOptionsPopover}
          >
            {pricings?.map(p => (
              <div key={p?.id}>{p?.label}</div>
            ))}
          </Tooltip>
        </td>
        <td>
          {addOn?.requiredOnAllPricingOptions || addOn?.requiredForPricingOptionIds?.length > 0 ? (
            <>
              <Badge
                size="sm"
                id={`ra-${addOn?.id}`}
                color="secondary"
                className="cursor-pointer"
                iconAfter={['far', 'info-circle']}
              >
                {requiredPricingOptionsBadgeText}
              </Badge>
              <Tooltip
                target={`ra-${addOn?.id}`}
                placement="bottom"
                isOpen={showRequiredPricingOptionsPopover}
                toggle={toggleRequiredPricingOptionsPopover}
              >
                {requiredForPricings?.map(p => (
                  <div key={p?.id}>{p?.label}</div>
                ))}
              </Tooltip>
            </>
          ) : (
            <EmptyCell />
          )}
        </td>
        <ShowForTypes types={[CARRIER, BROKER]}>
          <HideFromRoles roles={[EXPOSURE_MANAGER]}>
            <td align="right">
              <ActionDropdown onEdit={() => toggleEdit(addOn)} onRemove={() => toggleRemove(addOn)} />
            </td>
          </HideFromRoles>
        </ShowForTypes>
      </tr>
    </>
  );
}

const EmptyCell = ({ text = 'None' }) => <div className="font-weight-300 fs-xs font-italic text-muted">{text}</div>;
