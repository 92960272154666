import React from 'react';
import { BoxContainer, FormSection, PagerButtons } from '../../../components';
import RenewPolicyExposureElectionsTable from './RenewPolicyExposureElectionsTable';
import useListValidPolicyExposureElectionsForRenewal from '../hooks/useListValidPolicyExposureElectionsForRenewal';

export default function RenewPolicyExposureElectionsSelection({ formik, policy, allValidExposureElectionIds }) {
  const { elections, loading, error, hasNextPage, hasPreviousPage, loadNextPage, loadPreviousPage } =
    useListValidPolicyExposureElectionsForRenewal({ policy });

  return (
    <FormSection title="Select Exposure Elections">
      <BoxContainer>
        <RenewPolicyExposureElectionsTable
          formik={formik}
          elections={elections}
          allValidExposureElectionIds={allValidExposureElectionIds}
          policy={policy}
          loading={loading}
          error={error}
        />
      </BoxContainer>
      <PagerButtons
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        loadNextPage={loadNextPage}
        loadPreviousPage={loadPreviousPage}
        loading={loading}
      />
    </FormSection>
  );
}
