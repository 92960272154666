import { FormGroup } from 'reactstrap';
import { gql, useQuery } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { Label, Modal, ReactAsyncSelect } from '@oforce/global-components';

import { useCurrentUser } from '../../../shared/currentUser';
import { useActiveAccount } from '../../../shared/activeAccount';
import { useHistory } from 'react-router-dom';

const ALL_ACCOUNTS = gql`
  query allAccounts($name: String, $after: String, $first: Int) {
    accounts: allAccounts(first: $first, after: $after, name: $name) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          name
          owner {
            user {
              name
            }
          }
        }
      }
    }
  }
`;

export default function AccountSwitcherModal({ isOpen, toggle }) {
  const history = useHistory();
  const { isAdmin } = useCurrentUser();
  const { id: activeAccountId, name: activeAccountName, setActiveAccountId, loading } = useActiveAccount();

  const { fetchMore } = useQuery(ALL_ACCOUNTS, { variables: { first: 25, after: null } });

  const [selectedAccountId, setSelectedAccountId] = useState(activeAccountId);

  const getFilteredAccounts = async inputValue => {
    const opts = await fetchMore({ variables: { name: inputValue } });
    const normalizedOpts = opts?.data?.accounts?.edges?.map(x => x.node);
    return normalizedOpts?.map(({ name, id, owner }) => {
      let label = name;
      if (isAdmin) {
        label = owner?.user?.name ? `${owner.user.name}: ${name}` : name;
      }
      return { label, value: id };
    });
  };

  const promiseOptions = inputValue => new Promise(resolve => resolve(getFilteredAccounts(inputValue)));

  const onSubmit = useCallback(async () => {
    await setActiveAccountId(selectedAccountId);
    history.push(`/policies`);
    toggle();
  }, [setActiveAccountId, selectedAccountId, toggle, history]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      title="Select Other Account"
      onSubmit={onSubmit}
      submitButtonText="Switch to Account"
      submitButtonProps={{ color: 'info', loading }}
    >
      <FormGroup>
        <Label htmlFor="activeAccount">Active Account</Label>
        <ReactAsyncSelect
          id="activeAccount"
          name="activeAccount"
          placeholder="Type to search..."
          cacheOptions
          loadOptions={promiseOptions}
          defaultOptions
          defaultValue={
            activeAccountId && activeAccountName ? { value: activeAccountId, label: activeAccountName } : null
          }
          onChange={({ value }) => setSelectedAccountId(value)}
        />
      </FormGroup>
    </Modal>
  );
}
