import React from 'react';
import { ReactSelectField } from '@oforce/global-components';
import { useCanadianProvinces, useUSStates } from '../../hooks';
import { createFilter } from 'react-select';

export default function InputStateField({
  className = '',
  id,
  name,
  label = 'State',
  options,
  required = false,
  placeholder = 'Select state ...',
  placement = 'bottom',
  ...rest
}) {
  const { options: defaultStateOptions } = useUSStates();
  const { options: defaultCanadianProvincesOptions } = useCanadianProvinces();
  const stateOptions = options || [...defaultStateOptions, ...defaultCanadianProvincesOptions];
  return (
    <ReactSelectField
      className={className}
      id={id}
      name={name}
      label={label}
      options={stateOptions}
      required={required}
      placeholder={placeholder}
      filterOption={createFilter({ matchFrom: 'start' })}
      placement={placement}
      {...rest}
    />
  );
}
