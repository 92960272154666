import { gql } from '@apollo/client';
import { usePaginatedQuery } from '../../hooks';

export const GET_PENDING_ELECTIONS = gql`
  query pendingElections(
    $externalIds: [String]
    $withExternalId: Boolean
    $claimed: Boolean
    $archived: Boolean
    $first: Int
    $after: String
    $last: Int
    $before: String
    $searchBy: [PendingElectionSearchByInput]
    $ordering: PendingElectionsListOrder
  ) {
    pendingElections(
      first: $first
      after: $after
      last: $last
      before: $before
      externalIds: $externalIds
      withExternalId: $withExternalId
      claimed: $claimed
      archived: $archived
      searchBy: $searchBy
      ordering: $ordering
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          externalId
          archived
          claimed
          policy {
            id
            name
          }
          insuranceType {
            type
          }
          pricingOption {
            id
            label
          }
          errors
          insured {
            id
            firstName
            lastName
            middleName
            businessName
          }
          vehicle {
            id
            vin
            vehicleMake
            vehicleModel
            vehicleYear
          }
        }
      }
    }
  }
`;

export default function usePendingElections({ pageSize = 20, filters }) {
  const { data, loading, error, refetch, paginationProps, queryProps } = usePaginatedQuery(
    GET_PENDING_ELECTIONS,
    {
      variables: filters,
      pathToPageInfo: 'pendingElections',
      pageSize,
      fetchPolicy: 'cache-and-network'
    }
  );

  const pendingElections = data?.pendingElections?.edges?.map(({ node }) => node);

  return {
    pendingElections,
    loading,
    error,
    refetch,
    paginationProps,
    policiesQueryProps: queryProps
  };
}
