import React from 'react';
import { Button } from '@oforce/global-components';
import { AccountWebhookAdd, AccountWebhooksTable, TabPage } from '../../../components';
import { useToggle } from '../../../hooks';
import { useAccountWebhooks, useAddAccountWebhook } from '../hooks';
import { ShowForRoles, useActiveAccount } from '../../../shared/activeAccount';

export default function AccountWebhooks() {
  const {
    id: activeAccountId,
    ROLES: { OWNER, COLLABORATOR }
  } = useActiveAccount();
  const { webhooks, loading, error: getError } = useAccountWebhooks({ accountId: activeAccountId });
  const [isOpen, toggleAdd] = useToggle();
  const {
    addAccountWebhook,
    loading: addLoading,
    error
  } = useAddAccountWebhook({
    accountId: activeAccountId,
    onCompleted: () => toggleAdd()
  });

  if (loading) return null;

  return (
    <TabPage
      title="Webhooks"
      buttons={
        <ShowForRoles roles={[OWNER, COLLABORATOR]}>
          <Button color="info" icon={['far', 'plus']} onClick={toggleAdd}>
            Create
          </Button>
        </ShowForRoles>
      }
    >
      <AccountWebhooksTable webhooks={webhooks} loading={loading} error={getError} />
      <AccountWebhookAdd
        isOpen={isOpen}
        loading={addLoading}
        toggle={toggleAdd}
        error={error}
        handleSubmit={addAccountWebhook}
        initialValues={{ url: '', events: [], authToken: '' }}
      />
    </TabPage>
  );
}
