import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useUpdateExposuresJobContext } from '../../../../../context';
import {
  prepareInsuredDetails_NoStep,
  prepareVehicleDetails_NoStep,
  prepareElectionDetails_NoStep
} from '../../../../coverages/components/steps/hooks/submitPrepare';

export default function useUpdateExposures({ policy }) {
  const history = useHistory();
  const policyId = policy?.id;
  const { jobId, loading, error, finished, jobErrors = [], startUpdateExposureJob } = useUpdateExposuresJobContext();

  useEffect(() => {
    if (!!jobId && !error && finished && jobErrors.length === 0) {
      history.push(`/policies/${policyId}/insureds`);
    }
  }, [finished, error, jobErrors, history, jobId, policyId]);

  const onSubmit = rows => {
    const exposureElectionRows = rows.map(row => prepareRowForSubmit(row, policy));
    startUpdateExposureJob(exposureElectionRows);
  };

  const prepareRowForSubmit = row => {
    const values = Object.fromEntries(Object.entries(row).filter(([_, value]) => ![null, ''].includes(value)));

    const insuredDetails = prepareInsuredDetails_NoStep(values);
    const vehicleDetails = prepareVehicleDetails_NoStep(values);
    const { terminationDate, startDate } = prepareElectionDetails_NoStep({ ...values, policyId: policy?.id });

    return {
      ...vehicleDetails,
      ...insuredDetails,
      terminationDate,
      startDate,
      exposureElectionId: row?.exposureElectionId
    };
  };

  return {
    jobId,
    loading,
    error,
    onSubmit
  };
}
