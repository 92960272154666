import { gql, useMutation } from '@apollo/client';
import { GET_CLIENTS } from '../../clients/hooks/useClients';

const CREATE_CLIENT = gql`
  mutation create($name: String!, $externalId: String) {
    createClient(name: $name, externalId: $externalId) {
      name
      externalId
      id
      email
      address1
      address2
      city
      state
      zip
      country
      created
      updated
    }
  }
`;

export default function useCreateClient({ onCompleted = () => {}, onError = () => {} }) {
  const [create, { loading, error }] = useMutation(CREATE_CLIENT, {
    onCompleted,
    onError,
    refetchQueries: [{ query: GET_CLIENTS }]
  });

  return {
    createClient: (name, externalId = null) => create({ variables: { name, externalId } }),
    loading,
    error
  };
}
