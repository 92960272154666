import React from 'react';
import styled from 'styled-components';

const StyledBreadcrumb = styled.div.attrs({ className: 'mb-1' })`
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.gray500};
`;

export default function Breadcrumb({ children, ...rest }) {
  return <StyledBreadcrumb {...rest}>{children}</StyledBreadcrumb>;
}
