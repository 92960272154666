import React from 'react';
import { InputField, ReactSelectField, Render } from '@oforce/global-components';

import { FormSection } from '../../../components';
import useCoverageParameterTypes from '../hooks/useCoverageParameterTypes';

export default function CoverageParameterDetailsFormFields({ formik, focusRef, isCreateMode }) {
  const isPredefined = formik?.values?.isPredefined;
  const { predefinedParamOptions } = useCoverageParameterTypes();

  return (
    <FormSection
      title={`${isPredefined ? 'Predefined Coverage Parameter' : 'Custom Coverage Parameter'}`}
      subtitle={`${
        isPredefined
          ? isCreateMode
            ? 'Choose a predefined coverage parameter from the list'
            : "Can't change the name of a Predefined Coverage Parameter"
          : 'Set a name for your custom coverage parameter'
      }`}
      className="mb-0"
    >
      <Render if={isPredefined}>
        <ReactSelectField
          label="Coverage Parameter"
          options={predefinedParamOptions}
          name="predefinedCoverageParameter"
          isDisabled={!isCreateMode}
          onChange={() => {
            formik.setFieldValue('type', null);
            formik.setFieldValue('validate', true);
            formik.setFieldTouched('type', false);
          }}
        />
      </Render>
      <Render if={!isPredefined}>
        <InputField
          name="name"
          label="Name"
          placeholder="The name of your coverage parameter"
          innerRef={focusRef}
          required
        />
      </Render>
    </FormSection>
  );
}
