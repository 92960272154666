import React, { useState } from 'react';
import { Steps } from 'react-step-builder';
import { useHistory } from 'react-router-dom';

import { GraphQLErrors, MultiStepForm, Render } from '../../../components';

import { GenerateInvoiceForm } from '../components';
import {
  useGenerateInvoiceFormOptions,
  useGenerateInvoiceFormSteps,
  useGenerateInvoiceFormSubmit,
  usePolicyInvoiceInfo
} from '../hooks';
import { Loader } from '@oforce/global-components';
import { ImportFactorValues } from '../components/import';
import { ensureFloat } from '../../../utils/formatting';

export default function GenerateInvoice({ refetchInvoicesArgs, policy }) {
  const [factorValues, setFactorValues] = useState(null);
  const history = useHistory();
  const policyId = policy?.id;

  const { formOptions } = useGenerateInvoiceFormOptions({ policyId });

  const {
    formSteps,
    loading: loadingInvoiceData,
    error: invoiceDataError,
    automateInvoiceGeneration
  } = useGenerateInvoiceFormSteps({ policyId, formOptions, policy });
  const { canGenerateOffCycleInvoice } = usePolicyInvoiceInfo({ policy });

  const { formSubmit, loading, error } = useGenerateInvoiceFormSubmit({
    policy,
    refetchInvoicesArgs,
    exposureElectionFactorValues: factorValues,
    onCompleted: () => history.push(`/policies/${policyId}/invoices`),
    policyId
  });

  const formatFactorValue = value => {
    if (value === 'N/A') return null;
    else return ensureFloat(value);
  };

  const addFactorValues = validatedRows => {
    const results = validatedRows?.map(({ exposureElectionId, factorValue }) => {
      return { exposureElectionId, factorValue: formatFactorValue(factorValue) };
    });

    setFactorValues(results);
  };

  if (loadingInvoiceData) return <Loader />;
  if (invoiceDataError) return <GraphQLErrors error={invoiceDataError} />;

  return (
    <>
      <Render if={policy?.hasApiFactoredField && !factorValues}>
        <ImportFactorValues policy={policy} onComplete={addFactorValues} />
      </Render>
      <Render if={!policy?.hasApiFactoredField || (policy?.hasApiFactoredField && !!factorValues)}>
        <MultiStepForm
          formOptions={formOptions}
          formSteps={formSteps}
          formSubmit={formSubmit}
          onCancel={() => history.push(`/policies/${policyId}/invoices`)}
          showProgressBar={false}
          loading={loading}
          error={error}
          title="Generate Invoice"
          submitButtonProps={{
            color: 'info',
            loading,
            loadingText: 'Generating...',
            disabled: automateInvoiceGeneration && !canGenerateOffCycleInvoice
          }}
          submitButtonText={canGenerateOffCycleInvoice ? 'Generate Off Cycle Invoice' : 'Generate Invoice'}
        >
          {formProps => (
            <Steps>
              <GenerateInvoiceForm {...formProps} formOptions={formOptions} policy={policy} />
            </Steps>
          )}
        </MultiStepForm>
      </Render>
    </>
  );
}
