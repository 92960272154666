import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';

export const GET_CLIENT = gql`
  query client($id: ID!) {
    client(id: $id) {
      id
      name
      externalId
      email
      address1
      address2
      city
      state
      zip
      country
      updated
      created
    }
  }
`;

export default function useClient({ id }) {
  const { data, loading, error } = useQuery(GET_CLIENT, {
    variables: { id }
  });

  return { client: data?.client, loading, error };
}
