import React from 'react';
import { TrLoader, TrPlaceholder } from '@oforce/global-components';

import { Table } from '../../../components/table-2.0';
import { BoxContainer, RenderData, TrGraphQLErrors } from '../../../components';
import { useProductPricingOptions } from '../../../components/sharedComponents/pricing';

import { DocumentsTableRow } from '.';

export default function DocumentsTable({ productId, documents, loading, error }) {
  const { productPricingOptions: pricingOptions } = useProductPricingOptions({ productId });
  const colSpan = 4;

  return (
    <BoxContainer>
      <Table>
        <thead>
          <tr>
            <th>Document</th>
            <th>Effective Date</th>
            <th>Expiration Date</th>
            <th>Applies to</th>
            <th>State-Specific Requirements</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <RenderData
            data={documents}
            loading={loading}
            error={error}
            loadingComponent={<TrLoader colSpan={colSpan} />}
            errorComponent={<TrGraphQLErrors colSpan={colSpan} error={error} />}
            placeholderComponent={
              <TrPlaceholder colSpan={colSpan} className="bg-gray-100 border-bottom border-width-2 border-gray-300">
                No documents
              </TrPlaceholder>
            }
          >
            {documents =>
              documents?.map(doc => (
                <DocumentsTableRow key={doc?.id} productId={productId} pricingOptions={pricingOptions} doc={doc} />
              ))
            }
          </RenderData>
        </tbody>
      </Table>
    </BoxContainer>
  );
}
