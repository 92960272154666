import React from 'react';
import { FormGroup } from 'reactstrap';
import { Field, FieldArray } from 'formik';
import { Button, FieldLabel, InputField, Render } from '@oforce/global-components';

export default function InputMultiEmailField({
  formik,
  name,
  label,
  placeholder = 'Enter an email address',
  onChange,
  loading
}) {
  const values = formik?.values[name] || [];

  return (
    <FieldArray name={name}>
      {arrayHelpers => (
        <FormGroup>
          <FieldLabel name={label} />
          {values?.map((_email, index) => {
            const arrayLength = values?.length - 1;
            const noValue = formik?.values[name][index] === '';

            return (
              <div className="d-flex justify-content-between align-items-start mb-2" key={index}>
                <Render if={index !== arrayLength}>
                  <Field
                    as={InputField}
                    onChange={onChange}
                    className="mb-0"
                    name={`${name}.${index}`}
                    type="email"
                    placeholder={placeholder}
                    disabled
                  />
                  <Button
                    color="danger"
                    outline
                    icon={['far', 'minus']}
                    className="ml-2"
                    onClick={() => {
                      arrayHelpers.remove(index);
                      formik.handleSubmit();
                    }}
                  />
                </Render>
                <Render if={index === arrayLength}>
                  <Field
                    as={InputField}
                    onChange={onChange}
                    className="mb-0"
                    name={`${name}.${index}`}
                    type="email"
                    placeholder={placeholder}
                  />
                  <Button
                    color="info"
                    outline
                    icon={['far', 'plus']}
                    className="ml-2"
                    type="submit"
                    disabled={noValue || loading}
                  />
                </Render>
              </div>
            );
          })}
        </FormGroup>
      )}
    </FieldArray>
  );
}
