import React from 'react';
import { RenderData, Button } from '@oforce/global-components';

import { BoxErrors, BoxLoader } from '../../../components/box';
import { Render, TabPage } from '../../../components';

import { InvitationForAccount, InvitationForPolicy } from '.';
import { useUnclaimedInvitations } from '../hooks';
import { FullPage, Message, MessageBox, Title } from '../../../components/message-box';
import { useHistory } from 'react-router-dom';

export default function Invitations() {
  const history = useHistory();
  const { invitations, unopenedInvitationsCount, loading, error } = useUnclaimedInvitations();

  return (
    <>
      <Render if={invitations?.length === 0}>
        <FullPage>
          <MessageBox className="align-self-start justify-self-center">
            <Title className="mb-0 mt-3">Welcome to your new insurance managment system!</Title>
            <Message className="mb-2 mt-3">
              It looks like you have no invitations. Create an account to get started.
            </Message>
            <div className="mt-3 mb-3 w-100 d-flex flex-row align-items-center justify-content-center">
              <Button color="info" onClick={() => history.push(`/my-profile/accounts/create`)}>
                Create Account
              </Button>
            </div>
          </MessageBox>
        </FullPage>
      </Render>
      <Render if={invitations?.length > 0}>
        <TabPage title={`Invitations ${unopenedInvitationsCount > 0 ? `(${unopenedInvitationsCount})` : ''}`}>
          <RenderData
            data={invitations}
            loading={loading}
            error={error}
            loadingComponent={<BoxLoader />}
            errorComponent={<BoxErrors error={error} />}
          >
            {invitations =>
              invitations?.map(invitation =>
                invitation?.type === 'ACCOUNT' ? (
                  <InvitationForAccount invitation={invitation} key={invitation?.id} />
                ) : (
                  <InvitationForPolicy invitation={invitation} key={invitation?.id} />
                )
              )
            }
          </RenderData>
        </TabPage>
      </Render>
    </>
  );
}
