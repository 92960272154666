import React from 'react';
import { GraphQLErrors } from '../../components';
import { Loader } from '@oforce/global-components';
import { useVehicle, useVehicleMakeModelYear } from './hooks';
import { TabPane, TabBar, TabLink } from '../../components/tabs';
import { VehicleOverview, VehicleStakeholderUpdate, VehicleUpdate } from './components';
import { PageTitle, PageHeader } from '../../components/page-components';
import { Switch, Route, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import { CoverageForm } from '../coverages/components';
import { INSURANCE_TYPES } from '../configurationInsuranceTypes';

export default function Vehicle() {
  const { vehicleId } = useParams();
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const { vehicle, loading, error } = useVehicle({ vehicleId });
  const { hasMakeModelYear, makeModelYear } = useVehicleMakeModelYear(vehicle);

  if (loading) return <Loader />;
  if (error) return <GraphQLErrors error={error} />;

  return (
    <>
      <PageHeader withTabs>
        <PageTitle>{hasMakeModelYear ? makeModelYear : vehicle?.vin}</PageTitle>
        <TabBar>
          <TabLink exact to={`${url}`} icon={['far', 'cars']}>
            Vehicle
          </TabLink>
        </TabBar>
      </PageHeader>

      <TabPane>
        <Switch>
          <Route exact path={`${path}`}>
            <VehicleOverview vehicle={vehicle} />
          </Route>
          <Route exact path={`${path}/edit-stakeholders`}>
            <VehicleStakeholderUpdate vehicle={vehicle} />
          </Route>
          <Route exact path={`${path}/edit`}>
            <VehicleUpdate vehicle={vehicle} />
          </Route>
          <Route exact path={`${path}/add-coverage`}>
            <CoverageForm
              title="Create Vehicle Coverage"
              onCancel={() => history.push(url)}
              onCompleted={() => history.push(url)}
              vehicle={vehicle}
              policyInsuranceTypes={[INSURANCE_TYPES.VEHICLE]}
            />
          </Route>
        </Switch>
      </TabPane>
    </>
  );
}
