import React from 'react';
import { Modal } from '@oforce/global-components';

import { GraphQLErrors } from '..';

export default function AccountWebhookRemove({ isOpen, toggle, error, loading, handleSubmit }) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      title="Remove Webhook"
      loading={loading}
      onSubmit={handleSubmit}
      submitButtonText="Remove Webhook"
      submitButtonProps={{ color: 'danger' }}
    >
      <GraphQLErrors error={error} />
      Are you sure you want to remove this webhook?
    </Modal>
  );
}
