import * as yup from 'yup';
import moment from 'moment';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { GET_EXPOSURE_ELECTION_COVERAGE_HISTORY } from '../../coverage/hooks/useElectionHistoryCoverage';
import { GET_EXPOSURE_ELECTION_ACTION_PERMISSIONS } from '../../../components/quickActions/useQuickActionPermissions';

const UPDATE_EXPOSURE_ELECTION_START_DATE = gql`
  mutation UpdateExposureElection($exposureElectionId: ID!, $startDate: Date!) {
    updateExposureElectionStartDate(exposureElectionId: $exposureElectionId, startDate: $startDate) {
      id
      policyId
      startDate
    }
  }
`;

export default function useExposureEditStartDate({ election, toggle }) {
  const initialStartDate = election?.startDate;

  const [updateExposureElectionStartDate, { data, loading, error, reset }] = useMutation(
    UPDATE_EXPOSURE_ELECTION_START_DATE,
    {
      onCompleted: toggle,
      onError: () => {},
      refetchQueries: [
        { query: GET_EXPOSURE_ELECTION_COVERAGE_HISTORY, variables: { id: election.id } },
        { query: GET_EXPOSURE_ELECTION_ACTION_PERMISSIONS, variables: { id: election?.id } }
      ]
    }
  );

  const handleSubmit = ({ startDate }) => {
    if (startDate === '' || !startDate) {
      toggle();
    } else {
      updateExposureElectionStartDate({
        variables: {
          exposureElectionId: election.id,
          startDate: moment(startDate).format('YYYY-MM-DD')
        }
      });
    }
  };

  const initialValues = {
    startDate: initialStartDate ? moment(initialStartDate) : moment()
  };

  const validationSchema = yup.object({
    startDate: yup.date()
  });

  return {
    initialValues,
    validationSchema,
    handleSubmit,
    loading,
    data,
    error,
    reset
  };
}
