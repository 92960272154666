import { gql } from '@apollo/client';

export const INSURED_CORE_FIELDS = gql`
  fragment insuredCoreFields on Insured {
    id
    firstName
    middleName
    lastName
    country
    dateOfBirth
    age
    insertedAt
    updatedAt
    mobilePhone
    email
    businessName
    businessName2
    address
    state
    city
    zip
    ssnLastFour
    encryptedSsn
    requiredFields
    driversLicenseState
    driversLicenseNumber
    dispatchLocation
  }
`;
