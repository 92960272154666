import React from 'react';
import { SearchBy } from '../../../components';
import { useVehicleSearchByOptions } from '../hooks';

export default function VehiclesSearchBy({ onChange }) {
  const { vehicleSearchByOptions, loading } = useVehicleSearchByOptions();
  const defaultSearchField = vehicleSearchByOptions.find(option => option.value === 'VIN');

  const onFilterChange = incomingFilters => {
    const searchBy = incomingFilters.reduce((acc, { field: { value: searchField }, value: searchValue }) => {
      if (!!searchValue) return [...acc, { searchField, searchValue }];
      return acc;
    }, []);

    if (searchBy.length > 0) {
      onChange({ searchBy });
    } else {
      onChange({ searchBy: [] });
    }
  };

  if (loading) return null;

  return (
    <SearchBy
      label={'Search by'}
      onFilterChange={onFilterChange}
      name="vehicles-search-by"
      fieldOptions={vehicleSearchByOptions}
      defaultValue={defaultSearchField}
    />
  );
}
