import { gql, useQuery } from '@apollo/client';

const LIST_MISSING_EXPOSURE_FIELDS = gql`
  query listMissingRequiredFields($insuredId: ID, $policyIds: [ID], $vehicleId: ID) {
    listMissingRequiredFields(insuredId: $insuredId, policyIds: $policyIds, vehicleId: $vehicleId) {
      hasMissingInsuredFields
      hasMissingVehicleFields
      requiredInsuredFields
      requiredVehicleFields
    }
  }
`;

export default function useExposureMissingFields({ policyIds, insured, vehicle }) {
  const insuredId = insured?.id;
  const vehicleId = vehicle?.id;
  const { loading, error, data } = useQuery(LIST_MISSING_EXPOSURE_FIELDS, {
    variables: { policyIds, insuredId, vehicleId }
  });

  const isMissingInsuredFields = data?.listMissingRequiredFields?.hasMissingInsuredFields;
  const isMissingVehicleFields = data?.listMissingRequiredFields?.hasMissingVehicleFields;
  const requiredInsuredFields = reduceRequiredFields(data?.listMissingRequiredFields?.requiredInsuredFields);
  const requiredVehicleFields = reduceRequiredFields(data?.listMissingRequiredFields?.requiredVehicleFields);

  return {
    loading,
    error,
    isMissingInsuredFields,
    isMissingVehicleFields,
    requiredInsuredFields,
    requiredVehicleFields
  };
}

function reduceRequiredFields(requiredFieldsList) {
  return requiredFieldsList?.reduce((acc, fieldName) => {
    acc[fieldName] = true;
    return acc;
  }, {});
}
