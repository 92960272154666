import React, { useMemo } from 'react';
import { SearchBy } from '../../../components';
import useInsuredDocumentSearchByOptions from '../hooks/useInsuredDocumentsSearchBy';

export default function InsuredDocumentSearchBy({ onChange }) {
  const { insuredDocumentSearchByOptions, loading } = useInsuredDocumentSearchByOptions();
  const defaultValue = useMemo(
    () => insuredDocumentSearchByOptions?.find(option => option.value === 'DOCUMENT'),
    [insuredDocumentSearchByOptions]
  );

  const onFilterChange = incomingFilters => {
    const searchBy = incomingFilters?.reduce((acc, { field: { value: searchField }, value: searchValue }) => {
      if (!!searchValue) return [...acc, { searchField, searchValue }];
      return acc;
    }, []);

    if (searchBy?.length > 0) {
      onChange({ searchBy });
    } else {
      onChange({ searchBy: [] });
    }
  };

  if (loading) return null;
  return (
    <SearchBy
      label={'Search by'}
      onFilterChange={onFilterChange}
      name="insured-document-search-by"
      fieldOptions={insuredDocumentSearchByOptions}
      defaultValue={defaultValue}
      maxFilters={2}
    />
  );
}
