export const CLASSIC = 'CLASSIC';
export const SNAPSHOT_CUSTOM_DAY = 'SNAPSHOT_CUSTOM_DAY';
export const SNAPSHOT_END_OF_MONTH = 'SNAPSHOT_END_OF_MONTH';
export const HALF_MONTH = 'HALF_MONTH';
export const SNAPSHOT_BILLING_RULES = [SNAPSHOT_CUSTOM_DAY, SNAPSHOT_END_OF_MONTH];
export const NON_CLASSIC_BILLING_RULES = [SNAPSHOT_CUSTOM_DAY, SNAPSHOT_END_OF_MONTH, HALF_MONTH];

export const BILLING_RULE_OPTIONS = [
  {
    label: 'Classic',
    value: CLASSIC
  },
  {
    label: 'Snapshot with Custom Day',
    value: SNAPSHOT_CUSTOM_DAY
  },
  {
    label: 'Snapshot at End of Month',
    value: SNAPSHOT_END_OF_MONTH
  },
  {
    label: 'Half Month Billing',
    value: HALF_MONTH
  }
];

export function isBillingRuleAllowed({ pricingRateType, invoicingSchedule, enableProrating, billingRule }) {
  const isMonthly = pricingRateType?.value === 'MONTHLY';
  const isProrating = enableProrating;
  const allowNonClassicBillingRules = isMonthly && !isProrating;
  const isNonClassicBillingRule = NON_CLASSIC_BILLING_RULES?.includes(billingRule?.value);
  return (isNonClassicBillingRule && allowNonClassicBillingRules) || !isNonClassicBillingRule;
}

export function billingRuleErrorMessage({ enableProrating, pricingRateType }) {
  let message = '';
  if (enableProrating) message = message + 'Cannot use non-classic billing when prorating is enabled. ';
  if (pricingRateType?.value !== 'MONTHLY') message = message + 'Cannot use non-classic billing with weekly billing. ';
  return message;
}
