import * as yup from 'yup';
import { Formik } from 'formik';
import React, { useRef } from 'react';
import { Modal } from '@oforce/global-components';
import { gql, useMutation } from '@apollo/client';

import { GraphQLErrors } from '../../../components';

import { GET_ACCOUNT_INVITATIONS } from '../../../views/account/hooks/useAccountInvitations';
import { useFormOptions } from '../../../hooks';

import { useAccountRoles } from '../hooks';
import { InvitationForm } from '../components';

export const INVITE_USER_TO_ACCOUNT = gql`
  mutation inviteUserToAccount($emailAddress: String!, $role: AccountUserRole!) {
    inviteUserToAccount(emailAddress: $emailAddress, role: $role) {
      id
      role
      status
      emailAddress
    }
  }
`;

export default function AccountInviteMember({ isOpen, toggle, accountId }) {
  const focusRef = useRef(null);

  const { accountRoleOptions, loading: accountRoleOptionsLoading } = useAccountRoles();
  const formOptions = useFormOptions({
    options: [{ name: 'accountRole', options: accountRoleOptions, loading: accountRoleOptionsLoading }]
  });

  const initialValues = {
    emailAddress: '',
    role: formOptions?.accountRole?.options[0]
  };

  const validationSchema = yup.object({
    emailAddress: yup.string().required('Required'),
    role: yup.object({ label: yup.string(), value: yup.string() }).nullable().required('Required')
  });

  const [invite, { loading, error }] = useMutation(INVITE_USER_TO_ACCOUNT, {
    onCompleted: toggle,
    onError: () => {},
    refetchQueries: [{ query: GET_ACCOUNT_INVITATIONS, variables: { accountId } }]
  });

  const handleSubmit = ({ emailAddress, role }) => {
    invite({ variables: { emailAddress, role: role.value } });
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {formik => (
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          title="Invite Member"
          loading={loading}
          onSubmit={formik.handleSubmit}
          submitButtonText="Send Invite"
          submitButtonProps={{ color: 'success', disabled: formik.isSubmitting, loading: formik.isSubmitting }}
          onOpened={() => {
            formik.resetForm();
            focusRef?.current?.focus();
          }}
        >
          <GraphQLErrors error={error} />
          <InvitationForm formik={formik} focusRef={focusRef} formOptions={formOptions} />
        </Modal>
      )}
    </Formik>
  );
}
