import React from 'react';
import { ReactSelectField, Render, SwitchField } from '@oforce/global-components';

import { FormSection } from '../../../components';

export default function PolicyPricingFormFields({ formik, formOptions }) {
  const pricingRateIsWeekly = formik?.values?.pricingRateType?.value === 'WEEKLY';
  const enableProrating = formik?.values?.enableProrating;
  const prorateSettingOptions = pricingRateIsWeekly
    ? formOptions?.proratingWeekly?.options
    : formOptions?.proratingMonthly?.options;
  const prorateAddons = formik?.values?.prorateAddons;
  const hasInvoiced = formik?.values?.hasInvoiced;

  return (
    <FormSection title="Policy Pricing">
      <ReactSelectField
        name="pricingRateType"
        label="Pricing Rate"
        required
        isDisabled={formik?.values?.hasInvoiced}
        options={formOptions?.pricingRate?.options}
        isLoading={formOptions?.pricingRate?.loading}
        placeholder="Select a Pricing Rate..."
        // when changing pricing rate type, set automateInvoiceGeneration to false by default
        // also, set enableProrating setting to false, and clear out prorateSetting value
        onChange={() => {
          formik?.setFieldValue('automateInvoiceGeneration', false);
          formik?.setFieldValue('enableProrating', false);
          formik?.setFieldValue('prorateSetting', null);
        }}
      />
      <SwitchField
        id="enable-prorate-switch"
        className="mb-3"
        label="Prorating"
        name="enableProrating"
        size="sm"
        disabled={hasInvoiced}
        switchLabel={enableProrating ? 'Enabled' : 'Disabled'}
        messageAfter={hasInvoiced && 'This setting cannot be changed if the policy has already been invoiced.'}
        messageAfterProps={{ icon: ['far', 'info-circle'] }}
      />
      <Render if={enableProrating}>
        <ReactSelectField
          id="prorateSetting"
          name="prorateSetting"
          label="Prorate Setting"
          options={prorateSettingOptions}
          isDisabled={formik?.values?.hasInvoiced}
          placeholder="Select prorate setting..."
          required
        />
        <SwitchField
          id="enable-prorate-addons-switch"
          className="mb-3"
          label="Prorate Addons"
          name="prorateAddons"
          size="sm"
          disabled={formik?.values?.hasInvoiced}
          switchLabel={prorateAddons ? 'Enabled' : 'Disabled'}
        />
      </Render>
    </FormSection>
  );
}
