import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';

export const GET_EXPOSURE_ELECTION_COVERAGE_HISTORY = gql`
  query exposureElection($id: ID!) {
    getExposureElection(id: $id) {
      id
      coverageHistory {
        id
        coverageStartDate
        coverageEndDate
        addOnLabels
        factorLabel
        logs {
          type
          reason
          date
          eventDate
          note
          eventData
          userData
        }
        firstLogDate
        lastLogDate
      }
    }
  }
`;

export default function useElectionCoverageHistory({ exposureElectionId }) {
  const { data, loading, error } = useQuery(GET_EXPOSURE_ELECTION_COVERAGE_HISTORY, {
    skip: !exposureElectionId,
    variables: { id: exposureElectionId }
  });

  const activeHistoryGroups = data?.getExposureElection?.coverageHistory.map(x => x).reverse();

  return {
    coverageGroups: activeHistoryGroups || [],
    loading,
    error
  };
}
