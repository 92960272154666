import React, { useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { Button, useToggle } from '@oforce/global-components';
import { GraphQLErrors, Render } from '../../components';
import { TabPage } from '../../components/tabs';
import InvoiceTemplate from '../../views/invoiceTemplate';
import { ArchivePolicy, RenewPolicy } from '../../views/policy/components';
import { HideFromTypes, useActiveAccount } from '../../shared/activeAccount';
import { PolicyUpdate, PolicyDetailsView } from './containers';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDocumentDownloadJobContext } from '../../context';
import { HideFromRolesAndTypes } from '../../shared/activeAccount/components';
import { useValidPolicyExposureElectionsForRenewal } from '../policy/hooks';

export default function PolicyDetails({ policy }) {
  const history = useHistory();
  const { url, path } = useRouteMatch();

  const {
    TYPES: { CLIENT, CLAIMS_ADJUSTER },
    ROLES: { EXPOSURE_MANAGER, OBSERVER }
  } = useActiveAccount();

  const [showConfirm, toggleConfirm] = useToggle(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  const {
    startDownloadAllPolicyExposuresJob,
    jobId,
    loading: downloadLoading,
    error: downloadError
  } = useDocumentDownloadJobContext();

  const {
    exposureElections,
    loading: exposureElectionsLoading,
    error: exposureElectionsError
  } = useValidPolicyExposureElectionsForRenewal({ policy });

  if (!policy) return null;

  return (
    <Switch>
      <Route exact path={path}>
        <TabPage
          title="Details"
          buttons={
            <HideFromTypes types={[CLIENT]}>
              <HideFromRolesAndTypes types={[CLAIMS_ADJUSTER]} roles={[EXPOSURE_MANAGER, OBSERVER]}>
                <Render if={!policy?.archived}>
                  <Dropdown isOpen={dropdownOpen} toggle={toggle} color="info" direction="down">
                    <DropdownToggle className="ml-2 btn btn-outline-info">
                      <FontAwesomeIcon icon={['far', 'cog']} /> Manage
                    </DropdownToggle>
                    <DropdownMenu style={{ maxWidth: '20px' }}>
                      <Render if={!policy?.renewedToPolicyId}>
                        <DropdownItem onClick={() => history.push(`${url}/renewal`)}>Renew</DropdownItem>
                      </Render>
                      <DropdownItem onClick={toggleConfirm}>Archive</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  <ArchivePolicy policy={policy} showConfirm={showConfirm} toggleConfirm={toggleConfirm} />
                </Render>
                <Button
                  color="info"
                  outline
                  className="ml-2"
                  loading={downloadLoading}
                  icon={['far', 'file-export']}
                  disabled={!!jobId}
                  onClick={() => startDownloadAllPolicyExposuresJob(policy?.id)}
                >
                  Export All Exposures
                </Button>
                <Button
                  color="info"
                  icon={['far', 'pen']}
                  className="ml-2"
                  onClick={() => history.push(`${url}/update`)}
                >
                  Edit 
                </Button>
              </HideFromRolesAndTypes>
            </HideFromTypes>
          }
        >
          <Render if={downloadError}>
            <div className="p-3">
              <GraphQLErrors error={downloadError} />
            </div>
          </Render>
          <PolicyDetailsView policy={policy} />
          <InvoiceTemplate invoiceTemplate={policy?.invoiceTemplate} clientProductId={policy?.id} policy={policy} />
        </TabPage>
      </Route>
      <Route path={`${path}/update`}>
        <PolicyUpdate policy={policy} />
      </Route>
      <Route path={`${path}/renewal`}>
        <RenewPolicy
          policy={policy}
          exposureElections={exposureElections}
          loading={exposureElectionsLoading}
          error={exposureElectionsError}
        />
      </Route>
    </Switch>
  );
}
