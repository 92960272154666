import React, { useState } from 'react';
import { Td, Tr, TrPlaceholder, TrLoader, RenderData } from '@oforce/global-components';
import { Table } from '../../../components/table-2.0';
import { TrGraphQLErrors } from '../../../components';
import { CustomInput } from 'reactstrap';
import { NameCell } from '../../../components/tables';
import { CellPricingOptionAndAddOns, CellVehicleInfo } from '../../exposureElections/components';

export default function RenewPolicyExposureElectionsTable({
  formik,
  elections,
  allValidExposureElectionIds,
  policy,
  loading,
  error
}) {
  const colSpan = 3;
  const insuranceTypeRequiresVehicle = policy?.insuranceType?.requireVehicle;
  const [selectAll, setSelectAll] = useState(true);

  const toggleSelectAll = selectAll => {
    !selectAll
      ? formik?.setFieldValue('exposureElectionIds', allValidExposureElectionIds)
      : formik?.setFieldValue('exposureElectionIds', []);

    setSelectAll(!selectAll);
  };

  return (
    <Table responsive>
      <thead>
        <tr>
          <th>
            <CustomInput
              id="toggleSelectAllExposureElectionIds"
              type="checkbox"
              checked={selectAll}
              onChange={() => toggleSelectAll(selectAll)}
              className="mb-1"
            />
          </th>
          <th>Insured</th>
          {insuranceTypeRequiresVehicle && <th>Vehicle</th>}
          <th>Coverage Option</th>
        </tr>
      </thead>
      <tbody>
        <RenderData
          data={elections}
          loading={loading}
          error={error}
          loadingComponent={<TrLoader colSpan={colSpan} />}
          errorComponent={<TrGraphQLErrors colSpan={colSpan} error={error} />}
          placeholderComponent={<TrPlaceholder colSpan={colSpan}>No exposure elections</TrPlaceholder>}
          style={{ border: '0px!important' }}
        >
          {elections =>
            elections?.map(election => (
              <Tr
                key={election?.id}
                style={formik?.values?.exposureElectionIds.includes(election?.id) ? { backgroundColor: '#e6eaf2' } : {}}
              >
                <Td>
                  <CustomInput
                    id={election?.id}
                    type="checkbox"
                    checked={formik?.values?.exposureElectionIds.includes(election?.id)}
                    onChange={() => {
                      if (formik?.values?.exposureElectionIds.includes(election?.id)) {
                        formik?.setFieldValue(
                          'exposureElectionIds',
                          formik?.values?.exposureElectionIds.filter(
                            exposure_election_id => exposure_election_id !== election?.id
                          )
                        );
                      } else {
                        formik?.setFieldValue('exposureElectionIds', [
                          ...formik?.values?.exposureElectionIds,
                          election?.id
                        ]);
                      }
                    }}
                  />
                </Td>
                <Td>
                  <NameCell insured={election?.insured} skipLink={true} />
                </Td>
                {insuranceTypeRequiresVehicle && (
                  <Td>
                    <CellVehicleInfo {...election} skipLink={true} />
                  </Td>
                )}
                <Td>
                  <CellPricingOptionAndAddOns {...election} />
                </Td>
              </Tr>
            ))
          }
        </RenderData>
      </tbody>
    </Table>
  );
}
