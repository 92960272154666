import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import _ from 'lodash';
import React from 'react';
import { TimelineEvent } from 'react-event-timeline';
import { backendNameToLabel, titleCase } from '../../../../utils/formatting';
import { theme } from '@oforce/global-styles';
const { red, green, gray500 } = theme;

export default function TimelineLogEvent({ eventDate, userData, eventData, note, reason, type }) {
  const userName = titleCase(userData['name']);
  const title = `${userName} initiated ${backendNameToLabel(reason)}`;
  const subtitle = eventData?.note ? _.upperFirst(eventData.note) : _.upperFirst(note);

  const icons = {
    NOOP: ['far', 'info-circle'],
    START: ['far', 'check'],
    STOP: ['far', 'times']
  };

  const colors = {
    NOOP: gray500,
    START: green,
    STOP: red
  };

  return (
    <TimelineEvent
      title={title}
      lineColor={colors[type]}
      subtitle={subtitle}
      bubbleStyle={{ borderColor: colors[type], width: '25px', height: '25px', marginLeft: '4px' }}
      createdAt={moment(eventDate).format('MMMM Do YYYY, h:mm:ss a')}
      icon={<FontAwesomeIcon icon={icons[type]} color={colors[type]} size="sm" />}
    ></TimelineEvent>
  );
}
