import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';

export const GET_CLIENTS = gql`
  query clients {
    allClients {
      id
      name
    }
  }
`;

export default function useClientSelect({ emptyOption }) {
  const { data, loading, error } = useQuery(GET_CLIENTS);
  const startingOption = emptyOption ? [emptyOption] : [];

  const clientOptions = data?.allClients?.reduce(
    (acc, client) => [...acc, { label: client.name, value: client.id }],
    startingOption
  );

  return { clientOptions, loading, error };
}
