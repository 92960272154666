import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';

export const GET_COMPANY = gql`
  query company($id: ID!) {
    company(id: $id) {
      id
      name
      address
      city
      state
      zip
      phone
      email
      updated
      created
    }
  }
`;
export default function useCompany({ id }) {
  const { data, loading, error } = useQuery(GET_COMPANY, {
    variables: { id }
  });

  return { company: data?.company, loading, error };
}
