import { gql } from '@apollo/client';
import { useSubscription } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';
import { GenerateCombinedInvoicesXslJobContext } from './context';
import { useResettableMutation } from '../../hooks/useResettableMutation';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const START_JOB = gql`
  mutation startCombinedInvoicesXslJob($invoiceIds: [Int]!) {
    generateCombinedInvoicesXsl(invoiceIds: $invoiceIds)
  }
`;

const ON_GENERATE_COMBINED_INVOICES_XSL_JOB_PROGRESS = gql`
  subscription jobProgress($jobId: ID!) {
    job: generateCombinedInvoicesXslJobProgress(jobId: $jobId) {
      jobId
      filename
      url
      finished
      message
      errors
    }
  }
`;

export default function GenerateCombinedInvoicesXslJobProvider({ children }) {
  const history = useHistory();
  const [jobId, setJobId] = useState(null);
  const [jobErrors, setJobErrors] = useState([]);
  const [finished, setJobFinished] = useState(true);
  const [message, setMessage] = useState(null);
  const [filename, setFilename] = useState(null);
  const [url, setUrl] = useState(null);

  const clearJobId = useCallback(() => {
    setJobId(null);
    setJobErrors([]);
  }, []);

  useEffect(() => {
    if (!!jobId && !!finished) {
      setTimeout(() => clearJobId(), 10000);
    }
  }, [finished, clearJobId, jobId]);

  const [startCombinedInvoicesXslJob, { loading, error, reset }] = useResettableMutation(START_JOB, {
    onCompleted: data => {
      setJobFinished(false);
      setJobId(data?.generateCombinedInvoicesXsl);
      history.push(`/invoices`);
    },
    onError: () => setTimeout(() => reset(), 10000)
  });

  const startGenerateCombinedInvoicesXslJob = data => {
    setJobFinished(false);
    startCombinedInvoicesXslJob({ variables: { invoiceIds: data?.invoiceIds } });
  };

  useSubscription(ON_GENERATE_COMBINED_INVOICES_XSL_JOB_PROGRESS, {
    skip: !jobId,
    variables: { jobId },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      const job = data?.job;
      const { message, filename, url, finished, errors } = job;

      setJobErrors(errors);
      setJobFinished(finished);
      setMessage(message);
      setFilename(filename);
      setUrl(url);
    }
  });

  return (
    <GenerateCombinedInvoicesXslJobContext.Provider
      value={{
        startGenerateCombinedInvoicesXslJob,
        jobId,
        clearJobId,
        loading,
        error,
        finished,
        filename,
        url,
        message,
        jobErrors
      }}
    >
      {children}
    </GenerateCombinedInvoicesXslJobContext.Provider>
  );
}
