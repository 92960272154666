export default function useAddOnRequiredOnOptions() {
  const addOnRequiredOnOptions = [
    {
      label: 'All Pricing Options',
      value: true
    },
    {
      label: 'Selected Pricing Options',
      value: false
    }
  ];

  return { addOnRequiredOnOptions };
}
