import React from 'react';
import * as yup from 'yup';
import { GraphQLErrors } from '../../../components';
import useDeletePolicy from '../hooks/useDeletePolicy';
import { InputField, Label, Modal } from '@oforce/global-components';
import { Formik } from 'formik';
import { Form } from 'reactstrap';

export default function DeletePolicy({ policy, showConfirm, toggleConfirm }) {
  const { error, loading, deletePolicy } = useDeletePolicy({ policy, onCompleted: toggleConfirm });

  const initialValues = { policyId: '' };
  const validationSchema = yup.object({
    policyId: yup
      .string()
      .required()
      .matches(new RegExp(`^${policy?.id}$`), { message: 'Must match policy id' })
  });

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={deletePolicy}>
      {formik => {
        return (
          <>
            <Modal
              isOpen={showConfirm}
              toggle={toggleConfirm}
              title="Delete Policy"
              loading={loading}
              submitButtonText="Delete Policy"
              submitButtonProps={{ color: 'info', disabled: !formik?.isValid, loadingText: 'Deleting...' }}
              scrollable
              onSubmit={formik.handleSubmit}
            >
              <GraphQLErrors error={error} />
              <Form>
                This action will schedule this policy for deletion in 24 hours. Deleting the policy will remove it and
                all the elections, pricing options, documents, and other data associated with the policy.
                <br />
                <br />
                This is a destructive action, and cannot be reversed.
                <br />
                <br />
                To delete this policy, type it's id ({policy?.id}) into the text box below, and click 'Confirm'.
                <Label className="fs-sm mt-3 font-weight-500 mb-0 d-inline-flex align-items-center">
                  Archive Notes
                </Label>
                <InputField
                  type="text"
                  name="policyId"
                  placeholder="Enter the policy's id..."
                  style={{ minWidth: '200px' }}
                />
              </Form>
            </Modal>
          </>
        );
      }}
    </Formik>
  );
}
