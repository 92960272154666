import * as yup from 'yup';
import { gql, useMutation } from '@apollo/client';
import moment from 'moment';
import { GET_POLICY }  from './usePolicy'

const UPDATE_POLICY_ARCHIVE_DATE = gql`
  mutation updatePolicyArchiveDate($policyId: ID!, $archiveNotes: String, $archiveDate: Date!) {
    updatePolicyArchiveDate(policyId: $policyId, archiveNotes: $archiveNotes, archiveDate: $archiveDate) {
      id
    }
  }
`;

export default function useUpdatePolicyArchiveDate({ policy, toggleConfirm }) {

  const initialValues = {
    archiveDate: policy?.archiveDate ? moment(policy?.archiveDate) : moment(),
    archiveNotes: policy?.archiveNotes ? policy?.archiveNotees : "",
  }

  const validationSchema = yup.object({
    archiveDate: yup.date().required('Required'),
    archiveNotes:  yup.string()
  });

  const [updatePolicyArchiveDate, { loading, error }] = useMutation(UPDATE_POLICY_ARCHIVE_DATE, {
    onCompleted: () => { return toggleConfirm(); },
    refetchQueries: [{ query: GET_POLICY, variables: { id: policy?.id } }],
    onError: () => {}
  });

  const handleSubmit = ({ archiveNotes, archiveDate }) => {
    updatePolicyArchiveDate({
      variables: {
        policyId: policy?.id,
        archiveDate: moment(archiveDate).format('YYYY-MM-DD'),
        archiveNotes: archiveNotes
      }
    });
  };

  return {
    handleSubmit,
    loading,
    initialValues,
    validationSchema,
    error
  };
}
