import { isEmpty } from '../../utils';
import { sentenceToCamelCaseLabel, sentenceToSnakeCase } from '../../utils/formatting';

export function prepStakeholderAttrs(attrs = {}, stakeHolderType) {
  const stakeholderKeys = Object.keys(attrs).filter(key => key.startsWith(stakeHolderType));

  const stakeholderAttrs = stakeholderKeys.reduce((stakeholderAttrs, key) => {
    const value = attrs[key];
    const newKey = removeStakeholderPrefix(key, stakeHolderType);

    return { ...stakeholderAttrs, [newKey]: value };
  }, {});

  return isEmpty(stakeholderAttrs) ? null : stakeholderAttrs;
}

export function removeStakeholderAttrs(attrs = {}, prefixes) {
  return Object.keys(attrs).reduce((acc, key) => {
    const match = prefixes.find(prefix => key.startsWith(prefix));
    if (!match) acc[key] = attrs[key];
    return acc;
  }, {});
}

const removeStakeholderPrefix = (key, prefixToRemove) => {
  return sentenceToCamelCaseLabel(key.replace(prefixToRemove, ''));
};

export function addStakeholderPrefix(field, stakeHolderType) {
  if (stakeHolderType) return sentenceToCamelCaseLabel(`${stakeHolderType} ${field}`);
  else return field;
}

export function prepareValuesForSubmit({ state, ...values }) {
  return {
    state: state?.value,
    ...values
  };
}

export function stakeholderLabels(stakeholderType) {
  const camelLables = {
    name: addStakeholderPrefix('name', stakeholderType),
    relationship: addStakeholderPrefix('relationship', stakeholderType),
    address1: addStakeholderPrefix('address1', stakeholderType),
    address2: addStakeholderPrefix('address2', stakeholderType),
    city: addStakeholderPrefix('city', stakeholderType),
    state: addStakeholderPrefix('state', stakeholderType),
    zip: addStakeholderPrefix('zip', stakeholderType),
    email: addStakeholderPrefix('email', stakeholderType),
    phone: addStakeholderPrefix('phone', stakeholderType)
  };

  const snakeLabels = {
    name: sentenceToSnakeCase(camelLables['name']),
    relationship: sentenceToSnakeCase(camelLables['relationship']),
    address1: sentenceToSnakeCase(camelLables['address1']),
    address2: sentenceToSnakeCase(camelLables['address2']),
    city: sentenceToSnakeCase(camelLables['city']),
    state: sentenceToSnakeCase(camelLables['state']),
    zip: sentenceToSnakeCase(camelLables['zip']),
    email: sentenceToSnakeCase(camelLables['email']),
    phone: sentenceToSnakeCase(camelLables['phone'])
  };

  return { camelLables, snakeLabels };
}
