import React from 'react';
import { TrLoader, TrPlaceholder } from '@oforce/global-components';
import _ from 'lodash';

import { Table } from '../../../components/table-2.0';
import { TrGraphQLErrors, RenderData, BoxContainer } from '../../../components';
import { HideFromRoles, useActiveAccount } from '../../../shared/activeAccount';

import { RetailCommissionsTableRow } from '.';

export default function RetailCommissionsTable({ retailCommissions, loading, error, productId }) {
  const {
    ROLES: { EXPOSURE_MANAGER, POLICY_MANAGER }
  } = useActiveAccount();
  const colSpan = 3;
  const sortedRetailCommissions = _.sortBy(retailCommissions, ['name']);

  return (
    <BoxContainer>
      <Table>
        <thead>
          <tr>
            <th>Commission</th>
            <th>Rate Mode</th>
            <th>Applies to</th>
            <HideFromRoles roles={[POLICY_MANAGER, EXPOSURE_MANAGER]}>
              <th />
            </HideFromRoles>
          </tr>
        </thead>
        <tbody>
          <RenderData
            data={sortedRetailCommissions}
            loading={loading}
            error={error}
            loadingComponent={<TrLoader colSpan={colSpan} />}
            errorComponent={<TrGraphQLErrors colSpan={colSpan} error={error} />}
            placeholderComponent={
              <TrPlaceholder colSpan={colSpan} className="bg-gray-100 border-bottom border-width-2 border-gray-300">
                No retail commissions
              </TrPlaceholder>
            }
          >
            {retailCommissions =>
              retailCommissions?.map(retailCommission => (
                <RetailCommissionsTableRow
                  key={retailCommission?.id}
                  retailCommission={retailCommission}
                  productId={productId}
                />
              ))
            }
          </RenderData>
        </tbody>
      </Table>
    </BoxContainer>
  );
}
