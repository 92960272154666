import { useCanadianProvinces, useFormOptions, useUSStates } from '../../../../../hooks';
import { useCountries } from '../../../../../hooks/forms';
import { useVehicleTypes } from '../../../../configurationVehicleTypes/hooks';
import usePolicyPricingOptionsWithAddons from '../../../../policyPricing/hooks/usePolicyPricingWithAddons';
import { useVehicleWeightClasses, useCommercialAutoCoverageTypes } from '../../../../vehicle/hooks';

export default function useImportFormOptions({ policy }) {
  const { options: stateOptions } = useUSStates();
  const { options: countryOptions } = useCountries();
  const { options: canadianProvincesOptions } = useCanadianProvinces();
  const { options: vehicleTypeOptions, loading: loadingVehicleTypeOptions } = useVehicleTypes();
  const { options: coverageTypeOptions, loading: loadingCoverageTypeOptions } = useCommercialAutoCoverageTypes();
  const { options: vehicleWeightClassOptions, loading: loadingVehicleWeightClassOptions } = useVehicleWeightClasses();
  const { options: pricingOptions, loading: loadingPolicyPricingOptions } = usePolicyPricingOptionsWithAddons({
    policyId: policy?.id
  });

  const formOptions = useFormOptions({
    options: [
      { name: 'state', options: stateOptions },
      { name: 'country', options: countryOptions },
      { name: 'canadianProvinces', options: canadianProvincesOptions },
      { name: 'allStates', options: [...stateOptions, ...canadianProvincesOptions] },
      { name: 'coverageType', options: coverageTypeOptions, loading: loadingCoverageTypeOptions },
      { name: 'vehicleTypes', options: vehicleTypeOptions, loading: loadingVehicleTypeOptions },
      { name: 'pricingOptions', options: pricingOptions, loading: loadingPolicyPricingOptions },
      { name: 'weightClass', options: vehicleWeightClassOptions, loading: loadingVehicleWeightClassOptions }
    ]
  });

  return { formOptions };
}
