import DocumentDownloadJobProvider from '../document-download-job/provider';
import ImportExposureJobProvider from '../exposure-import-job/provider';
import GenerateInvoiceXslJobProvider from '../generateInvoiceXslJob/provider';
import GenerateInvoicesReportJobProvider from '../generateInvoicesReportJob/provider';
import PolicyRenewalJobProvider from '../policyRenewalJob/provider';
import SendDocumentsJobProvider from '../sendDocumentsJob/provider';
import NotificationStatusReportJobProvider from '../notificationStatusReportJob/provider';
import DownloadFailedDocumentsJobProvider from '../downloadFailedDocumentsJob/provider';
import UpdateExposureJobProvider from '../exposure-update-job/provider';
import SyncPricingOptionsJobProvider from '../syncPricingOptionsJob/provider';
import GenerateCombinedInvoicesXslJobProvider from '../generateCombinedInvoicesXslJob/provider';
import BulkDownloadDocumentsJobProvider from '../bulkDownloadDocumentsJob/provider';

export default function JobProviders({ children }) {
  return (
    <ImportExposureJobProvider>
      <UpdateExposureJobProvider>
        <DocumentDownloadJobProvider>
          <PolicyRenewalJobProvider>
            <SendDocumentsJobProvider>
              <BulkDownloadDocumentsJobProvider>
                <GenerateInvoiceXslJobProvider>
                  <GenerateInvoicesReportJobProvider>
                    <NotificationStatusReportJobProvider>
                      <DownloadFailedDocumentsJobProvider>
                        <SyncPricingOptionsJobProvider>
                          <GenerateCombinedInvoicesXslJobProvider>
                            <>{children}</>
                          </GenerateCombinedInvoicesXslJobProvider>
                        </SyncPricingOptionsJobProvider>
                      </DownloadFailedDocumentsJobProvider>
                    </NotificationStatusReportJobProvider>
                  </GenerateInvoicesReportJobProvider>
                </GenerateInvoiceXslJobProvider>
              </BulkDownloadDocumentsJobProvider>
            </SendDocumentsJobProvider>
          </PolicyRenewalJobProvider>
        </DocumentDownloadJobProvider>
      </UpdateExposureJobProvider>
    </ImportExposureJobProvider>
  );
}
