import React from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Render, Badge, useToggle } from '@oforce/global-components';

import { ActionDropdown } from '../../../components/table-2.0';
import { BoxItem, BoxItemTitle } from '../../../components/box';
import { ShowForTypes, HideFromRoles, useActiveAccount } from '../../../shared/activeAccount';

import { Delete } from '../containers';
import { useNormalizeCoverageParameterTypes } from '../hooks';

export default function CoverageParameterBoxItem({ coverageParameter, productId, isProduct, policyId }) {
  const isPredefinedParam = coverageParameter?.isPredefined;

  const [showDelete, toggleDelete] = useToggle();
  const {
    TYPES: { CARRIER },
    ROLES: { EXPOSURE_MANAGER }
  } = useActiveAccount();
  const { url } = useRouteMatch();
  const history = useHistory();

  const { getValueTypeName } = useNormalizeCoverageParameterTypes(coverageParameter);
  const typeBadgePrefixText = {
    INDIVIDUAL: '',
    LIST: 'List of ',
    RANGE: 'Range of '
  }[coverageParameter?.type];
  const typeBadgeText = `${getValueTypeName(coverageParameter?.valueType)}`;

  const goToUpdate = () => {
    history.push(`${url}/${coverageParameter?.id}/update`);
  };

  return (
    <>
      <BoxItem key={coverageParameter?.id}>
        <div className="d-flex align-items-center w-100">
          <span className="d-flex flex-row align-items-center" style={{ width: '450px' }}>
            <BoxItemTitle title={coverageParameter?.name} subtitle={coverageParameter?.label} className="mr-3" />
            <Render if={coverageParameter?.validate}>
              <Badge color="warning">Used for Exposure Validation</Badge>
            </Render>
          </span>

          <span className="mr-3 text-muted">
            <Render if={!!typeBadgePrefixText}>
              <span className="font-weight-500 mr-1">{typeBadgePrefixText}</span>
            </Render>
            {typeBadgeText}
          </span>
        </div>
        <ShowForTypes types={[CARRIER]}>
          <HideFromRoles roles={[EXPOSURE_MANAGER]}>
            <ActionDropdown onEdit={!isPredefinedParam && goToUpdate} editText="Rename" onDelete={toggleDelete} />
          </HideFromRoles>
        </ShowForTypes>
      </BoxItem>

      <Delete
        coverageParameter={coverageParameter}
        productId={productId}
        isProduct={isProduct}
        policyId={policyId}
        isOpen={showDelete}
        toggle={toggleDelete}
      />
    </>
  );
}
