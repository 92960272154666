import { gql } from '@apollo/client';
import { useMemo, useState } from 'react';
import { useResettableMutation } from '../../../hooks/useResettableMutation';

export const SEND_INVOICE_XSL_EMAIL = gql`
  mutation SendInvoiceXslEmails($invoiceId: Int!, $emailAddresses: [String]!, $groupBy: ReportGroupBy!) {
    sendInvoiceXslEmail(invoiceId: $invoiceId, emailAddresses: $emailAddresses, groupBy: $groupBy)
  }
`;

export default function useSendInvoiceXslEmails({ invoice, groupByOptions, onCompleted, onError = () => {} }) {
  const [send, { loading, error, reset: resetSendInvoice }] = useResettableMutation(SEND_INVOICE_XSL_EMAIL, {
    onCompleted,
    onError
  });

  const [invoiceEmailAddresses, setInvoiceEmails] = useState([]);
  const [groupByOption, setGroupByOption] = useState(groupByOptions[0]?.value);

  const configuredEmailAddresses = useMemo(() => {
    const addresses = invoice?.invoiceEmailAddresses?.map(email => ({ label: email, value: email }));
    setInvoiceEmails(addresses);
    return addresses;
  }, [invoice]);

  const handleSubmit = () => {
    send({
      variables: {
        invoiceId: invoice?.id,
        emailAddresses: invoiceEmailAddresses?.map(({ value }) => value) || [],
        groupBy: groupByOption
      }
    });
  };

  return {
    handleSubmit,
    loading,
    error,
    reset: () => {
      setGroupByOption(groupByOptions[0]?.value);
      setInvoiceEmails(configuredEmailAddresses);
      resetSendInvoice();
    },
    invoiceEmailAddresses,
    setInvoiceEmails,
    groupByOption,
    setGroupByOption
  };
}
