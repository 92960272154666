import React from 'react';
import { BoxContainer, RenderData, TrGraphQLErrors } from '../../components';
import { Table } from '../../components/table-2.0';
import { TrPlaceholder, TrLoader } from '@oforce/global-components';
import PendingElectionRow from './PendingElectionRow';

export default function PendingElectionsTable({ error, loading, refetch, pendingElections}) {
  const colSpan = 6;

  return (
    <div>
      <BoxContainer>
        <Table>
          <thead>
            <tr>
              <th style={{ width: '10%' }}>Status</th>
              <th style={{ width: '15%' }}>External Id</th>
              <th style={{ width: '15%' }}>Policy</th>
              <th style={{ width: '15%' }}>Pricing Option</th>
              <th style={{ width: '10%' }}>Insured</th>
              <th style={{ width: '10%' }}>Vehicle</th>
              <th style={{ width: '20%' }}>Errors</th>
              <th style={{ width: '5%' }} className="text-right">
                Actions
              </th>
            </tr>
          </thead>

          <tbody>
            <RenderData
              data={pendingElections}
              loading={loading}
              error={error}
              loadingComponent={<TrLoader colSpan={colSpan} />}
              errorComponent={<TrGraphQLErrors error={error} colSpan={colSpan} />}
              placeholderComponent={<TrPlaceholder colSpan={colSpan}>No Pending Elections</TrPlaceholder>}
            >
              {renderData =>
                renderData?.map(pendingElection => (
                  <PendingElectionRow key={pendingElection.id} pendingElection={pendingElection} refetch={refetch} />
                ))
              }
            </RenderData>
          </tbody>
        </Table>
      </BoxContainer>
    </div>
  );
}
