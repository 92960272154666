import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';

const GET_POLICY_PRICING_OPTIONS = gql`
  query policyPricingOptions($id: ID!) {
    policy(id: $id) {
      id
      pricingOptions {
        id
        label
      }
    }
  }
`;

export default function usePolicyPricingOptions({ policy, policyId, onCompleted = () => {}, onError = () => {} }) {
  const foundPolicyId = policyId || policy?.id;

  if (!foundPolicyId) {
    throw Error('Expected either a policy object or policyId');
  }

  const { data, loading, error } = useQuery(GET_POLICY_PRICING_OPTIONS, {
    variables: { id: foundPolicyId },
    skip: !foundPolicyId,
    fetchPolicy: !foundPolicyId ? 'cache-only' : 'cache-first',
    onCompleted,
    onError
  });

  const selectOptions = data?.policy?.pricingOptions?.map(pricing => ({
    label: pricing?.label,
    value: pricing?.id
  }));

  return {
    policyPricingOptions: data?.policy?.pricingOptions,
    selectOptions,
    loading,
    error
  };
}
