import React from 'react';
import { usePolicyRenewalJobContext } from '../../../context';
import { MultiStepForm } from '../../../components';
import { Steps } from 'react-step-builder';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useRenewPolicyFormSteps } from '../hooks';
import RenewPolicyExposureElectionsSelection from './RenewPolicyExposureElectionsSelection';
import RenewPolicyAdvisory from './RenewPolicyAdvisory';

export default function RenewPolicy({ policy, exposureElections }) {
  const history = useHistory();
  const { startPolicyRenewalJob, loading, error } = usePolicyRenewalJobContext();

  const allValidExposureElectionIds = exposureElections?.map(({ id }) => id);
  const { formSteps } = useRenewPolicyFormSteps({ allValidExposureElectionIds });

  if (policy?.status === 'ARCHIVED') return null;
  if (!!policy?.renewedToPolicyId) return null;

  return (
    <MultiStepForm
      formSteps={formSteps}
      formSubmit={({ exposureElectionIds }) => {
        startPolicyRenewalJob({ policyId: policy?.id, exposureElectionIds });
      }}
      maxWidth={'800px'}
      title={`Renew Policy`}
      onCancel={() => history.push(`/policies/${policy?.id}`)}
      submitButtonText={'Renew Policy'}
      submitButtonProps={{ loading, loadingText: 'Renewing...' }}
      showButtonsOnBottom
      error={error}
    >
      {formProps => (
        <Steps>
          <RenewPolicyAdvisory {...formProps} policyId={policy?.id} />
          <RenewPolicyExposureElectionsSelection
            {...formProps}
            policy={policy}
            allValidExposureElectionIds={allValidExposureElectionIds}
          />
        </Steps>
      )}
    </MultiStepForm>
  );
}
