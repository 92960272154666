import { Formik } from 'formik';
import React, { useRef } from 'react';
import { Modal } from '@oforce/global-components';

import { GraphQLErrors } from '..';
import { AccountWebhookForm } from '.';
import { validationSchema } from './accountWebhookValidationSchema';

export default function AccountWebhookAdd({ isOpen, toggle, error, loading, initialValues, handleSubmit }) {
  const focusRef = useRef(null);

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async values => {
        await handleSubmit(values);
      }}
    >
      {formik => (
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          title="Add Webhook"
          loading={loading}
          onSubmit={formik.handleSubmit}
          submitButtonText="Add Webhook"
          submitButtonProps={{ color: 'success', disabled: formik.isSubmitting, loading: formik.isSubmitting }}
          onOpened={() => {
            formik.resetForm();
            focusRef?.current?.focus();
          }}
        >
          <GraphQLErrors error={error} />
          <AccountWebhookForm formik={formik} focusRef={focusRef} loading={loading} />
        </Modal>
      )}
    </Formik>
  );
}
