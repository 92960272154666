import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledLink = styled(NavLink).attrs({
  className: 'd-inline-flex align-items-center flex-row text-nowrap mr-3 mr-md-4'
})`
  color: ${props => props.theme.gray600} !important;
  text-decoration: none !important;
  border-bottom: 2px solid ${props => props.theme.gray400};
  padding-bottom: 2px;
  &:hover {
    color: ${props => props.theme.gray800} !important;
    border-bottom: 2px solid ${props => props.theme.gray500};
  }
  &.active,
  &:active {
    color: ${props => props.theme.blue} !important;
    border-bottom: 2px solid ${props => props.theme.blue};
  }
`;

export default function TabLink({ to, icon, children, ...rest }) {
  return (
    <StyledLink to={to} {...rest}>
      {icon && <FontAwesomeIcon icon={icon} className="mb-1 mr-2" />}
      {children}
    </StyledLink>
  );
}
