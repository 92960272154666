import React from 'react';
import { Formik } from 'formik';
import { Modal } from '@oforce/global-components';

import { useChangeElectionForm } from '../hooks';
import { ExposureElectionForm } from '../components';

export default function ExposureElectionChangePricing({ isOpen, toggle, currentPricingOptionId, election, policy }) {
  const { validationSchema, initialValues, handleSubmit, loading, error, formOptions } = useChangeElectionForm({
    currentPricingOptionId,
    policy,
    election,
    onCompleted: toggle
  });

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {formik => (
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          title="Change Pricing"
          onOpened={formik.handleReset}
          onSubmit={formik.handleSubmit}
          submitButtonDisabled={formik.isSubmitting}
          submitButtonProps={{ loadingText: 'Changing...', loading }}
          submitButtonText="Save Changes"
        >
          <ExposureElectionForm formik={formik} formOptions={formOptions} error={error} />
        </Modal>
      )}
    </Formik>
  );
}
