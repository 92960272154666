import React from 'react';
import { Button, useToggle } from '@oforce/global-components';

import { TabPage } from '../../components';
import { ShowForRoles, useActiveAccount } from '../../shared/activeAccount';

import { useAccountMembers } from './hooks';
import { AccountInviteMember } from './containers';
import { AccountMembersDashboard, AccountMembersTable } from './components';

export default function AccountMembers() {
  const {
    id: accountId,
    ROLES: { OWNER, COLLABORATOR }
  } = useActiveAccount();
  const { members, loading, ...rest } = useAccountMembers({
    accountId
  });
  const [showInviteMember, toggleInviteMember] = useToggle(false);

  return (
    <>
      <ShowForRoles roles={[OWNER, COLLABORATOR]}>
        <AccountMembersDashboard members={members} loading={loading} {...rest} />
      </ShowForRoles>
      <TabPage
        title="Members"
        buttons={
          <ShowForRoles roles={[OWNER, COLLABORATOR]}>
            <Button color="info" icon={['far', 'plus']} onClick={toggleInviteMember}>
              Invite Member
            </Button>
          </ShowForRoles>
        }
      >
        <AccountMembersTable members={members} loading={loading} />

        <AccountInviteMember isOpen={showInviteMember} toggle={toggleInviteMember} accountId={accountId} />
      </TabPage>
    </>
  );
}
