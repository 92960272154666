import { pascalCase } from 'pascal-case';
import { snakeCase } from 'snake-case';

function createQueryDocument({ name, query }) {
  return `const GET_${snakeCase(name).toLocaleUpperCase()} = gql\`${query}\``;
}

const query = ({ name }) => `const { loading, error, data } = 
    useQuery(GET_${snakeCase(name).toLocaleUpperCase()}, { variables: {...variables}})`;

export default function singleQuery({ operationDataList }) {
  const operation = operationDataList[0];
  const hookName = `useOforce${pascalCase(operation.name)}`;

  return `
  import { gql } from '@apollo/client';
  import { useQuery } from '@apollo/client';
  
  ${createQueryDocument(operation)}
  
  function ${hookName}({...variables}) {      
  ${query(operation)}
  
    return { loading, error, data };
  }
  
  export default ${hookName};
      `;
}
