import * as yup from 'yup';
import moment from 'moment';
import { Form, Formik } from 'formik';
import { Col, Row } from 'reactstrap';
import { Modal, ReactSelectField, DatePickerField } from '@oforce/global-components';

import { useFormOptions } from '../../hooks';
import { GraphQLErrors } from '../../components';
import { useOwnedProducts } from '../products/hooks';
import { useClients } from '../clients/hooks';
import { useGenerateInvoicesReportJobContext } from '../../context';
import { useEffect } from 'react';

export default function InvoicesGenerateReport({ isOpen, toggle, policyId }) {
  const { jobId, loading, error, startGenerateInvoicesReportJob } = useGenerateInvoicesReportJobContext();

  useEffect(() => {
    if (!loading && !error && !!jobId) {
      toggle();
    }
  });

  const { options: productOptions } = useOwnedProducts();
  const { options: clientOptions } = useClients();

  const formOptions = useFormOptions({
    options: [
      { name: 'product', options: [{ label: 'All', value: '' }, ...productOptions] },
      { name: 'client', options: [{ label: 'All', value: '' }, ...clientOptions] }
    ]
  });

  const initialValues = {
    start: '',
    end: '',
    client: formOptions?.client?.options[0],
    product: formOptions?.product?.options[0],
    policyId
  };

  const handleSubmit = ({ start, end, ...values }) => {
    const clientId = values?.client?.value;
    const productId = values?.product?.value;
    const policyId = values?.policyId;

    const variables = {
      ...(start && { start: moment(start).format('YYYY-MM-DD') }),
      ...(end && { end: moment(end).format('YYYY-MM-DD') }),
      ...(!!clientId && { clientId }),
      ...(!!productId && { productId }),
      ...(!!policyId && { policyId })
    };

    startGenerateInvoicesReportJob({ variables }, toggle);
  };

  const validationSchema = yup.object({
    start: yup.string(),
    end: yup.string(),
    client: yup.object(),
    product: yup.object()
  });

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {formik => (
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          title="Generate Invoices Report"
          onSubmit={formik.handleSubmit}
          onOpened={() => {
            formik.handleReset();
          }}
          submitButtonText="Generate Report"
          submitButtonProps={{
            color: 'info',
            loading,
            loadingText: loading ? 'Generating report...' : 'Downloading...'
          }}
        >
          <GraphQLErrors error={error} className="mb-4" />
          <Form>
            <fieldset>
              <ReactSelectField label="Client(s)" name="client" options={formOptions?.client?.options} />
              <ReactSelectField label="Product(s)" name="product" options={formOptions?.product?.options} />
              <Row form>
                <Col>
                  <DatePickerField label="From" name="start" allowClear fullWidth inputReadOnly={true} />
                </Col>
                <Col>
                  <DatePickerField label="To" name="end" allowClear fullWidth inputReadOnly={true} />
                </Col>
              </Row>
            </fieldset>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}
