import React from 'react';
import { BoxContainer, TabPage, Table, TrGraphQLErrors } from '../../../components';
import { Loader, RenderData, TrLoader, TrPlaceholder } from '@oforce/global-components';
import DocumentVersionRow from './DocumentVersionRow';
import { useDocumentRelationshipVersions } from '../hooks';
import { useHistory } from 'react-router-dom';

export default function DocumentVersions({ documentRelationshipId }) {
  const history = useHistory();
  const { documentRelationship, versions, document, loading, error } = useDocumentRelationshipVersions({
    documentRelationshipId
  });
  const colSpan = 5;

  if (loading) return <Loader />;

  return (
    <>
      <TabPage
        title={!!document?.name ? `Document Versions: ${document?.name}` : 'Document Versions'}
        showBackButton
        onBack={() => history.goBack()}
        marginBottom="mb-0"
        contentClassName="mt-1"
        titleClassName="mb-1"
        className="flex-grow-1 mt-0"
      >
        <BoxContainer>
          <Table responsive>
            <thead>
              <tr>
                <th>Status</th>
                <th>Version</th>
                <th>Effective Dates</th>
                <th>Inactive Reason</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <RenderData
                data={versions}
                loading={loading}
                error={error}
                loadingComponent={<TrLoader colSpan={colSpan} />}
                errorComponent={<TrGraphQLErrors colSpan={colSpan} error={error} />}
                placeholderComponent={<TrPlaceholder colSpan={colSpan}>No Versions</TrPlaceholder>}
              >
                {versions =>
                  versions?.map((version, i) => (
                    <DocumentVersionRow
                      key={`version-${i}`}
                      version={version}
                      documentRelationship={documentRelationship}
                    />
                  ))
                }
              </RenderData>
            </tbody>
          </Table>
        </BoxContainer>
      </TabPage>
    </>
  );
}
