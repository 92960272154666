import { useEffect, useState } from 'react';
import { uuid } from '../../utils/regex';
import { validateJson } from '../JsonInput';
import schema from './schema.json';

export default function useCustomData({ data, onSubmit, reset = () => {}, onChange }) {
  const [dataChanged, setDataChanged] = useState(false);
  const [viewJson, setViewJson] = useState(false);
  const [customData, setCustomData] = useState([]);
  const [jsonCustomData, setJsonCustomData] = useState('');
  const [inputError, setError] = useState(null);

  const resetState = data => {
    const objectData = jsonToObjectList(data);
    const jsonData = objectListToJson(objectData);
    setCustomData(objectData);
    setJsonCustomData(jsonData);
    setError(null);
    setDataChanged(false);
    reset();
  };

  useEffect(() => {
    if (data) {
      const objectData = jsonToObjectList(data);
      const jsonData = objectListToJson(objectData);
      setCustomData(objectData);
      setJsonCustomData(jsonData);
    }

    // eslint-disable-next-line
  }, [data]);

  const isValidJson = json => {
    const { valid } = validateJson(schema, json);
    return valid;
  };

  const validateAndSync = () => {
    const currentJson = viewJson ? jsonCustomData : objectListToJson(customData);
    const valid = isValidJson(currentJson);
    const currentData = valid && viewJson ? jsonToObjectList(jsonCustomData) : customData;

    if (valid) {
      setCustomData(currentData);
      setJsonCustomData(currentJson);
      return true;
    } else {
      setError({ graphQLErrors: [{ message: 'Must be valid JSON with flat values and unique keys' }] });
      return false;
    }
  };

  const changeView = () => {
    if (validateAndSync()) {
      setViewJson(!viewJson);
    }
  };

  const jsonToObjectList = (json = {}) => {
    if (json === '' || json === null) return [];
    const parsed = typeof json === 'string' ? JSON.parse(json) : json;
    const normalizedParsed = parsed ? parsed : {};
    const data = Object.keys(normalizedParsed).reduce(
      (acc, key) => [...acc, { id: uuid(), key, value: normalizedParsed[key] }],
      []
    );
    return cleanEmptyKeys(data);
  };

  const objectListToJson = objectList => {
    const cleanedData = cleanEmptyKeys(objectList);
    const data = cleanedData.reduce((acc, { key, value }) => ({ ...acc, [key]: value }), {});
    return Object.keys(data).length > 0 ? JSON.stringify(data, null, 2) : '{}';
  };

  const cleanEmptyKeys = (listData = []) => {
    return listData.reduce((acc, object) => {
      if (!!object.key) {
        return [...acc, object];
      }
      return acc;
    }, []);
  };

  const addNew = () => {
    const newData = [...customData, { id: uuid(), key: '', value: '' }];
    setCustomData(newData);
  };

  const editJsonData = value => {
    setError(null);
    const newValue = value || '{}';
    setJsonCustomData(newValue);
    handleJsonMatch(newValue);
  };

  const remove = id => {
    const newData = customData.filter(e => e.id !== id);

    setCustomData(newData);
    handleDataMatch(newData);
  };

  const editKey = (id, key) => {
    setError(null);

    const newData = customData.map(x => {
      if (x.id === id) {
        x.key = key;
      }
      return x;
    });

    setCustomData(newData);
    handleDataMatch(newData);
  };

  const editValue = (id, value) => {
    setError(null);
    const newData = customData.map(x => {
      if (x.id === id) {
        x.value = value;
      }
      return x;
    });

    setCustomData(newData);
    handleDataMatch(newData);
  };

  const submit = () => {
    if (validateAndSync()) {
      const data = viewJson ? JSON.parse(jsonCustomData) : JSON.parse(objectListToJson(customData));
      setDataChanged(false);
      onSubmit && onSubmit(data);
    }
  };

  const handleJsonMatch = json => {
    if (isValidJson(json) && !dataMatch(json)) {
      setDataChanged(true);
    } else {
      setDataChanged(false);
    }
  };

  const handleDataMatch = incomingData => {
    const newData = incomingData.map(({ key, value }) => ({ key, value }));
    const jsonData = objectListToJson(newData);

    if (!dataMatch(jsonData)) {
      setDataChanged(true);
    } else {
      setDataChanged(false);
    }
  };

  const dataMatch = json => {
    return (
      Object.keys(JSON.parse(json)).every(key => data[key] === JSON.parse(json)[key]) &&
      Object.keys(data).every(key => JSON.parse(json)[key] === data[key])
    );
  };

  return {
    viewJson,
    changeView,
    dataChanged,
    jsonCustomData,
    editJsonData,
    addNew,
    remove,
    editKey,
    editValue,
    customData,
    inputError,
    resetState,
    submit
  };
}
