import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';

const GET_PRICING_OPTIONS_WITH_ADDONS = gql`
  query getPricingOptions($policyId: ID!) {
    policy(id: $policyId) {
      id
      pricingOptions {
        id
        name
        label
        addOns {
          required {
            id
            label
          }
          optional {
            id
            label
          }
        }
      }
    }
  }
`;

export default function usePolicyPricingOptionsWithAddons({ policyId }) {
  const skip = policyId === undefined || policyId === null ? true : false;

  const { data, loading, error } = useQuery(GET_PRICING_OPTIONS_WITH_ADDONS, {
    variables: { policyId },
    skip,
    fetchPolicy: skip ? 'cache-only' : 'cache-first'
  });

  const options = data?.policy?.pricingOptions?.map(pricing => ({
    label: pricing?.label,
    value: pricing?.id,
    requiredAddons: pricing?.addOns?.required?.map(option => {
      return { value: option.id, label: option.label };
    }),
    optionalAddons: pricing?.addOns?.optional?.map(option => {
      return { value: option.id, label: option.label };
    })
  }));

  return { pricingOptions: data?.policy?.pricingOptions, options, loading, error };
}
