import React, { useState } from 'react';
import { FilterLabel } from '../filtering';
import { Button } from '@oforce/global-components';
import { uuid } from '../../utils/regex';
import SearchByInputRow from './SearchByInputRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function SearchBy({
  fieldOptions,
  name,
  label,
  defaultValue,
  className,
  onFilterChange,
  maxFilters = 4
}) {
  const [filters, setFilters] = useState([{ id: uuid(), field: defaultValue || fieldOptions[0], value: '' }]);

  const currentFilterFields = filters.reduce((acc, { field }) => [...acc, field.value], []);
  const availableFieldOptions = fieldOptions.filter(({ value }) => !currentFilterFields.includes(value));

  const addFilter = () => {
    const nextFieldOption = availableFieldOptions[0];
    setFilters([...filters, { id: uuid(), field: nextFieldOption, value: '' }]);
  };

  const removeFilter = ({ id: idToRemove }) => {
    const newFilters = filters.filter(({ id }) => !(id === idToRemove));
    setFilters(newFilters);
    onFilterChange && onFilterChange(newFilters);
  };

  const changeFilterField = (filterToChange, newFieldOption) => {
    const newFilters = filters.reduce((acc, existingFilter) => {
      if (existingFilter.id === filterToChange.id) {
        return [...acc, { ...existingFilter, field: newFieldOption, value: '' }];
      } else {
        return [...acc, existingFilter];
      }
    }, []);

    setFilters(newFilters);
    onFilterChange && onFilterChange(newFilters);
  };

  const changeFilterValue = (filterToChange, incomingValue) => {
    const newFilters = filters.reduce((acc, existingFilter) => {
      if (existingFilter.id === filterToChange.id) {
        return [...acc, { ...existingFilter, value: incomingValue }];
      } else {
        return [...acc, existingFilter];
      }
    }, []);

    setFilters(newFilters);
    onFilterChange && onFilterChange(newFilters);
  };

  return (
    <div id={name} className={className}>
      <FilterLabel>{label}</FilterLabel>

      <div className="d-flex flex-column">
        {filters.map((filter, index) => {
          const { id, field: fieldOption, value } = filter;

          return (
            <div key={id} className="d-flex flex-row align-items-center" style={{ zIndex: '10' }}>
              <SearchByInputRow
                option={fieldOption}
                value={value}
                fieldOptions={availableFieldOptions}
                onFieldChange={newFieldOption => changeFilterField(filter, newFieldOption)}
                onValueUpdate={value => changeFilterValue(filter, value)}
              />
              {index !== 0 && (
                <FontAwesomeIcon
                  className="ml-2 cursor-pointer"
                  onClick={() => removeFilter(filter)}
                  icon={['far', 'trash-alt']}
                />
              )}
            </div>
          );
        })}
        {filters.length <= maxFilters && (
          <Button
            className="align-self-end mt-1 mr-3"
            size="xs"
            color="link"
            icon={['far', 'plus']}
            onClick={() => addFilter()}
            style={{ fontSize: '13px' }}
          >
            Add Search Filter
          </Button>
        )}
      </div>
    </div>
  );
}
