import React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import { Render, Tr, Td, Badge } from '@oforce/global-components';

import { formatDate, formatCurrency } from '../../../utils/formatting';
import { InvoiceStatus, InvoiceStatusBadge } from '../../invoice/components';

export default function InvoicesTableRow({ invoice, showBrokerTotal }) {
  const history = useHistory();
  const navigateToInvoice = () => history.push(`/invoices/${invoice?.id}`);
  const dueDateToolTipId = `due-date-${invoice?.id}`;
  const policyStatus = invoice?.clientProduct?.effectiveStatus;
  const policyIsArchived = policyStatus === 'ARCHIVED';

  return (
    <Tr onClick={navigateToInvoice}>
      <Td>
        <Render if={!policyIsArchived}>
          <InvoiceStatusBadge invoice={invoice} />
        </Render>
        <Render if={policyIsArchived}>
          <Badge color="muted">Archived</Badge>
        </Render>
      </Td>
      <Td>
        <span className="font-weight-500">{invoice?.id}</span>
      </Td>
      <Td>{moment(invoice?.invoiceDate).format('MM-DD-YYYY')}</Td>
      <Td>
        <UncontrolledTooltip target={dueDateToolTipId}>
          {moment(invoice?.dueDate).format('MM-DD-YYYY')}
        </UncontrolledTooltip>
        <span id={dueDateToolTipId}>{moment(invoice?.dueDate).fromNow()}</span>
      </Td>
      <td>
        {invoice?.type === 'OFF_CYCLE'
          ? 'Off Cycle'
          : `${formatDate(invoice?.serviceStartDate)} – ${formatDate(invoice?.serviceEndDate)}`}
      </td>
      <Td>
        <span className="font-weight-500 mr-2">{formatCurrency(invoice?.clientTotal || 0)}</span>

        <InvoiceStatus status={invoice?.clientStatus} />
      </Td>
      <Render if={showBrokerTotal}>
        <Td>
          <span className="font-weight-500 mr-2">{formatCurrency(invoice?.brokerTotal || 0)}</span>
          <InvoiceStatus status={invoice?.brokerStatus} />
        </Td>
      </Render>
      <Td>{invoice?.clientProduct?.externalId}</Td>
      <Td>{invoice?.client?.name}</Td>
    </Tr>
  );
}
