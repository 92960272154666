import { useEffect } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import useCursorPagination from '../../../hooks/useCursorPagination';
import { EXPOSURE_ELECTION_STATUSES } from '../../exposureElections/constants';

const { ACTIVE } = EXPOSURE_ELECTION_STATUSES;

export const LIST_VALID_POLICY_EXPOSURE_ELECTIONS_FOR_RENEWAL = gql`
  query listValidPolicyExposureElectionsForRenewal(
    $policyId: ID!
    $status: ExposureElectionStatus
    $terminationDate: Date
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    listValidPolicyExposureElectionsForRenewal(
      policyId: $policyId
      status: $status
      terminationDate: $terminationDate
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        node {
          id
          insured {
            firstName
            lastName
            businessName
            businessName2
          }
          vehicle {
            id
            vin
            vehicleYear
            vehicleMake
            vehicleModel
            unitNumber
          }
          addOns {
            id
            label
          }
          pricingOption {
            id
            label
            rate
            rateMode
            factoredField
            isPercentageFactor
          }
        }
      }
    }
  }
`;

export default function useListValidPolicyExposureElectionsForRenewal({
  numberToDisplay = 20,
  policy,
  first,
  last,
  after,
  before
}) {
  const [loadExposureElections, { data, loading, error }] = useLazyQuery(
    LIST_VALID_POLICY_EXPOSURE_ELECTIONS_FOR_RENEWAL
  );

  useEffect(() => {
    const variables = {
      first: numberToDisplay,
      policyId: policy?.id,
      ...(policy?.effectiveStatus === 'EXPIRED' ? { terminationDate: policy?.expirationDate } : { status: ACTIVE }),
      after: null,
      before: null,
      last: null
    };

    loadExposureElections({ variables });
  }, [loadExposureElections, numberToDisplay, policy?.id, policy?.effectiveStatus, policy?.expirationDate]);

  const { loadNextPage, loadPreviousPage } = useCursorPagination(loadExposureElections, {
    count: numberToDisplay
  });

  const pageInfo = data?.listValidPolicyExposureElectionsForRenewal?.pageInfo || {};
  const { hasNextPage, hasPreviousPage } = pageInfo;

  return {
    elections: data?.listValidPolicyExposureElectionsForRenewal?.edges?.map(x => x.node),
    loading,
    error,
    hasNextPage,
    hasPreviousPage,
    fetchMore: loadExposureElections,
    loadNextPage: () => {
      loadNextPage(pageInfo, {
        first,
        last,
        after,
        before
      });
    },
    loadPreviousPage: () =>
      loadPreviousPage(pageInfo, {
        first,
        last,
        after,
        before
      })
  };
}
