import React from 'react';

import { toSentenceCase } from 'js-convert-case';
import { useCompanies } from '../../companies/hooks';
import { titleCase } from '../../../utils/formatting';
import { useProrateSettings } from '../../pricing/hooks';
import { useInvoicingScheduleOptions } from '../../invoice/hooks';
import { BoxContainer, BoxContainerTitle, LabelValueGroup, Render } from '../../../components';
import { displayGuardedLimit } from '../../exposureElections/hooks/useExposureBackdateLimits';
import { useActiveAccount } from '../../../shared/activeAccount';

export default function ProductDetailsView({ product }) {
  const { options: companiesOptions } = useCompanies();
  const productCompanies = product?.companies;
  const insuranceType = product?.insuranceType?.name;
  const enableCustomStartDates = product?.enableCustomStartDates ? 'Enabled' : 'Disabled';
  const enableCustomTerminationDates = product?.enableCustomTerminationDates ? 'Enabled' : 'Disabled';

  const automateInvoiceGeneration = product?.automateInvoiceGeneration ? 'Enabled' : 'Disabled';
  const { options: invoicingScheduleOptions } = useInvoicingScheduleOptions();
  const invoicingSchedule = invoicingScheduleOptions?.find(o => o.value === product?.invoicingSchedule)?.label;

  const {
    options: prorateSettingOptions,
    PRORATE_SETTINGS: { NO_PRORATE }
  } = useProrateSettings();
  const enableProrating = product?.prorateSetting !== NO_PRORATE ? 'Enabled' : 'Disabled';
  const prorateSetting = prorateSettingOptions?.find(x => x.value === product?.prorateSetting)?.label;
  const prorateAddons = product?.prorateAddons ? 'Enabled' : 'Disabled';
  const startNumber = product?.masterCertificateStartNumber;
  const endNumber = product?.masterCertificateEndNumber;
  const prefix = product?.masterCertificatePrefix;
  const suffix = product?.masterCertificateSuffix;
  const pricingRateIsWeekly = product?.pricingRateType === 'WEEKLY';

  const { isAdmin } = useActiveAccount();

  return (
    <>
      <BoxContainer className="px-3 py-1 mb-5">
        <LabelValueGroup label="Name" value={product?.name} />
        <LabelValueGroup label="External Id" value={product?.externalId} />
        <LabelValueGroup label="Active Exposures" value={product?.activeExposureCount} />
        <CompanyRoleLabelValueGroup
          role="RETAIL_BROKER"
          label="Retail Broker"
          productCompanies={productCompanies}
          companiesOptions={companiesOptions}
        />
        <LabelValueGroup label="Insurance Type" value={insuranceType} />
        <CompanyRoleLabelValueGroup
          role="INSURANCE_CARRIER"
          label="Insurance Carrier"
          productCompanies={productCompanies}
          companiesOptions={companiesOptions}
        />
        <LabelValueGroup label="Custom Start Dates" value={enableCustomStartDates} />
        <Render if={product?.enableCustomStartDates}>
          <LabelValueGroup
            label="Backdate Start Limit"
            value={displayGuardedLimit(product?.startDateBackdatingLimit)}
          />
        </Render>
      <LabelValueGroup label="Custom Termination Dates" value={enableCustomTerminationDates} />
        <Render if={product?.enableCustomTerminationDates}>
          <LabelValueGroup
            label="Backdate Termination Limit"
            value={displayGuardedLimit(product?.terminationDateBackdatingLimit)}
          />
        </Render>
        <LabelValueGroup label="Rating Zone Source" value={toSentenceCase(product?.ratingZoneSource)} />
        <Render if={isAdmin}>
          <LabelValueGroup label="API Only Exposure Updates" value={product?.apiOnlyExposureUpdates ? 'Yes' : 'No'} />
        </Render>
      </BoxContainer>

      <BoxContainerTitle>Pricing</BoxContainerTitle>
      <BoxContainer className="px-3 py-1 mb-5">
        <LabelValueGroup label="Pricing Rate" value={titleCase(product?.pricingRateType)} />
        <LabelValueGroup label="Prorating" value={enableProrating} />
        <Render if={enableProrating === 'Enabled'}>
          <LabelValueGroup label="Prorate Setting" value={prorateSetting} />
          <LabelValueGroup label="Prorate AddOns" value={prorateAddons} />
        </Render>
        <LabelValueGroup label="Billing Rule" value={toSentenceCase(product?.billingRule)} />
        <Render if={product?.snapshotCustomDay}>
          <LabelValueGroup label="Snapshot Custom Day" value={product?.snapshotCustomDay} />
        </Render>
        <Render if={product?.billingRule !== 'CLASSIC'}>
          <LabelValueGroup label="One Premium Required" value={product.onePremiumRequired ? 'Enabled' : 'Disabled'} />
        </Render>
      </BoxContainer>

      <BoxContainerTitle>Invoicing</BoxContainerTitle>
      <BoxContainer className="px-3 py-1 mb-5">
        <LabelValueGroup label="Automate Invoice Generation" value={automateInvoiceGeneration} />
        <Render if={automateInvoiceGeneration === 'Enabled'}>
          <LabelValueGroup label="Collect Payment for" value={invoicingSchedule} />
          <LabelValueGroup label="Invoice Generation Day" value={product?.invoiceGenerationDay} />
        </Render>
        <Render if={!pricingRateIsWeekly}>
          <LabelValueGroup label="Invoice Due Day" value={product?.invoiceDueDay} />
        </Render>
      </BoxContainer>

      <Render if={!!startNumber}>
        <BoxContainerTitle>Master Certificates</BoxContainerTitle>
        <BoxContainer className="px-3 py-1 mb-5">
          <LabelValueGroup
            label="Format"
            value={
              <>
                <LabelValueGroup
                  labelWidth="50px"
                  label="Prefix"
                  pad={false}
                  showBorder={false}
                  value={prefix || 'None'}
                />
                <LabelValueGroup
                  labelWidth="50px"
                  label="Suffix"
                  pad={false}
                  showBorder={false}
                  value={suffix || 'None'}
                />
              </>
            }
          />
          <LabelValueGroup
            label="Number Range"
            value={
              <>
                <LabelValueGroup
                  labelWidth="50px"
                  label="Start"
                  pad={false}
                  showBorder={false}
                  value={startNumber || 'None'}
                />
                <LabelValueGroup
                  labelWidth="50px"
                  label="End"
                  pad={false}
                  showBorder={false}
                  value={endNumber || 'None'}
                />
              </>
            }
          />
        </BoxContainer>
      </Render>
    </>
  );
}

const CompanyRoleLabelValueGroup = ({ label, role, productCompanies, companiesOptions }) => {
  const companyId = productCompanies?.find(x => x.role === role)?.companyId;
  const value = companiesOptions?.find(x => x.value === companyId)?.label;

  return <LabelValueGroup label={label} value={value} />;
};
