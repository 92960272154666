import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const StyledTabButton = styled.button.attrs({
  className: 'd-inline-flex align-items-center flex-row text-nowrap mr-3 mr-md-4'
})`
  border: 0;
  background: none !important;
  color: ${({ active, theme }) => (active ? theme.blue : theme.gray600)};
  border-bottom: 2px solid ${({ active, theme }) => (active ? theme.blue : theme.gray400)};
  padding-bottom: 2px;
  &:hover {
    color: ${({ active, theme }) => (active ? theme.blue : theme.gray800)};
  }
  &:focus {
    outline: 0 !important;
  }
`;

export default function TabButton({ onClick, icon, children, ...rest }) {
  return (
    <StyledTabButton type="button" onClick={onClick} {...rest}>
      {icon && <FontAwesomeIcon icon={icon} className="mb-1 mr-2" />}
      {children}
    </StyledTabButton>
  );
}
