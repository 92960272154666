import React from 'react';
import { Row, Col } from 'reactstrap';
import { createFilter } from 'react-select';
import { InputField, ReactSelectField } from '@oforce/global-components';
import { stakeholderLabels } from '../../stakeholder/utils';

export default function VehicleStakeholderFormFields({ formOptions, stakeholderType = null, requiredFields = {} }) {
  const filterConfig = { matchFrom: 'start' };
  const { camelLables, snakeLabels } = stakeholderLabels(stakeholderType);

  return (
    <>
      <InputField name={camelLables['name']} label="Name" required={requiredFields[snakeLabels['name']]} />
      <InputField
        name={camelLables['email']}
        label="Email"
        type="email"
        required={requiredFields[snakeLabels['email']]}
      />
      <InputField
        name={camelLables['phone']}
        label="Phone"
        type="tel"
        required={requiredFields[snakeLabels['phone']]}
      />
      <InputField name={camelLables['address1']} label="Address 1" required={requiredFields[snakeLabels['address1']]} />
      <InputField name={camelLables['address2']} label="Address 2" required={requiredFields[snakeLabels['address2']]} />
      <Row form>
        <Col>
          <InputField name={camelLables['city']} label="City" required={requiredFields[snakeLabels['city']]} />
        </Col>
        <Col>
          <ReactSelectField
            name={camelLables['state']}
            required={requiredFields[snakeLabels['state']]}
            label="State"
            options={formOptions?.allStates?.options}
            filterOption={createFilter(filterConfig)}
            isClearable
          />
        </Col>
        <Col>
          <InputField name={camelLables['zip']} label="Zip" required={requiredFields[snakeLabels['zip']]} />
        </Col>
      </Row>
    </>
  );
}
