import React, { useState } from 'react';
import { Steps } from 'react-step-builder';
import { useHistory } from 'react-router-dom';
import { useToggle } from '@oforce/global-components';
import { ConfirmModal, MultiStepForm } from '../../../components';

import { usePolicyFormOptions, usePolicyFormSteps, usePolicyFormSubmit } from '../hooks';
import { PolicyDetailsFormFields, PolicyInvoicingFormFields, PolicyPricingFormFields } from '../components';
import { CustomDataStep } from '../../productCreateUpdate/components';

export default function PolicyUpdate({ policy }) {
  const history = useHistory();

  const [confirmed, setConfirmed] = useState(false);
  const [showConfirmModal, toggleConfirmModal] = useToggle();

  const { formOptions } = usePolicyFormOptions();
  const { formSteps } = usePolicyFormSteps({ policy, formOptions });
  const { formSubmit, loading, error } = usePolicyFormSubmit({
    policy,
    onCompleted: () => history.push(`/policies/${policy?.id}`),
    toggleConfirmModal,
    confirmed
  });

  const onConfirm = ({ formik }) => {
    setConfirmed(true);
    toggleConfirmModal();
    formik.handleSubmit();
  };

  return (
    <MultiStepForm
      title="Edit Policy"
      formOptions={formOptions}
      formSteps={formSteps}
      formSubmit={formSubmit}
      onCancel={() => history.push(`/policies/${policy?.id}`)}
      submitButtonText="Save Changes"
      submitButtonProps={{ loading, loadingText: 'Saving...' }}
      showButtonsOnBottom
      error={error}
    >
      {formProps => (
        <>
          <Steps>
            <PolicyDetailsFormFields {...formProps} />
            <CustomDataStep title="Policy Custom Data" {...formProps} />
            <PolicyPricingFormFields {...formProps} />
            <PolicyInvoicingFormFields {...formProps} />
          </Steps>
          <ConfirmModal isOpen={showConfirmModal} toggle={toggleConfirmModal} onConfirm={() => onConfirm(formProps)}>
            This policy has an effective date in the past and automatic invoice generation is enabled. If you continue,
            any invoices occuring in the past will be generated immediately.
          </ConfirmModal>
        </>
      )}
    </MultiStepForm>
  );
}
