import { gql, useQuery } from '@apollo/client';

export const GET_INVOICE = gql`
  query invoice($id: Int!) {
    invoice(id: $id) {
      __typename
      id
      dueDate
      serviceStartDate
      serviceEndDate
      invoiceDate
      clientTotal
      brokerTotal
      clientStatus
      brokerStatus
      status
      fees
      addOns
      retailCommission
      premium
      policyNumber
      invoiceEmailAddresses
      voided
      notes
      policyId
      clientProduct {
        id
        effectiveStatus
        hasApiFactoredField
        automateInvoiceGeneration
        insuranceType {
          id
          type
          requireVehicle
        }
      }
      product {
        name
      }
      invoiceTemplate {
        filename
        url
      }
      locationTotals {
        locationName
        total
        transactions {
          id
          coverageDates {
            startDate
            endDate
          }
          exposureElectionSource {
            insured {
              firstName
              middleName
              lastName
              businessName
              ssnLastFour
            }
            vehicle {
              id
              value
              vin
              unitNumber
            }
          }
          insuredId
          firstName
          lastName
          businessName
          productName
          transactionDate
          retailCommission
          total
          label
          addOns
          addOnsSource {
            label
          }
          factorValue
          factorLabel
        }
      }
      masterSubTotals {
        isSubTransactions
        total
        masterInsured {
          firstName
          lastName
          businessName
        }
        transactions {
          id
          coverageDates {
            startDate
            endDate
          }
          exposureElectionSource {
            contractType
            insured {
              firstName
              middleName
              lastName
              businessName
              ssnLastFour
            }
            vehicle {
              id
              value
              vin
              unitNumber
            }
          }
          insuredId
          firstName
          lastName
          businessName
          productName
          transactionDate
          retailCommission
          total
          label
          addOns
          addOnsSource {
            label
          }
          factorValue
          factorLabel
        }
      }
    }
  }
`;

export default function useInvoice({ id }) {
  const { data, loading, error } = useQuery(GET_INVOICE, {
    fetchPolicy: 'network-only',
    variables: { id: parseInt(id) },
    skip: !id
  });

  return {
    invoice: data?.invoice,
    loading,
    error
  };
}
