import { gql, useQuery } from '@apollo/client';

export const INSURED_ELECTION_VEHICLE_FIELDS = gql`
  fragment InsuredElectionVehicleFields on ExposureElection {
    vehicle {
      id
      vin
      value
      vehicleYear
      vehicleMake
      vehicleModel
      unitNumber
    }
  }
`;

export const INSURED_ELECTIONS_FIELDS = gql`
  fragment InsuredElectionsFields on ExposureElection {
    id
    policyId
    insuredId
    electionData
    terminationDate
    startDate
    pricingRateType
    enableCustomStartDates
    productName
    clientName
    total
    status
    premiumTotal
    contractType
    masterElection {
      id
      insured {
        id
        firstName
        middleName
        lastName
        businessName
      }
    }
    compliance {
      inCompliance
      rejected
      violations {
        id
        status
        source
        type
        field
        reason
      }
    }
    masterCertificateNumber
    ...InsuredElectionVehicleFields
    insured {
      id
      firstName
      middleName
      lastName
      email
      mobilePhone
      businessName
    }
    client {
      id
      name
    }
    product {
      id
      name
    }
    policy {
      id
      name
      apiOnlyExposureUpdates
      startDateBackdatingLimit
      startDateBackdatingMinimum
      enableCustomTerminationDates
      terminationDateBackdatingLimit
      terminationDateBackdatingMinimum
      insuranceType {
        id
        name
        type
        requireVehicle
      }
      product {
        id
      }
    }
    addOns {
      id
      label
    }
    pricingOption {
      id
      label
      rateMode
      factoredField
      rate
      coverageParameters {
        id
        name
        value
      }
      isPercentageFactor
    }
  }
  ${INSURED_ELECTION_VEHICLE_FIELDS}
`;

export const GET_INSURED_ELECTIONS = gql`
  query GetInsuredElections($id: ID!) {
    insuredElections(insuredIds: [$id]) {
      ...InsuredElectionsFields
    }
  }
  ${INSURED_ELECTIONS_FIELDS}
`;

export default function useInsuredElections({ insuredId }) {
  const { data, loading, error, refetch } = useQuery(GET_INSURED_ELECTIONS, { variables: { id: insuredId } });

  return {
    insuredElections: data?.insuredElections,
    refetch,
    loading,
    error
  };
}
