import * as yup from 'yup';
import { gql } from '@apollo/client';

export const validationSchema = yup.object().shape({
  name: yup.string().required('Required'),
  address: yup.string(),
  city: yup.string(),
  state: yup.object().nullable(),
  zip: yup.string(),
  phone: yup.string(),
  email: yup.string()
});

export const COMPANY_CORE_FIELDS = gql`
  fragment CompanyCoreFields on Company {
    name
    address
    city
    state
    zip
    phone
    email
    created
    updated
  }
`;
