import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { Modal } from '@oforce/global-components';

import { GraphQLErrors } from '../../../components';
import { GET_POLICY } from '../../../views/policy/hooks/usePolicy';

export const CANCEL_POLICY_INVITATION = gql`
  mutation CancelPolicyInvitation($id: ID!) {
    cancelPolicyInvitation(id: $id) {
      id
    }
  }
`;

export default function CancelPolicyInvitation({ isOpen, toggle, policyId, invitationId, onError = () => {} }) {
  const [cancelPolicyInvitation, { reset, loading, error }] = useMutation(CANCEL_POLICY_INVITATION, {
    variables: { id: invitationId },
    onError,
    onCompleted: toggle,
    refetchQueries: [{ query: GET_POLICY, variables: { id: policyId } }]
  });

  return (
    <Modal
      centered
      onOpened={reset}
      onSubmit={cancelPolicyInvitation}
      isOpen={isOpen}
      toggle={toggle}
      title="Revoke Invite"
      submitButtonProps={{ color: 'danger', loading }}
      submitButtonText="Revoke Invite"
    >
      <GraphQLErrors error={error} />
      Are you sure you want to revoke this invite?
    </Modal>
  );
}
