import { gql, useMutation } from '@apollo/client';
import { SERVER_SIDE_DATE_FORMAT } from '../../../utils/dates';
import { GET_DOCUMENTS } from './useProductDocuments';

const UPLOAD_DOCUMENT = gql`
  mutation UploadDocument($input: AddProductDocumentInput!) {
    addProductDocument(input: $input) {
      id
      documents {
        id
        name
        filename
        url
        appliesToAllPricingOptions
        pricingOptionIds
        type
        stateSource
        appliesToStates
        excludeFromStates
        populateOnPending
        effectiveDate
        expirationDate
      }
    }
  }
`;

const UPDATE_DOCUMENT = gql`
  mutation UpdateDocument($input: EditProductDocumentInput!) {
    editProductDocument(input: $input) {
      id
      documents {
        id
        name
        filename
        url
        appliesToAllPricingOptions
        pricingOptionIds
        type
        stateSource
        appliesToStates
        excludeFromStates
        populateOnPending
        effectiveDate
        expirationDate
      }
    }
  }
`;

export default function useProductDocumentFormSubmit({
  isCreateMode,
  productId,
  productDocumentId,
  onCompleted = () => {},
  onError = () => {}
}) {
  const [upload, { loading: uploading, error: uploadError }] = useMutation(UPLOAD_DOCUMENT, {
    onCompleted,
    onError
  });

  const [update, { loading: updating, error: updateError }] = useMutation(UPDATE_DOCUMENT, {
    refetchQueries: [{ query: GET_DOCUMENTS, variables: { id: productId } }],
    onCompleted,
    onError
  });

  const formSubmit = values => {
    const sharedInput = {
      name: values?.name,
      type: values?.type?.value,
      appliesToAllPricingOptions: values?.appliesToAllPricingOptions?.value,
      pricingOptionIds: values?.pricingOptions?.map(pricing => pricing?.value),
      stateSource: values?.stateSource?.value,
      appliesToStates: values?.selectedStates?.map(selectedState => selectedState.value),
      excludeFromStates: values?.excludedStates?.map(selectedState => selectedState.value),
      populateOnPending: values?.populateOnPending,
      effectiveDate: values?.effectiveDate.format(SERVER_SIDE_DATE_FORMAT),
      expirationDate: !!values?.expirationDate ? values?.expirationDate.format(SERVER_SIDE_DATE_FORMAT) : null
    };

    if (isCreateMode) {
      upload({
        variables: {
          input: {
            productId,
            fileData: values?.document[0],
            ...sharedInput
          }
        }
      });
    } else {
      update({
        variables: {
          input: {
            productDocumentId,
            ...sharedInput
          }
        }
      });
    }
  };

  return { formSubmit, loading: uploading || updating, error: uploadError || updateError };
}
