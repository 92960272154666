import { useCanadianProvinces, useFormOptions, useUSStates } from '../../../hooks';
import { COUNTRY_OPTIONS } from '../../../hooks/forms/useCountries';

export default function useInsuredFormOptions() {
  const { options: stateOptions } = useUSStates();
  const { options: canadianProvincesOptions } = useCanadianProvinces();

  return useFormOptions({
    options: [
      { name: 'state', options: stateOptions },
      { name: 'canadianProvinces', options: canadianProvincesOptions },
      { name: 'allStates', options: [...stateOptions, ...canadianProvincesOptions] },
      {
        name: 'country',
        options: COUNTRY_OPTIONS
      }
    ]
  });
}
