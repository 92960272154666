import React from 'react';
import { Render, TrPlaceholder, TrLoader } from '@oforce/global-components';

import { Table } from '../../../components/table-2.0';
import { BoxContainer, RenderData, TrGraphQLErrors } from '../../../components';
import { TablePagination } from '../../../components/tables';

import PolicyRow from './PolicyRow';
import PoliciesFilter from './PoliciesFilter';
import { useActiveAccount } from '../../../shared/activeAccount';

export default function PoliciesTable({ policies, loading, error, policyFilter, changePolicyFilter, paginationProps }) {
  const colSpan = 6;
  const {
    roleIsAtLeast,
    ROLES: { EXPOSURE_MANAGER }
  } = useActiveAccount();

  return (
    <>
      <PoliciesFilter {...policyFilter} onChange={changePolicyFilter} />

      <BoxContainer>
        <Table>
          <thead>
            <tr>
              <th style={{ width: '20%' }}>Policy Name</th>
              <th style={{ width: '20%' }}>Client Name</th>
              <th style={{ width: '10%' }}>External Id</th>
              <th style={{ width: '10%' }}>Status</th>
              <th style={{ width: '20%' }}>Policy Term</th>
              <th style={{ width: '10%' }}>Policy Pricing</th>
              <Render if={roleIsAtLeast(EXPOSURE_MANAGER)}>
                <th style={{ width: '10%' }} className="text-right">
                  Actions
                </th>
              </Render>
            </tr>
          </thead>

          <tbody>
            <RenderData
              data={policies}
              loading={loading}
              error={error}
              loadingComponent={<TrLoader colSpan={colSpan} />}
              errorComponent={<TrGraphQLErrors error={error} colSpan={colSpan} />}
              placeholderComponent={<TrPlaceholder colSpan={colSpan}>No Policies</TrPlaceholder>}
            >
              {renderData => renderData?.map(policy => <PolicyRow key={policy.id} policy={policy} />)}
            </RenderData>
          </tbody>
        </Table>
      </BoxContainer>

      <TablePagination {...paginationProps} loading={loading} />
    </>
  );
}
