import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';

export const GET_POLICY_ADD_ONS = gql`
  query policyAddOns($policyId: ID!) {
    policy(id: $policyId) {
      id
      addOns {
        id
        cost
        label
        name
        customData
        appliesToAllPricingOptions
        pricingOptionIds
        requiredOnAllPricingOptions
        requiredForPricingOptionIds
      }
    }
  }
`;

export default function usePolicyAddOns({ policyId }) {
  const { data, loading, error } = useQuery(GET_POLICY_ADD_ONS, {
    skip: !policyId,
    fetchPolicy: 'cache-and-network',
    variables: { policyId }
  });

  return { addOns: data?.policy?.addOns, loading, error };
}
