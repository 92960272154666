import React from 'react';
import { Render, ReactSelectField } from '@oforce/global-components';
import { FormSection } from '../../../components/forms';
import { CoverageParameterInputFields } from '../../coverageParameters/components';

export default function ProductPricingCoverageParametersFields({
  formik,
  formOptions,
  coverageParameters,
  insuranceTypeRequiresVehicle
}) {
  return (
    <FormSection title="Coverage Parameters">
      <Render if={formOptions?.vehicleTypes?.options?.length > 0 && insuranceTypeRequiresVehicle}>
        <ReactSelectField
          name="vehicleTypes"
          label="Vehicle Types"
          options={formOptions?.vehicleTypes?.options}
          isMulti
        />
      </Render>
      <CoverageParameterInputFields formik={formik} formOptions={formOptions} params={coverageParameters} />
    </FormSection>
  );
}
