export const VARIABLE_TYPES = {
  INT: 'Int',
  DECIMAL: 'Decimal',
  ID: 'ID',
  FLOAT: 'Float',
  STRING: 'String',
  BOOLEAN: 'Boolean',
  DATE: 'Date',
  ENUM: 'Enum'
};

export const VALID_TYPES = Object.keys(VARIABLE_TYPES).map(type => VARIABLE_TYPES[type]);
