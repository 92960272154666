import React from 'react';
import { TrLoader, TrPlaceholder, RenderData } from '@oforce/global-components';

import { Table } from '../../../components/table-2.0';
import { BoxContainer, TrGraphQLErrors } from '../../../components';

import { VehicleTypeRow } from '.';

export default function VehicleTypeTable({ vehicleTypes, loading, error }) {
  const colSpan = 2;

  return (
    <BoxContainer>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <RenderData
            data={vehicleTypes}
            loading={loading}
            error={error}
            loadingComponent={<TrLoader colSpan={colSpan} />}
            errorComponent={<TrGraphQLErrors colSpan={colSpan} error={error} />}
            placeholderComponent={<TrPlaceholder colSpan={colSpan}>No vehicle types</TrPlaceholder>}
          >
            {vehicleTypes =>
              vehicleTypes?.map(vehicleType => <VehicleTypeRow key={vehicleType?.id} vehicleType={vehicleType} />)
            }
          </RenderData>
        </tbody>
      </Table>
    </BoxContainer>
  );
}
