import React from 'react';
import { gql, useQuery, useMutation, useSubscription } from '@apollo/client';

import { CURRENT_USER_FIELDS } from '../../shared/currentUser';
import { ACTIVE_ACCOUNT_FIELDS } from '../../shared/activeAccount';

import CurrentUserContext from './context';
import { AuthenticatingPage, GraphQLErrorsPage } from '../../components/pages';
import { useEnabledFeatures } from './hooks';

export const GET_CURRENT_USER = gql`
  query currentUser {
    currentUser {
      ...CurrentUserFields
      ...ActiveAccountFields
    }
  }
  ${CURRENT_USER_FIELDS}
  ${ACTIVE_ACCOUNT_FIELDS}
`;

const SET_ACTIVE_ACCOUNT = gql`
  mutation setActiveAccountId($accountId: ID) {
    user: setActiveAccountId(accountId: $accountId) {
      id
      activeAccountId
      activeAccountType
      activeAccountRole
      activeAccountName
    }
  }
`;

const ACTIVE_ACCOUNT_SWITCHED = gql`
  subscription accountSwitched($userId: ID!) {
    user: activeAccountSwitched(userId: $userId) {
      id
      activeAccountId
      activeAccountType
      activeAccountRole
      activeAccountName
    }
  }
`;

export default function CurrentUserProvider({ children, onError = () => {} }) {
  const {
    data,
    loading: loadingCurrentUser,
    error: currentUserError,
    refetch
  } = useQuery(GET_CURRENT_USER, { fetchPolicy: 'cache-first' });

  const enabledFeatures = useEnabledFeatures();

  const [setActiveAccountId, { loading: switchingAccounts, error: switchingAccountsError }] = useMutation(
    SET_ACTIVE_ACCOUNT,
    {
      onError
    }
  );

  useSubscription(ACTIVE_ACCOUNT_SWITCHED, {
    skip: !data?.currentUser?.id,
    variables: { userId: data?.currentUser?.id },
    onSubscriptionData: () => {
      window.location.reload();
    }
  });

  // here I'm changing the api for the frontend to make it more friendly since it's weird on the backend
  const activeAccount = {
    id: data?.currentUser?.activeAccountId,
    name: data?.currentUser?.activeAccountName,
    role: data?.currentUser?.activeAccountRole,
    type: data?.currentUser?.activeAccountType,

    loading: switchingAccounts,
    error: switchingAccountsError,
    setActiveAccountId: (accountId, callback) => {
      setActiveAccountId({ variables: { accountId } });
      callback && callback();
    },
    refetchUser: refetch
  };

  const currentUser = {
    ...data?.currentUser,
    // here we put activeAccount on the current user as it's own object, rather than each individual field on the current user
    activeAccount,
    enabledFeatures
  };

  if (loadingCurrentUser) return <AuthenticatingPage />;
  if (currentUserError) return <GraphQLErrorsPage error={currentUserError} />;

  return (
    <CurrentUserContext.Provider value={currentUser}>
      {typeof children === 'function' ? children(currentUser) : children}
    </CurrentUserContext.Provider>
  );
}
