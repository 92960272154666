import { gql } from '@apollo/client';
import { usePaginatedQuery } from '../../../hooks';

export const GET_POLICIES = gql`
  query policies($first: Int, $after: String, $last: Int, $before: String, $productId: ID, $expired: Boolean) {
    policies(first: $first, after: $after, last: $last, before: $before, expired: $expired, productId: $productId) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export default function useProductPolicies({ pageSize = 20, filters = {} }) {
  const { data, loading, error, refetch, paginationProps, queryProps } = usePaginatedQuery(GET_POLICIES, {
    variables: filters,
    pathToPageInfo: 'policies',
    pageSize,
    fetchPolicy: 'cache-and-network'
  });

  return {
    policies: data?.policies?.edges?.map(({ node }) => node),
    loading: loading,
    error: error,
    refetch,
    paginationProps,
    policiesQueryProps: queryProps
  };
}
