import React from 'react';
import { FormGroup } from 'reactstrap';
import { FieldLabel, ReactSelectField, Render, SwitchField } from '@oforce/global-components';

import { FormSection } from '../../../components';
import useCoverageParameterTypes from '../hooks/useCoverageParameterTypes';

export default function CoverageParameterValidationFormFields({ formik, isCreateMode }) {
  const {
    typesNeedingValueType,
    typeOptions: allTypeOptions,
    getValueTypeOptions,
    getPredefinedTypeOptions,
    getPredefinedValueTypeOptions
  } = useCoverageParameterTypes();

  const isPredefined = formik?.values?.isPredefined;
  const paramLabel = formik?.values?.predefinedCoverageParameter?.value;

  const type = formik?.values?.type?.value;
  const typeLabel = formik?.values?.type?.label;
  const typeNeedsValueType = !!type && typesNeedingValueType.includes(type);

  const valueTypeOptions = isPredefined ? getPredefinedValueTypeOptions(paramLabel) : getValueTypeOptions(type);
  const typeOptions = isPredefined ? getPredefinedTypeOptions(paramLabel) : allTypeOptions;

  const coverageParameterName = isPredefined
    ? formik?.values?.predefinedCoverageParameter?.label
    : formik?.values?.name;

  return (
    <FormSection title="Types" subtitle="Determine the type of your coverage parameter">
      <FormGroup className="mb-4">
        <FieldLabel>{isPredefined ? 'Predefined Coverage Parameter' : 'Custom Coverage Parameter'}</FieldLabel>
        <span className="font-weight-500">{coverageParameterName}</span>
      </FormGroup>
      <ReactSelectField
        required
        label="Type"
        options={typeOptions}
        name="type"
        isDisabled={!isCreateMode}
        onChange={() => formik?.setFieldValue('valueType', null)}
        className={'mb-4'}
      />
      <Render if={typeNeedsValueType}>
        <ReactSelectField
          label={`Type of Values Inside ${typeLabel}`}
          required
          options={valueTypeOptions}
          name="valueType"
          isDisabled={!isCreateMode}
          isClearable
          className={'mb-4'}
        />
      </Render>
      <Render if={isPredefined}>
        <SwitchField
          id="validate"
          label="Validate"
          name="validate"
          size="sm"
          switchLabel={formik?.values?.validate ? 'Yes' : 'No'}
          messageAfterProps={{ icon: ['far', 'info-circle'] }}
          messageAfter={formik?.values?.validate ? '' : 'This parameter will not be use to validate exposures.'}
        />
      </Render>
    </FormSection>
  );
}
