import React from 'react';
import { Button, SwitchField, useToggle } from '@oforce/global-components';

import { BoxContainer } from '../../components';
import { TabPage } from '../../components/tabs';

import { FeesTable, FeeCreate, StateFeesTable } from './components';
import { HideFromRoles, ShowForTypes, useActiveAccount } from '../../shared/activeAccount';
import { Formik } from 'formik';
import { useSetIncludeStateFees } from './hooks';

export default function PolicyFees({ policy }) {
  const [showCreateFee, toggleCreateFee] = useToggle();
  const {
    TYPES: { CARRIER, BROKER },
    ROLES: { EXPOSURE_MANAGER }
  } = useActiveAccount();

  const initialValues = {
    policyId: policy?.id,
    includeStateFees: policy?.includeStateFees === true ? true : false
  };

  const { stateFees } = useSetIncludeStateFees();

  const handleSubmit = values => stateFees(values);

  return (
    <TabPage
      title="Fees"
      showBackButton
      buttons={
        <ShowForTypes types={[CARRIER, BROKER]}>
          <HideFromRoles roles={[EXPOSURE_MANAGER]}>
            <Button color="info" icon={['far', 'plus']} className="ml-2" onClick={toggleCreateFee}>
              Create
            </Button>
          </HideFromRoles>
        </ShowForTypes>
      }
    >
      <FeeCreate isOpen={showCreateFee} toggle={toggleCreateFee} policy={policy} />
      <BoxContainer className="p-3">
        <FeesTable policy={policy} />
      </BoxContainer>

      <div className="d-flex align-items-end justify-content-between mt-5">
        <span className="d-flex flex-column">
          <h5>State Fees</h5>
        </span>
        <span className="d-flex flex-row">
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {formik => (
              <SwitchField
                id="includeStateFees"
                label=""
                name="includeStateFees"
                size="sm"
                switchLabel={policy?.includeStateFees ? 'Include State Fees' : 'Include State Fees'}
                onChange={formik.handleSubmit}
              />
            )}
          </Formik>
        </span>
      </div>
      <BoxContainer className="p-3">
        <StateFeesTable policy={policy} />
      </BoxContainer>
    </TabPage>
  );
}
