import { gql, useQuery } from '@apollo/client';
import { backendNameToLabel } from '../../../utils/formatting';

export const INSURED_DOCUMENT_SEARCH_BY_OPTIONS = gql`
  query InsuredDocumentSearchByOptions {
    documentRelationshipSearchByFields
  }
`;

export default function useInsuredDocumentSearchByOptions() {
  const { loading, error, data } = useQuery(INSURED_DOCUMENT_SEARCH_BY_OPTIONS, {
    onCompleted: () => {},
    onError: () => {}
  });

  return {
    insuredDocumentSearchByOptions:
      data?.documentRelationshipSearchByFields?.map(field => ({
        label: backendNameToLabel(field),
        value: field
      })) || [],
    loading,
    error
  };
}
