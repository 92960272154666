import { gql, useQuery } from '@apollo/client';
import { useActiveAccount } from '../../../shared/activeAccount';
import { useCurrentUser } from '../../../shared/currentUser';

// TODO: write backend query for get_outgoing_invitations
// The account_id on an account_invitation is the account doing the inviting (come be a part of my account)

export const GET_ACCOUNT_USERS_AND_INVITES = gql`
  query GetAccountUsersAndInvites($accountId: ID!) {
    account(id: $accountId) {
      id
      users {
        id
        role
        user {
          id
          name
          avatar
          email
        }
      }
      invitations {
        id
        status
        role
        emailAddress
      }
    }
  }
`;

export default function useAccountMembers({ accountId }) {
  const { data, loading, error } = useQuery(GET_ACCOUNT_USERS_AND_INVITES, {
    variables: { accountId }
  });

  const {
    showForRoles,
    ROLES: { OWNER, COLLABORATOR }
  } = useActiveAccount();
  const { id: currentUserId } = useCurrentUser();

  const normalizedUsers = data?.account?.users?.reduce((acc, user) => {
    return [
      ...acc,
      {
        role: user?.role,
        userId: user?.user?.id,
        name: user?.user?.name,
        avatar: user?.user?.avatar,
        email: user?.user?.email,
        status: 'MEMBER',
        isCurrentUser: user?.user?.id === currentUserId
      }
    ];
  }, []);

  const combineUsersWithInvites = (acc, invite) => {
    // if owner of account, combine invites with users list
    if (showForRoles([OWNER, COLLABORATOR])) {
      if (invite?.status === 'CLAIMED' || invite?.status === 'REVOKED') return acc;

      return [
        ...acc,
        {
          name: invite?.emailAddress,
          invitationId: invite?.id,
          role: invite?.role,
          email: invite?.emailAddress,
          status: invite?.status,
          isCurrentUser: false
          // targetAccountId: invite?.accountId
        }
      ];
    } else {
      // just return users list
      return acc;
    }
  };

  const normalizedInvites = data?.account?.invitations?.reduce(combineUsersWithInvites, normalizedUsers);

  const members = normalizedInvites
    ?.filter(x => !['REMOVED', 'CANCELED'].includes(x?.status))
    ?.sort((a, b) => (a?.role === OWNER ? -1 : 1))
    ?.sort((a, b) => (['PENDING', 'DECLINED'].includes(a?.status) ? 1 : -1));

  return {
    members,
    membersCount: data?.account?.users?.length || 0,
    pendingInvitesCount: data?.account?.invitations?.filter(x => x.status === 'PENDING')?.length || 0,
    declinedInvitesCount: data?.account?.invitations?.filter(x => x.status === 'DECLINED')?.length || 0,
    loading,
    error
  };
}
