import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { GET_ACCOUNT_WEBHOOKS } from './useAccountWebhooks';

export const ADD_ACCOUNT_WEBHOOK = gql`
  mutation addAccountWebhook($accountId: ID!, $url: String!, $authToken: String!, $events: [AccountWebhookEvent]) {
    addAccountWebhook(url: $url, authToken: $authToken, events: $events, accountId: $accountId) {
      id
      url
      authToken
      events
    }
  }
`;

function useAddAccountWebhook({ onCompleted = () => {}, onError = () => {}, accountId }) {
  const [add, { loading, error }] = useMutation(ADD_ACCOUNT_WEBHOOK, {
    onCompleted,
    onError,
    refetchQueries: [{ query: GET_ACCOUNT_WEBHOOKS, variables: { accountId } }]
  });

  return {
    loading,
    error,
    addAccountWebhook: ({ url, events, authToken }) =>
      add({ variables: { accountId, url, authToken, events: events.map(({ value }) => value) } })
  };
}

export default useAddAccountWebhook;
