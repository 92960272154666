import React, { useCallback, useEffect, useState } from 'react';
import { gql, useSubscription } from '@apollo/client';
import { BulkDownloadDocumentsJobContext } from './context';
import { useResettableMutation } from '../../hooks/useResettableMutation';
import { useHistory } from 'react-router-dom';

const START_JOB = gql`
  mutation startBulkDownloadDocumentsJob($activeOn: Date!, $clientId: ID, $documentRelationshipIds: [ID]!) {
    bulkDownloadDocuments(activeOn: $activeOn, clientId: $clientId, documentRelationshipIds: $documentRelationshipIds)
  }
`;

const ON_BULK_DOWNLOAD_DOCUMENTS_JOB_PROGRESS = gql`
  subscription jobProgress($jobId: ID!) {
    job: bulkDownloadDocumentsJobProgress(jobId: $jobId) {
      jobId
      message
      finished
      filename
      url
      errors
    }
  }
`;

export default function BulkDownloadDocumentsJobProvider({ children }) {
  const history = useHistory();
  const [jobId, setJobId] = useState(null);
  const [jobErrors, setJobErrors] = useState([]);
  const [finished, setJobFinished] = useState(true);
  const [percentComplete, setPercentComplete] = useState(0);
  const [message, setMessage] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [filename, setFilename] = useState(null);
  const [url, setUrl] = useState(null);

  const clearJobId = useCallback(() => {
    setJobId(null);
    setJobErrors([]);
  }, []);

  useEffect(() => {
    if (!!jobId && !!finished) {
      setTimeout(() => clearJobId(), 10000);
    }
  }, [finished, clearJobId, jobId]);

  const [startBulkDownloadJob, { loading, error, reset }] = useResettableMutation(START_JOB, {
    onCompleted: data => {
      setJobFinished(false);
      setJobId(data?.bulkDownloadDocuments);
      history.push(redirectUrl);
    },
    onError: () => setTimeout(() => reset(), 10000)
  });

  const startBulkDownloadDocumentsJob = ({ activeOn, clientId, documentRelationshipIds, redirectUrl }) => {
    setJobFinished(false);
    setRedirectUrl(redirectUrl);
    setClientId(clientId);
    startBulkDownloadJob({
      variables: { activeOn, clientId, documentRelationshipIds }
    });
  };

  useSubscription(ON_BULK_DOWNLOAD_DOCUMENTS_JOB_PROGRESS, {
    skip: !jobId,
    variables: { jobId },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      const job = data?.job;
      const { message, finished, filename, url, progress, errors } = job;

      errors && setJobErrors(errors);
      setMessage(message);
      setJobFinished(finished);
      setPercentComplete(progress);
      setFilename(filename);
      setUrl(url);
    }
  });

  return (
    <BulkDownloadDocumentsJobContext.Provider
      value={{
        startBulkDownloadDocumentsJob,
        jobId,
        clientId,
        clearJobId,
        message,
        finished,
        filename,
        url,
        progress: percentComplete,
        jobErrors,
        loading,
        error
      }}
    >
      {children}
    </BulkDownloadDocumentsJobContext.Provider>
  );
}
