import { useCallback, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { getProp } from '../utils';

export default function usePaginatedLazyQuery(query, { variables, pageSize = 20, pathToPageInfo, ...rest }) {
  const [pageInfo, setPageInfo] = useState({});

  const [paginationProps, setPaginationProps] = useState({
    first: pageSize,
    after: pageInfo?.startCursor,
    last: null,
    before: null,
    ...variables
  });

  const [lazyQuery, { data, loading, error, refetch }] = useLazyQuery(query, {
    variables: { ...paginationProps },
    ...rest
  });

  const loadFirstPage = useCallback(
    (variables = {}) => {
      setPaginationProps(() => {
        const props = { ...variables, first: pageSize, last: null, after: null, before: null };
        lazyQuery(props);
        return props;
      });
    },
    [lazyQuery, pageSize]
  );

  const loadNextPage = useCallback(
    (variables = {}) => {
      if (pageInfo?.hasNextPage) {
        setPaginationProps(state => {
          const props = {
            ...state,
            ...variables,
            first: pageSize,
            after: pageInfo?.endCursor,
            last: null,
            before: null
          };
          lazyQuery({ variables: props });
          return { ...state, ...props };
        });
      }
    },
    [lazyQuery, pageInfo?.endCursor, pageInfo?.hasNextPage, pageSize]
  );

  const loadPreviousPage = useCallback(
    (variables = {}) => {
      if (pageInfo?.hasPreviousPage) {
        setPaginationProps(state => {
          const props = {
            ...state,
            ...variables,
            first: null,
            last: pageSize,
            before: pageInfo?.startCursor,
            after: null
          };
          lazyQuery({ variables: props });
          return { ...state, ...props };
        });
      }
    },
    [lazyQuery, pageInfo?.hasPreviousPage, pageInfo?.startCursor, pageSize]
  );

  useEffect(() => {
    if (data) {
      setPageInfo(getProp(data, `${pathToPageInfo}.pageInfo`));
    }
  }, [data, pathToPageInfo]);

  return [
    lazyQuery,
    {
      data,
      loading,
      error,
      refetch,
      queryProps: paginationProps,
      loadFirstPage,
      paginationProps: {
        pageInfo,
        loadNextPage: () => loadNextPage(variables),
        loadPreviousPage: () => loadPreviousPage(variables)
      }
    }
  ];
}
