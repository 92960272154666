import React from 'react';
import { Button, useToggle } from '@oforce/global-components';
import { gql, useQuery } from '@apollo/client';

import { BoxContainer } from '../../components';
import { TabPage } from '../../components/tabs';

import { CreateUpdate, RetailCommissionsTable } from './containers';
import { HideFromRoles, ShowForTypes, useActiveAccount } from '../../shared/activeAccount';

const GET_POLICY_RETAIL_COMMISSIONS = gql`
  query GetPolicyRetailCommissions($id: ID!) {
    policy(id: $id) {
      id
      retailCommissions {
        id
        name
        rate
        rateMode
        appliesToAllPricing
        pricingOptionIds
      }
    }
  }
`;

export default function PolicyRetailCommissions({ policy }) {
  const {
    TYPES: { CARRIER, BROKER },
    ROLES: { EXPOSURE_MANAGER }
  } = useActiveAccount();
  const [showCreate, toggleCreate] = useToggle();
  const policyId = policy?.id;

  const { data, loading, error } = useQuery(GET_POLICY_RETAIL_COMMISSIONS, {
    variables: { id: policyId }
  });

  return (
    <TabPage
      title="Retail Commissions"
      showBackButton
      buttons={
        <ShowForTypes types={[CARRIER, BROKER]}>
          <HideFromRoles roles={[EXPOSURE_MANAGER]}>
            <Button
              color="info"
              icon={['far', 'plus']}
              className="ml-2"
              onClick={toggleCreate}
              disabled={policy?.pricingOptions?.length === 0}
            >
              Create
            </Button>
          </HideFromRoles>
        </ShowForTypes>
      }
    >
      <CreateUpdate isOpen={showCreate} toggle={toggleCreate} policyId={policyId} />

      <BoxContainer className="p-3">
        <RetailCommissionsTable
          retailCommissions={data?.policy?.retailCommissions || []}
          loading={loading}
          error={error}
          policyId={policyId}
        />
      </BoxContainer>
    </TabPage>
  );
}
