import { gql, useSubscription } from '@apollo/client';
import { useActiveAccount } from '../../../shared/activeAccount';

const INVOICE_REGENERATED_SUBSCRIPTION = gql`
  subscription invoicenotificationd($accountId: ID!) {
    notification: invoiceNotifications(accountId: $accountId) {
      invoiceId
      status
      voidedInvoiceId
    }
  }
`;

export default function useRegeneratedInvoiceSubscription(
  { id: invoiceIdToRegenerate },
  { onInvoiceRegenerating, onInvoiceVoided, onInvoiceProcessing, onInvoiceCommitted, onInvoiceRegenerated }
) {
  const { id: accountId } = useActiveAccount();

  const callbacks = {
    REGENERATING: onInvoiceRegenerating,
    VOIDED: onInvoiceVoided,
    PROCESSING: onInvoiceProcessing,
    COMMITTED: onInvoiceCommitted,
    REGENERATED: onInvoiceRegenerated
  };

  function triggerEvent(status, notification) {
    const callback = callbacks[status];
    if (callback && typeof callback === 'function') {
      callback(notification);
    }
  }

  useSubscription(INVOICE_REGENERATED_SUBSCRIPTION, {
    variables: { accountId },
    skip: !invoiceIdToRegenerate,
    onSubscriptionData: ({
      subscriptionData: {
        data: { notification }
      }
    }) => {
      const { invoiceId, status, voidedInvoiceId } = notification;
      if (voidedInvoiceId === invoiceIdToRegenerate) {
        triggerEvent(status, {newInvoiceId: invoiceId, originalInvoiceId: voidedInvoiceId});
      }
    }
  });
}
