export const EXPOSURE_ELECTION_STATUSES = {
  ACTIVE: 'ACTIVE',
  PENDING_TERMINATION: 'PENDING_TERMINATION',
  TERMINATED: 'TERMINATED',
  REJECTED: 'REJECTED',
  NEEDS_APPROVAL: 'NEEDS_APPROVAL'
};

export const EXPOSURE_STATUSES = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  PENDING: 'PENDING'
};
