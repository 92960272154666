import React from 'react';
import { Form } from 'formik';
import { InputCurrencyField, InputPercentageField, ReactSelectField } from '@oforce/global-components';

import { useStateFeeRateModes } from '../../../components/sharedComponents/pricing';
import { useVehicleTypeOptions } from '../../../views/configurationVehicleTypes/hooks';
import { InputStateField } from '../../../components/forms';

export default function StateFeeForm({ loading, formik }) {
  const { stateFeeRateModeOptions } = useStateFeeRateModes();
  const { vehicleTypeOptions } = useVehicleTypeOptions();

  return (
    <Form>
      <fieldset disabled={loading}>
        <InputStateField className="mb-3" id="state" name="state" required={true} />
        <ReactSelectField
          className="mb-3"
          id="activeMode"
          name="activeMode"
          label="Active Mode"
          options={stateFeeRateModeOptions}
          required
          placeholder="Select active mode..."
        />
        {formik?.values?.activeMode?.value === 'PERCENTAGE' ? (
          <InputPercentageField name="fee" label="Fee" required options={{ decimalLimit: 4 }} />
        ) : (
          <InputCurrencyField options={{ integerLimit: 5 }} name="fee" label="Fee" required />
        )}
        <ReactSelectField
          className="mb-3"
          id="vehicleTypes"
          name="vehicleTypes"
          label="Vehicle Type(s)"
          isMulti
          options={vehicleTypeOptions}
          required
          placeholder="Select vehicle type(s)..."
        />
      </fieldset>
    </Form>
  );
}
