import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useImportExposuresJobContext } from '../../../../../context';
import {
  prepareInsuredDetails_NoStep,
  prepareVehicleDetails_NoStep,
  prepareElectionDetails_NoStep
} from '../../../../coverages/components/steps/hooks/submitPrepare';

export default function useImportExposures({ policy }) {
  const history = useHistory();
  const insuranceTypeRequiresVehicle = policy?.insuranceType?.requireVehicle;
  const policyId = policy?.id;
  const { jobId, loading, error, finished, jobErrors = [], startImportExposureJob } = useImportExposuresJobContext();

  useEffect(() => {
    if (!!jobId && !error && finished && jobErrors.length === 0) {
      history.push(`/policies/${policyId}/insureds`);
    }
  }, [finished, error, jobErrors, history, jobId, policyId]);

  const onSubmit = rows => {
    const exposureElectionRows = rows.map(row => prepareRowForSubmit(row, policy));
    startImportExposureJob(exposureElectionRows);
  };

  const prepareRowForSubmit = row => {
    const values = Object.fromEntries(Object.entries(row).filter(([_, value]) => ![null, ''].includes(value)));

    const insuredDetails = prepareInsuredDetails_NoStep(values);
    const vehicleDetails = prepareVehicleDetails_NoStep(values);
    const election = prepareElectionDetails_NoStep({ ...values, policyId: policy?.id });
    const vehicleInfo = { ...vehicleDetails };
    const insuredInfo = { ...insuredDetails };

    return {
      insuredInfo,
      ...(insuranceTypeRequiresVehicle && { vehicleInfo }),
      elections: [election]
    };
  };

  return {
    jobId,
    loading,
    error,
    onSubmit
  };
}
