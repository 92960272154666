export const BOUND = 'BOUND';
export const ARCHIVED = 'ARCHIVED';
export const QUOTED = 'QUOTED';

export default function usePolicyStatusOptions() {
  const options = [
    {
      label: 'Quoted',
      value: QUOTED
    },
    {
      label: 'Bound',
      value: BOUND
    }
  ];

  return options;
}
