import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { useCountExposureElections } from '../../coverages/hooks';
import React from 'react';
import styled from 'styled-components';
import { Card } from '../../../components';
import { useCountInsureds } from '../hooks';

const CardWrapper = styled.div`
  box-shadow: ${props => (props.isActive ? `0px 3px 0px 0px ${props.theme.blue}` : '')};
  border-radius: 10px;
`;

export default function DashboardFilters() {
  const { activeInsureds, openViolations } = useCountExposureElections();
  const { insuredsCount } = useCountInsureds({ electionStatus: 'ACTIVE' });

  return (
    <Row className="mb-3">
      <Col>
        <Link to="/coverages/pending-approvals" style={{ textDecoration: 'none' }}>
          <Card
            title={'Pending Approval'}
            name="pending-approval"
            tooltip="Insurance selections that need to be reviewed"
            className="flex-grow-1 flex-shrink-1"
          >
            <h1 className="mb-0">{openViolations}</h1>
          </Card>
        </Link>
      </Col>
      <Col>
        <CardWrapper>
          <Card
            title={'Insured Elections'}
            name="insuredElections"
            tooltip="Number of active bodily coverages"
            className="flex-grow-1 flex-shrink-1"
          >
            <h1 className="mb-0">{activeInsureds}</h1>
          </Card>
        </CardWrapper>
      </Col>
      <Col>
        <CardWrapper>
          <Card
            title={'Insureds'}
            name="insureds"
            tooltip="Number of insureds with active elections"
            className="flex-grow-1 flex-shrink-1"
          >
            <h1 className="mb-0">{insuredsCount}</h1>
          </Card>
        </CardWrapper>
      </Col>
    </Row>
  );
}
