import { useEffect, useState, useMemo } from 'react';
import useCursorPagination from '../../../hooks/useCursorPagination';
import { debounce } from 'throttle-debounce';
import { gql } from '@apollo/client';
import { useLazyQuery } from '@apollo/client';

const TRANSACTIONS_TO_DISPLAY = 20;

export const GET_TRANSACTIONS = gql`
  query GetTransactions(
    $startDate: Date
    $endDate: Date
    $lastFour: String
    $name: String
    $after: String
    $before: String
    $first: Int
    $last: Int
    $invoiceDate: Date
    $driversLicenseNumber: String
    $invoiceId: Int
    $clientId: String
  ) {
    clients: allClients {
      id
      name
    }
    transactions(
      startDate: $startDate
      endDate: $endDate
      lastFour: $lastFour
      name: $name
      first: $first
      after: $after
      last: $last
      before: $before
      invoiceDate: $invoiceDate
      driversLicenseNumber: $driversLicenseNumber
      invoiceId: $invoiceId
      clientId: $clientId
    ) {
      pageInfo {
        hasNextPage
        endCursor
        startCursor
        hasPreviousPage
      }
      edges {
        node {
          invoice {
            id
            serviceStartDate
            serviceEndDate
            client {
              id
              name
            }
            invoiceDate
          }
          label
          insuredId
          firstName
          lastName
          productName
          premium
          factorValue
          factorLabel
          insured {
            driversLicenseNumber
          }
          coverageDates {
            startDate
            endDate
          }
          premiumSource {
            rate
            rateMode
            isPercentageFactor
          }
          fee
          dateOfBirth
          ssnLastFour
          status
          addOns
          addOnsSource {
            label
          }
        }
      }
    }
  }
`;

export default function useTransactions() {
  const [variables, setVariables] = useState({
    startDate: null,
    endDate: null,
    lastFour: null,
    name: null,
    invoiceDate: null,
    driversLicenseNumber: null,
    invoiceId: null,
    clientId: null,
    first: null,
    last: null,
    before: null,
    after: null
  });

  const [loadTransactions, { loading, data: apiData, error }] = useLazyQuery(GET_TRANSACTIONS, {
    fetchPolicy: 'network-only'
  });

  const setClientFilter = debounce(500, false, value => {
    if (!value) return setVariables({ ...variables, clientId: null });

    return setVariables({ ...variables, clientId: value });
  });

  const setInvoiceIdFilter = debounce(500, false, value => {
    if (!value) return setVariables({ ...variables, invoiceId: null });

    return setVariables({ ...variables, invoiceId: parseInt(value) });
  });

  const setNameFilter = debounce(500, false, value => {
    if (!value) return setVariables({ ...variables, name: null });

    return setVariables({ ...variables, name: value.trim() });
  });

  const setSSNFilter = debounce(500, false, value => {
    if (!value) return setVariables({ ...variables, lastFour: null });

    const ssnMatch = value?.match(/(\d){4}/g);

    if (ssnMatch) {
      const [lastFour] = ssnMatch;
      return setVariables({
        ...variables,
        lastFour
      });
    }

    return setVariables({ ...variables });
  });

  const setDriversLicenseNumberFilter = debounce(500, false, value => {
    if (!value) return setVariables({ ...variables, driversLicenseNumber: null });

    return setVariables({
      ...variables,
      driversLicenseNumber: value
    });
  });

  const setDateRange = range => {
    if (!range) return setVariables({ ...variables, startDate: null, endDate: null });

    const [start, end] = range;

    return setVariables({
      ...variables,
      startDate: start.utc().format('YYYY-MM-DD'),
      endDate: end.utc().format('YYYY-MM-DD')
    });
  };

  function setInvoiceDate(date) {
    let invoiceDate = date;

    if (date) {
      invoiceDate = date.utc().format('YYYY-MM-DD');
    }

    setVariables({ ...variables, invoiceDate });
  }

  useEffect(() => {
    loadTransactions({
      variables: { ...variables, first: TRANSACTIONS_TO_DISPLAY, after: null, before: null, last: null }
    });
  }, [variables, loadTransactions]);

  const clients = useMemo(() => {
    return (
      apiData?.clients?.map(client => ({ label: client?.name, value: client?.id })) || [
        {
          name: '',
          id: ''
        }
      ]
    );
  }, [apiData]);

  const transactions = useMemo(() => {
    return (
      apiData?.transactions?.edges?.map(x => x.node) || [
        {
          firstName: '',
          lastName: '',
          invoice: { id: '', client: { id: '', name: '' } }
        }
      ]
    );
  }, [apiData]);

  const { loadNextPage, loadPreviousPage } = useCursorPagination(loadTransactions, { count: TRANSACTIONS_TO_DISPLAY });

  const pageInfo = apiData?.transactions?.pageInfo || {};
  const { hasNextPage, hasPreviousPage } = pageInfo;

  return {
    clients,
    data: transactions,
    hasNextPage,
    hasPreviousPage,
    loadNextPage: () => loadNextPage(pageInfo, variables),
    loadPreviousPage: () => loadPreviousPage(pageInfo, variables),
    setClientFilter,
    setInvoiceIdFilter,
    setNameFilter,
    setSSNFilter,
    setDriversLicenseNumberFilter,
    setDateRange,
    setInvoiceDate,
    loading,
    error
  };
}
