import React from 'react';
import { Formik } from 'formik';
import { Modal } from '@oforce/global-components';

import { GraphQLErrors } from '../../../components';

import { StateFeeForm } from '.';
import { useUpdateStateFeeForm } from '../hooks';

export default function UpdateStateFee({ showUpdateStateFee, toggleUpdateStateFee, stateFee }) {
  const { validationSchema, initialValues, handleSubmit, loading, error, reset } = useUpdateStateFeeForm({
    stateFee,
    onUpdated: toggleUpdateStateFee
  });

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {formik => (
        <Modal
          id="updateStateFeeModal"
          isOpen={showUpdateStateFee}
          toggle={toggleUpdateStateFee}
          onOpened={() => {
            reset();
            formik.handleReset();
          }}
          title={`Update State Fee`}
          loading={loading}
          submitButtonText="Save"
          submitButtonProps={{ loadingText: 'Saving...' }}
          onSubmit={formik.handleSubmit}
          submitButtonDisabled={formik.isSubmitting}
        >
          <GraphQLErrors error={error} />
          <StateFeeForm formik={formik} loading={loading} />
        </Modal>
      )}
    </Formik>
  );
}
