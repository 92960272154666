import React from 'react';
import { Formik } from 'formik';
import { gql, useMutation } from '@apollo/client';
import { Modal } from '@oforce/global-components';

import { GraphQLErrors } from '../../../components';
import { convertPercentageToDecimal, convertToFloat } from '../../../utils';
import { useFeeRateModes } from '../../../components/sharedComponents/pricing';

import { GET_POLICY_PRICINGS } from '../../policyPricing/hooks/usePolicyPricings';

import { FeeForm } from '.';
import { GET_POLICY_FEES } from './FeesTable';
import { useFeeValidationSchema, useFeeAppliesToOptions } from '../hooks';

import { GET_ACCOUNT_TAGS } from '../../account/hooks/useAccountTags';

const CREATE_POLICY_FEE = gql`
  mutation CreatePolicyFee($input: CreatePolicyFeeInput!) {
    createPolicyFee(input: $input) {
      id
      name
      rateMode
      rateModeType
      rate
      tags
      appliesToAllPricingOptions
      policyPricingIds
      multiplierFeeIds
      prorated
    }
  }
`;

export default function FeeCreate({ isOpen, toggle, policy, onError = () => {} }) {
  const [createFee, { loading, error, reset }] = useMutation(CREATE_POLICY_FEE, {
    onCompleted: toggle,
    onError,
    refetchQueries: [
      { query: GET_ACCOUNT_TAGS, variables: { accountId: policy?.product?.accountId } },
      { query: GET_POLICY_PRICINGS, variables: { id: policy?.id } },
      { query: GET_POLICY_FEES, variables: { id: policy?.id } }
    ]
  });

  const validationSchema = useFeeValidationSchema();

  const { feeRateModeOptions: rateModeOptions } = useFeeRateModes();
  const { feeAppliesToOptions } = useFeeAppliesToOptions();

  const initialValues = {
    name: '',
    rateMode: rateModeOptions?.find(({ value }) => value === 'FLAT') || null,
    rate: '',
    tags: [],
    prorated: policy?.prorateSetting !== 'NO_PRORATE' ? true : false,
    appliesToAllPricingOptions: feeAppliesToOptions?.find(x => x.value === true),
    policyPricings: [],
    multipliers: []
  };

  const handleSubmit = ({
    name,
    rateMode,
    rate,
    appliesToAllPricingOptions,
    policyPricings,
    multipliers,
    tags,
    prorated
  }) => {
    const rateModeValue = rateMode.value === 'PREMIUM_PLUS' ? 'PERCENTAGE' : rateMode.value;
    const rateModeType = rateMode.value === 'PREMIUM_PLUS' ? 'PREMIUM_PLUS' : 'PREMIUM';

    createFee({
      variables: {
        input: {
          policyId: policy?.id,
          name,
          rateModeType,
          rateMode: rateModeValue,
          rate: rateModeValue === 'PERCENTAGE' ? convertPercentageToDecimal(rate) : convertToFloat(rate),
          appliesToAllPricingOptions: appliesToAllPricingOptions.value,
          policyPricingIds: policyPricings?.map(pricing => pricing?.value),
          multiplierFeeIds: multipliers?.map(fee => fee?.value),
          tags: tags?.map(tag => tag?.value),
          prorated: prorated
        }
      }
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {formik => (
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          title="Create Fee"
          onSubmit={formik.handleSubmit}
          submitButtonDisabled={formik.isSubmitting}
          submitButtonProps={{ color: 'info', loadingText: 'Creating...' }}
          submitButtonText="Create Fee"
          loading={loading}
          onOpened={() => {
            reset();
            formik.handleReset();
          }}
        >
          <GraphQLErrors error={error} />
          <FeeForm policy={policy} formik={formik} />
        </Modal>
      )}
    </Formik>
  );
}
