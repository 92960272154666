import React from 'react';
import { CustomDropdownItem } from '@oforce/global-components';
import { Dropdown, DropdownMenu } from 'reactstrap';
import { EllipsisDropdownToggle } from '../dropdowns';
import { useToggle } from '../../hooks';
import { HideFromTypes, HideFromRoles, useActiveAccount, ShowForRoles } from '../../shared/activeAccount';
import { useCurrentUser } from '../../shared/currentUser';
import Render from '../Render';

export default function ActionDropdown({
  onEdit,
  onClick,
  editText = 'Edit',
  onDownload,
  downloading,
  onRemove,
  onDelete,
  onCancel,
  onDecline,
  onAccept,
  onResend,
  resendText = 'Resend',
  resending,
  onArchive,
  className,
  direction = 'left',
  onHardDelete,
  children
}) {
  const {
    TYPES: { CLIENT },
    ROLES: { ADMIN, EXPOSURE_MANAGER }
  } = useActiveAccount();
  const { isAdmin } = useCurrentUser();
  const [dropdownOpen, toggleDropdown] = useToggle();

  const toggle = () => {
    toggleDropdown();
    onClick && onClick();
  };

  return (
    <Dropdown className={className} isOpen={dropdownOpen} toggle={toggle} direction={direction}>
      <EllipsisDropdownToggle />
      <DropdownMenu className="border-width-2 border-gray-300 shadow-sm">
        {onEdit && (
          <HideFromTypes types={[CLIENT]}>
            <HideFromRoles roles={[EXPOSURE_MANAGER]}>
              <CustomDropdownItem color="info" icon={['far', 'pen']} onClick={onEdit}>
                {editText}
              </CustomDropdownItem>
            </HideFromRoles>
          </HideFromTypes>
        )}
        {onDownload && (
          <CustomDropdownItem
            color="info"
            icon={['far', 'arrow-to-bottom']}
            onClick={onDownload}
            loading={downloading}
            loadingText="Downloading..."
          >
            Download
          </CustomDropdownItem>
        )}
        {onRemove && (
          <HideFromTypes types={[CLIENT]}>
            <HideFromRoles roles={[EXPOSURE_MANAGER]}>
              <CustomDropdownItem color="danger" icon={['far', 'trash-alt']} onClick={onRemove}>
                Remove
              </CustomDropdownItem>
            </HideFromRoles>
          </HideFromTypes>
        )}
        {onHardDelete && (
          <ShowForRoles roles={[ADMIN]}>
            <CustomDropdownItem color="danger" icon={['far', 'trash-alt']} onClick={onHardDelete}>
              Permanently Delete
            </CustomDropdownItem>
          </ShowForRoles>
        )}
        {onDelete && (
          <Render if={isAdmin}>
            <CustomDropdownItem color="danger" icon={['far', 'trash-alt']} onClick={onDelete}>
              Delete
            </CustomDropdownItem>
          </Render>
        )}
        {onDecline && (
          <CustomDropdownItem color="danger" icon={['far', 'times']} onClick={onDecline}>
            Decline
          </CustomDropdownItem>
        )}
        {onCancel && (
          <CustomDropdownItem color="danger" icon={['far', 'times']} onClick={onCancel}>
            Cancel
          </CustomDropdownItem>
        )}
        {onAccept && (
          <CustomDropdownItem color="success" icon={['far', 'check']} onClick={onAccept}>
            Accept
          </CustomDropdownItem>
        )}
        {onArchive && (
          <HideFromTypes types={[CLIENT]}>
            <HideFromRoles roles={[EXPOSURE_MANAGER]}>
              <CustomDropdownItem color="info" icon={['fas', 'archive']} onClick={onArchive}>
                Archive
              </CustomDropdownItem>
            </HideFromRoles>
          </HideFromTypes>
        )}
        {onResend && (
          <CustomDropdownItem
            color="info"
            icon={['far', 'sync-alt']}
            loading={resending}
            loadingText="Resending..."
            onClick={() => {
              onResend();
              toggleDropdown();
            }}
          >
            {resendText}
          </CustomDropdownItem>
        )}
        {children}
      </DropdownMenu>
    </Dropdown>
  );
}
