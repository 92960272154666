import React from 'react';
import { ReactSelectField } from '@oforce/global-components';

import { FormSection } from '../../../components';

export default function Policies({ formOptions }) {
  return (
    <FormSection title="Applied to Policies">
      <ReactSelectField
        required
        label="Selected Policies"
        name="policies"
        placeholder="Select..."
        isMulti
        options={formOptions?.policies?.options}
        isLoading={formOptions?.policies?.loading}
        clearable
      />
    </FormSection>
  );
}
