import React from 'react';
import SwitchInput from '../SwitchInput';
import JsonInput from '../JsonInput';
import schema from './schema.json';
import { Input, Col, Row } from 'reactstrap';
import { Button, Label } from '@oforce/global-components';

export default function CustomDataForm({
  viewJson,
  changeView,
  editJsonData,
  customData,
  jsonCustomData,
  editKey,
  editValue,
  addNew,
  remove
}) {
  return (
    <>
      <SwitchInput
        onChange={changeView}
        isActive={viewJson}
        activeLabel="View Json"
        inactiveLabel="View Json"
        name="viewJsonSwitch"
        className="justify-content-end"
      />
      {viewJson ? (
        <JsonInput name={'customData'} value={jsonCustomData} onChange={editJsonData} schema={schema} />
      ) : (
        customData?.map(({ id, key, value }) => {
          return (
            <div key={id}>
              <Row className="d-flex align-items-center justify-content-between mb-3">
                <Col>
                  <Label>Key</Label>
                  <Input value={key} onChange={e => editKey(id, e.target.value)} />
                </Col>
                <Col>
                  <Label>Value</Label>
                  <Input value={value} onChange={e => editValue(id, e.target.value)} />
                </Col>
                <Button
                  color="link"
                  size="lg"
                  className="text-danger mt-3 mr-2"
                  icon={['far', 'trash-alt']}
                  onClick={() => remove(id)}
                />
              </Row>
            </div>
          );
        })
      )}
      {!viewJson && (
        <Button color="secondary" outline className="fs-sm w-100 mt-3" icon={['far', 'plus']} onClick={addNew}>
          Add Key-Value pair
        </Button>
      )}
    </>
  );
}
