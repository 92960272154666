import React, { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { Button } from '@oforce/global-components';

import { useDownloadFile } from '../../hooks';
import { useGenerateCombinedInvoicesXslJobContext } from '../../context';
import { ToastContent } from '../../components/react-toastify';

export default function GenerateCombinedInvoicesXslJob() {
  const { jobId, message, finished, filename, url, error, jobErrors, clearJobId } =
    useGenerateCombinedInvoicesXslJobContext();
  const toastId = useRef(null);

  useEffect(() => {
    if (!toast.current && jobId) {
      toastId.current = toast(<GeneratingCombinedInvoicesXsl />, { autoClose: false, closeOnClick: false });
    }
  }, [toastId, jobId, clearJobId]);

  useEffect(() => {
    if (!toastId.current) {
      return;
    }

    if (error || jobErrors.length !== 0) {
      toast.update(toastId.current, {
        render: () => <ErrorGeneratingCombinedInvoicesXsl error={jobErrors || error} />,
        onClose: clearJobId
      });
    } else if (!finished && !url) {
      toast.update(toastId.current, {
        render: () => <GeneratingCombinedInvoicesXsl message={message} />
      });
    } else if (finished) {
      toast.update(toastId.current, {
        render: () => (
          <DownloadCombinedInvoicesXsl
            message={message}
            url={url}
            filename={filename}
            errors={jobErrors}
            clearJobId={clearJobId}
          />
        ),
        onClose: clearJobId
      });
    }
    // eslint-disable-next-line
  }, [toastId, message, filename, url, finished, error, jobErrors]);

  return <></>;
}

const GeneratingCombinedInvoicesXsl = ({ message }) => {
  let title = message ?? 'Preparing Combined Invoices Report';

  return (
    <ToastContent
      title={title}
      icon={['far', 'spinner-third']}
      iconProps={{ spin: true }}
      message="Please allow us some time to generate the report."
    />
  );
};

const ErrorGeneratingCombinedInvoicesXsl = ({ error }) => (
  <ToastContent
    title="Error Preparing Combined Invoices Report"
    titleProps={{ className: 'text-danger' }}
    icon={['fas', 'times-circle']}
    message={error.message || error}
  />
);

const DownloadCombinedInvoicesXsl = ({ message, url, filename, errors, clearJobId }) => {
  const { download, loading } = useDownloadFile({ url, filename });

  const downloadAndClearJobId = () => {
    download();
    clearJobId();
  };

  return (
    <ToastContent
      title={message || 'Combined Invoices Report Ready'}
      titleProps={{ className: 'text-success' }}
      icon={url ? ['fas', 'check-circle'] : ['far', 'exclamation-triangle']}
      message={url && 'Your combined invoices report is ready for download.'}
    >
      {errors && errors.length > 0 && errors.map(e => <div key={e}>{e}</div>)}
      <div>
        {url && (
          <Button
            color="link"
            size="sm"
            icon={['far', 'arrow-to-bottom']}
            className="mr-2"
            loading={loading}
            loadingText="Downloading..."
            onClick={downloadAndClearJobId}
          >
            Download Now
          </Button>
        )}
      </div>
    </ToastContent>
  );
};
