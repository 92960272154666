import { useMemo } from 'react';

import { useCurrentUser } from '../../../shared/currentUser';
import { useActiveAccount } from '../../../shared/activeAccount';

import { useLogout } from '.';
import { GLOBAL_NAVIGATION_TABS } from '../constants';

const {
  OFORCE_ADMIN_TABS,
  CARRIER_TABS,
  BROKER_TABS,
  CLIENT_TABS,
  API_EXPLORER_TAB,
  CLAIMS_ADJUSTER_TABS,
  filterByRole
} = GLOBAL_NAVIGATION_TABS;

const adminTabs = OFORCE_ADMIN_TABS;
const carrierTabs = [...CARRIER_TABS, ...BROKER_TABS, ...CLIENT_TABS];
const brokerTabs = [...BROKER_TABS, ...CLIENT_TABS];
const clientTabs = [...CLIENT_TABS];
const claimsAdjusterTabs = [...CLAIMS_ADJUSTER_TABS];

function createApiExplorerTabs(isAdmin, accountName) {
  if (/hudson/gi.test(accountName) || (accountName && isAdmin)) {
    return [API_EXPLORER_TAB];
  }
  return [];
}

function filterReportsTab(tabs, hasClientAccountReportsAccess, hasClientCumulioReportsAccess) {
  return tabs.filter(
    ({ name }) =>
      name !== 'Reports' ||
      (name === 'Reports' && hasClientAccountReportsAccess === true) ||
      (name === 'Reports' && hasClientCumulioReportsAccess === true)
  );
}

export default function useGlobalNav() {
  const { logout } = useLogout();
  const { hasClientAccountReports, hasClientCumulioReports, isAdmin } = useCurrentUser();
  const { type: activeAccountType, name: activeAccountName, role: activeAccountRole } = useActiveAccount();

  const tabsByAccountTypeAndRole = useMemo(() => {
    const tabsByAccount =
      {
        ADMIN: adminTabs,
        CARRIER: carrierTabs,
        BROKER: brokerTabs,
        BROKER_MANAGER: brokerTabs,
        CLIENT: filterReportsTab(clientTabs, hasClientAccountReports, hasClientCumulioReports),
        CLIENT_MANAGER: filterReportsTab(clientTabs, hasClientAccountReports, hasClientCumulioReports),
        CLAIMS_ADJUSTER: claimsAdjusterTabs
      }[activeAccountType] || [];

    return filterByRole(tabsByAccount, activeAccountRole);
  }, [activeAccountType, activeAccountRole, hasClientAccountReports, hasClientCumulioReports]);

  const menuItems = useMemo(() => {
    const coreTabs = isAdmin ? [...adminTabs, ...tabsByAccountTypeAndRole] : tabsByAccountTypeAndRole;
    return [...coreTabs, ...createApiExplorerTabs(isAdmin, activeAccountName)];
  }, [activeAccountName, isAdmin, tabsByAccountTypeAndRole]);

  const profileItems = [
    {
      name: 'My Profile',
      pathname: '/my-profile',
      icon: ['far', 'user'],
      useRouter: true
    }
  ];

  return {
    profileItems,
    menuItems,
    logout
  };
}
