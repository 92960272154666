import React, { useState } from 'react';
import { BoxContainer, FormSection } from '../../../components';
import { useListInsureds } from '../../insureds/hooks';
import { TablePagination } from '../../../components/tables';
import BulkDownloadDocumentsInsuredsTable from './BulkDownloadDocumentsInsuredsTable';
import BulkDownloadDocumentsInsuredsFilters from './BulkDownloadDocumentsInsuredsFilters';

export default function BulkDownloadDocumentsInsuredsSelection({ formik, clientId }) {
  const { allValidInsuredIds, insuredIdsFromChosenDocuments } = formik?.values;
  const validInsuredIds = insuredIdsFromChosenDocuments.filter(insuredId => allValidInsuredIds.includes(insuredId));

  const [insuredsFilter, setInsuredsFilter] = useState({
    clientId: clientId,
    ordering: 'LAST_NAME',
    ids: insuredIdsFromChosenDocuments
  });

  const { insureds, loading, error, paginationProps, loadFirstPage } = useListInsureds({
    filters: insuredsFilter
  });

  const onChange = filters => {
    setInsuredsFilter({ ...filters });
    loadFirstPage({ ...insuredsFilter, ...filters });
  };

  return (
    <FormSection title="Select Insureds">
      <BulkDownloadDocumentsInsuredsFilters
        defaults={insuredsFilter}
        onChange={onChange}
        filterValue={insuredsFilter}
      />
      <BoxContainer>
        <BulkDownloadDocumentsInsuredsTable
          formik={formik}
          insureds={insureds}
          allValidInsuredIds={validInsuredIds}
          loading={loading}
          error={error}
        />
      </BoxContainer>
      <TablePagination {...paginationProps} loading={loading} />
    </FormSection>
  );
}
