import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';

export const GET_ACCOUNT_WEBHOOKS = gql`
  query accountWebhooks($accountId: ID!) {
    account(id: $accountId) {
      id
      webhooks {
        id
        events
        authToken
        url
      }
    }
  }
`;

function useAccountWebhooks({ accountId }) {
  const { loading, error, data } = useQuery(GET_ACCOUNT_WEBHOOKS, {
    skip: !accountId,
    variables: { accountId }
  });

  return {
    loading,
    error,
    webhooks: data?.account?.webhooks || []
  };
}

export default useAccountWebhooks;
