import React from 'react';
import styled from 'styled-components';
import { DropdownToggle } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledDropdownToggle = styled(DropdownToggle).attrs({
  className: 'p-2 rounded-circle cursor-pointer border border-gray-300'
})`
  background: ${props => props.theme.white};
  color: ${props => props.theme.gray600};
  &:hover {
    color: ${props => props.theme.gray700};
    background: ${props => props.theme.gray200};
  }
  &:active {
    color: ${props => props.theme.gray800};
    background: ${props => props.theme.gray300};
  }
`;

export default function EllipsisDropdownToggle() {
  return (
    <StyledDropdownToggle onClick={e => e.stopPropagation()} tag="span" data-toggle="dropdown">
      <FontAwesomeIcon icon={['far', 'ellipsis-h']} />
    </StyledDropdownToggle>
  );
}
