import React from 'react';
import { Helmet } from 'react-helmet';
import { Button, useToggle } from '@oforce/global-components';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { TabPage } from '../../components';
import { Invoice, InvoicesGenerateReport } from '../../views';
import { PageHeader, PageTitle, PageBody } from '../../components/page-components';
import { CombinedInvoicesReport, InvoicesTable } from './containers';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

export default function Invoices() {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const [showGenerateReport, toggleGenerateReport] = useToggle();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Helmet>
          <title>Invoices • IC Insure</title>
        </Helmet>
        <PageHeader>
          <PageTitle>Invoices</PageTitle>
        </PageHeader>
        <PageBody>
          <TabPage
            title="Invoices"
            buttons={
              <>
                <Button
                  color="info"
                  icon={['far', 'file-chart-line']}
                  onClick={() => history.push(`${url}/combined-invoices-report`)}
                  className="ml-2"
                >
                  Combined Invoices Report
                </Button>
                <Button
                  color="info"
                  icon={['far', 'file-chart-line']}
                  onClick={toggleGenerateReport}
                  className="ml-2"
                >
                  Generate Report
                </Button>
              </>
            }
          >
            <InvoicesTable />
            {showGenerateReport && <InvoicesGenerateReport isOpen={showGenerateReport} toggle={toggleGenerateReport} />}
          </TabPage>
        </PageBody>
      </Route>
      <Route path={`${path}/combined-invoices-report`}>
        <Helmet>
          <title>Invoices • IC Insure</title>
        </Helmet>
        <PageHeader>
          <PageTitle>Invoices</PageTitle>
        </PageHeader>
        <PageBody>
          <CombinedInvoicesReport />
        </PageBody>
      </Route>
      <Route path={`${path}/:invoiceId`}>
        <Invoice />
      </Route>
    </Switch>
  );
}
