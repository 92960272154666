import React from 'react';
import { SwitchField, ReactSelectField} from '@oforce/global-components';

import { Render, FormSection } from '../../../components';

export default function ProductPricingFormFields({ formik, formOptions }) {
  const pricingRateIsWeekly = formik?.values?.pricingRateType?.value === 'WEEKLY';
  const pricingIsExternallyFactored = formik?.values?.pricingIsExternallyFactored;
  const enableProrating = formik?.values?.enableProrating;
  const prorateAddons = formik?.values?.prorateAddons;
  const prorateSettingOptions = pricingRateIsWeekly
    ? formOptions?.proratingWeekly?.options
    : formOptions?.proratingMonthly?.options;

  return (
    <>
    <FormSection title="Product Pricing">
      <ReactSelectField
        id="pricingRateType"
        name="pricingRateType"
        label="Pricing Rate"
        options={formOptions?.pricingRate?.options}
        isLoading={formOptions?.pricingRate?.loading}
        placeholder="Select pricing rate..."
        // when changing pricing rate type, set automateInvoiceGeneration to false by default
        // also, set enableProrating setting to false, and clear out prorateSetting value
        onChange={() => {
          formik?.setFieldValue('automateInvoiceGeneration', false);
          formik?.setFieldValue('enableProrating', false);
          formik?.setFieldValue('prorateSetting', null);
          formik?.setFieldValue('billingRule', null);
          formik?.setFieldValue('snapshotCustomDay', null);
        }}
        required
      />
      <SwitchField
        id="enable-prorating-switch"
        className="mb-3"
        label="Prorating"
        name="enableProrating"
        disabled={pricingIsExternallyFactored}
        size="sm"
        switchLabel={enableProrating ? 'Enabled' : 'Disabled'}
        messageAfter={pricingIsExternallyFactored && 'Not available with api-only factored pricing option.'}
        messageAfterProps={{ icon: ['far', 'info-circle'] }}
      />

      <Render if={enableProrating}>
        <ReactSelectField
          id="prorateSetting"
          name="prorateSetting"
          label="Prorate Setting"
          options={prorateSettingOptions}
          placeholder="Select prorate setting..."
          required
        />
        <SwitchField
          id="enable-addon-prorating-switch"
          className="mb-3"
          label="Prorate Addons"
          name="prorateAddons"
          size="sm"
          switchLabel={prorateAddons ? 'Enabled' : 'Disabled'}
        />
      </Render>
    </FormSection>
      </>
  );
}
