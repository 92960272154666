import React, { useMemo } from 'react';
import { SearchBy } from '../../../components';
import { useCoverageSearchByOptions } from '../hooks';

export default function CoveragesSearchBy({ policyId, onChange }) {
  const { coverageSearchByOptions, loading } = useCoverageSearchByOptions({ policyId });
  const defaultValue = useMemo(
    () => coverageSearchByOptions.find(option => option.value === 'LAST_NAME'),
    [coverageSearchByOptions]
  );

  const onFilterChange = incomingFilters => {
    const searchBy = incomingFilters.reduce((acc, { field: { value: searchField }, value: searchValue }) => {
      if (!!searchValue) return [...acc, { searchField, searchValue }];
      return acc;
    }, []);

    if (searchBy.length > 0) {
      onChange({ searchBy });
    } else {
      onChange({ searchBy: [] });
    }
  };

  if (loading) return null;
  return (
    <SearchBy
      label={'Search by'}
      onFilterChange={onFilterChange}
      name="coverages-search-by"
      fieldOptions={coverageSearchByOptions}
      defaultValue={defaultValue}
    />
  );
}
