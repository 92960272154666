import React, { useState } from 'react';
import { FiltersContainer, FilterLabel, FilterDropdown } from '../../../components';
import { BulkEmailInsuredsSearchBy } from '.';

export default function BulkEmailInsuredsFilters({ defaults, onChange, filterValue }) {
  const [filters, setFilters] = useState(defaults);

  const orderOptions = [
    { label: 'First Name', value: 'FIRST_NAME' },
    { label: 'Last Name', value: 'LAST_NAME' }
  ];

  const updateFilter = values => {
    const newFilters = { ...filters, ...values };
    setFilters(newFilters);
    onChange(newFilters);
  };

  return (
    <div>
      <FiltersContainer className="pb-0 mb-2 pt-3">
        <FilterLabel>Sort by</FilterLabel>
        <FilterDropdown
          id="ordering"
          className="mr-3"
          headerText="Sort ascending..."
          icon={['far', 'sort-alpha-down']}
          options={orderOptions}
          defaultOption={orderOptions.find(({ value }) => value === filterValue?.ordering)}
          setFilter={option => updateFilter({ ordering: option.value })}
        />
        <BulkEmailInsuredsSearchBy onChange={updateFilter} />
      </FiltersContainer>
    </div>
  );
}
