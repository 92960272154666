import React from 'react';
import { LabelValueGroup, CreatedUpdatedDates } from '../../../components';
import { castCountry } from '../../../hooks/forms/useCountries';

export default function ClientDetailsView({ client }) {
  return (
    <>
      <LabelValueGroup label="Name" value={client?.name} />
      <LabelValueGroup label="External ID" value={client?.externalId} />
      <LabelValueGroup label="Email" value={client?.email} />
      <LabelValueGroup label="Address Line 1" value={client?.address1} />
      <LabelValueGroup label="Address Line 2" value={client?.address2} />
      <LabelValueGroup label="City" value={client?.city} />
      <LabelValueGroup label="State" value={client?.state} />
      <LabelValueGroup label="Zip" value={client?.zip} />
      <LabelValueGroup label="Country" value={castCountry(client?.country)} />
      <hr />
      <CreatedUpdatedDates created={client?.created} updated={client?.updated} />
    </>
  );
}
