import React from 'react';
import moment from 'moment';
import { FormSection } from '../../../components';
import { DatePickerField, FieldMessage } from '@oforce/global-components';

export default function BulkEmailDateSelection({ formik }) {
  return (
    <FormSection title="Generate Documents for this Date">
      <DatePickerField
        fullWidth
        name="activeOn"
        label="Active On"
        format="MM/DD/YYYY"
        onChange={e => {
          !!e
            ? formik?.setFieldValue('activeOn', moment(e).format(moment.HTML5_FMT.DATE))
            : formik?.setFieldValue('activeOn', '');
          formik?.setFieldValue('documentRelationships', []);
        }}
      />
      <FieldMessage className="w-100" icon={['far', 'info-circle']}>
        If no date is chosen, the most recent active version of the document will be sent
      </FieldMessage>
    </FormSection>
  );
}
