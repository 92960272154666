import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { Loader } from '@oforce/global-components';

import { GraphQLErrors } from '../../../components';
import { LargeIcon, Message, MessageBox, Title } from '../../../components/message-box';

const OPEN_INVITATION = gql`
  mutation invitation($id: ID!) {
    invitation: openAccountInvitation(invitationId: $id) {
      id
      role
      account {
        name
      }
    }
  }
`;

export default function AccountInvitation({ id }) {
  const history = useHistory();
  const [open, { data, loading, error }] = useMutation(OPEN_INVITATION, { onError: () => {}, variables: { id } });

  useEffect(() => {
    if (id && !loading && !error && !data) {
      open();
    }
  }, [data, error, id, loading, open]);

  useEffect(() => {
    if (!loading && !error && data?.invitation) history.replace(`/my-profile/invitations`);
  }, [data, history, error, loading, id]);

  if (loading) return <Loader />;

  return (
    <div className="h-100 w-100 d-flex align-self-center justify-content-center">
      <MessageBox className="align-self-center justify-self-center">
        <LargeIcon icon="exclamation-triangle" color="warning" />
        <Title>Oops!</Title>
        <Message className="mb-3">Oops, it looks like there is a problem with this invitation.</Message>
        <GraphQLErrors error={error} />
      </MessageBox>
    </div>
  );
}
