import { gql } from '@apollo/client';

export const EXPOSURE_RELATED_DOCUMENTS = gql`
  fragment exposureRelatedDocuments on ExposureRelatedDocuments {
    exposureDocuments {
      id
      document {
        id
        url
        name
        filename
      }
      client {
        id
        name
      }
      vehicle {
        id
        vin
        unitNumber
      }
      insured {
        id
        email
      }
    }
    documentRelationships {
      id
      source
      document {
        id
        url
        name
        filename
      }
      client {
        id
        name
      }
      vehicle {
        id
        vin
        unitNumber
      }
      insured {
        id
        email
      }
    }
  }
`;

export const GET_ELECTION_DOCUMENTS = gql`
  query ElectionDocuments($id: ID!) {
    election: getExposureElection(id: $id) {
      id
      compliance
      documents {
        ...exposureRelatedDocuments
      }
    }
  }
  ${EXPOSURE_RELATED_DOCUMENTS}
`;
