import React from 'react';
import styled from 'styled-components';

export const StyledFormFeedback = styled.div`
  color: ${props => props.theme.red};
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
`;

export default function FormFeedback({ children, invalid }) {
  return <StyledFormFeedback invalid={invalid}>{children}</StyledFormFeedback>;
}
