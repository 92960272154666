import React from 'react';
import { Row, Col } from 'reactstrap';
import { CheckboxField, InputField, ReactSelectField } from '@oforce/global-components';
import { FormSection } from '../../../components';

export default function VehicleFields({
  disableVinUpdate,
  focusRef,
  formOptions,
  requiredFields = {},
  disabledVehicleFields = {}
}) {
  return (
    <>
      <FormSection title="Vehicle Info">
        <InputField name="vin" label="VIN" required disabled={disableVinUpdate} />
        <Row form>
          <Col>
            <InputField
              id="vehicleYear"
              name="vehicleYear"
              label="Year"
              placeholder="Year"
              required={requiredFields['vehicle_year']}
              innerRef={focusRef}
              disabled={disabledVehicleFields.vehicleYear}
            />
          </Col>
          <Col>
            <InputField
              id="vehicleMake"
              name="vehicleMake"
              label="Make"
              placeholder="Make"
              required={requiredFields['vehicle_make']}
              disabled={disabledVehicleFields.vehicleMake}
            />
          </Col>
          <Col>
            <InputField
              id="vehicleModel"
              name="vehicleModel"
              label="Model"
              placeholder="Model"
              required={requiredFields['vehicle_model']}
              disabled={disabledVehicleFields.vehicleModel}
            />
          </Col>
        </Row>
        <ReactSelectField
          options={formOptions?.vehicleType?.options}
          isLoading={formOptions?.vehicleType?.loading}
          name="vehicleType"
          label="Vehicle Type"
          placeholder="Select a vehicle type..."
          className="mb-4"
          required={requiredFields['vehicle_type_id']}
          isDisabled={disabledVehicleFields.vehicleType}
        />
        <InputField
          type="number"
          name="vehicleWeight"
          label="Weight"
          required={requiredFields['vehicle_weight']}
          disabled={disabledVehicleFields.vehicleWeight}
        />

        <InputField name="mileage" label="Mileage" required={requiredFields['mileage']} />
        <InputField type="number" name="value" label="Value" required={requiredFields['value']} />

        <ReactSelectField
          options={formOptions?.vehicleWeightClass?.options}
          isLoading={formOptions?.vehicleWeightClass?.loading}
          name="weightClass"
          label="Vehicle Weight Class"
          placeholder="Select a vehicle weight class..."
          className="mb-4"
          isClearable
          required={requiredFields['weight_class_id']}
        />

        <ReactSelectField
          options={formOptions?.coverageType?.options}
          isLoading={formOptions?.coverageType?.loading}
          name="commercialAutoCoverageType"
          label="Commercial Auto Coverage Type"
          placeholder="Select a commercial auto coverage type..."
          className="mb-4"
          isClearable
          required={requiredFields['commercial_auto_coverage_type_id']}
        />

        <InputField name="fleetNumber" label="Fleet Number" required={requiredFields['fleet_number']} />
        <InputField name="unitNumber" label="Unit Number" required={requiredFields['unit_number']} />
        <CheckboxField
          id="isTractor"
          name="isTractor"
          label="Is Tractor"
          checkboxLabel="Is Tractor"
          required={requiredFields['is_tractor']}
        />
        <InputField name="notes" label="Notes" required={requiredFields['notes']} />
        <InputField name="serialNumber" label="Serial Number" required={requiredFields['serial_number']} />
        <ReactSelectField
          options={formOptions?.allStates?.options}
          name="stateOfRegistration"
          label="State of Registration"
          isClearable
          placeholder="Select a state..."
          required={requiredFields['state_of_registration']}
        />
        <ReactSelectField
          options={formOptions?.allStates?.options}
          name="garagedState"
          label="Garaged State"
          isClearable
          placeholder="Select a state..."
          required={requiredFields['garaged_state']}
        />
        <InputField name="garagedZip" label="Garaged in Zip Code" required={requiredFields['garaged_zip']} />
      </FormSection>
    </>
  );
}
