import { gql, useQuery, useMutation } from '@apollo/client';

export const POLICY_NOTIFICATION_GROUP_CORE_FIELDS = gql`
  fragment policyNotificationGroupCoreFields on PolicyNotificationGroup {
    id
    policyId
    notificationGroup
    emails
  }
`;

export const CREATE_POLICY_NOTIFICATION_GROUP = gql`
  mutation createPolicyNotificationGroup(
    $policyId: ID!
    $notificationGroup: PolicyNotificationGroupType!
    $emails: [String]
  ) {
    createPolicyNotificationGroup(notificationGroup: $notificationGroup, policyId: $policyId, emails: $emails) {
      ...policyNotificationGroupCoreFields
    }
  }
  ${POLICY_NOTIFICATION_GROUP_CORE_FIELDS}
`;

export const GET_POLICY_NOTIFICATION_GROUP = gql`
  query getPolicyNotificationGroup($policyId: ID!, $notificationGroup: PolicyNotificationGroupType!) {
    policyNotificationGroup(notificationGroup: $notificationGroup, policyId: $policyId) {
      ...policyNotificationGroupCoreFields
    }
  }
  ${POLICY_NOTIFICATION_GROUP_CORE_FIELDS}
`;

export const UPDATE_POLICY_NOTIFICATION_GROUP = gql`
  mutation updatePolicyNotificationGroup(
    $id: ID!
    $policyId: ID!
    $notificationGroup: PolicyNotificationGroupType!
    $emails: [String]
  ) {
    updatePolicyNotificationGroup(
      id: $id
      notificationGroup: $notificationGroup
      policyId: $policyId
      emails: $emails
    ) {
      ...policyNotificationGroupCoreFields
    }
  }
  ${POLICY_NOTIFICATION_GROUP_CORE_FIELDS}
`;

export default function usePolicyNotificationGroupForm({ policy, notificationGroup }) {
  const { data, loading: getLoading, error: getError } = useQuery(GET_POLICY_NOTIFICATION_GROUP, {
    variables: { policyId: policy?.id, notificationGroup }
  });

  const policyId = policy?.id;
  const isCreateMode = !data?.policyNotificationGroup;
  const refetchQueries = [{ query: GET_POLICY_NOTIFICATION_GROUP, variables: { policyId, notificationGroup } }];

  const [addPolicyNotificationGroup, { loading: createLoading, error: createError }] = useMutation(
    CREATE_POLICY_NOTIFICATION_GROUP,
    {
      refetchQueries: refetchQueries,
      variables: { policyId, notificationGroup },
      onError: () => {}
    }
  );

  const [updatePolicyNotificationGroup, { loading: updateLoading, error: updateError }] = useMutation(
    UPDATE_POLICY_NOTIFICATION_GROUP,
    {
      refetchQueries: refetchQueries,
      variables: { policyId, notificationGroup },
      onError: () => {}
    }
  );

  const handleSubmit = values => {
    const emailAddresses = values.emails.filter(item => item !== '');
    const variables = {
      ...(!isCreateMode && { id: data.policyNotificationGroup.id }),
      emails: emailAddresses,
      policyId: policy.id,
      notificationGroup: notificationGroup
    };
    if (isCreateMode) addPolicyNotificationGroup({ variables });
    else updatePolicyNotificationGroup({ variables });
  };

  const initialValues = {
    emails: isCreateMode ? [''] : [...data.policyNotificationGroup.emails, '']
  };

  return {
    initialValues,
    handleSubmit,
    mutationError: createError || updateError,
    data,
    getError,
    getLoading,
    mutationLoading: createLoading || updateLoading
  };
}
