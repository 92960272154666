import { useVehicleTypes } from '.';

export default function useVehicleTypeOptions() {
  const { vehicleTypes, loading, error } = useVehicleTypes();

  const vehicleTypeOptions = vehicleTypes?.map(({ name, id }) => ({
    label: name,
    value: id
  }));

  return {
    vehicleTypeOptions,
    loading,
    error
  };
}
