import { gql, useQuery } from '@apollo/client';

const GET_POLICY_COVERAGE_PARAMETERS = gql`
  query policyCoverageParmaters($id: ID!) {
    policy(id: $id) {
      id
      policyCoverageParameters {
        id
        name
        type
        valueType
        validate
        isPredefined: predefined
      }
    }
  }
`;

export default function usePolicyCoverageParameters({ policyId }) {
  const { data, loading, error } = useQuery(GET_POLICY_COVERAGE_PARAMETERS, {
    variables: { id: policyId }
  });

  return {
    policyCoverageParameters: data?.policy?.policyCoverageParameters,
    loading,
    error
  };
}
