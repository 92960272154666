import moment from 'moment';
import { displayElectionTotal, displayElectionBarePremium } from '../views/exposureElections';
import { MONTHLY, WEEKLY } from '../views/pricing/hooks/useProrateSettings';
import { isEmpty } from './index';
import { notWhitespace } from './regex';

const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

const extendedCurrencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 5
});

const percentFormat = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

export const formatCurrency = value => currencyFormat.format(value);
export const formatExtendedCurrency = value => extendedCurrencyFormat.format(value);

export const formatPercent = value => percentFormat.format(ensureFloat(value) / 100);

export const ensureFloat = value => {
  if (typeof value === 'string') {
    return parseFloat(value.replace(/[$%]+/, ''));
  }
  return value;
};

export const isDateOnly = date => !!date.match(/^(\d){4}-(\d){2}-(\d){2}$/g);

export const adjustForTimeZone = date => date && moment(date, moment.ISO_8601).utc();
export const humanFriendlyDate = date => adjustForTimeZone(date).fromNow();

// This formatDate function does too much, we should make it more extensible
export const formatDate = (date, options = { adjustToUtc: true }) => {
  if (date) {
    if (isDateOnly(date)) {
      return moment(date, moment.ISO_8601).format('MM/DD/YYYY');
    }

    if (options.adjustToUtc) return adjustForTimeZone(date).format('MM/DD/YYYY');

    return moment(date, moment.ISO_8601).format('MM/DD/YYYY');
  }
  return '';
};

export const normalizeNumber = num => {
  if (!num) return '';
  const number = num.toString().replace(/[^\d.]/g, '');
  return number;
};

export const titleCase = string =>
  string ? string.replace(notWhitespace, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()) : '';

export const backendNameToLabel = field_name => {
  return titleCase(field_name?.split('_').join(' '));
};

export const backendNameToCamelCaseLabel = field_name => {
  const titleCased = field_name
    .split('_')
    .map(x => titleCase(x))
    .join('');
  return camelCaseLabel(titleCased);
};

export const camelCaseLabel = label => {
  const txt = label.replace(' ', '');
  return txt.charAt(0).toLowerCase() + txt.substr(1);
};

export const sentenceToCamelCaseLabel = sentence => {
  const titleCased = sentence
    .split(/(?=[A-Z, ])/)
    .map(x => titleCase(x))
    .join('');
  return camelCaseLabel(titleCased);
};

export const sentenceToSnakeCase = sentence => {
  return sentence
    .split(/(?=[A-Z, ])/)
    .map(x => x.replace(' ', '').toLowerCase())
    .join('_');
};

export const dropFilenameExtension = filename => {
  const lastDotIndex = filename.lastIndexOf('.');
  return lastDotIndex < 1 ? filename : filename.substring(0, lastDotIndex);
};

export const formikErrorsToStrings = formik => {
  const { errors, submitCount } = formik;
  if (isEmpty(errors) || submitCount === 0) return null;
  return Object.keys(errors).reduce((acc, key, i) => {
    const errorString = `${titleCase(key)} ${errors[key].toLowerCase()}`;
    if (i === 0) return errorString;
    return `${acc}, ${errorString}`;
  }, '');
};

export const formatPricingRateType = pricingRateType => {
  const month = 'Month';
  const week = 'Week';
  if (pricingRateType === MONTHLY) return month;
  else if (pricingRateType === WEEKLY) return week;
  else if (pricingRateType === 'monthly') return month;
  else if (pricingRateType === 'weekly') return week;
  else return month;
};

export function formatPremium(election) {
  const total = displayElectionTotal(election);
  const rateMessage =
    total.startsWith('Missing') || total.startsWith('TBD')
      ? ''
      : `/ ${formatPricingRateType(election?.pricingRateType)}`;
  return `
  ${total} ${rateMessage}
`;
}

export function formatBarePremium(election) {
  const total = displayElectionBarePremium(election);
  const rateMessage =
    total.startsWith('Missing') || total.startsWith('TBD')
      ? ''
      : `/ ${formatPricingRateType(election?.pricingRateType)}`;
  return `
  ${total} ${rateMessage}
`;
}

export function formatAddonsList(election) {
  const addOns = election?.addOns || [];
  const addOnLabels = addOns.map(addOn => addOn.label || addOn.name);
  return addOnLabels.join(', ');
}

export function formatElectionServiceDates(election) {
  if (election?.startDate && election?.terminationDate)
    return `${formatDate(election?.startDate)} - ${formatDate(election?.terminationDate)}`;
  if (election?.startDate) return `${formatDate(election?.startDate)}`;
  return '';
}
