import React from 'react';
import { Formik } from 'formik';
import { Modal } from '@oforce/global-components';

import { GraphQLErrors } from '../../../components';

import { useRemovePricingForm } from '../hooks';

export default function PricingRemove({ show, toggle, pricing }) {
  const { initialValues, validationSchema, handleSubmit, loading, error } = useRemovePricingForm({
    pricingId: pricing?.id,
    onCompleted: toggle
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {formik => (
        <Modal
          isOpen={show}
          toggle={toggle}
          title="Remove Policy Pricing"
          onSubmit={formik.handleSubmit}
          submitButtonDisabled={formik.isSubmitting}
          submitButtonProps={{ color: 'danger', loadingText: 'Deleting...' }}
          submitButtonText={`Remove ${pricing?.label}`}
          loading={loading}
          onOpened={() => formik.handleReset()}
        >
          <GraphQLErrors error={error} />
          Are you sure you want to remove {pricing?.label}?
        </Modal>
      )}
    </Formik>
  );
}
