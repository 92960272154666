export default function useAddOnsAppliesToOptions() {
  const addOnAppliesToOptions = [
    {
      label: 'All Pricing Options',
      value: true
    },
    {
      label: 'Selected Pricing Options',
      value: false
    }
  ];

  return { addOnAppliesToOptions };
}
