import React, { useState } from 'react';
import { FilterLabel, FilterDropdown } from '.';
import { Render } from '@oforce/global-components';
import { EXPOSURE_ELECTION_STATUSES } from '../../views/exposureElections/constants';

const { ACTIVE, PENDING_TERMINATION, TERMINATED, REJECTED } = EXPOSURE_ELECTION_STATUSES;

export default function CoverageStatusFilter({ hideLabel, defaultStatus, onChange, className, style }) {
  const statusOptions = [
    { label: 'All', value: null },
    { label: 'Active', value: ACTIVE },
    { label: 'Pending Termination', value: PENDING_TERMINATION },
    { label: 'Terminated', value: TERMINATED },
    { label: 'Rejected', value: REJECTED }
  ];

  const defaultOption = statusOptions.find(({ value }) => value === defaultStatus);

  const [statusFitlers, setStatusFilters] = useState(defaultOption);

  const updateFilter = values => {
    const newFilters = { ...statusFitlers, ...values };
    setStatusFilters(newFilters);
    onChange && onChange(newFilters);
  };

  return (
    <div className={`d-flex flex-row align-items-center ${className}`}>
      <Render if={!hideLabel}>
        <FilterLabel>Status</FilterLabel>
      </Render>
      <FilterDropdown
        id="election-status"
        className="mr-3"
        style={style}
        headerText="Status..."
        options={statusOptions}
        defaultOption={defaultOption}
        setFilter={option => {
          if (option?.value === null) {
            updateFilter({
              status: null,
              inCompliance: null,
              complianceStatus: 'ALL'
            });
          } else if (option?.value === REJECTED) {
            updateFilter({
              status: null,
              inCompliance: null,
              complianceStatus: REJECTED
            });
          } else if (option?.value === ACTIVE) {
            updateFilter({
              status: ACTIVE,
              inCompliance: true,
              complianceStatus: null
            });
          } else if (option?.value === TERMINATED) {
            updateFilter({
              status: TERMINATED,
              inCompliance: null,
              complianceStatus: null
            });
          } else {
            updateFilter({
              status: option.value,
              inCompliance: true,
              complianceStatus: null
            });
          }
        }}
      />
    </div>
  );
}
