import React from 'react';
import { InputCurrencyField, InputPercentageField, ReactSelectField, Render } from '@oforce/global-components';
import { InputTypeSwitchLabel, FormSection } from '../../../components/forms';

export default function PolicyPricingPricingFields({ formik, formOptions }) {
  const activeMode = formik?.values?.rateMode?.value;
  const isPercentageFactor = formik?.values?.isPercentageFactor;
  const isFactored = activeMode === 'FACTORED';

  return (
    <FormSection title="Pricing">
      <ReactSelectField
        name="rateMode"
        required
        label="Mode"
        options={formOptions?.rateMode?.options}
        isLoading={formOptions?.rateMode?.loading}
        onChange={option => {
          formik.setFieldValue('rateMode', option);
          formik.setFieldValue('isPercentageFactor', option.value === 'FACTORED');
          formik.setFieldValue('factorableField', null);
        }}
      />

      <Render if={isFactored}>
        <InputTypeSwitchLabel
          id="rate"
          name="rate"
          label="Rate"
          required
          checked={formik.values.isPercentageFactor}
          onChange={() => formik.setFieldValue('isPercentageFactor', !formik.values.isPercentageFactor)}
        />
        <Render if={isPercentageFactor}>
          <InputPercentageField name="rate" required options={{ decimalLimit: 7 }} />
        </Render>
        <Render if={!isPercentageFactor}>
          <InputCurrencyField name="rate" required options={{ decimalLimit: null }} />
        </Render>
        <ReactSelectField
          name="factoredField"
          label="Factor"
          options={formOptions?.factorableFields?.options}
          isLoading={formOptions?.factorableFields?.loading}
          isClearable
          required
        />
        <InputCurrencyField
          key="percentageMinimum"
          name="percentageMinimum"
          label="Minimum Premium Value"
          optional
          options={{ allowLeadingZeroes: false, integerLimit: 7 }}
        />
        <InputCurrencyField
          key="percentageMaximum"
          name="percentageMaximum"
          label="Maximum Premium Value"
          optional
          options={{ allowLeadingZeroes: false, integerLimit: 7 }}
        />
      </Render>

      <Render if={!isFactored}>
        <InputCurrencyField options={{ decimalLimit: null }} required name="rate" label="Rate" />
      </Render>
    </FormSection>
  );
}
