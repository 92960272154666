import React from 'react';
import { Form } from 'reactstrap';
import moment from 'moment';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { Modal, DatePickerField, FieldMessage, Render } from '@oforce/global-components';

import { GraphQLErrors } from '../../../components';
import { useExposureEditStartDate, useRenewedFromPolicy } from '../hooks';
import { backDateMessage } from '../../exposureElections/hooks';

function message(policy) {
  return backDateMessage(policy?.startDateBackdatingMinimum, policy?.startDateBackdatingLimit);
}

export default function ExposureEditStartDate({ isOpen, toggle, policy, election }) {
  const { loading, error, reset, initialValues, validationSchema, handleSubmit } = useExposureEditStartDate({
    policy,
    election,
    toggle
  });

  const { renewedFromPolicy, loading: renewLoading, error: renewError } = useRenewedFromPolicy({ policy });
  const { backdate, id: renewedFromPolicyId, name: renewedFromPolicyName } = renewedFromPolicy;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {formik => (
        <>
          <Modal
            isOpen={isOpen}
            toggle={toggle}
            title="Edit Exposure Start Date"
            loading={loading || renewLoading}
            onSubmit={formik.handleSubmit}
            submitButtonText="Save Changes"
            onOpened={() => {
              formik.handleReset();
              reset();
            }}
            submitButtonProps={{ disabled: loading }}
          >
            <GraphQLErrors error={error || renewError} />
            <Form>
              <DatePickerField
                required
                fullWidth
                name="startDate"
                label="Start Date"
                format="MM/DD/YYYY"
                disabledDate={current => policy?.effectiveDate && current && current < moment(policy?.effectiveDate)}
                allowClear={false}
                inputReadOnly={true}
                messageAfter={message(policy)}
                messageAfterProps={{ icon: ['far', 'info-circle'] }}
              />
            </Form>
            <Render if={!!backdate && !!renewedFromPolicyId}>
              <FieldMessage icon={['far', 'info-circle']}>
                Backdating before the policy's effective date, requires changes in the previous policy:{' '}
                <Link to={`/policies/${renewedFromPolicyId}/insureds`}>{renewedFromPolicyName}</Link>
              </FieldMessage>
            </Render>
          </Modal>
        </>
      )}
    </Formik>
  );
}
