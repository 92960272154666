import React from 'react';
import { Form } from 'formik';
import { FieldMessage, InputField, ReactSelectField } from '@oforce/global-components';

export default function InvitationForm({ focusRef, formOptions }) {
  return (
    <Form>
      <InputField name="emailAddress" label="Email Address" required innerRef={focusRef} />
      <ReactSelectField
        required
        label="Role"
        name="role"
        options={formOptions?.accountRole?.options}
        isLoading={formOptions?.accountRole?.loading}
      />
      <FieldMessage icon={['far', 'info-circle']}>
        This user will see <b>all clients and policies</b> associated with this account.
        Invite a user at the policy level to prevent this.
      </FieldMessage>
    </Form>
  );
}
