import React from 'react';
import moment from 'moment';

import { toSentenceCase } from 'js-convert-case';
import { useProrateSettings } from '../../pricing/hooks';
import { backendNameToLabel, titleCase } from '../../../utils/formatting';
import { useInvoicingScheduleOptions } from '../../../views/invoice/hooks';
import { BoxContainer, BoxContainerTitle, LabelValueGroup, Render } from '../../../components';
import { displayGuardedLimit } from '../../exposureElections/hooks/useExposureBackdateLimits';
import { useActiveAccount } from '../../../shared/activeAccount';
import { FeatureFlag } from '../../../shared/activeAccount/components';
import { FLAGGED_FEATURES } from '../../../shared/activeAccount/constants';

export default function PolicyDetailsView({ policy }) {
  const { isAdmin } = useActiveAccount();
  const {
    options: prorateSettingOptions,
    PRORATE_SETTINGS: { NO_PRORATE }
  } = useProrateSettings();
  const { options: invoicingScheduleOptions } = useInvoicingScheduleOptions();
  const enableProrating = policy?.prorateSetting !== NO_PRORATE ? 'Enabled' : 'Disabled';
  const prorateSetting = prorateSettingOptions?.find(x => x.value === policy?.prorateSetting)?.label;
  const prorateAddons = policy?.prorateAddons ? 'Enabled' : 'Disabled';
  const invoicingSchedule = invoicingScheduleOptions?.find(o => o.value === policy?.invoicingSchedule)?.label;
  const pricingRateIsWeekly = policy?.pricingRateType === 'WEEKLY';

  return (
    <>
      <BoxContainer className="px-3 py-1 mb-5">
        {/* Policy specific field */}
        <LabelValueGroup label="Policy Number" value={policy?.policyNumber} />

        {/* Shared fields between policy and product */}
        <LabelValueGroup label="Name" value={policy?.name} />
        <LabelValueGroup label="External Id" value={policy?.externalId} />
        <LabelValueGroup label="Retail Broker" value={policy.retailBroker?.name} />
        <LabelValueGroup label="Insurance Type" value={policy?.insuranceType?.name} />
        <LabelValueGroup label="Insurance Carrier" value={policy.insuranceCarrier?.name} />
        <LabelValueGroup label="Custom Start Dates" value={policy?.enableCustomStartDates ? 'Enabled' : 'Disabled'} />
        <Render if={policy?.enableCustomStartDates}>
          <LabelValueGroup label="Backdate Start Limit" value={displayGuardedLimit(policy?.startDateBackdatingLimit)} />
        </Render>
        <LabelValueGroup
          label="Custom Termination Dates"
          value={policy?.enableCustomTerminationDates ? 'Enabled' : 'Disabled'}
        />
        <Render if={policy?.enableCustomTerminationDates}>
          <LabelValueGroup
            label="Backdate Termination Limit"
            value={displayGuardedLimit(policy?.terminationDateBackdatingLimit)}
          />
        </Render>

        {/* These fields are policy specific */}
        <LabelValueGroup label="Status" value={backendNameToLabel(policy?.effectiveStatus)} />
        <LabelValueGroup label="State" value={policy?.state} />
        <LabelValueGroup
          label="Effective Date"
          value={policy?.effectiveDate && moment(policy?.effectiveDate).format('MM/DD/YYYY')}
        />
        <LabelValueGroup
          label="Expiration Date"
          value={policy?.expirationDate && moment(policy?.expirationDate).format('MM/DD/YYYY')}
        />
        <Render if={isAdmin}>
          <LabelValueGroup label="API Only Exposure Updates" value={policy?.apiOnlyExposureUpdates ? 'Yes' : 'No'} />
        </Render>
        <LabelValueGroup
          label="Archive Date"
          value={policy?.archiveDate && moment(policy?.archiveDate).format('MM/DD/YYYY')}
        />
        <LabelValueGroup label="Rating Zone Source" value={toSentenceCase(policy?.ratingZoneSource)} />
        <Render if={isAdmin}>
          <LabelValueGroup label="Netsuite ID" value={policy?.netSuiteId} />
        </Render>
      </BoxContainer>

      <BoxContainerTitle>Pricing</BoxContainerTitle>
      <BoxContainer className="px-3 py-1 mb-5">
        <FeatureFlag feature={FLAGGED_FEATURES.ROLLOVER_PRICING}>
          <LabelValueGroup label="Rollover Pricing" value={policy?.rolloverPricing ? 'Enabled' : 'Disabled'} />
        </FeatureFlag>
        <LabelValueGroup label="Pricing Rate" value={titleCase(policy?.pricingRateType)} />
        <LabelValueGroup label="Prorating" value={enableProrating} />
        <Render if={enableProrating === 'Enabled'}>
          <LabelValueGroup label="Prorate Setting" value={prorateSetting} />
          <LabelValueGroup label="Prorate AddOns" value={prorateAddons} />
        </Render>
        <LabelValueGroup label="Billing Rule" value={toSentenceCase(policy?.billingRule)} />
        <Render if={policy?.snapshotCustomDay}>
          <LabelValueGroup label="Snapshot Custom Day" value={policy?.snapshotCustomDay} />
        </Render>
        <Render if={policy?.billingRule !== 'CLASSIC'}>
          <LabelValueGroup label="One Premium Required" value={policy?.onePremiumRequired ? 'Enabled' : 'Disabled'} />
        </Render>
      </BoxContainer>

      <BoxContainerTitle>Invoicing</BoxContainerTitle>
      <BoxContainer className="px-3 py-1 mb-5">
        {/* These are both shared between policy and product */}
        <LabelValueGroup label="Invoicing Features" value={policy?.isInvoicing ? 'Enabled' : 'Disabled'} />
        <LabelValueGroup
          label="Automate Invoice Generation"
          value={policy?.automateInvoiceGeneration ? 'Enabled' : 'Disabled'}
        />
        <Render if={!pricingRateIsWeekly}>
          <LabelValueGroup label="Invoice Due Day" value={policy?.invoiceDueDay} />
        </Render>
        <Render if={policy?.automateInvoiceGeneration}>
          <LabelValueGroup label="Collect Payment for" value={invoicingSchedule} />
          <LabelValueGroup label="Invoice Generation Day" value={policy?.invoiceGenerationDay} />
        </Render>
      </BoxContainer>
    </>
  );
}
