import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

import { AuthenticatingPage } from '../../components/pages';

import useAccessToken from './useAccessToken';
import useCustomApolloClient from './useCustomApolloClient';

function AuthenticatedApolloProvider({ children }) {
  const { isAuthenticated } = useAuth0();
  const { accessToken } = useAccessToken();

  const client = useCustomApolloClient({ accessToken });

  if (!isAuthenticated || !Boolean(accessToken)) return null;
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default withAuthenticationRequired(AuthenticatedApolloProvider, {
  onRedirecting: () => <AuthenticatingPage />
});
