import React from 'react';
import { useHistory } from 'react-router-dom';
import { Badge, Button, Render } from '@oforce/global-components';
import { toHeaderCase } from 'js-convert-case';

import { Table } from '../../../components/table-2.0';
import { useActiveAccount } from '../../../shared/activeAccount';
import { BoxContainer, BoxItemTitle } from '../../../components';

export default function AccountsList({ userAccounts }) {
  const history = useHistory();
  const { id: activeAccountId, setActiveAccountId } = useActiveAccount();

  return (
    <BoxContainer>
      <Table>
        <thead>
          <tr>
            <th>Account</th>
            <th>Your Role</th>
            <th className="text-right">Actions</th>
          </tr>
        </thead>
        <tbody>
          {userAccounts?.map(userAccount => (
            <tr key={userAccount?.id}>
              <td>
                <span className="d-flex flex-column flex-md-row align-items-center">
                  <BoxItemTitle
                    title={userAccount?.account?.name}
                    subtitle={`${toHeaderCase(userAccount?.account?.type)} Account`}
                  />
                  <Render if={activeAccountId === userAccount?.account?.id}>
                    <Badge color="success" className="my-1">
                      Current Account
                    </Badge>
                  </Render>
                </span>
              </td>
              <td>
                <span className="text-muted fs-sm">{toHeaderCase(userAccount?.role)}</span>
              </td>
              <td className="text-right">
                <Render if={activeAccountId !== userAccount?.account?.id}>
                  <Button
                    color="info"
                    outline
                    size="sm"
                    icon={['far', 'exchange-alt']}
                    onClick={async () => {
                      await setActiveAccountId(userAccount?.account?.id);
                      history.push(`/policies`);
                    }}
                  >
                    Switch to Account
                  </Button>
                </Render>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </BoxContainer>
  );
}
