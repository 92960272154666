import { useQuery } from '@apollo/client';
import { Queries } from '../model';
const { GET_EXPOSURE_ELECTION } = Queries;

export default function useGetExposureElection({ exposureElectionId }) {
  const { data, loading, error } = useQuery(GET_EXPOSURE_ELECTION, {
    skip: !exposureElectionId,
    variables: { id: exposureElectionId }
  });

  return {
    election: data?.getExposureElection,
    loading,
    error
  };
}
