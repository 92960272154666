import React from 'react';
import { humanFriendlyDate } from '../utils/formatting';

export default function CreatedUpdatedDates({ created, updated }) {
  return (
    <div className="d-flex align-items-center">
      <div className="mr-5">
        <Label>Created</Label>
        <Date date={created} />
      </div>
      <div>
        <Label>Last Edited</Label>
        <Date date={updated} />
      </div>
    </div>
  );
}

const Label = ({ children }) => <div className="fs-xxs font-weight-400 text-muted text-uppercase ls-1">{children}</div>;

const Date = ({ date }) => (
  <div className="fs-xxs mb-0 font-italic text-muted">{humanFriendlyDate(date) || 'None'}</div>
);
