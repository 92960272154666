import React from 'react';
import { Col, Row } from 'reactstrap';
import { BoxContainer, MultiStepForm, Render } from '../../../../components';
import { LargeIcon, Message } from '../../../../components/message-box';
import ExposureSteps from '../steps/ExposureSteps';
import useExposureDetailsFormOptions from './useExposureDetailsFormOptions';
import useExposureDetailsFormSteps from './useExposureDetailsFormSteps';

export default function ExposureDetailsMultiStepForm({
  insured,
  vehicle,
  coverages = [],
  onSubmit,
  onCancel,
  onBack,
  loading,
  title,
  error
}) {
  const { formOptions } = useExposureDetailsFormOptions();
  const {
    loading: hooksLoading,
    formSteps,
    stepsToRender,
    requiredInsuredFields,
    requiredVehicleFields
  } = useExposureDetailsFormSteps({
    formOptions,
    coverages,
    insured,
    vehicle
  });

  const formProps = {
    requiredInsuredFields,
    requiredVehicleFields,
    hasSsn: !!insured?.ssnLastFour
  };

  return (
    <>
      <Render if={hooksLoading}>
        <Row className="justify-content-center">
          <Col xs="12" lg="10" xl="8" style={{ maxWidth: '700px' }}>
            <BoxContainer>
              <div className="d-flex flex-column align-items-center">
                <div className="p-4" />
                <Message>Configuring your insurance forms ...</Message>
                <LargeIcon icon={['far', 'spinner-third']} color="info" spin />
                <div className="p-4" />
              </div>
            </BoxContainer>
          </Col>
        </Row>
      </Render>
      <Render if={!hooksLoading}>
        <MultiStepForm
          formOptions={formOptions}
          formSteps={formSteps}
          formSubmit={values => onSubmit(values, stepsToRender, coverages)}
          formProps={formProps}
          title={title}
          onCancel={onCancel}
          submitButtonText="Submit"
          submitButtonProps={{ loading, loadingText: 'Submitting...' }}
          showButtonsOnBottom
          enableReinitialize={false}
          onBack={onBack}
          loading={loading}
          error={error}
        >
          {formProps => <ExposureSteps formProps={formProps} stepsToRender={stepsToRender} />}
        </MultiStepForm>
      </Render>
    </>
  );
}
