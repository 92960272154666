import React from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Tr, Td } from '../../../components/table-2.0';
import { TrPlaceholder, TrLoader } from '@oforce/global-components';

import { BoxContainer, RenderData, TrGraphQLErrors } from '../../../components';

import { useProducts } from '../hooks';

export default function ProductsTable() {
  const history = useHistory();
  const { products, loading, error } = useProducts({});
  const colSpan = 1;

  return (
    <BoxContainer>
      <Table>
        <thead>
          <Tr>
            <th>Product Name</th>
            <th>Retail Broker Name</th>
            <th>Insurance Carrier Name</th>
            <th>External ID</th>
          </Tr>
        </thead>
        <tbody>
          <RenderData
            data={products}
            loading={loading}
            error={error}
            loadingComponent={<TrLoader colSpan={colSpan} />}
            errorComponent={<TrGraphQLErrors colSpan={colSpan} error={error} />}
            placeholderComponent={<TrPlaceholder colSpan={colSpan}>No products</TrPlaceholder>}
          >
            {renderData =>
              renderData?.map(product => (
                <Tr key={product?.id} onClick={() => history.push(`/products/${product.id}`)}>
                  <Td>{product?.name}</Td>
                  <Td>{product?.retailBroker?.name}</Td>
                  <Td>{product?.insuranceCarrier?.name}</Td>
                  <Td>{product?.externalId}</Td>
                </Tr>
              ))
            }
          </RenderData>
        </tbody>
      </Table>
    </BoxContainer>
  );
}
