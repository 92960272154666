import React from 'react';
import { Modal, Label, ReactCreatableSelect } from '@oforce/global-components';

import { GraphQLErrors } from '../../../components';

import { useSendInvoiceEmails } from '../hooks';

export default function SendInvoiceModal({ isOpen, toggle, invoice }) {
  const { handleSubmit, loading, error, invoiceEmailAddresses, setInvoiceEmails, reset } = useSendInvoiceEmails({
    invoice,
    onCompleted: toggle
  });

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      title="Send Invoice to Recipient(s)"
      loading={loading}
      onOpened={reset}
      submitButtonProps={{ loadingText: 'Sending...' }}
      submitButtonText="Send Invoice"
      submitButtonDisabled={invoiceEmailAddresses?.length === 0}
      onSubmit={handleSubmit}
    >
      <>
        {error && <GraphQLErrors error={error} />}
        <Label>Invoice Recipient(s)</Label>
        <ReactCreatableSelect
          options={invoiceEmailAddresses}
          isMulti
          value={invoiceEmailAddresses}
          name={'invoiceEmailAddresses'}
          label={'Invoice Recipient(s)'}
          onCreateOption={value => {
            setInvoiceEmails(values => [...(values ?? []), { value: value, label: value }]);
          }}
          onChange={values => setInvoiceEmails(values ?? [])}
          placeholder="Enter Emails..."
        />
      </>
    </Modal>
  );
}
