import { EXPOSURE_STATUSES } from '../exposureElections/constants';
const { ACTIVE, INACTIVE, PENDING } = EXPOSURE_STATUSES;

export function exposureStatusOptions() {
  return exposureStatuses.map(status => {
    return { label: status.label, value: status.value };
  });
}

export function exposureStatusColor(status) {
  return exposureStatuses.find(exposureStatus => status === exposureStatus.value).color || 'gray600';
}

export const exposureStatuses = [
  {
    label: 'All',
    value: null,
    color: 'gray600'
  },
  {
    label: 'Active',
    value: ACTIVE,
    color: 'green'
  },
  {
    label: 'Inactive',
    value: INACTIVE,
    color: 'red'
  },
  {
    label: 'Pending Approval',
    value: PENDING,
    color: 'yellow'
  }
];
