import styled from 'styled-components';

export const StyledGraphiQLContainer = styled.div.attrs({ className: 'graphiql-container' })`
  width: 320px !important;
  min-width: 320px !important;

  /* Hide close button for explorer */
  .docExplorerHide {
    display: none !important;
  }

  /* Hide add query/mutation functionality in explorer */
  .graphiql-explorer-root > div + div {
    display: none !important;
  }

  /* Hide GraphiQL logo */
  .topBar .title {
    display: none !important;
  }
`;
