import React from 'react';

import { BoxContainer, LabelValueGroup } from '../../../components';

export default function VehicleDetails({ vehicle }) {
  return (
    <>
      <BoxContainer className="px-3 py-3 ">
        <LabelValueGroup label="VIN #" value={vehicle?.vin.toUpperCase()} />
        <LabelValueGroup label="Unit Number" value={vehicle?.unitNumber} />
        <LabelValueGroup label="State of Registration" value={vehicle?.stateOfRegistration} />
        <LabelValueGroup label="Mileage" value={vehicle?.mileage} />
        <LabelValueGroup label="Value" value={vehicle?.value} />
        <LabelValueGroup label="Garaged State" value={vehicle?.garagedState} />
        <LabelValueGroup label="Garaged Zip" value={vehicle?.garagedZip} />
        <LabelValueGroup label="Weight Class" value={vehicle?.weightClass?.name} />
      </BoxContainer>
    </>
  );
}
