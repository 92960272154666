import { gql, useQuery } from '@apollo/client';
import { useFormOptions } from '../../../hooks';

const GET_AVAILABLE_REPORTS = gql`
  query GetAvailableReports {
    availableReports {
      name
      projectionModule
      query
    }
  }
`;

export default function useCreateReportFormOptions() {
  const { data, loading, error } = useQuery(GET_AVAILABLE_REPORTS);
  const availableReportsOptions = data?.availableReports?.map(report => ({
    label: report?.name,
    value: { projectionModule: report?.projectionModule, query: report?.query }
  }));

  const formOptions = useFormOptions({
    options: [
      {
        name: 'report',
        options: availableReportsOptions,
        loading,
        error
      }
    ]
  });

  return {
    formOptions
  };
}
