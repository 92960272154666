import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useActiveAccount } from '..';

export default function RouteShownForTypes({ children, roles = 'ALL', types = 'ALL', ...rest }) {
  const { showForTypes, showForRoles } = useActiveAccount();

  const showForRole = () => {
    if (roles === 'ALL') {
      return true;
    } else {
      return showForRoles(roles);
    }
  };

  const showForType = () => {
    if (roles === 'ALL') {
      return true;
    } else {
      return showForTypes(types);
    }
  };

  return showForType(types) && showForRole(roles) ? (
    <Route {...rest}>{children}</Route>
  ) : (
    <Redirect to="/access-denied" />
  );
}
