import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { TrLoader, TrPlaceholder } from '@oforce/global-components';

import { Table } from '../../../components/table-2.0';
import { TrGraphQLErrors } from '../../../components';

import StateFeeRow from './StateFeeRow';

export const GET_POLICY_STATE_FEES = gql`
  query PolicyStateFees($id: ID!) {
    policy(id: $id) {
      insuranceType {
        id
        stateFees {
          id
          activeMode
          fee
          state
        }
      }
    }
  }
`;

export default function StateFeesTable({ policy }) {
  const { data, loading, error } = useQuery(GET_POLICY_STATE_FEES, {
    variables: { id: policy?.id }
  });
  const stateFees = data?.policy?.insuranceType?.stateFees;
  const totalColCount = 2;

  return (
    <Table>
      <thead>
        <tr>
          <th>Fee</th>
          <th>State</th>
        </tr>
      </thead>
      <tbody>
        {loading && <TrLoader colSpan={totalColCount} />}
        {!loading && error && <TrGraphQLErrors colSpan={totalColCount} error={error} />}
        {!loading && !error && stateFees?.length === 0 && (
          <TrPlaceholder colSpan={totalColCount} className="bg-gray-100 border-bottom border-width-2 border-gray-300">
            No State Fees
          </TrPlaceholder>
        )}
        {!loading &&
          !error &&
          stateFees?.length > 0 &&
          stateFees?.map(stateFee => <StateFeeRow key={stateFee?.id} stateFee={stateFee} />)}
      </tbody>
    </Table>
  );
}
