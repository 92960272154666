import { Render } from '@oforce/global-components';
import React from 'react';
import { Link } from 'react-router-dom';
import { makeModelYear } from '../../vehicle/utils';

export default function CellVehicleInfo({ vehicle, skipLink = false }) {
  const makeModelYearLabel = makeModelYear(vehicle);

  return (
    <div className="d-flex flex-column align-items-start">
      {skipLink ? (
        <div className="font-weight-500">{!!makeModelYearLabel ? makeModelYearLabel : vehicle?.vin?.toUpperCase()}</div>
      ) : (
        <Link to={`/vehicles/${vehicle?.id}`} className="mb-0">
          {!!makeModelYearLabel ? makeModelYearLabel : vehicle?.vin?.toUpperCase()}
        </Link>
      )}
      <Render if={!!makeModelYearLabel}>
        <span className="text-muted fs-xs">{vehicle?.vin?.toUpperCase()}</span>
      </Render>
    </div>
  );
}
