import { DatePickerField, Modal } from '@oforce/global-components';
import { Formik } from 'formik';
import { GraphQLErrors } from '../../../components';
import * as yup from 'yup';
import moment from 'moment';

export default function ShowDocumentDownloadModal({ show, toggle, populateDocument, loading, error }) {
  const validationSchema = yup.object().shape({ activeOn: yup.date().required('Required') });
  const initialValues = { activeOn: moment() };

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={populateDocument}>
      {formik => (
        <Modal
          isOpen={show}
          toggle={toggle}
          title="Download Document"
          submitButtonText="Download"
          onSubmit={formik.handleSubmit}
          submitButtonProps={{ loadingText: 'Downloading...' }}
          loading={loading}
          onOpened={() => {
            formik.resetForm({ values: initialValues });
          }}
        >
          <GraphQLErrors error={error} />
          <DatePickerField fullWidth required name="activeOn" label="Active On" format="MM/DD/YYYY" />
        </Modal>
      )}
    </Formik>
  );
}
