import { gql, useQuery } from '@apollo/client';
import { backendNameToLabel } from '../../../utils/formatting';

export const NOTIFICATION_STATUS_OPTIONS = gql`
  query NotificationStatusOptions {
    notificationStatuses
  }
`;

export default function useNotificationStatusOptions() {
  const { data, loading, error } = useQuery(NOTIFICATION_STATUS_OPTIONS, {
    onCompleted: () => {},
    onError: () => {}
  });

  return {
    notificationStatusOptions:
      data?.notificationStatuses?.map(field => ({
        label: backendNameToLabel(field),
        value: field
      })) || [],
    loading,
    error
  };
}
