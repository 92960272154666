import React from 'react';
import { Modal, Label, ReactCreatableSelect, ReactSelect } from '@oforce/global-components';
import { GraphQLErrors } from '../../../components';
import { useSendInvoiceXslEmails } from '../hooks';
import { FLAGGED_FEATURES } from '../../../shared/activeAccount/constants';
import { FeatureFlag } from '../../../shared/activeAccount/components';
const { MASTER_SUB_RELATIONSHIP } = FLAGGED_FEATURES;

export default function SendInvoiceXslModal({ invoice, groupByOptions, isOpen, toggle }) {
  const {
    handleSubmit,
    loading,
    error,
    invoiceEmailAddresses,
    setInvoiceEmails,
    groupByOption,
    setGroupByOption,
    reset
  } = useSendInvoiceXslEmails({
    invoice,
    groupByOptions,
    onCompleted: toggle
  });

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      title="Send Invoice XSL Report to Recipient(s)"
      loading={loading}
      onOpened={() => reset()}
      submitButtonProps={{ loadingText: 'Sending...' }}
      submitButtonText="Send Invoice"
      submitButtonDisabled={invoiceEmailAddresses?.length === 0}
      onSubmit={handleSubmit}
    >
      <>
        {error && <GraphQLErrors error={error} />}
        <FeatureFlag feature={MASTER_SUB_RELATIONSHIP}>
          <Label>Group by</Label>
          <ReactSelect
            name="groupByOption"
            label="Group by"
            options={groupByOptions}
            value={groupByOptions.find(option => option.value === groupByOption)}
            onChange={groupByOption => {
              setGroupByOption(groupByOption?.value);
            }}
            isLoading={loading}
            disabled={loading}
            required
          />
          <br />
        </FeatureFlag>
        <Label>Invoice Recipient(s)</Label>
        <ReactCreatableSelect
          options={invoiceEmailAddresses}
          isMulti
          value={invoiceEmailAddresses}
          name={'invoiceEmailAddresses'}
          label={'Invoice Recipient(s)'}
          onCreateOption={value => {
            setInvoiceEmails(values => [...(values ?? []), { value: value, label: value }]);
          }}
          onChange={values => setInvoiceEmails(values ?? [])}
          placeholder="Enter Emails..."
        />
      </>
    </Modal>
  );
}
