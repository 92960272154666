import { createContext } from 'react';

export const InitialState = {
  startBulkDownloadDocumentsJob: () => {},
  clearJobId: () => {},
  jobId: null,
  progress: 0,
  message: null,
  loading: false,
  jobErrors: []
};

export const BulkDownloadDocumentsJobContext = createContext(InitialState);

BulkDownloadDocumentsJobContext.displayName = 'BulkDownloadDocumentsJobContext';
