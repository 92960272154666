import { createContext } from 'react';

export const InitialState = {
  startUpdateExposureJob: () => {},
  jobId: null,
  clearJobId: () => {},
  insured: null,
  vehicle: null,
  exposure_election: null,
  progress: 0,
  loading: false,
  jobErrors: [],
  message: null
};

export const ExposureUpdateJobContext = createContext(InitialState);

ExposureUpdateJobContext.displayName = 'ExposureUpdateJobContext';
