import React from 'react';
import { gql, useMutation } from '@apollo/client';

import { ConfirmModal, Bold } from '../../../components';

export const TRANSFER_ACCOUNT_OWNERSHIP = gql`
  mutation TransferAccountOwnership($userId: ID!) {
    transferAccountOwnership(newOwnerId: $userId) {
      id
      users {
        id
        role
        user {
          id
          name
          avatar
        }
      }
    }
  }
`;

export default function AccountTransferOwnership({ isOpen, toggle, userId, memberName }) {
  const [makeOwner, { loading, error, reset }] = useMutation(TRANSFER_ACCOUNT_OWNERSHIP, {
    variables: { userId },
    onCompleted: toggle,
    onError: () => {}
  });

  return (
    <ConfirmModal
      title="Transfer ownership"
      isOpen={isOpen}
      toggle={toggle}
      onConfirm={makeOwner}
      confirmButtonProps={{ loading, loadingText: 'Transferring...' }}
      error={error}
      onOpened={reset}
    >
      Are you sure you want to make <Bold>{memberName}</Bold> the new <Bold>Owner</Bold> of this account? You will lose{' '}
      <Bold>Owner</Bold> access and become a <Bold>Collaborator</Bold> role on the account.
    </ConfirmModal>
  );
}
