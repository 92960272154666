import { gql, useQuery } from '@apollo/client';

export const GET_ALL_OWNED_PRODUCTS = gql`
  query products {
    allProducts(filter: OWNED) {
      id
      name
    }
  }
`;

export default function useOwnedProducts() {
  const { data, loading, error } = useQuery(GET_ALL_OWNED_PRODUCTS);

  const options = data?.allProducts?.map(product => ({ label: product?.name, value: product?.id })) || [];

  return { ownedProducts: data?.allProducts, options, loading, error };
}
