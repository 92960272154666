import { gql, useMutation } from '@apollo/client';
import { GET_PRODUCT_FEES } from './useFees';

import { GET_PRODUCT_PRICINGS } from '../../productPricing/hooks/useProductPricings';
import { GET_ACCOUNT_TAGS } from '../../account/hooks/useAccountTags';

const EDIT_PRODUCT_FEE = gql`
  mutation EditProductFee($input: EditProductFeeInput!) {
    editProductFee(input: $input) {
      id
      name
      rateMode
      rate
      tags
      appliesToAllPricingOptions
      productPricingIds
    }
  }
`;

export default function useEditFee({ product, feeId, onCompleted = () => {}, onError = () => {} }) {
  const [edit, { loading, error, reset }] = useMutation(EDIT_PRODUCT_FEE, {
    onCompleted,
    onError,
    refetchQueries: [
      { query: GET_ACCOUNT_TAGS, variables: { accountId: product?.accountId } },
      { query: GET_PRODUCT_PRICINGS, variables: { id: product?.id } },
      { query: GET_PRODUCT_FEES, variables: { id: product?.id } }
    ]
  });

  return {
    editFee: feeInput =>
      edit({
        variables: {
          input: { productId: product?.id, feeId, ...feeInput }
        }
      }),
    loading,
    error,
    reset
  };
}
