import React, { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { Button } from '@oforce/global-components';

import { useDownloadFile } from '../../hooks';
import { useDocumentDownloadJobContext } from '../../context';
import { ToastContent } from '../../components/react-toastify';

export default function DocumentDownloadJob() {
  const { jobId, url, error, jobErrors, finished, progress, populatedDocument, message, filename, clearJobId } =
    useDocumentDownloadJobContext();

  const toastId = useRef(null);

  useEffect(() => {
    if (!toast.current && jobId) {
      toastId.current = toast(<PreparingDocuments />, { autoClose: false, closeOnClick: false });
    }
  }, [toastId, jobId, clearJobId]);

  useEffect(() => {
    if (!toastId.current) {
      return;
    }

    if (error) {
      toast.update(toastId.current, {
        render: () => <ErrorPreparingDocuments error={jobErrors || error} />,
        progress: finished ? 0.99 : progress,
        onClose: clearJobId
      });
    } else if (!finished && !url) {
      toast.update(toastId.current, {
        render: () => <PreparingDocuments populatedDocument={populatedDocument} message={message} />,
        progress: progress
      });
    } else if (finished) {
      toast.update(toastId.current, {
        render: () => <DocumentsReady message={message} url={url} filename={filename} errors={jobErrors} />,
        progress: 0.99,
        onClose: clearJobId
      });
    }
    // eslint-disable-next-line
  }, [toastId, url, error, finished, progress, populatedDocument, message, jobErrors]);

  return <></>;
}

const PreparingDocuments = ({ message, populatedDocument }) => {
  let title = message ?? 'Preparing Document(s)';

  if (populatedDocument) {
    const { insuredFirstName, insuredLastName } = populatedDocument;
    title = `${insuredFirstName} ${insuredLastName}`;
  }

  return (
    <ToastContent
      title={title}
      icon={['far', 'spinner-third']}
      iconProps={{ spin: true }}
      message="Please allow us some time to get everything together."
    />
  );
};

const ErrorPreparingDocuments = ({ error }) => (
  <ToastContent
    title="Error Preparing Document(s)"
    titleProps={{ className: 'text-danger' }}
    icon={['fas', 'times-circle']}
    message={error.message || error}
  />
);

const DocumentsReady = ({ message, url, filename, errors }) => {
  const { download, loading } = useDownloadFile({ url, filename });
  const { clearJobId } = useDocumentDownloadJobContext();

  const downloadAndClearJobId = () => {
    download();
    clearJobId();
  };

  return (
    <ToastContent
      title={message || 'Document(s) Ready'}
      titleProps={{ className: 'text-success' }}
      icon={url ? ['fas', 'check-circle'] : ['far', 'exclamation-triangle']}
      message={url && 'Your document(s) are ready for download.'}
    >
      {errors && errors.length > 0 && errors.map(e => <div key={e}>{e}</div>)}
      <div>
        {url && (
          <Button
            color="link"
            size="sm"
            icon={['far', 'arrow-to-bottom']}
            className="mr-2"
            loading={loading}
            loadingText="Downloading..."
            onClick={downloadAndClearJobId}
          >
            Download Now
          </Button>
        )}
      </div>
    </ToastContent>
  );
};
