import React, { useState } from 'react';
import { useToggle } from '@oforce/global-components';

import { AddOns, AddOnRemove } from '../../views/addOns/components';
import { AddOnCreate, AddOnEdit } from './components';

import { useProductPricingOptions } from '../../components/sharedComponents/pricing';

import { useProductAddOns, useAddOnCreate, useAddOnEdit } from './hooks';

export default function ProductAddOns({ product }) {
  const [showCreate, toggleCreate] = useToggle();
  const [showEdit, toggleShowEdit] = useToggle();
  const [editingAddOn, setEditingAddOn] = useState(null);

  const [showRemove, toggleShowRemove] = useToggle();
  const [removingAddOn, setRemovingAddOn] = useState(null);

  const { addOns, loading, error } = useProductAddOns({ productId: product?.id });
  const { productPricingOptions, selectOptions } = useProductPricingOptions({ productId: product?.id });

  const toggleEdit = addOn => {
    setEditingAddOn(addOn);
    toggleShowEdit();
  };

  const toggleRemove = addOn => {
    setRemovingAddOn(addOn);
    toggleShowRemove();
  };

  const {
    loading: createLoading,
    error: createError,
    reset: createReset,
    initialValues,
    handleSubmit
  } = useAddOnCreate({
    toggle: toggleCreate,
    product
  });

  const {
    loading: editLoading,
    error: editError,
    reset: editReset,
    initialValues: editInitialValues,
    handleSubmit: editHandleSubmit
  } = useAddOnEdit({ addOn: editingAddOn, toggle: toggleEdit, selectOptions, productId: product?.id });

  return (
    <>
      <AddOns
        addOns={addOns}
        loading={loading}
        error={error}
        toggleEdit={toggleEdit}
        toggleCreate={toggleCreate}
        toggleRemove={toggleRemove}
        pricingOptions={productPricingOptions}
      />
      {showCreate && (
        <AddOnCreate
          isOpen={showCreate}
          toggle={toggleCreate}
          loading={createLoading}
          error={createError}
          reset={createReset}
          initialValues={initialValues}
          handleSubmit={handleSubmit}
          pricingSelectOptions={selectOptions}
        />
      )}
      {showEdit && (
        <AddOnEdit
          isOpen={showEdit}
          toggle={toggleEdit}
          loading={editLoading}
          error={editError}
          reset={editReset}
          initialValues={editInitialValues}
          handleSubmit={editHandleSubmit}
          pricingSelectOptions={selectOptions}
        />
      )}
      {showRemove && <AddOnRemove isOpen={showRemove} toggle={toggleRemove} addOn={removingAddOn} />}
    </>
  );
}
