import moment from 'moment';
import * as yup from 'yup';

export default function useClientDocumentFormSteps({ formOptions }) {
  const DOCUMENT_VALIDATION = {
    SUPPORTED_FORMATS: ['application/pdf'],
    MAX_FILE_SIZE: 50000000
  };

  const formSteps = [
    {
      name: 'Document Details',
      initialValues: {
        document: null,
        name: '',
        type: formOptions?.documentType?.options[0],
        effectiveDate: moment().startOf('day'),
        expirationDate: ''
      },
      validationSchema: yup.object().shape({
        document: yup.mixed().when('$document', (_value, schema) =>
          schema
            .required('Required')
            .test('fileType', 'File must be a PDF', values =>
              values
                ?.map(value => DOCUMENT_VALIDATION.SUPPORTED_FORMATS.includes(value?.type))
                .reduce((acc, valid) => acc && valid, true)
            )
            .test('fileSize', 'File must be less than 50 MB', values =>
              values
                ?.map(value => value && value.size < DOCUMENT_VALIDATION.MAX_FILE_SIZE)
                .reduce((acc, valid) => acc && valid, true)
            )
        ),
        name: yup.string().required('Required'),
        type: yup.object({
          value: yup.string().required('Required'),
          label: yup.string().required('Required')
        }),
        effectiveDate: yup.date().required('Required'),
        expirationDate: yup.date()
      })
    },
    {
      name: 'Policies',
      initialValues: {
        policies: []
      },
      validationSchema: yup.object().shape({
        policies: yup.array().of(yup.object()).required('Required')
      })
    }
  ];

  return { formSteps };
}
