import { useQuery, gql } from '@apollo/client';
import { titleCase } from '../../../utils/formatting';

export const PRICING_RATE_TYPES = gql`
  query PricingRateTypes {
    pricingRateTypes
  }
`;

export default function usePricingRateTypes() {
  const { data, loading, error } = useQuery(PRICING_RATE_TYPES);

  return {
    pricingRateTypes: data?.pricingRateTypes,
    options: data?.pricingRateTypes?.map(type => ({ label: titleCase(type), value: type })) || [],
    loading,
    error
  };
}
