import { createContext } from 'react';

export const InitialState = {
  startGenerateCombinedInvoicesXslJob: () => {},
  clearJobId: () => {},
  jobId: null,
  filename: null,
  url: null,
  message: null,
  loading: false,
  jobErrors: []
};

export const GenerateCombinedInvoicesXslJobContext = createContext(InitialState);

GenerateCombinedInvoicesXslJobContext.displayName = 'GenerateCombinedInvoicesXslJobContext';
