import React from 'react';
import { Route, Switch, useParams, useRouteMatch, Redirect } from 'react-router-dom';
import { Loader } from '@oforce/global-components';

import { PageTitle, PageHeader } from '../../components/page-components';
import { GraphQLErrors } from '../../components';
import { TabBar, TabLink, TabPane } from '../../components/tabs';

import { ClientDetails } from '../../views';
import { useClient } from './hooks';
import ClientPolicies from './components/client-policies/ClientPolicies';
import { ClientDocuments } from '../clientDocuments';
import { FeatureFlag } from '../../shared/activeAccount/components';
import { FLAGGED_FEATURES } from '../../shared/activeAccount/constants';
const { CLIENT_DOCUMENTS } = FLAGGED_FEATURES;

export default function Client() {
  const { clientId } = useParams();
  const { url, path } = useRouteMatch();
  const { client, loading, error } = useClient({ id: clientId });

  if (loading) return <Loader />;
  if (error) return <GraphQLErrors error={error} />;

  return (
    <>
      <PageHeader withTabs>
        <PageTitle placeholder="No name found">{client?.name}</PageTitle>
        <TabBar>
          <TabLink to={`${url}/details`} icon={['far', 'info-square']}>
            Details
          </TabLink>
          <TabLink to={`${url}/policies`} icon={['far', 'file-contract']}>
            Policies
          </TabLink>
          <FeatureFlag feature={CLIENT_DOCUMENTS}>
            <TabLink to={`${url}/documents`} icon={['far', 'file']}>
              Documents
            </TabLink>
          </FeatureFlag>
        </TabBar>
      </PageHeader>

      <Switch>
        <Redirect exact from={`${path}`} to={`${path}/policies`} />
        <Route path={`${path}/policies`}>
          <TabPane>
            <ClientPolicies loading={loading} error={error} client={client} />
          </TabPane>
        </Route>
        <Route path={`${path}/details`}>
          <TabPane>
            <ClientDetails loading={loading} error={error} client={client} />
          </TabPane>
        </Route>
        <FeatureFlag feature={CLIENT_DOCUMENTS}>
          <Route path={`${path}/documents`}>
            <TabPane>
              <ClientDocuments client={client} />
            </TabPane>
          </Route>
        </FeatureFlag>
      </Switch>
    </>
  );
}
