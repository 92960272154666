import { gql } from '@apollo/client';
import { useSubscription } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';

import { ExposureUpdateJobContext } from './context';
import { useResettableMutation } from '../../hooks/useResettableMutation';

const START_JOB = gql`
  mutation startUpdateJob($exposureElectionRows: [UpdateExposureElectionInfoInput]!) {
    updateExposureElections(exposureElectionRows: $exposureElectionRows)
  }
`;

const ON_UPDATE_JOB_PROGRESS = gql`
  subscription jobProgress($jobId: ID!) {
    job: exposureUpdateJobProgress(jobId: $jobId) {
      jobId
      finished
      message
      errors
      commandCount
      progress
      insured {
        firstName
        lastName
        businessName
      }
      vehicle {
        vin
      }
      exposureElection {
        id
        terminationDate
      }
    }
  }
`;

export default function UpdateExposureJobProvider({ children }) {
  const [jobId, setJobId] = useState(null);
  const [jobErrors, setJobErrors] = useState([]);
  const [finished, setJobFinished] = useState(true);
  const [percentComplete, setPercentComplete] = useState(0);
  const [message, setMessage] = useState(null);
  const [insured, setInsured] = useState(null);
  const [vehicle, setVehicle] = useState(null);
  const [exposureElection, setExposureElection] = useState(null);

  const clearJobId = useCallback(() => {
    setJobId(null);
    setJobErrors([]);
  }, []);

  useEffect(() => {
    if (!!jobId && !!finished) {
      setTimeout(() => clearJobId(), 10000);
    }
  }, [finished, clearJobId, jobId]);

  const [startUpdateJob, { loading, error, reset }] = useResettableMutation(START_JOB, {
    onCompleted: data => {
      setJobFinished(false);
      setJobId(data?.updateExposureElections);
    },
    onError: () => setTimeout(() => reset(), 10000)
  });

  const startUpdateExposureJob = exposureElectionRows => {
    setJobFinished(false);
    startUpdateJob({ variables: { exposureElectionRows } });
  };

  useSubscription(ON_UPDATE_JOB_PROGRESS, {
    skip: !jobId,
    variables: { jobId },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      const job = data?.job;

      const { errors, message, insured, vehicle, exposureElection, finished, progress } = job;

      setJobErrors(errors);
      setJobFinished(finished);
      setPercentComplete(progress);
      setInsured(insured);
      setVehicle(vehicle);
      setExposureElection(exposureElection);
      setMessage(message);
    }
  });

  return (
    <ExposureUpdateJobContext.Provider
      value={{
        startUpdateExposureJob,
        jobId,
        clearJobId,
        loading,
        error,
        jobErrors,
        finished,
        progress: percentComplete,
        message,
        insured,
        vehicle,
        exposureElection
      }}
    >
      {children}
    </ExposureUpdateJobContext.Provider>
  );
}
