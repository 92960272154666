// included for Bootstrap Alert icons
import '@fortawesome/fontawesome-pro/css/solid.min.css';

import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faArchive,
  faCheckCircle as fasCheckCircle,
  faCircle as fasCircle,
  faCogs,
  faDollarSign as fasDollarSign,
  faEnvelope as fasEnvelope,
  faExclamationTriangle,
  faFile,
  faFileAlt,
  faFileChartLine as fasFileChartLine,
  faFileImport,
  faHouseUser as fasHouseUser,
  faLongArrowAltDown,
  faLongArrowAltLeft,
  faPercent as fasPercent,
  faPhone,
  faSignOut,
  faSort,
  faTable,
  faTicketAlt,
  faTimesCircle,
  faTrophy,
  faUsdCircle as fasUsdCircle,
  faUser as fasUser,
  faUserTie,
  faUserCircle as fasUserCircle,
  faEraser
} from '@fortawesome/pro-solid-svg-icons';

import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowDown,
  faArrowToBottom,
  faArrowUp,
  faArrowLeft,
  faBallot,
  faBallotCheck,
  faBars,
  faBox,
  faBoxUsd,
  faBuilding,
  faBullseyeArrow,
  faCalendarEdit,
  faCars,
  faCheck,
  faCheckSquare,
  faCity,
  faCog as farCog,
  faCopy,
  faCubes,
  faDollarSign,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faExclamationTriangle as farExclamationTriangle,
  faFile as farFile,
  faFileContract,
  faFileDownload,
  faFileImport as farFileImport,
  faFileInvoice,
  faFileInvoiceDollar,
  faFileUpload,
  faGlobe,
  faInfoCircle as farInfoCircle,
  faInfoSquare,
  faLaptopCode,
  faLayerPlus,
  faLongArrowAltRight,
  faLongArrowLeft,
  faLongArrowRight,
  faMinus,
  faNotesMedical,
  faPen,
  faPencil,
  faPercent,
  faPlus,
  faSearch,
  faSpinnerThird,
  faTimes,
  faTrafficCone,
  faTrashAlt,
  faUmbrella,
  faUsers,
  faUsersClass,
  faUser,
  faHome,
  faEnvelope,
  faExchangeAlt,
  faMountain,
  faFileExport,
  faUserEdit,
  faPaperPlane,
  faFileChartLine,
  faUserCog,
  faSyncAlt,
  faHouseUser,
  faUsdCircle,
  faCircle,
  faDotCircle,
  faClock,
  faChartBar,
  faFileChartPie,
  faExclamationCircle,
  faBan,
  faSortAlphaDown,
  faFilter,
  faExclamationSquare,
  faBadgeCheck,
  faLockAlt,
  faLockOpen,
  faUserTimes,
  faBell,
  faEnvelopeOpenText
} from '@fortawesome/pro-regular-svg-icons';

import {
  faCog,
  faFileSpreadsheet,
  faTimes as falTimes,
  faAngleRight as falAngleRight,
  faBuilding as falBuilding
} from '@fortawesome/pro-light-svg-icons';

import { faSortUp, faSortDown } from '@fortawesome/pro-duotone-svg-icons';

library.add(
  faEnvelopeOpenText,
  faBell,
  faUserTimes,
  fasUserCircle,
  faExclamationCircle,
  faFileChartPie,
  faChartBar,
  faLockAlt,
  faLockOpen,
  faBadgeCheck,
  faExclamationSquare,
  faFilter,
  faBan,
  faSortAlphaDown,
  faClock,
  faDotCircle,
  faCircle,
  fasUsdCircle,
  fasHouseUser,
  faSyncAlt,
  faUserCog,
  faPaperPlane,
  faFileExport,
  falBuilding,
  faExchangeAlt,
  fasEnvelope,
  faHome,
  falAngleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArchive,
  faArrowDown,
  faArrowToBottom,
  faArrowUp,
  faBallot,
  faBallotCheck,
  faBars,
  faBox,
  faBoxUsd,
  faBuilding,
  faBullseyeArrow,
  faCalendarEdit,
  faCars,
  faCheck,
  fasCheckCircle,
  faCheckSquare,
  fasCircle,
  faCity,
  faCog,
  faCogs,
  faCopy,
  faCubes,
  faDollarSign,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faExclamationTriangle,
  faFile,
  faFileAlt,
  faFileChartLine,
  fasFileChartLine,
  faFileContract,
  faFileDownload,
  faFileImport,
  faFileInvoice,
  faFileInvoiceDollar,
  faFileSpreadsheet,
  faFileUpload,
  faGlobe,
  faHouseUser,
  faInfoSquare,
  faLaptopCode,
  faLayerPlus,
  faLongArrowAltDown,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faLongArrowLeft,
  faLongArrowRight,
  falTimes,
  faMinus,
  faMountain,
  faNotesMedical,
  faPen,
  faPencil,
  faPercent,
  faPhone,
  faPlus,
  farCog,
  farExclamationTriangle,
  farFile,
  farFileImport,
  farInfoCircle,
  fasDollarSign,
  faSearch,
  faSignOut,
  faSort,
  faSortDown,
  faSortUp,
  fasPercent,
  faSpinnerThird,
  faTable,
  faTicketAlt,
  faTimes,
  falTimes,
  faTimesCircle,
  faTrafficCone,
  faTrashAlt,
  faTrophy,
  faUmbrella,
  faUsdCircle,
  fasUser,
  faUser,
  faUsers,
  faUsersClass,
  faUserTie,
  faUserEdit,
  faArrowLeft,
  faEraser
);
