import React, { useState } from 'react';
import { FiltersContainer, FilterLabel, FilterDropdown } from '../../../components';
import { DashboardFilters, CoveragesSearchBy } from '.';
import { EXPOSURE_ELECTION_STATUSES } from '../../exposureElections/constants';
import { CoverageStatusFilter } from '../../../components/filtering';
const { ACTIVE } = EXPOSURE_ELECTION_STATUSES;

export default function CoveragesFilters({ defaults, onChange, filterValue }) {
  const [filters, setFilters] = useState(defaults);

  const orderOptions = [
    { label: 'First Name', value: 'FIRST_NAME' },
    { label: 'Last Name', value: 'LAST_NAME' },
    { label: 'Insured Created', value: 'NEWEST' },
    { label: 'Election Created', value: 'NEWEST_ELECTION' },
    { label: 'Client Name', value: 'CLIENT_NAME' },
    { label: 'Insurance Type', value: 'INSURANCE_TYPE' },
    { label: 'Start Date', value: 'START_DATE' },
    { label: 'Dispatch Location', value: 'DISPATCH_LOCATION' }
  ];

  const updateFilter = values => {
    const newFilters = { ...filters, ...values };
    setFilters(newFilters);
    onChange(newFilters);
  };

  return (
    <div>
      <DashboardFilters filters={filterValue} />
      <FiltersContainer className="pb-0 mb-0 pt-3">
        <FilterLabel>Sort by</FilterLabel>
        <FilterDropdown
          id="ordering"
          className="mr-3"
          headerText="Sort ascending..."
          icon={['far', 'sort-alpha-down']}
          options={orderOptions}
          defaultOption={orderOptions.find(({ value }) => value === filterValue?.ordering)}
          setFilter={option => updateFilter({ ordering: option.value })}
        />
        <CoverageStatusFilter defaultStatus={ACTIVE} onChange={updateFilter} />
        <CoveragesSearchBy onChange={updateFilter} />
      </FiltersContainer>
    </div>
  );
}
