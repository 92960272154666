import { gql, useMutation } from '@apollo/client';
import { GET_UNCLAIMED_INVITATIONS } from '../../myProfile';

const DECLINE_POLICY_INVITATION = gql`
  mutation DeclinePolicyInvitation($invitationId: ID!) {
    invitation: declinePolicyInvitation(id: $invitationId) {
      id
      status
      account {
        id
      }
    }
  }
`;

export default function useDeclinePolicyInvitation({ onCompleted = () => {}, onError = () => {}, invitationId }) {
  const [decline, { loading, error }] = useMutation(DECLINE_POLICY_INVITATION, {
    variables: { invitationId },
    onCompleted,

    refetchQueries: [{ query: GET_UNCLAIMED_INVITATIONS }],
    onError
  });

  return [
    decline,
    {
      loading,
      error
    }
  ];
}
