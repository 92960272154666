import React from 'react';
import { Loader, Button } from '@oforce/global-components';
import { Switch, Route, useHistory, useRouteMatch } from 'react-router-dom';

import { BoxContainer, GraphQLErrors, Placeholder, RenderData, TabPage } from '../../components';
import { useActiveAccount } from '../../shared/activeAccount';
import { HideFromRolesAndTypes, RouteHiddenFromRolesAndTypes } from '../../shared/activeAccount/components';

import { useInsuredVehicles } from './hooks';
import { VehicleBoxItem } from './components';
import { VehicleCreate } from './containers';

export default function InsuredVehicles({ insured }) {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { vehicles, loading, error } = useInsuredVehicles({ insured });
  const {
    ROLES: { OBSERVER },
    TYPES: { CLAIMS_ADJUSTER }
  } = useActiveAccount();

  return (
    <Switch>
      <Route exact path={path}>
        <TabPage
          title="Vehicles"
          buttons={
            <HideFromRolesAndTypes roles={[OBSERVER]} types={[CLAIMS_ADJUSTER]}>
              <Button
                color="info"
                icon={['far', 'plus']}
                onClick={() => history.push(`/insureds/${insured?.id}/vehicles/new`)}
              >
                Create
              </Button>
            </HideFromRolesAndTypes>
          }
        >
          <BoxContainer>
            <RenderData
              data={vehicles}
              loading={loading}
              error={error}
              loadingComponent={<Loader />}
              errorComponent={<GraphQLErrors error={error} />}
              placeholderComponent={<Placeholder>No vehicles</Placeholder>}
            >
              {vehicles =>
                vehicles?.map(vehicle => {
                  return (
                    <VehicleBoxItem
                      key={vehicle?.id}
                      vehicle={vehicle}
                      onClick={() => history.push(`/vehicles/${vehicle?.id}`)}
                    />
                  );
                })
              }
            </RenderData>
          </BoxContainer>
        </TabPage>
      </Route>
      <RouteHiddenFromRolesAndTypes types={[CLAIMS_ADJUSTER]} roles={[OBSERVER]} path={`${path}/new`}>
        <VehicleCreate insured={insured} />
      </RouteHiddenFromRolesAndTypes>
    </Switch>
  );
}
