import React from 'react';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import { Button } from '@oforce/global-components';
import { Helmet } from 'react-helmet';

import { ProductsTable } from './components';
import { PageTitle, PageHeader, PageBody } from '../../components/page-components';

import { Product, ProductCreateUpdate } from '../../views';
import { TabPage } from '../../components/tabs';
import { ShowForTypes, useActiveAccount } from '../../shared/activeAccount';
import { HideFromRolesAndTypes } from '../../shared/activeAccount/components';

export default function Products() {
  const history = useHistory();
  const { path } = useRouteMatch();
  // const [showCreate, toggleCreate] = useToggle();
  const {
    TYPES: { CARRIER, CLAIMS_ADJUSTER },
    ROLES: { EXPOSURE_MANAGER, OBSERVER }
  } = useActiveAccount();

  return (
    <>
      <Switch>
        <Route exact path={`${path}`}>
          <Helmet>
            <title>Products • IC Insure</title>
          </Helmet>
          <PageHeader>
            <PageTitle>Products</PageTitle>
          </PageHeader>
          <PageBody>
            <TabPage
              title="My Products"
              buttons={
                <ShowForTypes types={[CARRIER]}>
                  <HideFromRolesAndTypes types={[CLAIMS_ADJUSTER]} roles={[EXPOSURE_MANAGER, OBSERVER]}>
                    <Button color="info" onClick={() => history.push('/products/new')} icon={['far', 'plus']}>
                      Create
                    </Button>
                  </HideFromRolesAndTypes>
                </ShowForTypes>
              }
            >
              <ProductsTable />
              {/* <ProductCreateUpdate isOpen={showCreate} toggle={toggleCreate} /> */}
            </TabPage>
          </PageBody>
        </Route>
        <Route path={`${path}/new`}>
          <Helmet>
            <title>Create Product • IC Insure</title>
          </Helmet>
          <PageHeader>
            <PageTitle>Products</PageTitle>
          </PageHeader>
          <PageBody>
            <ProductCreateUpdate />
          </PageBody>
        </Route>
        <Route path={`${path}/:productId`}>
          <Product />
        </Route>
      </Switch>
    </>
  );
}
