import React from 'react';
import { Td, Tr } from '.';

export default function BlankRow({ colSpan }) {
  return (
    <Tr>
      <Td colSpan={colSpan}>
        <span className="d-inline-block"></span>
      </Td>
    </Tr>
  );
}
