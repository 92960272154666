import * as yup from 'yup';

export default function buildValidationSchema({
  requiredFields = {},
  includeCoreFields = true,
  onlyFields = false,
  includeVin = false
}) {
  const requireField = fieldName => (_value, schema) => {
    return requiredFields[fieldName] ? schema.required('Required') : schema;
  };

  const coreFields = {
    vehicleMake: yup.string().when('$vehicleMake', requireField('vehicle_make')),
    vehicleYear: yup
      .number()
      .nullable()
      .min(1885, 'Earliest year allowed is 1885')
      .max(new Date().getFullYear() + 1, `Latest year allowed is ${new Date().getFullYear() + 1}`)
      .when('$vehicleYear', requireField('vehicle_year'))
      .when('$vehicleYear', (_value, schema) =>
        requiredFields['vehicle_year']
          ? schema.test('length', 'Must be exactly 4 digits', val => !!val && val.toString().length === 4)
          : schema
      )
      .typeError('Numbers only'),
    vehicleModel: yup.string().when('$vehicleModel', requireField('vehicle_model')),
    vehicleWeight: yup.number().nullable().when('$vehicleWeight', requireField('vehicle_weight')),
    value: yup.number().nullable().when('$value', requireField('value')),
    mileage: yup.string().when('$mileage', requireField('mileage')),
    garagedZip: yup.string().when('$garagedZip', requireField('garaged_zip')),
    garagedState: yup
      .object()
      .shape({ label: yup.string(), value: yup.string() })
      .nullable()
      .when('$garagedState', requireField('garaged_state')),
    stateOfRegistration: yup
      .object()
      .shape({ label: yup.string(), value: yup.string() })
      .nullable()
      .when('$stateOfRegistration', requireField('state_of_registration')),
    commercialAutoCoverageType: yup
      .object()
      .shape({ label: yup.string(), value: yup.string() })
      .when('$commercialAutoCoverageType', requireField('commercial_auto_coverage_type_id'))
      .nullable(),
    weightClass: yup
      .object()
      .shape({ label: yup.string(), value: yup.string() })
      .nullable()
      .when('$weightClass', requireField('weight_class_id')),
    vehicleType: yup
      .object()
      .shape({ label: yup.string(), value: yup.number() })
      .nullable()
      .when('$vehicleType', requireField('vehicle_type_id')),
    unitNumber: yup.string().when('$unitNumber', requireField('unit_number')),
    fleetNumber: yup.string().when('$fleetNumber', requireField('fleet_number')),
    serialNumber: yup.string().when('$serialNumber', requireField('serial_number')),
    isTractor: yup.boolean().when('$isTractor', requireField('is_tractor')),
    notes: yup.string().when('$notes', requireField('notes'))
  };

  const vinField = {
    vin: yup.string().when('$vin', requireField('vin'))
  };

  let fields = {
    ...(includeCoreFields && coreFields),
    ...(includeVin && vinField)
  };

  if (onlyFields) return fields;

  return yup.object().shape(fields);
}
