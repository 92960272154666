import React from 'react';
import cn from 'classnames';
import { Table } from 'reactstrap';
import { Td, Tr, TrPlaceholder } from '@oforce/global-components';
import { BlankRow } from '../../../components/table-2.0';
import { formatCurrency } from '../../../utils/formatting';
import { Render, SkeletonLoader } from '../../../components';
import TransactionLocationRow from './TransactionLocationRow';
import { INVOICE_STATUSES } from '../constants';
import TransactionLocationTitleRow from './TransactionLocationTitleRow';

export default function LocationTransactions({ invoice, isClientInvoice }) {
  const addons = invoice?.addOns || 0;
  const premiumTotal = parseFloat(invoice?.premium) + parseFloat(addons);
  const total = isClientInvoice ? invoice?.clientTotal : invoice?.brokerTotal;
  const invoicePending = invoice?.status === INVOICE_STATUSES.OPENED;
  const insuranceTypeRequiresVehicle = invoice?.clientProduct?.insuranceType?.requireVehicle;
  const colSpan = 5;
  const insuredHeaders = (
    <tr>
      <th style={{ width: '20%' }}>Line</th>
      <th style={{ width: '25%' }}>Description</th>
      <th style={{ width: '15%' }}>SSN</th>
      <th style={{ width: '20%' }}>Coverage Dates</th>
      <th className="text-right" style={{ width: '10%' }}>
        Amount
      </th>
    </tr>
  );

  const vehicleHeaders = (
    <tr>
      <th style={{ width: '20%' }}>Line</th>
      <th style={{ width: '20%' }}>Description</th>
      <th style={{ width: '30%' }}>Vin / Unit Number / Value</th>
      <th style={{ width: '20%' }}>Coverage Dates</th>
      <th className="text-right" style={{ width: '10%' }}>
        Amount
      </th>
    </tr>
  );

  return (
    <Table size="sm">
      <thead>{insuranceTypeRequiresVehicle ? vehicleHeaders : insuredHeaders}</thead>
      <tbody>
        {invoice?.locationTotals?.map((locationTotal, index) => (
          <React.Fragment key={index}>
            <TransactionLocationTitleRow
              colSpan={colSpan}
              text={locationTotal?.locationName ? 'Group Location: ' + locationTotal?.locationName : 'No Location'}
              insuranceTypeRequiresVehicle={insuranceTypeRequiresVehicle}
            />
            <Render if={locationTotal?.transactions?.length > 0}>
              {locationTotal?.transactions?.map((transaction, index) => (
                <TransactionLocationRow
                  key={transaction?.id}
                  index={index + 1}
                  transaction={transaction}
                  colSpan={colSpan}
                  insuranceTypeRequiresVehicle={insuranceTypeRequiresVehicle}
                />
              ))}
            </Render>
            <Render if={(!invoicePending && !locationTotal?.transactions) || locationTotal?.transactions?.length === 0}>
              <TrPlaceholder colSpan={colSpan} className="p-3">
                No transactions
              </TrPlaceholder>
            </Render>
            <TransactionTotalRow
              loading={invoicePending}
              label="Premium Subtotal"
              bold
              value={locationTotal.total}
              colSpan={colSpan}
            />
          </React.Fragment>
        ))}
        <Render if={invoicePending}>
          <tr>
            <td colSpan={colSpan}>
              <SkeletonLoader width="100%" height={23} shimmerWidth="150px" shape="squared" />
            </td>
          </tr>
        </Render>
        <Render if={(!invoicePending && !invoice?.locationTotals) || invoice?.locationTotals?.length === 0}>
          <TrPlaceholder colSpan={colSpan} className="p-3">
            No transactions
          </TrPlaceholder>
        </Render>
        <BlankRow colSpan={colSpan} />
        <TransactionTotalRow loading={invoicePending} label="Premiums Total" value={premiumTotal} colSpan={colSpan} />
        <TransactionTotalRow loading={invoicePending} label="Fees Total" value={invoice?.fees} colSpan={colSpan} />
        <Render if={!isClientInvoice}>
          <TransactionTotalRow
            loading={invoicePending}
            label="Retail Commissions"
            value={invoice?.retailCommission * -1 || 0}
            colSpan={colSpan}
          />
        </Render>
        <TransactionTotalRow loading={invoicePending} label="Total" bold value={total || 0} colSpan={colSpan} />
      </tbody>
    </Table>
  );
}

const TransactionTotalRow = ({ label, value, bold, colSpan, loading }) => {
  const isNegative = value < 0;

  const labelClasses = cn('d-inline-block', { 'font-weight-600': bold, 'font-weight-500': !bold });
  const valueClasses = cn('d-inline-block fs-lg', {
    'font-weight-600': bold,
    'font-weight-500': !bold,
    'text-danger': isNegative
  });

  return (
    <Tr>
      <Td colSpan={colSpan} align="right">
        <span className={labelClasses}>{label}</span>
        <span style={{ width: '150px' }} className={valueClasses}>
          {loading ? <SkeletonLoader width="50px" height={23} shape="squared" /> : formatCurrency(value)}
        </span>
      </Td>
    </Tr>
  );
};
