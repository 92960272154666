import React from 'react';
import { CustomInput } from 'reactstrap';

export default function SwitchInput({ name, activeLabel, inactiveLabel, onChange, isActive, className }) {
  return (
    <div className={`${className} d-flex align-items-center`}>
      <label
        className={`d-inline-flex mb-0 cursor-pointer pr-2 ${isActive ? 'text-info' : 'text-muted'}`}
        htmlFor={`customSwitchInput-${name}`}
      >
        {isActive ? activeLabel : inactiveLabel}
      </label>
      <CustomInput
        type="switch"
        id={`customSwitchInput-${name}`}
        name={`customSwitchInput-${name}`}
        checked={isActive}
        className="d-inline-flex"
        onChange={onChange}
      />
    </div>
  );
}
