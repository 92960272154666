import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { DeleteModal } from '../../../components/modals';
import { FieldMessage } from '@oforce/global-components';
import { LargeIcon } from '../../../components/message-box';

const HARD_DELETE_POLICY_DOCUMENT = gql`
  mutation hardDeletePolicyDocument($policyDocumentId: ID!) {
    hardDeletePolicyDocument(policyDocumentId: $policyDocumentId) {
      id
      documents {
        id
        appliesToAllPricingOptions
        filename
        url
        pricingOptionIds
        excludeFromAllPolicyPricingOptions
        type
        name
        source
        stateSource
        appliesToStates
      }
    }
  }
`;

export default function HardDeletePolicyDocument({ doc, isOpen, toggle }) {
  const [hardDeletePolicyDocument, { loading, error }] = useMutation(HARD_DELETE_POLICY_DOCUMENT, {
    onCompleted: toggle,
    onError: () => {}
  });

  return (
    <DeleteModal
      isOpen={isOpen}
      toggle={toggle}
      entityType="Policy Document"
      entityName={doc?.name}
      deleting={loading}
      error={error}
      submitButtonText={'Delete'}
      cancelButtonText={'Cancel'}
      onSubmit={() => hardDeletePolicyDocument({ variables: { policyDocumentId: doc?.id } })}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }} className="mt-3 mb-3">
        <LargeIcon icon="exclamation-triangle" color="danger" style={{ marginRight: '8px' }} />
        <FieldMessage color="danger">
          <div className="fs-md pt-0">
            This will <b>permanently delete</b> every instance of the policy document and cannot be undone.
          </div>
        </FieldMessage>
      </div>
    </DeleteModal>
  );
}
