import React, { useEffect, useRef } from 'react';
import { useSteps } from 'react-step-builder';
import { Button, Debugger } from '@oforce/global-components';
import { withFormikDevtools } from 'formik-devtools-extension';

import { TabPage, BoxContainer, Render, GraphQLErrors } from '..';
import { Form, Formik } from 'formik';

export default function MultiStepPage({
  formSteps,
  formOptions,
  formSubmit,
  formProps,
  formikRef,
  title,
  onCancel,
  submitButtonText = 'Create',
  showProgressBar = true,
  showButtonsOnTop = true,
  showButtonsOnBottom,
  submitButtonProps,
  enableReinitialize = true,
  loading,
  error,
  debug,
  children,
  onBack
}) {
  // get our helper values from useSteps (which comes from react-step-builder library)
  const { current, total, hasPrev, next, prev, isLast, progress } = useSteps();

  // if only one step, we treat as a normal full single page form
  const onlyOneStep = total === 1;

  // track the current step of the form
  const currentStep = formSteps[current - 1] || {};

  // create focusRef
  const focusRef = useRef(null);

  // focus the focusRef when the form is rendered
  useEffect(() => {
    focusRef?.current?.focus();
  }, [focusRef]);

  // set up formik's handleSubmit function
  const handleSubmit = (values, actions) => {
    if (isLast) {
      // if last step then we submit the form
      formSubmit(values, actions);
    } else {
      // if not last step, we set fields as touched to trigger validation
      actions?.setTouched({});
      // then navigate to the next step
      next();
    }
  };

  // combine all of the initialValues from each step into one object to pass to Formik
  const initialValues = formSteps?.reduce((acc, step) => {
    return { ...acc, ...step?.initialValues };
  }, {});

  const back = hasPrev ? prev : onBack;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={currentStep?.validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={enableReinitialize}
      innerRef={formikRef}
    >
      {formik => {
        withFormikDevtools(formik);

        // combine the renderProps for the form which will be passed to each step
        const renderProps = { formOptions, formik, focusRef, ...formProps };

        return (
          <TabPage
            title={title}
            subtitle={!onlyOneStep && `Step ${current} out of ${total}`}
            showButtonsOnTop={showButtonsOnTop}
            showButtonsOnBottom={showButtonsOnBottom}
            buttons={
              <>
                <Button className="ml-2" onClick={onCancel}>
                  Cancel
                </Button>
                <Render if={!onlyOneStep || !!back}>
                  <Button
                    className="ml-2"
                    color="info"
                    outline
                    disabled={!(back || hasPrev)}
                    icon={['far', 'long-arrow-left']}
                    onClick={back}
                  >
                    Back
                  </Button>
                </Render>
                <Render if={isLast}>
                  <Button
                    className="ml-2"
                    color="success"
                    loading={formik?.isSubmitting}
                    onClick={formik?.handleSubmit}
                    {...submitButtonProps}
                  >
                    {submitButtonText}
                  </Button>
                </Render>
                <Render if={!isLast}>
                  <Button
                    className="ml-2"
                    color="info"
                    iconAfter={['far', 'long-arrow-right']}
                    onClick={formik?.handleSubmit}
                  >
                    Next
                  </Button>
                </Render>
              </>
            }
          >
            <BoxContainer>
              <Render if={showProgressBar}>
                <ProgressBar progress={progress} />
              </Render>
              <div className="p-3">
                <Form>
                  <fieldset disabled={loading}>
                    <GraphQLErrors error={error} />
                    <Render if={debug}>
                      <Debugger {...formik} />
                    </Render>
                    {typeof children == 'function' ? children(renderProps) : children}
                  </fieldset>
                </Form>
              </div>
            </BoxContainer>
          </TabPage>
        );
      }}
    </Formik>
  );
}

const ProgressBar = ({ progress }) => (
  <div className="w-100" style={{ borderRadius: '2px 2px 0 0', overflow: 'hidden' }}>
    <div
      className="bg-info"
      style={{ width: `${progress * 100}%`, transition: 'all ease-in-out 0.5s', padding: '3px 8px' }}
    />
  </div>
);
