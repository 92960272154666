import React from 'react';
import cn from 'classnames';

export default function BoxItemTitle({ title, subtitle, className, children }) {
  const classes = cn('mr-2', className);

  return (
    <span className={classes}>
      <div className="font-weight-semibold" style={{ marginTop: '3px' }}>
        {title}
      </div>
      {subtitle && (
        <div className="text-muted fs-sm" style={{ marginTop: '0px', marginBottom: '3px' }}>
          {subtitle}
        </div>
      )}
    {children}
    </span>
  );
}
