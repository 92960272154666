import { ApolloClient, InMemoryCache, ApolloLink, from, defaultDataIdFromObject } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { hasSubscription } from '@jumpn/utils-graphql';
import useWebSocketLink from './useWebSocketLink';
import { createLink } from 'apollo-absinthe-upload-link';

export default function useCustomApolloClient({ accessToken }) {
  const webSocketLink = useWebSocketLink();

  const authAccountMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: `Bearer ${accessToken}`
      }
    }));

    return forward(operation);
  });

  const cacheIdGenerator = object => {
    const { __typename, cacheId } = object;
    if (cacheId) {
      return __typename + ':' + cacheId;
    }
    return defaultDataIdFromObject(object);
  };

  const httpLink = new createLink({ uri: '/api' });

  const middlewares = from([authAccountMiddleware, httpLink]);

  const link = new RetryLink().split(operation => hasSubscription(operation.query), webSocketLink, middlewares);

  return new ApolloClient({
    cache: new InMemoryCache({ dataIdFromObject: cacheIdGenerator }),
    defaultOptions: { watchQuery: { fetchPolicy: 'cache-and-network' } },
    link,
    connectToDevTools: true
  });
}
