import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ClientsTable } from './components';
import { Client } from '../../views';
import { PageTitle, PageHeader, PageBody } from '../../components/page-components';
import { useClients } from './hooks';

export default function Clients() {
  const { path } = useRouteMatch();
  const { clients, loading, error, clientFilter, changeClientFilter } = useClients();

  return (
    <>
      <Switch>
        <Route exact path={`${path}`}>
          <Helmet>
            <title>Clients • IC Insure</title>
          </Helmet>
          <PageHeader>
            <PageTitle>Clients</PageTitle>
          </PageHeader>
          <PageBody>
            <ClientsTable
              clients={clients}
              loading={loading}
              error={error}
              clientFilter={clientFilter}
              changeClientFilter={filters => changeClientFilter({ ...filters })}
            />
          </PageBody>
        </Route>
        <Route path={`${path}/:clientId`}>
          <Client />
        </Route>
      </Switch>
    </>
  );
}
