import { Loader } from '@oforce/global-components';
import React, { useState } from 'react';
import { GraphQLErrors, Placeholder } from '../../../components';
import CoverageGroup from './CoverageGroup';
import useElectionCoverageHistory from '../hooks/useElectionHistoryCoverage';

export default function ExposureElectionCoverageHistory({ election }) {
  const { coverageGroups, loading, error } = useElectionCoverageHistory({ exposureElectionId: election?.id });
  const [viewingCoverageGroup, setViewingCoverageGroup] = useState(null);

  if (loading) return <Loader />;
  if (!loading && error) return <GraphQLErrors error={error} />;
  if (coverageGroups.length === 0) return <Placeholder> None </Placeholder>;

  return (
    <div className="ml-0">
      {coverageGroups?.map(group => {
        return (
          <CoverageGroup
            key={group?.id}
            viewingDetails={viewingCoverageGroup?.id === group.id}
            setViewingDetails={setViewingCoverageGroup}
            group={group}
          />
        );
      })}
    </div>
  );
}
