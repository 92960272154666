import React from 'react';
import { FullPage, MessageBox, LargeIcon, Title, Message } from '../../components/message-box';

export default function RedirectingPage() {
  return (
    <FullPage>
      <MessageBox className="align-self-center justify-self-center">
        <LargeIcon icon={['far', 'spinner-third']} color="info" spin />
        <Title className="mb-0">Redirecting you</Title>
        <Message>Please wait...</Message>
      </MessageBox>
    </FullPage>
  );
}
