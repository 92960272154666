import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@oforce/global-components';

export default function CreateUpdateModal({
  isCreateMode,
  creating,
  updating,
  entityName,
  children,
  submitButtonProps,
  ...rest
}) {
  return (
    <Modal
      {...rest}
      title={`${isCreateMode ? 'Create' : 'Edit'} ${entityName}`}
      submitButtonProps={{
        loading: isCreateMode ? creating : updating,
        loadingText: isCreateMode ? 'Creating...' : 'Saving...',
        ...submitButtonProps
      }}
      submitButtonText={isCreateMode ? `Create ${entityName}` : 'Save Changes'}
    >
      {children}
    </Modal>
  );
}

CreateUpdateModal.propTypes = {
  isCreateMode: PropTypes.bool.isRequired,

  creating: PropTypes.bool,

  updating: PropTypes.bool,

  // This is the name of the entity being updated/created. It should be a singular noun.
  entityName: PropTypes.string
};
