import { Formik } from 'formik';
import React, { useRef } from 'react';
import { Modal } from '@oforce/global-components';

import { GraphQLErrors } from '../../../components';

import { VehicleTypeForm } from '.';
import { useUpdateVehicleTypeForm } from '../hooks';

export default function UpdateVehicleType({ showUpdateVehicleType, toggleUpdateVehicleType, vehicleType }) {
  const { validationSchema, initialValues, handleSubmit, loading, error } = useUpdateVehicleTypeForm({
    vehicleType,
    onUpdated: toggleUpdateVehicleType
  });

  const nameInputRef = useRef(null);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {formik => (
        <Modal
          isOpen={showUpdateVehicleType}
          toggle={toggleUpdateVehicleType}
          onOpened={() => {
            nameInputRef && nameInputRef.current.focus();
            formik.handleReset();
          }}
          title="Edit Vehicle Type"
          loading={loading}
          submitButtonText="Save Changes"
          submitButtonProps={{ loadingText: 'Saving...' }}
          onSubmit={formik.handleSubmit}
          submitButtonDisabled={formik.isSubmitting}
        >
          <GraphQLErrors error={error} />
          <VehicleTypeForm loading={loading} focusRef={nameInputRef} />
        </Modal>
      )}
    </Formik>
  );
}
