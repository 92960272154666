import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import CoverageForm from './CoverageForm';
import { INSURANCE_TYPES } from '../../configurationInsuranceTypes';

export default function CoverageCreate() {
  const history = useHistory();
  const { state } = useLocation();
  const { VEHICLE } = INSURANCE_TYPES;
  const isVehicle = state?.election?.insuranceType === VEHICLE
  const renderSelectOrCreateInsuredStep = state?.renderSelectOrCreateInsuredStep || false
  const renderSelectOrCreateVehicleStep = (state?.renderSelectOrCreateVehicleStep && isVehicle) || false
  const masterId = state?.masterId
  const insuredId = state?.election?.insuredId
  const policy = state?.election?.policy
  const policyInsuranceTypes = state?.election?.policy ? [state?.election?.insuranceType] : null
  const createSubcontractedCoverage = masterId && insuredId 
  const redirectUrl = createSubcontractedCoverage ? `/insureds/${insuredId}/elections/${masterId}` : '/coverages';

  return (
    <CoverageForm
      title="Create Coverage"
      onCancel={() => history.push(redirectUrl)}
      renderSelectOrCreateVehicleStep={renderSelectOrCreateVehicleStep}
      renderSelectOrCreateInsuredStep={renderSelectOrCreateInsuredStep}
      masterId={masterId}
      policy={policy}
      policyInsuranceTypes={policyInsuranceTypes}
    />
  );
}
