import { useFormOptions } from '../../../hooks';
import { usePolicyFactorableFields } from '../../policy/hooks';
import { useVehicleTypeOptions } from '../../configurationVehicleTypes/hooks';
import { usePremiumRateModes } from '../../../components/sharedComponents/pricing';

export default function usePolicyPricingFormOptions({ policyId }) {
  const { premiumRateModeOptions: rateModeOptions, loading: rateModeOptionsLoading } = usePremiumRateModes();
  const { vehicleTypeOptions, loading: vehicleTypeOptionsLoading } = useVehicleTypeOptions();
  const { flattenedOptions: factorableFieldsOptions, loading: factorableFieldsOptionsLoading } =
    usePolicyFactorableFields({
      policyId
    });

  const formOptions = useFormOptions({
    options: [
      { name: 'rateMode', options: rateModeOptions, loading: rateModeOptionsLoading },
      {
        name: 'vehicleTypes',
        options: vehicleTypeOptions,
        loading: vehicleTypeOptionsLoading
      },
      {
        name: 'factorableFields',
        options: factorableFieldsOptions,
        loading: factorableFieldsOptionsLoading
      },
      {
        name: 'stateMode',
        options: [
          { label: 'Include', value: 'include' },
          { label: 'Exclude', value: 'exclude' }
        ]
      }
    ]
  });

  return { formOptions };
}
