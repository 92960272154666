import React from 'react';
import { Loader } from '@oforce/global-components';
import { useParams, Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';

import { CompanyCreateUpdate } from '../../views';
import { PageTitle, GraphQLErrors } from '../../components';
import { PageHeader } from '../../components/page-components';
import { TabLink, TabBar, TabPane } from '../../components/tabs';

import { useCompany } from './hooks';
import { CompanyDetails } from './components';

export default function Company() {
  const { path, url } = useRouteMatch();
  const { companyId } = useParams();
  const { company, loading, error } = useCompany({ id: companyId });

  if (loading) return <Loader />;
  if (error) return <GraphQLErrors error={error} />;

  return (
    <Route path={path}>
      <PageHeader withTabs>
        <PageTitle>{company?.name}</PageTitle>
        <TabBar>
          <TabLink to={`${url}/details`} icon={['far', 'building']}>
            Details
          </TabLink>
        </TabBar>
      </PageHeader>

      <Switch>
        <Redirect exact from={path} to={`${path}/details`} />
        <Route path={`${path}/details`}>
          <TabPane>
            <Switch>
              <Route exact path={`${path}/details`}>
                <CompanyDetails company={company} />
              </Route>
              <Route path={`${path}/details/update`}>
                <CompanyCreateUpdate company={company} />
              </Route>
            </Switch>
          </TabPane>
        </Route>
      </Switch>
    </Route>
  );
}
