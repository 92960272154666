import React from 'react';
import { Row, Col } from 'reactstrap';
import { SwitchField, InputField, ReactSelectField } from '@oforce/global-components';
import { useActiveAccount } from '../../../shared/activeAccount';

import { FormSection, Render } from '../../../components';

export default function ProductDetailsFormFields({ formik, focusRef, formOptions, isCreateMode }) {
  const { isAdmin } = useActiveAccount();

  return (
    <FormSection title="Product Details">
      <InputField
        className="mb-3"
        name="name"
        label="Name"
        required
        placeholder="Enter a product name"
        innerRef={focusRef}
      />
      <ReactSelectField
        className="mb-3"
        name="insuranceType"
        id="insuranceType"
        label="Insurance Type"
        isClearable
        required
        options={formOptions?.insuranceTypes?.options}
        isLoading={formOptions?.insuranceTypes?.loading}
        isDisabled={!isCreateMode}
        placeholder="Select type..."
      />
      <ProductRoleField
        role="insuranceCarrier"
        label="Insurance Carrier"
        required
        options={formOptions?.companies?.options}
        isLoading={formOptions?.companies?.loading}
      />
      <ProductRoleField
        role="retailBroker"
        label="Retail Broker"
        options={formOptions?.companies?.options}
        isLoading={formOptions?.companies?.loading}
      />
      <InputField className="mb-3" name="externalId" label="External Id" optional placeholder="Enter an external id" />
      <SwitchField
        id="enable-custom-start-dates-switch"
        className="mb-3"
        label="Custom Start Dates"
        name="enableCustomStartDates"
        size="sm"
        switchLabel={formik.values.enableCustomStartDates ? 'Enabled' : 'Disabled'}
      />
      <Row form>
        <Col>
          <InputField
            name="startDateBackdatingLimit"
            label="Backdate Start Limit"
            type="number"
            messageAfter="Days"
            disabled={!formik.values.enableCustomStartDates}
            fullWidth
          />
        </Col>
        <SwitchField
          id="enable-custom-termination-dates-switch"
          className="mb-3"
          label="Custom Termination Dates"
          name="enableCustomTerminationDates"
          size="sm"
          switchLabel={formik?.values?.enableCustomTerminationDates ? 'Enabled' : 'Disabled'}
        />
        <Col>
          <InputField
            name="terminationDateBackdatingLimit"
            label="Backdate Termination Limit"
            type="number"
            messageAfter="Days"
            disabled={!formik?.values?.enableCustomTerminationDates}
            fullWidth
          />
        </Col>
      </Row>
      <ReactSelectField
        className="mb-3"
        name="ratingZoneSource"
        id="ratingZoneSource"
        label="Rating Zone Source"
        isClearable
        required
        options={formOptions?.ratingZoneSource?.options}
        isLoading={formOptions?.ratingZoneSource?.loading}
        placeholder="Select rating zone source..."
      />
      <Render if={isAdmin}>
        <SwitchField
          id="api-only-exposure-updates"
          className="mb-3"
          label="API Only Exposure Updates"
          name="apiOnlyExposureUpdates"
          size="sm"
          switchLabel={formik.values.apiOnlyExposureUpdates ? 'Enabled' : 'Disabled'}
        />
      </Render>
    </FormSection>
  );
}

const ProductRoleField = ({ label, role, options, required, ...rest }) => {
  return (
    <ReactSelectField
      id={role}
      name={role}
      label={label}
      options={options}
      required={required}
      placeholder="Select a company..."
      className="mb-3"
      isClearable
      {...rest}
    />
  );
};
