import { gql } from '@apollo/client';
import { useCurrentUser } from '../../currentUser';

export const CHECK_FEATURE_ENABLED = gql`
  mutation CheckFeatureEnabled($feature: FlaggedFeatures!) {
    checkFeatureEnabled(feature: $feature)
  }
`;

export default function FeatureFlag({ children, feature }) {
  const { enabledFeatures } =  useCurrentUser();
  const isEnabled = enabledFeatures.includes(feature)

  if (!feature) return null;
  return isEnabled ? children : null;
}
