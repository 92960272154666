import * as yup from 'yup';
import moment from 'moment';
import { gql, useQuery } from '@apollo/client';

import { useDateFormatting } from '../../../hooks';
import { INTERVAL_TYPES } from './useGenerateInvoiceFormOptions';
import usePolicyInvoiceInfo from './usePolicyInvoiceInfo';

const GET_POLICY_INVOICE_DATA = gql`
  query GetPolicyInvoiceData($policyId: ID!) {
    policy(id: $policyId) {
      id
      pricingRateType
      effectiveDate
      automateInvoiceGeneration
      invoiceEmailAddresses
      product {
        id
      }
      latestInvoice {
        id
        serviceEndDate
      }
      nextInvoiceServiceDates {
        startDate
        endDate
        generateAt
      }
    }
  }
`;

export default function useGenerateInvoiceFormSteps({ formOptions, policyId, policy }) {
  const {
    WEEKLY: { WEEK_RANGE, SPECIFIC_WEEK },
    MONTHLY: { MONTH_RANGE, SPECIFIC_MONTH }
  } = INTERVAL_TYPES;

  const { data, loading, error } = useQuery(GET_POLICY_INVOICE_DATA, {
    variables: { policyId },
    fetchPolicy: 'cache-and-network'
  });

  const nextInvoiceServiceDates = data?.policy?.nextInvoiceServiceDates;
  const firstAvailableStartDate = moment(data?.policy?.latestInvoice?.serviceEndDate).add(1, 'days');
  const automateInvoiceGeneration = data?.policy?.automateInvoiceGeneration;
  const effectiveDate = data?.policy?.effectiveDate;
  const pricingRateType = data?.policy?.pricingRateType;
  const { local: scheduledInvoiceGenerationDate } = useDateFormatting(nextInvoiceServiceDates?.generateAt);
  const { canGenerateOffCycleInvoice } = usePolicyInvoiceInfo({ policy });

  const formSteps = [
    {
      name: 'Generate Invoice',
      initialValues: {
        intervalType:
          pricingRateType === 'WEEKLY'
            ? formOptions?.weeklyIntervalType?.options[0]
            : formOptions?.monthlyIntervalType?.options[0],

        specificMonth: nextInvoiceServiceDates?.startDate || '',

        dateRange: !!nextInvoiceServiceDates
          ? [nextInvoiceServiceDates?.startDate, nextInvoiceServiceDates?.endDate]
          : null,

        invoiceEmailAddresses: data?.policy?.invoiceEmailAddresses.map(value => {
          return { value: value, label: value };
        }),
        effectiveDate,
        pricingRateType,
        automateInvoiceGeneration,
        invoiceGenerationAlreadyScheduled: !!nextInvoiceServiceDates?.generateAt,
        scheduledInvoiceGenerationDate,
        firstAvailableStartDate,
        invoiceDate: moment().format('YYYY-MM-DD')
      },
      validationSchema: yup.object({
        specificMonth: yup.object().when('intervalType', {
          is: ({ value }) => value === SPECIFIC_MONTH,
          then: schema => (!canGenerateOffCycleInvoice ? schema.required('Required') : schema),
          otherwise: schema => schema.nullable()
        }),
        dateRange: yup.mixed().when('intervalType', {
          is: ({ value }) => value === WEEK_RANGE || value === MONTH_RANGE || value === SPECIFIC_WEEK,
          then: schema => (!canGenerateOffCycleInvoice ? schema.required('Required') : schema),
          otherwise: schema => schema.nullable()
        }),
        invoiceDate: yup.date().required('Required')
      })
    }
  ];

  return { formSteps, loading, error, automateInvoiceGeneration };
}
