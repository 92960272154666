import React from 'react';
import { Formik } from 'formik';
import { Modal } from '@oforce/global-components';

import { GraphQLErrors } from '../../../components';

import { AddOnForm } from '.';
import { useAddOnValidationSchema } from '../../addOns/hooks';

export default function AddOnEdit({
  isOpen,
  toggle,
  reset,
  error,
  loading,
  initialValues,
  handleSubmit,
  pricingSelectOptions
}) {
  const validationSchema = useAddOnValidationSchema();

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {formik => (
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          title="Edit Add-On"
          loading={loading}
          onSubmit={formik.handleSubmit}
          submitButtonDisabled={formik.isSubmitting}
          submitButtonText="Save"
          onOpened={() => {
            formik.resetForm();
            reset();
          }}
        >
          <GraphQLErrors error={error} />
          <AddOnForm formik={formik} pricingSelectOptions={pricingSelectOptions} />
        </Modal>
      )}
    </Formik>
  );
}
