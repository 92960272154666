import React from 'react';
import { FilterDropdown, FilterLabel } from '../../../components';
import PoliciesSearchBy from './PoliciesSearchBy';

function PoliciesFilter({ onChange }) {
  const changeValue = (filterName, value) => onChange({ [filterName]: value });

  return (
    <div className="d-flex align-items-center flex-wrap">
      <FilterLabel>Sort by</FilterLabel>
      <PoliciesOrdering changeValue={changeValue} />
      <FilterLabel>Status</FilterLabel>
      <StatusFilter changeValue={changeValue} />
      <PoliciesSearchBy className={'mr-2'} onChange={onChange} />
    </div>
  );
}

const StatusFilter = ({ changeValue }) => {
  const options = [
    { label: 'Active', value: 'ACTIVE' },
    { label: 'All', value: null },
    { label: 'Archived', value: 'ARCHIVED' },
    { label: 'Expired', value: 'EXPIRED' }
  ];

  return (
    <FilterDropdown
      id="activeStatus"
      className="mr-2"
      headerText="Sort by..."
      icon={['far', 'sort-alpha-down']}
      options={options}
      defaultOption={options[0]}
      setFilter={option => changeValue('activeStatus', option?.value)}
    />
  );
};

const PoliciesOrdering = ({ changeValue }) => {
  const orderOptions = [
    { label: 'Policy Name', value: 'POLICY_NAME' },
    { label: 'Client Name', value: 'CLIENT_NAME' },
    { label: 'Policy Term', value: 'EFFECTIVE_DATE' },
    { label: 'Policy Pricing', value: 'POLICY_PRICING' },
    { label: 'Policy External Id', value: 'POLICY_EXTERNAL_ID' }
  ];

  return (
    <FilterDropdown
      id="ordering"
      className="mr-2"
      headerText="Sort by..."
      icon={['far', 'sort-alpha-down']}
      options={orderOptions}
      defaultOption={orderOptions[0]}
      setFilter={option => changeValue('ordering', option?.value)}
    />
  );
};

export default PoliciesFilter;
