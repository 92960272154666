import moment from 'moment';

export const dateHasPassed = date => {
  const today = moment();
  return date ? moment(date).isBefore(today) : false;
};

export const CLIENT_SIDE_DATE_FORMAT = 'MM/DD/YYYY';
export const SERVER_SIDE_DATE_FORMAT = 'YYYY-MM-DD';

export const dateToClientSideFormat = date => {
  if (!date) {
    return moment().format(CLIENT_SIDE_DATE_FORMAT);
  } else {
    return moment(date, SERVER_SIDE_DATE_FORMAT).format(CLIENT_SIDE_DATE_FORMAT);
  }
};

export const dateToServerSideFormat = date => {
  if (!date) {
    return moment().format(SERVER_SIDE_DATE_FORMAT);
  } else {
    return moment(date, CLIENT_SIDE_DATE_FORMAT).format(SERVER_SIDE_DATE_FORMAT);
  }
};
