import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';

export const GET_COMPANIES = gql`
  query companies {
    allCompanies {
      id
      name
      created
      updated
    }
  }
`;

export default function useCompanies() {
  const { data, loading, error } = useQuery(GET_COMPANIES);

  return {
    companies: data?.allCompanies,
    options: data?.allCompanies?.map(company => ({
      label: company?.name,
      value: company?.id
    })),
    loading,
    error
  };
}
