import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';

export const GET_POLICY_INVITATION = gql`
  query policyInvitation($invitationId: ID!) {
    policyInvitation(id: $invitationId) {
      id
      role
      status
      emailAddress
      created
      policy {
        id
        name
      }
      account {
        id
        name
        type
      }
    }
  }
`;

function usePolicyInvitation({ invitationId }) {
  const { loading, error, data } = useQuery(GET_POLICY_INVITATION, {
    skip: !invitationId,
    variables: { invitationId }
  });

  return {
    loading,
    error,
    invitation: data?.policyInvitation
  };
}

export default usePolicyInvitation;
