import * as yup from 'yup';
import moment from 'moment';

import { buildValidationSchema as buildInsuredValidationSchema } from '../../../insured/model';
import { buildValidationSchema as buildVehicleValidationSchema } from '../../../vehicle/model';
import {
  INSURED_DETAILS,
  VEHICLE_DETAILS,
  VIN_VALIDATION,
  VEHICLE_STAKEHOLDERS_DETAILS,
  SUBMIT,
  INSURED_STAKEHOLDERS_DETAILS
} from '../steps/stepConstants';
import useExposureMissingFields from './useExposureMissingFields';
import * as VehicleStakeholder from '../../../vehicleStakeholder/model';
import * as InsuredStakeholder from '../../../insuredStakeholder/model';

export default function useExposureDetailsFormSteps({ coverages, formOptions, insured, vehicle }) {
  const { isMissingInsuredFields, isMissingVehicleFields, requiredInsuredFields, requiredVehicleFields, loading } =
    useExposureMissingFields({
      insured,
      vehicle,
      policyIds: coverages.map(coverage => coverage?.policy?.id)
    });
  const insuredDetailsStep = {
    name: insured ? 'Edit Insured' : 'Create Insured',
    stepName: INSURED_DETAILS,
    validationSchema: buildInsuredValidationSchema({ requiredFields: requiredInsuredFields }),
    initialValues: {
      existingInsuredId: insured?.id,
      address: insured?.address || '',
      city: insured?.city || '',
      businessName: insured?.businessName || '',
      country: insured
        ? formOptions?.country?.options?.find(({ value }) => value === insured?.country) ||
          formOptions?.country?.options[0]
        : formOptions?.country?.options[0],
      dateOfBirth: insured?.dateOfBirth ? moment(insured?.dateOfBirth, 'YYYY-MM-DD').format('MM/DD/YYYY') : '',
      email: insured?.email || '',
      firstName: insured?.firstName || '',
      middleName: insured?.middleName || '',
      lastName: insured?.lastName || '',
      mobilePhone: insured?.mobilePhone || '',
      state: insured ? formOptions?.state?.options?.find(({ value }) => value === insured?.state) : null,
      zip: insured?.zip || '',
      ssn: insured?.ssnLastFour ? `XXX-XX-${insured.ssnLastFour}` : '',
      encryptedSsn: insured?.encryptedSsn,
      driversLicenseState: insured
        ? formOptions?.state?.options?.find(({ value }) => value === insured?.driversLicenseState)
        : null,
      driversLicenseNumber: insured?.driversLicenseNumber || '',
      dispatchLocation: insured?.dispatchLocation || ''
    }
  };

  const vinValidationStep = {
    name: 'VIN Validation',
    stepName: VIN_VALIDATION,
    validationSchema: yup.object().shape({
      vin: yup
        .string()
        .test('len', 'Must be exactly 17 characters', val => {
          if (val === undefined) {
            return true;
          }
          return val.length === 0 || val.length === 17;
        })
        .required('Required')
    }),
    initialValues: {
      vin: '',
      VinApiFields: {}
    }
  };

  const vehicleDetailsStep = {
    name: vehicle ? 'Edit Vehicle' : 'Create Vehicle',
    stepName: VEHICLE_DETAILS,
    validationSchema: buildVehicleValidationSchema({
      requiredFields: requiredVehicleFields
    }),
    initialValues: {
      vehicleId: vehicle?.id || '',
      vin: vehicle?.vin || '',
      vehicleModel: vehicle?.vehicleModel || '',
      vehicleMake: vehicle?.vehicleMake || '',
      vehicleYear: vehicle?.vehicleYear || '',
      vehicleWeight: vehicle?.vehicleWeight || '',
      vehicleType: vehicle
        ? formOptions?.vehicleType?.options?.find(({ value }) => value === vehicle?.vehicleType?.id)
        : null,
      fleetNumber: vehicle?.fleetNumber || '',
      unitNumber: vehicle?.unitNumber || '',
      isTractor: vehicle?.isTractor || false,
      notes: vehicle?.notes || '',
      serialNumber: vehicle?.serialNumber || '',
      stateOfRegistration: vehicle
        ? formOptions?.allStates?.options?.find(({ value }) => value === vehicle?.stateOfRegistration)
        : null,
      value: vehicle?.value || '',
      garagedZip: vehicle?.garagedZip || '',
      garagedState: vehicle
        ? formOptions?.allStates?.options?.find(({ value }) => value === vehicle?.garagedState)
        : null,
      mileage: vehicle?.mileage || '',
      weightClass: vehicle
        ? formOptions?.vehicleWeightClass?.options?.find(({ value }) => value === vehicle?.weightClass?.id)
        : null,
      commercialAutoCoverageType: vehicle
        ? formOptions?.coverageType?.options?.find(({ value }) => value === vehicle?.commercialAutoCoverageType?.id)
        : null
    }
  };

  const vehicleStakeholdersStep = {
    stepName: VEHICLE_STAKEHOLDERS_DETAILS,
    ...VehicleStakeholder.step(vehicle, requiredVehicleFields, formOptions)
  };

  const insuredStakeholdersStep = {
    stepName: INSURED_STAKEHOLDERS_DETAILS,
    ...InsuredStakeholder.step(insured, requiredInsuredFields, formOptions)
  };

  let stepsToRender = [];
  let formSteps = [];

  if (isMissingInsuredFields) {
    stepsToRender.push(INSURED_DETAILS);
    formSteps.push(insuredDetailsStep);

    stepsToRender.push(INSURED_STAKEHOLDERS_DETAILS);
    formSteps.push(insuredStakeholdersStep);
  }

  if (isMissingVehicleFields && !vehicle) {
    stepsToRender.push(VIN_VALIDATION);
    formSteps.push(vinValidationStep);
  }

  if (isMissingVehicleFields) {
    stepsToRender.push(VEHICLE_DETAILS);
    formSteps.push(vehicleDetailsStep);

    stepsToRender.push(VEHICLE_STAKEHOLDERS_DETAILS);
    formSteps.push(vehicleStakeholdersStep);
  }

  if (stepsToRender.length === 0) {
    stepsToRender.push(SUBMIT);
  }

  return {
    formSteps,
    stepsToRender,
    requiredInsuredFields,
    requiredVehicleFields,
    loading
  };
}
