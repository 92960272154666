import React from 'react';
import { Steps } from 'react-step-builder';
import { useHistory } from 'react-router-dom';

import { MultiStepForm } from '../../components';

import {
  ProductDetailsFormFields,
  CustomDataStep,
  ProductMasterCertificateFields,
  ProductPricingFormFields,
  ProductInvoicingFormFields
} from './components';
import { useProductFormOptions, useProductFormSteps, useProductFormSubmit } from './hooks';

export default function ProductCreateUpdate({ product }) {
  const history = useHistory();

  const isCreateMode = !product;
  const { formOptions } = useProductFormOptions({ product });
  const { formSteps } = useProductFormSteps({ product, formOptions });
  const { formSubmit, loading, error } = useProductFormSubmit({ product, isCreateMode });

  return (
    <MultiStepForm
      formOptions={formOptions}
      formSteps={formSteps}
      formSubmit={formSubmit}
      formProps={{ isCreateMode }}
      title={`${isCreateMode ? 'Create' : 'Edit'} Product`}
      onCancel={() => (isCreateMode ? history.push(`/products`) : history.push(`/products/${product?.id}`))}
      submitButtonText={isCreateMode ? 'Create' : 'Save'}
      submitButtonProps={{ loading, loadingText: isCreateMode ? 'Creating...' : 'Saving...' }}
      showButtonsOnBottom
      error={error}
    >
      {formProps => (
        <Steps>
          <ProductDetailsFormFields {...formProps} />
          <CustomDataStep title="Product Custom Data" {...formProps} />
          <ProductMasterCertificateFields {...formProps} />
          <ProductPricingFormFields {...formProps} />
          <ProductInvoicingFormFields {...formProps} />
        </Steps>
      )}
    </MultiStepForm>
  );
}
