import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

const StyledBoxContainer = styled.div`
  box-shadow: 0px 3px 0px 0px ${props => props.theme.gray300};
  border-radius: 10px;
`;

export default function BoxContainer({ disabled, className, children }) {
  const boxContainerClasses = classNames(
    'bg-white rounded border border-width-2 border-gray-400',
    { 'opacity-75': disabled },
    className
  );

  return <StyledBoxContainer className={boxContainerClasses}>{children}</StyledBoxContainer>;
}
