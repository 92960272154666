import { gql, useMutation } from '@apollo/client';
import { GET_UNCLAIMED_INVITATIONS } from '../../myProfile';

const ACCEPT_POLICY_INVITATION = gql`
  mutation AcceptPolicyInvitation($accountId: ID!, $invitationId: ID!) {
    invitation: acceptPolicyInvitation(accountId: $accountId, id: $invitationId) {
      id
      status
      policy {
        id
        name
      }
      account {
        id
      }
    }
  }
`;

export default function useAcceptInvitation({ onCompleted = () => {}, onError = () => {}, invitationId }) {
  const [accept, { loading, error }] = useMutation(ACCEPT_POLICY_INVITATION, {
    onCompleted,
    onError,
    refetchQueries: [{ query: GET_UNCLAIMED_INVITATIONS }]
  });

  return [
    ({ accountId }) => accept({ variables: { invitationId, accountId } }),
    {
      loading,
      error
    }
  ];
}
