import { gql, useQuery } from '@apollo/client';
import { backendNameToLabel } from '../../../utils/formatting';

export const INVOICE_SEARCH_BY_OPTIONS = gql`
  query InvoiceSearchByOptions {
    invoiceSearchByFields
  }
`;

export default function useInvoiceSearchByOptions() {
  const { loading, error, data } = useQuery(INVOICE_SEARCH_BY_OPTIONS, {
    variables: {},
    onCompleted: () => {},
    onError: () => {}
  });

  return {
    invoiceSearchByOptions:
      data?.invoiceSearchByFields?.map(field => ({
        label: backendNameToLabel(field),
        value: field
      })) || [],
    loading,
    error
  };
}
