import { gql, useSubscription } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Button } from '@oforce/global-components';
import { toast } from 'react-toastify';
import { useActiveAccount } from '../../../shared/activeAccount';
import { INVOICE_STATUSES } from '../../invoice/constants';
import { ToastContent } from '../../../components/react-toastify';

export const INVOICE_SUBSCRIPTION = gql`
  subscription invoiceChanged($accountId: ID!) {
    change: invoiceNotifications(accountId: $accountId) {
      invoiceId
      policyId
      status
      voidedInvoiceId
    }
  }
`;

export default function useInvoiceNotifications() {
  const { id } = useActiveAccount();
  const history = useHistory();

  useSubscription(INVOICE_SUBSCRIPTION, {
    variables: { accountId: id },
    skip: !id,
    onSubscriptionData({ subscriptionData: { data } }) {
      const {
        change: { invoiceId, voidedInvoiceId, status }
      } = data;

      if (status === INVOICE_STATUSES.COMMITTED && !voidedInvoiceId) {
        toast(
          <ToastContent
            title="Success!"
            message="The invoice has been generated."
            icon={['fas', 'check-circle']}
            titleProps={{ className: 'text-success' }}
          >
            <Button
              color="link"
              size="sm"
              icon={['far', 'arrow-to-bottom']}
              className="mr-2"
              onClick={() => history.push(`/invoices/${invoiceId}`)}
            >
              View invoice
            </Button>
          </ToastContent>,
          { autoClose: 3000, progressClassName: 'bg-success' }
        );
      }
    }
  });
}
