import React from 'react';
import { Formik, Form } from 'formik';
import { Modal, ReactSelectField } from '@oforce/global-components';
import { GraphQLErrors } from '../../../components';
import { useGenerateInvoiceXslJobContext } from '../../../context';
import { FeatureFlag } from '../../../shared/activeAccount/components';
import { FLAGGED_FEATURES } from '../../../shared/activeAccount/constants';
import { Alert } from 'reactstrap';
const { MASTER_SUB_RELATIONSHIP } = FLAGGED_FEATURES;

export default function GenerateInvoiceXslModal({ invoice, groupByOptions = [], isOpen, toggle }) {
  const { loading, error, startGenerateInvoiceXslJob } = useGenerateInvoiceXslJobContext();

  const handleSubmit = values => {
    startGenerateInvoiceXslJob({ invoiceId: values?.invoiceId, groupBy: values?.groupBy });
    toggle();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{ invoiceId: invoice?.id, groupBy: groupByOptions[0]?.value }}
      onSubmit={handleSubmit}
    >
      {formik => (
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          title={`Download Invoice XSL #${invoice?.id}`}
          onSubmit={formik.handleSubmit}
          submitButtonText="Download"
          submitButtonProps={{
            color: 'info',
            loading: loading,
            loadingText: 'Generating invoice report...'
          }}
        >
          <GraphQLErrors error={error} />
          <Form>
            <Alert color="info">{`Download invoice report grouped by ${formik?.values?.groupBy}`}</Alert>
            <FeatureFlag feature={MASTER_SUB_RELATIONSHIP}>
              <ReactSelectField
                name="groupByOption"
                label="Group by"
                options={groupByOptions}
                defaultValue={groupByOptions[0]}
                onChange={groupByOption => {
                  formik?.setFieldValue('groupBy', groupByOption?.value);
                }}
                isLoading={loading}
                disabled={loading}
                required
              />
            </FeatureFlag>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}
