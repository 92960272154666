import React, { useState } from 'react';
import { BoxContainer, TabPage, Table, TrGraphQLErrors } from '../../../components';
import { RenderData, TrLoader, TrPlaceholder } from '@oforce/global-components';
import { useInsuredDocuments } from '../hooks';
import InsuredDocumentRow from './InsuredDocumentRow';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { DocumentVersions } from '../../document/components';
import { InsuredDocumentFilters } from '.';
import { MessageBox, LargeIcon, Title, Message } from '../../../components/message-box';

export default function InsuredDocuments({ insuredId }) {
  const { path } = useRouteMatch();

  const [filters, setFilters] = useState({ searchBy: [], ordering: 'CLIENT_NAME' });

  const { insuredStatus, exposureElectionDocuments, documentRelationships, loading, error } = useInsuredDocuments({
    insuredId,
    filters
  });
  const allInsuredDocuments = [...exposureElectionDocuments, ...documentRelationships];
  const colSpan = 4;

  return (
    <>
      <Switch>
        <Route exact path={path}>
          {insuredStatus === 'PENDING' && (
            <MessageBox className="align-self-center justify-self-center mb-5">
              <LargeIcon icon={['far', 'exclamation-triangle']} color="warning" />
              <Title className="mb-0">Insured has compliance violations</Title>
              <Message>Document versions are not available for coverages with open violations</Message>
            </MessageBox>
          )}
          <TabPage
            title="Insured Documents"
            marginBottom="mb-0"
            contentClassName="mt-1"
            titleClassName="mb-1"
            className="flex-grow-1 mt-0"
          >
            <InsuredDocumentFilters defaults={filters} onChange={setFilters} filterValue={filters} loading={loading} />
            <BoxContainer>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Document</th>
                    <th>Client</th>
                    <th>VIN/UNIT</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <RenderData
                    data={allInsuredDocuments}
                    loading={loading}
                    error={error}
                    loadingComponent={<TrLoader colSpan={colSpan} />}
                    errorComponent={<TrGraphQLErrors colSpan={colSpan} error={null} />}
                    placeholderComponent={<TrPlaceholder colSpan={colSpan}>No documents</TrPlaceholder>}
                  >
                    {allInsuredDocuments =>
                      allInsuredDocuments?.map((document, i) => (
                        <InsuredDocumentRow key={`document-${i}`} insuredDocument={document} />
                      ))
                    }
                  </RenderData>
                </tbody>
              </Table>
            </BoxContainer>
          </TabPage>
        </Route>
        <Route
          path={`${path}/:documentRelationshipId`}
          render={({ match }) => <DocumentVersions documentRelationshipId={match.params.documentRelationshipId} />}
        />
      </Switch>
    </>
  );
}
