import React from 'react';
import { CustomInput } from 'reactstrap';
import { useDisableProductDocument } from '../hooks';
import { HideFromTypes, useActiveAccount } from '../../../shared/activeAccount';
import { HideFromRolesAndTypes } from '../../../shared/activeAccount/components';

export default function DisableDocumentSwitch({ policyId, productDocumentId, disabled }) {
  const { changeDocumentExclution } = useDisableProductDocument({
    productDocumentId,
    policyId
  });
  const {
    TYPES: { CLIENT, CLAIMS_ADJUSTER },
    ROLES: { EXPOSURE_MANAGER, OBSERVER }
  } = useActiveAccount();

  return (
    <HideFromTypes types={[CLIENT]}>
      <HideFromRolesAndTypes types={[CLAIMS_ADJUSTER]} roles={[EXPOSURE_MANAGER, OBSERVER]}>
        <div className="d-flex align-items-center justify-content-end" style={{ marginRight: '-7px' }}>
          <label
            className={`d-inline-flex mb-0 cursor-pointer pr-2 ${disabled ? 'text-muted' : 'text-info'}`}
            htmlFor={`enableDocumentSwitch-${productDocumentId}`}
          >
            {disabled ? 'Disabled' : 'Active'}
          </label>
          <CustomInput
            type="switch"
            id={`enableDocumentSwitch-${productDocumentId}`}
            name="enableDocumentSwitch"
            checked={!disabled}
            className="d-inline-flex"
            onChange={() => changeDocumentExclution(!disabled)}
          />
        </div>
      </HideFromRolesAndTypes>
    </HideFromTypes>
  );
}
