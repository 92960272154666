import * as yup from 'yup';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { GET_EXPOSURE_ELECTION_COVERAGE_HISTORY } from '../../coverage/hooks/useElectionHistoryCoverage';
import useContractTypeOptions from './useExposureContractTypeOptions';

export const UPDATE_CONTRACT_TYPE = gql`
  mutation UpdateContractType($electionId: ID!, $contractType: ContractType!) {
    updateContractType(exposureElectionId: $electionId, contractType: $contractType) {
      id
      contractType
    }
  }
`;

export default function useExposureUpdateContractType(election, toggle) {
  const [update, { data, loading, error, reset }] = useMutation(UPDATE_CONTRACT_TYPE, {
    onCompleted: toggle,
    onError: () => {},
    refetchQueries: [{ query: GET_EXPOSURE_ELECTION_COVERAGE_HISTORY, variables: { id: election.id } }]
  });

  const handleSubmit = ({ contractType }) => {
    update({
      variables: {
        electionId: election.id,
        contractType: contractType.value
      }
    });
  };

  const { contractTypeOptions, loading: optionsLoading } = useContractTypeOptions();

  const initialValues = {
    contractType: contractTypeOptions.find(({ value }) => value === election.contractType)
  };

  const validationSchema = yup.object({
    contractType: yup.object().required('Required')
  });

  return {
    initialValues,
    validationSchema,
    handleSubmit,
    loading: loading || optionsLoading,
    data,
    error,
    reset
  };
}
