import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@oforce/global-components';

import { FullPage, MessageBox, LargeIcon, Title, Message } from '../../components/message-box';

export default function AccessDeniedPage() {
  const history = useHistory();

  return (
    <FullPage>
      <MessageBox className="align-self-center justify-self-center">
        <LargeIcon icon="exclamation-triangle" color="warning" />
        <Title>Access Denied</Title>
        <Message>Oops, it looks like you don't have permission to access this page.</Message>
        <Button color="link" iconAfter={['far', 'long-arrow-right']} onClick={() => history.push(`/`)} className="mt-3">
          Take me home
        </Button>
      </MessageBox>
    </FullPage>
  );
}
