import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Render } from '..';

const StyledIcon = styled(FontAwesomeIcon)``;

const StyledBoxItem = styled.div`
  border-bottom: 2px solid ${props => props.theme.gray200};
  background: ${props => props.theme.white};
  ${StyledIcon} {
    color: ${props => props.theme.gray500};
  }
  &:hover {
    background: ${props =>
      typeof props.onClick === 'function'
        ? props.theme.gray200
        : props.theme.white}; // transparentize(0.95, props.theme.blue)
    cursor: ${props => (typeof props.onClick === 'function' ? 'pointer' : 'default')};
    ${StyledIcon} {
      color: ${props => props.theme.gray600};
    }
  }
  &:active {
    background: ${props => (typeof props.onClick === 'function' ? props.theme.gray300 : props.theme.white)};
  }
  &:last-of-type {
    border-bottom: none;
  }
`;

export default function BoxItem({
  size = 'md',
  tight = false,
  justifyContent = 'between',
  onClick,
  children,
  className = '',
  icon = ['fal', 'angle-right']
}) {
  const tightClasses = cn({ 'px-2': tight, 'px-3': !tight });

  const sizeClasses = cn({
    'py-3': size === 'lg',
    'py-2': size === 'md',
    'py-1': size === 'sm'
  });

  const iconSpanClasses = cn('align-self-center', {
    'pl-3 pr-2': size === 'md',
    'pl-2 pr-1': size === 'sm'
  });

  const iconClasses = cn({
    'fs-xxl': size === 'md',
    'fs-xl': size === 'sm'
  });

  const centeredClasses = cn(`justify-content-${justifyContent}`);

  return (
    <StyledBoxItem
      className={cn('d-flex align-items-start', centeredClasses, tightClasses, sizeClasses, className)}
      onClick={onClick}
    >
      {children}
      <Render if={!!onClick}>
        <span className={iconSpanClasses}>
          <StyledIcon icon={icon} className={iconClasses} />
        </span>
      </Render>
    </StyledBoxItem>
  );
}

BoxItem.propTypes = {
  // This determines the padding on the top and bottom of the box item
  size: PropTypes.oneOf(['sm', 'md', 'lg']),

  // This determines the padding on the left and right of the box item
  tight: PropTypes.bool,

  // This deteremines the justify content of the Box Item
  justifyContent: PropTypes.oneOf(['center', 'between', 'start', 'end']),

  // This determines the padding on the left and right of the box item
  centered: PropTypes.bool
};
