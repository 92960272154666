import React from 'react';
import { Switch, Route, useHistory, useRouteMatch } from 'react-router-dom';
import { Button, DatePickerField, Modal, Render, useToggle } from '@oforce/global-components';
import { TabPage } from '../../components/tabs';
import { MappableDocumentFields } from '../../views';
import { useDocumentDownloadJobContext } from '../../context';
import { BoxContainer, GraphQLErrors } from '../../components';
import { ShowForTypes, useActiveAccount } from '../../shared/activeAccount';
import { usePolicyDocuments } from './hooks';
import { BulkEmail, UploadUpdate } from './containers';
import { DocumentsTable } from './components';
import { HideFromRolesAndTypes } from '../../shared/activeAccount/components';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { SERVER_SIDE_DATE_FORMAT } from '../../utils/dates';

export const DOCUMENT_TYPE = {
  GENERAL: 'GENERAL',
  APPLICATION: 'APPLICATION',
  CERTIFICATE_OF_INSURANCE: 'CERTIFICATE_OF_INSURANCE'
};

export default function PolicyDocuments({ policy }) {
  const [showMappableFields, toggleMappableFields] = useToggle();
  const [showModal, toggleModal] = useToggle();
  const policyId = policy?.id;

  const {
    TYPES: { CARRIER, BROKER, CLAIMS_ADJUSTER },
    ROLES: { EXPOSURE_MANAGER, OBSERVER },
    isAdmin
  } = useActiveAccount();
  const { path, url } = useRouteMatch();
  const history = useHistory();

  const {
    documents,
    loading: documentsLoading,
    error: documentsError,
    hasCertificatesOfInsurance
  } = usePolicyDocuments({
    policyId: policy?.id
  });

  const {
    startDownloadAllCertificatesOfInsuranceJob,
    jobId,
    loading,
    error: documentDownloadJobError
  } = useDocumentDownloadJobContext();

  const shouldShowDocumentUploadButton = policy => {
    if (policy?.archived) return false;
    else if (policy?.expirationDate !== null && new Date(policy?.expirationDate) <= new Date()) return false;
    else if (isAdmin) return true;
    else return !policy?.apiOnlyExposureUpdates;
  };

  const handleSubmit = ({ activeOn }) => {
    startDownloadAllCertificatesOfInsuranceJob({
      policyId: policy?.id,
      activeOn: activeOn.format(SERVER_SIDE_DATE_FORMAT)
    });
    toggleModal();
  };

  return (
    <Switch>
      <Route exact path={path}>
        <TabPage
          title="Documents"
          buttons={
            <ShowForTypes types={[CARRIER, BROKER]}>
              <HideFromRolesAndTypes types={[CLAIMS_ADJUSTER]} roles={[EXPOSURE_MANAGER, OBSERVER]}>
                <Button
                  color="info"
                  outline
                  className="ml-3"
                  icon={['fas', 'envelope']}
                  onClick={() => history.push(`${url}/email`)}
                >
                  Send Bulk Email
                </Button>
                <Button
                  color="info"
                  outline
                  className="ml-3"
                  icon={['far', 'info-circle']}
                  onClick={toggleMappableFields}
                >
                  View Mappable Fields
                </Button>
                <Render if={shouldShowDocumentUploadButton(policy)}>
                  <Button
                    color="info"
                    className="ml-3"
                    icon={['far', 'file-upload']}
                    onClick={() => history.push(`${url}/upload`)}
                  >
                    Upload Document
                  </Button>
                </Render>
              </HideFromRolesAndTypes>
            </ShowForTypes>
          }
        >
          <BoxContainer className="mb-3">
            <Render if={!!documentDownloadJobError}>
              <div className="p-3">
                <GraphQLErrors error={documentDownloadJobError} />
              </div>
            </Render>
            <DocumentsTable policy={policy} documents={documents} loading={documentsLoading} error={documentsError} />
          </BoxContainer>

          <div className="d-flex justify-content-end">
            <Button
              color="link"
              size="sm"
              icon={['far', 'arrow-to-bottom']}
              loading={loading}
              loadingText="Preparing Documents..."
              disabled={!!jobId || !hasCertificatesOfInsurance}
              onClick={toggleModal}
            >
              Download All Certificates of Insurance
            </Button>
          </div>

          <MappableDocumentFields open={showMappableFields} toggle={toggleMappableFields} policyId={policy?.id} />

          <Formik initialValues={{ activeOn: moment() }} onSubmit={handleSubmit}>
            {formik => (
              <Modal
                isOpen={showModal}
                toggle={toggleModal}
                onSubmit={formik.handleSubmit}
                title={'Download All Certificates of Insurance'}
                submitButtonProps={{ loadingText: 'Downloading...' }}
                submitButtonText="Download"
                cancelButtonText="Cancel"
              >
                <Form>
                  <DatePickerField fullWidth name="activeOn" label="Active On" format="MM/DD/YYYY" />
                </Form>
              </Modal>
            )}
          </Formik>
        </TabPage>
      </Route>

      <Route path={`${path}/email`}>
        <BulkEmail policyId={policyId} redirectUrl={`/policies/${policyId}/documents`} />
      </Route>

      <Route path={`${path}/upload`}>
        <UploadUpdate policyId={policyId} />
      </Route>

      <Route path={`${path}/:documentId/update`}>
        <UploadUpdate policyId={policyId} documents={documents} />
      </Route>
    </Switch>
  );
}
