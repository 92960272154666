import { gql, useMutation } from '@apollo/client';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { VALIDATED_ROW_FIELDS } from '../model/constants';

const VALIDATE_ROWS = gql`
  mutation ValidateRows($importRows: [Json]!, $policyId: ID!) {
    validateExposureImportRows(importRows: $importRows, policyId: $policyId) {
      validatedRows {
        ...validatedRowFields
      }
      allValid
      invalidRowIds
    }
  }
  ${VALIDATED_ROW_FIELDS}
`;

export default function useValidation({ rows = [], policy, onCompleted = () => {}, onError = () => {} }) {
  const { id: policyId } = policy;
  const [validations, setValidations] = useState(null);

  const [validateRows, { loading, error }] = useMutation(VALIDATE_ROWS, {
    onCompleted: ({ validateExposureImportRows: data }) => {
      setValidations(data);
      onCompleted && onCompleted(data);
    },
    onError
  });

  useEffect(() => {
    if (!validations && rows.length > 0) {
      const json = rows.map(row => JSON.stringify(row));
      validateRows({ variables: { importRows: json, policyId } });
    }
  }, [validations, rows, policyId, validateRows]);

  const updateValidations = incomingValidatedRow => {
    const { rowId, allBrokenFields } = incomingValidatedRow;
    const { validatedRows, invalidRowIds } = validations;

    const oldValidations = validatedRows?.filter(row => row?.rowId !== rowId);
    const oldRowIds = invalidRowIds?.filter(id => id !== rowId);

    if (allBrokenFields?.length === 0 && oldRowIds?.length === 0) {
      const attrs = {
        allValid: true,
        validatedRows: [...oldValidations, incomingValidatedRow],
        invalidRowIds: oldRowIds
      };
      setValidations(attrs);
    } else if (allBrokenFields?.length === 0 && oldRowIds?.length > 0) {
      const attrs = {
        allValid: false,
        validatedRows: [...oldValidations, incomingValidatedRow],
        invalidRowIds: oldRowIds
      };
      setValidations(attrs);
    } else {
      const attrs = {
        allValid: false,
        validatedRows: [...oldValidations, incomingValidatedRow],
        invalidRowIds: [...oldRowIds, rowId]
      };
      setValidations(attrs);
    }
  };

  const validatedRows = validations?.validatedRows || [];

  return {
    validatedRows: _.sortBy(validatedRows, ['index']),
    allValid: validations?.allValid,
    invalidRowIds: validations?.invalidRowIds || [],
    validateRows,
    updateValidations,
    loading: loading || !validations,
    error: error
  };
}
