import React from 'react';
import { SwitchField, InputField, FieldMessage, ReactSelectField, useToggle } from '@oforce/global-components';

import { Render, FormSection } from '../../../components';
import { SNAPSHOT_CUSTOM_DAY } from '../../product/utils';
import { Tooltip } from 'reactstrap';

export default function ProductInvoicingFormFields({ formik, formOptions }) {
  const [showDisabledTooltip, toggleDisabledTooltip] = useToggle();
  const pricingRateIsWeekly = formik?.values?.pricingRateType?.value === 'WEEKLY';
  const pricingIsExternallyFactored = formik?.values?.pricingIsExternallyFactored;
  const automateInvoiceGeneration = formik?.values?.automateInvoiceGeneration;
  const onePremiumRequired = formik?.values?.onePremiumRequired;
  const backdate = formik?.values?.backdate;

  const tooltipMessage = pricingRateIsWeekly
    ? 'Cannot auto generate if pricing rate is weekly.'
    : pricingIsExternallyFactored
    ? 'Not available with api-only factored pricing option.'
    : null;

  return (
    <FormSection title="Product Invoicing">
      <ReactSelectField
        id="billingRule"
        name="billingRule"
        label="Billing Rule"
        options={formOptions?.billingRule?.options}
        placeholder="Select Billing Rule..."
        required
      />
      <Render if={formik.values?.billingRule?.value === SNAPSHOT_CUSTOM_DAY}>
        <InputField type="number" name="snapshotCustomDay" label="Snapshot Custom Day" />
      </Render>

      <div id="automatic-invoice-generation-switch-div" className="mb-3">
        <Render if={formik.values?.billingRule?.value !== 'CLASSIC'}>
          <SwitchField
            id="enable-one-premium-required-switch"
            className="mb-3"
            label="One Premium Required"
            name="onePremiumRequired"
            size="sm"
            switchLabel={onePremiumRequired ? 'Enabled' : 'Disabled'}
          />
        </Render>
        <SwitchField
          id="automatic-invoice-generation-switch"
          label="Automatic Invoice Generation"
          name="automateInvoiceGeneration"
          optional
          // if product pricing rate type is weekly,
          // or product is factored externally (meaning the automation of the invoice generation is done outside of IC Insure)
          // then we disable this field in the form
          disabled={pricingRateIsWeekly || pricingIsExternallyFactored}
          afterMessage={tooltipMessage}
          message={tooltipMessage}
          size="sm"
          switchLabel={automateInvoiceGeneration ? 'On' : 'Off'}
        />
      </div>
      <Tooltip
        disabled={!tooltipMessage}
        target={'automatic-invoice-generation-switch-div'}
        placement="left-start"
        isOpen={showDisabledTooltip}
        toggle={toggleDisabledTooltip}
      >
        {tooltipMessage}
      </Tooltip>
      <div id="backdate-div">
        <SwitchField
          id="backdate"
          label="Backdate Coverage"
          name="backdate"
          optional
          size="sm"
          switchLabel={backdate ? 'On' : 'Off'}
        />
      </div>
      <Render if={!pricingRateIsWeekly}>
        <ReactSelectField
          className="mb-0"
          name="invoicingSchedule"
          label="Collect Payment for"
          required
          options={formOptions?.invoicingSchedule?.options}
        />
        <FieldMessage className="fs-sm text-muted">Only applicable when auto generating invoices.</FieldMessage>
        <InputField
          className="mt-3"
          type="number"
          name="invoiceDueDay"
          label="Invoice Due Day"
          messageAfter="Only applicable when auto generating invoices."
        />

        <InputField
          name="invoiceGenerationDay"
          label="Invoice Generation Day"
          optional
          placeholder="0"
          messageAfter="This is the day each month the invoice will generate. Only applicable when auto generating invoices."
        />
      </Render>
    </FormSection>
  );
}
