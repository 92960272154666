import React from 'react';
import { Badge } from '@oforce/global-components';
import { INVOICE_STATUSES } from '../constants';

export default function InvoiceStatusBadge({ invoice, ...rest }) {
  const { COMMITTED, OPENED, VOIDED } = INVOICE_STATUSES;

  const statusBadgeColor = {
    [COMMITTED]: 'success',
    [OPENED]: 'info',
    [VOIDED]: 'danger'
  }[invoice?.status];

  const statusBadgeText = {
    [COMMITTED]: 'Active',
    [OPENED]: 'Processing',
    [VOIDED]: 'Voided'
  }[invoice?.status];

  return (
    <Badge color={statusBadgeColor} {...rest}>
      {statusBadgeText}
    </Badge>
  );
}
