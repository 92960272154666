import { useInputValues, useSubmitValues, useUiValues, useYupValidation } from './support';

export default function useParseCoverageParameters() {
  const { prepareParametersForInputs } = useInputValues();
  const { prepareParameterValueForUi } = useUiValues();
  const { coverageParametersYupValidation } = useYupValidation();
  const { normalizeParameterValuesForSubmit } = useSubmitValues();

  return {
    prepareParametersForInputs,
    prepareParameterValueForUi,
    normalizeParameterValuesForSubmit,
    coverageParametersYupValidation
  };
}
