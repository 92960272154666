import 'codemirror-mode-elixir';

import generate from './generator';

const snippet = {
  language: 'Elixir',
  codeMirrorMode: 'elixir',
  name: 'ExUnit Fixture',
  options: [],
  generate,
};

export default snippet;
