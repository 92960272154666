import React from 'react';
import { Badge } from '@oforce/global-components';

export default function PendingElectionBadge({ pendingElection }) {
  const { claimed, errors, archived } = pendingElection;
  const hasErrors = errors?.length > 0;

  const text = () => {
    if (archived) return 'Archived';
    if (claimed) return 'Claimed';
    if (hasErrors) return 'Pending';

    return 'Ready';
  };

  const color = () => {
    if (archived) return '';
    if (claimed) return 'info';
    if (hasErrors) return 'warning';

    return 'success';
  };

  return (
    <Badge size="sm" color={color()}>
      {text()}
    </Badge>
  );
}
