import { Td, Tr } from '@oforce/global-components';
import { toSentenceCase } from 'js-convert-case';
import { ContactInfoCell, NameCell } from '../../../../components/tables';
import { backendNameToLabel, formatDate } from '../../../../utils/formatting';

import { ExposureElectionStatusBadge } from '../../../exposureElections/components';
import RejectApproveButtons from './RejectApproveButtons';

const checkDate = value => {
  const date = formatDate(value);
  if (date === 'Invalid date' || date === '') return value;
  return date;
};

function castComplianceSummary({ compliance }) {
  const { violations } = compliance;

  return violations
    .filter(x => x.status === 'OPEN')
    .map((violation, i) => {
      const field = toSentenceCase(backendNameToLabel(violation.field));
      const value = violation?.value ? checkDate(violation?.value) : '';

      return (
        <span key={`violation-str-${i}`}>
          {field}
          {value && <span className="font-italic text-muted">{` (${value})`}</span>} in violation
          <br></br>
        </span>
      );
    });
}

export default function PendingApprovalRow({ election }) {
  if (!election) return null;
  return (
    <Tr key={election?.id}>
      <Td>
        <ExposureElectionStatusBadge election={election} />
      </Td>
      <Td>
        <NameCell insured={election?.insured} linkAddress={`/insureds/${election?.insured?.id}`} />
      </Td>
      <Td>
        <ContactInfoCell
          breakLines={true}
          email={election?.insured?.email}
          mobilePhone={election?.insured?.mobilePhone}
        />
      </Td>
      <Td>{election?.policy?.name}</Td>
      {election && election?.compliance && <Td>{castComplianceSummary(election)}</Td>}
      <Td>
        <RejectApproveButtons election={election} />
      </Td>
    </Tr>
  );
}
