import React from 'react';
import { FullPage, MessageBox, LargeIcon, Title, Message } from '../../components/message-box';

export default function AuthenticatingPage() {
  return (
    <FullPage className="bg-gray-100">
      <MessageBox className="align-self-center justify-self-center">
        <LargeIcon icon={['far', 'spinner-third']} color="info" spin />
        <Title className="mb-0">Authenticating</Title>
        <Message>Please wait...</Message>
      </MessageBox>
    </FullPage>
  );
}
