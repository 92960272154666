import React from 'react';
import { Row, Col } from 'reactstrap';
import { InputField, ReactSelectField } from '@oforce/global-components';

export default function CompanyDetailsStep({ formOptions, focusRef }) {
  return (
    <>
      <InputField name="name" label="Company Name" required innerRef={focusRef} />
      <InputField name="address" label="Address" />
      <Row form>
        <Col>
          <InputField name="city" label="City" />
        </Col>

        <Col>
          <ReactSelectField name="state" label="State" options={formOptions?.state?.options} />
        </Col>
        <Col>
          <InputField name="zip" label="Zip Code" />
        </Col>
      </Row>
      <InputField name="phone" label="Phone Number" />
      <InputField name="email" label="Email" />
    </>
  );
}
