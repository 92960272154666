import React, { useState, useEffect } from 'react';
import { DatePickerField, InputDropzoneField, InputField, ReactSelectField } from '@oforce/global-components';
import { FormSection } from '../../../components';
import { defaultDocumentName } from '../../productDocuments';

export default function Details({ formik, formOptions }) {
  const [documentName, setDocumentName] = useState(null);

  useEffect(() => {
    if (documentName) {
      return;
    }

    if (formik?.values?.document?.length === 1 && !formik?.values?.name) {
      const name = defaultDocumentName(formik?.values?.document[0]?.name);
      setDocumentName(name);
      formik.setFieldValue('name', name);
    }
  }, [formik, documentName, setDocumentName]);

  return (
    <FormSection title="Document Details">
      <InputDropzoneField label="Document (pdf)" name="document" id="document" accept={['.pdf']} required />

      <InputField required label="Name" name="name" placeholder="Enter a document name" />
      <DatePickerField
        required
        fullWidth
        name="effectiveDate"
        label="Effective Date"
        format="MM/DD/YYYY"
        allowClear={false}
        inputReadOnly={true}
        messageAfterProps={{ icon: ['far', 'info-circle'] }}
      />
      <DatePickerField fullWidth name="expirationDate" label="Expiration Date" format="MM/DD/YYYY" allowClear={true} />
      <ReactSelectField
        required
        label="Type"
        name="type"
        options={formOptions?.documentType.options}
        placeholder="Select..."
      />
    </FormSection>
  );
}
