import { gql, useMutation } from '@apollo/client';
import { GET_POLICY } from './usePolicy';

const DELETE_POLICY = gql`
  mutation deletePolicy($policyId: ID!) {
    deletePolicy(policyId: $policyId) {
      id
    }
  }
`;

export default function useDeletePolicy({ policy, onCompleted }) {
  const [deletePolicy, { loading, error }] = useMutation(DELETE_POLICY, {
    variables: { policyId: policy?.id },
    onCompleted,
    refetchQueries: [{ query: GET_POLICY, variables: { id: policy?.id } }],
    onError: () => {}
  });

  return {
    deletePolicy,
    loading,
    error
  };
}
