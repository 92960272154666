import React from 'react';
import styled from 'styled-components';

const StyledField = styled.div.attrs(({ className, hasChildren }) => ({
  className: `${className} ${
    hasChildren ? '' : 'text-muted font-italic font-size-xxs'
  }`
}))``;

export default function ReadOnlyField({
  children,
  placeholderText,
  className,
  ...rest
}) {
  return (
    <StyledField hasChildren={children} {...rest}>
      {children || placeholderText}
    </StyledField>
  );
}
