import { Render } from '@oforce/global-components';
import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import ExposureDetailsMultiStepForm from './exposureDetails/ExposureDetailsMultiStepForm';
import CoveragesSelectStep from '../components/steps/selectCoveragesStep/CoveragesSelectStep';

export default function DynamicCoveragesForm({
  onCancel,
  vehicle,
  insured,
  onSubmit,
  error,
  loading,
  title,
  onBack,
  policyInsuranceTypes,
  policy
}) {
  const [coverageComplete, setCoverageComplete] = useState(false);
  const [coverages, setCoverages] = useState([]);

  return (
    <>
      <Row className="justify-content-center">
        <Col xs="12" lg="10" xl="8" style={{ maxWidth: '700px' }}>
          <Render if={!coverageComplete}>
            <CoveragesSelectStep
              selectedCoverages={coverages}
              onCancel={onCancel}
              onSubmit={coverages => {
                setCoverages(coverages);
                setCoverageComplete(true);
              }}
              policyInsuranceTypes={policyInsuranceTypes}
              insured={insured}
              vehicle={vehicle}
              title={title}
              onBack={onBack}
              policy={policy}
            />
          </Render>
        </Col>
      </Row>
      <Render if={coverageComplete}>
        <ExposureDetailsMultiStepForm
          insured={insured}
          vehicle={vehicle}
          onBack={() => setCoverageComplete(false)}
          coverages={coverages}
          onSubmit={onSubmit}
          loading={loading}
          error={error}
          onCancel={onCancel}
          title={title}
        />
      </Render>
    </>
  );
}
