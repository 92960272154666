import React from 'react';
import { Button } from '@oforce/global-components';

import { AddOnsTable } from '.';
import { TabPage } from '../../../components/tabs';
import { HideFromRoles, ShowForTypes, useActiveAccount } from '../../../shared/activeAccount';
import { BoxContainer } from '../../../components';

export default function AddOns({ addOns, loading, error, toggleEdit, toggleRemove, toggleCreate, pricingOptions }) {
  const {
    TYPES: { CARRIER, BROKER },
    ROLES: { EXPOSURE_MANAGER }
  } = useActiveAccount();

  return (
    <TabPage
      title="Add-Ons"
      showBackButton
      buttons={
        <ShowForTypes types={[CARRIER, BROKER]}>
          <HideFromRoles roles={[EXPOSURE_MANAGER]}>
            <NewAddOnButton onClick={toggleCreate} />
          </HideFromRoles>
        </ShowForTypes>
      }
    >
      <BoxContainer>
        <AddOnsTable
          addOns={addOns}
          loading={loading}
          error={error}
          toggleEdit={toggleEdit}
          toggleRemove={toggleRemove}
          pricingOptions={pricingOptions}
        />
      </BoxContainer>
    </TabPage>
  );
}

const NewAddOnButton = ({ onClick }) => (
  <Button color="info" className="ml-2" icon={['far', 'plus']} onClick={onClick}>
    Create
  </Button>
);
