import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';

export const GET_TRANSACTION_BREAKDOWN = gql`
  query getTransaction($id: ID!) {
    transaction(id: $id) {
      label
      factorValue
      factorLabel
      premium
      premiumSource {
        value
        proratedValue
        rate
        rateMode
        isPercentageFactor
      }
      addOnsSource {
        label
        value
        proratedValue
      }
      feeSource {
        name
        value
        proratedValue
      }
    }
  }
`;

export default function useTransactionBreakdown({ transaction }) {
  const { data, loading, error } = useQuery(GET_TRANSACTION_BREAKDOWN, {
    variables: { id: transaction?.id },
    fetchPolicy: 'cache-first'
  });

  const normalizeRate = ({ name, label, value, proratedValue }) => {
    const normalizedLabel = label || name;
    const normalizedValue = proratedValue || value;

    return { label: normalizedLabel, value: normalizedValue };
  };

  return {
    factorValue: data?.transaction?.factorValue,
    factorLabel: data?.transaction?.factorLabel,
    isPercentageFactor: data?.transaction?.premiumSource?.isPercentageFactor,
    rate: data?.transaction?.premiumSource?.rate,
    rateMode: data?.transaction?.premiumSource?.rateMode,
    addOns: data?.transaction?.addOnsSource?.map(x => normalizeRate(x)) || [],
    premium: normalizeRate({ ...data?.transaction?.premiumSource, label: data?.transaction?.label } || {}),
    fees: data?.transaction?.feeSource?.map(x => normalizeRate(x)) || [],
    loading,
    error
  };
}
