import React from 'react';
import _ from 'lodash';
import { Timeline } from 'react-event-timeline';
import TimelineLogEvent from './TimelineLogEvent';
import { theme } from '@oforce/global-styles';
const { gray700 } = theme;

export default function CoverageLogsTimeline({ logs }) {
  const sortedLogs = logs ? _.sortBy(logs, ['eventDate']).reverse() : [];

  return (
    <Timeline lineColor={gray700}>
      {sortedLogs.map((log, i) => (
        <div key={`coverage-group-timeline-log-${log?.id}-${i}`}>
          <TimelineLogEvent {...log} />
        </div>
      ))}
    </Timeline>
  );
}
