import { pascalCase } from 'pascal-case';
import { parse as parseUri } from 'uri-js';
import { addLeftWhitespace } from 'graphiql-code-exporter/lib/utils';

function makeTest(path) {
  return ({ name }) => `
    test "${name} test", ctx do
        %{conn: conn} = ctx

        variables = %{}

        conn
        |> post("${path}", %{query: @document, variables: variables, operationName: "${name}"})
        |> json_response(200)
        |> get_in(["data", "${name}"])
    end`;
}

export default function generate({ operationDataList, serverUrl, context }) {
  const { path } = parseUri(serverUrl);

  const document = operationDataList
    .map((x) => x.query)
    .join('\n\n')
    .trim();

  const tests = operationDataList.map(makeTest(path)).join('\n');

  return `
defmodule Oforce.${pascalCase(context.apiName)}Test do
    import Plug.Test
    import Insurance.Factory

    use InsuranceWeb.ConnCase
    
    setup %{conn: conn, current_user: current_user} do
        %{conn: init_test_session(conn, test_user: current_user)}
    end

    @document """
${addLeftWhitespace(document, 6)}
    """
${tests}
end
`;
}
