import { useCallback } from 'react';

export default function useCursorPagination(fetchQuery, { count }) {
  const loadNextPage = useCallback(
    ({ hasNextPage, endCursor }, variables = {}) => {
      if (hasNextPage) {
        fetchQuery({
          variables: { ...variables, first: count, after: endCursor }
        });
      }
    },
    [fetchQuery, count]
  );

  const loadPreviousPage = useCallback(
    ({ hasPreviousPage, startCursor }, variables = {}) => {
      if (hasPreviousPage) {
        fetchQuery({
          variables: {
            ...variables,
            last: startCursor ? count : null,
            before: startCursor,
            first: startCursor ? null : count
          }
        });
      }
    },
    [fetchQuery, count]
  );

  return { loadNextPage, loadPreviousPage };
}
