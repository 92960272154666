import { BoxContainer, FormSection, PagerButtons } from '../../../components';
import { useProductPolicies } from '../hooks';
import { SyncPoliciesTable } from './';

export default function SelectPoliciesStep({ allPolicyIds, productId, formik }) {
  const { policies, loading, error, paginationProps } = useProductPolicies({
    pageSize: 10,
    filters: { productId, expired: false }
  });
  const { pageInfo, loadNextPage, loadPreviousPage } = paginationProps;
  const { hasNextPage, hasPreviousPage } = pageInfo;

  return (
    <FormSection title="Select Policies to Sync Product Pricing Options to">
      <BoxContainer>
        <SyncPoliciesTable
          allPolicyIds={allPolicyIds}
          policies={policies}
          loading={loading}
          error={error}
          formik={formik}
        />
      </BoxContainer>
      <PagerButtons
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        loadNextPage={loadNextPage}
        loadPreviousPage={loadPreviousPage}
        loading={loading}
      />
    </FormSection>
  );
}
