import React from 'react';
import { Button } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BoxItem, BoxItemTitle, Render } from '../../../../../components';

export default function CoverageBoxItem({ coverage, onRemove, onUpdate }) {
  const addOns = coverage?.addOns?.concat(coverage?.requiredAddOns);
  return (
    <BoxItem className="border rounded border-gray-400 mb-3 d-flex justify-content-between">
      <div className="d-flex flex-column">
        <BoxItemTitle title={coverage?.policy?.label} subtitle={coverage?.pricingOption?.label} />
        <Render if={addOns?.length > 0}>
          Addons: {addOns?.reduce((acc, addOn) => acc + addOn.label + ', ', '').slice(0, -2)}
        </Render>
      </div>
      <div>
        <Button color="link" type="button" onClick={onUpdate}>
          <FontAwesomeIcon type="button" icon={['far', 'pencil']} className="ml-2" />
        </Button>
        <Button color="link" type="button" onClick={onRemove}>
          <FontAwesomeIcon icon={['far', 'trash-alt']} className="ml-2" />
        </Button>
      </div>
    </BoxItem>
  );
}
