import { gql, useMutation } from '@apollo/client';

import { GET_PRODUCT_PRICINGS } from '../../productPricing/hooks/useProductPricings';
import { GET_PRODUCT_FEES } from './useFees';

const REMOVE_PRODUCT_FEE = gql`
  mutation RemoveProductFee($input: RemoveProductFeeInput!) {
    removeProductFee(input: $input) {
      id
    }
  }
`;

export default function useRemoveFee({ productId, feeId, onCompleted = () => {}, onError = () => {} }) {
  const [remove, { loading, error }] = useMutation(REMOVE_PRODUCT_FEE, {
    variables: { input: { productId, feeId } },
    onCompleted,
    onError,
    refetchQueries: [
      { query: GET_PRODUCT_FEES, variables: { id: productId } },
      { query: GET_PRODUCT_PRICINGS, variables: { id: productId } }
    ]
  });

  return {
    removeFee: remove,
    loading,
    error
  };
}
