import { gql } from '@apollo/client';
import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';

export const GET_PRODUCTS = gql`
  query products($filter: AllProductsFilter) {
    allProducts(filter: $filter) {
      id
      name
      externalId
      retailBroker {
        id
        name
      }
      insuranceCarrier {
        name
        id
      }
    }
  }
`;

export default function useProducts({ filter }) {
  const [getProducts, { data, loading, error }] = useLazyQuery(GET_PRODUCTS, {
    variables: { filter },
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  return {
    products: data?.allProducts,
    loading,
    error
  };
}
