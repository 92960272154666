import React from 'react';
import { useHistory } from 'react-router-dom';
import { TrLoader, TrPlaceholder } from '@oforce/global-components';
import { CellCoverageDates, InsuranceTypeBadge } from '../../exposureElections/components';
import { BoxContainer, RenderData, Table, Td, Tr, TrGraphQLErrors } from '../../../components';
import {
  statusBadgeColors,
  electionStatusString
} from '../../exposureElections/components/ExposureElectionStatusBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatPremium } from '../../../utils/formatting';
import { normalizeInsuredFullName } from '../../insured/utils';

export default function VehicleCoveragesTable({ coverages, loading, error }) {
  const history = useHistory();

  return (
    <BoxContainer className="px-3 py-2 mb-5">
      <Table>
        <thead>
          <tr>
            <th>Coverage</th>
            <th>Insured Name</th>
            <th>Unit Number</th>
            <th>Coverage Dates</th>
            <th>Client Name</th>
            <th>Premium</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <RenderData
            data={coverages}
            loading={loading}
            error={error}
            loadingComponent={<TrLoader colSpan={5} />}
            errorComponent={<TrGraphQLErrors colSpan={5} error={error} />}
            placeholderComponent={<TrPlaceholder colSpan={5}>None</TrPlaceholder>}
          >
            {coverages =>
              coverages.map(coverage => (
                <Tr
                  key={coverage.id}
                  onClick={() => history.push(`/insureds/${coverage?.insuredId}/elections/${coverage.id}`)}
                >
                  <Td>
                    <InsuranceTypeBadge
                      insuranceType={coverage?.policy?.insuranceType}
                      color={statusBadgeColors[electionStatusString(coverage)]}
                    />
                  </Td>
                  <Td>{normalizeInsuredFullName(coverage?.insured)}</Td>
                  <Td>{coverage?.vehicle?.unitNumber}</Td>
                  <CellCoverageDates election={coverage} />
                  <Td>{coverage?.policy?.client?.name}</Td>
                  <Td>{formatPremium(coverage)}</Td>
                  <Td className="cursor-pointer">
                    <FontAwesomeIcon className="ml-2" icon={['far', 'angle-right']} />
                  </Td>
                </Tr>
              ))
            }
          </RenderData>
        </tbody>
      </Table>
    </BoxContainer>
  );
}
