import React from 'react';
import { Helmet } from 'react-helmet';
import { Loader } from '@oforce/global-components';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';

import { MyProfileAccounts } from '../../views';
import { GraphQLErrors } from '../../components';
import { useCurrentUser } from '../../shared/currentUser';
import { PageHeader } from '../../components/page-components';
import { TabBar, TabLink, TabPane } from '../../components/tabs';

import { ProfileHeader } from './components';
import { Invitations } from './containers';

export { GET_UNCLAIMED_INVITATIONS } from './hooks/useUnclaimedInvitations';

export default function MyProfile() {
  const { path } = useRouteMatch();
  const currentUser = useCurrentUser();

  if (currentUser?.loading) return <Loader />;

  if (currentUser?.error) return <GraphQLErrors error={currentUser?.error} />;

  return (
    <>
      <Switch>
        <Route path={`${path}`}>
          <Helmet>
            <title>My Profile • IC Insure</title>
          </Helmet>
          <PageHeader withTabs>
            <ProfileHeader {...currentUser} />

            <TabBar className="px-5 border-bottom-2 border-gray-300">
              <TabLink to={`${path}/accounts`} icon={['far', 'users']}>
                Accounts
              </TabLink>
              <TabLink to={`${path}/invitations`} icon={['far', 'envelope-open-text']}>
                Invitations
              </TabLink>
            </TabBar>
          </PageHeader>
          <div className="bg-gray-200 h-100">
            <TabPane>
              <Switch>
                <Redirect exact from={`${path}`} to={`${path}/accounts`} />
                <Route path={`${path}/accounts`}>
                  <MyProfileAccounts />
                </Route>
                <Route path={`${path}/invitations`}>
                  <Invitations />
                </Route>
              </Switch>
            </TabPane>
          </div>
        </Route>
      </Switch>
    </>
  );
}
