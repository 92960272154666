import { gql, useQuery } from '@apollo/client';
import { backendNameToLabel } from '../../../utils/formatting';

export const POLICY_SEARCH_BY_OPTIONS = gql`
  query PolicySearchByOptions {
    policySearchByFields
  }
`;

export default function usePolicySearchByOptions() {
  const { loading, error, data } = useQuery(POLICY_SEARCH_BY_OPTIONS, {
    onCompleted: () => {},
    onError: () => {}
  });

  return {
    policySearchByOptions:
      data?.policySearchByFields?.map(field => ({
        label: backendNameToLabel(field),
        value: field
      })) || [],
    loading,
    error
  };
}
