import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';
import _ from 'lodash';

export const GET_DOCUMENTS = gql`
  query productDocuments($id: ID!) {
    product(id: $id) {
      id
      documents {
        id
        name
        filename
        url
        appliesToAllPricingOptions
        pricingOptionIds
        type
        stateSource
        appliesToStates
        excludeFromStates
        populateOnPending
        effectiveDate
        expirationDate
      }
    }
  }
`;

export default function useProductDocuments({ productId, onCompleted = () => {}, onError = () => {} }) {
  const { loading, error, data } = useQuery(GET_DOCUMENTS, {
    variables: { id: productId },
    onCompleted,
    onError
  });

  return { loading, error, documents: _.sortBy(data?.product?.documents, 'name') };
}
