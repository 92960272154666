import { gql, useSubscription } from '@apollo/client';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@oforce/global-components';
import { toast } from 'react-toastify';
import { useActiveAccount } from '../../../shared/activeAccount';
import { ToastContent } from '../../../components/react-toastify';

export const EXPIRED_POLICY_INVOICE_SUBSCRIPTION = gql`
  subscription expiredPolicyInvoiceNotifications($accountId: ID!) {
    change: expiredPolicyInvoiceNotifications(accountId: $accountId) {
      policyId
      message
    }
  }
`;

export default function useExpiredPolicyInvoiceNotifications() {
  const { id } = useActiveAccount();
  const history = useHistory();
  const [policyIds, setPolicyIds] = useState([]);

  useSubscription(EXPIRED_POLICY_INVOICE_SUBSCRIPTION, {
    variables: { accountId: id },
    skip: !id,
    onSubscriptionData({ subscriptionData: { data } }) {
      const {
        change: { policyId, message }
      } = data;

      if (!policyIds.includes(policyId)) {
        setPolicyIds([...policyIds, policyId]);

        toast(
          <ToastContent
            title="Expired Policy Invoice Notification"
            message={message}
            icon={['fas', 'check-circle']}
            titleProps={{ className: 'text-success' }}
          >
            <Button
              color="link"
              size="sm"
              icon={['far', 'arrow-to-bottom']}
              className="mr-2"
              onClick={() => history.push(`/policies/${policyId}/invoices`)}
            >
              View Policy
            </Button>
          </ToastContent>,
          {
            autoClose: 3000,
            progressClassName: 'bg-success',
            onClose: ({ policyId }) => setPolicyIds(policyIds.filter(id => id !== policyId))
          }
        );
      }
    }
  });
}
