import React from 'react';
import * as yup from 'yup';
import { Form, Formik } from 'formik';

import { BoxItem, FormSection, GraphQLErrors } from '../../../components';

import InputMultiEmailField from '../../../components/forms/InputMultiEmailField';
import { Loader, Render } from '@oforce/global-components';
import usePolicyNotificationGroupForm from '../hooks/usePolicyNotificationGroupForm';

export default function PolicyNotificationForm({ policy, notificationGroup, title, description }) {
  const {
    initialValues,
    handleSubmit,
    mutationError,
    mutationLoading,
    data,
    getError,
    getLoading
  } = usePolicyNotificationGroupForm({
    policy,
    notificationGroup: notificationGroup
  });

  if (getLoading && (!data || data?.length === 0)) return <Loader />;
  if (getError) return <GraphQLErrors error={getError} />;
  return (
    <Formik
      validateOnChange={false}
      initialValues={initialValues}
      validationSchema={yup.object().shape({
        emails: yup.array().of(yup.string().email('Must be a valid email'))
      })}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {formik => (
        <Form>
          <BoxItem justifyContent="start" size="lg">
            <div className="w-100">
              <FormSection title={title} subtitle={description} />

              <GraphQLErrors error={mutationError} />
              <fieldset style={{ maxWidth: '400px' }}>
                <InputMultiEmailField name="emails" formik={formik} />
              </fieldset>

              <div style={{ minHeight: 24 }} className="d-flex align-items-center justify-content-end w-100">
                <span>
                  <Render if={mutationLoading}>
                    <Loader size="1x" className="p-0 d-inline-flex mr-2" />
                    <span className="text-info fs-sm">Saving...</span>
                  </Render>
                </span>
              </div>
            </div>
          </BoxItem>
        </Form>
      )}
    </Formik>
  );
}
