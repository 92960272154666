import React from 'react';
import { Td, Tr } from '@oforce/global-components';

import {
  CellActions,
  CellOwnerContact,
  CellPricingOptionAndAddOns,
  CellContractType,
  CellCoverageDates,
  CellTotal,
  ExposureElectionStatusBadge
} from '../../../exposureElections/components';

import { useActiveAccount } from '../../../../shared/activeAccount';
import { FeatureFlag, HideFromRolesAndTypes } from '../../../../shared/activeAccount/components';
import { FLAGGED_FEATURES } from '../../../../shared/activeAccount/constants';

const { MASTER_SUB_RELATIONSHIP } = FLAGGED_FEATURES;

export default function InsuredCoverageTableRow({ coverage, policy, fetchNewestCoverages }) {
  const {
    ROLES: { OBSERVER },
    TYPES: { CLAIMS_ADJUSTER }
  } = useActiveAccount();

  return (
    <Tr className="bg-white">
      <Td>
        <ExposureElectionStatusBadge election={coverage} />
      </Td>
      <Td>
        <CellOwnerContact {...coverage} />
      </Td>
      <FeatureFlag feature={MASTER_SUB_RELATIONSHIP}>
        <Td>
          <CellContractType election={coverage} />
        </Td>
      </FeatureFlag>
      <Td>
        <CellPricingOptionAndAddOns {...coverage} />
      </Td>
      <CellTotal colSpan="1" election={coverage} />
      <CellCoverageDates election={coverage} />
      <HideFromRolesAndTypes roles={[OBSERVER]} types={[CLAIMS_ADJUSTER]}>
        <Td>
          <CellActions election={coverage} policy={policy} fetchNewestInsureds={fetchNewestCoverages} />
        </Td>
      </HideFromRolesAndTypes>
    </Tr>
  );
}
