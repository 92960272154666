import React from 'react';
import { toast } from 'react-toastify';
import { useMutation, gql } from '@apollo/client';
import { Button, useToggle } from '@oforce/global-components';
import { toHeaderCase } from 'js-convert-case';

import { BoxItem, BoxItemTitle } from '../../../components';
import { ToastContent } from '../../../components/react-toastify';

import CancelPolicyInvitation from './CancelPolicyInvitation';
import PolicyInvitationStatusBadge from './PolicyInvitationStatusBadge';
import { HideFromRoles, HideFromTypes, useActiveAccount } from '../../../shared/activeAccount';

const RESEND_POLICY_INVITATION = gql`
  mutation ResendPolicyInvitation($id: ID!) {
    resendPolicyInvitation(id: $id) {
      id
    }
  }
`;

export default function ActiveInvitation({ invitation, policyId }) {
  const [showRevokeInvite, toggleRevokeInvite] = useToggle();
  const {
    TYPES: { CLIENT },
    ROLES: { EXPOSURE_MANAGER }
  } = useActiveAccount();

  const [resendPolicyInvitation, { loading }] = useMutation(RESEND_POLICY_INVITATION, {
    variables: { id: invitation?.id },
    onCompleted: () =>
      toast(
        <ToastContent
          title="Success!"
          message="This invitation was resent."
          icon={['fas', 'check-circle']}
          titleProps={{ className: 'text-success' }}
        />,
        { autoClose: 3000, progressClassName: 'bg-success' }
      ),
    onError: error =>
      toast(
        <ToastContent
          title="Oops, this invitation was not able to be resent."
          message={error?.message}
          icon={['fas', 'check-times']}
          titleProps={{ className: 'text-danger' }}
        />,
        { autoClose: 3000, progressClassName: 'bg-danger' }
      )
  });

  return (
    <>
      <BoxItem>
        <div className="d-flex justify-content-start align-items-center">
          <BoxItemTitle title={invitation?.emailAddress} subtitle={toHeaderCase(invitation?.role)} />
          <PolicyInvitationStatusBadge status={invitation?.status} />
        </div>

        <HideFromTypes types={[CLIENT]}>
          <HideFromRoles roles={[EXPOSURE_MANAGER]}>
            <div className="d-flex flex-row mt-2">
              <Button color="secondary" size="sm" onClick={toggleRevokeInvite} className="mr-2">
                Revoke Invite
              </Button>
              <Button
                color="info"
                size="sm"
                onClick={resendPolicyInvitation}
                loading={loading}
                loadingText="Resending..."
              >
                Resend
              </Button>
            </div>
          </HideFromRoles>
        </HideFromTypes>
      </BoxItem>
      <HideFromTypes types={[CLIENT]}>
        <HideFromRoles roles={[EXPOSURE_MANAGER]}>
          <CancelPolicyInvitation
            isOpen={showRevokeInvite}
            toggle={toggleRevokeInvite}
            policyId={policyId}
            invitationId={invitation?.id}
          />
        </HideFromRoles>
      </HideFromTypes>
    </>
  );
}
