import { gql, useQuery } from '@apollo/client';
import { backendNameToLabel } from '../../../utils/formatting';

const GET_ACCOUNT_WEBHOOK_EVENTS = gql`
  query GetAccountWebhookEvents {
    accountWebhookEvents
  }
`;

export default function useAccountWebhookEvents() {
  const { data, loading, error } = useQuery(GET_ACCOUNT_WEBHOOK_EVENTS);

  const accountWebhookEventOptions =
    data?.accountWebhookEvents?.map(event => {
      const label = backendNameToLabel(event);

      return { value: event, label };
    }) || [];

  return { accountWebhookEvents: data?.accountWebhookEvents, accountWebhookEventOptions, loading, error };
}
