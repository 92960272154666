import { createContext } from 'react';

export const InitialState = {
  startSendDocumentsJob: () => {},
  clearJobId: () => {},
  jobId: null,
  insured: null,
  progress: 0,
  message: null,
  loading: false,
  jobErrors: []
};

export const SendDocumentsJobContext = createContext(InitialState);

SendDocumentsJobContext.displayName = 'SendDocumentsJobContext';
