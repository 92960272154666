import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { backendNameToLabel } from '../../../utils/formatting';

const GET_RETAIL_COMMISSION_RATE_MODES = gql`
  query retailCommissionRateModes {
    retailCommissionRateModes
  }
`;

export default function useRetailCommissionRateModes() {
  const { loading, error, data } = useQuery(GET_RETAIL_COMMISSION_RATE_MODES);

  return {
    retailCommissionRateModes: data?.retailCommissionRateModes,
    options: data?.retailCommissionRateModes?.map(mode => ({
      label: backendNameToLabel(mode),
      value: mode
    })),
    loading,
    error
  };
}
