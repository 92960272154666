import { createContext } from 'react';

export const InitialState = {
  startDownloadFailedDocumentsJob: () => {},
  clearJobId: () => {},
  jobId: null,
  filename: null,
  url: null,
  message: null,
  loading: false,
  jobErrors: []
};

export const DownloadFailedDocumentsJobContext = createContext(InitialState);

DownloadFailedDocumentsJobContext.displayName = 'DownloadFailedDocumentsJobContext';
