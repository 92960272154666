import React from 'react';
import { TrPlaceholder, TrLoader } from '@oforce/global-components';
import { Table } from '../../../components/table-2.0';
import { BoxContainer, RenderData, TrGraphQLErrors } from '../../../components';
import { TablePagination } from '../../../components/tables';
import NotificationJobsRow from './NotificationJobsRow';

export default function NotificationJobsTable({ notificationJobs, loading, error, paginationProps }) {
  const colSpan = 4;

  return (
    <>
      <BoxContainer>
        <Table>
          <thead>
            <tr>
              <th>Template</th>
              <th>Policy</th>
              <th>Job Id</th>
              <th>Created On</th>
            </tr>
          </thead>
          <tbody>
            <RenderData
              data={notificationJobs}
              loading={loading}
              error={error}
              loadingComponent={<TrLoader colSpan={colSpan} />}
              errorComponent={<TrGraphQLErrors error={error} colSpan={colSpan} />}
              placeholderComponent={<TrPlaceholder colSpan={colSpan}>No Notification Jobs</TrPlaceholder>}
            >
              {renderData =>
                renderData?.map(notificationJob => (
                  <NotificationJobsRow key={notificationJob?.id} notificationJob={notificationJob} />
                ))
              }
            </RenderData>
          </tbody>
        </Table>
      </BoxContainer>
      <TablePagination {...paginationProps} loading={loading} />
    </>
  );
}
