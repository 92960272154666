import React, { useEffect, useRef, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { CumulioDashboardComponent } from '@cumul.io/react-cumulio-dashboard';
import { Loader } from '@oforce/global-components';
import { theme } from '@oforce/global-styles';
import { GraphQLErrors } from '../../../components';
import { Alert } from 'reactstrap';
import styled from 'styled-components';

const AUTHORIZE_CUMULIO_DASHBOARD = gql`
  mutation authorizeCumulioDashboard {
    authorizeCumulioDashboard {
      authKey
      authToken
    }
  }
`;

export default function Dashboard({ dashboardId, setDashboards, setAccessibleDashboardsLoading }) {
  const [currentDashboard, setCurrentDashboard] = useState({});
  const dashboardRef = useRef();

  const [authorizeCumulioDashboard, { loading, error }] = useMutation(AUTHORIZE_CUMULIO_DASHBOARD, {
    onCompleted: data => {
      setCurrentDashboard({ dashboardId: dashboardId, ...data?.authorizeCumulioDashboard });
    },
    onError: () => {}
  });

  useEffect(() => {
    authorizeCumulioDashboard();
  }, [authorizeCumulioDashboard]);

  if (loading) return <Loader />;
  if (error) return <GraphQLErrors error={error} />;
  if (!loading && !currentDashboard?.dashboardId) return <Alert color="danger">Unable to retreive dashboard</Alert>;

  return (
    <>
      <CumulioWrapper
        style={{ display: currentDashboard?.dashboardId === 'b0e0be89-d177-4c18-bfe9-eb74358bfb97' ? 'none' : 'block' }}
      >
        <CumulioDashboardComponent
          ref={dashboardRef}
          dashboardId={currentDashboard?.dashboardId}
          authKey={currentDashboard?.authKey}
          authToken={currentDashboard?.authToken}
          switchScreenmodeOnResize={true}
          loaderBackground={theme.gray200}
          loaderFontColor={theme.gray600}
          loaderSpinnerColor={theme.blue}
          loaderSpinnerBackground="transparent"
          loaderLogoColor={theme.blue}
          editMode="view"
          load={() => {
            dashboardRef.current.getAccessibleDashboards().then(dashboards => {
              const dashboardsExceptDefault = dashboards.filter(
                dashboard => dashboard?.id !== 'b0e0be89-d177-4c18-bfe9-eb74358bfb97'
              );

              setDashboards(
                dashboardsExceptDefault.map(dashboard => ({
                  dashboardId: dashboard?.id,
                  dashboardSlug: dashboard?.name || dashboard?.id
                }))
              );

              setAccessibleDashboardsLoading(false);
            });
          }}
        ></CumulioDashboardComponent>
      </CumulioWrapper>
    </>
  );
}

const CumulioWrapper = styled.div`
  height: 500px;
`;
