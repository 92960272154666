import { convertPercentageToDecimal, convertToFloat } from '../../../utils';
import { useFeeRateModes } from '../../../components/sharedComponents/pricing';

import { useCreateFee, useFeeAppliesToOptions, useFeeValidationSchema } from '.';

export default function useCreateFeeForm({ product, onCompleted, onError }) {
  const { createFee, loading, error, reset } = useCreateFee({
    product,
    onCompleted,
    onError
  });
  const validationSchema = useFeeValidationSchema();
  const { feeRateModeOptions: rateModeOptions } = useFeeRateModes();
  const { feeAppliesToOptions } = useFeeAppliesToOptions();
  const flatRateModeOption = rateModeOptions?.find(({ value }) => value === 'FLAT');

  const initialValues = {
    name: '',
    rateMode: flatRateModeOption,
    rate: '',
    tags: [],
    appliesToAllPricingOptions: feeAppliesToOptions.find(x => x.value === true),
    productPricings: [],
    multipliers: [],
    prorated: product?.prorateSetting !== 'NO_PRORATE' ? true : false
  };

  const handleSubmit = ({
    name,
    rateMode,
    rate,
    appliesToAllPricingOptions,
    productPricings,
    multipliers,
    tags,
    prorated
  }) => {
    const rateModeValue = rateMode.value === 'PREMIUM_PLUS' ? 'PERCENTAGE' : rateMode.value;
    const rateModeType = rateMode.value === 'PREMIUM_PLUS' ? 'PREMIUM_PLUS' : 'PREMIUM';

    createFee({
      name: name,
      rateModeType,
      rateMode: rateModeValue,
      rate: rateModeValue === 'PERCENTAGE' ? convertPercentageToDecimal(rate) : convertToFloat(rate),
      appliesToAllPricingOptions: appliesToAllPricingOptions.value,
      productPricingIds: productPricings?.map(pricing => pricing?.value),
      multiplierFeeIds: multipliers?.map(fee => fee?.value),
      tags: tags?.map(tags => tags?.value),
      prorated
    });
  };

  return {
    initialValues,
    validationSchema,
    handleSubmit,
    loading,
    error,
    reset
  };
}
