import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Render, Td, Tr, useToggle } from '@oforce/global-components';

import { formatCurrency } from '../../../utils/formatting';
import { dateToClientSideFormat } from '../../../utils/dates';

import TransactionBreakdown from './TransactionBreakdown';
import classNames from 'classnames';
import { normalizeInsuredFullNameLastFirst, vehicleData } from '../../insured/utils';

export default function TransactionMasterSubRow({ index, insuranceTypeRequiresVehicle, transaction, colSpan }) {
  const [showBreakdown, toggleBreakdown] = useToggle();
  const icon = showBreakdown ? 'angle-down' : 'angle-right';
  const total = transaction?.total;
  const isNegative = total < 0;
  const totalClasses = classNames('font-weight-500', { 'text-danger': isNegative });
  const coverageDates = transaction?.coverageDates;

  const name = normalizeInsuredFullNameLastFirst(transaction?.exposureElectionSource?.insured);

  const capitalizeOnlyFirstLetter = string => string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);

  const formattedContractType = transaction?.exposureElectionSource?.contractType
    ? capitalizeOnlyFirstLetter(transaction?.exposureElectionSource?.contractType)
    : '';

  const vehicleInfo = vehicleData(transaction?.exposureElectionSource?.vehicle);

  return (
    <>
      <Tr key={transaction?.id} onClick={toggleBreakdown}>
        <Td>{index}</Td>
        <Td colSpan="2" className="cursor-pointer">
          <span className="font-weight-500">
            <FontAwesomeIcon icon={['far', 'user']} className="mb-1 mr-2" />
            {name}
          </span>
        </Td>
        {insuranceTypeRequiresVehicle === false ? (
          <Td>{transaction?.exposureElectionSource?.insured?.ssnLastFour}</Td>
        ) : (
          <Td>{vehicleInfo}</Td>
        )}
        <Td>{formattedContractType}</Td>
        <Td>
          <Render if={!!coverageDates}>
            {dateToClientSideFormat(coverageDates?.startDate)} {'–'} {dateToClientSideFormat(coverageDates?.endDate)}
          </Render>
        </Td>
        <Td className="text-right user-select-none cursor-pointer" colSpan="2">
          <FontAwesomeIcon icon={['far', icon]} fixedWidth className="mb-1 mr-1" />
          <span className={totalClasses}>{formatCurrency(total)}</span>
        </Td>
      </Tr>
      <Render if={showBreakdown}>
        <TransactionBreakdown colSpan={colSpan} transaction={transaction} />
      </Render>
    </>
  );
}
