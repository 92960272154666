import { gql, useQuery } from '@apollo/client';
import { ALL_VEHICLE_STAKEHOLDER_FIELDS } from '../../vehicleStakeholder/model';

export const GET_VEHICLE_STAKEHOLDERS = gql`
  query getVehicle($id: ID!) {
    vehicle: getVehicle(id: $id) {
      id
      additionalInsured {
        ...allVehicleStakeholderFields
      }
      lossPayee {
        ...allVehicleStakeholderFields
      }
      certHolder {
        ...allVehicleStakeholderFields
      }
      lienholder {
        ...allVehicleStakeholderFields
      }
    }
  }
  ${ALL_VEHICLE_STAKEHOLDER_FIELDS}
`;

export default function useVehicleStakeholders({ vehicleId }) {
  const { data, loading, error } = useQuery(GET_VEHICLE_STAKEHOLDERS, {
    variables: { id: vehicleId },
    skip: !vehicleId,
    fetchPolicy: !vehicleId ? 'cache-only' : 'cache-first'
  });

  let stakeholders = [];
  if (data?.vehicle?.additionalInsured) stakeholders.push(data?.vehicle?.additionalInsured);
  if (data?.vehicle?.lossPayee) stakeholders.push(data?.vehicle?.lossPayee);
  if (data?.vehicle?.certHolder) stakeholders.push(data?.vehicle?.certHolder);
  if (data?.vehicle?.lienholder) stakeholders.push(data?.vehicle?.lienholder);

  return { stakeholders, loading, error };
}
