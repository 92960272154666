import { Td, Tr } from '../../../components';

export default function TransactionLocationTitleRow({ text, colSpan }) {
  return (
    <Tr>
      <Td colSpan={colSpan}>
        <span className="font-weight-500">{text}</span>
      </Td>
    </Tr>
  );
}
