import { Formik } from 'formik';
import React, { useCallback } from 'react';
import { Modal, InputField } from '@oforce/global-components';

import { GraphQLErrors } from '../../../components';
import { useDuplicatePricingOption } from '../../pricing/hooks';

import { GET_POLICY_PRICINGS } from '../hooks/usePolicyPricings';

export default function DuplicatePricingOption({ show, toggle, policy, pricing }) {
  const refetchQueries = [{ query: GET_POLICY_PRICINGS, variables: { id: policy.id } }];

  const { duplicatePricingOption, loading, error } = useDuplicatePricingOption({
    id: pricing?.id,
    refetchQueries,
    onCompleted: toggle
  });

  const handleSubmit = useCallback(
    ({ name, label }) => {
      duplicatePricingOption({ name, label });
    },
    [duplicatePricingOption]
  );

  const initialValues = { name: pricing.name ? `${pricing.name} copy` : '', label: `${pricing.label} copy` };

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={handleSubmit}>
      {formik => (
        <Modal
          isOpen={show}
          toggle={toggle}
          title="Duplicate Pricing Option"
          onSubmit={formik.handleSubmit}
          submitButtonDisabled={formik.isSubmitting}
          submitButtonText={`Duplicate ${pricing?.label}`}
          loading={loading}
          onOpened={() => formik.handleReset()}
        >
          <GraphQLErrors error={error} />
          <InputField name="label" label="Label" required />
          <InputField name="name" label="Name" optional />
        </Modal>
      )}
    </Formik>
  );
}
