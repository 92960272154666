import { DatePickerField, FieldMessage } from '@oforce/global-components';
import { useCallback, useEffect, useState } from 'react';
import { sendStartDate } from '../../hooks/useAssignSubcontractRelationship';
import useGetInfoMessage from '../../hooks/useGetInfoMessage';

export default function SubContractedCoverageFormFields({ className, election, subcontractor, action }) {
  const [infoMessage, setInfoMessage] = useState('');
  const [id, setId] = useState(null);
  const [showStartDatePicker, setShowStartDatePicker] = useState(false);

  const { getMessage } = useGetInfoMessage({
    action,
    onCompleted: message => {
      setInfoMessage(message);
    }
  });

  const subSelected = useCallback(
    option => {
      if (sendStartDate(option, election)) {
        setShowStartDatePicker(true);
      } else {
        setShowStartDatePicker(false);
      }

      getMessage({
        masterExposureElectionId: election?.id,
        subExposureElectionId: option?.value
      });
    },
    [election, getMessage]
  );

  useEffect(() => {
    if (subcontractor?.value !== id) {
      subSelected(subcontractor);
      setId(subcontractor?.value);
    }
  }, [id, subcontractor, subSelected]);

  return (
    <div className={className}>
      {action === 'ADD' && showStartDatePicker && (
        <DatePickerField
          required
          fullWidth
          name="startDate"
          label="Start Date"
          format="MM/DD/YYYY"
          allowClear={false}
        />
      )}
      {subcontractor && infoMessage && (
        <FieldMessage icon={['far', 'info-circle']} className="fs-sm mb-3">
          {infoMessage}
        </FieldMessage>
      )}
    </div>
  );
}
