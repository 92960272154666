import debounce from 'debounce-promise';
import { gql } from '@apollo/client';
import { useFormOptions, usePaginatedQuery } from '../../../../../hooks';
import { normalizeInsuredFullName } from '../../../../insured/utils';

export const LIST_INSURED_OPTIONS = gql`
  query insureds($after: String, $before: String, $first: Int, $last: Int, $name: String) {
    allInsureds(first: $first, after: $after, last: $last, before: $before, name: $name) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          firstName
          lastName
          businessName
          middleName
        }
      }
    }
  }
`;

function castInsuredOptions(insureds) {
  return insureds?.map(insured => {
    return {
      label: normalizeInsuredFullName(insured),
      value: insured
    };
  });
}

export default function useInsuredFormOptions({ pageSize = 5 }) {
  const { data, loading, error, fetchMore } = usePaginatedQuery(LIST_INSURED_OPTIONS, {
    variables: { first: pageSize },
    pathToPageInfo: 'allInsureds',
    pageSize
  });
  const insuredOptions = castInsuredOptions(data?.allInsureds?.edges?.map(x => x.node));

  const formOptions = useFormOptions({
    options: [
      { name: 'insured', options: insuredOptions, loading, error },
      {
        name: 'createOrSelectInsured',
        options: [
          { label: 'Create', value: 'create' },
          { label: 'Select', value: 'select' }
        ]
      }
    ]
  });

  const getFilteredInsureds = async (query, setOptions) => {
    const { data } = await fetchMore({ variables: { name: query, first: pageSize } });
    const insureds = data?.allInsureds?.edges?.map(x => x.node);
    const insuredOptions = castInsuredOptions(insureds);
    setOptions(insuredOptions);
  };

  const loadOptions = debounce(getFilteredInsureds);

  return {
    loading,
    formOptions,
    loadOptions
  };
}
