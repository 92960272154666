import React from 'react';
import { GraphQLErrors } from '..';

export default function BoxErrors({ error }) {
  return (
    <div className="px-3 pt-3">
      <GraphQLErrors error={error} />
    </div>
  );
}
