import { gql } from '@apollo/client';
import { useState } from 'react';

import { usePaginatedQuery } from '../../../hooks';
import { dropNullProps } from '../../../utils';

export const GET_POLICIES = gql`
  query policies(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $activeStatus: PolicyActiveStatus
    $ordering: ListPoliciesOrdering
    $clientId: ID
    $searchBy: [PolicySearchByInput] = []
  ) {
    policies(
      first: $first
      after: $after
      last: $last
      before: $before
      activeStatus: $activeStatus
      ordering: $ordering
      searchBy: $searchBy
      clientId: $clientId
      includeArchived: true
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          name
          status
          effectiveStatus
          pricingRateType
          effectiveDate
          expirationDate
          externalId
          archived
          requiredInsuredFields
          requiredVehicleFields
          product {
            insuranceType {
              id
              type
            }
          }
          client {
            id
            name
          }
        }
      }
    }
  }
`;

export default function usePolicies({ pageSize = 20, initialFilters = {} }) {
  const [policyFilter, setPolicyFilter] = useState({
    archived: false,
    ...initialFilters
  });

  const { data, loading, error, refetch, paginationProps, queryProps, loadFirstPage } = usePaginatedQuery(
    GET_POLICIES,
    {
      variables: dropNullProps({ ...policyFilter }),
      pathToPageInfo: 'policies',
      pageSize,
      fetchPolicy: 'cache-and-network'
    }
  );

  return {
    policies: data?.policies?.edges?.map(({ node }) => node),
    loading: loading,
    error: error,
    refetch,
    paginationProps,
    policiesQueryProps: queryProps,
    policyFilter,
    changePolicyFilter(filters) {
      setPolicyFilter(state => {
        let newState = { ...state, ...filters };
        loadFirstPage(newState);
        return newState;
      });
    }
  };
}
