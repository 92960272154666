import * as yup from 'yup';
import { stakeholderLabels } from '../../stakeholder/utils';

export default function buildValidationSchema({ stakeholderType = '', requiredFields = {}, onlyFields = false }) {
  const { camelLables, snakeLabels } = stakeholderLabels(stakeholderType);

  let fields = {
    [camelLables['name']]: yup
      .string()
      .when(`$${camelLables['name']}`, (_value, schema) =>
        requiredFields[snakeLabels['name']] ? schema.required('Required') : schema
      ),
    [camelLables['relationship']]: yup
      .string()
      .when(`$${camelLables['relationship']}`, (_value, schema) =>
        requiredFields[snakeLabels['relationship']] ? schema.required('Required') : schema
      ),
    [camelLables['address1']]: yup
      .string()
      .when(`$${camelLables['address1']}`, (_value, schema) =>
        requiredFields[snakeLabels['address1']] ? schema.required('Required') : schema
      ),
    [camelLables['address2']]: yup
      .string()
      .when(`$${camelLables['address2']}`, (_value, schema) =>
        requiredFields[snakeLabels['address2']] ? schema.required('Required') : schema
      ),
    [camelLables['city']]: yup
      .string()
      .when(`$${camelLables['city']}`, (_value, schema) =>
        requiredFields[snakeLabels['city']] ? schema.required('Required') : schema
      ),
    [camelLables['email']]: yup
      .string()
      .email()
      .when(`$${camelLables['email']}`, (_value, schema) =>
        requiredFields[snakeLabels['email']] ? schema.required('Required') : schema
      ),
    [camelLables['phone']]: yup
      .string()
      .when(`$${camelLables['phone']}`, (_value, schema) =>
        requiredFields[snakeLabels['phone']] ? schema.required('Required') : schema
      ),
    [camelLables['state']]: yup
      .object()
      .shape({ label: yup.string(), value: yup.string() })
      .nullable()
      .when(`$${camelLables['state']}`, (_value, schema) =>
        requiredFields[snakeLabels['state']] ? schema.required('Required') : schema
      ),
    [camelLables['zip']]: yup
      .string()
      .when(`$${camelLables['zip']}`, (_value, schema) =>
        requiredFields[snakeLabels['zip']] ? schema.required('Required') : schema
      )
  };

  if (onlyFields) return fields;

  return yup.object().shape(fields);
}
