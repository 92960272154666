import { formatCurrency } from '../../utils/formatting';

export function normalizeInsuredFullName(insured) {
  const firstName = insured?.firstName;
  const middleName = insured?.middleName;
  const lastName = insured?.lastName;
  const businessName = insured?.businessName || insured?.businessName2;

  const label = () => {
    if (!!businessName && !!firstName && !!lastName && !!middleName)
      return `${lastName}, ${firstName}, ${middleName} - ${businessName}`;
    if (!!businessName && !!firstName && !!lastName) return `${lastName}, ${firstName} - ${businessName}`;
    if (!businessName && !!firstName && !!lastName && !!middleName) return `${lastName}, ${firstName}, ${middleName}`;
    if (!businessName && !!firstName && !!lastName) return `${lastName}, ${firstName}`;
    if (!!businessName) return `${businessName}`;
    return 'Unknown name';
  };

  return label();
}

export function normalizeInsuredFullNameLastFirst(insured) {
  const firstName = insured?.firstName;
  const middleName = insured?.middleName;
  const lastName = insured?.lastName;
  const businessName = insured?.businessName || insured?.businessName2;

  const label = () => {
    if (!!businessName && !!firstName && !!lastName && !!middleName)
      return `${lastName}, ${firstName} ${middleName} - ${businessName}`;
    if (!!businessName && !!firstName && !!lastName) return ` ${lastName}, ${firstName} - ${businessName}`;
    if (!businessName && !!firstName && !!lastName && !!middleName) return ` ${lastName}, ${firstName} ${middleName}`;
    if (!businessName && !!firstName && !!lastName) return ` ${lastName}, ${firstName}`;
    if (!!businessName) return `${businessName}`;
    return 'Unknown name';
  };

  return label();
}

export function cityState(insured) {
  const city = insured?.city;
  const state = insured?.state;
  if (!!city && !!state) return `${city}, ${state}`;
  else if (!!state) return state;
  else if (!!city) return city;
  else return 'Location not set';
}

export function vehicleData(vehicle) {
  const vin = vehicle?.vin;
  const unitNumber = vehicle?.unitNumber;
  const value = vehicle?.value;

  const label = () => {
    if (!!vin && !!value && !!unitNumber) return `${vin} - ${unitNumber}, ${formatCurrency(value)}`;
    if (!!vin && !!unitNumber) return `${vin} - ${unitNumber}`;
    if (!!vin && !!value) return `${vin}, ${formatCurrency(value)}`;
    if (!!value && !!unitNumber) return `${unitNumber}, ${formatCurrency(value)}`;

    if (!!vin) return `${vin}`;
    if (!!unitNumber) return `${unitNumber}`;
    if (!!value) return `${formatCurrency(value)}`;

    return '';
  };

  return label();
}
