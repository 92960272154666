import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import * as yup from 'yup';

export const UPLOAD_CLIENT_PRODUCT_INVOICE_TEMPLATE = gql`
  mutation UploadInvoiceTemplate($clientProductId: ID!, $fileData: Upload!) {
    uploadInvoiceTemplate(policyId: $clientProductId, fileData: $fileData) {
      id
      invoiceTemplate {
        filename
        url
      }
    }
  }
`;

export default function useUploadInvoiceTemplate({ onCompleted = () => {}, onError = () => {}, clientProductId }) {
  const [upload, { loading, error }] = useMutation(UPLOAD_CLIENT_PRODUCT_INVOICE_TEMPLATE, {
    onCompleted,
    onError
  });

  const initialValues = {
    document: null,
    name: ''
  };

  const DOCUMENT_VALIDATION = {
    SUPPORTED_FORMATS: ['application/pdf'],
    MAX_FILE_SIZE: 50000000
  };

  const validationSchema = yup.object({
    document: yup.mixed().when('$document', (_value, schema) =>
      schema
        .required('Required')
        .test('fileType', 'File must be a PDF', values =>
          values
            ?.map(value => DOCUMENT_VALIDATION.SUPPORTED_FORMATS.includes(value?.type))
            .reduce((acc, valid) => acc && valid, true)
        )
        .test('fileSize', 'File must be less than 50 MB', values =>
          values
            ?.map(value => value && value.size < DOCUMENT_VALIDATION.MAX_FILE_SIZE)
            .reduce((acc, valid) => acc && valid, true)
        )
    )
  });

  return {
    uploadInvoiceTemplate: ({ document }) => upload({ variables: { clientProductId, fileData: document[0] } }),
    loading,
    error,
    initialValues,
    validationSchema
  };
}
