import React from 'react';
import { Button, useToggle } from '@oforce/global-components';
import { gql, useQuery } from '@apollo/client';

import { TabPage } from '../../components/tabs';

import { CreateUpdate, RetailCommissionsTable } from './containers';
import { HideFromRoles, ShowForTypes, useActiveAccount } from '../../shared/activeAccount';

const GET_PRODUCT_RETAIL_COMMISSIONS = gql`
  query GetProductRetailCommissions($id: ID!) {
    product(id: $id) {
      id
      retailCommissions {
        id
        name
        rate
        rateMode
        appliesToAllPricing
        pricingOptionIds
      }
    }
  }
`;

export default function ProductRetailCommissions({ product }) {
  const {
    TYPES: { CARRIER },
    ROLES: { EXPOSURE_MANAGER }
  } = useActiveAccount();
  const [showCreate, toggleCreate] = useToggle();
  const productId = product?.id;

  const { data, loading, error } = useQuery(GET_PRODUCT_RETAIL_COMMISSIONS, {
    variables: { id: productId }
  });

  return (
    <TabPage
      title="Retail Commissions"
      showBackButton
      buttons={
        <ShowForTypes types={[CARRIER]}>
          <HideFromRoles roles={[EXPOSURE_MANAGER]}>
            <Button
              color="info"
              icon={['far', 'plus']}
              className="ml-2"
              onClick={toggleCreate}
              disabled={product?.pricingOptions?.length === 0}
            >
              Create
            </Button>
          </HideFromRoles>
        </ShowForTypes>
      }
    >
      <CreateUpdate isOpen={showCreate} toggle={toggleCreate} productId={productId} />

      <RetailCommissionsTable
        retailCommissions={data?.product?.retailCommissions || []}
        loading={loading}
        error={error}
        productId={productId}
      />
    </TabPage>
  );
}
