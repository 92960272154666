import { gql, useMutation } from '@apollo/client';

import { GET_INSURANCE_TYPES } from '../../../views/configurationInsuranceTypes';

const DELETE_STATE_FEE = gql`
  mutation DeleteStateFee($id: ID!) {
    deleteStateFee(id: $id) {
      id
      insuranceTypeId
    }
  }
`;

export default function useRemoveStateFee({ onCompleted = () => {}, onError = () => {} }) {
  const [remove, { loading, error }] = useMutation(DELETE_STATE_FEE, {
    refetchQueries: [{ query: GET_INSURANCE_TYPES }],
    onError,
    onCompleted
  });

  return {
    removeStateFee: id => remove({ variables: { id } }),
    loading,
    error
  };
}
