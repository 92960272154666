export const CANADA = 'CAN';
export const USA = 'USA';

export const COUNTRY_OPTIONS = [
  { value: USA, label: 'United States' },
  { value: CANADA, label: 'Canada' }
];

export const US_VERSIONS = ['USA', 'UNITED STATES', 'UNITED STATES OF AMERICA', 'US'];
export const CANADA_VERSIONS = ['CAN', 'CANADA', 'CA'];

export const administrativeAreaSelectOptions = (country, formOptions) => {
  if (country === CANADA) return formOptions?.canadianProvinces?.options
  if (country === USA) return formOptions?.state?.options
  else return []
}

export const castCountry = (country) => {
  const option = COUNTRY_OPTIONS.find(({ value }) => value === country);
  if (option) return option.label;
  else return country;
};

export default function useCountries() {
  return { options: [...COUNTRY_OPTIONS] };
}
