import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { backendNameToLabel } from '../../../utils/formatting';

const GET_STATE_FEE_RATE_MODES = gql`
  query stateFeeRateModes {
    stateFeeRateModes
  }
`;

export default function useStateFeeRateModes() {
  const { loading, error, data } = useQuery(GET_STATE_FEE_RATE_MODES);

  return {
    loading,
    error,
    stateFeeRateModes: data?.stateFeeRateModes,
    stateFeeRateModeOptions: data?.stateFeeRateModes?.map(mode => ({
      label: backendNameToLabel(mode),
      value: mode
    }))
  };
}
