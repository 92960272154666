import React from 'react';
import { Steps } from 'react-step-builder';
import { useHistory } from 'react-router-dom';
import { usePolicyCoverageParameters } from '../../views/policy/hooks';
import { GraphQLErrors, MultiStepForm, Render } from '../../components';
import { usePolicyInsuranceType } from '../../components/sharedComponents/hooks';
import { usePolicyPricingFormOptions, usePolicyPricingFormSteps, usePolicyPricingFormSubmit } from './hooks';
import { Loader } from '@oforce/global-components';
import {
  PolicyPricingDetailsFields,
  PolicyPricingPricingFields,
  PolicyPricingCoverageParametersFields
} from './components';

export default function PolicyPricingCreateUpdate({ policyId, pricing }) {
  const history = useHistory();
  const {
    insuranceType,
    loading: gettingInsuranceType,
    error: insuranceTypeError
  } = usePolicyInsuranceType({ policyId });

  const isCreateMode = !pricing;
  const insuranceTypeRequiresVehicle = insuranceType?.requireVehicle;

  const {
    policyCoverageParameters: coverageParameters,
    loading: loadingCoverageParams,
    error: coverageParamError
  } = usePolicyCoverageParameters({
    policyId
  });

  const { formOptions } = usePolicyPricingFormOptions({ policyId });
  const { formSteps } = usePolicyPricingFormSteps({
    formOptions,
    isCreateMode,
    insuranceTypeRequiresVehicle,
    pricing,
    coverageParameters,
    insuranceType
  });

  const { formSubmit, loading, error } = usePolicyPricingFormSubmit({
    policyId,
    pricing,
    isCreateMode,
    coverageParameters
  });

  const filterFormikValues = formikValues =>
    Object.keys(formikValues).reduce((acc, key) => {
      return !key.startsWith(`stateMode-`) && !key.startsWith('tempStateOptions-')
        ? { ...acc, [key]: formikValues[key] }
        : acc;
    }, {});

  if (insuranceTypeError || coverageParamError)
    return <GraphQLErrors error={insuranceTypeError || coverageParamError} />;
  if (gettingInsuranceType || loadingCoverageParams) return <Loader />;

  return (
    <MultiStepForm
      formOptions={formOptions}
      formSteps={formSteps}
      formSubmit={formikValues => {
        const values = filterFormikValues(formikValues);
        formSubmit(values);
      }}
      formProps={{ isCreateMode, insuranceTypeRequiresVehicle, policyId, coverageParameters }}
      title={`${isCreateMode ? 'Create' : 'Edit'} Policy Pricing`}
      onCancel={() => history.push(`/policies/${policyId}/pricing`)}
      submitButtonText={isCreateMode ? 'Create' : 'Save Changes'}
      submitButtonProps={{ loading, loadingText: isCreateMode ? 'Creating...' : 'Saving...' }}
      showButtonsOnBottom
      loading={loading}
      error={error}
    >
      {formProps => (
        <>
          <Render if={(!coverageParameters || coverageParameters?.length === 0) && !insuranceTypeRequiresVehicle}>
            <Steps>
              <PolicyPricingDetailsFields {...formProps} />
              <PolicyPricingPricingFields {...formProps} />
            </Steps>
          </Render>

          <Render if={coverageParameters?.length > 0 || insuranceTypeRequiresVehicle}>
            <Steps>
              <PolicyPricingDetailsFields {...formProps} />
              <PolicyPricingPricingFields {...formProps} />
              <PolicyPricingCoverageParametersFields {...formProps} />
            </Steps>
          </Render>
        </>
      )}
    </MultiStepForm>
  );
}
