import { gql } from '@apollo/client';
import { useSubscription } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { DocumentDownloadJobContext } from './context';
import { useResettableMutation } from '../../hooks/useResettableMutation';

const START_JOB = gql`
  mutation startCertJob($policyId: ID!, $activeOn: Date!) {
    downloadAllCertificatesOfInsurance(policyId: $policyId, activeOn: $activeOn)
  }
`;

const START_EXPOSURE_JOB = gql`
  mutation startExposureJob($productId: ID!) {
    startExportProductExposuresJob(productId: $productId)
  }
`;

const START_FACTOR_VALUE_JOB = gql`
  mutation startFactorValueJob($policyId: ID!) {
    startFactorValueTemplateJob(policyId: $policyId)
  }
`;

const START_UPDATE_EXPOSURE_JOB = gql`
  mutation startExposureUpdateTemplateJob($policyId: ID!) {
    startExposureUpdateTemplateJob(policyId: $policyId)
  }
`;

const START_POLICY_EXPOSURE_JOB = gql`
  mutation startExposureJob($policyId: ID!) {
    startExportPolicyExposuresJob(policyId: $policyId)
  }
`;

const ON_CERT_JOB_PROGRESS = gql`
  subscription jobProgress($jobId: ID!) {
    job: certificatesOfInsuranceJobProgress(jobId: $jobId) {
      jobId
      url
      finished
      message
      errors
      documentCount
      progress
      document {
        insuredFirstName
        insuredLastName
        documentName
        documentType
      }
    }
  }
`;

const ON_FACTOR_VALUE_COMPLETED = gql`
  subscription jobProgress($jobId: ID!) {
    job: factorValueTemplateJobCompleted(jobId: $jobId) {
      jobId
      url
      filename
    }
  }
`;

const ON_EXPOSURE_UPDATE_COMPLETED = gql`
  subscription jobProgress($jobId: ID!) {
    job: exposureUpdateTemplateJobCompleted(jobId: $jobId) {
      jobId
      url
      filename
    }
  }
`;

const ON_EXPOSURE_JOB_COMPLETED = gql`
  subscription jobProgress($jobId: ID!) {
    job: exportProductExposuresJobCompleted(jobId: $jobId) {
      jobId
      url
      filename
    }
  }
`;

const ON_POLICY_EXPOSURE_JOB_COMPLETED = gql`
  subscription jobProgress($jobId: ID!) {
    job: exportPolicyExposuresJobCompleted(jobId: $jobId) {
      jobId
      url
      filename
    }
  }
`;

export default function DocumentDownloadJobProvider({ children }) {
  const [jobId, setJobId] = useState(null);
  const [filename, setFilename] = useState(null);
  const [url, setUrl] = useState(null);
  const [jobErrors, setJobErrors] = useState([]);
  const [finished, setJobFinished] = useState(true);
  const [percentComplete, setPercentComplete] = useState(0);
  const [populatedDocument, setPopulatedDocument] = useState(null);
  const [message, setMessage] = useState(null);

  const [startCertJob, { loading, error, reset }] = useResettableMutation(START_JOB, {
    onCompleted: data => {
      setJobFinished(false);
      setJobId(data?.downloadAllCertificatesOfInsurance);
    },
    onError: () => setTimeout(() => reset(), 5000)
  });

  const [startExposureJob, { loading: exposureLoading, error: exposureError, reset: exposureReset }] =
    useResettableMutation(START_EXPOSURE_JOB, {
      onCompleted: data => {
        setJobFinished(false);
        setJobId(data?.startExportProductExposuresJob);
      },
      onError: () => setTimeout(() => exposureReset(), 5000)
    });

  const [startFactorValueJob, { loading: factorValueLoading, error: factorValueError, reset: factorValueReset }] =
    useResettableMutation(START_FACTOR_VALUE_JOB, {
      onCompleted: data => {
        setJobFinished(false);
        setJobId(data?.startFactorValueTemplateJob);
      },
      onError: () => setTimeout(() => factorValueReset(), 5000)
    });

  const [
    startExposureUpdateJob,
    { loading: exposureUpdateLoading, error: exposureUpdateError, reset: exposureUpdateReset }
  ] = useResettableMutation(START_UPDATE_EXPOSURE_JOB, {
    onCompleted: data => {
      setJobFinished(false);
      setJobId(data?.startExposureUpdateTemplateJob);
    },
    onError: () => setTimeout(() => exposureUpdateReset(), 5000)
  });

  const [
    startPolicyExposureJob,
    { loading: policyExposureLoading, error: policyExposureError, reset: policyExposureReset }
  ] = useResettableMutation(START_POLICY_EXPOSURE_JOB, {
    onCompleted: data => {
      setJobFinished(false);
      setJobId(data?.startExportPolicyExposuresJob);
    },
    onError: () => setTimeout(() => policyExposureReset(), 5000)
  });

  const clearJobId = useCallback(() => {
    setJobId(null);
    setUrl(null);
    setFilename('');
    setJobErrors([]);
  }, []);

  const startDownloadAllCertificatesOfInsuranceJob = (args) => {
    const {policyId, activeOn} = args
    setJobFinished(false);
    setFilename('all-policy-certificates-of-insurance.zip');
    startCertJob({
      variables: { policyId, activeOn }
    });
  };

  const startDownloadAllProductExposuresJob = productId => {
    setJobFinished(false);
    startExposureJob({
      variables: { productId }
    });
  };

  const startDownloadFactorValueTemplateJob = policyId => {
    setJobFinished(false);
    startFactorValueJob({
      variables: { policyId }
    });
  };

  const startExposureUpdateTemplateJob = policyId => {
    setJobFinished(false);
    startExposureUpdateJob({
      variables: { policyId }
    });
  };

  const startDownloadAllPolicyExposuresJob = policyId => {
    setJobFinished(false);
    startPolicyExposureJob({
      variables: { policyId }
    });
  };

  useSubscription(ON_CERT_JOB_PROGRESS, {
    skip: !jobId,
    variables: { jobId },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      const job = data?.job;

      const { errors, url, message, document, finished, progress } = job;

      setUrl(url);
      setJobErrors(errors);
      setJobFinished(finished);
      setPercentComplete(progress);
      setPopulatedDocument(document);
      setMessage(message);
    }
  });

  useSubscription(ON_FACTOR_VALUE_COMPLETED, {
    skip: !jobId,
    variables: { jobId },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      const job = data?.job;
      const { url, filename } = job;

      setFilename(filename);
      setUrl(url);
      setJobFinished(true);
    }
  });

  useSubscription(ON_EXPOSURE_UPDATE_COMPLETED, {
    skip: !jobId,
    variables: { jobId },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      const job = data?.job;
      const { url, filename } = job;

      setFilename(filename);
      setUrl(url);
      setJobFinished(true);
    }
  });

  useSubscription(ON_EXPOSURE_JOB_COMPLETED, {
    skip: !jobId,
    variables: { jobId },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      const job = data?.job;
      const { url, filename } = job;

      setFilename(filename);
      setUrl(url);
      setJobFinished(true);
    }
  });
  useSubscription(ON_POLICY_EXPOSURE_JOB_COMPLETED, {
    skip: !jobId,
    variables: { jobId },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      const job = data?.job;
      const { url, filename } = job;

      setFilename(filename);
      setUrl(url);
      setJobFinished(true);
    }
  });

  return (
    <DocumentDownloadJobContext.Provider
      value={{
        startDownloadAllCertificatesOfInsuranceJob,
        startDownloadAllProductExposuresJob,
        startDownloadAllPolicyExposuresJob,
        startDownloadFactorValueTemplateJob,
        startExposureUpdateTemplateJob,
        jobId,
        clearJobId,
        loading: loading || exposureLoading || policyExposureLoading || factorValueLoading || exposureUpdateLoading,
        error: error || exposureError || policyExposureError || factorValueError || exposureUpdateError,
        jobErrors,
        finished,
        progress: percentComplete,
        url,
        message,
        filename,
        populatedDocument
      }}
    >
      {children}
    </DocumentDownloadJobContext.Provider>
  );
}
