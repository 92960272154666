import { gql, useQuery } from '@apollo/client';
import { fragments as productPricingFragments } from '../../productPricing/hooks/useProductPricings';

export const fragments = {
  policyPricingOption: gql`
    fragment PolicyPricingOption on PolicyPricingOption {
      id
      name
      label
      rateMode
      rate
      isPercentageFactor
      percentageMinimum
      percentageMaximum
      factoredField
      retailCommission {
        id
        name
        rate
        rateMode
      }
      coverageParameters {
        id
        cacheId
        name
        type
        value
        valueType
      }
      fees {
        id
        name
        tags
        rateMode
        rate
      }
      vehicleTypes {
        id
        name
      }
    }
  `
};

export const GET_POLICY_PRICINGS = gql`
  query getPolicyPricings($id: ID!) {
    policy(id: $id) {
      id
      pricingOptions {
        ...PolicyPricingOption
        ...ProductPricingOption
      }
    }
  }
  ${fragments.policyPricingOption}
  ${productPricingFragments.productPricingOption}
`;

export default function usePolicyPricings({ policyId }) {
  const { data, loading, error } = useQuery(GET_POLICY_PRICINGS, {
    fetchPolicy: 'network-only',
    variables: { id: policyId }
  });

  return {
    pricings: data?.policy?.pricingOptions,
    loading: loading,
    error: error
  };
}
