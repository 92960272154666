import React from 'react';
import { Helmet } from 'react-helmet';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';

import { PageHeader, PageTitle } from '../../components/page-components';
import { TabBar, TabLink, TabPane } from '../../components/tabs';
import { ConfigurationDocuments, ConfigurationVehicleTypes, ConfigurationInsuranceTypes } from '../../views';

export default function Configuration() {
  const { url, path } = useRouteMatch();
  return (
    <>
      <Helmet>
        <title>Configuration • IC Insure</title>
      </Helmet>
      <PageHeader withTabs>
        <PageTitle>Configuration</PageTitle>

        <TabBar>
          <TabLink to={`${url}/insurance-types`} icon={['far', 'umbrella']}>
            Insurance Types
          </TabLink>
          <TabLink to={`${url}/vehicle-types`} icon={['far', 'cars']}>
            Vehicle Types
          </TabLink>
          <TabLink to={`${url}/documents`} icon={['far', 'file']}>
            Documents
          </TabLink>
        </TabBar>
      </PageHeader>

      <TabPane>
        <Switch>
          <Redirect exact from={`${path}`} to={`${path}/insurance-types`} />
          <Route path={`${path}/insurance-types`}>
            <ConfigurationInsuranceTypes />
          </Route>
          <Route path={`${path}/vehicle-types`}>
            <ConfigurationVehicleTypes />
          </Route>
          <Route path={`${path}/documents`}>
            <ConfigurationDocuments />
          </Route>
        </Switch>
      </TabPane>
    </>
  );
}
