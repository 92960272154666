import React from 'react';
import { Formik } from 'formik';
import { Modal } from '@oforce/global-components';

import { GraphQLErrors } from '../../../components';

import { StateFeeForm } from '.';
import { useAddStateFeeForm } from '../hooks';

export default function AddStateFee({ insuranceTypeId, showAddStateFee, toggleAddStateFee }) {
  const { validationSchema, initialValues, handleSubmit, loading, error, reset } = useAddStateFeeForm({
    insuranceTypeId,
    onCompleted: toggleAddStateFee
  });

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {formik => (
        <Modal
          isOpen={showAddStateFee}
          toggle={toggleAddStateFee}
          onOpened={() => {
            reset();
            formik.handleReset();
          }}
          title="Add State Fee"
          loading={loading}
          submitButtonText="Add State Fee"
          submitButtonProps={{ loadingText: 'Adding...' }}
          submitButtonDisabled={formik.isSubmitting}
          onSubmit={formik.handleSubmit}
        >
          <GraphQLErrors error={error} />
          <StateFeeForm loading={loading} formik={formik} />
        </Modal>
      )}
    </Formik>
  );
}
