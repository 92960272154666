import { pascalCase } from 'pascal-case';
import { camelCase } from 'camel-case';
import { snakeCase } from 'snake-case';

function createQueryDocument({ name, query }) {
  return `const GET_${snakeCase(name).toLocaleUpperCase()} = gql\`${query}\``;
}

function createLazyQuery({ name }) {
  const query = `const [
          get${pascalCase(name)},
          {
              loading: loading${pascalCase(name)},
              error: ${camelCase(name)}Error,
              data: ${camelCase(name)}Data
          }
      ] = useLazyQuery(GET_${snakeCase(name).toLocaleUpperCase()})`;
  return query;
}

function createReturnObject(operations) {
  const fns = operations
    .map(
      ({ name }) => `get${pascalCase(name)}: ({...variables}) => get${pascalCase(name)}({variables: {...variables}})`
    )
    .join(',\n');

  const loading = operations.map(({ name }) => `loading${pascalCase(name)}`).join(' || ');
  const error = operations.map(({ name }) => `${camelCase(name)}Error`).join(' || ');
  const data = operations.map(({ name }) => `${camelCase(name)}: ${camelCase(name)}Data`).join(',\n');

  return `return {
          ${fns},
          loading: ${loading},
          error: ${error},
          ${data}
      }`;
}

export default function multiQuery({ operationDataList, context }) {
  const hookName = `useOforce${context.apiName}`;
  const documents = operationDataList.map(createQueryDocument).join('\n\n');
  const queries = operationDataList.map(createLazyQuery).join('\n\n');

  return `
  import { gql } from '@apollo/client';
  import { useLazyQuery } from '@apollo/client';
  
  ${documents}
  
  function ${hookName}() {
  ${queries}
  
  ${createReturnObject(operationDataList)}
  }
  
  export default ${hookName};
      `;
}
