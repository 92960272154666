import React from 'react';
import { Button, Render } from '@oforce/global-components';
import { CustomDataForm, useCustomData } from '../../../components/custom-data';
import { FormSection } from '../../../components';

export default function CustomDataStep({ formik, title }) {
  const { inputError, resetState, submit, dataChanged, ...rest } = useCustomData({
    data: formik?.values?.customData,
    onSubmit: data => formik.setFieldValue('customData', data)
  });

  return (
    <FormSection title={title}>
      <CustomDataForm {...rest} />
      <Render if={dataChanged}>
        <div className="d-flex flex-row w-100 justify-content-end mt-3">
          <Button className="mr-3" onClick={() => resetState(formik?.values?.customData)} color="warning">
            Reset
          </Button>
          <Button className="mr-3" onClick={submit} color="success">
            Save
          </Button>
        </div>
      </Render>
    </FormSection>
  );
}
