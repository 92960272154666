import React from 'react';
import { Modal } from '@oforce/global-components';

import { GraphQLErrors } from '../../../components';

import { useRemoveStateFee } from '../hooks';

export default function RemoveStateFee({ showRemoveStateFee, toggleRemoveStateFee, id, state }) {
  const { removeStateFee, loading, error } = useRemoveStateFee({
    onCompleted: toggleRemoveStateFee
  });

  return (
    <Modal
      isOpen={showRemoveStateFee}
      toggle={toggleRemoveStateFee}
      title={`Remove ${state} State Fee`}
      loading={loading}
      submitButtonText="Remove State Fee"
      submitButtonProps={{ color: 'danger', loadingText: 'Removing...' }}
      onSubmit={() => removeStateFee(id)}
    >
      <GraphQLErrors error={error} />
      Are you sure you want to remove this state fee?
    </Modal>
  );
}
