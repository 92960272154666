import { InputDropzoneField, InputField, ReactSelectField } from '@oforce/global-components';
import { useEffect, useState } from 'react';
import { defaultDocumentName } from '../../productDocuments';

export default function DocumentFields({ uploading, formik }) {
  const documentTypeOptions = [
    { value: 'GENERAL', label: 'General' },
    { value: 'APPLICATION', label: 'Application' },
    { value: 'CERTIFICATE_OF_INSURANCE', label: 'Certificate of Insurance' }
  ];

  const [documentName, setDocumentName] = useState(null);

  useEffect(() => {
    if (documentName) {
      return;
    }

    if (formik?.values?.document?.length === 1 && !formik?.values?.name) {
      const name = defaultDocumentName(formik?.values?.document[0]?.name);
      setDocumentName(name);
      formik.setFieldValue('name', name);
    }
  }, [formik, documentName, setDocumentName]);

  return (
    <>
      <InputDropzoneField
        required
        accept={['.pdf']}
        id="document"
        label="Document"
        name="document"
        disabled={uploading}
        readOnly={uploading}
        placeholder={'Choose PDF file'}
      />
      <InputField required label="Name" name="name" placeholder="Enter a document name" />
      <ReactSelectField required label="Type" name="type" options={documentTypeOptions} placeholder="Select..." />
    </>
  );
}
