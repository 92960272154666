import { Td, Tr } from '@oforce/global-components';
import { NameCell } from '../../../components/tables';
import { dateToClientSideFormat } from '../../../utils/dates';
import { ExposureStatusDot } from '../../exposures/components';

export default function InsuredRow({ insured }) {
  return (
    <Tr>
      <Td>
        <ExposureStatusDot exposure={insured} />
      </Td>
      <Td>
        <NameCell insured={insured} linkAddress={`/insureds/${insured?.id}`} />
      </Td>
      <Td>{insured?.electionCount}</Td>
      <Td>{insured?.dispatchLocation}</Td>
      <Td>{insured?.ssnLastFour ? `XXX-XX-${insured?.ssnLastFour}` : ''}</Td>
      <Td>{dateToClientSideFormat(insured?.insertedAt)}</Td>
    </Tr>
  );
}
