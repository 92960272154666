import React from 'react';
import { Route } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import { AuthenticatingPage } from '../components/pages';

function ProtectedRoute({ children, ...args }) {
  return <Route {...args}>{children}</Route>;
}

export default withAuthenticationRequired(ProtectedRoute, { onRedirecting: () => <AuthenticatingPage /> });
