import React from 'react';
import { Formik } from 'formik';
import { gql, useMutation } from '@apollo/client';
import { Modal } from '@oforce/global-components';

import { GraphQLErrors } from '../../../components';

import { GET_POLICY_PRICINGS } from '../../policyPricing/hooks/usePolicyPricings';
import { GET_POLICY_FEES } from '../../policyFees/components/FeesTable';
import { useFeeValidationSchema } from '../../policyFees/hooks';

const REMOVE_POLICY_FEE = gql`
  mutation RemovePolicyFee($input: RemovePolicyFeeInput!) {
    removePolicyFee(input: $input) {
      id
    }
  }
`;

export default function FeeRemove({ isOpen, toggle, policy, fee, onError = () => {} }) {
  const [removeFee, { loading, error }] = useMutation(REMOVE_POLICY_FEE, {
    variables: { input: { policyId: policy?.id, feeId: fee?.id } },
    onCompleted: toggle,
    onError,
    refetchQueries: [
      { query: GET_POLICY_FEES, variables: { id: policy?.id } },
      { query: GET_POLICY_PRICINGS, variables: { id: policy?.id } }
    ]
  });

  const validationSchema = useFeeValidationSchema();

  return (
    <Formik initialValues={{}} validationSchema={validationSchema} enableReinitialize onSubmit={removeFee}>
      {formik => (
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          title="Remove Fee"
          onSubmit={removeFee}
          submitButtonDisabled={formik.isSubmitting}
          submitButtonProps={{ color: 'danger', loadingText: 'Deleting...' }}
          submitButtonText={`Remove ${fee?.name}`}
          loading={loading}
          onOpened={() => formik.handleReset()}
        >
          <GraphQLErrors error={error} />
          Are you sure you want to remove {fee?.name}?
        </Modal>
      )}
    </Formik>
  );
}
