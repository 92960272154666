import debounce from 'debounce-promise';
import { gql } from '@apollo/client';

import { useFormOptions, usePaginatedQuery } from '../../../../../hooks';
import { makeModelYearVin } from '../../../../vehicle/utils';

export const LIST_VEHICLE_OPTIONS = gql`
  query Vehicles($after: String, $before: String, $first: Int, $last: Int, $insuredId: ID, $makeModelYearVin: String) {
    vehicles(
      first: $first
      after: $after
      last: $last
      before: $before
      insuredId: $insuredId
      makeModelYearVin: $makeModelYearVin
    ) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          vin
          vehicleMake
          vehicleModel
          vehicleYear
        }
      }
    }
  }
`;

function castVehicleOptions(vehicles) {
  return vehicles?.map(vehicle => {
    return {
      label: makeModelYearVin(vehicle),
      value: vehicle
    };
  });
}

export default function useVehicleFormOptions({ pageSize }) {
  const { data, loading, fetchMore } = usePaginatedQuery(LIST_VEHICLE_OPTIONS, {
    variables: {
      first: pageSize
    },
    pathToPageInfo: 'vehicles',
    pageSize
  });

  const vehicleOptions = castVehicleOptions(data?.vehicles?.edges?.map(x => x.node));

  const formOptions = useFormOptions({
    options: [
      { name: 'vehicle', options: vehicleOptions, loading },
      {
        name: 'createOrSelectVehicle',
        options: [
          { label: 'Create', value: 'create' },
          { label: 'Select', value: 'select' }
        ]
      }
    ]
  });

  const getFilteredVehicles = async (query, setOptions) => {
    const { data } = await fetchMore({ variables: { makeModelYearVin: query, first: pageSize } });
    const vehicles = data?.vehicles?.edges?.map(x => x.node);
    const vehicleOptions = castVehicleOptions(vehicles);
    setOptions(vehicleOptions);
  };

  const loadOptions = debounce(getFilteredVehicles);

  return {
    loading,
    formOptions,
    loadOptions
  };
}
