import { createContext } from 'react';

export const InitialState = {
  startImportExposureJob: () => {},
  jobId: null,
  clearJobId: () => {},
  insured: null,
  progress: 0,
  loading: false,
  jobErrors: [],
  message: null
};

export const ExposureImportJobContext = createContext(InitialState);

ExposureImportJobContext.displayName = 'ExposureImportJobContext';
