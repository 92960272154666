import React from 'react';
import moment from 'moment';
import { UncontrolledTooltip } from 'reactstrap';
import { Render, Badge } from '@oforce/global-components';
import { Link } from 'react-router-dom';

import { EXPOSURE_ELECTION_STATUSES } from '../constants';
import { backendNameToLabel } from '../../../utils/formatting';
const { ACTIVE, PENDING_TERMINATION, NEEDS_APPROVAL, TERMINATED, REJECTED } = EXPOSURE_ELECTION_STATUSES;

const hasOpenViolations = election => {
  const openViolations = election?.compliance?.violations?.filter(x => x.status === 'OPEN') || [];
  return openViolations.length > 0;
};

const rejected = election => {
  return election?.compliance?.rejected === true;
};

const terminated = election => {
  return election.status === TERMINATED;
};

export const electionStatusString = election => {
  if (rejected(election)) return REJECTED;
  if (hasOpenViolations(election)) return NEEDS_APPROVAL;
  if (terminated(election)) return TERMINATED;

  return election.status;
};

export const statusBadgeColors = {
  [ACTIVE]: 'success',
  [PENDING_TERMINATION]: 'warning',
  [NEEDS_APPROVAL]: 'danger',
  [TERMINATED]: 'danger',
  [REJECTED]: 'danger'
};

export default function ExposureElectionStatusBadge({ election, className }) {
  const electionStatus = electionStatusString(election);

  const terminationDate = !!election?.terminationDate ? moment(election?.terminationDate).format('MM-DD-YYYY') : '';
  const tooltipId = `tooltip-${election?.id}`;

  const badgeText = {
    [ACTIVE]: 'Active',
    [PENDING_TERMINATION]: 'Pending Termination',
    [NEEDS_APPROVAL]: 'Pending Approval',
    [TERMINATED]: 'Terminated',
    [REJECTED]: 'Rejected'
  }[electionStatus];

  const badgeColor = statusBadgeColors[electionStatus];

  const badgeTooltip = {
    [ACTIVE]: null,
    [PENDING_TERMINATION]: `Scheduled for ${terminationDate}`,
    [NEEDS_APPROVAL]: <Violations violations={election?.compliance?.violations} />,
    [TERMINATED]: `Terminated on ${terminationDate}`,
    [REJECTED]: `Rejected`
  }[electionStatus];

  const badgeIcon = {
    [ACTIVE]: null,
    [PENDING_TERMINATION]: null,
    [NEEDS_APPROVAL]: null,
    [TERMINATED]: null
  }[electionStatus];

  return (
    <Link to={`/insureds/${election?.insuredId}/elections/${election?.id}`} className={className}>
      <Render if={!!badgeTooltip}>
        <UncontrolledTooltip target={tooltipId}>{badgeTooltip}</UncontrolledTooltip>
      </Render>
      <Badge id={tooltipId} color={badgeColor} icon={badgeIcon} className="text-nowrap">
        {badgeText}
      </Badge>
    </Link>
  );
}

const Violations = ({ violations }) => {
  const formatViolation = violation =>
    `${backendNameToLabel(violation?.field)}: ${violation?.reason} on ${backendNameToLabel(violation?.source)}`;

  return violations?.map((v, i) => <div key={i}>{formatViolation(v)}</div>);
};
