import * as yup from 'yup';

export default function useAddOnValidationSchema() {
  return yup.object({
    label: yup.string().required('Required'),
    name: yup.string(),
    cost: yup.mixed().nullable().required('Required'),
    appliesToAllPricingOptions: yup.object({ label: yup.string(), value: yup.boolean() }).required('Required'),
    pricings: yup
      .array()
      .when('appliesToAllPricingOptions', ({ value }, _schema) =>
        value ? yup.array().nullable() : yup.array().of(yup.object()).nullable().required('Required')
      ),
    requiredOnAllPricingOptions: yup.object({ label: yup.string(), value: yup.boolean() }).required('Required'),
    requiredForPricings: yup
      .array()
      .when('appliesToAllPricingOptions', ({ value }, _schema) =>
        value ? yup.array().nullable() : yup.array().of(yup.object()).nullable()
      )
  });
}
