import { toHeaderCase } from 'js-convert-case';
import { Address, Td, Tr } from '../../../components';
import { ContactInfoCell } from '../../../components/tables';

export default function VehicleStakeholderRow({ stakeholder }) {
  const { id, stakeholderType, name, phone, email } = stakeholder;

  return (
    <Tr key={id}>
      <Td>{toHeaderCase(stakeholderType)}</Td>
      <Td>
        <div className="d-flex flex-column align-items-start">
          <span className="fs-sm">{name}</span>
          <div>
            <ContactInfoCell email={email} mobilePhone={phone} />
          </div>
        </div>
      </Td>
      <Td>
        <Address stakeholder={stakeholder} />
      </Td>
    </Tr>
  );
}
