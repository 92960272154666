import { InputDropzoneField, ReactSelect, Render, Label } from '@oforce/global-components';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { Modal } from '@oforce/global-components';
import { useCsvImport, useParseExposureCsvFile } from './hooks';
import ImportFileTemplateLink from '../ImportFileTemplateLink';
import { AddOnSelect } from '../../../addOns/components';

export default function UploadExposureImportFileModal({ onParseComplete, isOpen, toggle, policy, formOptions }) {
  const [pricingOption, setPricingOption] = useState(null);
  const [selectedAddOns, setAddOns] = useState([]);

  const { parseData, loading } = useParseExposureCsvFile({
    policy,
    onComplete: onParseComplete,
    policyPricingOptionId: pricingOption?.value,
    addOnIds: selectedAddOns.map(x => x.value)
  });

  const { initialValues, validationSchema } = useCsvImport();
  const insuranceType = policy?.insuranceType;

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} enableReinitialize onSubmit={parseData}>
      {formik => (
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          title="Upload CSV"
          submitButtonText="Upload CSV"
          onSubmit={formik.handleSubmit}
          loading={loading}
          submitButtonProps={{ loadingText: 'Parsing File...', disabled: !pricingOption || !formik.values.document }}
        >
          <InputDropzoneField
            className="mb-0"
            required
            accept={[".csv"]}
            id="document"
            label="Exposure Csv File"
            name="document"
            disabled={loading}
            readOnly={loading}
            placeholder={'Choose CSV file'}
          />
          <ImportFileTemplateLink insuranceType={insuranceType} />

          <Render if={!policy?.enableCustomStartDates}>
            <Label for="uploadExposures" className="fs-xs text-danger ml-1 mb-0">
              *Custom start dates are disabled on this product. All start dates will be set to today.
            </Label>
          </Render>
          <Label className="font-weight-500 mb-0 fs-sm">Policy Option</Label>
          <ReactSelect
            options={formOptions?.pricingOptions?.options}
            value={formOptions?.pricingOptions?.options?.find(({ value }) => value === pricingOption?.value)}
            onChange={option => {
              setAddOns([]);
              setPricingOption(option);
            }}
            required
            placeholder="Select a default policy option..."
          />
          <AddOnSelect
            policy={policy}
            pricingOptionId={pricingOption?.value}
            onChange={setAddOns}
            selectedAddOnIds={selectedAddOns?.map(x => x.value)}
          />
        </Modal>
      )}
    </Formik>
  );
}
