import React, { useState } from 'react';
import { BoxContainer, FormSection, TrGraphQLErrors } from '../../../components';
import { CustomInput } from 'reactstrap';
import { TrLoader, TrPlaceholder, RenderData, Td, Tr, FieldMessage, Loader } from '@oforce/global-components';
import { Table } from '../../../components/table-2.0';
import useBulkEmailDocumentRelationships from '../hooks/useBulkEmailDocumentRelationships';

export default function BulkEmailDocumentsSelection({ formik, policyId, clientId, loading, error }) {
  const colSpan = 3;
  const [selectAll, setSelectAll] = useState(true);
  const activeOn = formik?.values?.activeOn;

  const setDefaults = result => {
    const { documentIds, insureds, relationships } = getFields(result);
    const allValidInsuredIds = insureds?.filter(insured => !!insured?.email)?.map(insured => insured?.id);
    const allInsuredIds = insureds?.map(insured => insured?.id);

    if (!formik?.values?.documentRelationships || formik?.values?.documentRelationships?.length === 0) {
      formik?.setFieldValue('documentIds', documentIds);
      formik?.setFieldValue('insuredIds', allValidInsuredIds);
      formik?.setFieldValue('allValidInsuredIds', allValidInsuredIds);
      formik?.setFieldValue('allInsuredIds', allInsuredIds);
      formik?.setFieldValue('documentRelationships', relationships);
    }
  };

  const {
    data,
    loading: relsLoading,
    error: relsError
  } = useBulkEmailDocumentRelationships({ activeOn, policyId, clientId, onCompleted: setDefaults });

  const { documents, documentIds } = getFields(data);

  const toggleSelectAll = selectAll => {
    !selectAll ? formik?.setFieldValue('documentIds', documentIds) : formik?.setFieldValue('documentIds', []);
    setSelectAll(!selectAll);
  };

  const renderError = error || relsError;
  const renderLoading = loading || relsLoading;

  if (renderLoading) return <Loader />;

  return (
    <FormSection title="Select Documents">
      {renderLoading ? (
        <FieldMessage icon={['far', 'info-circle']}>
          Thank you for hanging out while we find your documents. This may take a little time.
        </FieldMessage>
      ) : null}
      <BoxContainer>
        <Table responsive>
          <thead>
            <tr>
              <th>
                <CustomInput
                  id="toggleSelectAllDocumentIds"
                  type="checkbox"
                  checked={selectAll}
                  onChange={() => toggleSelectAll(selectAll)}
                  className="mb-1"
                />
              </th>
              <th>Document Name</th>
              <th>File Type</th>
            </tr>
          </thead>
          <tbody>
            <RenderData
              data={documents}
              loading={renderLoading}
              error={renderError}
              loadingComponent={<TrLoader colSpan={colSpan} />}
              errorComponent={<TrGraphQLErrors colSpan={colSpan} error={renderError} />}
              placeholderComponent={<TrPlaceholder colSpan={colSpan}>No documents</TrPlaceholder>}
            >
              {documents =>
                documents?.map((document, i) => (
                  <Tr
                    key={i}
                    style={formik?.values?.documentIds?.includes(document?.id) ? { backgroundColor: '#e6eaf2' } : {}}
                  >
                    <Td>
                      <CustomInput
                        id={document?.name}
                        type="checkbox"
                        checked={formik?.values?.documentIds?.includes(document?.id)}
                        onChange={() => {
                          if (formik?.values?.documentIds?.includes(document?.id)) {
                            formik?.setFieldValue(
                              'documentIds',
                              formik?.values?.documentIds.filter(document_id => document_id !== document?.id)
                            );
                          } else {
                            formik?.setFieldValue('documentIds', [...formik?.values?.documentIds, document?.id]);
                          }
                        }}
                        className="mb-1"
                      />
                    </Td>
                    <Td>{document?.name}</Td>
                    <Td>{document?.filename.split('.').pop().toUpperCase()}</Td>
                  </Tr>
                ))
              }
            </RenderData>
          </tbody>
        </Table>
      </BoxContainer>
    </FormSection>
  );
}

const getFields = result => {
  const unique = arr => {
    if (!!arr) return [...new Set(arr)];
    return [];
  };

  return {
    documents: unique(result?.bulkEmailDocumentRelationships?.map(x => x.document)),
    documentIds: unique(result?.bulkEmailDocumentRelationships?.map(x => x.document)?.map(x => x.id)),
    relationships: result?.bulkEmailDocumentRelationships,
    insuredIds: unique(
      result?.bulkEmailDocumentRelationships?.reduce((acc, x) => [...acc, x.insured], [])?.map(x => x.id)
    ),
    insureds: unique(result?.bulkEmailDocumentRelationships?.reduce((acc, x) => [...acc, x.insured], []))
  };
};
