import React from 'react';
import { Modal } from '@oforce/global-components';

import { GraphQLErrors } from '..';

export default function DeleteModal({
  isOpen,
  toggle,
  entityType,
  entityName,
  deleting,
  error,
  submitButtonText,
  cancelButtonText,
  children,
  ...rest
}) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      title={`Delete ${entityType}`}
      submitButtonProps={{ loading: deleting, loadingText: 'Deleting...', color: 'danger' }}
      submitButtonText={submitButtonText ? submitButtonText : `Yes, Delete ${entityName || entityType}`}
      cancelButtonText={cancelButtonText ? cancelButtonText : 'No, Cancel'}
      {...rest}
    >
      <GraphQLErrors error={error} />
      Are you sure you want to delete {entityName}? {children}
    </Modal>
  );
}
