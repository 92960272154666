import React from 'react';
import { Formik, Form } from 'formik';
import { DatePickerField, FieldMessage, InputField, Modal, Render } from '@oforce/global-components';

import { Bold, GraphQLErrors } from '../../../components';

import moment from 'moment';
import { useExposureTerminationDate, usePolicyEffectiveDates } from '../hooks';
import { backDateMessage } from '../../exposureElections/hooks';
import { FeatureFlag } from '../../../shared/activeAccount/components';
import { FLAGGED_FEATURES } from '../../../shared/activeAccount/constants';

function message(policy) {
  return backDateMessage(policy?.terminationDateBackdatingMinimum, policy?.terminationDateBackdatingLimit);
}

export default function ExposureTerminate({ policy, election, isOpen, toggle }) {
  const { loading, error, reset, initialValues, validationSchema, handleSubmit } = useExposureTerminationDate(
    election,
    toggle
  );

  const { expirationDate, enableCustomTerminationDates } = usePolicyEffectiveDates(policy);

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {formik => {
        const isFutureDate = moment(formik?.values?.terminationDate) > moment();

        return (
          <Modal
            isOpen={isOpen}
            toggle={toggle}
            title="Terminate Exposure"
            loading={loading}
            onSubmit={formik.handleSubmit}
            submitButtonText={isFutureDate ? 'Schedule Termination' : 'Terminate'}
            onOpened={() => {
              formik.handleReset();
              reset();
            }}
            scrollable
            submitButtonProps={{
              color: 'danger',
              disabled: loading,
              loadingText: isFutureDate ? 'Scheduling termination...' : 'Terminating...'
            }}
          >
            <GraphQLErrors error={error} />
            <Form>
              <FeatureFlag feature={FLAGGED_FEATURES.MASTER_SUB_RELATIONSHIP}>
                <Render if={election?.contractType === 'MASTER'}>
                  <div className="mb-3">
                    Terminating a master with subcontracts will <Bold> terminate all subcontracts</Bold> on the same
                    date.
                    <br />
                    <br />
                    Are you sure you want to continue?
                  </div>
                </Render>
              </FeatureFlag>
              {enableCustomTerminationDates ? (
                <>
                  <DatePickerField
                    required
                    fullWidth
                    name="terminationDate"
                    label="Terminate on"
                    format="MM/DD/YYYY"
                    allowClear={false}
                    inputReadOnly={true}
                    disabledDate={current =>
                      (expirationDate && current && current > moment(expirationDate)) ||
                      (election?.startDate && current && current < moment(election?.startDate))
                    }
                    messageAfter={message(policy)}
                    messageAfterProps={{ icon: ['far', 'info-circle'] }}
                  />
                  <FieldMessage icon={['far', 'info-circle']}>
                    If policy is expired and chosen termination date is AFTER the policy's expiration date, the policy
                    expiration date will be used as the termination date.
                  </FieldMessage>
                </>
              ) : (
                <InputField name="terminationDate" label="Terminate On" disabled={true} />
              )}
              <InputField type="textarea" label="Note" name="note" placeholder="Enter a note..." />
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
}
