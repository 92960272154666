import React, { useState } from 'react';
import { BoxContainer, FormSection } from '../../../components';
import { useListInsureds } from '../../insureds/hooks';
import BulkEmailInsuredsTable from './BulkEmailInsuredsTable';
import BulkEmailInsuredsFilters from './BulkEmailInsuredsFilters';
import { TablePagination } from '../../../components/tables';

export default function BulkEmailInsuredsSelection({ formik, policyId, clientId }) {
  const [insuredsFilter, setInsuredsFilter] = useState({
    policyId: policyId,
    clientId: clientId,
    ordering: 'LAST_NAME',
    ids: formik?.values?.allInsuredIds
  });

  const allValidInsuredIds = formik?.values?.allValidInsuredIds;

  const { insureds, loading, error, paginationProps, loadFirstPage } = useListInsureds({
    filters: insuredsFilter
  });

  const onChange = filters => {
    setInsuredsFilter({ ...filters });
    loadFirstPage({ ...insuredsFilter, ...filters });
  };

  return (
    <>
      <FormSection title="Select Recipients">
        <BulkEmailInsuredsFilters defaults={insuredsFilter} onChange={onChange} filterValue={insuredsFilter} />
        <BoxContainer>
          <BulkEmailInsuredsTable
            formik={formik}
            insureds={insureds}
            allValidInsuredIds={allValidInsuredIds}
            loading={loading}
            error={error}
          />
        </BoxContainer>
        <TablePagination {...paginationProps} loading={loading} />
      </FormSection>
    </>
  );
}
