import React from 'react';
import styled from 'styled-components';

const StyledTabBar = styled.div.attrs(props => ({
  className: `d-flex flex-nowrap ${props.flush ? '' : 'px-3 px-md-5'}`
}))`
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  box-shadow: inset 0px -2px 0px 0px ${props => props.theme.gray400};
`;

export default function TabBar({ flush, children }) {
  return <StyledTabBar flush={flush}>{children}</StyledTabBar>;
}
