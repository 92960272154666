import { Render } from '@oforce/global-components';
import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import DynamicCoveragesForm from './DynamicCoveragesForm';
import { useInsuredCreateFormSubmit, useSubcontractedInsuredCreateFormSubmit } from './steps/hooks';
import SelectOrCreateInsuredStep from './steps/SelectOrCreateInsuredStep';
import SelectOrCreateVehicleStep from './steps/SelectOrCreateVehicleStep';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function CoverageForm({
  onCancel,
  onCompleted,
  insured,
  vehicle,
  renderSelectOrCreateVehicleStep = true,
  renderSelectOrCreateInsuredStep = true,
  title,
  policyInsuranceTypes,
  masterId,
  policy
}) {
  const [formState, setFormState] = useState({
    renderSelectOrCreateInsuredStep: renderSelectOrCreateInsuredStep && !insured,
    renderSelectOrCreateVehicleStep: renderSelectOrCreateVehicleStep && !vehicle,
    insured: insured,
    vehicle: vehicle
  });

  const history = useHistory()
  const defaultOnCompleted = ({ createCompleteInsured }) => history.push(`/insureds/${createCompleteInsured?.id}`)
  onCompleted = onCompleted ? onCompleted : defaultOnCompleted

  const {
    formSubmit: directFormSubmit,
    loading: directLoading,
    error: directError
  } = useInsuredCreateFormSubmit({
    insured: formState.insured,
    vehicle: formState.vehicle,
    onCompleted
  });

  const {
    formSubmit: subFormSubmit,
    loading: subLoading,
    error: subError
  } = useSubcontractedInsuredCreateFormSubmit({
    insured: formState?.insured,
    vehicle: formState?.vehicle,
    masterId,
    onCompleted: r => onCompleted(r)
  });

  const loading = directLoading || subLoading;
  const error = directError || subError;
  const formSubmit = masterId ? subFormSubmit : directFormSubmit;

  return (
    <>
      <Row className="justify-content-center">
        <Col xs="12" lg="10" xl="8" style={{ maxWidth: '700px' }}>
          <Render if={formState.renderSelectOrCreateVehicleStep}>
            <SelectOrCreateVehicleStep
              title={title}
              onCancel={onCancel}
              onSubmit={vehicle => {
                setFormState({ ...formState, vehicle, renderSelectOrCreateVehicleStep: false });
              }}
              insured={insured}
              vehicle={vehicle}
            />
          </Render>
          <Render if={!formState.renderSelectOrCreateVehicleStep && formState.renderSelectOrCreateInsuredStep}>
            <SelectOrCreateInsuredStep
              title={title}
              onCancel={onCancel}
              onSubmit={insured => {
                setFormState({ ...formState, insured, renderSelectOrCreateInsuredStep: false });
              }}
              insured={insured}
              vehicle={vehicle}
            />
          </Render>
        </Col>
      </Row>
      <Render if={!formState.renderSelectOrCreateVehicleStep && !formState.renderSelectOrCreateInsuredStep}>
        <DynamicCoveragesForm
          onSubmit={formSubmit}
          loading={loading}
          error={error}
          onCancel={onCancel}
          onBack={() =>
            setFormState({
              ...formState,
              renderSelectOrCreateInsuredStep: renderSelectOrCreateInsuredStep && !insured,
              renderSelectOrCreateVehicleStep: renderSelectOrCreateVehicleStep && !vehicle
            })
          }
          title={title}
          policyInsuranceTypes={policyInsuranceTypes}
          insured={formState?.insured}
          vehicle={formState?.vehicle}
          policy={policy}
        />
      </Render>
    </>
  );
}
