import React from 'react';
import { Row, Col } from 'reactstrap';
import { createFilter } from 'react-select';
import { InputField, InputDateField, ReactSelectField } from '@oforce/global-components';
import { COUNTRY_OPTIONS, administrativeAreaSelectOptions } from '../../../hooks/forms/useCountries';

export default function InsuredFields({ formik, focusRef, formOptions, requiredFields = {}, isCreateMode, hasSsn }) {
  const filterConfig = { matchFrom: 'start' };

  return (
    <>
      <Row form>
        <Col>
          <InputField name="firstName" label="First Name" required={requiredFields['first_name']} innerRef={focusRef} />
        </Col>
        <Col>
          <InputField name="middleName" label="Middle Name" required={requiredFields['middle_name']} />
        </Col>
        <Col>
          <InputField name="lastName" label="Last Name" required={requiredFields['last_name']} />
        </Col>
      </Row>
      <InputField
        name="businessName"
        label="Business Name"
        required={requiredFields['business_name'] || requiredFields['contractor_name']}
      />
      <InputField name="businessName2" label="Business Name 2" required={requiredFields['business_name2']} />
      <InputField name="ssn" label="SSN" disabled={!isCreateMode && !!hasSsn} required={requiredFields['ssn']} />

      <InputDateField name="dateOfBirth" label="Date of Birth" required={requiredFields['date_of_birth']} />
      <InputField name="email" label="Email" type="email" required={requiredFields['email']} />
      <InputField name="mobilePhone" label="Mobile Phone" type="tel" required={requiredFields['mobile_phone']} />
      <InputField
        name="driversLicenseNumber"
        label="Driver's License/CDL Number"
        required={requiredFields['drivers_license_number']}
      />

      <ReactSelectField
        name="driversLicenseState"
        label="Driver's License State"
        options={administrativeAreaSelectOptions(formik?.values?.country?.value, formOptions)}
        required={requiredFields['drivers_license_state']}
        filterOption={createFilter(filterConfig)}
        isClearable
      />

      <InputField name="dispatchLocation" label="Dispatch Location" required={requiredFields['dispatch_location']} />
      <InputField name="address" label="Address" required={requiredFields['address']} />
      <ReactSelectField
        id="country"
        name="country"
        label="Country"
        required={requiredFields['country']}
        options={COUNTRY_OPTIONS}
        onChange={() => {
          formik?.setFieldValue('state', null);
          formik?.setFieldValue('driversLicenseState', null);
        }}
      />
      <Row form>
        <Col>
          <InputField name="city" label="City" required={requiredFields['city']} />
        </Col>
        <Col>
          <ReactSelectField
            name="state"
            label="State"
            options={administrativeAreaSelectOptions(formik?.values?.country?.value, formOptions)}
            required={requiredFields['state']}
            filterOption={createFilter(filterConfig)}
            isClearable
          />
        </Col>
        <Col>
          <InputField name="zip" label="Zip" required={requiredFields['zip']} />
        </Col>
      </Row>
    </>
  );
}
