import React from 'react';
import { Button, useToggle } from '@oforce/global-components';
import { toHeaderCase } from 'js-convert-case';

import { AccountCreateUpdate } from '../../views';
import { BoxContainer, LabelValueGroup, TabPage } from '../../components';
import { HideFromRoles, useActiveAccount } from '../../shared/activeAccount';

export default function AccountDetails({ account }) {
  const {
    ROLES: { POLICY_MANAGER, EXPOSURE_MANAGER }
  } = useActiveAccount();

  const [showUpdateAccount, toggleUpdateAccount] = useToggle(false);
  const hasNotificationEmailAddresses = account?.notificationEmailAddresses?.length > 0;

  return (
    <TabPage
      title="Account Details"
      buttons={
        <HideFromRoles roles={[POLICY_MANAGER, EXPOSURE_MANAGER]}>
          <Button icon={['far', 'pen']} color="info" onClick={toggleUpdateAccount}>
            Edit
          </Button>
        </HideFromRoles>
      }
    >
      <BoxContainer className="px-3 pt-2 pb-1">
        <LabelValueGroup label="Name" value={account?.name} />
        <LabelValueGroup label="Type" value={toHeaderCase(account?.type)} />
        <LabelValueGroup
          label="Notification Recipients"
          value={
            hasNotificationEmailAddresses
              ? account?.notificationEmailAddresses?.map(email => <div key={email}>{email}</div>)
              : null
          }
        />
        <LabelValueGroup label="Allow Report Access to Client" value={account?.allowReportAccessToClient ? 'Yes' : 'No'} />
      </BoxContainer>

      <AccountCreateUpdate
        isOpen={showUpdateAccount}
        toggle={toggleUpdateAccount}
        account={account}
        onCompleted={() => toggleUpdateAccount()}
      />
    </TabPage>
  );
}
