import React from 'react';
import { Steps } from 'react-step-builder';
import { Render } from '@oforce/global-components';
import { useParams, useHistory } from 'react-router-dom';

import { MultiStepForm } from '../../../components';

import {
  CoverageParameterTypeFormFields,
  CoverageParameterDetailsFormFields,
  CoverageParameterValidationFormFields
} from '../components';

import { useCoverageParametersFormSteps, useCoverageParametersFormSubmit } from '../hooks';

export default function CreateUpdate({ coverageParameters, productId, policyId, isProduct }) {
  const history = useHistory();
  const { coverageParameterId } = useParams();
  const coverageParameter = coverageParameters?.find(param => param.id === coverageParameterId);
  const isCreateMode = !coverageParameter;
  const { formSteps } = useCoverageParametersFormSteps({ coverageParameter });

  const onCompletedUrlPath = isProduct ? `/products/${productId}` : `/policies/${policyId}`;
  const { formSubmit, creating, createError, updating, updateError } = useCoverageParametersFormSubmit({
    coverageParameter,
    productId,
    policyId,
    isProduct,
    onCompleted: () => history.push(`${onCompletedUrlPath}/configuration/coverage-parameters`),
    isCreateMode
  });

  return (
    <MultiStepForm
      title={`${isCreateMode ? 'Create' : 'Rename'} Coverage Parameter`}
      formSteps={formSteps}
      formSubmit={formSubmit}
      formProps={{ isCreateMode }}
      onCancel={() => history.push(`${onCompletedUrlPath}/configuration/coverage-parameters`)}
      submitButtonText={isCreateMode ? 'Create' : 'Save Changes'}
      submitButtonProps={{ loading: creating || updating, loadingText: isCreateMode ? 'Creating...' : 'Updating...' }}
      showButtonsOnBottom
      error={createError || updateError}
    >
      {formProps => (
        <>
          <Render if={isCreateMode}>
            <Steps>
              <CoverageParameterTypeFormFields {...formProps} />
              <CoverageParameterDetailsFormFields {...formProps} />
              <CoverageParameterValidationFormFields {...formProps} />
            </Steps>
          </Render>
          <Render if={!isCreateMode}>
            <Steps>
              <CoverageParameterDetailsFormFields {...formProps} />
            </Steps>
          </Render>
        </>
      )}
    </MultiStepForm>
  );
}
