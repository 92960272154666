import { useToggle } from '@oforce/global-components';
import React from 'react';
import { Tooltip } from 'reactstrap';

export default function FieldTooltip({ field, tooltips }) {
  const [show, toggle] = useToggle(false);
  const tooltip = tooltips?.find(({name}) => name === field || field.endsWith(name))

  if (tooltip) return (
    <Tooltip key={`badge-${field}`} target={`badge-${field}`} placement="top" isOpen={show} toggle={toggle}>
      <p>{tooltip.doc}</p>
      <p>{tooltip.example}</p>
    </Tooltip>
  );
  else return null;
}
