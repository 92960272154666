import React from 'react';
import { SearchBy } from '../../../components';
import { usePolicySearchByOptions } from '../hooks';

export default function PoliciesSearchBy({ onChange, className }) {
  const { policySearchByOptions, loading } = usePolicySearchByOptions();

  const onFilterChange = incomingFilters => {
    const searchBy = incomingFilters.reduce((acc, { field: { value: searchField }, value: searchValue }) => {
      if (!!searchValue) return [...acc, { searchField, searchValue }];
      return acc;
    }, []);

    if (searchBy.length > 0) {
      onChange({ searchBy });
    } else {
      onChange({ searchBy: [] });
    }
  };

  if (loading) return null;
  return (
    <SearchBy
      className={className}
      label={'Search by'}
      onFilterChange={onFilterChange}
      name="policies-search-by"
      fieldOptions={policySearchByOptions}
    />
  );
}
