import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ToastContent({
  title = 'Some title',
  showTitle = true,
  titleProps,
  message = 'Our message',
  icon,
  iconProps,
  children
}) {
  return (
    <div>
      {showTitle && (
        <h6 {...titleProps}>
          {icon && (
            <FontAwesomeIcon icon={icon} className="mb-1 mr-2" {...iconProps} />
          )}
          {title}
        </h6>
      )}
      <div className={`${children ? 'mb-3' : ''}`}>{message}</div>
      {children}
    </div>
  );
}
