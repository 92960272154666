import { Render } from '@oforce/global-components';
import moment from 'moment';
import React from 'react';
import { Row, Col } from 'reactstrap';

export default function Details({ invoice }) {
  return (
    <Row>
      <Col xs="12">
        <div className="border-left border-gray-800 border-width-2 px-3 mb-4 mt-2">
          <dl className="row mb-0">
            <dt className="col-6 col-md-4 fs-sm">Product</dt>
            <dd className="col-6 col-md-8 mb-0">
              <div className="fs-sm">{invoice?.product?.name}</div>
            </dd>
          </dl>
          <dl className="row mb-0">
            <dt className="col-6 col-md-4 fs-sm">Policy Number</dt>
            <dd className="col-6 col-md-8 mb-0">
              <div className="fs-sm">{invoice?.policyNumber || 'None'}</div>
            </dd>
          </dl>
          <dl className="row mb-0">
            <dt className="col-6 col-md-4 fs-sm">Service Dates</dt>
            <dd className="col-6 col-md-8 mb-0">
              <div className="fs-sm">
                <span className="font-weight-500">{moment(invoice?.serviceStartDate).format('MM/DD/YYYY')}</span> –{' '}
                <span className="font-weight-500">{moment(invoice?.serviceEndDate).format('MM/DD/YYYY')}</span>
              </div>
            </dd>
          </dl>
          <Render if={!!invoice?.notes}>
            <dl className="row mt-3">
              <dt className="col-6 col-md-4 fs-sm">Note</dt>
              <dd className="col-6 col-md-8 mb-0">
                <div className="fs-sm">{invoice?.notes || 'None'}</div>
              </dd>
            </dl>
          </Render>
        </div>
      </Col>
    </Row>
  );
}
