import * as yup from 'yup';
import { useChangeElection } from '.';
import { useFormOptions } from '../../../hooks';

import { usePolicyPricingOptions } from '../../../components/sharedComponents/pricing';
import { usePricingOptionAddOns } from '../../../views/policyInsureds/hooks';

export default function useChangeElectionForm({ currentPricingOptionId, election, policy, onCompleted }) {
  const { changeElection, loading, error } = useChangeElection({
    election,
    onCompleted
  });

  const { optionalAddOns } = usePricingOptionAddOns({ pricingOptionId: currentPricingOptionId });
  const { selectOptions: pricingOptions, loading: loadingPricingOptions } = usePolicyPricingOptions({
    policyId: policy?.id
  });

  const formOptions = useFormOptions({
    options: [{ name: 'policyPricing', options: pricingOptions, loading: loadingPricingOptions }]
  });

  const currentAddOnIds = election?.addOns?.map(({ id }) => id) || [];

  const initialValues = {
    pricingOption: pricingOptions?.find(x => x.value === currentPricingOptionId) || null,
    addOns: optionalAddOns
      ?.map(({ label, id }) => ({ label, value: id }))
      .filter(({ value }) => currentAddOnIds.includes(value))
  };

  const handleSubmit = values => {
    changeElection({
      pricingOptionId: values?.pricingOption?.value,
      addOnIds: values?.addOns?.map(({ value }) => value)
    });
  };

  const validationSchema = yup.object({
    pricingOption: yup.object().required('Required'),
    addOns: yup.array().of(yup.object()).nullable()
  });

  return {
    validationSchema,
    initialValues,
    handleSubmit,
    loading,
    error,
    formOptions
  };
}
