import React from 'react';
import { Form } from 'reactstrap';
import { FileInputField } from '@oforce/global-components';

export default function InvoiceTemplateForm({ loading }) {
  return (
    <Form>
      <fieldset disabled={loading}>
        <FileInputField
          required
          accept=".pdf"
          id="document"
          label="Invoice Template File"
          name="document"
          placeholder={'Choose PDF file'}
        />
      </fieldset>
    </Form>
  );
}
