import { gql, useQuery } from '@apollo/client';

export const GET_POLICY_EFFECTIVE_DATES = gql`
  query policyEffectiveDates($id: ID!) {
    policy(id: $id) {
      id
      effectiveDate
      expirationDate
      enableCustomTerminationDates
    }
  }
`;

export default function usePolicy({ id }) {
  const { data, loading, error } = useQuery(GET_POLICY_EFFECTIVE_DATES, {
    variables: { id },
    fetchPolicy: 'cache-and-network'
  });

  return {
    enableCustomTerminationDates: data?.policy?.enableCustomTerminationDates,
    effectiveDate: data?.policy?.effectiveDate,
    expirationDate: data?.policy?.expirationDate,
    loading,
    error
  };
}
