import React from 'react';
import { backendNameToLabel } from '../../utils/formatting';
import { Tr, Td } from '@oforce/global-components';
import { useToggle } from '../../hooks';
import { ActionDropdown } from '../table-2.0';
import { useAccountWebhookEvents, useRemoveAccountWebhook, useUpdateAccountWebhook } from '../../views/account/hooks';
import { AccountWebhookUpdate, AccountWebhookRemove } from '.';
import { ShowForRoles, useActiveAccount } from '../../shared/activeAccount';

export default function AccountWebhookRow({ webhook = {}, onError }) {
  const {
    id: activeAccountId,
    ROLES: { OWNER, COLLABORATOR }
  } = useActiveAccount();
  const { id, url, authToken, events = [] } = webhook;
  const [updateIsOpen, toggleUpdateWebhook] = useToggle(false);
  const [removeIsOpen, toggleRemoveWebhook] = useToggle(false);
  const { accountWebhookEventOptions } = useAccountWebhookEvents();

  const {
    removeAccountWebhook,
    loading: removeLoading,
    error: removeError
  } = useRemoveAccountWebhook({
    webhookId: id,
    accountId: activeAccountId,
    onError
  });

  const {
    updateAccountWebhook,
    loading: updateLoading,
    error: updateError
  } = useUpdateAccountWebhook({
    webhookId: id,
    accountId: activeAccountId,
    onError
  });

  const initialValues = {
    url,
    authToken: authToken ? authToken : '',
    events: accountWebhookEventOptions.filter(({ value }) => events.includes(value))
  };

  const friendlyEvents = events.reduce((acc, value) => {
    if (acc === '') return backendNameToLabel(value);
    return `${acc}, ${backendNameToLabel(value)}`;
  }, '');

  return (
    <Tr>
      <Td>{url}</Td>
      <Td>{friendlyEvents}</Td>
      <Td>
        <ShowForRoles roles={[OWNER, COLLABORATOR]}>
          <ActionDropdown onRemove={toggleRemoveWebhook} onEdit={toggleUpdateWebhook} />
        </ShowForRoles>
      </Td>
      {removeIsOpen && (
        <AccountWebhookRemove
          isOpen={removeIsOpen}
          toggle={toggleRemoveWebhook}
          loading={removeLoading}
          error={removeError}
          handleSubmit={() => removeAccountWebhook({ webhookId: id })}
        />
      )}

      {updateIsOpen && (
        <AccountWebhookUpdate
          isOpen={updateIsOpen}
          toggle={toggleUpdateWebhook}
          loading={updateLoading}
          error={updateError}
          initialValues={initialValues}
          handleSubmit={updateAccountWebhook}
        />
      )}
    </Tr>
  );
}
