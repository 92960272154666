import React from 'react';
import { ReactSelect, ReactSelectField } from '@oforce/global-components';
import { useProductSelect } from '../hooks';

export default function ProductSelect({
  isFormik,
  selectedProductId,
  onChange,
  emptyOption,
  ...rest
}) {
  const { productOptions = [] } = useProductSelect({ emptyOption });
  const id = selectedProductId ? selectedProductId : '';
  const selectedOption = productOptions.find(option => option.value === id);

  if (isFormik) {
    return (
      <ReactSelectField
        options={productOptions}
        name="product"
        label="Product"
        placeholder="Choose Product..."
        {...rest}
      />
    );
  }

  return (
    <ReactSelect
      options={productOptions}
      name="product"
      label="Product"
      placeholder="Choose Product..."
      value={selectedOption}
      onChange={onChange}
      {...rest}
    />
  );
}
