import { gql, useQuery } from '@apollo/client';

export const GET_INSURANCE_TYPE_ACRONYMS = gql`
  query insuranceTypeAcronyms($lowerCase: Boolean) {
    insuranceTypeAcronyms(lowerCase: $lowerCase)
  }
`;

export default function useInsuranceTypeAcronyms() {
  const { data, loading, error } = useQuery(GET_INSURANCE_TYPE_ACRONYMS, { variables: { lowerCase: true } });

  return {
    insuranceTypeAcronyms: data?.insuranceTypeAcronyms,
    loading,
    error
  };
}
