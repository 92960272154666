import { Formik } from 'formik';
import React, { useRef } from 'react';
import { Modal } from '@oforce/global-components';

import { GraphQLErrors } from '../../../components';
import { VehicleTypeForm } from '../../../views/configurationVehicleTypes/components';

import { useCreateVehicleTypeForm } from '../hooks';

export default function CreateVehicleType({ showCreateVehicleType, toggleCreateVehicleType }) {
  const { validationSchema, initialValues, handleSubmit, loading, error } = useCreateVehicleTypeForm({
    onCreation: toggleCreateVehicleType
  });

  const nameInputRef = useRef(null);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {formik => (
        <Modal
          isOpen={showCreateVehicleType}
          toggle={toggleCreateVehicleType}
          onOpened={() => {
            nameInputRef && nameInputRef.current.focus();
            formik.handleReset();
          }}
          title="Create Vehicle Type"
          loading={loading}
          submitButtonText="Create"
          submitButtonProps={{ loadingText: 'Creating...' }}
          onSubmit={formik.handleSubmit}
          submitButtonDisabled={formik.isSubmitting}
        >
          <GraphQLErrors error={error} />
          <VehicleTypeForm loading={loading} focusRef={nameInputRef} />
        </Modal>
      )}
    </Formik>
  );
}
