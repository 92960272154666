import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Render } from '@oforce/global-components';
import classNames from 'classnames';
import React from 'react';

export default function ProfileHeader({ name, avatar, email }) {
  return (
    <div className="d-flex flex-column align-items-start px-3 px-md-5 py-3 py-md-4">
      <div className="d-flex justify-content-start align-items-center">
        <UserAvatar avatar={avatar} size="lg" />
        <span>
          <UserName name={name} size="lg" />
          <UserEmail email={email}></UserEmail>
        </span>
      </div>
    </div>
  );
}

export const UserName = ({ name, size }) => {
  return <div className={`font-weight-500 ${size === 'lg' ? 'fs-xxl' : 'fs-md'}`}>{name}</div>;
};

export const UserEmail = ({ email, size }) => {
  return <div className={`${size === 'lg' ? 'fs-xxl' : 'fs-md'}`}>{email}</div>;
};

export const UserAvatar = ({ avatar, size, className = '' }) => {
  const spanStyle = size === 'lg' ? { width: 100, height: 100 } : { width: 42, height: 42 };
  const imgStyle = size === 'lg' ? undefined : { width: 44, height: 44 };

  return (
    <>
      <Render if={!!avatar}>
        <span
          className={classNames(
            `rounded-circle bg-black text-white p-3 d-inline-flex align-items-center justify-content-center overflow-hidden`,
            { 'mr-4': !className },
            className
          )}
          style={spanStyle}
        >
          <img style={imgStyle} src={avatar} alt="" />
        </span>
      </Render>
      <Render if={!avatar}>
        <span className="mr-3">
          <FontAwesomeIcon icon={['fas', 'user-circle']} size="3x" className="text-gray-400" />
        </span>
      </Render>
    </>
  );
};
