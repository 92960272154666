import React from 'react';
import { Helmet } from 'react-helmet';
import { Button } from '@oforce/global-components';
import { Switch, Route, useRouteMatch, useLocation, useHistory } from 'react-router-dom';

import { TabPage } from '../../components/tabs';
import { PageHeader, PageTitle, PageBody } from '../../components/page-components';

import { Policy } from '..';
import { usePolicies } from './hooks';
import { PoliciesTable, PolicyCreate } from './components';
import { HideFromRoles, HideFromTypes, useActiveAccount } from '../../shared/activeAccount';

export default function Policies() {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { state } = useLocation();
  const {
    ROLES: { EXPOSURE_MANAGER, OBSERVER },
    TYPES: { BROKER, CLIENT, CLAIMS_ADJUSTER }
  } = useActiveAccount();

  const { policies, loading, error, refetch, paginationProps, policyFilter, changePolicyFilter } = usePolicies({
    pageSize: 20,
    initialFilters: { activeStatus: 'ACTIVE', ...state } || {}
  });

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Helmet>
          <title>Policies • IC Insure</title>
        </Helmet>
        <PageHeader>
          <PageTitle>Policies</PageTitle>
        </PageHeader>
        <PageBody>
          <TabPage
            title=""
            buttons={
              <HideFromTypes types={[BROKER, CLIENT, CLAIMS_ADJUSTER]}>
                <HideFromRoles roles={[EXPOSURE_MANAGER, OBSERVER]}>
                  <Button
                    color="info"
                    icon={['far', 'plus']}
                    className="ml-2"
                    onClick={() => history.push('/policies/new')}
                  >
                    Create
                  </Button>
                </HideFromRoles>
              </HideFromTypes>
            }
          >
            <PoliciesTable
              policies={policies}
              loading={loading}
              error={error}
              policyFilter={policyFilter}
              changePolicyFilter={changePolicyFilter}
              paginationProps={paginationProps}
            />
          </TabPage>
        </PageBody>
      </Route>
      <Route path={`${path}/new`}>
        <Helmet>
          <title>Create Policy • IC Insure</title>
        </Helmet>
        <PageHeader>
          <PageTitle>Policies</PageTitle>
        </PageHeader>
        <PageBody>
          <PolicyCreate refetchPolicies={refetch} />
        </PageBody>
      </Route>
      <Route path={`${path}/:policyId`}>
        <Policy />
      </Route>
    </Switch>
  );
}
