import React from 'react';
import { SearchBy } from '../../../components';
import { useNotificationSearchByOptions } from '../hooks';

export default function NotificationSearchBy({ onChange, className }) {
  const { notificationSearchByOptions, loading } = useNotificationSearchByOptions();

  const onFilterChange = incomingFilters => {
    const searchBy = incomingFilters.reduce((acc, { field: { value: searchField }, value: searchValue }) => {
      if (!!searchValue) return [...acc, { searchField, searchValue }];
      return acc;
    }, []);

    if (searchBy.length > 0) {
      onChange({ searchBy });
    } else {
      onChange({ searchBy: [] });
    }
  };

  if (loading) return null;

  return (
    <SearchBy
      className={className}
      onFilterChange={onFilterChange}
      name="notification-search-by"
      fieldOptions={notificationSearchByOptions}
      maxFilters={notificationSearchByOptions?.length - 1}
      defaultValue={notificationSearchByOptions?.find(option => option.value === 'FIRST_NAME')}
    />
  );
}
