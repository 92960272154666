import React from 'react';
import { AddOns } from '.';

export default function ProductElectionCell({ name, label, addOnsSource }) {
  return (
    <>
      <div className="fs-xs text-muted">{name}</div>
      <div className="fs-sm">
        {label}
        {addOnsSource?.length > 0 && <AddOns addOns={addOnsSource} />}
      </div>
    </>
  );
}
