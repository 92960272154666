import React from 'react';
import * as yup from 'yup';
import { AddOnForm } from '.';
import { useAddOnCreate, useAddOnValidationSchema } from '../hooks';
import { Steps } from 'react-step-builder';
import { CustomDataStep } from '../../productCreateUpdate/components';
import { MultiStepForm } from '../../../components';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { usePolicyPricingOptions } from '../../../components/sharedComponents/pricing';
import { useFormOptions } from '../../../hooks';

export default function AddOnCreate({ policy }) {
  const validationSchema = useAddOnValidationSchema();
  const history = useHistory();
  const { selectOptions } = usePolicyPricingOptions({ policyId: policy?.id });
  const { loading, error, initialValues, handleSubmit } = useAddOnCreate({
    policy,
    onCompleted: () => history.goBack()
  });
  const formOptions = useFormOptions({
    options: [{ name: 'pricingSelectOptions', options: selectOptions }]
  });

  return (
    <MultiStepForm
      title="Create Add-On"
      formOptions={formOptions}
      formSteps={[
        { name: 'Add-On Details', initialValues, validationSchema },
        {
          name: 'Add-On Custom Data',
          initialValues: { customData: {} },
          validationSchema: yup.object().shape({
            customData: yup.object()
          })
        }
      ]}
      formSubmit={handleSubmit}
      onCancel={() => history.goBack()}
      submitButtonText="Create Add-On"
      submitButtonProps={{ loading, loadingText: 'Creating...' }}
      showButtonsOnBottom
      error={error}
    >
      {formProps => (
        <>
          <Steps>
            <AddOnForm {...formProps} />
            <CustomDataStep title="Add-On Custom Data" {...formProps} />
          </Steps>
        </>
      )}
    </MultiStepForm>
  );
}
