import moment from 'moment';

export function displayGuardedLimit(limit) {
  if (limit === 0) return '0 days';
  else if (limit) return `${limit} days`;
  else if (!limit) return 'None';
}

export function guardLimit(limit) {
  if (limit === 0) return 0;
  else if (limit) return limit;
  else if (!limit) return 30;
}

export function guardTerminationLimit(limit) {
  if (!limit) return 0;
  else return limit;
}


export function backDateMessage(minDate, limit = 30) {
  const date = new moment(minDate);
  const dateString = date.format('MM/DD/YYYY');
  return `Select ${dateString} or later. This policy limits backdating to ${limit} days prior to today's date.`;
}
