import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ImportFileTemplateLink({ insuranceType }) {
  const file =
    insuranceType?.type === 'INSURED' && insuranceType?.requireVehicle === false
      ? '/insured-exposures.csv'
      : '/vehicle-exposures.csv';

  const fileName =
    insuranceType?.type === 'INSURED' && insuranceType?.requireVehicle === false
      ? 'insured-exposures.csv'
      : insuranceType?.type === 'VEHICLE'
      ? 'vehicle-exposures.csv'
      : 'insured-with-vehicle-exposures.csv';

  return (
    <a className="text-info p-0 btn btn-link btn-sm fs-xs mt-1 mb-3" href={file} download={fileName} rel="help">
      <FontAwesomeIcon icon={['far', 'arrow-to-bottom']} className="mr-2 mb-1" />
      Download Example Template
    </a>
  );
}
