import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { GET_ACCOUNT_WEBHOOKS } from './useAccountWebhooks';

export const REMOVE_ACCOUNT_WEBHOOK = gql`
  mutation removeAccountWebhook($webhookId: ID!) {
    removeAccountWebhook(id: $webhookId) {
      id
      url
      events
      authToken
    }
  }
`;

function useRemoveAccountWebhook({ onCompleted = () => {}, onError = () => {}, webhookId, accountId }) {
  const [remove, { loading, error }] = useMutation(REMOVE_ACCOUNT_WEBHOOK, {
    onCompleted,
    onError,
    refetchQueries: [{ query: GET_ACCOUNT_WEBHOOKS, variables: { accountId } }]
  });

  return {
    loading,
    error,
    removeAccountWebhook: () => remove({ variables: { webhookId } })
  };
}

export default useRemoveAccountWebhook;
