import * as yup from 'yup';

const DOCUMENT_VALIDATION = {
  SUPPORTED_FORMATS: ['text/csv'],
  MAX_FILE_SIZE: 50000000
};

export default function useCsvImport() {
  const validationSchema = yup.object().shape({
    document: yup.mixed().when('$document', (_value, schema) =>
      schema
        .required('Required')
        .test('fileType', 'File must be a CSV', values =>
          values
            ?.map(value => DOCUMENT_VALIDATION.SUPPORTED_FORMATS.includes(value?.type))
            .reduce((acc, valid) => acc && valid, true)
        )
        .test('fileSize', 'File must be less than 50 MB', values =>
          values
            ?.map(value => value && value.size < DOCUMENT_VALIDATION.MAX_FILE_SIZE)
            .reduce((acc, valid) => acc && valid, true)
        )
    )
  });

  const initialValues = {
    document: null
  };

  return { initialValues, validationSchema };
}
