import 'codemirror-mode-elixir';

import generator from './generator';
import generateBaseClient from './baseClient';

const snippet = {
  language: 'Elixir',
  codeMirrorMode: 'elixir',
  name: 'HTTPoison',
  options: [
    {
      id: 'generate_client',
      label: 'Generate API Client',
      initial: false,
    },
  ],
  generate: (input) => {
    const { options } = input;
    const code = options.generate_client
      ? [generateBaseClient(input), generator(input)]
      : [generator(input)];
    return code.join('');
  },
};

export default snippet;
