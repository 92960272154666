import React, { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useSyncPricingOptionsJobContext } from '../../context';
import { ToastContent } from '../../components/react-toastify';
import { useHistory } from 'react-router-dom';
import { Button } from '@oforce/global-components';

export default function SyncPricingOptionsJob() {
  const { jobId, productId, message, finished, clearJobId, error, jobErrors = [] } = useSyncPricingOptionsJobContext();
  const history = useHistory();
  const toastId = useRef(null);

  useEffect(() => {
    if (!toast.current && jobId) {
      toastId.current = toast(<Synchronizing />, {
        hideProgressBar: false,
        pauseOnHover: true,
        autoClose: false
      });
      history.push(`/products/${productId}/pricing`);
    }
  }, [toastId, jobId, clearJobId, history, productId]);

  useEffect(() => {
    if (!toastId.current) {
      return;
    }

    if (!finished && (error || jobErrors.length !== 0)) {
      toast.update(toastId.current, {
        render: () => <ErrorSynchronizingPricingOptions error={jobErrors || error} />,
        autoClose: 4000,
        progressClassName: 'bg-danger'
      });
    } else if (!finished) {
      toast.update(toastId.current, {
        render: () => <Synchronizing message={message} />,
        autoClose: false
      });
    } else if (finished) {
      toast.update(toastId.current, {
        render: () => <PricingOptionSyncFinished message={message} productId={productId} errors={jobErrors} />,
        autoClose: 4000,
        progressClassName: 'bg-success'
      });
    }
    // eslint-disable-next-line
  }, [toastId, finished, message, error, jobErrors]);

  return <></>;
}

const Synchronizing = ({ message }) => {
  let title = message ?? 'Synchronizing Pricing Options';

  return (
    <ToastContent
      title={title}
      icon={['far', 'spinner-third']}
      iconProps={{ spin: true }}
      message="Allow us some time to synchronize your pricing options."
    />
  );
};

const ErrorSynchronizingPricingOptions = ({ error }) => {
  return (
    <ToastContent
      title="Error Synchronizing Pricing Options"
      titleProps={{ className: 'text-danger' }}
      icon={['fas', 'times-circle']}
      message={error.message || error}
    />
  );
};

const PricingOptionSyncFinished = ({ message, errors, productId }) => {
  const hadErrors = errors && errors.length > 0;
  const history = useHistory();

  return (
    <ToastContent
      title={message || 'Pricing Option Synchronization Finished'}
      titleProps={{ className: !hadErrors ? 'text-success' : 'text-danger' }}
      icon={!hadErrors ? ['fas', 'check-circle'] : ['far', 'exclamation-triangle']}
      message={
        !hadErrors ? 'Your Pricing Options Synchronized Successfully' : 'Synchronizing Your Pricing Options Failed'
      }
    >
      {!hadErrors && (
        <Button
          color="link"
          size="sm"
          icon={['far', 'search']}
          className="mr-2"
          onClick={() => history.push(`/products/${productId}`)}
        >
          View Source Product
        </Button>
      )}
      {hadErrors &&
        errors.map((error, index) => (
          <div key={index}>
            {index + 1}. {error}
          </div>
        ))}
    </ToastContent>
  );
};
