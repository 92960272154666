import React from 'react';
import styled from 'styled-components';
import { PlaceholderText } from '@oforce/global-components';
import classNames from 'classnames';

const StyledLabelValueGroup = styled.li`
  border-bottom: ${props => (props.$showBorder ? `1px solid ${props.theme.gray300}` : 'none')};

  &:last-of-type {
    border-bottom: none;
  }
`;

export default function LabelValueGroup({
  label,
  value,
  pad = true,
  showBorder = true,
  labelWidth = '270px',
  placeholder = 'None',
  className,
  children
}) {
  const showPlaceholder = !value && !children;
  const showValue = (!!value && !children) || (!!value && !!children);
  const showChildren = !value && children;
  const labelValueGroupClasses = classNames('list-unstyled d-flex flex-column flex-md-row', { 'py-2': pad }, className);

  return (
    <StyledLabelValueGroup className={labelValueGroupClasses} $showBorder={showBorder}>
      <div className="fs-sm font-weight-500 mb-0" style={{ minWidth: labelWidth }}>
        {label}
      </div>
      <div>
        {showValue && <Value>{value}</Value>}
        {showPlaceholder && <PlaceholderText>{placeholder}</PlaceholderText>}
        {showChildren && children}
      </div>
    </StyledLabelValueGroup>
  );
}

const Value = ({ children }) => <div className="fs-sm font-weight-400">{children}</div>;
