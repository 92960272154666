import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';

export const GET_EXPOSURE_ELECTION_SUBCONTRACTS = gql`
  query ExposureElectionSubcontracts($id: ID!) {
    exposureElectionSubcontracts(exposureElectionId: $id) {
      id
      insuredId
      status
      terminationDate
      pricingRateType
      total
      startDate
      pricingOption {
        id
        label
        rate
        rateMode
        factoredField
        isPercentageFactor
      }
      compliance {
        rejected
        inCompliance
        violations {
          id
          field
          reason
          source
          status
        }
      }
      policy {
        id
        insuranceType {
          id
          normalizedName
        }
      }
      insured {
        firstName
        lastName
        middleName
        businessName
      }
    }
  }
`;

export default function useCoverageSubcontracts({ exposureElectionId }) {
  const { data, loading, error } = useQuery(GET_EXPOSURE_ELECTION_SUBCONTRACTS, {
    skip: !exposureElectionId,
    variables: { id: exposureElectionId }
  });

  return {
    subcontractedCoverages: data?.exposureElectionSubcontracts || [],
    loading,
    error
  };
}
