import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledDashedArea = styled.button.attrs({ className: 'text-center py-2' })`
  width: 100%;
  background: transparent;
  border: 2px dashed ${props => props.theme.gray400};
  cursor: pointer;
  color: ${props => props.theme.gray600};
  &:disabled {
    cursor: default;
    color: ${props => props.theme.gray300} !important;
    border-color: ${props => props.theme.gray300} !important;
  }
  &:hover {
    border-color: ${props => props.theme.gray500};
    color: ${props => props.theme.gray700};
  }
  &:active,
  &:focus {
    outline: 0 !important;
  }
  &:active {
    border-color: ${props => props.theme.gray600};
    color: ${props => props.theme.gray800};
  }
`;

export default function ButtonDashed({
  icon,
  onClick,
  disabled,
  children,
  ...rest
}) {
  return (
    <StyledDashedArea onClick={onClick} disabled={disabled} {...rest}>
      {icon && <FontAwesomeIcon icon={icon} className="mb-1 mr-2" />}
      {children}
    </StyledDashedArea>
  );
}
