import { gql, useMutation } from '@apollo/client';

import { GET_PRODUCT_FEES } from './useFees';
import { GET_PRODUCT_PRICINGS } from '../../../views/productPricing/hooks/useProductPricings';
import { GET_ACCOUNT_TAGS } from '../../../views/account/hooks/useAccountTags';

const CREATE_PRODUCT_FEE = gql`
  mutation CreateProductFee($input: CreateProductFeeInput!) {
    createProductFee(input: $input) {
      id
      name
      rateMode
      rateModeType
      rate
      tags
      appliesToAllPricingOptions
      productPricingIds
      multiplierFeeIds
    }
  }
`;

export default function useCreateFee({ product, onCompleted = () => {}, onError = () => {} }) {
  const [create, { loading, error, reset }] = useMutation(CREATE_PRODUCT_FEE, {
    onCompleted,
    onError,
    refetchQueries: [
      { query: GET_ACCOUNT_TAGS, variables: { accountId: product?.accountId } },
      { query: GET_PRODUCT_PRICINGS, variables: { id: product?.id } },
      { query: GET_PRODUCT_FEES, variables: { id: product?.id } }
    ]
  });

  return {
    createFee: feeInput =>
      create({
        variables: {
          input: { productId: product?.id, ...feeInput }
        }
      }),
    loading,
    error,
    reset
  };
}
