import 'codemirror-mode-elixir';
import { snakeCase } from 'snake-case';
import { pascalCase } from 'pascal-case';

export default function withBaseClient({ serverUrl, context }) {
  return `
defmodule Oforce.${pascalCase(context.apiName)}.GraphQLClient do
  # Add this to your application's children  
  use GenServer

  @timeout :timer.hours(1)

  def start_link(_opts) do
    state = %{token: "${context.accessToken}"}
    GenServer.start_link(__MODULE__, state, name: __MODULE__)
  end

  def get_access_token, do: GenServer.call(__MODULE__, :get_access_token)

  @impl true
  def init(state) do
    Process.send_after(self(), :refresh_token, @timeout)
    {:ok, state}
  end

  @impl true
  def handle_call(:get_access_token, _from , %{token: token} = state) do
    {:reply, token, state}
  end
  
  @impl true
  def handle_info(:refresh_token, %{token: token}) do
    #call auth server for new token
    Process.send_after(self(), :refresh_token, @timeout)
    {:noreply, %{token: token}}
  end

  defmacro __using__(_opts) do
    quote do
      defp url() do
        # change :my_app to your application name and configure this key in config.exs
        case Application.get_env(:my_app, :${snakeCase(
          context.apiName
        )}, :api_url) do
          nil -> "${serverUrl}"
          url -> url
        end
      end

      def execute(document, operation, variables) do
        headers = [Authorization: "Bearer " <> Oforce.Insurance.GraphQLClient.get_access_token()]
        body = Jason.encode!(%{query: document, variables: variables, operationName: operation})

        case HTTPoison.post(url(), body, headers) do
          {:ok, %{status_code: 200, body: body}} -> {:ok, Jason.decode!(body, keys: :atoms)}
          {:error, error} -> {:error, error}
        end
      end
    end
  end
end
`;
}
