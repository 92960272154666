import { useContext } from 'react';
import { gql } from '@apollo/client';

import CurrentUserContext from '../../../context/currentUser/context';

export const CURRENT_USER_FIELDS = gql`
  fragment CurrentUserFields on User {
    id
    avatar
    name
    email
    isAdmin
    hasClientAccountReports
    hasClientCumulioReports
    roles: oforceRoles
    userAccounts {
      id
      role
      account {
        id
        name
        type
      }
    }
  }
`;

export default function useCurrentUser() {
  const currentUser = useContext(CurrentUserContext);

  return currentUser;
}
