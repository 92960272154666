import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { GET_EXPOSURE_ELECTION_SUBCONTRACTS } from './useCoverageSubcontracts';
import { LIST_EXPOSURE_ELECTIONS_FOR_CONTRACT } from './useExposureElectionsForContract';

export const REMOVE_SUBCONTRACT_RELATIONSHIP = gql`
  mutation RemoveSubcontractRelationship($masterId: ID!, $subId: ID!) {
    removeSubcontractRelationship(masterExposureElectionId: $masterId, subExposureElectionId: $subId) {
      id
    }
  }
`;

export default function useRemoveSubcontractRelationship({ election, toggle }) {
  const [remove, { loading, error, reset }] = useMutation(REMOVE_SUBCONTRACT_RELATIONSHIP, {
    onCompleted: toggle,
    onError: () => {},
    refetchQueries: [
      { query: GET_EXPOSURE_ELECTION_SUBCONTRACTS, variables: { id: election.id } },
      {
        query: LIST_EXPOSURE_ELECTIONS_FOR_CONTRACT,
        fetchPolicy: 'network-only',
        variables: { id: election.id, first: 5, policyId: election?.policyId }
      }
    ]
  });

  const handleSubmit = subId => {
    remove({
      variables: {
        masterId: election?.id,
        subId
      }
    });
  };

  return {
    handleSubmit,
    loading,
    error,
    reset
  };
}
