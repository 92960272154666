import { Formik } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import { GraphQLErrors } from '../../../components';
import { DatePickerField, Modal } from '@oforce/global-components';

export default function ShowDocumentPreviewModal({
  show,
  toggle,
  previewDocument,
  data,
  reset,
  loading,
  error,
  showActiveOn
}) {
  const validationSchema = yup.object().shape({ activeOn: yup.date() });
  const initialValues = { activeOn: showActiveOn ? moment() : null };

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={previewDocument}>
      {formik => (
        <Modal
          isOpen={show}
          toggle={toggle}
          title="Preview Document"
          submitButtonText="Preview"
          onSubmit={formik.handleSubmit}
          submitButtonProps={{ loadingText: 'Loading preview...', disabled: !showActiveOn }}
          loading={loading}
          onOpened={() => {
            reset();
            formik.resetForm({ values: initialValues });
            !showActiveOn && previewDocument({ activeOn: null });
          }}
          scrollable
        >
          <GraphQLErrors error={error} />
          {showActiveOn && <DatePickerField fullWidth name="activeOn" label="Active On" format="MM/DD/YYYY" />}
          {!!data && (
            <object data={data?.document?.url} type="application/pdf" width="100%" height="700px">
              Loading PDF
            </object>
          )}
        </Modal>
      )}
    </Formik>
  );
}
