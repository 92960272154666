import React from 'react';
import { useToggle } from '@oforce/global-components';
import { Card as RsCard, CardBody, CardTitle, Tooltip } from 'reactstrap';

export default function Card({ className, name, tooltip, children, title }) {
  const [showTooltip, toggleTooltip] = useToggle();

  return (
    <>
      <RsCard id={`${name}-card`} className={className}>
        <CardBody>
          <CardTitle>{title}</CardTitle>
          {children}
        </CardBody>
      </RsCard>
      <Tooltip isOpen={showTooltip} toggle={toggleTooltip} target={`${name}-card`} placement="bottom">
        {tooltip}
      </Tooltip>
    </>
  );
}
