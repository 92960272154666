import { gql, useMutation } from '@apollo/client';

const REMOVE_VEHICLE_TYPE = gql`
  mutation remove($id: Int!) {
    removeVehicleType(id: $id) {
      id
      name
    }
  }
`;

export default function useRemoveVehicleType({ getVehicleTypesQuery, onCompleted = () => {}, onError = () => {} }) {
  const [remove, { loading, error }] = useMutation(REMOVE_VEHICLE_TYPE, {
    update(cache, { data: { removeVehicleType } }) {
      const { vehicleTypes } = cache.readQuery({
        query: getVehicleTypesQuery
      });
      cache.writeQuery({
        query: getVehicleTypesQuery,
        data: {
          vehicleTypes: vehicleTypes.filter(x => x.id !== removeVehicleType.id)
        }
      });
    },
    onError,
    onCompleted
  });

  return {
    removeVehicleType: id => remove({ variables: { id } }),
    loading,
    error
  };
}
