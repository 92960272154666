import React from 'react';
import { Form } from 'reactstrap';
import { InputDropzoneField } from '@oforce/global-components';

export default function InvoiceTemplateForm({ loading }) {
  return (
    <Form>
      <fieldset disabled={loading}>
        <InputDropzoneField
          required
          accept={[".pdf"]}
          id="document"
          label="Invoice Template File"
          name="document"
          placeholder={'Choose PDF file'}
        />
      </fieldset>
    </Form>
  );
}
