import { useFormOptions } from '../../../../../hooks';
import { useClients } from '../../../../clients/hooks';
import { usePricingOptionAddOns } from '../../../../policyInsureds/hooks';
import { usePolicyPricingOptions } from '../../../../policyPricing/hooks';
import { useExistingExposurePolicyIds, useSearchablePolicyOptions } from '../hooks';

export default function useCoveragesFormOptions({
  policy,
  pricingOption,
  insured,
  vehicle,
  selectedPolicyIds,
  policyInsuranceTypes,
  client,
  effectiveOnDate
}) {
  const policyId = policy?.value;
  const pricingOptionId = pricingOption?.value;

  const {
    defaultOptions,
    loading: loadingPolicyOptions,
    error: policyOptionsError,
    loadOptions
  } = useSearchablePolicyOptions({
    variables: {
      exposureType: policyInsuranceTypes,
      clientId: client?.id,
      effectiveOnDate
    }
  });
  const { options: policyPricingOptions, loading: loadingPolicyPricingOptions } = usePolicyPricingOptions({ policyId });
  const { requiredAddOns = [], loading: loadingRequiredAddOns } = usePricingOptionAddOns({ pricingOptionId });

  const {
    options: clientOptions,
    loading: loadingClientOptions,
    error: clientOptionsError,
    loadOptions: loadClientOptions
  } = useClients();

  const {
    existingExposurePolicyIds = [],
    loading: existingPolicyIdsLoading,
    error: existingPolicyIdsError
  } = useExistingExposurePolicyIds({ insured, vehicle });

  const finalPolicyOptions = defaultOptions
    .filter(policy => !selectedPolicyIds.filter(id => id !== policyId).includes(policy.id))
    .filter(policy => !existingExposurePolicyIds.includes(policy.id));

  const formOptions = useFormOptions({
    options: [
      {
        name: 'policy',
        defaultOptions: finalPolicyOptions,
        loading: loadingPolicyOptions || existingPolicyIdsLoading,
        error: policyOptionsError || existingPolicyIdsError,
        loadOptions
      },
      {
        name: 'client',
        defaultOptions: clientOptions,
        loading: loadingClientOptions,
        error: clientOptionsError,
        loadOptions: loadClientOptions
      },
      { name: 'policyPricing', options: policyPricingOptions, loading: loadingPolicyPricingOptions },
      { name: 'requiredAddOns', options: requiredAddOns, loading: loadingRequiredAddOns }
    ]
  });

  return { formOptions };
}
