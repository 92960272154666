import React from 'react';
import * as yup from 'yup';
import { Steps } from 'react-step-builder';
import { useHistory } from 'react-router-dom';
import { MultiStepForm } from '../../../components';
import { Policies, UpdateDetails } from '.';
import { useClientDocument, useClientDocumentFormOptions, useClientDocumentUpdateSubmit } from '../hooks';
import { useParams } from 'react-router-dom';
import { Loader } from '@oforce/global-components';
import moment from 'moment';

export default function EditDocument({ clientId }) {
  const history = useHistory();
  const { clientDocumentId } = useParams();
  const { clientDocument, loading: docLoading, error: docError } = useClientDocument({ clientDocumentId });
  const { formOptions } = useClientDocumentFormOptions({ clientId });

  const { formSubmit, loading, error } = useClientDocumentUpdateSubmit({
    clientDocumentId: clientDocument?.id,
    onCompleted: () => history.push(`/clients/${clientId}/documents`)
  });

  if (docLoading) return <Loader />;

  const formSteps = [
    {
      name: 'Document Details',
      initialValues: {
        name: clientDocument?.name,
        type:
          formOptions?.documentType?.options.find(option => clientDocument?.document?.type === option.value) ||
          formOptions?.documentType?.options[0],
        effectiveDate: moment(clientDocument?.effectiveDate),
        expirationDate: !!clientDocument?.expirationDate ? moment(clientDocument?.expirationDate) : ''
      },
      validationSchema: yup.object().shape({
        name: yup.string().required('Required'),
        type: yup.string().required('Required'),
        effectiveDate: yup.date().required('Required'),
        expirationDate: yup.date()
      })
    },
    {
      name: 'Policies',
      initialValues: {
        policies:
          formOptions?.policies?.options?.filter(option =>
            clientDocument?.policies?.map(policy => policy?.id)?.includes(option?.value)
          ) || []
      },
      validationSchema: yup.object().shape({
        policies: yup.array().of(yup.object()).required('Required')
      })
    }
  ];

  return (
    <MultiStepForm
      formOptions={formOptions}
      formSteps={formSteps}
      formSubmit={formSubmit}
      title={'Update'}
      onCancel={() => history.push(`/clients/${clientId}/documents`)}
      submitButtonText={'Update'}
      submitButtonProps={{ loading, loadingText: 'Updating...' }}
      showButtonsOnBottom
      error={error || docError}
    >
      {formProps => (
        <Steps>
          <UpdateDetails {...formProps} />
          <Policies {...formProps} />
        </Steps>
      )}
    </MultiStepForm>
  );
}
