import React from 'react';
import { useHistory } from 'react-router-dom';
import { Td, Tr } from '../../../components/table-2.0';
import { dateToClientSideFormat } from '../../../utils/dates';
import { backendNameToLabel } from '../../../utils/formatting';

export default function NotificationJobsRow({ notificationJob }) {
  const history = useHistory();

  return (
    <Tr onClick={() => history.push(`/notifications/${notificationJob?.jobId}`)}>
      <Td>{backendNameToLabel(notificationJob?.templateGroup)}</Td>
      <Td>{notificationJob?.policy?.name}</Td>
      <Td>{notificationJob?.jobId}</Td>
      <Td>{dateToClientSideFormat(notificationJob?.created)}</Td>
    </Tr>
  );
}
