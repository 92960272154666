import React from 'react';
import { Table, TrPlaceholder } from '../../../components/table-2.0';
import { PricingRow } from '.';
import { HideFromRoles, useActiveAccount } from '../../../shared/activeAccount';

export default function PricingsTable({ pricings, product }) {
  const totalColCount = 7;
  const insuranceTypeRequiresVehicle = product?.insuranceType?.requireVehicle;
  const {
    ROLES: { EXPOSURE_MANAGER, POLICY_MANAGER }
  } = useActiveAccount();

  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Price</th>
          <th>Min/Max</th>
          <HideFromRoles roles={[EXPOSURE_MANAGER]}>
            <th>Commission</th>
          </HideFromRoles>
          <th>Fees</th>
          {insuranceTypeRequiresVehicle && <th>Vehicle Types</th>}
          <th>Coverage Parameters</th>
          <HideFromRoles roles={[EXPOSURE_MANAGER, POLICY_MANAGER]}>
            <th></th>
          </HideFromRoles>
        </tr>
      </thead>
      <tbody>
        {pricings?.length === 0 && (
          <TrPlaceholder colSpan={totalColCount} className="bg-gray-100 border-bottom border-width-2 border-gray-300">
            No Pricings
          </TrPlaceholder>
        )}
        {pricings?.map(pricing => (
          <PricingRow key={pricing?.id} pricing={pricing} product={product} />
        ))}
      </tbody>
    </Table>
  );
}
