import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';

// TODO: write backend query for get_outgoing_invitations
// The account_id on an account_invitation is the account doing the inviting (come be a part of my account)

export const GET_ACCOUNT_INVITATIONS = gql`
  query accountInvitations($accountId: ID!) {
    account(id: $accountId) {
      id
      invitations {
        id
        status
        role
        emailAddress
      }
    }
  }
`;

function useAccountInvitations({ accountId }) {
  const { loading, error, data } = useQuery(GET_ACCOUNT_INVITATIONS, {
    skip: !accountId,
    fetchPolicy: "cache-and-network",
    variables: { accountId }
  });

  return {
    loading,
    error,
    pendingInvitations: data?.account?.invitations?.filter(({status}) => status === "PENDING") || [],
    declinedInvitations: data?.account?.invitations?.filter(({status}) => status === "DECLINED") || [],
    canceledInvitations: data?.account?.invitations?.filter(({status}) => status === "CANCELED") || [],
    claimedInvitations: data?.account?.invitations?.filter(({status}) => status === "CLAIMED") || [],
    invitations: data?.account?.invitations || []
  };
}

export default useAccountInvitations;
