import { useState, useCallback, useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';

import { useGoogleAnalytics } from '../../../hooks';
import { usePolicyPricingOptions } from '../../policyPricing/hooks';

const BEGIN_IMPORT_JOB = gql`
  mutation upload($policyId: ID!, $pricingOptionId: ID!, $addOnIds: [ID!], $file: Upload!) {
    importExposures(fileData: $file, policyId: $policyId, pricingOptionId: $pricingOptionId, addOnIds: $addOnIds) {
      count
      errors
    }
  }
`;

export default function useImportFromFile({ policyId, insuranceType, toggle }) {
  const { triggerGaEvent } = useGoogleAnalytics();

  const [data, setData] = useState(null);
  const [errors, setErrors] = useState(null);

  const [file, setFile] = useState(null);
  const [policyOption, setPolicyOption] = useState(null);
  const [selectedAddOns, setProductAddOns] = useState([]);

  const { pricingOptions } = usePolicyPricingOptions({ policyId });

  useEffect(() => {
    setProductAddOns([]);
  }, [policyOption, setProductAddOns]);

  const options = pricingOptions?.map(option => ({
    value: option.id,
    label: option.label
  }));

  const [uploadExposures, { reset }] = useMutation(BEGIN_IMPORT_JOB, {
    onCompleted: data => {
      setData(data);
    },
    onError: e => {
      setErrors(e.graphQLErrors);
    }
  });

  const onFileUpload = e => {
    const fileBlob = e.target.files[0];
    fileBlob && setFile(fileBlob);
  };
  const onPolicyOptionSelect = value => setPolicyOption(value);
  const onAddOnSelect = value => setProductAddOns(value);

  const resetImport = () => {
    setData(null);
    setFile(null);
    setPolicyOption(null);
    setErrors(null);
    toggle();
  };

  const onSuccess = () => {
    handleSubmit();
    triggerGaEvent(`Client Product > Upload ${insuranceType}`, 'Uploaded File');
  };

  const handleSubmit = useCallback(() => {
    setErrors(null);
    uploadExposures({
      variables: {
        policyId,
        pricingOptionId: policyOption.value,
        addOnIds: selectedAddOns.map(selection => selection.value),
        file: file
      }
    });
  }, [file, policyId, policyOption, uploadExposures, selectedAddOns]);

  return {
    data,
    errors,
    options,
    policyOption,
    onPolicyOptionSelect,
    file,
    onFileUpload,
    resetImport,
    onSuccess,
    onAddOnSelect,
    selectedAddOns,
    reset
  };
}
