import React from 'react';
import { InputField } from '@oforce/global-components';

import { FormSection } from '../../../components/forms';

export default function ProductPricingDetailsFields({ focusRef }) {
  return (
    <FormSection title="Details">
      <InputField name="label" label="Label" innerRef={focusRef} required />
      <InputField name="name" label="Name" optional />
    </FormSection>
  );
}
