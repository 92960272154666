import React from 'react';
import { InputRadio, FormSection } from '../../../components';

export default function CoverageParameterTypeFormFields({ formik }) {
  const isPredefined = formik?.values?.isPredefined;

  return (
    <FormSection
      title="Category"
      subtitle="What category of coverage parameter do you want to create?"
      className="mb-0"
    >
      <InputRadio
        id="custom"
        value="custom"
        checked={!isPredefined}
        onClick={() => formik?.setFieldValue('isPredefined', false)}
        name="coverageParameterType"
        title="Custom"
        description="Create your own custom coverage parameter"
      />
      <InputRadio
        id="predefined"
        value="predefined"
        checked={isPredefined}
        onClick={() => formik?.setFieldValue('isPredefined', true)}
        name="coverageParameterType"
        title="Predefined"
        description="Choose from one of our existing coverage parameters with the option to use it for Exposure Validation"
      />
    </FormSection>
  );
}
