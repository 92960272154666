import buildValidationSchema from './validationSchema';
import initialValues from './initialValues';
import * as yup from 'yup';

export default function step(insured, requiredFields, formOptions) {
  const beneficiaryValidationSchema = buildValidationSchema({
    onlyFields: true,
    stakeholderType: 'beneficiary',
    requiredFields: requiredFields
  });
  var validationSchema = yup.object({
    ...beneficiaryValidationSchema
  });

  return {
    name: 'Insured Stakeholders',
    validationSchema: validationSchema,
    initialValues: {
      ...initialValues('beneficiary', insured?.beneficiary, formOptions)
    }
  };
}
