import { gql, useQuery } from '@apollo/client';

export const COUNT_INSUREDS = gql`
  query InsuredCount($electionStatus: ElectionStatus, $inCompliance: Boolean) {
    getInsuredsCount(electionStatus: $electionStatus, inCompliance: $inCompliance)
  }
`;

export default function useCountInsureds({ electionStatus = 'ACTIVE', inCompliance = true }) {
  const { data, loading, error } = useQuery(COUNT_INSUREDS, { variables: { electionStatus, inCompliance } });
  return {
    insuredsCount: data?.getInsuredsCount,
    loading,
    error
  };
}
