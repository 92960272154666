import React from 'react';
import { Steps } from 'react-step-builder';
import { useHistory, useParams } from 'react-router-dom';

import { MultiStepForm } from '../../../components';

import {
  ProductDocumentDetailsFields,
  ProductDocumentPricingFields,
  ProductDocumentRequiredStatesFields
} from '../components';
import { useProductDocumentFormOptions, useProductDocumentFormSteps, useProductDocumentFormSubmit } from '../hooks';

export default function UploadUpdate({ productId, documents }) {
  const history = useHistory();
  const { documentId } = useParams();
  const doc = documents?.find(doc => doc.id === documentId);
  const isCreateMode = !doc;

  const { formOptions } = useProductDocumentFormOptions({ productId });
  const { formSteps } = useProductDocumentFormSteps({ doc, formOptions, isCreateMode });
  const { formSubmit, loading, error } = useProductDocumentFormSubmit({
    productId,
    productDocumentId: documentId,
    isCreateMode,
    onCompleted: () => history.push(`/products/${productId}/documents`)
  });

  return (
    <MultiStepForm
      formOptions={formOptions}
      formSteps={formSteps}
      formSubmit={formSubmit}
      formProps={{ isCreateMode }}
      title={`${isCreateMode ? 'Upload' : 'Edit'} Document`}
      onCancel={() => history.push(`/products/${productId}/documents`)}
      submitButtonText={isCreateMode ? 'Upload' : 'Save'}
      submitButtonProps={{ loading, loadingText: isCreateMode ? 'Uploading...' : 'Saving...' }}
      showButtonsOnBottom
      error={error}
    >
      {formProps => (
        <Steps>
          <ProductDocumentDetailsFields {...formProps} />
          <ProductDocumentPricingFields {...formProps} />
          <ProductDocumentRequiredStatesFields {...formProps} />
        </Steps>
      )}
    </MultiStepForm>
  );
}
