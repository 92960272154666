import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Td, Tr } from '../../../components';
import { normalizeInsuredFullNameLastFirst } from '../../insured/utils';

export default function TransactionMasterSubTitleRow({ masterSubTotal }) {
  const name = normalizeInsuredFullNameLastFirst(masterSubTotal?.masterInsured);

  return (
    <Tr>
      <Td>
        <FontAwesomeIcon icon={['far', 'angle-down']} className="pr-1" />
      </Td>
      <Td colSpan="2">
        <span className="font-weight-500">{name}</span>
      </Td>
      <Td colSpan="3">
        <span className="font-weight-500">Master</span>
      </Td>
    </Tr>
  );
}
