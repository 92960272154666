import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { transparentize } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)``;

const StyledInputRadio = styled.label`
  border: 1px solid ${props => (props.checked ? props.theme.info : props.theme.gray400)};
  box-shadow: inset 0px 0px 0px 1px ${props => (props.checked ? props.theme.info : props.theme.white)};
  background: ${props => (props.checked ? transparentize(0.95, props.theme.info) : props.theme.white)};
  h6 {
    color: ${props => (props.checked ? props.theme.info : props.theme.gray900)};
  }
  p {
    color: ${props => (props.checked ? props.theme.info : props.theme.gray600)};
  }
  input {
    display: none;
  }
  ${StyledFontAwesomeIcon} {
    color: ${props => (props.checked ? props.theme.info : props.theme.gray500)};
  }
  &:hover {
    ${StyledFontAwesomeIcon} {
      color: ${props => (props.checked ? props.theme.info : props.theme.gray600)};
    }
    box-shadow: inset 0px 0px 0px 1px ${props => (!props.checked ? props.theme.gray400 : props.theme.info)};
    background: ${props => !props.checked && props.theme.gray100};
    p {
      color: ${props => !props.checked && props.theme.gray600};
    }
  }
  margin-bottom: 1rem !important;
  &:last-child {
    margin-bottom: 0 !important;
  }
`;

export default function InputRadio({
  id,
  name,
  checked = false,
  title,
  value,
  description,
  active,
  className,
  onClick = () => {}
}) {
  const radioFieldClasses = classNames('p-3 rounded d-flex cursor-pointer', className);
  const radioIcon = checked ? 'dot-circle' : 'circle';

  return (
    <StyledInputRadio active={active} className={radioFieldClasses} checked={checked}>
      <span className="d-flex align-items-center mr-3">
        <StyledFontAwesomeIcon fixedWidth icon={['far', radioIcon]} />
      </span>
      <span className="d-flex flex-column">
        <input id={id} checked={checked} type="radio" name={name} onClick={onClick} value={value} />
        <h6 className="mt-1 mb-1">{title}</h6>
        <p className="mb-0" style={{ maxWidth: '450px' }}>
          {description}
        </p>
      </span>
    </StyledInputRadio>
  );
}
