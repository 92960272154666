import React from 'react';
import { FieldArray } from 'formik';
import { Col, Row, FormGroup, Input } from 'reactstrap';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import ReactMaskedInput from 'react-text-mask';
import { normalizeNumber } from '../../../utils/formatting';
import { BooleanSelect } from '../../../components/forms';
import { useCanadianProvinces, useUSStates } from '../../../hooks';
import {
  InputCurrencyField,
  FieldContainer,
  InputField,
  Label,
  ReactCreatableSelectField,
  InputDateField,
  ReactSelectField,
  FieldLabel,
  Render,
  Button,
  RangePicker
} from '@oforce/global-components';

export default function CoverageParameterInputFields({ formik, formOptions, params }) {
  return params?.map((param, index) => {
    switch (param?.type) {
      case 'LIST':
        return <ListField key={`${param}-${index}`} formik={formik} formOptions={formOptions} param={param} />;

      case 'RANGE':
        return <RangeField key={`${param}-${index}`} formik={formik} param={param} />;

      case 'INDIVIDUAL':
        return valueTypeField({ param, key: `${param}-${index}` });

      default:
        return <InputField key={`${param}-${index}`} name={param.id} label={param.name} />;
    }
  });
}

function valueTypeField({ param, key }) {
  switch (param?.valueType) {
    case 'CURRENCY':
      return <InputCurrencyField key={key} name={param.id} label={param.name} options={{ integerLimit: 10 }} />;

    case 'BOOLEAN':
      return <BooleanField key={key} param={param} />;

    case 'STATE':
      return <StateSelect key={key} param={param} />;

    case 'DATE':
      return <DateField key={key} param={param} />;

    case 'INTEGER':
      return <InputField key={key} name={param.id} label={param.name} type="number" />;

    default:
      return <InputField key={key} name={param.id} label={param.name} />;
  }
}

function DateField({ param }) {
  const { id, name } = param;

  return <InputDateField name={id} label={name} />;
}

function StateSelect({ param, isMulti }) {
  const { options: stateOptions } = useUSStates();
  const { options: provinceOptions } = useCanadianProvinces();
  const options = [...stateOptions, ...provinceOptions];
  const { id, name } = param;

  return <ReactSelectField name={id} label={name} options={options} isMulti={isMulti} isClearable />;
}

function StateListField({ formik, formOptions, param, isMulti }) {
  const { options: stateOptions } = useUSStates();
  const { options: provinceOptions } = useCanadianProvinces();
  const options = [...stateOptions, ...provinceOptions];
  const { id, name } = param;

  return (
    <>
      <FieldLabel name={name} className="mb-1" />
      <ReactSelectField
        required
        label="Include or Exclude States"
        name={`stateMode-${id}`}
        defaultValue={formOptions?.stateMode?.options?.find(
          ({ value }) => value === formik?.values[`stateMode-${id}`]?.value
        )}
        options={formOptions?.stateMode?.options}
        onChange={option => {
          formik.setFieldValue(id, []);
          formik.setFieldValue(
            `stateMode-${id}`,
            formOptions?.stateMode?.options?.find(({ value }) => value === option?.value)
          );
          formik.setFieldValue(`tempStateOptions-${id}`, []);
        }}
      />
      <ReactSelectField
        name={`tempStateOptions-${id}`}
        options={options}
        isMulti={isMulti}
        defaultValue={
          formik?.values[`stateMode-${id}`]?.value === 'include'
            ? formik?.values[id]
            : options?.filter(({ value }) => !formik?.values[id]?.map(({ value }) => value)?.includes(value))
        }
        onChange={stateOptions => {
          formik?.values[`stateMode-${id}`]?.value === 'include'
            ? formik.setFieldValue(id, stateOptions)
            : formik.setFieldValue(
                id,
                options?.filter(({ value }) => !stateOptions?.map(({ value }) => value)?.includes(value))
              );
        }}
        isClearable
      />
    </>
  );
}

function BooleanField({ param }) {
  const { id, name } = param;

  return <BooleanSelect name={id} label={name} isClearable />;
}

function ListField({ formik, formOptions, param }) {
  const { id, name, valueType } = param;
  if (valueType === 'STATE') return <StateListField formik={formik} formOptions={formOptions} param={param} isMulti />;
  if (valueType === 'DATE') return <DateListField param={param} formik={formik} />;

  return (
    <ReactCreatableSelectField
      options={formik.values[id]}
      isMulti
      name={id}
      label={name}
      onCreateOption={value => {
        const values = formik.values[id] || [];
        values.push({ value, label: value });
        formik.setFieldValue(id, values);
      }}
      placeholder="Enter Values..."
    />
  );
}

function DateListField({ param, formik }) {
  const { id, name } = param;
  const dateValues = formik?.values[id] || [];
  return (
    <FieldArray name={id}>
      {arrayHelpers => (
        <FormGroup>
          <FieldLabel name={name} />
          {dateValues?.map((_date, index) => {
            const arrayLength = dateValues?.length - 1;

            return (
              <div key={`${id}-${index}`} className="d-flex justify-content-between mb-1 align-items-start">
                <InputDateField name={`${id}.${index}`} />
                <Render if={index !== arrayLength}>
                  <Button
                    color="danger"
                    outline
                    icon={['far', 'minus']}
                    className="ml-2"
                    onClick={() => arrayHelpers.remove(index)}
                  />
                </Render>
                <Render if={index === arrayLength}>
                  <Button
                    color="info"
                    outline
                    icon={['far', 'plus']}
                    className="ml-2"
                    onClick={() => {
                      arrayHelpers.push('');
                    }}
                  />
                </Render>
              </div>
            );
          })}
        </FormGroup>
      )}
    </FieldArray>
  );
}

function DateRange({ param, formik }) {
  const { id, name } = param;
  const setDateFilter = dates => {
    if (dates) {
      const [startDate, endDate] = dates;

      let start = null;
      let end = null;

      if (startDate) start = startDate.format('YYYY-MM-DD');
      if (endDate) end = endDate.format('YYYY-MM-DD');

      formik.setFieldValue(id, [start, end]);
    }
  };

  return (
    <FieldContainer id={id} name={id} label={name}>
      <RangePicker id={id} onChange={setDateFilter} />
    </FieldContainer>
  );
}

function RangeField({ param, formik }) {
  const { id, name, valueType } = param;
  if (valueType === 'DATE') return <DateRange param={param} formik={formik} />;
  if (valueType === 'INTEGER') return <IntegerRange param={param} formik={formik} />;

  const { min, max } = formik.values[id];
  const mask = createNumberMask({
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 6,
    allowNegative: true,
    allowLeadingZeroes: true
  });

  return (
    <div>
      <FieldContainer name={id} label={name}>
        <Row className="mt-1">
          <Col>
            <Label className="fs-xs">Min</Label>
            <ReactMaskedInput
              mask={mask}
              defaultValue={min}
              className="form-control"
              onChange={({ target }) => {
                const minValue = normalizeNumber(target.value);
                const range = formik.values[id];
                formik.setFieldValue(id, { ...range, min: minValue });
              }}
            />
          </Col>
          <Col>
            <Label className="fs-xs">Max</Label>
            <ReactMaskedInput
              mask={mask}
              defaultValue={max}
              className="form-control"
              onChange={({ target }) => {
                const maxValue = normalizeNumber(target.value);
                const range = formik.values[id];
                formik.setFieldValue(id, { ...range, max: maxValue });
              }}
            />
          </Col>
        </Row>
      </FieldContainer>
    </div>
  );
}

function IntegerRange({ param, formik }) {
  const { id, name } = param;
  const { min, max } = formik.values[id];

  return (
    <div>
      <FieldContainer name={id} label={name}>
        <Row className="mt-1">
          <Col>
            <Label className="fs-xs">Min</Label>
            <Input
              value={min}
              type="number"
              onChange={({ target }) => {
                const range = formik.values[id];
                formik.setFieldValue(id, { ...range, min: target.value });
              }}
            />
          </Col>
          <Col>
            <Label className="fs-xs">Max</Label>
            <Input
              value={max}
              type="number"
              onChange={({ target }) => {
                const range = formik.values[id];
                formik.setFieldValue(id, { ...range, max: target.value });
              }}
            />
          </Col>
        </Row>
      </FieldContainer>
    </div>
  );
}
