import { Badge, Label } from '@oforce/global-components';
import React from 'react';
import { ReactSelect } from '../../../components/react-select';
import { usePricingOptionAddOns } from '../../../views/policyInsureds/hooks';

export default function AddOnSelect({ pricingOptionId, onChange, selectedAddOnIds, row }) {
  const { optionalAddOns: addOns = [], requiredAddOns = [] } = usePricingOptionAddOns({ pricingOptionId });
  const addOnOptions = addOns.reduce((acc, option) => [...acc, { value: option.id, label: option.label }], []);
  const selectedAddOns = addOnOptions.filter(({ value }) => selectedAddOnIds.includes(value));

  return (
    <div className={row ? 'd-flex flex-row ' : 'd-flex flex-column'}>
      <div className={row ? 'mr-3' : 'mt-3'}>
        <Label className="font-weight-500 mt-2 mb-0 fs-sm">Optional Add-On(s)</Label>
        <ReactSelect
          options={addOnOptions}
          value={selectedAddOns}
          onChange={onChange}
          name="addOns"
          label="Add-On(s)"
          labelProps={{ size: 'xs' }}
          optional
          placeholder="Select an add-on..."
          isMulti
        />
      </div>
      <div className="mr-3">
        {requiredAddOns.length > 0 && (
          <>
            <Label className="font-weight-500 mb-0 mt-3 fs-sm">Required Add-On(s)</Label>
            <div className="d-flex flex-direction-row flex-wrap">
              {requiredAddOns.map(({ id, label }) => (
                <Badge key={id} size="sm" className="m-1" color="secondary">
                  {label}
                </Badge>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
