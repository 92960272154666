import { gql, useMutation } from '@apollo/client';
import { GET_ACCOUNT_WEBHOOKS } from './useAccountWebhooks';

export const UPDATE_ACCOUNT_WEBHOOK = gql`
  mutation updateAccountWebhook($id: ID!, $url: String!, $authToken: String!, $events: [AccountWebhookEvent]) {
    updateAccountWebhook(url: $url, id: $id, events: $events, authToken: $authToken) {
      id
      url
      accountId
      authToken
      events
    }
  }
`;

function useUpdateAccountWebhook({ onCompleted = () => {}, onError = () => {}, webhookId, accountId }) {
  const [update, { loading, error }] = useMutation(UPDATE_ACCOUNT_WEBHOOK, {
    onCompleted,
    onError,
    refetchQueries: [{ query: GET_ACCOUNT_WEBHOOKS, variables: { accountId } }]
  });

  return {
    loading,
    error,
    updateAccountWebhook: ({ url, events, authToken }) =>
      update({ variables: { id: webhookId, url, authToken, events: events.map(({ value }) => value) } })
  };
}

export default useUpdateAccountWebhook;
