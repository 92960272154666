import prettier from 'prettier';
import parserBabel from 'prettier/parser-babel';

import multiQuery from './multiQuery';
import singleQuery from './singleQuery';

const formatJs = (source) =>
  prettier.format(source, {
    parser: 'babel',
    plugins: [parserBabel],
  });

const snippet = {
  language: 'JavaScript',
  codeMirrorMode: 'javascript',
  name: 'Apollo Hook',
  options: [],
  generate: (input) => {
    const { operationDataList } = input;
    const queryCount = operationDataList.length;
    const source = queryCount === 1 ? singleQuery(input) : multiQuery(input);
    return formatJs(source);
  },
};

export default snippet;
