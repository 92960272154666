import { gql, useQuery } from '@apollo/client';

import { useCreateVehicleType, useRemoveVehicleType, useUpdateVehicleType } from '.';

export const GET_VEHICLE_TYPES = gql`
  query GetVehicleTypes {
    vehicleTypes {
      id
      name
    }
  }
`;

export default function useVehicleTypes() {
  const { data, loading, error } = useQuery(GET_VEHICLE_TYPES);

  const {
    createVehicleType,
    loading: creating,
    error: creationError
  } = useCreateVehicleType({
    getVehicleTypesQuery: GET_VEHICLE_TYPES
  });

  const {
    removeVehicleType,
    loading: removing,
    error: removalError
  } = useRemoveVehicleType({
    getVehicleTypesQuery: GET_VEHICLE_TYPES
  });

  const { updateVehicleType, loading: updating, error: updateError } = useUpdateVehicleType({});

  return {
    vehicleTypes: data?.vehicleTypes || [],
    options: data?.vehicleTypes?.map(option => ({ value: option?.id, label: option?.name })),
    loading,
    error: error || creationError || removalError || updateError,
    busy: loading || creating || removing || updating,
    createVehicleType,
    removeVehicleType,
    updateVehicleType,
    updating,
    creating,
    removing
  };
}
