import React from 'react';
import { Render } from '../../../components';
import { CustomDropdownItem } from '@oforce/global-components';

export default function CellActionsEditStartDate({ render, onClick }) {
  return (
    <Render if={render}>
      <CustomDropdownItem color="info" icon={['far', 'calendar-edit']} onClick={onClick}>
        Edit Start Date
      </CustomDropdownItem>
    </Render>
  );
}
