import React from 'react';
import ReactGA from 'react-ga';
import ReactDOM from 'react-dom';
import { hotjar } from 'react-hotjar';
import { theme } from '@oforce/global-styles';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import './assets/fontawesome';
import { ScrollToTop } from './components';
import { GlobalStylesProvider } from './styles';
import { CurrentUserProvider, AuthProvider, ApolloProvider } from './context';
import JobProviders from './context/jobProviders';

const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
ReactGA.initialize(trackingId);
hotjar.initialize(1783560, 6);

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Router>
      <ScrollToTop />
      <AuthProvider>
        <ApolloProvider>
          <CurrentUserProvider>
            <JobProviders>
              <GlobalStylesProvider>
                <App />
              </GlobalStylesProvider>
            </JobProviders>
          </CurrentUserProvider>
        </ApolloProvider>
      </AuthProvider>
    </Router>
  </ThemeProvider>,
  document.getElementById('root')
);
