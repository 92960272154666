import PropTypes from 'prop-types';

export default function Render({ if: show, children }) {
  return show ? children : null;
}

Render.propTypes = {
  /** If this is set to true, the children of the component will be rendered, otherwise, nothing will be rendered. */
  if: PropTypes.bool,
  className: PropTypes.string
};
