export default function useBulkEmailDocumentFormSteps() {
  const formSteps = [
    {
      name: 'Select Date',
      initialValues: { activeOn: '' }
    },
    {
      name: 'Select Documents',
      initialValues: { documentIds: [], documentRelationships: [] }
    },
    {
      name: 'Select Recipients',
      initialValues: { insuredIds: [] }
    },
    {
      name: 'Confirm Recipients',
      initialValues: {}
    }
  ];

  const filterDocumentRelationships = ({ documentIds, documentRelationships, insuredIds }) => {
    return documentRelationships
      ?.filter(({ document: { id } }) => documentIds?.includes(id))
      ?.filter(({ insuredId }) => insuredIds?.includes(insuredId))
      ?.map(x => x.id);
  };

  return { formSteps, filterDocumentRelationships };
}
