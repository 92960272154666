import React, { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useUpdateExposuresJobContext } from '../../context';
import { ToastContent } from '../../components/react-toastify';
import { dateToClientSideFormat } from '../../utils/dates';

export default function UpdateExposuresJob() {
  const { jobId, error, jobErrors, finished, progress, insured, vehicle, exposureElection, message, clearJobId } =
    useUpdateExposuresJobContext();

  const toastId = useRef(null);

  useEffect(() => {
    if (!toast.current && jobId) {
      toastId.current = toast(<Importing />, {
        progress: 0,
        hideProgressBar: false,
        pauseOnHover: true
      });
    }
  }, [toastId, jobId, clearJobId]);

  useEffect(() => {
    if (!toastId.current) {
      return;
    }

    if (error) {
      toast.update(toastId.current, {
        render: () => <ErrorUpdatingExposures error={jobErrors || error} />,
        progress: finished ? 0.99 : progress,
        autoClose: 10000,
        progressClassName: 'bg-danger'
      });
    } else if (!finished) {
      toast.update(toastId.current, {
        render: () => (
          <Importing insured={insured} vehicle={vehicle} exposureElection={exposureElection} message={message} />
        ),
        progress: progress,
        autoClose: false
      });
    } else if (finished) {
      toast.update(toastId.current, {
        render: () => <ImportFinished message={message} errors={jobErrors} />,
        progress: 0.99,
        autoClose: 5000,
        progressClassName: 'bg-success'
      });
    }
    // eslint-disable-next-line
  }, [toastId, error, finished, progress, insured, message, jobErrors]);

  return <></>;

  // dont need to return the container here becasue it is returned in subscriptions
  // return (
  //   <>
  //     <ToastContainer
  //       containerId="importExposuresJobToast"
  //       position="bottom-left"
  //       hideProgressBar={false}
  //       pauseOnHover={true}
  //     />
  //   </>
  // );
}

const Importing = ({ message, insured, vehicle, exposureElection }) => {
  let title = message ?? 'Preparing Import';

  if (insured) {
    const { firstName, lastName, businessName } = insured;
    title = `Updating Insured: ${firstName} ${lastName} - ${businessName}`;
  }

  if (vehicle) {
    const { vin } = vehicle;
    title = `Updating Vehicle: ${vin}`;
  }

  if (exposureElection) {
    const { terminationDate } = exposureElection;
    if (!!terminationDate) {
      title = `Updating Termination Date: ${dateToClientSideFormat(terminationDate)}`;
    } else {
      title = `Clearing Termination Date`;
    }
  }

  return (
    <ToastContent
      title={title}
      icon={['far', 'spinner-third']}
      iconProps={{ spin: true }}
      message="Allow us some time to import."
    />
  );
};

const ErrorUpdatingExposures = ({ error }) => (
  <ToastContent
    title="Error Updating Exposure(s)"
    titleProps={{ className: 'text-danger' }}
    icon={['fas', 'times-circle']}
    message={error.message || error}
  />
);

const ImportFinished = ({ message, errors }) => {
  const hadErrors = errors && errors.length > 0;

  return (
    <ToastContent
      title={message || 'Updates Finished'}
      titleProps={{ className: 'text-success' }}
      icon={!hadErrors ? ['fas', 'check-circle'] : ['far', 'exclamation-triangle']}
      message={!hadErrors ? 'Your Exposures Updated Sucessfully' : 'Some Rows Failed to Update'}
    >
      {hadErrors && 'It is Important that you run these failed updates separately to prevent issues'}
      {hadErrors && errors.map(e => <div key={e}>{e}</div>)}
    </ToastContent>
  );
};
