import React from 'react';
import { Modal } from '@oforce/global-components';
import { GraphQLErrors } from '../../../components';

import { useRemoveVehicleType } from '../hooks';
import { GET_VEHICLE_TYPES } from '..';

export default function RemoveVehicleType({ showRemoveVehicleType, toggleRemoveVehicleType, vehicleType }) {
  const { removeVehicleType, loading, error } = useRemoveVehicleType({
    getVehicleTypesQuery: GET_VEHICLE_TYPES,
    onCompleted: toggleRemoveVehicleType
  });

  return (
    <Modal
      isOpen={showRemoveVehicleType}
      toggle={toggleRemoveVehicleType}
      title="Remove Vehicle Type"
      loading={loading}
      submitButtonText="Remove Vehicle Type"
      submitButtonProps={{ color: 'danger', loadingText: 'Removing...' }}
      onSubmit={() => removeVehicleType(vehicleType?.id)}
    >
      <GraphQLErrors error={error} />
      Are you sure you want to remove this vehicle type?
    </Modal>
  );
}
