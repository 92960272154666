import { Alert } from 'reactstrap';
import { gql, useMutation } from '@apollo/client';
import { Render } from '@oforce/global-components';
import React, { useState, useEffect } from 'react';

import { ConfirmModal, Bold } from '../../../components';

export const RESEND_ACCOUNT_INVITATION = gql`
  mutation resendAccountInvitation($id: ID!) {
    resendAccountInvitation(id: $id) {
      id
      status
    }
  }
`;

export default function AccountResendInvite({ isOpen, toggle, invitationId, inviteeEmail }) {
  const [success, setSuccess] = useState(false);

  const [resendInvite, { loading, error, reset }] = useMutation(RESEND_ACCOUNT_INVITATION, {
    variables: { id: invitationId },
    onCompleted: () => setSuccess(true),
    onError: () => {}
  });

  useEffect(() => {
    if (success) {
      const timeoutId = setTimeout(() => {
        setSuccess(false);
        toggle();
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [success, toggle]);

  return (
    <ConfirmModal
      isOpen={isOpen}
      toggle={toggle}
      title="Resend invite"
      onConfirm={resendInvite}
      confirmButtonText="Resend Invite"
      icon={['far', 'sync-alt']}
      iconColor="info"
      cancelButtonProps={{ disabled: success }}
      confirmButtonProps={{ color: 'info', loading, disabled: success, loadingText: 'Resending invite...' }}
      onOpened={reset}
      error={error}
    >
      <Render if={success}>
        <Alert color="success">Success! This invitation has been resent.</Alert>
      </Render>
      Are you sure you want to resend this invitation to <Bold>{inviteeEmail}</Bold>?
    </ConfirmModal>
  );
}
