import { RenderData, TrLoader, TrPlaceholder } from '@oforce/global-components';
import { useState } from 'react';
import { InsuredRow } from '.';
import { BoxContainer, Table, TrGraphQLErrors } from '../../../components';
import { InsuredsFilters } from '.';
import useListInsureds from '../hooks/useListInsureds';
import { TablePagination } from '../../../components/tables';

export default function InsuredsTable() {
  const colSpan = 6;

  const [filters, setFilters] = useState({ status: 'ACTIVE', ordering: 'LAST_NAME' });
  const { insureds, loading, error, paginationProps } = useListInsureds({ filters });

  return (
    <>
      <InsuredsFilters filters={filters} setFilters={setFilters} />
      <BoxContainer>
        <Table responsive>
          <thead>
            <tr>
              <th></th>
              <th>Insured</th>
              <th>Active Elections</th>
              <th>Dispatch Location</th>
              <th>SSN</th>
              <th>Created Date</th>
            </tr>
          </thead>
          <tbody>
            <RenderData
              data={insureds}
              loading={loading}
              error={error}
              loadingComponent={<TrLoader colSpan={colSpan} />}
              errorComponent={<TrGraphQLErrors colSpan={colSpan} error={error} />}
              placeholderComponent={<TrPlaceholder colSpan={colSpan}>No Insureds</TrPlaceholder>}
            >
              {insureds => insureds?.map(insured => <InsuredRow key={insured?.id} insured={insured} />)}
            </RenderData>
          </tbody>
        </Table>
      </BoxContainer>
      <TablePagination {...paginationProps} loading={loading} />
    </>
  );
}
