import React from 'react';
import { FieldArray, Form } from 'formik';
import { Alert, Row, Col, CustomInput } from 'reactstrap';
import { Button, Loader } from '@oforce/global-components';

import { GraphQLErrors, Render, RenderData } from '../../../components';
import { HideFromTypes, useActiveAccount } from '../../../shared/activeAccount';

export default function RequiredFieldsForm({
  formik,
  loading,
  updating,
  error,
  success,
  disableAllFields,
  showSubmitButton
}) {
  const {
    TYPES: { CLIENT }
  } = useActiveAccount();

  return (
    <Form>
      <GraphQLErrors error={error} />
      <fieldset disabled={disableAllFields}>
        <RenderData
          data={formik?.values?.requiredFields}
          loading={loading}
          success={success}
          successComponent={
            <Alert color="success" className="mb-4">
              Success! Your changes have been saved.
            </Alert>
          }
          loadingComponent={<Loader />}
          errorComponent={<GraphQLErrors error={error} />}
        >
          <Row>
            <FieldArray name="requiredFields">
              {({ replace }) =>
                formik?.values?.requiredFields?.map((field, i) => (
                  <Col key={i} xs="12" sm="6" xl="3">
                    <CustomInput
                      label={field?.label}
                      disabled={field?.alwaysRequired || disableAllFields}
                      onChange={() => replace(i, { ...field, required: !field.required })}
                      id={field?.name}
                      type="checkbox"
                      checked={field?.required}
                      className="mb-1"
                    />
                  </Col>
                ))
              }
            </FieldArray>
          </Row>
        </RenderData>
      </fieldset>
      <div className="d-flex justify-content-end mt-3">
        <HideFromTypes types={[CLIENT]}>
          <Render if={showSubmitButton}>
            <Button
              color="success"
              className="ml-2"
              disabled={!formik.dirty || disableAllFields}
              onClick={formik.handleSubmit}
              loading={updating}
              loadingText="Saving..."
            >
              Save Changes
            </Button>
          </Render>
        </HideFromTypes>
      </div>
    </Form>
  );
}
