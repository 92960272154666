import React from 'react';
import { useParams } from 'react-router-dom';

import { ProductPricingCreateUpdate } from '../../../views';

export default function PricingUpdate({ pricings, productId }) {
  const { pricingId } = useParams();

  return (
    <ProductPricingCreateUpdate productId={productId} pricing={pricings?.find(pricing => pricing.id === pricingId)} />
  );
}
