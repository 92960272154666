import { gql, useQuery } from '@apollo/client';

import { backendNameToLabel } from '../../../utils/formatting';

const GET_ACCOUNT_ROLES = gql`
  query GetAccountRoles {
    accountRoles
  }
`;

export default function useAccountRoles() {
  const { data, loading, error } = useQuery(GET_ACCOUNT_ROLES);

  const accountRoleOptions =
    data?.accountRoles
      ?.filter(role => role !== 'OWNER')
      .map(role => {
        const label = backendNameToLabel(role);

        return { value: role, label };
      }) || [];

  return { accountRoles: data?.accountRoles, accountRoleOptions, loading, error };
}
