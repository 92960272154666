import { stakeholderLabels } from '../../stakeholder/utils';

export default function initialValues(stakeholderType, stakeholder, formOptions) {
  const { camelLables } = stakeholderLabels(stakeholderType);

  return {
    [camelLables['name']]: stakeholder?.name || '',
    [camelLables['relationship']]: stakeholder?.relationship || '',
    [camelLables['address1']]: stakeholder?.address1 || '',
    [camelLables['address2']]: stakeholder?.address2 || '',
    [camelLables['city']]: stakeholder?.city || '',
    [camelLables['email']]: stakeholder?.email || '',
    [camelLables['phone']]: stakeholder?.phone || '',
    [camelLables['state']]: stakeholder?.state
      ? formOptions?.allStates?.options?.find(({ value }) => value === stakeholder?.state)
      : null,
    [camelLables['zip']]: stakeholder?.zip || ''
  };
}
