import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';

import MultiStepPage from './MultiStepPage';
import MultiStepProvider from './MultiStepProvider';

export default function MultiStepForm({
  children,
  formOptions,
  formSteps,
  formSubmit,
  formProps,
  formikRef,
  maxWidth = '700px',
  ...rest
}) {
  return (
    <MultiStepProvider>
      <Row className="justify-content-center">
        <Col xs="12" lg="10" xl="8" style={{ maxWidth }}>
          <MultiStepPage
            formOptions={formOptions}
            formSteps={formSteps}
            formSubmit={formSubmit}
            formProps={formProps}
            formikRef={formikRef}
            {...rest}
          >
            {children}
          </MultiStepPage>
        </Col>
      </Row>
    </MultiStepProvider>
  );
}

MultiStepForm.propTypes = {
  /**
   * The steps in the form. They should have the following properties: { name, initialValues, validationSchema }
   */
  formSteps: PropTypes.array.isRequired,

  /**
   * The options to use to populate the form select fields.
   */
  formOptions: PropTypes.object,

  /**
   * The function to be called on the final submit of the form when the last step is reached.
   */
  formSubmit: PropTypes.func.isRequired,

  /**
   * These are optional props you can pass into the component which will get spread into the
   * render props for the component. This allows you to pass additional data to the steps
   * without having to explicitly pass it to each step. This will not overwrite the other renderProps, just be included with them.
   */
  formProps: PropTypes.object
};
