import { gql } from '@apollo/client';
import { useSubscription } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';

import { GenerateInvoicesReportJobContext } from './context';
import { useResettableMutation } from '../../hooks/useResettableMutation';

const START_JOB = gql`
  mutation startInvoiceReportJob($clientId: ID, $policyId: ID, $productId: ID, $start: Date, $end: Date) {
    generateInvoicesReport(
      clientId: $clientId
      policyId: $policyId
      productId: $productId
      invoiceStartDate: $start
      invoiceEndDate: $end
    )
  }
`;

const ON_GENERATE_INVOICES_REPORT_JOB_PROGRESS = gql`
  subscription jobProgress($jobId: ID!) {
    job: generateInvoicesReportJobProgress(jobId: $jobId) {
      jobId
      filename
      url
      finished
      message
      errors
    }
  }
`;

export default function GenerateInvoicesReportJobProvider({ children }) {
  const [jobId, setJobId] = useState(null);
  const [jobErrors, setJobErrors] = useState([]);
  const [finished, setJobFinished] = useState(true);
  const [message, setMessage] = useState(null);
  const [filename, setFilename] = useState(null);
  const [url, setUrl] = useState(null);
  const [toggler, setToggle] = useState(null);

  const clearJobId = useCallback(() => {
    setJobId(null);
    setJobErrors([]);
  }, []);

  useEffect(() => {
    if (!!jobId && !!finished) {
      setTimeout(() => clearJobId(), 10000);
    }
  }, [finished, clearJobId, jobId]);

  const [startInvoiceReportJob, { loading, error, reset }] = useResettableMutation(START_JOB, {
    onCompleted: data => {
      setJobFinished(false);
      setJobId(data?.generateInvoicesReport);
      toggler();
    },
    onError: () => setTimeout(() => reset(), 10000)
  });

  const startGenerateInvoicesReportJob = ({ variables }, toggle) => {
    setToggle(() => toggle);
    setJobFinished(false);
    startInvoiceReportJob({ variables });
  };

  useSubscription(ON_GENERATE_INVOICES_REPORT_JOB_PROGRESS, {
    skip: !jobId,
    variables: { jobId },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      const job = data?.job;
      const { message, filename, url, finished, errors } = job;

      setJobErrors(errors);
      setJobFinished(finished);
      setMessage(message);
      setFilename(filename);
      setUrl(url);
    }
  });

  return (
    <GenerateInvoicesReportJobContext.Provider
      value={{
        startGenerateInvoicesReportJob,
        jobId,
        clearJobId,
        loading,
        error,
        finished,
        filename,
        url,
        message,
        jobErrors
      }}
    >
      {children}
    </GenerateInvoicesReportJobContext.Provider>
  );
}
