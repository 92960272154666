import debounce from 'debounce-promise';
import { useQuery, gql } from '@apollo/client';
import { useCurrentUser } from '../../../../../shared/currentUser';

const GET_FILTERED_POLICIES = gql`
  query GetFilteredPolicies(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $name: String
    $exposureType: [ExposureType]
    $apiOnlyExposureUpdates: Boolean
    $searchBy: [PolicySearchByInput] = []
    $effectiveOnDate: Date
    $clientId: ID
  ) {
    policies(
      first: $first
      after: $after
      last: $last
      before: $before
      name: $name
      exposureType: $exposureType
      apiOnlyExposureUpdates: $apiOnlyExposureUpdates
      searchBy: $searchBy
      effectiveOnDate: $effectiveOnDate
      clientId: $clientId
    ) {
      edges {
        node {
          id
          name
          enableCustomStartDates
          startDateBackdatingMinimum
          startDateBackdatingLimit
          requiredInsuredFields
          requiredVehicleFields
          insuranceType {
            type
          }
        }
      }
    }
  }
`;

const GET_POLICY_COUNT = gql`
  query GetPolicyCount($name: String, $exposureType: [ExposureType], $apiOnlyExposureUpdates: Boolean) {
    policyCount(name: $name, exposureType: $exposureType, apiOnlyExposureUpdates: $apiOnlyExposureUpdates)
  }
`;

// This hook is only being used in forms to select coverage.
// Becasue of this we put an apiOnlyExposureUpdates filter in here to filter out policies that
// are not allowed to be chossen for exposures via the UI and only the api.
// If you need to use this component and want it to return those policies, you need to pass, apiOnlyExposureUpdates: null as a vaiable (test this theory)
export default function useSearchablePolicyOptions({ variables = {}, count }) {
  const { isAdmin } = useCurrentUser();
  const attrs = { apiOnlyExposureUpdates: isAdmin ? null : false, ...variables };

  const { data, loading, error, fetchMore } = useQuery(GET_FILTERED_POLICIES, {
    variables: { first: 20, after: null, ...attrs },
    skip: !!count
  });

  const {
    data: countData,
    loading: countLoading,
    error: countError
  } = useQuery(GET_POLICY_COUNT, {
    variables: { ...attrs },
    skip: !count
  });

  const dataToOptions = data =>
    data?.policies?.edges?.map(({ node }) => ({
      value: node?.id,
      label: node?.name,
      ...node
    })) || [];

  const getFilteredPolicies = async (searchQuery, callback) => {
    const { clientId, effectiveOnDate } = variables;
    const { data } = await fetchMore({ variables: { clientId, effectiveOnDate, name: searchQuery } });
    const filteredOptions = dataToOptions(data);
    callback(filteredOptions);
  };

  const loadOptions = debounce(getFilteredPolicies, 500);

  return {
    loadOptions,
    defaultOptions: dataToOptions(data),
    count: countData?.policyCount,
    loading: loading || countLoading,
    error: error || countError
  };
}
