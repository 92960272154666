export default function useNormalizeCoverageParameterTypes() {
  const getValueTypeName = name => {
    return {
      DATE: 'Date',
      STATE: 'State',
      INTEGER: 'Number',
      STRING: 'Text value',
      BOOLEAN: 'True/False',
      CURRENCY: 'Currency'
    }[name];
  };

  const getValueTypeDescription = type => {
    return {
      DATE: 'A valid date in the format MM-DD-YYYY.',
      STATE: 'A valid US State or Canadian Province.',
      INTEGER: 'A valid whole number, it cannot contain decimals, but can be negative or positive.',
      STRING:
        'A valid string of text. This can include all alphanumeric values and also numbers and special characters.',
      BOOLEAN: 'A true or false value. This is not case sensitive.',
      CURRENCY: 'A valid amount in US Currency. '
    }[type];
  };

  const getValueTypeExampleValues = type => {
    return {
      DATE: '04-03-1994',
      STATE: 'Arizona',
      INTEGER: '15, -32, 0',
      STRING: 'Tall',
      BOOLEAN: 'True, true, FALSE',
      CURRENCY: '$12.55, $1,254.14, $0.00, $10.00'
    }[type];
  };

  return {
    getValueTypeName,
    getValueTypeDescription,
    getValueTypeExampleValues
  };
}
