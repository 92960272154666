import React from 'react';
import { useHistory } from 'react-router-dom';
import { CustomDropdownItem, useToggle } from '@oforce/global-components';
import { useParseCoverageParameters } from '../../../views/coverageParameters/hooks';
import { ActionDropdown, LabelValueCell, CellTable, Td, Tr } from '../../../components/table-2.0';
import { backendNameToLabel, formatCurrency } from '../../../utils/formatting';
import { ACCOUNT_ROLES, ACCOUNT_TYPES, HideFromRoles, ShowForTypes } from '../../../shared/activeAccount';
import { RemovePricing, DuplicatePricingOption } from '.';
import { useCanadianProvinces, useUSStates } from '../../../hooks';
import { formatPricing, pricingLabel } from '../../exposureElections/utils';

export default function PricingRow({ pricing, product }) {
  const history = useHistory();
  const [showDuplicate, toggleDuplicate] = useToggle();
  const [showRemove, toggleRemove] = useToggle();
  const { CARRIER } = ACCOUNT_TYPES;
  const { EXPOSURE_MANAGER } = ACCOUNT_ROLES;
  const insuranceTypeRequiresVehicle = product?.insuranceType?.requireVehicle;

  return (
    <>
      <Tr>
        <Td>
          <NameCell name={pricing?.name} label={pricing?.label} />
        </Td>
        <Td>
          <PricingCell
            factor={pricing?.factoredField}
            rate={pricing?.rate}
            mode={pricing?.rateMode}
            isPercentageFactor={pricing?.isPercentageFactor}
          />
        </Td>
        <Td>
          <MinMaxCell mode={pricing?.rateMode} min={pricing?.percentageMinimum} max={pricing?.percentageMaximum} />
        </Td>

        <HideFromRoles roles={[EXPOSURE_MANAGER]}>
          <Td>
            <CommissionCell commission={pricing?.retailCommission} />
          </Td>
        </HideFromRoles>

        <Td>
          <FeesCell fees={pricing?.fees} />
        </Td>
        {insuranceTypeRequiresVehicle && (
          <Td>
            <VehicleTypesCell vehicleTypes={pricing?.vehicleTypes} />
          </Td>
        )}
        <Td>
          <CoverageParameterCell coverageParameters={pricing?.coverageParameters} />
        </Td>
        <ShowForTypes types={[CARRIER]}>
          <HideFromRoles roles={[EXPOSURE_MANAGER]}>
            <Td align="right">
              <ActionDropdown
                onEdit={() => history.push(`/products/${product?.id}/pricing/${pricing?.id}/update`)}
                editText="Edit"
                onRemove={toggleRemove}
              >
                <CustomDropdownItem color="info" icon={['far', 'copy']} onClick={toggleDuplicate}>
                  Duplicate
                </CustomDropdownItem>
              </ActionDropdown>
            </Td>
          </HideFromRoles>
        </ShowForTypes>
      </Tr>
      <RemovePricing product={product} pricing={pricing} show={showRemove} toggle={toggleRemove} />
      <DuplicatePricingOption product={product} pricing={pricing} show={showDuplicate} toggle={toggleDuplicate} />
    </>
  );
}

const NameCell = ({ name, label }) => <LabelValueCell label={label} value={name} />;

const VehicleTypesCell = ({ vehicleTypes }) => {
  if (vehicleTypes.length === 0) return <EmptyCell />;
  return vehicleTypes.reduce((acc, type, i) => {
    if (i === 0) return `${type.name}`;
    return `${acc}, ${type.name}`;
  }, '');
};

const CommissionCell = ({ commission }) => {
  if (!commission) return <EmptyCell />;

  const formattedRate = formatPricing({
    rate: commission?.rate,
    rateMode: commission?.rateMode,
    isPercentageFactor: true
  });
  return <LabelValueCell label={formattedRate} value={commission?.name} />;
};

const FeesCell = ({ fees }) => {
  if (!fees) return <EmptyCell />;

  const feeRows = fees.reduce((acc, { rate, rateMode, name }) => {
    if (rate)
      return [
        ...acc,
        { label: name, value: formatPricing({ rate, rateMode, isPercentageFactor: rateMode === 'PERCENTAGE' }) }
      ];
    return acc;
  }, []);

  return feeRows.length > 0 ? <CellTable rows={feeRows} /> : <EmptyCell />;
};

const CoverageParameterCell = ({ coverageParameters }) => {
  const { options: stateOptions } = useUSStates();
  const { options: provinceOptions } = useCanadianProvinces();
  const allStateOptions = [...stateOptions, ...provinceOptions];
  const { prepareParameterValueForUi } = useParseCoverageParameters();

  const paramRows = coverageParameters.reduce((acc, param) => {
    const { name: label, value, type, valueType } = param;
    if (value && type === 'LIST' && valueType === 'STATE') {
      const parsedValues = JSON.parse(value);
      const labelIncludeExclude = parsedValues?.length <= allStateOptions?.length / 2 ? 'Applies to' : 'Excluded from';
      const values =
        parsedValues?.length <= allStateOptions?.length / 2
          ? parsedValues
          : allStateOptions
              ?.filter(({ value }) => !parsedValues?.map(value => value)?.includes(value))
              ?.map(({ value }) => value);

      return [
        ...acc,
        {
          label,
          value: values.join(', '),
          numState: values?.length,
          labelIncludeExclude: labelIncludeExclude,
          stateList: true,
          id: Math.random().toString().split('.')[1]
        }
      ];
    } else if (value) {
      return [...acc, { label, value: prepareParameterValueForUi(param) }];
    }
    return acc;
  }, []);

  return paramRows.length > 0 ? <CellTable rows={paramRows} /> : <EmptyCell />;
};

const PricingCell = ({ rate, mode, factor, isPercentageFactor }) => {
  const value = pricingLabel({ factoredField: factor }) || backendNameToLabel || backendNameToLabel(mode);
  return <LabelValueCell label={formatPricing({ rate, rateMode: mode, isPercentageFactor })} value={value} />;
};

const MinMaxCell = ({ mode, min, max }) => {
  if (mode === 'PERCENTAGE' || mode === 'FACTORED') {
    const minLabel = min && formatCurrency(min);
    const maxLabel = max && formatCurrency(max);
    if (min && max) {
      return <LabelValueCell label={`${minLabel} - ${maxLabel}`} />;
    } else if (min && !max) {
      return <LabelValueCell label={`> ${minLabel}`} />;
    } else if (!min && max) {
      return <LabelValueCell label={` < ${maxLabel}`} />;
    } else {
      return <EmptyCell />;
    }
  }
  return <EmptyCell text="Inactive" />;
};

const EmptyCell = ({ text = 'None' }) => <div className="font-weight-400 fs-xs text-gray-500">{text}</div>;
