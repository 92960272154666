import { gql, useQuery } from '@apollo/client';

const POLICY_INVOICE_INFO = gql`
  query PolicyInvoiceInfo($policyId: ID!) {
    policy(id: $policyId) {
      id
      canGenerateOffCycleInvoice
    }
  }
`;

export default function usePolicyInvoiceInfo({ policy }) {
  const { data, loading, error } = useQuery(POLICY_INVOICE_INFO, {
    variables: { policyId: policy?.id },
    skip: !policy?.id,
    fetchPolicy: 'cache-and-network'
  });

  return {
    loading,
    error,
    canGenerateOffCycleInvoice: data?.policy?.canGenerateOffCycleInvoice
  };
}
