import React from 'react';
import { Modal } from '@oforce/global-components';
import { GraphQLErrors } from '../../../components';

export default function MarkAsPaidModal({ isOpen, toggle, invoice, loading, error, onSubmit }) {
  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        title={`Mark Invoice #${invoice?.id} as Paid`}
        submitButtonText={`Mark Invoice #${invoice?.id} as Paid`}
        submitButtonProps={{ loading, loadingText: 'Marking as paid...', color: 'info' }}
        onSubmit={onSubmit}
      >
        <GraphQLErrors error={error} />
        <div className="fs-lg">
          Are you sure you want to mark this invoice as paid? This operation can not be reversed.
        </div>
      </Modal>
    </>
  );
}
