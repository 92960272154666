import React from 'react';
import { Tooltip } from 'reactstrap';
import { Badge, useToggle } from '@oforce/global-components';

import { convertDecimalToPercentage } from '../../../utils';
import { backendNameToLabel, formatCurrency } from '../../../utils/formatting';
import { ActionDropdown, LabelValueCell } from '../../../components/table-2.0';
import { useProductPricings } from '../../../views/productPricing/hooks';

import { EditFee, RemoveFee } from '.';
import { ACCOUNT_ROLES, ACCOUNT_TYPES, HideFromRoles, ShowForTypes } from '../../../shared/activeAccount';

export default function FeeRow({ product, fee }) {
  const { CARRIER } = ACCOUNT_TYPES;
  const { EXPOSURE_MANAGER } = ACCOUNT_ROLES;

  const [showEditFee, toggleEditFee] = useToggle();
  const [showRemoveFee, toggleRemoveFee] = useToggle();
  const [showPricingOptionsPopover, togglePricingOptionsPopover] = useToggle();
  const { pricings: pricingOptions } = useProductPricings({
    productId: product?.id
  });

  const pricingOptionsBadgeText = fee?.appliesToAllPricingOptions
    ? `All Pricing Options (${pricingOptions?.length})`
    : `Some Pricing Options (${fee?.productPricingIds?.length})`;

  const pricings = fee?.appliesToAllPricingOptions
    ? pricingOptions
    : pricingOptions?.filter(x => fee?.productPricingIds?.includes(x?.id));

  return (
    <>
      <tr>
        <td>{fee?.name}</td>
        <td>
          <PricingCell rate={fee?.rate} mode={fee?.rateMode} modeType={fee?.rateModeType} />
        </td>
        <td>
          <Badge
            size="sm"
            id={`a-${fee?.id}`}
            color="secondary"
            className="cursor-pointer"
            iconAfter={['far', 'info-circle']}
          >
            {pricingOptionsBadgeText}
          </Badge>
          <Tooltip
            target={`a-${fee?.id}`}
            placement="bottom"
            isOpen={showPricingOptionsPopover}
            toggle={togglePricingOptionsPopover}
          >
            {pricings?.map(p => (
              <div key={p?.id}>{p?.label}</div>
            ))}
          </Tooltip>
        </td>
        <td>{fee?.prorated ? 'Enabled' : 'Disabled'}</td>
        <ShowForTypes types={[CARRIER]}>
          <HideFromRoles roles={[EXPOSURE_MANAGER]}>
            <td align="right">
              <ActionDropdown onEdit={toggleEditFee} onRemove={toggleRemoveFee} />
            </td>
          </HideFromRoles>
        </ShowForTypes>
      </tr>
      <EditFee isOpen={showEditFee} toggle={toggleEditFee} fee={fee} product={product} />
      <RemoveFee isOpen={showRemoveFee} toggle={toggleRemoveFee} productId={product?.id} fee={fee} />
    </>
  );
}

const PricingCell = ({ rate, mode, modeType }) => {
  const name = modeType === 'PREMIUM_PLUS' ? 'Percentage of Premium PLUS' : backendNameToLabel(mode);
  const label = `${name}`;
  const value = mode === 'PERCENTAGE' ? convertDecimalToPercentage(rate) : formatCurrency(rate);
  return <LabelValueCell label={label} value={value} />;
};
