import { gql } from '@apollo/client';
import { useSubscription } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';

import { ExposureImportJobContext } from './context';
import { useResettableMutation } from '../../hooks/useResettableMutation';

const START_JOB = gql`
  mutation startImportJob($exposureElectionRows: [CreateCompleteInsuredImportInfo]!) {
    importExposureElections(exposureElectionRows: $exposureElectionRows)
  }
`;

const ON_IMPORT_JOB_PROGRESS = gql`
  subscription jobProgress($jobId: ID!) {
    job: exposureImportJobProgress(jobId: $jobId) {
      jobId
      finished
      message
      errors
      exposureCount
      progress
      insured {
        firstName
        lastName
        businessName
      }
    }
  }
`;

export default function ImportExposureJobProvider({ children }) {
  const [jobId, setJobId] = useState(null);
  const [jobErrors, setJobErrors] = useState([]);
  const [finished, setJobFinished] = useState(true);
  const [percentComplete, setPercentComplete] = useState(0);
  const [message, setMessage] = useState(null);
  const [insured, setInsured] = useState(null);

  const clearJobId = useCallback(() => {
    setJobId(null);
    setJobErrors([]);
  }, []);

  useEffect(() => {
    if (!!jobId && !!finished) {
      setTimeout(() => clearJobId(), 10000);
    }
  }, [finished, clearJobId, jobId]);

  const [startImportJob, { loading, error, reset }] = useResettableMutation(START_JOB, {
    onCompleted: data => {
      setJobFinished(false);
      setJobId(data?.importExposureElections);
    },
    onError: () => setTimeout(() => reset(), 10000)
  });

  const startImportExposureJob = exposureElectionRows => {
    setJobFinished(false);
    startImportJob({ variables: { exposureElectionRows } });
  };

  useSubscription(ON_IMPORT_JOB_PROGRESS, {
    skip: !jobId,
    variables: { jobId },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      const job = data?.job;

      const { errors, message, insured, finished, progress } = job;

      setJobErrors(errors);
      setJobFinished(finished);
      setPercentComplete(progress);
      setInsured(insured);
      setMessage(message);
    }
  });

  return (
    <ExposureImportJobContext.Provider
      value={{
        startImportExposureJob,
        jobId,
        clearJobId,
        loading,
        error,
        jobErrors,
        finished,
        progress: percentComplete,
        message,
        insured
      }}
    >
      {children}
    </ExposureImportJobContext.Provider>
  );
}
