import { gql, useQuery } from '@apollo/client';
import useInsuranceTypeAcronyms from './useInsuranceTypeAcronyms';

export const GET_MAPPABLE_EXPOSURE_DOCUMENT_FIELDS = gql`
  query mappableExposureDocumentFields($productId: ID, $policyId: ID, $clientId: ID) {
    mappableExposureDocumentFields(productId: $productId, policyId: $policyId, clientId: $clientId)
  }
`;

export default function useMappableExposureDocumentFields({ productId, policyId, clientId }) {
  const { loading, error, data } = useQuery(GET_MAPPABLE_EXPOSURE_DOCUMENT_FIELDS, {
    skip: (productId && policyId && clientId) || (!policyId && !productId && !clientId),
    variables: { productId, policyId, clientId }
  });

  const { insuranceTypeAcronyms } = useInsuranceTypeAcronyms();

  const pricingFields = [
    'add_ons',
    'fee',
    'premium',
    'pricing_option_label',
    'retail_commission',
    'premium_rate',
    'total'
  ];

  const policyFields = [
    'retail_broker_address',
    'retail_broker_name',
    'insurance_type',
    'product_name',
    'product_external_id'
  ];

  const electionFields = ['start_date', 'termination_date', 'master_cert_number'];

  const initialFields = {
    clientFields: [],
    insuredFields: [],
    vehicleFields: [],
    policyFields: [],
    pricingFields: [],
    electionFields: [],
    insuranceTypeFields: [],
    otherFields: []
  };

  const fields = data?.mappableExposureDocumentFields?.reduce((acc, field) => {
    if (field.startsWith('client')) return { ...acc, clientFields: [...acc.clientFields, field] };
    if (field.startsWith('insured')) return { ...acc, insuredFields: [...acc.insuredFields, field] };
    if (field.startsWith('vehicle')) return { ...acc, vehicleFields: [...acc.vehicleFields, field] };
    if (field.startsWith('policy') || policyFields?.includes(field))
      return { ...acc, policyFields: [...acc.policyFields, field] };
    if (pricingFields?.includes(field)) return { ...acc, pricingFields: [...acc.pricingFields, field] };
    if (electionFields?.includes(field)) return { ...acc, electionFields: [...acc.electionFields, field] };
    if (insuranceTypeAcronyms?.reduce((acc, acronym) => acc || field.startsWith(acronym), false))
      return { ...acc, insuranceTypeFields: [...acc.insuranceTypeFields, field] };

    return { ...acc, otherFields: [...acc.otherFields, field] };
  }, initialFields);

  return {
    loading,
    error,
    ...fields
  };
}
