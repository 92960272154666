import React from 'react';
import { Button, Loader } from '@oforce/global-components';

import { Render, TabPage } from '../../../components';
import { BodilyCoveragesDetails, VehicleCoveragesDetails } from '.';
import { useActiveAccount } from '../../../shared/activeAccount';
import { Row } from 'reactstrap';
import { InsuredStakeholderTable } from '../../insuredStakeholder/components';
import { useHistory, useRouteMatch } from 'react-router-dom';
import InsuredInfo from './InsuredInfo';
import TerminateAllButton from './TerminateAllButton';
import { useInsuredElections } from '../../insuredElections/hooks';
import { HideFromRolesAndTypes } from '../../../shared/activeAccount/components';

export default function InsuredDetails({ insured }) {
  const history = useHistory();
  const { url } = useRouteMatch();
  const filterByInsuranceType = (elections, type) =>
    elections?.filter(election => election.policy?.insuranceType?.type === type);

  const { insuredElections, loading, error } = useInsuredElections({ insuredId: insured?.id });
  const insuredTypeElections = filterByInsuranceType(insuredElections, 'INSURED');
  const vehicleTypeElections = filterByInsuranceType(insuredElections, 'VEHICLE');

  const {
    roleIsAtLeast,
    ROLES: { OBSERVER, POLICY_MANAGER },
    TYPES: { CLAIMS_ADJUSTER }
  } = useActiveAccount();

  const isAtLeastPolicyManager = roleIsAtLeast(POLICY_MANAGER);

  return (
    <>
      <Row>
        <TabPage
          title="Dashboard"
          onBack={() => history.goBack()}
          className="pr-4 flex-grow-1 "
          marginBottom="mb-0"
          contentClassName="mt-1"
          titleClassName="mb-1"
          buttons={
            <HideFromRolesAndTypes roles={[OBSERVER]} types={[CLAIMS_ADJUSTER]}>
              <Button color="info" icon={['far', 'pen']} onClick={() => history.push(`${url}/edit`)}>
                Edit
              </Button>
              <Render if={isAtLeastPolicyManager}>
                <TerminateAllButton
                  insured={insured}
                  insuredTypeElections={insuredTypeElections}
                  vehicleTypeElections={vehicleTypeElections}
                />
              </Render>
            </HideFromRolesAndTypes>
          }
        >
          <InsuredInfo insured={insured} />
        </TabPage>
        <TabPage
          title="Beneficiary"
          marginBottom="mb-0"
          contentClassName="mt-1"
          titleClassName="mb-1"
          className="flex-grow-1 mt-0"
          buttons={
            <HideFromRolesAndTypes roles={[OBSERVER]} types={[CLAIMS_ADJUSTER]}>
              <Button
                color="info"
                icon={['far', 'pen']}
                onClick={() => history.push(`${url}/edit-stakeholders`)}
              >
                Edit
              </Button>
            </HideFromRolesAndTypes>
          }
        >
          <InsuredStakeholderTable insured={insured} />
        </TabPage>
      </Row>
      {loading && <Loader />}
      {!loading && !error && (
        <>
          <Row>
            <BodilyCoveragesDetails coverages={insuredTypeElections} />
          </Row>
          <Row>
            <VehicleCoveragesDetails coverages={vehicleTypeElections} />
          </Row>
        </>
      )}
    </>
  );
}
