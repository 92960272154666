import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';

export const GET_DEFAULT_DOCUMENTS = gql`
  query documents {
    invoiceTemplate: defaultInvoiceTemplateDocument {
      filename
      id
      url
    }
  }
`;

export default function useDocuments() {
  const { loading, error, data } = useQuery(GET_DEFAULT_DOCUMENTS, { fetchPolicy: 'cache-and-network' });

  return { loading, error, invoiceTemplate: data?.invoiceTemplate };
}
