import { gql, useQuery } from '@apollo/client';
import { backendNameToLabel } from '../../../utils/formatting';

export const NOTIFICATION_SEARCH_BY_OPTIONS = gql`
  query NotificationSearchByOptions {
    notificationSearchByFields
  }
`;

export default function useNotificationSearchByOptions() {
  const { data, loading, error } = useQuery(NOTIFICATION_SEARCH_BY_OPTIONS, {
    onCompleted: () => {},
    onError: () => {}
  });

  return {
    notificationSearchByOptions:
      data?.notificationSearchByFields?.map(field => ({
        label: backendNameToLabel(field),
        value: field
      })) || [],
    loading,
    error
  };
}
