import React from 'react';
import { useHistory } from 'react-router-dom';
import { TrPlaceholder, TrLoader, RenderData } from '@oforce/global-components';
import { Table, Td, Tr } from '../../../components/table-2.0';
import { BoxContainer, TrGraphQLErrors } from '../../../components';
import ClientsFilter from './ClientsFilters';

export default function ClientsTable({ clients, loading, error, clientFilter, changeClientFilter }) {
  const history = useHistory();
  const colSpan = 1;

  return (
    <>
      <ClientsFilter {...clientFilter} onChange={changeClientFilter} />
      <BoxContainer>
        <Table>
          <thead>
            <tr>
              <th>Client Name</th>
              <th>External ID</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            <RenderData
              data={clients}
              loading={loading}
              error={error}
              loadingComponent={<TrLoader colSpan={colSpan} />}
              errorComponent={<TrGraphQLErrors colSpan={colSpan} error={error} />}
              placeholderComponent={<TrPlaceholder colSpan={colSpan}>No clients</TrPlaceholder>}
            >
              {clients =>
                clients?.map(client => (
                  <Tr key={client?.id} onClick={() => history.push(`/clients/${client?.id}`)}>
                    <Td>{client?.name}</Td>
                    <Td>{client?.externalId}</Td>
                    <Td>{client?.email}</Td>
                  </Tr>
                ))
              }
            </RenderData>
          </tbody>
        </Table>
      </BoxContainer>
    </>
  );
}
