import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';

export const LIST_DOCUMENT_RELATIONSHIP_VERSIONS = gql`
  query DocumentRelationshipVersions($id: ID!) {
    documentRelationship(id: $id) {
      id
      insured {
        id
        email
      }
      vehicle {
        id
        vin
        unitNumber
      }
      client {
        id
        name
      }
      document {
        id
        name
        filename
      }
      versions {
        id
        versionNumber
        activeDate
        inactiveDate
        inactiveReason
      }
    }
  }
`;

export default function useDocumentRelationshipVersions({ documentRelationshipId }) {
  const { data, loading, error } = useQuery(LIST_DOCUMENT_RELATIONSHIP_VERSIONS, {
    skip: !documentRelationshipId,
    variables: { id: documentRelationshipId }
  });

  return {
    documentRelationship: data?.documentRelationship,
    document: data?.documentRelationship?.document,
    versions: data?.documentRelationship?.versions || [],
    loading,
    error
  };
}
