import React, { useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Formik } from 'formik';

import { BoxContainer, BoxItemTitle } from '../../../components';

import { RequiredFieldsForm } from '../../requiredFields/components';
import { useNormalizeRequiredFields } from '../../requiredFields/hooks';
import { useActiveAccount } from '../../../shared/activeAccount';

export const GET_POLICY_REQUIRED_INSURED_FIELDS = gql`
  query GetPolicyRequiredInsuredFields($id: ID!) {
    policy(id: $id) {
      id
      insuranceType {
        id
        type
      }
      requiredInsuredFields
    }
  }
`;

const UPDATE_POLICY_REQUIRED_INSURED_FIELDS = gql`
  mutation updatePolicyRequiredInsuredFields($policyId: ID!, $requiredInsuredFields: [String]!) {
    updatePolicyRequiredInsuredFields(policyId: $policyId, requiredInsuredFields: $requiredInsuredFields) {
      id
      requiredInsuredFields
    }
  }
`;

export default function InsuredRequiredFields({
  policyId,
  stateFees,
  roleBelowCollaborator,
  typeBelowBroker,
  onError = () => {}
}) {
  const {
    roleIsAtLeast,
    ROLES: { COLLABORATOR }
  } = useActiveAccount();
  const [success, setSuccess] = useState(false);

  const { data, loading, error, refetch } = useQuery(GET_POLICY_REQUIRED_INSURED_FIELDS, {
    variables: { id: policyId }
  });

  const [update, { loading: updating, error: updateError }] = useMutation(UPDATE_POLICY_REQUIRED_INSURED_FIELDS, {
    onCompleted: () => {
      refetch();
      setSuccess(true);
      setTimeout(() => setSuccess(false), 3000);
    },
    onError
  });

  const handleSubmit = values => {
    update({
      variables: {
        policyId,
        requiredInsuredFields: values?.requiredFields
          ?.filter(field => field.required || field.alwaysRequired)
          ?.map(field => field.name)
      }
    });
  };

  const getFields = policy => {
    if (policy?.insuranceType?.type === 'INSURED') {
      return policy?.requiredInsuredFields;
    } else {
      const keys = policy?.requiredInsuredFields ? Object.keys(policy?.requiredInsuredFields) : [];
      return keys.reduce((acc, key) => {
        if (key.includes('beneficiary')) return acc;
        return { ...acc, [key]: policy?.requiredInsuredFields[key] };
      }, {});
    }
  };

  const normalizedInsuredFields = useNormalizeRequiredFields({
    fields: getFields(data?.policy),
    stateFees
  });

  const initialValues = {
    requiredFields: normalizedInsuredFields
  };

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={handleSubmit}>
      {formik => (
        <BoxContainer className="p-3 mb-4">
          <BoxItemTitle
            title="Create Insured"
            subtitle="If enabled, these fields will be required to create a new Insured."
          />
          <RequiredFieldsForm
            formik={formik}
            loading={loading}
            updating={updating}
            error={error || updateError}
            success={success}
            showSubmitButton={roleIsAtLeast(COLLABORATOR)}
            disableAllFields={roleBelowCollaborator || typeBelowBroker}
          />
        </BoxContainer>
      )}
    </Formik>
  );
}
