import React from 'react';

import { ConfirmModal, Bold } from '../../../components';
import { useRemoveAccountUser } from '../hooks';

export default function AccountMemberRemove({
  isOpen,
  toggle,
  userId,
  accountId,
  onCompleted = () => {},
  onError = () => {},
  memberName
}) {
  const { removeAccountUser, loading, error, reset } = useRemoveAccountUser({
    accountId,
    onError,
    onCompleted
  });

  return (
    <ConfirmModal
      isOpen={isOpen}
      toggle={toggle}
      title="Remove member"
      onConfirm={() => removeAccountUser({ userId })}
      confirmButtonText="Remove Member"
      iconColor="danger"
      confirmButtonProps={{ color: 'danger', loading, loadingText: 'Removing member...' }}
      onOpened={reset}
      error={error}
    >
      Are you sure you want to remove <Bold>{memberName}</Bold> from this account? This cannot be undone.
    </ConfirmModal>
  );
}
