import { convertDecimalToPercentage } from '../../utils';
import { backendNameToLabel, formatCurrency, formatExtendedCurrency } from '../../utils/formatting';

export const displayElectionTotal = election => {
  return election?.total === 'error' &&
    election?.pricingOption?.rateMode === 'FACTORED' &&
    election?.pricingOption?.factoredField?.includes('api_')
    ? formatPricingWithLabel(election?.pricingOption)
    : election?.total === 'error'
    ? `Missing ${backendNameToLabel(election?.pricingOption?.factoredField)}`
    : formatCurrency(election?.total);
};

export const displayElectionBarePremium = election => {
  return election?.premiumTotal === 'error' &&
    election?.pricingOption?.rateMode === 'FACTORED' &&
    election?.pricingOption?.factoredField?.includes('api_')
    ? formatPricingWithLabel(election?.pricingOption)
    : election?.premiumTotal === 'error'
    ? `Missing ${backendNameToLabel(election?.pricingOption?.factoredField)}`
    : formatCurrency(election?.premiumTotal);
};

// 6%
// $5.00
export const formatPricing = ({ rate, isPercentageFactor, rateMode }) => {
  if (['flat', 'FLAT'].includes(rateMode)) return formatCurrency(rate);
  return formattedRate({ rate, isPercentageFactor });
};

// 6% of settlement
// $5.00 per mile
// $10.00 (if flat)
export const formatPricingWithLabel = ({ rate, isPercentageFactor, factoredField, rateMode }) => {
  if (['flat', 'FLAT'].includes(rateMode)) return formatCurrency(rate);
  return factoredPricingWithLabel({ rate, isPercentageFactor, factoredField });
};

// per mile
// of settlement
// ""
export const pricingLabel = ({ factoredField }) => {
  if (!factoredField) return '';
  const shortenedField = factoredField?.split('api_').join('').replace('per_', '');
  const joiner = factoredField?.includes('_per_') ? 'per' : 'of';
  return `${joiner} ${backendNameToLabel(shortenedField).toLowerCase()}`;
};

// $5.00 per mile at 342 mile(s)
// 6% of settlement at 54.24
// Flat Premium
export const pricingDefinitionLabel = ({ rate, isPercentageFactor, rateMode, factoredField, factorValue }) => {
  const label = formatPricingWithLabel({ rate, isPercentageFactor, factoredField, rateMode });
  if (['flat', 'FLAT'].includes(rateMode)) return `Flat Premium`;
  const unit = factoredField?.includes('_per_') && factoredField?.replace('api_', '').replace('per_', '');
  const unitsLabel = unit ? `${unit}(s)` : '';
  return `Factored Premium ${label} at ${factorValue} ${unitsLabel}`;
};

const formattedRate = ({ rate, isPercentageFactor }) => {
  const intOnly = rate?.replace('%', '').replace('$', '');
  return isPercentageFactor ? `${convertDecimalToPercentage(intOnly)}` : `${formatExtendedCurrency(intOnly)}`;
};

const factoredPricingWithLabel = ({ rate, isPercentageFactor, factoredField }) => {
  const prettyRate = formattedRate({ rate, isPercentageFactor });
  const endingLabel = pricingLabel({ factoredField });

  return `${prettyRate} ${endingLabel}`;
};
