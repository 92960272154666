import React from 'react';
import { Badge } from '@oforce/global-components';

export default function MemberStatusBadge({ status }) {
  const color =
    {
      CLAIMED: 'success',
      PENDING: 'warning',
      DECLINED: 'danger',
      CANCELED: 'danger',
      REMOVED: 'danger'
    }[status] || 'muted';

  const statusText = {
    CLAIMED: 'Claimed',
    PENDING: 'Pending',
    DECLINED: 'Declined',
    CANCELED: 'Revoked',
    REMOVED: 'Removed'
  }[status];

  return (
    <span className="text-muted fs-sm">
      <Badge color={color} className="my-1">
        {statusText}
      </Badge>
    </span>
  );
}
