import React from 'react';
import { Label, Button, PlaceholderText, useToggle } from '@oforce/global-components';

import { useCurrentUser } from '../../../shared/currentUser';

import { AddStateFee, StateFee } from '.';

export default function StateFees({ insuranceTypeId, stateFees }) {
  const [showAddStateFee, toggleAddStateFee] = useToggle(false);
  const { isAdmin } = useCurrentUser();

  return (
    <div className="d-flex flex-column w-100">
      <div className="d-flex justify-content-between">
        <Label>State Fees</Label>
        {isAdmin && (
          <Button color="link" size="sm" icon={['far', 'plus']} onClick={toggleAddStateFee}>
            Create State Fee
          </Button>
        )}
      </div>

      {stateFees?.length > 0 ? (
        stateFees?.map(fee => <StateFee key={fee.id} stateFee={fee} />)
      ) : (
        <PlaceholderText>None</PlaceholderText>
      )}

      <AddStateFee
        showAddStateFee={showAddStateFee}
        toggleAddStateFee={toggleAddStateFee}
        insuranceTypeId={insuranceTypeId}
      />
    </div>
  );
}
