import { gql } from '@apollo/client';
import { useSubscription } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';

import { NotificationStatusReportJobContext } from './context';
import { useResettableMutation } from '../../hooks/useResettableMutation';

const START_JOB = gql`
  mutation startNotificationStatusReportJob($notificationJobId: ID!) {
    notificationStatusReport(notificationJobId: $notificationJobId)
  }
`;

const ON_NOTIFICATION_STATUS_REPORT_JOB_PROGRESS = gql`
  subscription jobProgress($jobId: ID!) {
    job: notificationStatusReportJobProgress(jobId: $jobId) {
      jobId
      filename
      url
      finished
      message
      errors
    }
  }
`;

export default function NotificationStatusReportJobProvider({ children }) {
  const [jobId, setJobId] = useState(null);
  const [jobErrors, setJobErrors] = useState([]);
  const [finished, setJobFinished] = useState(true);
  const [message, setMessage] = useState(null);
  const [filename, setFilename] = useState(null);
  const [url, setUrl] = useState(null);

  const clearJobId = useCallback(() => {
    setJobId(null);
    setJobErrors([]);
  }, []);

  useEffect(() => {
    if (!!jobId && !!finished) {
      setTimeout(() => clearJobId(), 10000);
    }
  }, [finished, clearJobId, jobId]);

  const [startNotificationReportJob, { loading, error, reset }] = useResettableMutation(START_JOB, {
    onCompleted: data => {
      setJobFinished(false);
      setJobId(data?.notificationStatusReport);
    },
    onError: () => setTimeout(() => reset(), 10000)
  });

  const startNotificationStatusReportJob = data => {
    setJobFinished(false);
    startNotificationReportJob({ variables: { notificationJobId: data?.notificationJobId } });
  };

  useSubscription(ON_NOTIFICATION_STATUS_REPORT_JOB_PROGRESS, {
    skip: !jobId,
    variables: { jobId },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      const job = data?.job;
      const { message, filename, url, finished, errors } = job;

      setJobErrors(errors);
      setJobFinished(finished);
      setMessage(message);
      setFilename(filename);
      setUrl(url);
    }
  });

  return (
    <NotificationStatusReportJobContext.Provider
      value={{
        startNotificationStatusReportJob,
        jobId,
        clearJobId,
        loading,
        error,
        finished,
        filename,
        url,
        message,
        jobErrors
      }}
    >
      {children}
    </NotificationStatusReportJobContext.Provider>
  );
}
