import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { GET_EXPOSURE_ELECTION_ACTION_PERMISSIONS } from '../../../components/quickActions/useQuickActionPermissions';
import { GET_EXPOSURE_ELECTION_COVERAGE_HISTORY } from '../../coverage/hooks/useElectionHistoryCoverage';
import { GET_ELECTION_DOCUMENTS } from '../../document/model/Queries';

const CHANGE_ELECTION = gql`
  mutation changeElection($exposureElectionId: ID!, $pricingOptionId: ID!, $addOnIds: [ID]) {
    changeExposureElection(
      exposureElectionId: $exposureElectionId
      pricingOptionId: $pricingOptionId
      addOnIds: $addOnIds
    ) {
      id
      total
      addOns {
        id
        label
      }
      terminationDate
      pricingOption {
        id
        rateMode
        rate
        label
        name
        factoredField
      }
    }
  }
`;

export default function useChangeElection({ election, onCompleted, onError = () => {} }) {
  const [changeElection, { loading, error }] = useMutation(CHANGE_ELECTION, {
    onCompleted,
    refetchQueries: [
      { query: GET_EXPOSURE_ELECTION_COVERAGE_HISTORY, variables: { id: election.id } },
      { query: GET_EXPOSURE_ELECTION_ACTION_PERMISSIONS, variables: { id: election?.id } },
      { query: GET_ELECTION_DOCUMENTS, variables: { id: election?.id } }
    ],
    onError
  });

  return {
    changeElection: ({ pricingOptionId, addOnIds }) => {
      changeElection({
        variables: { exposureElectionId: election?.id, pricingOptionId, addOnIds }
      });
    },
    loading,
    error
  };
}
