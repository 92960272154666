import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import { toSentenceCase, toCamelCase, toHeaderCase } from 'js-convert-case';

import { BoxItem, BoxItemTitle, Render } from '../../../components';

export default function StakeholderBoxItem({
  formik,
  stakeholderType,
  requiredFields,
  renderState,
  setRenderedForm,
  children
}) {
  const exists = Object.keys(formik?.values)
    .filter(key => key.startsWith(stakeholderType))
    .reduce((acc, key) => (formik?.values[key] ? true : acc), false);

  const isRequired = Object.keys(requiredFields).reduce(
    (acc, key) => (toCamelCase(key).startsWith(stakeholderType) ? true : acc),
    false
  );

  const hasErrors = Object.keys(formik?.errors).reduce(
    (acc, key) => (toCamelCase(key).startsWith(stakeholderType) ? true : acc),
    false
  );

  const clearFormValues = form =>
    Object.keys(form?.values)
      .filter(key => key.startsWith(stakeholderType))
      .map(key => formik?.setFieldValue(key, ''));

  const borderColor = hasErrors ? 'border-danger' : 'border-gray-400';

  const boxTitle = (
    <span className="d-flex align-items-center">
      {toSentenceCase(stakeholderType)}
      {isRequired && <span className="text-danger ml-1"> *</span>}
    </span>
  );

  const stakeholderName = formik.values[stakeholderType + 'Name'] ?? '';

  const { current: incomingValues } = useRef(formik?.values);

  const submit = () => {
    formik.validateForm();
    const hasErrors = Object.keys(formik?.errors).reduce((acc, key) => {
      return key.startsWith(stakeholderType) ? true : acc;
    }, false);
    if (!hasErrors) setRenderedForm(null);
  };

  const cancel = () => {
    Object.keys(formik?.values).forEach(key => {
      if (key.startsWith(stakeholderType)) formik.setFieldValue(key, incomingValues[key]);
    });
    setRenderedForm(null);
  };

  return (
    <>
      <Render if={renderState[stakeholderType] === 'box'}>
        <BoxItem className={`border rounded ${borderColor} d-flex justify-content-between`}>
          <BoxItemTitle title={boxTitle} subtitle={stakeholderName} />
          <div>
            {!exists && (
              <Button color="link" type="button">
                <FontAwesomeIcon
                  onClick={() => setRenderedForm(stakeholderType)}
                  type="button"
                  icon={['far', 'plus']}
                  className="ml-2"
                />
              </Button>
            )}
            {exists && (
              <Button color="link" type="button">
                <FontAwesomeIcon
                  onClick={() => setRenderedForm(stakeholderType)}
                  type="button"
                  icon={['far', 'pencil']}
                  className="ml-2"
                />
              </Button>
            )}
            {exists && (
              <Button color="link" type="button">
                <FontAwesomeIcon onClick={() => clearFormValues(formik)} icon={['far', 'trash-alt']} className="ml-2" />
              </Button>
            )}
            {hasErrors && <FontAwesomeIcon icon={['far', 'exclamation-circle']} className="text-danger mr-1" />}
          </div>
        </BoxItem>
        <div className="fs-sm text-danger d-inline-block">
          {hasErrors && `${toSentenceCase(stakeholderType)} is missing required fields.`}
        </div>
      </Render>
      <Render if={renderState[stakeholderType] === 'form'}>
        <div className="mb-2">
          <h6>{toHeaderCase(stakeholderType)}</h6>
        </div>
        {children}
        <Button type="button" onClick={submit} className="mr-2" color="success">
          Save
        </Button>
        <Button onClick={cancel} color="danger">
          Cancel
        </Button>
      </Render>
    </>
  );
}
