import { useFormOptions } from '../../../hooks';
import useClientPolicyOptions from './useClientPolicyOptions';

export default function useClientDocumentFormOptions({ clientId }) {
  const documentTypeOptions = [
    { value: 'CERTIFICATE_OF_INSURANCE', label: 'Certificate of Insurance' },
    { value: 'GENERAL', label: 'General' }
  ];

  const { clientPolicyOptions, loading, error } = useClientPolicyOptions({
    clientId
  });

  const formOptions = useFormOptions({
    options: [
      { name: 'documentType', options: documentTypeOptions },
      { name: 'policies', options: clientPolicyOptions, loading, error }
    ]
  });

  return {
    formOptions
  };
}
