import { DatePickerField, InputField, ReactCreatableSelectField } from '@oforce/global-components';
import { useState } from 'react';

export default function DocumentEmailFields({ initialEmailOptions, showActiveOn }) {
  const [emailAddresses, setEmails] = useState(initialEmailOptions);
  const setEmailValues = value => setEmails(values => [...values, { value: value, label: value }]);

  return (
    <>
      {showActiveOn && <DatePickerField fullWidth required name="activeOn" label="Active On" format="MM/DD/YYYY" />}
      <ReactCreatableSelectField
        className="mb-4"
        options={emailAddresses}
        isMulti
        required
        name="emailAddresses"
        label="Email Address(es)"
        onCreateOption={value => setEmailValues(value)}
      />
      <InputField required label="Email Subject" name="subject" />
      <InputField
        required
        type="textarea"
        label="Email Body"
        name="body"
        messageAfter={'The file included with this email will expire in 7 days.'}
        messageAfterProps={{ icon: ['far', 'info-circle'] }}
      />
    </>
  );
}
