import React, { useState } from 'react';
import { FormSection } from '../../../../components';
import { InsuredStakeholderFormFields } from '../../../insuredStakeholder/components';
import { StakeholderBoxItem } from '../../../stakeholder/components';

export default function InsuredStakeholdersStep({ requiredInsuredFields = {}, formik, formOptions }) {
  formik.validateOnChange = true;

  const singleFormState = {
    beneficiary: null
  };
  const boxesState = {
    beneficiary: 'box'
  };
  const [renderState, setRenderState] = useState(boxesState);
  const setRenderedForm = key => {
    if (key) setRenderState({ ...singleFormState, ...{ [key]: 'form' } });
    else setRenderState(boxesState);
  };

  return (
    <FormSection title="Beneficiary">
      {Object.keys(boxesState).map(key => {
        return (
          <StakeholderBoxItem
            key={key}
            setRenderedForm={setRenderedForm}
            renderState={renderState}
            formik={formik}
            requiredFields={requiredInsuredFields}
            stakeholderType={key}
          >
            <InsuredStakeholderFormFields
              formOptions={formOptions}
              stakeholderType={key}
              requiredFields={requiredInsuredFields}
            />
          </StakeholderBoxItem>
        );
      })}
    </FormSection>
  );
}
