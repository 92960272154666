import { useState } from 'react';
import PendingElectionsFilters from './PendingElectionsFilters';
import PendingElectionsTable from './PendingElectionsTable';
import { TablePagination } from '../../components/tables';
import usePendingElections from './usePendingElections';

export default function PendingElections() {
  const [filters, setFilters] = useState({
    ordering: 'PENDING_ELECTION_UPDATED',
    searchBy: [],
    archived: false,
    claimed: false,
    withExternalId: true
  });

  const { pendingElections, loading, error, refetch, paginationProps } = usePendingElections({
    pageSize: 20,
    filters
  });

  return (
    <div>
      <PendingElectionsFilters defaults={filters} onChange={setFilters} filterValue={filters} />
      <PendingElectionsTable
        changeFilter={setFilters}
        pendingElections={pendingElections}
        refetch={refetch}
        error={error}
      />
      <TablePagination {...paginationProps} loading={loading} />
    </div>
  );
}
