import React from 'react';
import { Tooltip, UncontrolledTooltip } from 'reactstrap';
import { Badge, Render } from '@oforce/global-components';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { Td, ActionDropdown } from '../../../components/table-2.0';
import { useDownloadFile, useToggle } from '../../../hooks';
import { backendNameToLabel, formatDate } from '../../../utils/formatting';
import { HardDeleteProductDocument } from '../containers';
import { useActiveAccount } from '../../../shared/activeAccount';
import { EmptyCell } from '../../../components/tables';

export default function DocumentsTableRow({ pricingOptions, doc }) {
  const {
    ROLES: { ADMIN },
    showForRoles
  } = useActiveAccount();
  const history = useHistory();
  const { url } = useRouteMatch();

  const [showPricingOptionsPopover, togglePricingOptionsPopover] = useToggle();
  const [showHardDeleteDocument, toggleHardDeleteDocument] = useToggle();

  const pricingOptionsBadgeText = doc?.appliesToAllPricingOptions
    ? `All Pricing Options (${pricingOptions?.length})`
    : `Some Pricing Options (${doc?.pricingOptionIds?.length})`;

  const appliedPricingOptions = doc?.appliesToAllPricingOptions
    ? pricingOptions
    : pricingOptions?.filter(x => doc?.pricingOptionIds.includes(x?.id));

  const { download, loading } = useDownloadFile({
    url: doc?.url,
    filename: doc?.filename
  });

  const requiredStatesTooltipId = `required-states-${doc?.id}`;

  return (
    <>
      <tr>
        <td>
          <div className="font-weight-semibold" style={{ marginTop: '3px' }}>
            {doc?.name}
          </div>
          <div className="font-weight-400 fs-sm text-muted" style={{ marginTop: '0px', marginBottom: '3px' }}>
            {backendNameToLabel(doc?.type)} • PDF
          </div>
        </td>
        <td>
          <div className="font-weight-400">{formatDate(doc?.effectiveDate)}</div>
        </td>
        <td>
          {!!doc?.expirationDate ? (
            <div className="font-weight-400">{formatDate(doc?.expirationDate)}</div>
          ) : (
            <EmptyCell />
          )}
        </td>
        <td>
          <Badge
            size="sm"
            id={`a-${doc?.id}`}
            color="secondary"
            className="cursor-pointer"
            iconAfter={['far', 'info-circle']}
          >
            {pricingOptionsBadgeText}
          </Badge>
          <Tooltip
            target={`a-${doc?.id}`}
            placement="bottom"
            isOpen={showPricingOptionsPopover}
            toggle={togglePricingOptionsPopover}
          >
            {appliedPricingOptions?.map(pp => (
              <div key={pp?.id}>{pp?.label}</div>
            ))}
          </Tooltip>
        </td>
        <Td>
          <Render if={doc?.appliesToStates?.length > 0 && !doc?.excludeFromStates?.length}>
            <Badge
              size="sm"
              id={requiredStatesTooltipId}
              color="secondary"
              className="cursor-pointer"
              iconAfter={['far', 'info-circle']}
            >
              Applies to ({doc?.appliesToStates?.length}) States
            </Badge>
            <UncontrolledTooltip target={requiredStatesTooltipId} placement="bottom">
              {doc?.appliesToStates?.join(', ')}
            </UncontrolledTooltip>
          </Render>
          <Render if={doc?.excludeFromStates?.length > 0}>
            <Badge
              size="sm"
              id={requiredStatesTooltipId}
              color="secondary"
              className="cursor-pointer"
              iconAfter={['far', 'info-circle']}
            >
              Excluded From ({doc?.excludeFromStates?.length}) States
            </Badge>
            <UncontrolledTooltip target={requiredStatesTooltipId} placement="bottom">
              {doc?.excludeFromStates?.join(', ')}
            </UncontrolledTooltip>
          </Render>
          <Render if={!doc?.appliesToStates || doc?.appliesToStates?.length === 0}>
            <span className="fs-sm text-gray-500">None</span>
          </Render>
        </Td>
        <td align="right">
          <ActionDropdown
            onEdit={() => history.push(`${url}/${doc?.id}/update`)}
            onDownload={download}
            downloading={loading}
            onHardDelete={showForRoles([ADMIN]) ? toggleHardDeleteDocument : null}
          />
        </td>
      </tr>
      <Render if={showHardDeleteDocument}>
        <HardDeleteProductDocument doc={doc} isOpen={showHardDeleteDocument} toggle={toggleHardDeleteDocument} />
      </Render>
    </>
  );
}
