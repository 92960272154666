import React from 'react';
import { BoxItem, BoxItemTitle } from '../../../../../components';

export default function CoveragesPlaceholder() {
  return (
    <BoxItem className="border rounded border-gray-400 mb-3">
      <BoxItemTitle title="No Coverages" subtitle="Add a coverage to continue" />
    </BoxItem>
  );
}
