import React from 'react';
import { gql } from '@apollo/client';

import PolicyAssignedAccount from './components/PolicyAssignedAccount';
import { POLICY } from '../../utils/constants';

export const ASSIGNED_ACCOUNTS_FIELDS = gql`
  fragment AssignedAccountsFields on Policy {
    assignedAccounts {
      broker {
        account {
          id
          name
          type
        }
        email
        policyAccountAssignmentId
      }
      client {
        account {
          id
          name
          type
        }
        email
        policyAccountAssignmentId
      }
      claimsAdjuster {
        account {
          id
          name
          type
        }
        email
        policyAccountAssignmentId
      }
    }
  }
`;

export const POLICY_INVITATIONS_FIELDS = gql`
  fragment PolicyInvitationsFields on Policy {
    invitations {
      clientInvitation {
        id
        emailAddress
        status
        role
      }
      brokerInvitation {
        id
        emailAddress
        status
        role
      }
      claimsAdjusterInvitation {
        id
        emailAddress
        status
        role
      }
    }
  }
`;

export default function PolicyManagers({ policy }) {
  return (
    <>
      <PolicyAssignedAccount
        accountType="Client"
        invitationRole={POLICY.INVITATION.ROLES.CLIENT}
        invitation={policy?.invitations?.clientInvitation}
        account={policy?.assignedAccounts?.client?.account}
        policyAccountAssignmentId={policy?.assignedAccounts?.client?.policyAccountAssignmentId}
        policyId={policy?.id}
        email={policy?.assignedAccounts?.client?.email}
      />
      <PolicyAssignedAccount
        accountType="Broker"
        invitationRole={POLICY.INVITATION.ROLES.BROKER}
        invitation={policy?.invitations?.brokerInvitation}
        account={policy?.assignedAccounts?.broker?.account}
        policyAccountAssignmentId={policy?.assignedAccounts?.broker?.policyAccountAssignmentId}
        policyId={policy?.id}
        email={policy?.assignedAccounts?.broker?.email}
      />
      <PolicyAssignedAccount
        accountType="Claims Adjuster"
        invitationRole={POLICY.INVITATION.ROLES.CLAIMS_ADJUSTER}
        invitation={policy?.invitations?.claimsAdjusterInvitation}
        account={policy?.assignedAccounts?.claimsAdjuster?.account}
        policyAccountAssignmentId={policy?.assignedAccounts?.claimsAdjuster?.policyAccountAssignmentId}
        policyId={policy?.id}
        email={policy?.assignedAccounts?.claimsAdjuster?.email}
      />
    </>
  );
}
