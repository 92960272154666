import { useCanadianProvinces, useUSStates } from '../../../hooks';
import { useVehicleTypeOptions } from '../../../views/configurationVehicleTypes/hooks';
import { useStateFeeRateModes } from '../../../components/sharedComponents/pricing';
import { convertDecimalToPercentage, convertPercentageToDecimal, convertToFloat } from '../../../utils';

import { useUpdateStateFee, useStateFeeValidationSchema } from '.';

export default function useUpdateStateFeeForm({ stateFee, onUpdated }) {
  const { updateStateFee, loading, error, reset } = useUpdateStateFee({
    onCompleted: onUpdated
  });

  const { stateFeeRateModeOptions } = useStateFeeRateModes();
  const { options: usStateOptions } = useUSStates();
  const { options: canadianProvinceOptions } = useCanadianProvinces();
  const stateOptions = [...usStateOptions, ...canadianProvinceOptions];
  const { validationSchema } = useStateFeeValidationSchema();
  const { vehicleTypeOptions } = useVehicleTypeOptions();

  const initialState = stateOptions?.find(x => x.value === stateFee?.state) || null;

  const initialValues = {
    fee: stateFee?.activeMode === 'PERCENTAGE' ? convertDecimalToPercentage(stateFee?.fee) : stateFee?.fee,
    state: initialState,
    activeMode: stateFeeRateModeOptions?.find(x => x?.value === stateFee?.activeMode) || null,
    vehicleTypes: vehicleTypeOptions.filter(x => stateFee?.vehicleTypes.find(t => t.id === x.value))
  };

  const handleSubmit = ({ fee, activeMode, state, vehicleTypes }) => {
    updateStateFee({
      id: stateFee?.id,
      fee: activeMode?.value === 'PERCENTAGE' ? convertPercentageToDecimal(fee) : convertToFloat(fee),
      activeMode: activeMode?.value,
      state: state?.value,
      vehicleTypeIds: vehicleTypes?.map(v => v.value)
    });
  };

  return {
    initialValues,
    validationSchema,
    handleSubmit,
    loading,
    error,
    reset
  };
}
