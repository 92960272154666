import React from 'react';
import { useHistory } from 'react-router-dom';
import { Tr, Td, useToggle, CustomDropdownItem, Badge, Render } from '@oforce/global-components';

import { useCurrentUser } from '../../../shared/currentUser';
import { ActionDropdown } from '../../../components/table-2.0';
import { backendNameToLabel } from '../../../utils/formatting';
import { ShowForRoles, useActiveAccount } from '../../../shared/activeAccount';
import { UserAvatar, UserName } from '../../../views/myProfile/components/ProfileHeader';

import { MemberStatusBadge } from '.';
import { AccountMemberRemove, AccountRevokeInvite, AccountResendInvite, AccountTransferOwnership } from '../containers';

export default function AccountMembersTableRow({
  userId,
  invitationId,
  status,
  avatar,
  name,
  role,
  email,
  isCurrentUser
}) {
  const history = useHistory();
  const { id: currentUserId } = useCurrentUser();

  const {
    id: activeAccountId,
    setActiveAccountId,
    ROLES: { COLLABORATOR, OWNER }
  } = useActiveAccount();

  const [showMakeOwner, toggleMakeOwner] = useToggle(false);
  const [showRemoveMember, toggleRemoveMember] = useToggle(false);
  const [showResendInvite, toggleResendInvite] = useToggle(false);
  const [showRevokeInvite, toggleRevokeInvite] = useToggle(false);

  const isOwner = role === OWNER;
  const isMember = status === 'MEMBER';
  const isInvite = status !== 'CLAIMED' && !isMember;
  const isRevoked = status === 'CANCELED' || status === 'DECLINED';

  return (
    <>
      <Tr>
        <Td>
          <div className="d-flex flex-row align-items-center">
            <UserAvatar size="sm" avatar={avatar} className="mr-3" />
            <span className="d-flex flex-column">
              <span className="d-flex flex-row align-items-start ">
                <UserName name={name} size="sm" />
                <Render if={isCurrentUser}>
                  <Badge size="sm" color="info" className="ml-2">
                    You
                  </Badge>
                </Render>
              </span>
              <Email>{email}</Email>
            </span>
          </div>
        </Td>
        <Td>
          <Render if={isOwner}>
            <Badge color="success">Account Owner</Badge>
          </Render>
          <Render if={!isOwner}>
            <span className="text-muted">{backendNameToLabel(role)}</span>
          </Render>
        </Td>
        <Td>
          <Render if={isMember}>
            <span className="text-muted">Member</span>
          </Render>
          <Render if={!isMember}>
            <MemberStatusBadge status={status} />
          </Render>
        </Td>
        <Td>
          {/* don't show any actions if it's the owner user */}
          <Render if={!isOwner}>
            <ActionDropdown>
              {/* these actions are only available on a user */}
              <Render if={!isInvite}>
                {/* only owners can transfer ownership */}
                <ShowForRoles roles={[OWNER]}>
                  <CustomDropdownItem icon={['far', 'user-edit']} color="info" onClick={toggleMakeOwner}>
                    Make Owner
                  </CustomDropdownItem>
                </ShowForRoles>

                {/* only owners and collaborators can remove other members */}
                <ShowForRoles roles={[OWNER, COLLABORATOR]}>
                  <CustomDropdownItem icon={['far', 'user-times']} color="danger" onClick={toggleRemoveMember}>
                    Remove Member
                  </CustomDropdownItem>
                </ShowForRoles>
              </Render>

              {/* these actions are only available on an invite */}
              <Render if={isInvite}>
                {/* only owners are allowed to resend and revoke invites */}
                <ShowForRoles roles={[OWNER, COLLABORATOR]}>
                  <CustomDropdownItem icon={['far', 'sync-alt']} color="info" onClick={toggleResendInvite}>
                    Resend Invite
                  </CustomDropdownItem>

                  <Render if={!isRevoked}>
                    <CustomDropdownItem icon={['far', 'ban']} color="danger" onClick={toggleRevokeInvite}>
                      Revoke Invite
                    </CustomDropdownItem>
                  </Render>
                </ShowForRoles>
              </Render>
            </ActionDropdown>
          </Render>
        </Td>
      </Tr>

      <AccountMemberRemove
        isOpen={showRemoveMember}
        toggle={toggleRemoveMember}
        accountId={activeAccountId}
        userId={userId}
        memberName={name}
        onCompleted={async ({ userId }) => {
          if (userId === currentUserId) {
            await setActiveAccountId(null);
            history.replace('/my-profile');
          }
        }}
      />

      <AccountTransferOwnership isOpen={showMakeOwner} toggle={toggleMakeOwner} memberName={name} userId={userId} />

      <AccountRevokeInvite
        isOpen={showRevokeInvite}
        toggle={toggleRevokeInvite}
        invitationId={invitationId}
        inviteeEmail={email}
      />

      <AccountResendInvite
        isOpen={showResendInvite}
        toggle={toggleResendInvite}
        invitationId={invitationId}
        inviteeEmail={email}
      />
    </>
  );
}

const Email = ({ children }) => <span className="text-muted">{children}</span>;
