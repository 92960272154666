import { gql, useMutation } from '@apollo/client';
const EDIT_ELECTION_DATA = gql`
  mutation UpdateExposureElectionData($id: ID!, $data: Json!) {
    updateExposureElectionData(exposureId: $id, data: $data) {
      id
      electionData
    }
  }
`;

export default function useEditElectionData({ election, onCompleted = () => {}, onError = () => {} }) {
  const [editElectionData, { loading, error, reset }] = useMutation(EDIT_ELECTION_DATA, {
    onCompleted,
    onError
  });

  const handleSubmit = data => {
    editElectionData({ variables: { id: election.id, data: JSON.stringify(data) } });
  };

  return {
    loading,
    error,
    reset,
    handleSubmit
  };
}
