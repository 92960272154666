import React, { useEffect, useState } from 'react';
import { RenderData, TrLoader, TrPlaceholder } from '@oforce/global-components';

import { Table } from '../../../components/table-2.0';
import { BoxContainer, TrGraphQLErrors } from '../../../components';

import { AccountMembersTableRow } from '.';

export default function AccountMembersTable({ members, loading, error, className }) {
  const [mutationError, setError] = useState(null);
  const colSpan = 4;

  useEffect(() => {
    if (mutationError) {
      setTimeout(() => setError(null), 10000);
    }
  }, [mutationError]);

  return (
    <BoxContainer className={className}>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Role</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <RenderData
            data={members}
            loading={loading}
            error={error || mutationError}
            loadingComponent={<TrLoader colSpan={colSpan} />}
            errorComponent={<TrGraphQLErrors colSpan={colSpan} error={error || mutationError} />}
            placeholderComponent={<TrPlaceholder colSpan={colSpan}>None</TrPlaceholder>}
          >
            {members =>
              members?.map(member => (
                <AccountMembersTableRow
                  key={member?.userId || member?.invitationId}
                  {...member}
                  onError={error => setError(error)}
                />
              ))
            }
          </RenderData>
        </tbody>
      </Table>
    </BoxContainer>
  );
}
