import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { AccountInvitation, PolicyInvitation } from '../views/invitations/components';

import {
  MyProfile,
  Account,
  Companies,
  Products,
  Insureds,
  Vehicles,
  Invoices,
  Clients,
  Transactions,
  Policies,
  Reports,
  ApiExplorer,
  Configuration,
  PendingElections,
  Coverages,
  NotificationJobs
} from '../views';

import { useActiveAccount } from '../shared/activeAccount';
import { AccessDeniedPage, RedirectingPage } from '../components/pages';
import { RouteHiddenFromRolesAndTypes, RouteShownForTypes } from '../shared/activeAccount/components';

import AdminRoute from './AdminRoute';
import ProtectedRoute from './ProtectedRoute';

export { AdminRoute, ProtectedRoute };

export default function Routes() {
  const {
    id: activeAccountId,
    TYPES: { CARRIER, CLAIMS_ADJUSTER },
    ROLES: { OBSERVER, COLLABORATOR, POLICY_MANAGER, EXPOSURE_MANAGER, OWNER, ADMIN }
  } = useActiveAccount();

  const allRoles = [OBSERVER, COLLABORATOR, POLICY_MANAGER, EXPOSURE_MANAGER, OWNER, ADMIN];

  return (
    <Switch>
      <Redirect exact from="/" to={!!activeAccountId ? '/policies' : '/my-profile/invitations'} />
      <Route path="/callback">
        <RedirectingPage />
      </Route>
      <AdminRoute path="/configuration" component={Configuration} />
      <AdminRoute path="/pending-elections" component={PendingElections} />
      <Route path="/my-profile" component={MyProfile} />
      <Route path="/api-explorer" component={ApiExplorer} />
      <Route path="/insureds" component={Insureds} />
      <Route path="/coverages" component={Coverages} />
      <Route path="/vehicles" component={Vehicles} />
      <Route path="/policies" component={Policies} />
      <Route path="/notifications" component={NotificationJobs} />
      <Route path="/account-invitation/:id" render={({ match }) => <AccountInvitation id={match.params.id} />} />
      <Route path="/policy-invitation/:id" render={({ match }) => <PolicyInvitation id={match.params.id} />} />

      <RouteHiddenFromRolesAndTypes types={[CLAIMS_ADJUSTER]} roles={[OBSERVER]} path="/invoices" component={Invoices} />
      <RouteHiddenFromRolesAndTypes types={[CLAIMS_ADJUSTER]} roles={[OBSERVER]} path="/products" component={Products} />
      <RouteHiddenFromRolesAndTypes types={[CLAIMS_ADJUSTER]} roles={[OBSERVER]} path="/clients" component={Clients} />
      <RouteHiddenFromRolesAndTypes types={[CLAIMS_ADJUSTER]} roles={[OBSERVER]} path="/transactions" component={Transactions} />
      <RouteHiddenFromRolesAndTypes types={[CLAIMS_ADJUSTER]} roles={[OBSERVER]} path="/reports" component={Reports} />
      <RouteHiddenFromRolesAndTypes roles={[OBSERVER]} path="/account" component={Account} />

      <RouteShownForTypes
        types={[CARRIER]}
        roles={allRoles.filter(x => x !== OBSERVER)}
        path="/companies"
        component={Companies}
      />

      <Route exact path="/access-denied">
        <AccessDeniedPage />
      </Route>
    </Switch>
  );
}
