import React from 'react';
import { Formik } from 'formik';
import { Button, Loader, useToggle } from '@oforce/global-components';

import { ClientForm } from '../../views/client/components';

import { BoxContainer, GraphQLErrors, Render } from '../../components';

import { useUpdateClientForm } from './hooks';
import { ClientDetailsView } from './components';
import { TabPage } from '../../components/tabs';
import { useUSStates } from '../../hooks';
import { useCanadianProvinces, useCountries, useFormOptions } from '../../hooks/forms';

export default function ClientDetails({ client, loading, error }) {
  const [showUpdate, toggleUpdate] = useToggle();

  const { options: stateOptions } = useUSStates();
  const { options: countryOptions } = useCountries();
  const { options: canadianProvincesOptions } = useCanadianProvinces();
  const formOptions = useFormOptions({
    options: [
      { name: 'state', options: stateOptions },
      { name: 'canadianProvinces', options: canadianProvincesOptions },
      { name: 'country', options: countryOptions },
      { name: 'allStates', options: [...stateOptions, ...canadianProvincesOptions] }
    ]
  });
  const {
    initialValues,
    validationSchema,
    handleSubmit,
    loading: updating,
    error: updateError
  } = useUpdateClientForm({ client, formOptions, onCompleted: toggleUpdate });

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {formik => (
        <TabPage
          title="Details"
          showBackButton={showUpdate}
          onBack={toggleUpdate}
          backButtonText="Cancel"
          buttons={
            <>
              <Render if={showUpdate}>
                <Button
                  color="success"
                  type="submit"
                  className="ml-2"
                  onClick={formik.handleSubmit}
                  loadingText="Saving..."
                  loading={updating}
                  icon={['far', 'check']}
                >
                  Save Changes
                </Button>
              </Render>
              <Render if={!showUpdate}>
                <Button color="info" className="ml-2" onClick={toggleUpdate} icon={['far', 'pen']}>
                  Edit
                </Button>
              </Render>
            </>
          }
        >
          <BoxContainer className="p-3">
            {loading && <Loader />}
            {!loading && error && <GraphQLErrors error={error || updateError} />}
            {!loading && !error && showUpdate ? (
              <ClientForm
                products={client?.products}
                loading={loading}
                formOptions={formOptions}
                formik={formik}
                error={updateError}
              />
            ) : (
              <ClientDetailsView client={client} />
            )}
          </BoxContainer>
        </TabPage>
      )}
    </Formik>
  );
}
