import React from 'react';
import { TrLoader, TrPlaceholder } from '@oforce/global-components';
import _ from 'lodash';

import { Table } from '../../../components/table-2.0';
import { TrGraphQLErrors, RenderData } from '../../../components';

import { RetailCommissionsTableRow } from '.';
import { HideFromRoles, useActiveAccount } from '../../../shared/activeAccount';

export default function RetailCommissionsTable({ policyId, retailCommissions, loading, error }) {
  const {
    hideFromRoles,
    ROLES: { POLICY_MANAGER, EXPOSURE_MANAGER }
  } = useActiveAccount();
  const sortedRetailCommissions = _.sortBy(retailCommissions, ['name']);
  const showExtraColumn = !hideFromRoles([POLICY_MANAGER, EXPOSURE_MANAGER]);
  const colSpan = showExtraColumn ? 4 : 3;

  return (
    <Table>
      <thead>
        <tr>
          <th>Commission</th>
          <th>Rate Mode</th>
          <th>Applies to</th>
          <HideFromRoles roles={[POLICY_MANAGER, EXPOSURE_MANAGER]}>
            <th />
          </HideFromRoles>
        </tr>
      </thead>
      <tbody>
        <RenderData
          data={sortedRetailCommissions}
          loading={loading}
          error={error}
          loadingComponent={<TrLoader colSpan={colSpan} />}
          errorComponent={<TrGraphQLErrors colSpan={colSpan} error={error} />}
          placeholderComponent={
            <TrPlaceholder colSpan={colSpan} className="bg-gray-100 border-bottom border-width-2 border-gray-300">
              No retail commissions
            </TrPlaceholder>
          }
        >
          {retailCommissions =>
            retailCommissions?.map(retailCommission => (
              <RetailCommissionsTableRow
                key={retailCommission?.id}
                retailCommission={retailCommission}
                policyId={policyId}
              />
            ))
          }
        </RenderData>
      </tbody>
    </Table>
  );
}
