import _ from 'lodash';
import { DatePickerField, InputField, CheckboxField, ReactSelectField } from '@oforce/global-components';

import { VARIABLE_TYPES } from '../constants';

export default function VariableInputField({ name, type, required, options, focusRef, showFocusRef }) {
  const getLabel = name => _.startCase(_.snakeCase(name)?.replaceAll('_', ' '));

  const fieldProps = { label: getLabel(name), name, required, ...(showFocusRef && { innerRef: focusRef }) };

  switch (type) {
    case VARIABLE_TYPES.INT:
    case VARIABLE_TYPES.DECIMAL:
    case VARIABLE_TYPES.FLOAT:
    case VARIABLE_TYPES.ID:
    case VARIABLE_TYPES.STRING: {
      return <InputField {...fieldProps} />;
    }
    case VARIABLE_TYPES.BOOLEAN: {
      return <CheckboxField id={`checkbox-${name}`} checkboxLabel={fieldProps?.label} {...fieldProps} />;
    }
    case VARIABLE_TYPES.DATE: {
      return <DatePickerField fullWidth {...fieldProps} inputReadOnly={true} />;
    }
    case VARIABLE_TYPES.ENUM: {
      return <ReactSelectField options={options} {...fieldProps} />;
    }
    default: {
      return null;
    }
  }
}
