import { useFormOptions, useUSStates, useCanadianProvinces } from '../../../hooks';
import { useCommercialAutoCoverageTypes, useVehicleWeightClasses } from '.';
import { useVehicleTypes } from '../../configurationVehicleTypes/hooks';

export default function useVehicleFormOptions() {
  const { options: stateOptions } = useUSStates();
  const { options: canadianProvincesOptions } = useCanadianProvinces();
  const { options: vehicleTypeOptions, loading: loadingVehicleTypeOptions } = useVehicleTypes();
  const { options: coverageTypeOptions, loading: loadingCoverageTypeOptions } = useCommercialAutoCoverageTypes();
  const { options: vehicleWeightClassOptions, loading: loadingVehicleWeightClassOptions } = useVehicleWeightClasses();

  const formOptions = useFormOptions({
    options: [
      { name: 'state', options: stateOptions },
      { name: 'canadianProvinces', options: canadianProvincesOptions },
      { name: 'allStates', options: [...stateOptions, ...canadianProvincesOptions] },
      { name: 'coverageType', options: coverageTypeOptions, loading: loadingCoverageTypeOptions },
      { name: 'vehicleType', options: vehicleTypeOptions, loading: loadingVehicleTypeOptions },
      { name: 'vehicleWeightClass', options: vehicleWeightClassOptions, loading: loadingVehicleWeightClassOptions }
    ]
  });

  return { formOptions };
}
