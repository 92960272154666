import React from 'react';
import { DatePickerField, InputField, ReactSelectField } from '@oforce/global-components';
import { FormSection } from '../../../components';

export default function Details({ formOptions }) {
  return (
    <FormSection title="Document Details">
      <InputField required label="Name" name="name" placeholder="Enter a document name" />
      <DatePickerField
        required
        fullWidth
        name="effectiveDate"
        label="Effective Date"
        format="MM/DD/YYYY"
        allowClear={false}
        inputReadOnly={true}
        messageAfterProps={{ icon: ['far', 'info-circle'] }}
      />
      <DatePickerField fullWidth name="expirationDate" label="Expiration Date" format="MM/DD/YYYY" allowClear={true} />
      <ReactSelectField
        required
        label="Type"
        name="type"
        options={formOptions?.documentType.options}
        placeholder="Select..."
      />
    </FormSection>
  );
}
