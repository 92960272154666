import React from 'react';
import { Helmet } from 'react-helmet';
import { Switch, Route, useRouteMatch, Redirect, useHistory } from 'react-router-dom';
import { TabBar, TabLink, TabPage } from '../../components/tabs';
import { PageHeader, PageTitle, PageBody } from '../../components/page-components';
import { useActiveAccount } from '../../shared/activeAccount';
import { HideFromRolesAndTypes, RouteHiddenFromRolesAndTypes } from '../../shared/activeAccount/components';

import { CoverageCreate, Coverages } from './components';
import { PendingApprovalsTable } from './components/pendingApproval';
import { Button } from '@oforce/global-components';

export default function CoveragesIndex() {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const {
    ROLES: { OBSERVER },
    TYPES: { CLAIMS_ADJUSTER }
  } = useActiveAccount();

  return (
    <Switch>
      <Redirect exact from={`${path}`} to={`${path}/overview`} />
      <Route path={`${path}/overview`}>
        <Helmet>
          <title>Coverages • IC Insure</title>
        </Helmet>
        <PageHeader withTabs>
          <PageTitle>Coverages</PageTitle>
          <TabBar>
            <TabLink to={`${url}/overview`} icon={['far', 'info-square']}>
              Overview
            </TabLink>
            <TabLink to={`${url}/pending-approvals`} icon={['far', 'ballot-check']}>
              Pending Approvals
            </TabLink>
          </TabBar>
        </PageHeader>
        <PageBody>
          <TabPage
            title=""
            buttons={
              <HideFromRolesAndTypes roles={[OBSERVER]} types={[CLAIMS_ADJUSTER]}>
                <Button
                  color="info"
                  icon={['far', 'plus']}
                  className="ml-2"
                  onClick={() => history.push(`${path}/new`)}
                >
                  Create
                </Button>
              </HideFromRolesAndTypes>
            }
          >
            <Coverages />
          </TabPage>
        </PageBody>
      </Route>

      <RouteHiddenFromRolesAndTypes types={[CLAIMS_ADJUSTER]} roles={[OBSERVER]} path={`${path}/pending-approvals`}>
        <Helmet>
          <title>Coverages • IC Insure</title>
        </Helmet>
        <PageHeader withTabs>
          <PageTitle>Coverages</PageTitle>
          <TabBar>
            <TabLink to={`${url}/overview`} icon={['far', 'info-square']}>
              Overview
            </TabLink>
            <TabLink to={`${url}/pending-approvals`} icon={['far', 'ballot-check']}>
              Pending Approvals
            </TabLink>
          </TabBar>
        </PageHeader>
        <PageBody>
          <PendingApprovalsTable />
        </PageBody>
      </RouteHiddenFromRolesAndTypes>

      <RouteHiddenFromRolesAndTypes types={[CLAIMS_ADJUSTER]} roles={[OBSERVER]} path={`${path}/new`}>
        <Helmet>
          <title>Create Coverage • IC Insure</title>
        </Helmet>
        <PageHeader>
          <PageTitle>Coverage</PageTitle>
        </PageHeader>
        <PageBody>
          <CoverageCreate />
        </PageBody>
      </RouteHiddenFromRolesAndTypes>
    </Switch>
  );
}
