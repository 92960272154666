import React, { useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Formik } from 'formik';

import { BoxContainer, BoxItemTitle } from '../../../components';

import { RequiredFieldsForm } from '../../requiredFields/components';
import { useNormalizeRequiredFields } from '../../requiredFields/hooks';
import { useActiveAccount } from '../../../shared/activeAccount';

export const GET_PRODUCT_REQUIRED_VEHICLE_FIELDS = gql`
  query GetProductRequiredVehicleFields($id: ID!) {
    product(id: $id) {
      id
      requiredVehicleFields
    }
  }
`;

const UPDATE_PRODUCT_REQUIRED_VEHICLE_FIELDS = gql`
  mutation updateProductRequiredVehicleFields($id: ID!, $requiredVehicleFields: [String]!) {
    updateProductRequiredVehicleFields(id: $id, requiredVehicleFields: $requiredVehicleFields) {
      id
      requiredVehicleFields
    }
  }
`;

export default function VehicleRequiredFields({
  productId,
  requireStateOfRegistration,
  roleBelowCollaborator,
  typeBelowBroker,
  onError = () => {}
}) {
  const {
    roleIsAtLeast,
    ROLES: { COLLABORATOR }
  } = useActiveAccount();
  const [success, setSuccess] = useState(false);

  const { data, loading, error } = useQuery(GET_PRODUCT_REQUIRED_VEHICLE_FIELDS, {
    variables: { id: productId }
  });

  const [update, { loading: updating, error: updateError }] = useMutation(UPDATE_PRODUCT_REQUIRED_VEHICLE_FIELDS, {
    onCompleted: () => {
      setSuccess(true);
      setTimeout(() => setSuccess(false), 3000);
    },
    onError,
    refetchQueries: [
      {
        query: GET_PRODUCT_REQUIRED_VEHICLE_FIELDS,
        variables: { id: productId }
      }
    ]
  });

  const normalizedVehicleFields = useNormalizeRequiredFields({
    fields: data?.product?.requiredVehicleFields,
    requireStateOfRegistration
  });

  const initialValues = {
    requiredFields: normalizedVehicleFields
  };

  const handleSubmit = values => {
    update({
      variables: {
        id: productId,
        requiredVehicleFields: values?.requiredFields
          ?.filter(field => field.required || field.alwaysRequired)
          ?.map(field => field.name)
      }
    });
  };

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={handleSubmit}>
      {formik => (
        <BoxContainer className="p-3 mb-4">
          <BoxItemTitle
            title="Create Vehicle"
            subtitle="If enabled, these fields will be required to create a new Vehicle."
          />
          <RequiredFieldsForm
            formik={formik}
            loading={loading}
            updating={updating}
            error={error || updateError}
            success={success}
            showSubmitButton={roleIsAtLeast(COLLABORATOR)}
            disableAllFields={roleBelowCollaborator || typeBelowBroker}
          />
        </BoxContainer>
      )}
    </Formik>
  );
}
