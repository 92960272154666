import * as yup from 'yup';
import moment from 'moment';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { GET_EXPOSURE_ELECTION_COVERAGE_HISTORY } from '../../coverage/hooks/useElectionHistoryCoverage';
import { GET_EXPOSURE_ELECTION_ACTION_PERMISSIONS } from '../../../components/quickActions/useQuickActionPermissions';

export const TERMINATE_EXPOSURE = gql`
  mutation TerminateExposure($electionId: ID!, $terminationDate: Date!, $note: String) {
    terminateExposure(exposureElectionId: $electionId, terminationDate: $terminationDate, note: $note) {
      id
      terminationDate
      status
    }
  }
`;

const TERMINATE_FRAGMENT = gql`
  fragment ExposureTerminate on ExposureElection {
    id
    terminationDate
    status
  }
`;

export default function useExposureTerminate(election, toggle) {
  const initialTerminationDate = election?.terminationDate;

  const [terminateExposure, { data, loading, error, reset }] = useMutation(TERMINATE_EXPOSURE, {
    onCompleted: toggle,
    onError: () => {},
    refetchQueries: [
      { query: GET_EXPOSURE_ELECTION_COVERAGE_HISTORY, variables: { id: election.id } },
      { query: GET_EXPOSURE_ELECTION_ACTION_PERMISSIONS, variables: { id: election?.id } }
    ],
    update(cache, { data: { terminateExposure } }) {
      cache.writeFragment({
        id: `ExposureElection:${terminateExposure.id}`,
        fragment: TERMINATE_FRAGMENT,
        data: { ...terminateExposure }
      });
    }
  });

  const handleSubmit = ({ terminationDate, note }) => {
    terminateExposure({
      variables: {
        electionId: election.id,
        terminationDate: moment(terminationDate).format('YYYY-MM-DD'),
        note
      }
    });
  };

  const initialValues = {
    terminationDate: initialTerminationDate
      ? moment(initialTerminationDate).format('MM/DD/YYYY')
      : moment().format('MM/DD/YYYY'),
    note: ''
  };

  const validationSchema = yup.object({
    terminationDate: yup.date(),
    note: yup.string().max(255, 'Must be 255 characters or less')
  });

  return {
    initialValues,
    validationSchema,
    handleSubmit,
    loading,
    data,
    error,
    reset
  };
}
