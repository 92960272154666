import { gql } from '@apollo/client';
import { EXPOSURE_ELECTION_FIELDS } from './fragments';

export const GET_EXPOSURE_ELECTION = gql`
  query exposureElection($id: ID!) {
    getExposureElection(id: $id) {
      id
      contractType
      insured {
        id
        email
      }
      vehicle {
        unitNumber
      }
      pricingOption {
        id
        label
        rate
        rateMode
        factoredField
        isPercentageFactor
      }
      masterElection {
        id
        insured {
          id
          firstName
          middleName
          lastName
          businessName
        }
      }
      compliance {
        inCompliance
        rejected
        violations {
          field
          id
          insertedAt
          reason
          source
          status
          type
          updatedAt
        }
      }
      insuranceType {
        id
        name
        type
        requireVehicle
      }
      client {
        id
        name
      }
      addOns {
        id
        label
        name
      }
      policy {
        id
        name
        terminationDateBackdatingMinimum
        terminationDateBackdatingLimit
        startDateBackdatingMinimum
        startDateBackdatingLimit
        pricingRateType
        apiOnlyExposureUpdates
        archived
        expirationDate
        enableCustomStartDates
        enableCustomTerminationDates
      }
      coverageHistory {
        coverageStartDate
        coverageEndDate
        addOnLabels
        factorLabel
      }
      ...exposureElectionFields
    }
  }
  ${EXPOSURE_ELECTION_FIELDS}
`;
