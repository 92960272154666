import { debounce } from 'lodash';
import { gql, useMutation } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';

const alphaNumericOnly = /^[A-Z0-9]+$/;

const VALIDATE_VIN = gql`
  mutation ValidateVin($vin: String!) {
    vehicleInfo: validateVin(vin: $vin) {
      vin
      vehicleYear
      vehicleMake
      vehicleModel
      vehicleWeight
      vehicleType
      vehicleTypeId
    }
  }
`;

export default function useVinValidationStep({ formik, formOptions, setDisabledVehicleFields }) {
  // memoized vinValue from formik state
  const vinValue = useMemo(() => formik?.values?.vin, [formik]);
  const [error, setError] = useState();

  const doesOptionExist = (formOptions, vehicleInfo) => {
    return formOptions?.vehicleType?.options?.find(
      opt => opt.label.toLowerCase() === vehicleInfo?.vehicleType.toLowerCase()
    );
  };

  // backend mutation
  const [validateVinMutation, { data, loading, error: validationError }] = useMutation(VALIDATE_VIN, {
    onCompleted: ({ vehicleInfo }) => {
      let optionExists = doesOptionExist(formOptions, vehicleInfo);

      if (!optionExists) {
        formOptions?.vehicleType?.options.push({ value: vehicleInfo?.vehicleTypeId, label: vehicleInfo?.vehicleType });
      }

      !!vehicleInfo?.vehicleYear && formik?.setFieldValue('vehicleYear', vehicleInfo?.vehicleYear);
      !!vehicleInfo?.vehicleMake && formik?.setFieldValue('vehicleMake', vehicleInfo?.vehicleMake);
      !!vehicleInfo?.vehicleModel && formik?.setFieldValue('vehicleModel', vehicleInfo?.vehicleModel);
      !!vehicleInfo?.vehicleWeight && formik?.setFieldValue('vehicleWeight', vehicleInfo?.vehicleWeight);
      !!vehicleInfo?.vehicleType &&
        formik?.setFieldValue(
          'vehicleType',
          formOptions?.vehicleType?.options?.find(
            opt => opt.label.toLowerCase() === vehicleInfo?.vehicleType.toLowerCase()
          )
        );
      setDisabledVehicleFields({
        vehicleYear: vehicleInfo?.vehicleYear ? true : false,
        vehicleMake: vehicleInfo?.vehicleMake ? true : false,
        vehicleModel: vehicleInfo?.vehicleModel ? true : false,
        vehicleWeight: vehicleInfo?.vehicleWeight ? true : false,
        vehicleType: vehicleInfo?.vehicleType ? true : false
      });
    },
    onError: () => {}
  });

  // set local display error based on mutation error
  useEffect(() => {
    if (!!validationError) {
      if (validationError?.message === 'http_error') {
        setError({ message: 'VIN validation service failed. Manually input vehicle information.' });
      }
      setError(validationError);
    } else {
      setError(null);
    }
  }, [validationError]);

  // memoized function that calls mutation with correct vinValue
  const validateVin = useCallback(() => {
    validateVinMutation({ variables: { vin: vinValue } });
  }, [vinValue, validateVinMutation]);

  // memoized and debounced validate handler function
  const handleValidate = useMemo(() => debounce(validateVin, 250), [validateVin]);

  // call debounce validate function if vinValue is correct length
  useEffect(() => {
    if (vinValue?.length === 17) {
      handleValidate(vinValue);
    }
  }, [vinValue, handleValidate]);

  // handleChange function for vin input
  const handleVinChange = e => {
    const vin = e.target.value.toUpperCase();
    if (vin?.length === 0) formik?.setFieldTouched('vin', false);
    if (vin?.length === 0 || (vin?.length <= 17 && alphaNumericOnly.test(vin))) {
      formik?.setFieldValue('vin', vin);
    }
  };

  return {
    data,
    loading,
    error,
    handleVinChange
  };
}
