import React from 'react';
import { Formik } from 'formik';
import { Modal } from '@oforce/global-components';

import { GraphQLErrors } from '../../../components';

import { FeeForm } from '.';
import { useCreateFeeForm } from '../hooks';

export default function CreateFee({ isOpen, toggle, product }) {
  const { initialValues, validationSchema, handleSubmit, loading, error, reset } = useCreateFeeForm({
    product,
    onCompleted: toggle
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {formik => (
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          title="Create Fee"
          onSubmit={formik.handleSubmit}
          submitButtonDisabled={formik.isSubmitting}
          submitButtonProps={{ color: 'info', loadingText: 'Saving...' }}
          submitButtonText="Create"
          loading={loading}
          onOpened={() => {
            reset();
            formik.handleReset();
          }}
        >
          <GraphQLErrors error={error} />
          <FeeForm product={product} formik={formik} />
        </Modal>
      )}
    </Formik>
  );
}
