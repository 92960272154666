import React from 'react';
import moment from 'moment';

import { BoxContainer, LabelValueGroup } from '../../../components';
import { normalizeInsuredFullNameLastFirst } from '../utils';
import { ContactInfoCell } from '../../../components/tables';
import styled from 'styled-components';
import Address from '../../../components/Address';
import { castCountry } from '../../../hooks/forms/useCountries';

const BoxHeaderWrapper = styled.div`
  border-bottom: ${props => `1px solid ${props.theme.gray300}`};
`;

export default function InsuredInfo({ insured }) {
  const dateOfBirth = insured?.dateOfBirth ? moment(insured?.dateOfBirth, 'YYYY-MM-DD').format('MM-DD-YYYY') : '';

  return (
    <BoxContainer className="px-3 pt-2 pb-3 mb-4">
      <BoxHeaderWrapper className="d-flex flex-row mt-2 justify-content-between mb-3">
        <div className="d-flex flex-column align-items-start">
          <span className="fs-xl">{normalizeInsuredFullNameLastFirst(insured)}</span>
          <div>
            <ContactInfoCell email={insured?.email} mobilePhone={insured?.mobilePhone} />
          </div>
        </div>
      </BoxHeaderWrapper>
      <LabelValueGroup
        className="py-1"
        pad={false}
        label="SSN:"
        value={insured?.ssnLastFour ? `XXX-XX-${insured?.ssnLastFour}` : ''}
      />
      <LabelValueGroup className="py-1" pad={false} label="DOB:" value={dateOfBirth} />
      <LabelValueGroup className="py-1" pad={false} label="Age:" value={insured?.age} />
      <LabelValueGroup
        className="py-1"
        pad={false}
        label="Driver's License/CDL Number"
        value={insured?.driversLicenseNumber}
      />
      <LabelValueGroup
        className="py-1"
        pad={false}
        label="Driver's License State"
        value={insured?.driversLicenseState}
      />
      <LabelValueGroup
        className="py-1"
        pad={false}
        label="Business Name:"
        value={insured?.businessName || insured?.businessName2}
      />
      <LabelValueGroup className="py-1" pad={false} label="Address:" value={<Address insured={insured} />} />
      <LabelValueGroup className="py-1" pad={false} label="Country:" value={castCountry(insured?.country)} />
      <LabelValueGroup className="py-1" pad={false} label="Dispatch Location" value={insured?.dispatchLocation} />
    </BoxContainer>
  );
}
