import React from 'react';
import { Form } from 'formik';
import {
  InputCurrencyField,
  InputField,
  InputPercentageField,
  ReactCreatableSelectField,
  ReactSelectField,
  SwitchField
} from '@oforce/global-components';

import { useFeeRateModes, usePolicyFeeOptions } from '../../../components/sharedComponents/pricing';
import { usePolicyPricingOptions } from '../../../components/sharedComponents/pricing';

import { useFeeAppliesToOptions } from '../hooks';

import { useAccountTags } from '../../account/hooks';
import { useActiveAccount } from '../../../shared/activeAccount';
import { Render } from '../../../components';
import { useProrateSettings } from '../../pricing/hooks';

export default function FeeForm({ policy, formik, fee }) {
  const { id: activeAccountId } = useActiveAccount();

  const { feeRateModeOptions: rateModeOptions } = useFeeRateModes();
  const { feeAppliesToOptions } = useFeeAppliesToOptions();
  const { tagOptions } = useAccountTags({ accountId: activeAccountId });
  const { selectOptions: options, loading } = usePolicyPricingOptions({ policy });
  const {
    PRORATE_SETTINGS: { NO_PRORATE }
  } = useProrateSettings();
  const { selectOptions: feeOptions, feeOptionsLoading } = usePolicyFeeOptions({ policyId: policy?.id, fee });

  const prorateFee = formik?.values?.prorated;
  const hasInvoiced = !!policy?.latestInvoice?.id;

  return (
    <Form>
      <InputField required label="Name" name="name" placeholder="Enter a fee name" />
      <ReactSelectField required label="Rate Mode" options={rateModeOptions} name="rateMode" />

      {formik?.values?.rateMode?.value !== 'FLAT' ? (
        <InputPercentageField name="rate" label="Rate" options={{ decimalLimit: 4 }} required />
      ) : (
        <InputCurrencyField options={{ integerLimit: 5 }} name="rate" label="Rate" required />
      )}
      <Render if={policy?.prorateSetting !== NO_PRORATE}>
        <SwitchField
          id="enable-prorate-fee-switch"
          className="mb-3"
          label="Prorate"
          name="prorated"
          size="sm"
          disabled={hasInvoiced}
          switchLabel={prorateFee ? 'Enabled' : 'Disabled'}
          messageAfter={hasInvoiced && 'This setting cannot be changed if this policy has already been invoiced.'}
          messageAfterProps={{ icon: ['far', 'info-circle'] }}
        />
      </Render>
      {formik?.values?.rateMode?.value === 'PREMIUM_PLUS' && (
        <ReactSelectField
          required
          label="Premium PLUS Fees"
          name="multipliers"
          options={feeOptions}
          placeholder="Select..."
          isMulti
          isLoading={feeOptionsLoading}
          disabled={feeOptionsLoading}
          clearable
        />
      )}
      <ReactSelectField
        required
        className="mt-3"
        label="Applies to"
        options={feeAppliesToOptions}
        name="appliesToAllPricingOptions"
      />
      {!formik?.values?.appliesToAllPricingOptions?.value && (
        <ReactSelectField
          required
          label="Selected Pricing Options"
          name="policyPricings"
          options={options}
          placeholder="Select..."
          isMulti
          isLoading={loading}
          disabled={loading}
          clearable
        />
      )}
      <ReactCreatableSelectField
        options={tagOptions}
        isMulti
        name="tags"
        label="Tags"
        onCreateOption={tag => {
          const tags = formik.values.tags;
          tags.push({ value: tag, label: tag });
          formik.setFieldValue('tags', tags);
        }}
        placeholder="Choose or Create Tags..."
      />
    </Form>
  );
}
