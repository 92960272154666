import React from 'react';

import { TrLoader, TrPlaceholder } from '@oforce/global-components';
import { BoxContainer, RenderData, Table, TrGraphQLErrors } from '../../../../components';
import SubcontractorsRow from './SubcontractorsRow';

export default function SubcontractorsTable({ election, coverages, loading, error }) {
  return (
    <BoxContainer className="px-3 py-2 mb-5">
      <Table>
        <thead>
          <tr>
            <th>Coverage</th>
            <th>Insured Name</th>
            <th>Coverage Dates</th>
            <th>Coverage Option</th>
            <th>Premium</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <RenderData
            data={coverages}
            loading={loading}
            error={error}
            loadingComponent={<TrLoader colSpan={5} />}
            errorComponent={<TrGraphQLErrors colSpan={5} error={error} />}
            placeholderComponent={<TrPlaceholder colSpan={5}>None</TrPlaceholder>}
          >
            {coverages =>
              coverages.map(coverage => (
                <SubcontractorsRow key={coverage?.id} election={election} subcontractor={coverage} />
              ))
            }
          </RenderData>
        </tbody>
      </Table>
    </BoxContainer>
  );
}
