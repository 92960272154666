import React from 'react';
import { InputField, ReactSelectField } from '@oforce/global-components';
import { Form } from 'formik';
import { GraphQLErrors } from '..';

import { useAccountWebhookEvents } from '../../views/account/hooks';
export default function AccountWebhookForm({ focusRef, error }) {
  const { accountWebhookEventOptions, loading: gettingEventTypes } = useAccountWebhookEvents();

  return (
    <Form>
      <GraphQLErrors error={error} />
      <InputField name="url" label="Url" required innerRef={focusRef} />
      <InputField name="authToken" optional label="Authentication Token" />
      <ReactSelectField
        required
        label="Events"
        name="events"
        isMulti
        isClearable
        options={accountWebhookEventOptions}
        isLoading={gettingEventTypes}
        disabled={gettingEventTypes}
      />
    </Form>
  );
}
