import React from 'react';
import { Steps } from 'react-step-builder';
import { useHistory, useParams } from 'react-router-dom';

import { MultiStepForm } from '../../../components';

import {
  PolicyDocumentDetailsFields,
  PolicyDocumentPricingFields,
  PolicyDocumentRequiredStatesFields
} from '../components';
import { usePolicyDocumentFormOptions, usePolicyDocumentFormSteps, usePolicyDocumentFormSubmit } from '../hooks';

export default function UploadUpdate({ policyId, documents }) {
  const history = useHistory();
  const { documentId } = useParams();
  const doc = documents?.find(doc => doc.id === documentId);
  const isCreateMode = !doc;

  const { formOptions } = usePolicyDocumentFormOptions({ policyId });
  const { formSteps } = usePolicyDocumentFormSteps({ doc, formOptions, isCreateMode });
  const { formSubmit, loading, error } = usePolicyDocumentFormSubmit({
    policyId,
    policyDocumentId: documentId,
    isCreateMode,
    onCompleted: () => history.push(`/policies/${policyId}/documents`)
  });

  return (
    <MultiStepForm
      formOptions={formOptions}
      formSteps={formSteps}
      formSubmit={formSubmit}
      formProps={{ isCreateMode }}
      title={`${isCreateMode ? 'Upload' : 'Edit'} Document`}
      onCancel={() => history.push(`/policies/${policyId}/documents`)}
      submitButtonText={isCreateMode ? 'Upload' : 'Save Changes'}
      submitButtonProps={{ loading, loadingText: isCreateMode ? 'Uploading...' : 'Saving...' }}
      showButtonsOnBottom
      error={error}
    >
      {formProps => (
        <Steps>
          <PolicyDocumentDetailsFields {...formProps} />
          <PolicyDocumentPricingFields {...formProps} />
          <PolicyDocumentRequiredStatesFields {...formProps} />
        </Steps>
      )}
    </MultiStepForm>
  );
}
