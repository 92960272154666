import * as yup from 'yup';
import { excludeStatesOption, includeStatesOption } from '../components/PolicyDocumentRequiredStatesFields';
import moment from 'moment';

export default function usePolicyDocumentFormSteps({ doc, formOptions, isCreateMode }) {
  const DOCUMENT_VALIDATION = {
    SUPPORTED_FORMATS: ['application/pdf'],
    MAX_FILE_SIZE: 50000000
  };

  const formSteps = [
    {
      name: 'Document Details',
      initialValues: {
        document: null,
        name: doc?.name || '',
        populateOnPending: !doc ? true : doc?.populateOnPending,
        type:
          formOptions?.documentType?.options.find(option => doc?.type === option.value) ||
          formOptions?.documentType?.options[0],
        effectiveDate: moment(doc?.effectiveDate) || moment(),
        expirationDate: !!doc?.expirationDate ? moment(doc?.expirationDate) : ''
      },
      validationSchema: yup.object().shape({
        document: yup.mixed().when('$document', (_value, schema) =>
          !isCreateMode
            ? schema
            : schema
                .required('Required')
                .test('fileType', 'File must be a PDF', values =>
                  values
                    ?.map(value => DOCUMENT_VALIDATION.SUPPORTED_FORMATS.includes(value?.type))
                    .reduce((acc, valid) => acc && valid, true)
                )
                .test('fileSize', 'File must be less than 50 MB', values =>
                  values
                    ?.map(value => value && value.size < DOCUMENT_VALIDATION.MAX_FILE_SIZE)
                    .reduce((acc, valid) => acc && valid, true)
                )
        ),
        name: yup.string().required('Required'),
        populateOnPending: yup.boolean().required('Required'),
        type: yup.object({
          value: yup.string().required('Required'),
          label: yup.string().required('Required')
        }),
        effectiveDate: yup.date().required('Required'),
        expirationDate: yup.date()
      })
    },
    {
      name: 'Pricing Options',
      initialValues: {
        appliesToAllPricingOptions:
          formOptions?.documentAppliesTo?.options.find(option => option.value === doc?.appliesToAllPricingOptions) ||
          formOptions?.documentAppliesTo?.options[0],
        pricingOptions:
          formOptions?.policyPricing?.options?.filter(option => doc?.pricingOptionIds.includes(option.value)) || []
      },
      validationSchema: yup.object().shape({
        appliesToAllPricingOptions: yup.object({ label: yup.string(), value: yup.boolean() }).required('Required'),
        pricingOptions: yup
          .array()
          .when('appliesToAllPricingOptions', ({ value }, _schema) =>
            value ? yup.array().nullable() : yup.array().of(yup.object()).required('Required')
          )
      })
    },
    {
      name: 'Required States',
      initialValues: {
        enableRequiredStates: !!doc?.stateSource,
        stateSource: formOptions?.stateSource?.options?.find(option => option.value === doc?.stateSource),
        selectedStates: formOptions?.states?.options?.filter(state => doc?.appliesToStates?.includes(state.value)),
        excludedStates: formOptions?.states?.options?.filter(state => doc?.excludeFromStates?.includes(state.value)),
        stateMode: doc?.excludeFromStates?.length > 0 ? excludeStatesOption : includeStatesOption
      },
      validationSchema: yup.object().shape({
        enableRequiredStates: yup.boolean(),
        stateSource: yup
          .object()
          .nullable()
          .when('enableRequiredStates', {
            is: enableRequiredStates => enableRequiredStates === true,
            then: schema => schema.required('Required')
          }),
        selectedStates: yup
          .array()
          .nullable()
          .when(['enableRequiredStates', 'stateMode'], {
            is: (enableRequiredStates, stateMode) => enableRequiredStates === true && stateMode.value === 'include',
            then: schema => schema.required('Required')
          }),
        excludedStates: yup
          .array()
          .nullable()
          .when(['enableRequiredStates', 'stateMode'], {
            is: (enableRequiredStates, stateMode) => enableRequiredStates === true && stateMode.value === 'exclude',
            then: schema => schema.required('Required')
          })
      })
    }
  ];

  return { formSteps };
}
