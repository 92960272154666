import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldLabel, RangePicker, Button, Render } from '@oforce/global-components';

import { FilterDropdown, FilterLabel } from '../../../components';
import useClients from '../../clients/hooks/useClients';
import { useOwnedProducts } from '../../../views/products/hooks';
import { useFormOptions } from '../../../hooks';
import { useActiveAccount } from '../../../shared/activeAccount';

export default function InvoicesTableFilters({
  setVariable,
  variables,
  clearFilters,
  filtersAreDefault,
  setDateFilter
}) {

  const {
    hideFromRoles,
    ROLES: { EXPOSURE_MANAGER }
  } = useActiveAccount();

  const hideVoidedFilter = hideFromRoles([EXPOSURE_MANAGER]);

  const { options: productOptions } = useOwnedProducts();
  const { options: clientOptions } = useClients();

  const formOptions = useFormOptions({
    options: [
      { name: 'product', options: [{ label: 'All Products', value: '' }, ...productOptions] },
      { name: 'client', options: [{ label: 'All Clients', value: '' }, ...clientOptions] }
    ]
  });

  const orderOptions = [
    { label: 'Invoice ID', value: 'INVOICE_ID' },
    { label: 'Client Name', value: 'CLIENT_NAME' },
    { label: 'External ID', value: 'EXTERNAL_ID' },
    { label: 'Service Date Range', value: 'SERVICE_START_DATE' },
    { label: 'Invoice Date', value: 'INVOICE_DATE' }
  ];

  const [resetDefault, setResetDefault] = useState(false);

  const handleClear = () => {
    clearFilters();
    setResetDefault(true);
    setTimeout(() => setResetDefault(false), 1000);
  };

  return (
    <div className="mb-3">
      <DateRangeFilter onChange={setDateFilter} />
      <div className="d-flex align-items-center">
        <FilterLabel>Sort by</FilterLabel>
        <FilterDropdown
          id="ordering"
          className="mr-3"
          headerText="Sort ascending..."
          icon={['far', 'sort-alpha-down']}
          options={orderOptions}
          defaultOption={orderOptions[0]}
          setFilter={option => {setVariable(Object.assign(variables, {ordering: option.value}))}}
        />

        <span className="font-weight-500 mr-2">Filters</span>
        <FilterDropdown
          id="invoices-table-product-filter"
          defaultOption={
            formOptions?.product?.options?.find(x => x.value === variables?.productId) ||
            formOptions?.product?.options[0]
          }
          setFilter={option => setVariable('productId', option?.value)}
          options={formOptions?.product?.options}
          resetDefault={resetDefault}
          icon={['far', 'box']}
        />
        <FilterDropdown
          id="invoices-table-client-filter"
          defaultOption={
            formOptions?.client?.options?.find(x => x.value === variables?.clientId) || formOptions?.client?.options[0]
          }
          setFilter={option => setVariable('clientId', option?.value)}
          options={formOptions?.client?.options}
          resetDefault={resetDefault}
          icon={['far', 'house-user']}
        />
        <Button
          color={variables?.archived ? 'info' : 'secondary'}
          size="sm"
          className="mr-2 rounded-pill"
          onClick={() => setVariable('archived', !variables?.archived)}
        >
          Archived
        </Button>
        <Render if={!hideVoidedFilter}>
          <Button
            color={variables?.voided ? 'info' : 'secondary'}
            size="sm"
            className="rounded-pill"
            onClick={() => setVariable('voided', !variables?.voided)}
          >
            Voided
          </Button>
        </Render>
        <Render if={!filtersAreDefault}>
          <span className="text-gray-500 px-2 mt-1">|</span>
          <Button color="link" className="text-muted mt-1" size="sm" onClick={handleClear}>
            Clear Filters
          </Button>
        </Render>
      </div>
    </div>
  );
}

const DateRangeFilter = ({ onChange }) => (
  <div className="mb-3">
    <FieldLabel name="Filter By Date" />
    <RangePicker
      style={{ minHeight: '38px' }}
      format="MM/DD/YYYY"
      onChange={onChange}
      separator={<FontAwesomeIcon icon={['far', 'long-arrow-alt-right']} className="text-gray-500 ml-0" />}
    />
  </div>
);
