import React, { useRef } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';

import { CreateUpdateModal } from '../../../components/modals';
import { useFormOptions } from '../../../hooks/forms';

import { RetailCommissionForm } from '../../retailCommission/components';

import { useProductPricingOptions } from '../../../components/sharedComponents/pricing';
import { convertDecimalToPercentage, convertPercentageToDecimal } from '../../../utils';
import { useRetailCommissionRateModes } from '../../retailCommission/hooks';

import { gql, useMutation } from '@apollo/client';
import { GET_PRODUCT_PRICINGS } from '../../productPricing/hooks/useProductPricings';

const CREATE_PRODUCT_RETAIL_COMMISSION = gql`
  mutation CreateProductRetailCommission($input: CreateProductRetailCommissionInput!) {
    createProductRetailCommission(input: $input) {
      id
      retailCommissions {
        id
        name
        rateMode
        rate
        appliesToAllPricing
        pricingOptionIds
      }
    }
  }
`;

const UPDATE_PRODUCT_RETAIL_COMMISSION = gql`
  mutation EditProductRetailCommission($input: EditProductRetailCommissionInput!) {
    editProductRetailCommission(input: $input) {
      id
      retailCommissions {
        id
        name
        rateMode
        rate
        appliesToAllPricing
        pricingOptionIds
      }
    }
  }
`;

export default function CreateUpdate({ productId, isOpen, toggle, retailCommission, onError = () => {} }) {
  const [create, { loading: creating, error: createError }] = useMutation(CREATE_PRODUCT_RETAIL_COMMISSION, {
    onCompleted: toggle,
    onError,
    refetchQueries: [{ query: GET_PRODUCT_PRICINGS, variables: { id: productId } }]
  });

  const [edit, { loading: updating, error: updateError }] = useMutation(UPDATE_PRODUCT_RETAIL_COMMISSION, {
    onCompleted: toggle,
    onError,
    refetchQueries: [{ query: GET_PRODUCT_PRICINGS, variables: { id: productId } }]
  });

  const isCreateMode = !retailCommission;
  const focusRef = useRef(null);

  const commissionAppliesToOptions = [
    {
      label: 'All Pricing Options',
      value: true
    },
    {
      label: 'Selected Pricing Options',
      value: false
    }
  ];

  const { options: rateModeOptions, loading: loadingRateModeOptions } = useRetailCommissionRateModes();
  const { selectOptions: productPricingOptions, loading: loadingProductPricingOptions } = useProductPricingOptions({
    productId
  });

  const formOptions = useFormOptions({
    options: [
      { name: 'rateMode', options: rateModeOptions, loading: loadingRateModeOptions },
      { name: 'pricing', options: productPricingOptions, loading: loadingProductPricingOptions },
      { name: 'commissionAppliesTo', options: commissionAppliesToOptions }
    ]
  });

  const initialValues = {
    name: retailCommission?.name || '',
    rate: !!retailCommission?.rate ? convertDecimalToPercentage(retailCommission?.rate) : '',
    rateMode: rateModeOptions?.find(({ value }) => value === 'PERCENTAGE'),
    appliesToAllPricing:
      commissionAppliesToOptions?.find(option => option.value === retailCommission?.appliesToAllPricing) ||
      commissionAppliesToOptions[1],
    pricings: productPricingOptions?.filter(option => retailCommission?.pricingOptionIds.includes(option.value)) || []
  };

  const handleSubmit = values => {
    const input = {
      name: values?.name,
      rate: convertPercentageToDecimal(values?.rate),
      rateMode: values?.rateMode?.value,
      appliesToAllProductPricing: values?.appliesToAllPricing?.value,
      productPricingIds: values?.pricings?.map(pricing => pricing?.value)
    };

    if (isCreateMode) {
      create({
        variables: {
          input: { productId, ...input }
        }
      });
    } else {
      edit({
        variables: {
          input: { retailCommissionId: retailCommission?.id, ...input }
        }
      });
    }
  };

  const validationSchema = yup.object({
    name: yup.string().required('Required'),
    rate: yup.string().required('Required'),
    rateMode: yup.object({
      value: yup.string().required('Required'),
      label: yup.string().required('Required')
    }),
    appliesToAllPricing: yup.object({ label: yup.string(), value: yup.boolean() }).required('Required'),
    pricings: yup.array().when('appliesToAllPricing', {
      is: val => val?.value === true,
      then: schema => schema.nullable(),
      otherwise: schema => schema.of(yup.object({ label: yup.string(), value: yup.string() })).required('Required')
    })
  });

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {formik => (
        <CreateUpdateModal
          isOpen={isOpen}
          toggle={toggle}
          entityName="Retail Commission"
          creating={creating}
          updating={updating}
          isCreateMode={isCreateMode}
          onSubmit={formik.handleSubmit}
          onOpened={() => {
            focusRef?.current?.focus();
            formik.handleReset();
          }}
        >
          <RetailCommissionForm
            formik={formik}
            error={createError || updateError}
            focusRef={focusRef}
            formOptions={formOptions}
          />
        </CreateUpdateModal>
      )}
    </Formik>
  );
}
