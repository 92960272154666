import React from 'react';
import { Button } from 'reactstrap';
import { BoxContainer, FormSection, Render, TabPage } from '../../../../components';
import { Formik } from 'formik';
import { ReactAsyncSelectField, ReactSelectField } from '@oforce/global-components';
import useVehicleFormOptions from './hooks/useVehicleFormOptions';
import useLazyVehicle from './hooks/useLazyVehicle';

export default function SelectOrCreateVehicleStep({ onCancel, onSubmit, title }) {
  const { loading, formOptions, loadOptions } = useVehicleFormOptions({ pageSize: 5 });
  const { getVehicle } = useLazyVehicle({ onCompleted: onSubmit });

  const initialValues = {
    createOrSelectVehicle: formOptions?.createOrSelectVehicle?.options[0],
    vehicle: null
  };

  const handleSubmit = async ({ vehicle }) => {
    if (vehicle?.value?.id) {
      getVehicle(vehicle?.value?.id);
    } else {
      onSubmit();
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
      {formik => (
        <TabPage
          title={title}
          showButtonsOnTop={true}
          showButtonsOnBottom={true}
          buttons={
            <>
              <Button className="ml-2" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                className="ml-2"
                color="info"
                iconafter={['far', 'long-arrow-right']}
                onClick={formik?.handleSubmit}
              >
                Next
              </Button>
            </>
          }
        >
          <BoxContainer className="p-3">
            <FormSection title="Create or Select Vehicle">
              <ReactSelectField
                isClearable={true}
                required={true}
                label="Create or Select Vehicle"
                options={formOptions?.createOrSelectVehicle?.options}
                name="createOrSelectVehicle"
                onChange={() => {
                  formik.setFieldValue('vehicle', null);
                }}
              />
              <Render if={formik?.values?.createOrSelectVehicle?.value === 'select'}>
                <ReactAsyncSelectField
                  id="vehicle"
                  name="vehicle"
                  label="Vehicle"
                  placeholder="Choose or search for vehicle..."
                  cacheOptions
                  loadOptions={loadOptions}
                  defaultOptions={formOptions?.vehicle?.options}
                  defaultValue={formOptions?.vehicle?.options[0]}
                  loading={loading}
                  required
                  isClearable
                />
              </Render>
            </FormSection>
          </BoxContainer>
        </TabPage>
      )}
    </Formik>
  );
}
