import { gql, useMutation } from '@apollo/client';

const DISABLE_PRODUCT_DOCUMENT = gql`
  mutation addExclusions($input: AddPolicyPricingDocumentExclusionsInput!) {
    addPolicyPricingDocumentExclusions(input: $input) {
      id
      documents {
        id
        appliesToAllPricingOptions
        filename
        url
        pricingOptionIds
        excludeFromAllPolicyPricingOptions
        type
        name
        source
        stateSource
        appliesToStates
      }
    }
  }
`;

export default function useDisableProductDocument({
  onCompleted = () => {},
  onError = () => {},
  policyId,
  productDocumentId
}) {
  const [addDocumentExclution, { loading, error }] = useMutation(DISABLE_PRODUCT_DOCUMENT, {
    onError,
    onCompleted
  });

  return {
    changeDocumentExclution: excludeFromAllPolicyPricingOptions => {
      addDocumentExclution({
        variables: {
          input: {
            policyId,
            productDocumentId,
            excludeFromAllPolicyPricingOptions
          }
        }
      });
    },
    loading,
    error
  };
}
