import React, { useState } from 'react';
import { Render, useToggle, Button } from '@oforce/global-components';

import { BoxContainer, FormSection, TabPage } from '../../../../../components';
import CoveragesPlaceholder from './CoveragesPlaceholder';
import CoverageBoxItem from './CoverageBoxItem';
import CoverageForm from './CoverageForm';
import { useExistingExposurePolicyIds, useSearchablePolicyOptions } from '../hooks';

export default function CoveragesSelectStep({
  onSubmit,
  onCancel,
  insured,
  vehicle,
  onBack,
  policyInsuranceTypes,
  selectedCoverages = [],
  title = 'Create Coverages',
  policy
}) {
  const [addingCoverage, toggleAddingCoverage] = useToggle(false);
  const [coverageBeingUpdated, setUpdatingCoverage] = useState(null);
  const [coverages, setCoverages] = useState(selectedCoverages);

  const startUpdatingCoverage = coverageToBeUpdated => setUpdatingCoverage(coverageToBeUpdated);
  const stopUpdatingCoverage = () => setUpdatingCoverage(null);

  const addCoverage = values => {
    const newCoverages = [...coverages, values];
    setCoverages(newCoverages);
    toggleAddingCoverage();
  };

  const removeCoverage = policyId => {
    const newCoverages = coverages.filter(({ policy: { id } }) => id !== policyId);
    setCoverages(newCoverages);
  };

  const updateCoverage = (policyId, values) => {
    const filteredCoverages = coverages.filter(({ policy: { id } }) => id !== policyId);
    setCoverages([...filteredCoverages, values]);
    stopUpdatingCoverage();
  };

  const selectedPolicyIds = coverages?.map(coverage => coverage?.policy?.id);

  const { defaultOptions } = useSearchablePolicyOptions({ variables: { exposureType: policyInsuranceTypes } });
  const { existingExposurePolicyIds } = useExistingExposurePolicyIds({ insured, vehicle });

  const availablePolicyIds = defaultOptions
    .filter(policy => !existingExposurePolicyIds?.includes(policy.id))
    .filter(policy => !selectedPolicyIds?.includes(policy.id));

  return (
    <>
      <TabPage
        title={title}
        showButtonsOnTop={true}
        showButtonsOnBottom={true}
        buttons={
          <>
            <Button className="ml-2" onClick={onCancel}>
              Cancel
            </Button>
            <Render if={!!onBack}>
              <Button className="ml-2" color="info" outline icon={['far', 'long-arrow-left']} onClick={onBack}>
                Back
              </Button>
            </Render>
            <Button
              className="ml-2"
              color="info"
              iconAfter={['far', 'long-arrow-right']}
              disabled={coverages?.length === 0}
              onClick={() => onSubmit(coverages)}
            >
              Next
            </Button>
          </>
        }
      >
        <BoxContainer className="p-3">
          <Render if={!addingCoverage && coverageBeingUpdated === null}>
            <FormSection title="Add Coverage">
              <Render if={coverages.length === 0}>
                <CoveragesPlaceholder />
              </Render>
              {coverages.map(coverage => (
                <CoverageBoxItem
                  key={coverage?.policy?.id}
                  coverage={coverage}
                  onRemove={() => removeCoverage(coverage.policy.id)}
                  onUpdate={() => startUpdatingCoverage(coverage)}
                />
              ))}
              <Render if={availablePolicyIds?.length === 0 && !policy}>No Available Policies</Render>
              <Render if={availablePolicyIds?.length > 0 || !!policy}>
                <Button color="success" onClick={toggleAddingCoverage} type="button">
                  Add Coverage
                </Button>
              </Render>
            </FormSection>
          </Render>
          <Render if={addingCoverage}>
            <CoverageForm
              policyInsuranceTypes={policyInsuranceTypes}
              onSubmit={addCoverage}
              onCancel={toggleAddingCoverage}
              selectedPolicyIds={selectedPolicyIds}
              insured={insured}
              vehicle={vehicle}
              policy={policy}
            />
          </Render>
          <Render if={coverageBeingUpdated !== null}>
            <CoverageForm
              policyInsuranceTypes={policyInsuranceTypes}
              coverage={coverageBeingUpdated}
              onSubmit={values => updateCoverage(coverageBeingUpdated.policy.id, values)}
              onCancel={stopUpdatingCoverage}
              selectedPolicyIds={selectedPolicyIds}
              insured={insured}
              vehicle={vehicle}
              policy={policy}
            />
          </Render>
        </BoxContainer>
      </TabPage>
    </>
  );
}
