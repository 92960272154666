import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { backendNameToLabel } from '../../../utils/formatting';

const GET_RATE_MODES = gql`
  query rateModes {
    allRateModes
  }
`;

export default function useRateModes() {
  const { loading, error, data } = useQuery(GET_RATE_MODES);

  return {
    loading,
    error,
    rateModes: data?.allRateModes,
    rateModeOptions: data?.allRateModes?.map(mode => ({
      label: backendNameToLabel(mode),
      value: mode
    }))
  };
}
