import React from 'react';
import { Button } from '@oforce/global-components';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { LabelValueGroup, CreatedUpdatedDates, BoxContainer, TabPage } from '../../../components';

export default function CompanyDetails({ company }) {
  const history = useHistory();
  const { url } = useRouteMatch();

  return (
    <TabPage
      buttons={
        <Button color="info" onClick={() => history.push(`${url}/update`)} icon={['far', 'pen']}>
          Edit
        </Button>
      }
    >
      <BoxContainer className="px-3 pt-2 pb-3">
        <LabelValueGroup label="Company Name" value={company?.name} />
        <LabelValueGroup label="Address" value={company?.address} />
        <LabelValueGroup label="City" value={company?.city} />
        <LabelValueGroup label="State" value={company?.state} />
        <LabelValueGroup label="Zip Code" value={company?.zip} />
        <LabelValueGroup label="Phone number" value={company?.phone} />
        <LabelValueGroup label="Email" value={company?.email} />
        <hr className="mt-0" />
        <CreatedUpdatedDates created={company.created} updated={company.updated} />
      </BoxContainer>
    </TabPage>
  );
}
