import { gql, useMutation } from '@apollo/client';

import { GET_INSURANCE_TYPES } from '../../../views/configurationInsuranceTypes';

const ADD_STATE_FEE = gql`
  mutation AddStateFee(
    $insuranceTypeId: ID!
    $fee: Decimal!
    $activeMode: StateFeeRateMode!
    $state: ClientProductState!
    $vehicleTypeIds: [ID]!
  ) {
    createStateFee(
      insuranceTypeId: $insuranceTypeId
      fee: $fee
      activeMode: $activeMode
      state: $state
      vehicleTypeIds: $vehicleTypeIds
    ) {
      id
      fee
      state
      activeMode
      vehicleTypes {
        id
        name
      }
      insuranceTypeId
    }
  }
`;

export default function useAddStateFee({ onCompleted = () => {}, onError = () => {} }) {
  const [addFee, { loading, error, reset }] = useMutation(ADD_STATE_FEE, {
    refetchQueries: [{ query: GET_INSURANCE_TYPES }],
    onCompleted,
    onError
  });

  return {
    addStateFee: ({ fee, state, activeMode, vehicleTypeIds, insuranceTypeId }) =>
      addFee({
        variables: { fee, state, activeMode, vehicleTypeIds, insuranceTypeId }
      }),
    loading,
    error,
    reset
  };
}
