import React from 'react';
import { ReactSelectField } from '@oforce/global-components';

import { usePricingOptionAddOns } from '../../../views/policyInsureds/hooks';

export default function AddOnSelectFormik({ pricingOptionId, disabled }) {
  const { optionalAddOns: addOns = [] } = usePricingOptionAddOns({ pricingOptionId });
  const addOnOptions = addOns.reduce((acc, addOn) => [...acc, { value: addOn.id, label: addOn.label }], []);

  return (
    <ReactSelectField
      options={addOnOptions}
      name="addOns"
      label="Optional Add-On(s)"
      labelProps={{ size: 'xs' }}
      placeholder="Select an add-on..."
      isMulti
      isDisabled={disabled}
    />
  );
}
