import React from 'react';

export default function TrPlaceholder({ colSpan, children, ...rest }) {
  return (
    <tr
      className="bg-gray-100 border-bottom border-width-2 border-gray-300"
      {...rest}
    >
      <td className="text-center text-muted" colSpan={colSpan}>
        {children}
      </td>
    </tr>
  );
}
