import { gql, useMutation } from '@apollo/client';
import { useDownloadFile } from '../../../hooks';

const RENEWAL_AUDIT_REPORT = gql`
  mutation renewalAuditReport($policyId: ID!) {
    renewalAuditReport(policyId: $policyId) {
      filename
      url
    }
  }
`;

export default function useRenewalAuditReport() {
  const { downloadFile } = useDownloadFile({});

  const [renewalAuditReport, { loading: loadingReport, error: errorReport }] = useMutation(RENEWAL_AUDIT_REPORT, {
    onCompleted: data => {
      downloadFile(data?.renewalAuditReport);
    },
    onError: () => {}
  });

  const handleSubmit = data => {
    renewalAuditReport({ variables: { policyId: data?.policyId } });
  };

  return {
    handleSubmit,
    loadingReport,
    errorReport
  };
}
