import { Button, useToggle } from '@oforce/global-components';
import { TabPage } from '../../../../components';
import useCoverageSubcontracts from '../../hooks/useCoverageSubcontracts';
import SubContractedCoverageForm from './SubContractedCoverageForm';
import SubcontractorsTable from './SubcontractorsTable';

export default function SubContractedCoverages({ election }) {
  const [showModal, toggleModal] = useToggle();
  const { subcontractedCoverages, loading, error } = useCoverageSubcontracts({ exposureElectionId: election?.id });
  return (
    <TabPage
      title="Subcontracted Coverages"
      buttons={
        <Button color="info" outline className="ml-2" icon={['far', 'plus']} onClick={toggleModal}>
          Add
        </Button>
      }
    >
      <SubcontractorsTable election={election} coverages={subcontractedCoverages} loading={loading} error={error} />
      {showModal && <SubContractedCoverageForm election={election} isOpen={showModal} toggle={toggleModal} />}
    </TabPage>
  );
}
