import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';

import { PRODUCT_CORE_FIELDS, GET_PRODUCT } from '../../../views/product';
import { useHistory } from 'react-router-dom';
import { useProrateSettings } from '../../pricing/hooks';

const CREATE_PRODUCT = gql`
  mutation create($input: CreateProductInput!) {
    createProduct(input: $input) {
      ...ProductCoreFields
      masterCertificate {
        startNumber
        endNumber
        suffix
        prefix
        nextNumber
      }
      companies {
        id
        companyId
        name
        role
      }
      insuranceType {
        id
        name
        type
      }
    }
  }
  ${PRODUCT_CORE_FIELDS}
`;

export const UPDATE_PRODUCT = gql`
  mutation update($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      ...ProductCoreFields
      masterCertificate {
        startNumber
        endNumber
        suffix
        prefix
        nextNumber
      }
      companies {
        id
        companyId
        name
        role
      }
      insuranceType {
        id
        name
        type
      }
    }
  }
  ${PRODUCT_CORE_FIELDS}
`;

export default function useProductFormSubmit({ product, isCreateMode, onError = () => {} }) {
  const history = useHistory();

  const {
    PRORATE_SETTINGS: { NO_PRORATE }
  } = useProrateSettings();

  const [update, { loading: updating, error: updateError }] = useMutation(UPDATE_PRODUCT, {
    onCompleted: ({ updateProduct }) => history.push(`/products/${updateProduct?.id}`),
    onError,
    refetchQueries: [{ query: GET_PRODUCT, variables: { id: product?.id } }]
  });

  const [create, { loading: creating, error: createError }] = useMutation(CREATE_PRODUCT, {
    onCompleted: ({ createProduct }) => history.push(`/products/${createProduct.id}`),
    onError
  });

  const formSubmit = useCallback(
    ({
      insuranceType,
      pricingRateType,
      insuranceCarrier,
      retailBroker,
      pricingIsExternallyFactored,
      prorateSetting,
      enableProrating,
      invoicingSchedule,
      enableMasterCertificateNumbers,
      invoiceDueDay,
      billingRule,
      snapshotCustomDay,
      customData,
      ratingZoneSource,
      ...values
    }) => {
      const masterCertificateFields = {
        masterCertificateStartNumber: enableMasterCertificateNumbers
          ? Number(values?.masterCertificateStartNumber)
          : null,
        masterCertificateEndNumber: enableMasterCertificateNumbers ? Number(values?.masterCertificateEndNumber) : null,
        masterCertificatePrefix: enableMasterCertificateNumbers ? values?.masterCertificatePrefix : '',
        masterCertificateSuffix: enableMasterCertificateNumbers ? values?.masterCertificateSuffix : ''
      };

      const sharedInputFields = {
        pricingRateType: pricingRateType?.value,
        invoicingSchedule: invoicingSchedule?.value,
        invoiceGenerationDay: !!values?.invoiceGenerationDay ? Number(values?.invoiceGenerationDay) : null,
        companies: [
          {
            role: 'INSURANCE_CARRIER',
            companyId: insuranceCarrier?.value || null
          },
          {
            role: 'RETAIL_BROKER',
            companyId: retailBroker?.value || null
          }
        ],
        prorateSetting: enableProrating ? prorateSetting?.value : NO_PRORATE,
        invoiceDueDay: invoiceDueDay === '' ? null : parseInt(invoiceDueDay),
        billingRule: billingRule?.value,
        snapshotCustomDay: snapshotCustomDay === '' ? null : parseInt(snapshotCustomDay),
        customData: JSON.stringify(customData),
        ...masterCertificateFields
      };

      if (isCreateMode) {
        create({
          variables: {
            input: {
              ...values,
              insuranceTypeId: insuranceType?.value || null,
              ratingZoneSource: ratingZoneSource.value || null,
              ...sharedInputFields
            }
          }
        });
      } else {
        update({
          variables: {
            input: {
              ...values,
              id: product?.id,
              ratingZoneSource: ratingZoneSource.value || null,
              ...sharedInputFields
            }
          }
        });
      }
    },
    [isCreateMode, create, update, product, NO_PRORATE]
  );

  return { formSubmit, loading: creating || updating, error: createError || updateError };
}
