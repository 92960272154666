import React from 'react';
import * as yup from 'yup';
import { Steps } from 'react-step-builder';
import { useHistory } from 'react-router-dom';
import { InputField, ReactSelectField } from '@oforce/global-components';

import { MultiStepForm } from '../../../components';
import { useActiveAccount } from '../../../shared/activeAccount';

import { useCreateReportFormOptions, useCreateReportFormSubmit } from '../hooks';

export default function ReportCreate({ refetchReports }) {
  const history = useHistory();
  const { id } = useActiveAccount();

  const { formOptions } = useCreateReportFormOptions();

  const formSteps = [
    {
      name: 'Report Details',
      initialValues: { accountId: id, name: '', report: formOptions?.report?.options[0] },
      validationSchema: yup.object().shape({
        name: yup.string().required('Required'),
        report: yup.object().required('Required')
      })
    }
  ];

  const { formSubmit, loading, error } = useCreateReportFormSubmit({
    onCompleted: () => {
      history.push(`/reports`);
      refetchReports();
    }
  });

  return (
    <>
      <MultiStepForm
        formOptions={formOptions}
        formSteps={formSteps}
        formSubmit={formSubmit}
        showProgressBar={false}
        title="Create Report"
        submitButtonText="Create"
        onCancel={() => history.push(`/reports`)}
        submitButtonProps={{ loading, loadingText: 'Creating...' }}
        loading={loading}
        error={error}
      >
        {formProps => (
          <Steps>
            <>
              <InputField innerRef={formProps?.focusRef} name="name" label="Name" placeholder="Your report name..." />
              <ReactSelectField
                name="report"
                label="Report"
                options={formProps?.formOptions?.report?.options}
                isLoading={formProps?.formOptions?.report?.loading}
              />
            </>
          </Steps>
        )}
      </MultiStepForm>
    </>
  );
}
