export const ACCOUNT_ROLES = {
  ADMIN: 'ADMIN',
  OWNER: 'OWNER',
  COLLABORATOR: 'COLLABORATOR',
  POLICY_MANAGER: 'POLICY_MANAGER',
  EXPOSURE_MANAGER: 'EXPOSURE_MANAGER',
  OBSERVER: 'OBSERVER'
};

export const ACCOUNT_TYPES = {
  CARRIER: 'CARRIER',
  BROKER: 'BROKER',
  CLIENT: 'CLIENT',
  CLAIMS_ADJUSTER: 'CLAIMS_ADJUSTER',
  BROKER_MANAGER: 'BROKER_MANAGER',
  CLIENT_MANAGER: 'CLIENT_MANAGER'
};

export const ROLE_POWERS = {
  ADMIN: 6,
  OWNER: 5,
  COLLABORATOR: 4,
  POLICY_MANAGER: 3,
  EXPOSURE_MANAGER: 2,
  OBSERVER: 1
};

export const TYPE_POWERS = {
  CARRIER: 5,
  BROKER: 4,
  CLIENT: 3
};

export const FLAGGED_FEATURES = {
  MASTER_SUB_RELATIONSHIP: 'MASTER_SUB_RELATIONSHIP',
  CLIENT_DOCUMENTS: 'CLIENT_DOCUMENTS',
  PREMIUM_PLUS: 'PREMIUM_PLUS',
  ROLLOVER_PRICING: 'ROLLOVER_PRICING'
};
