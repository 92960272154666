import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';

export const GET_POLICY_OPTIONS = gql`
  query policyOptions($id: ID!) {
    policy(id: $id) {
      id
      pricingOptions {
        id
        rateMode
        factoredField
      }
    }
  }
`;

export default function usePolicyOptions({ policyId }) {
  const { data, loading, error } = useQuery(GET_POLICY_OPTIONS, {
    variables: { id: policyId }
  });

  return { pricingOptions: data?.policy?.pricingOptions, loading, error };
}
