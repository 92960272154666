export const NON_TRUCKING_LIABILITY = 'NON_TRUCKING_LIABILITY';
export const AUTO_LIABILITY = 'AUTO_LIABILITY';
export const PASSENGER_ACCIDENT = 'PASSENGER_ACCIDENT';
export const PHYSICAL_DAMAGE = 'PHYSICAL_DAMAGE';
export const OCCUPATIONAL_ACCIDENT = 'OCCUPATIONAL_ACCIDENT';
export const INSEL = 'INSEL';
export const GENERAL_LIABILITY = 'GENERAL_LIABILITY';
export const WHILE_UNDER_DISPATCH = 'WHILE_UNDER_DISPATCH';
export const CARGO = 'CARGO';
export const TRUCKING_LIABILITY = 'TRUCKING_LIABILITY';
export const OCCUPATIONAL_ACCIDENT_WITH_VEHICLE = 'OCCUPATIONAL_ACCIDENT_WITH_VEHICLE';

export const insuredInsuranceTypes = [OCCUPATIONAL_ACCIDENT, OCCUPATIONAL_ACCIDENT_WITH_VEHICLE];
export const vehicleInsuranceTypes = [
  NON_TRUCKING_LIABILITY,
  AUTO_LIABILITY,
  PASSENGER_ACCIDENT,
  PHYSICAL_DAMAGE,
  INSEL,
  GENERAL_LIABILITY,
  WHILE_UNDER_DISPATCH,
  CARGO,
  TRUCKING_LIABILITY
];

const insuranceTypes = [
  {
    type: NON_TRUCKING_LIABILITY,
    name: 'Non-Trucking Liability',
    acronym: 'NTL'
  },
  {
    type: AUTO_LIABILITY,
    name: 'Auto Liability',
    acronym: 'AL'
  },
  {
    type: PASSENGER_ACCIDENT,
    name: 'Passenger Accident',
    acronym: 'PA'
  },
  {
    type: PHYSICAL_DAMAGE,
    name: 'Vehicle Physical Damage',
    acronym: 'VPD'
  },
  {
    type: INSEL,
    name: 'Inservice Liability ',
    acronym: 'INSEL'
  },
  {
    type: GENERAL_LIABILITY,
    name: 'General Liability',
    acronym: 'GL'
  },
  {
    type: WHILE_UNDER_DISPATCH,
    name: 'While Under Dispatch',
    acronym: 'WUD'
  },
  {
    type: CARGO,
    name: 'Cargo',
    acronym: 'MTC'
  },
  {
    type: TRUCKING_LIABILITY,
    name: 'Trucking Liability',
    acronym: 'TL'
  },
  {
    type: OCCUPATIONAL_ACCIDENT,
    name: 'Occupational Accident',
    acronym: 'OA'
  },
  {
    type: OCCUPATIONAL_ACCIDENT_WITH_VEHICLE,
    name: 'Occupational Accident with Vehicle',
    acronym: 'OAWV'
  }
];

export const insuranceTypeAcronym = insuranceType => {
  const data = insuranceTypes.find(({ type }) => type === insuranceType);
  return data?.acronym;
};

export const insuranceTypeName = insuranceType => {
  const data = insuranceTypes.find(({ type }) => type === insuranceType);
  return data?.name;
};
