import { gql, useQuery } from '@apollo/client';
import { backendNameToLabel } from '../../../utils/formatting';

export const CONTRACT_TYPE_OPTIONS = gql`
  query ContractTypes {
    contractTypes
  }
`;

export default function useContractTypeOptions() {
  const { loading, error, data } = useQuery(CONTRACT_TYPE_OPTIONS, {
    onCompleted: () => {},
    onError: () => {}
  });

  return {
    contractTypeOptions:
      data?.contractTypes
        ?.filter(field => field !== 'SUB')
        .map(field => ({
          label: backendNameToLabel(field),
          value: field
        })) || [],
    loading,
    error
  };
}
