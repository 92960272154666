import { useRef, useEffect } from 'react';

export default function useFocusOnMount() {
  const ref = useRef();

  useEffect(() => {
    ref.current && ref.current.focus();
  }, [ref]);

  return ref;
}
