import { gql, useQuery } from '@apollo/client';

export const LIST_INSURED_CLIENTS = gql`
  query ListInsuredClients($insuredId: ID!) {
    clients: listInsuredClients(insuredId: $insuredId) {
      id
      name
    }
  }
`;

export default function useListInsuredClients({ insuredId }){
  const { data, loading, error } = useQuery(LIST_INSURED_CLIENTS, {
    variables: { insuredId }
  })

  let clients = [];
  if (data?.clients) clients = data?.clients;

  const clientOptions = clients?.reduce((acc, client) => {
    return [...acc, { label: client?.name, value: client?.id }];
  }, []);

  return { clients, clientOptions, loading, error };
}
