import moment from 'moment';
import * as yup from 'yup';

export default function useBulkDownloadDocumentsFormSteps(
  allInsuredIds,
  allValidInsuredIds,
  allDocumentInsuredsMapping,
  allValidDocumentRelationships,
  documentIds
) {
  const formSteps = [
    {
      name: 'Select Date',
      initialValues: {
        activeOn: moment().startOf('day'),
        allInsuredIds: allInsuredIds || [],
        allValidInsuredIds: allValidInsuredIds || [],
        allDocumentInsuredsMapping: allDocumentInsuredsMapping || {},
        documentInsuredsMapping: allDocumentInsuredsMapping || {},
        allValidDocumentRelationships: allValidDocumentRelationships || {}
      },
      validationSchema: yup.object().shape({
        activeOn: yup.date().required('Required')
      })
    },
    {
      name: 'Select Documents',
      initialValues: { documentIds: documentIds || [], insuredIdsFromChosenDocuments: allValidInsuredIds || [] },
      validationSchema: yup.object().shape({
        documentIds: yup.array().min(1).required('Required')
      })
    },
    {
      name: 'Select Insureds',
      initialValues: { insuredIds: allValidInsuredIds || [] },
      validationSchema: yup.object().shape({
        insuredIds: yup.array().min(1).required('Required')
      })
    }
  ];

  return { formSteps };
}
