import { gql, useQuery } from '@apollo/client';

export const LIST_BULK_EMAIL_DOCUMENT_RELATIONSHIPS = gql`
  query BulkEmailDocumentRelationships($policyId: ID, $clientId: ID, $activeOn: Date) {
    bulkEmailDocumentRelationships(policyId: $policyId, clientId: $clientId, activeOn: $activeOn) {
      id
      insuredId
      insured {
        id
        email
      }
      document {
        id
        name
        filename
      }
    }
  }
`;

export default function useBulkEmailDocumentRelationships({
  policyId,
  clientId,
  activeOn,
  onCompleted = () => {},
  onError = () => {}
}) {
  const { loading, error, data } = useQuery(LIST_BULK_EMAIL_DOCUMENT_RELATIONSHIPS, {
    fetchPolicy: 'cache-and-network',
    variables: { policyId, clientId, activeOn: activeOn === '' ? null : activeOn },
    skip: !(policyId || clientId),
    onCompleted,
    onError
  });

  return {
    loading,
    error,
    data
  };
}
