import moment from 'moment';
import pluralize from 'pluralize';
import React, { useState } from 'react';
import { DateRangeField, DatePickerField } from '@oforce/global-components';

import { Render } from '../../../components';
import { INTERVAL_TYPES } from '../hooks/useGenerateInvoiceFormOptions';

export default function GenerateInvoiceFormDates({ formik, disabled }) {
  const automateInvoiceGeneration = formik?.values?.automateInvoiceGeneration;
  const invoiceGenerationAlreadyScheduled = formik?.values?.invoiceGenerationAlreadyScheduled;
  const intervalType = formik?.values?.intervalType?.value;
  const effectiveDate = formik?.values?.effectiveDate;
  const dateRange = formik?.values?.dateRange;
  const weeks = dateRange?.length > 1 ? -moment(dateRange[0]).diff(moment(dateRange[1]), 'weeks') : 0;
  const [selectedStartDate, setSelectedStartDate] = useState('');

  const {
    WEEKLY: { WEEK_RANGE, SPECIFIC_WEEK },
    MONTHLY: { MONTH_RANGE, SPECIFIC_MONTH }
  } = INTERVAL_TYPES;

  const MONTH_DATE_FORMAT = 'MMMM YYYY';
  const WEEK_DATE_FORMAT = 'MM-DD-YYYY';

  const datePickerFieldProps = {
    label: { SPECIFIC_MONTH: 'Month' }[intervalType],
    picker: { SPECIFIC_MONTH: 'month' }[intervalType],
    format: { SPECIFIC_MONTH: MONTH_DATE_FORMAT }[intervalType]
  };
  const isDatePicker = intervalType === SPECIFIC_MONTH;

  const weekRangeDisabledDate = current => {
    const currentDayOfWeek = current.clone().day();
    const effectiveDayOfWeek = moment(effectiveDate).day();
    const incorrectDayOfWeek = currentDayOfWeek !== effectiveDayOfWeek;
    const beforeEffectiveDate = moment(current) < moment(effectiveDate);

    if (selectedStartDate) {
      const beforeStartDate = current < selectedStartDate;
      const offsetDay = moment(effectiveDate).subtract(1, 'days').day();
      const sixDayOffset = currentDayOfWeek !== offsetDay;

      return sixDayOffset || beforeStartDate;
    } else {
      return incorrectDayOfWeek || beforeEffectiveDate;
    }
  };

  const specificWeekDisabledDate = current => {
    const currentDayOfWeek = current.clone().day();
    const effectiveDayOfWeek = moment(effectiveDate).day();
    const incorrectDayOfWeek = currentDayOfWeek !== effectiveDayOfWeek;
    const beforeEffectiveDate = moment(current) < moment(effectiveDate);

    if (selectedStartDate) {
      const oneWeekFromStartDate = moment(selectedStartDate).add('6', 'days');

      return moment(current).diff(oneWeekFromStartDate, 'days') !== 0;
    } else {
      return incorrectDayOfWeek || beforeEffectiveDate;
    }
  };

  const dateRangeFieldProps = {
    label: { MONTH_RANGE: 'Month Range', WEEK_RANGE: 'Week Range', SPECIFIC_WEEK: 'Week' }[intervalType],
    picker: { MONTH_RANGE: 'month', WEEK_RANGE: undefined, SPECIFIC_WEEK: undefined }[intervalType],
    format: { MONTH_RANGE: MONTH_DATE_FORMAT, WEEK_RANGE: WEEK_DATE_FORMAT, SPECIFIC_WEEK: WEEK_DATE_FORMAT }[
      intervalType
    ],
    onCalendarChange: dates => dates && dates?.length > 0 && setSelectedStartDate(dates[0]),
    onOpenChange: open => open && setSelectedStartDate(''),
    renderExtraFooter: () => {
      if (showWeeksMessage) {
        return <div>{pluralize('week', weeks, true)}</div>;
      } else {
        return null;
      }
    },
    disabledDate: {
      MONTH_RANGE: null,
      WEEK_RANGE: weekRangeDisabledDate,
      SPECIFIC_WEEK: specificWeekDisabledDate
    }[intervalType]
  };
  const isDateRange = intervalType === WEEK_RANGE || intervalType === MONTH_RANGE || intervalType === SPECIFIC_WEEK;
  const showWeeksMessage = intervalType === WEEK_RANGE && weeks > 0;

  const sharedProps = {
    fullWidth: true,
    allowClear: true,
    isDisabled: disabled,
    disabled
  };

  return (
    <>
      <Render if={isDatePicker}>
        <DatePickerField name="specificMonth" {...datePickerFieldProps} {...sharedProps} inputReadOnly={true} />
      </Render>

      <Render if={isDateRange}>
        <DateRangeField
          name="dateRange"
          {...dateRangeFieldProps}
          {...sharedProps}
          inputReadOnly={true}
          disabled={invoiceGenerationAlreadyScheduled || automateInvoiceGeneration}
          messageAfter={
            automateInvoiceGeneration && invoiceGenerationAlreadyScheduled
              ? "Automated invoices don't allow for custom service dates"
              : showWeeksMessage
              ? `${pluralize('week', weeks, true)} selected`
              : null
          }
          messageAfterProps={{ icon: showWeeksMessage ? undefined : ['far', 'info-circle'] }}
        />
      </Render>
    </>
  );
}
