import React from 'react';
import { useHistory } from 'react-router-dom';
import { TrLoader, TrPlaceholder } from '@oforce/global-components';
import { Table, Td, Tr } from '../../../components';
import { BoxContainer, RenderData, TrGraphQLErrors } from '../../../components';

export default function CompaniesTable({ companies, loading, error }) {
  const history = useHistory();

  return (
    <BoxContainer>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          <RenderData
            data={companies}
            loading={loading}
            error={error}
            loadingComponent={<TrLoader />}
            errorComponent={<TrGraphQLErrors error={error} />}
            placeholderComponent={<TrPlaceholder>No companies</TrPlaceholder>}
          >
            {companies =>
              companies?.map(company => (
                <Tr key={company.id} size="sm" onClick={() => history.push(`/companies/${company.id}`)}>
                  <Td>
                    <span className="font-weight-500">{company?.name}</span>
                  </Td>
                </Tr>
              ))
            }
          </RenderData>
        </tbody>
      </Table>
    </BoxContainer>
  );
}
