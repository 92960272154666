import * as yup from 'yup';

export default function useStateFeeValidationSchema() {
  const validationSchema = yup.object({
    state: yup.object().nullable().required('Required'),
    activeMode: yup.object().required('Required'),
    fee: yup.string().required('Required'),
    vehicleTypes: yup.array().of(yup.object()).nullable().required('Required')
  });

  return {
    validationSchema
  };
}
