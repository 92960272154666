import React from 'react';
import { SearchBy } from '../../../components';
import { useInsuredSearchByOptions } from '../hooks';

export default function InsuredsSearchBy({ onChange }) {
  const { insuredSearchByOptions, loading } = useInsuredSearchByOptions();
  const defaultSearchField = insuredSearchByOptions.find(option => option.value === 'LAST_NAME');

  const onFilterChange = incomingFilters => {
    const searchBy = incomingFilters.reduce((acc, { field: { value: searchField }, value: searchValue }) => {
      if (!!searchValue) return [...acc, { searchField, searchValue }];
      return acc;
    }, []);

    if (searchBy.length > 0) {
      onChange({ searchBy });
    } else {
      onChange({ searchBy: [] });
    }
  };

  if (loading) return null;

  return (
    <SearchBy
      label={'Search by'}
      onFilterChange={onFilterChange}
      name="insureds-search-by"
      fieldOptions={insuredSearchByOptions}
      defaultValue={defaultSearchField}
    />
  );
}
