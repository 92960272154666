import React from 'react';
import { Button } from 'reactstrap';
import { BoxContainer, FormSection, Render, TabPage } from '../../../../components';
import { Formik } from 'formik';
import { ReactAsyncSelectField, ReactSelectField } from '@oforce/global-components';
import { useInsuredFormOptions } from './hooks';
import useLazyInsured from './hooks/useLazyInsured';

export default function SelectOrCreateInsuredStep({ onCancel, onSubmit, title }) {
  const { loading, formOptions, loadOptions } = useInsuredFormOptions({ pageSize: 5 });
  const { getInsured } = useLazyInsured({ onCompleted: onSubmit });

  const initialValues = {
    createOrSelectInsured: formOptions?.createOrSelectInsured?.options[0],
    insured: null
  };

  const handleSubmit = async ({ insured }) => {
    if (insured?.value?.id) {
      getInsured(insured?.value?.id);
    } else {
      onSubmit();
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
      {formik => (
        <TabPage
          title={title}
          showButtonsOnTop={true}
          showButtonsOnBottom={true}
          buttons={
            <>
              <Button className="ml-2" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                className="ml-2"
                color="info"
                iconafter={['far', 'long-arrow-right']}
                onClick={formik?.handleSubmit}
              >
                Next
              </Button>
            </>
          }
        >
          <BoxContainer className="p-3">
            <FormSection title="Create or Select Insured">
              <ReactSelectField
                isClearable={true}
                required={true}
                label="Create or Select Insured"
                options={formOptions?.createOrSelectInsured?.options}
                name="createOrSelectInsured"
                onChange={() => {
                  formik.setFieldValue('insured', null);
                }}
              />
              <Render if={formik?.values?.createOrSelectInsured?.value === 'select'}>
                <ReactAsyncSelectField
                  id="insured"
                  name="insured"
                  label="Insured"
                  placeholder="Choose or search for insured..."
                  cacheOptions
                  loadOptions={loadOptions}
                  defaultOptions={formOptions?.insured?.options}
                  defaultValue={formOptions?.insured?.options[0]}
                  loading={loading}
                  required
                  isClearable
                />
              </Render>
            </FormSection>
          </BoxContainer>
        </TabPage>
      )}
    </Formik>
  );
}
