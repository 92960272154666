import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';

export const GET_OWNED_PRODUCTS = gql`
  query products {
    allProducts(filter: OWNED) {
      id
      name
    }
  }
`;

export default function useProducts({ emptyOption }) {
  const { data, loading, error } = useQuery(GET_OWNED_PRODUCTS);
  const startingOption = emptyOption ? [emptyOption] : [];

  const productOptions = data?.allProducts?.reduce(
    (acc, product) => [...acc, { label: product.name, value: product.id }],
    startingOption
  );

  return { productOptions, loading, error };
}
