import { useCompanies } from '../../../views/companies/hooks';
import { useUSStates, useFormOptions, useCanadianProvinces } from '../../../hooks';
import { useInvoicingScheduleOptions } from '../../../views/invoice/hooks';
import { useInsuranceTypes } from '../../../views/configurationInsuranceTypes/hooks';
import { usePricingRateTypes, useProrateSettings } from '../../../views/pricing/hooks';
import { BILLING_RULE_OPTIONS } from '../../product/utils';

import { usePolicyStatusOptions } from '../../../views/policyDetails/hooks';
import { useRatingZoneSources } from '../../../components/sharedComponents/hooks';

export default function usePolicyFormOptions() {
  const { options: pricingRateOptions, loading: loadingPricingRateOptions } = usePricingRateTypes();
  const { options: companiesOptions, loading: loadingCompaniesOptions } = useCompanies();
  const { options: insuranceTypeOptions, loading: loadingInsuranceTypeOptions } = useInsuranceTypes();
  const policyStatusOptions = usePolicyStatusOptions();
  const { options: usStateOptions } = useUSStates();
  const { options: canadianProvinceOptions } = useCanadianProvinces();
  const stateOptions = [...usStateOptions, ...canadianProvinceOptions];
  const { options: invoicingScheduleOptions } = useInvoicingScheduleOptions();
  const { options: ratingZoneSourceOptions } = useRatingZoneSources();
  const {
    options: prorateSettingsOptions,
    CATEGORIES: { WEEKLY, MONTHLY }
  } = useProrateSettings();

  const formOptions = useFormOptions({
    options: [
      { name: 'pricingRate', options: pricingRateOptions, loading: loadingPricingRateOptions },
      { name: 'companies', options: companiesOptions, loading: loadingCompaniesOptions },
      { name: 'prorateSettings', options: prorateSettingsOptions },
      { name: 'proratingWeekly', options: prorateSettingsOptions?.filter(x => x.category === WEEKLY) },
      { name: 'proratingMonthly', options: prorateSettingsOptions?.filter(x => x.category === MONTHLY) },
      { name: 'policyStatus', options: policyStatusOptions },
      { name: 'insuranceType', options: insuranceTypeOptions, loading: loadingInsuranceTypeOptions },
      { name: 'states', options: stateOptions },
      { name: 'invoicingSchedule', options: invoicingScheduleOptions },
      { name: 'billingRule', options: BILLING_RULE_OPTIONS },
      { name: 'ratingZoneSource', options: ratingZoneSourceOptions }
    ]
  });

  return { formOptions };
}
