import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useToggle } from '@oforce/global-components';
import { GlobalNavigation as GlobalNav } from '@oforce/global-navigation';

import { useCurrentUser } from '../../shared/currentUser';
import { backendNameToLabel } from '../../utils/formatting';
import { useActiveAccount } from '../../shared/activeAccount';

import { useGlobalNav } from './hooks';
import { AccountSwitcherModal } from './containers';

export default function GlobalNavigation() {
  const history = useHistory();
  const [showAccountSwitcher, toggleAccountSwitcher] = useToggle();

  const activeAccount = useActiveAccount();
  const { userAccounts, isAdmin, ...currentUser } = useCurrentUser();
  const { activeAccountRole } = currentUser;

  const roleName = isAdmin ? 'Admin' : backendNameToLabel(activeAccountRole);

  const { profileItems, menuItems, logout } = useGlobalNav();

  const accountOptions = useMemo(() => {
    const accounts = userAccounts?.map(x => x.account) || [];

    const options = accounts.map(({ id, name }) => {
      const isActiveAccount = id === activeAccount?.id;
      return {
        name,
        disabled: isActiveAccount,
        onClick: isActiveAccount
          ? () => {}
          : async () => {
              await activeAccount?.setActiveAccountId(id);
              history.push('/policies');
            },
        icon: ['far', isActiveAccount ? 'dot-circle' : 'circle']
      };
    });

    if (isAdmin) {
      // check if the active account is one of the user's accounts or not
      const isUserAccount = accounts?.find(x => x.id === activeAccount?.id);
      if (!isUserAccount) {
        // if the account is not one of the user's accounts, add it to the beginning of the list of accounts and disable it
        options.unshift({ name: activeAccount?.name, disabled: true, onClick: () => {}, icon: ['far', 'dot-circle'] });
      }
      // add select other account option for admins to access accounts that the user doesn't explicitly own
      options.unshift({ name: 'Select Other Account', onClick: toggleAccountSwitcher, icon: ['far', 'exchange-alt'] });
    }

    return options;
  }, [userAccounts, isAdmin, activeAccount, toggleAccountSwitcher, history]);

  return (
    <>
      <GlobalNav
        appName="IC Insure"
        userInfo={{ ...currentUser, items: profileItems, roleName }}
        environment={process.env.REACT_APP_OF_APP_ENV}
        menuItems={menuItems}
        isAuthenticated={true}
        client={{
          name: activeAccount?.loading ? 'Switching account...' : activeAccount?.name || 'No account selected',
          icon: activeAccount?.loading ? ['far', 'spinner-third'] : ['far', 'user'],
          iconProps: { spin: activeAccount?.loading },
          items: accountOptions
        }}
        logout={logout}
        login={() => {}}
      />
      <AccountSwitcherModal isOpen={showAccountSwitcher} toggle={toggleAccountSwitcher} />
    </>
  );
}
