const ALL_TABS = [
  {
    name: 'Configuration',
    mobileName: 'Config',
    pathname: '/configuration',
    icon: ['far', 'cog'],
    useRouter: true
  },
  {
    name: 'Pending Elections',
    mobileName: 'Pendings',
    pathname: '/pending-elections',
    icon: ['far', 'check'],
    useRouter: true
  },
  {
    name: 'Products',
    pathname: '/products',
    icon: ['far', 'box'],
    useRouter: true
  },
  {
    name: 'Companies',
    pathname: '/companies',
    icon: ['far', 'building'],
    useRouter: true
  },
  {
    name: 'Clients',
    pathname: '/clients',
    icon: ['fas', 'house-user'],
    useRouter: true
  },
  {
    name: 'Reports',
    mobileName: 'Reports',
    pathname: '/reports',
    icon: ['far', 'file-chart-line'],
    useRouter: true
  },
  {
    name: 'Policies',
    mobileName: 'Policies',
    pathname: '/policies',
    icon: ['far', 'file-contract'],
    useRouter: true
  },
  {
    name: 'Invoices',
    pathname: '/invoices',
    icon: ['far', 'file-invoice'],
    useRouter: true
  },
  {
    name: 'Transactions',
    pathname: '/transactions',
    icon: ['far', 'file-invoice-dollar'],
    useRouter: true
  },
  {
    name: 'Insureds',
    pathname: '/insureds',
    icon: ['far', 'users-class'],
    useRouter: true
  },
  { name: 'Coverages', pathname: '/coverages', icon: ['far', 'umbrella'], useRouter: true },
  {
    name: 'Vehicles',
    pathname: '/vehicles',
    icon: ['far', 'cars'],
    useRouter: true
  },
  {
    name: 'Notifications',
    pathname: '/notifications',
    icon: ['fas', 'envelope'],
    useRouter: true
  },
  {
    name: 'Account',
    pathname: '/account',
    icon: ['far', 'user-cog'],
    useRouter: true
  },
  {
    name: 'API Explorer',
    pathname: '/api-explorer',
    icon: ['far', 'mountain'],
    useRouter: true
  }
];

// Tabs by account type
const ADMIN_TAB_NAMES = ['Configuration', 'Pending Elections'];
const CARRIER_TAB_NAMES = ['Products', 'Companies'];
const BROKER_TAB_NAMES = ['Clients'];
const CLIENT_TAB_NAMES = [
  'Policies',
  'Invoices',
  'Transactions',
  'Insureds',
  'Coverages',
  'Vehicles',
  'Account',
  'Notifications',
  'Reports'
];
const API_EXPLORER_TAB_NAME = 'API Explorer';
const CLAIMS_ADJUSTER_TAB_NAMES = ['Policies', 'Insureds', 'Coverages', 'Vehicles', 'Account']

const ALL = 'ALL';
// Tabs by role in account
const tabNamesByRole = {
  ADMIN: ALL,
  OWNER: ALL,
  COLLABORATOR: ALL,
  POLICY_MANAGER: ALL,
  EXPOSURE_MANAGER: ALL,
  OBSERVER: ['Policies', 'Insureds']
};

export const GLOBAL_NAVIGATION_TABS = {
  OFORCE_ADMIN_TABS: ALL_TABS.filter(({ name }) => ADMIN_TAB_NAMES.includes(name)),
  CARRIER_TABS: ALL_TABS.filter(({ name }) => CARRIER_TAB_NAMES.includes(name)),
  BROKER_TABS: ALL_TABS.filter(({ name }) => BROKER_TAB_NAMES.includes(name)),
  CLIENT_TABS: ALL_TABS.filter(({ name }) => CLIENT_TAB_NAMES.includes(name)),
  CLAIMS_ADJUSTER_TABS: ALL_TABS.filter(({ name }) => CLAIMS_ADJUSTER_TAB_NAMES.includes(name)),

  filterByRole: (tabs, role) => {
    const tabNamesToInclude = tabNamesByRole[role];
    if (tabNamesToInclude === ALL) return tabs;
    return tabs.filter(({ name }) => tabNamesToInclude.includes(name));
  },

  API_EXPLORER_TAB: ALL_TABS.find(({ name }) => name === API_EXPLORER_TAB_NAME)
};
