import React from 'react';
import { Steps } from 'react-step-builder';
import { useHistory } from 'react-router-dom';

import { useCanadianProvinces, useFormOptions, useUSStates } from '../../hooks';
import { MultiStepForm } from '../../components';
import { validationSchema } from '../../views/company/model';

import { CompanyDetailsStep } from './components';
import { useFormSubmit } from './hooks';

export default function CompanyCreateUpdate({ company }) {
  const history = useHistory();
  const isCreateMode = !company;
  const companyId = company?.id;

  const { options: usStateOptions } = useUSStates();
  const { options: canadianProvinceOptions } = useCanadianProvinces();
  const stateOptions = [...usStateOptions, ...canadianProvinceOptions];
  const formOptions = useFormOptions({
    options: [{ name: 'state', options: stateOptions }]
  });

  const formSteps = [
    {
      name: 'Company Details',
      initialValues: {
        name: company?.name || '',
        address: company?.address || '',
        city: company?.city || '',
        state: formOptions?.state?.options?.find(opt => opt.value === company?.state) || null,
        zip: company?.zip || '',
        phone: company?.phone || '',
        email: company?.email || ''
      },
      validationSchema
    }
  ];

  const { formSubmit, creating, createError, updating, updateError } = useFormSubmit({ companyId, isCreateMode });

  return (
    <MultiStepForm
      formOptions={formOptions}
      formSteps={formSteps}
      formSubmit={formSubmit}
      formProps={{ companyId }}
      title={`${isCreateMode ? 'Create' : 'Edit'} Company`}
      onCancel={() => history.push(isCreateMode ? '/companies' : `/companies/${companyId}`)}
      submitButtonText={isCreateMode ? 'Create' : 'Save Changes'}
      submitButtonProps={{ loading: creating || updating, loadingText: isCreateMode ? 'Creating...' : 'Saving...' }}
      showButtonsOnBottom={false}
      showProgressBar={false}
      loading={creating || updating}
      error={createError || updateError}
    >
      {formProps => (
        <Steps>
          <CompanyDetailsStep {...formProps} />
        </Steps>
      )}
    </MultiStepForm>
  );
}
