import useRemovePricing from './useRemovePricing';

export default function useRemovePricingForm({
  pricingId,
  onCompleted,
  onError
}) {
  const { removePricing, loading, error } = useRemovePricing({
    productPricingOptionId: pricingId,
    onCompleted,
    onError
  });

  const initialValues = {};

  const handleSubmit = () => removePricing();

  return {
    initialValues,
    handleSubmit,
    loading,
    error
  };
}
