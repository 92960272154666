export const POLICY = {
  INVITATION: {
    STATUSES: {
      CANCELED: 'CANCELED',
      CLAIMED: 'CLAIMED',
      DECLINED: 'DECLINED',
      PENDING: 'PENDING'
    },
    ROLES: {
      CLIENT: 'CLIENT',
      BROKER: 'BROKER',
      CLAIMS_ADJUSTER: 'CLAIMS_ADJUSTER'
    }
  }
};
