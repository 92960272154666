import { Render, Td, Tr } from '@oforce/global-components';
import { Link } from 'react-router-dom';
import { NameCell } from '../../../components/tables';
import { formatCurrency } from '../../../utils/formatting';
import { ExposureStatusDot } from '../../exposures/components';
import { useVehicleMakeModelYear } from '../../vehicle/hooks';

export default function VehicleRow({ vehicle }) {
  const { hasMakeModelYear, makeModelYear } = useVehicleMakeModelYear(vehicle);
  const insured = vehicle?.insureds[0];

  return (
    <Tr>
      <Td>
        <ExposureStatusDot exposure={vehicle} />
      </Td>
      <Td>
        <Render if={hasMakeModelYear}>
          <Link to={`/vehicles/${vehicle?.id}`}>{makeModelYear}</Link>
          <br /> <span className="text-muted">{vehicle?.vin.toUpperCase()}</span>
        </Render>
        <Render if={!hasMakeModelYear}>
          <Link to={`/vehicles/${vehicle?.id}`}>{vehicle?.vin.toUpperCase()}</Link>
        </Render>
      </Td>
      <Td>{vehicle?.electionCount}</Td>
      <Td>
        <NameCell insured={insured} linkAddress={`/insureds/${insured?.id}`} />
      </Td>
      <Td>{insured?.dispatchLocation}</Td>
      <Td>{vehicle?.unitNumber}</Td>
      <Td>{vehicle?.vehicleWeight}</Td>
      <Td>{formatCurrency(vehicle?.value)}</Td>
    </Tr>
  );
}
