import React, { useMemo } from 'react';
import { SearchBy } from '../../../components';
import { useInsuredSearchByOptions } from '../../insureds/hooks';

export default function BulkEmailInsuredsSearchBy({ onChange }) {
  const { insuredSearchByOptions, loading } = useInsuredSearchByOptions();
  const defaultValue = useMemo(
    () => insuredSearchByOptions.find(option => option.value === 'LAST_NAME'),
    [insuredSearchByOptions]
  );

  const supportedFilters = ['FIRST_NAME', 'LAST_NAME', 'BUSINESS_NAME'];

  const filteredInsuredSearchByOptions = insuredSearchByOptions.filter(option =>
    supportedFilters.includes(option.value)
  );

  const onFilterChange = incomingFilters => {
    const searchBy = incomingFilters.reduce((acc, { field: { value: searchField }, value: searchValue }) => {
      if (!!searchValue) return [...acc, { searchField, searchValue }];
      return acc;
    }, []);

    if (searchBy.length > 0) {
      onChange({ searchBy });
    } else {
      onChange({ searchBy: [] });
    }
  };

  if (loading) return null;

  return (
    <>
      <SearchBy
        label={'Search by'}
        onFilterChange={onFilterChange}
        name="insured-search-by"
        fieldOptions={filteredInsuredSearchByOptions}
        defaultValue={defaultValue}
        maxFilters={2}
      />
    </>
  );
}
