import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';

export const UPDATE_CLIENT = gql`
  mutation update(
    $id: ID!
    $name: String!
    $externalId: String!
    $email: String!
    $address1: String
    $address2: String
    $city: String
    $state: ClientProductState
    $zip: String
    $country: String
  ) {
    updateClient(
      id: $id
      name: $name
      externalId: $externalId
      email: $email
      address1: $address1
      address2: $address2
      city: $city
      state: $state
      zip: $zip
      country: $country
    ) {
      id
      name
      externalId
      email
      address1
      address2
      city
      state
      zip
      country
      updated
      policies {
        id
        name
      }
    }
  }
`;

export default function useUpdateClient({ onCompleted = () => {}, onError = () => {} }) {
  const [update, { loading, error }] = useMutation(UPDATE_CLIENT, {
    onCompleted,
    onError
  });

  return {
    updateClient: (id, clientInput) => update({ variables: { id, ...clientInput } }),
    loading,
    error
  };
}
