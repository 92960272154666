import React from 'react';
import { Helmet } from 'react-helmet';
import { gql, useQuery } from '@apollo/client';
import { Loader } from '@oforce/global-components';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { toHeaderCase } from 'js-convert-case';

import { GraphQLErrors } from '../../components';
import { backendNameToLabel } from '../../utils/formatting';
import { AccountDetails, AccountMembers } from '../../views';
import { useActiveAccount } from '../../shared/activeAccount';
import { TabBar, TabLink, TabPane } from '../../components/tabs';
import { PageHeader, PageTitle } from '../../components/page-components';

import { AccountWebhooks } from './components';

export const GET_ACCOUNT = gql`
  query account($accountId: ID!) {
    account(id: $accountId) {
      id
      name
      type
      notificationEmailAddresses
      allowReportAccessToClient
      created
      updated
    }
  }
`;

export default function Account() {
  const { path } = useRouteMatch();
  const { id: accountId } = useActiveAccount();

  const { data, loading, error } = useQuery(GET_ACCOUNT, { variables: { accountId } });
  const account = data?.account;

  if (loading) return <Loader />;
  if (error) return <GraphQLErrors error={error} />;

  return (
    <>
      <Switch>
        <Route path={`${path}`}>
          <Helmet>
            <title>{`My ${backendNameToLabel(account?.type)} Account • ${account?.name}`}</title>
          </Helmet>
          <PageHeader withTabs>
            <PageTitle>{toHeaderCase(account?.name)}</PageTitle>
            <TabBar className="px-5 border-bottom-2 border-gray-300">
              <TabLink to={`${path}/details`} icon={['far', 'info-square']}>
                Details
              </TabLink>
              <TabLink to={`${path}/members`} icon={['far', 'users']}>
                Members
              </TabLink>
              <TabLink to={`${path}/webhooks`} icon={['far', 'cubes']}>
                Webhooks
              </TabLink>
            </TabBar>
          </PageHeader>
          <div className="bg-gray-200 h-100">
            <TabPane>
              <Switch>
                <Redirect exact from={`${path}`} to={`${path}/details`} />
                <Route path={`${path}/details`}>
                  <AccountDetails account={account} />
                </Route>
                <Route path={`${path}/members`}>
                  <AccountMembers />
                </Route>
                <Route path={`${path}/webhooks`}>
                  <AccountWebhooks />
                </Route>
              </Switch>
            </TabPane>
          </div>
        </Route>
      </Switch>
    </>
  );
}
