import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { Render, Badge } from '@oforce/global-components';
import { insuranceTypeAcronym, insuranceTypeName } from '../../../utils/insuranceTypes';

export default function InsuranceTypeBadge({ insuranceType, className, color }) {
  const tooltipId = `tooltip-${insuranceType?.id}`;
  const badgeText = insuranceTypeAcronym(insuranceType?.normalizedName);
  const badgeTooltip = insuranceTypeName(insuranceType?.normalizedName);

  return (
    <div className={className}>
      <Render if={!!badgeTooltip}>
        <UncontrolledTooltip target={tooltipId}>{badgeTooltip}</UncontrolledTooltip>
      </Render>
      <Badge id={tooltipId} color={color} className="text-nowrap">
        {badgeText}
      </Badge>
    </div>
  );
}
