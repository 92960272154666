import * as yup from 'yup';
import { convertToInt } from '../../../utils';
import { INSURANCE_TYPES } from '../../configurationInsuranceTypes';
import { useParseCoverageParameters } from '../../coverageParameters/hooks';
import { convertDecimalToPercentage } from '../../../utils';
import { useCanadianProvinces, useUSStates } from '../../../hooks';

const isMinimumValue = minimumValue => value => {
  if (!value) return true;
  return value && convertToInt(value) >= minimumValue;
};

export default function useProductPricingFormSteps({
  pricing,
  coverageParameters,
  insuranceType,
  isCreateMode,
  insuranceTypeRequiresVehicle,
  formOptions
}) {
  const { prepareParametersForInputs, coverageParametersYupValidation } = useParseCoverageParameters();
  const initialValuesForCreate = prepareParametersForInputs({ coverageParameters });
  const initialValuesForUpdate = pricing ? prepareParametersForInputs(pricing) : {};
  const coverageParametersYupSchema = coverageParametersYupValidation({ coverageParameters });

  const { options: stateOptions } = useUSStates();
  const { options: provinceOptions } = useCanadianProvinces();
  const allStateOptions = [...stateOptions, ...provinceOptions];

  const detailsStep = {
    name: 'Details',
    initialValues: {
      label: pricing?.label || '',
      name: pricing?.name || ''
    },
    validationSchema: yup.object().shape({
      label: yup
        .string()
        .required('Required')
        .test('len', 'Must be 255 characters or less', val => val?.length <= 255),
      name: yup.string()
    })
  };

  const vehicleTypeIds = pricing?.vehicleTypes?.map(({ id }) => id);

  const pricingStep = {
    name: 'Pricing',
    initialValues: {
      rate: pricing?.isPercentageFactor ? convertDecimalToPercentage(pricing?.rate) : pricing?.rate || '',
      rateMode:
        formOptions?.rateMode?.options?.find(option => option?.value === pricing?.rateMode) ||
        formOptions?.rateMode?.options[1],
      isPercentageFactor: pricing?.isPercentageFactor ?? false,
      factoredField:
        formOptions?.factorableFields?.options
          ?.reduce((acc, value) => acc.concat(value?.options), [])
          .find(option => option?.value === pricing?.factoredField) || null,
      ...(insuranceTypeRequiresVehicle && {
        vehicleTypes:
          formOptions?.vehicleTypes?.options?.filter(option => vehicleTypeIds?.includes(option?.value)) || null
      }),
      percentageMinimum: pricing?.percentageMinimum?.toString() || '',
      percentageMaximum: pricing?.percentageMaximum?.toString() || ''
    },
    validationSchema: yup.object().shape({
      rate: yup.string().required('Required'),
      rateMode: yup.object().nullable().required('Required'),
      isPercentageFactor: yup.boolean().when('rateMode', {
        is: rateMode => rateMode?.value === 'FACTORED',
        then: yup.boolean().required('Required'),
        otherwise: yup.boolean()
      }),
      factoredField: yup
        .object()
        .nullable()
        .when('rateMode', {
          is: rateMode => rateMode?.value === 'FACTORED',
          then: schema => schema.required('Required'),
          otherwise: schema => schema
        }),
      vehicleTypes: yup
        .array()
        .of(
          yup.object().shape({
            label: yup.string(),
            value: yup.string()
          })
        )
        .nullable(),
      percentageMinimum: yup.mixed().when('rateMode', {
        is: rateMode => rateMode?.value === 'FACTORED',
        then: yup.mixed().test('Minimum', 'Must be $0.00 or greater', isMinimumValue(0)),
        otherwise: yup.mixed().nullable()
      }),
      percentageMaximum: yup.mixed().when(['rateMode', 'percentageMinimum'], (rateMode, percentageMinimum) => {
        const intPercentageMinimum = convertToInt(percentageMinimum);

        if (rateMode?.value === 'FACTORED' && percentageMinimum) {
          return yup
            .mixed()
            .test('Maximum', `Must be greater than ${percentageMinimum}`, isMinimumValue(intPercentageMinimum));
        } else {
          return yup.mixed().nullable().label('Maximum value');
        }
      })
    })
  };

  const determineStateModeDefault = id => {
    return isCreateMode
      ? 'include'
      : initialValuesForUpdate[id]?.length <= allStateOptions?.length / 2
      ? 'include'
      : 'exclude';
  };

  const stateModeInitialValues = coverageParameters
    ?.filter(({ type, valueType }) => type === 'LIST' && valueType === 'STATE')
    ?.reduce(
      (acc, { id }) => ({
        ...acc,
        [`stateMode-${id}`]: formOptions?.stateMode?.options?.find(
          ({ value }) => value === determineStateModeDefault(id)
        )
      }),
      {}
    );

  const coverageParametersStep = {
    name: 'CoverageParameters',
    initialValues: {
      ...(isCreateMode ? { ...initialValuesForCreate } : { ...initialValuesForUpdate }),
      ...stateModeInitialValues
    },
    validationSchema: yup.object().shape({ ...coverageParametersYupSchema })
  };

  let formSteps = [detailsStep, pricingStep];

  if (coverageParameters?.length > 0 || insuranceType === INSURANCE_TYPES.VEHICLE) {
    formSteps = [...formSteps, coverageParametersStep];
  }

  return { formSteps };
}
