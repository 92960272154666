import React from 'react';
import { PoliciesTable } from '../../../policies/components';
import { usePolicies } from '../../../policies/hooks';

export default function ClientPolicies({ client }) {
  const { policies, loading, error, paginationProps, policyFilter, changePolicyFilter } = usePolicies({
    pageSize: 20,
    initialFilters: { activeStatus: 'ACTIVE', clientId: client.id }
  });

  return (
    <div>
      <PoliciesTable
        policies={policies}
        loading={loading}
        error={error}
        policyFilter={policyFilter}
        changePolicyFilter={filters => changePolicyFilter({ ...filters, clientId: client.id })}
        paginationProps={paginationProps}
      />
    </div>
  );
}
