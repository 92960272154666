import { TrPlaceholder, TrLoader, RenderData } from '@oforce/global-components';

import { Table } from '../../../../components/table-2.0';
import { PagerButtons, TrGraphQLErrors, BoxContainer, FiltersContainer } from '../../../../components';

import { useExposureElections } from '../../hooks';
import PendingApprovalRow from './PendingApprovalRow';
import { useActiveAccount } from '../../../../shared/activeAccount';
import { HideFromRolesAndTypes } from '../../../../shared/activeAccount/components';
import { FilterDropdown, FilterLabel } from '../../../../components/filtering';
import { EXPOSURE_ELECTION_STATUSES } from '../../../exposureElections/constants';
import { useState } from 'react';
const { ACTIVE, PENDING_TERMINATION, TERMINATED, REJECTED } = EXPOSURE_ELECTION_STATUSES;

export function castViolationDetails({ compliance }, filterStatus = 'OPEN') {
  const { violations } = compliance;
  return violations
    .filter(x => x.status === filterStatus)
    .map(violation => {
      const text = `${violation.field} ${violation.reason}`;
      return { id: violation.id, text: text.charAt(0).toUpperCase() + text.slice(1) };
    });
}

export default function PendingApprovalsTable() {
  const colSpan = 6;
  const defaults = { complianceStatus: 'OPEN', status: ACTIVE };
  const [filters, setFilters] = useState(defaults);

  const updateFilter = values => {
    const newFilters = { ...filters, ...values };
    setFilters(newFilters);
  };

  const { elections, loading, error, hasNextPage, hasPreviousPage, loadNextPage, loadPreviousPage } =
    useExposureElections({
      ...filters
    });

  const {
    ROLES: { OBSERVER },
    TYPES: { CLAIMS_ADJUSTER }
  } = useActiveAccount();

  const statusOptions = [
    { label: 'All', value: null },
    { label: 'Active', value: ACTIVE },
    { label: 'Pending Termination', value: PENDING_TERMINATION },
    { label: 'Terminated', value: TERMINATED },
    { label: 'Rejected', value: REJECTED }
  ];

  const defaultOption = statusOptions.find(({ value }) => value === ACTIVE);

  return (
    <>
      <FiltersContainer className="mb-4">
      <FilterLabel>Status</FilterLabel>
      <FilterDropdown
        id="election-status"
        className="mr-3"
        headerText="Status..."
        options={statusOptions}
        defaultOption={defaultOption}
        setFilter={({ value }) => updateFilter({ status: value})}
      />
      </FiltersContainer>
      <BoxContainer>
        <Table responsive>
          <thead>
            <tr>
              <th>Status</th>
              <th>Insured Name</th>
              <th>Contact</th>
              <th>Coverage</th>
              <th>Issues</th>
              <HideFromRolesAndTypes roles={[OBSERVER]} types={[CLAIMS_ADJUSTER]}>
                <th>Actions</th>
              </HideFromRolesAndTypes>
            </tr>
          </thead>
          <tbody>
            <RenderData
              data={elections}
              loading={loading}
              error={error}
              loadingComponent={<TrLoader colSpan={colSpan} />}
              errorComponent={<TrGraphQLErrors colSpan={colSpan} error={error} />}
              placeholderComponent={<TrPlaceholder colSpan={colSpan}>No coverages pending approval</TrPlaceholder>}
            >
              {elections => elections?.map(election => <PendingApprovalRow key={election.id} election={election} />)}
            </RenderData>
          </tbody>
        </Table>
      </BoxContainer>
      <PagerButtons
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        loadNextPage={loadNextPage}
        loadPreviousPage={loadPreviousPage}
        loading={loading}
      />
    </>
  );
}
