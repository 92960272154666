import React, { useState } from 'react';
import { useExposureElections } from '../hooks';
import { EXPOSURE_ELECTION_STATUSES } from '../../../views/exposureElections/constants';
import CoveragesTable from './CoveragesTable';
import { BoxContainer, PagerButtons } from '../../../components';
import CoveragesFilters from './CoveragesFilters';
const { ACTIVE } = EXPOSURE_ELECTION_STATUSES;

export default function Coverages() {
  const [coveragesFilter, setCoveragessFilter] = useState({
    ordering: 'LAST_NAME',
    inCompliance: true,
    status: ACTIVE
  });

  const { elections, loading, error, hasNextPage, hasPreviousPage, loadNextPage, loadPreviousPage } =
    useExposureElections({
      ...coveragesFilter
    });

  return (
    <>
      <CoveragesFilters defaults={coveragesFilter} onChange={setCoveragessFilter} filterValue={coveragesFilter} />
      <BoxContainer>
        <CoveragesTable elections={elections} loading={loading} error={error} />
      </BoxContainer>
      <PagerButtons
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        loadNextPage={loadNextPage}
        loadPreviousPage={loadPreviousPage}
        loading={loading}
      />
    </>
  );
}
