import React from 'react';
import PropTypes from 'prop-types';
import { Render } from '.';

export default function RenderData({
  data,
  placeholderComponent,
  loading = false,
  loadingComponent,
  success = false,
  successComponent,
  error,
  errorComponent,
  children
}) {
  return (
    <>
      <Render if={loading && (!data || data?.length === 0)}>{loadingComponent}</Render>
      <Render if={!loading && error && !!errorComponent}>{errorComponent}</Render>
      <Render if={!loading && success && !!successComponent}>{successComponent}</Render>
      <Render if={!loading && !error && (!data || data?.length === 0) && !!placeholderComponent}>
        {placeholderComponent}
      </Render>
      <Render if={!error && (!!data || data?.length > 0)}>
        {typeof children == 'function' ? children(data) : children}
      </Render>
    </>
  );
}

RenderData.propTypes = {
  // The data you want to render, must be an array
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  // If true, nothing will be rendered but the loadingComponent you pass to it
  loading: PropTypes.bool.isRequired,
  // The loading component that will be rendered while the data is loading
  loadingComponent: PropTypes.element.isRequired,
  // If true, nothing will be rendered but the error you pass to it
  error: PropTypes.object,
  // The error component that will be rendered when there is an error
  errorComponent: PropTypes.element.isRequired,
  // The optional placeholder component that will be rendered when the data has been fetched, but there are no items and no errors.
  placeholderComponent: PropTypes.element
};
