import React from 'react';
import { Button } from '@oforce/global-components';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';

import { Render, TabPage } from '../../components';
import { AccountCreateUpdate } from '../../views';
import { GET_CURRENT_USER } from '../../context/currentUser/provider';

import { AccountsList } from './containers';
import { useCurrentUser } from '../../shared/currentUser';
import { FullPage, Message, MessageBox, Title } from '../../components/message-box';

export default function MyProfileAccounts() {
  const history = useHistory();
  const { userAccounts } = useCurrentUser();

  const { url, path } = useRouteMatch();

  return (
    <>
      <Render if={userAccounts?.length === 0}>
        <FullPage>
          <MessageBox className="align-self-start justify-self-center">
            <Title className="mb-0 mt-3">Welcome to your new insurance managment system!</Title>
            <Message className="mb-2 mt-3">
              It looks like you have no accounts. You can either view invitations, or create a new account.
            </Message>
            <div className="mt-3 mb-3 w-100 d-flex flex-row justify-content-around">
              <Button color="info" onClick={() => history.push(`${url}/create`)}>
                Create Account
              </Button>
              <Button color="info" onClick={() => history.push(`/my-profile/invitations`)}>
                View Invitations
              </Button>
            </div>
          </MessageBox>
        </FullPage>
      </Render>
      <Render if={userAccounts?.length > 0}>
        <TabPage
          title="Accounts"
          buttons={
            <Button color="info" icon={['far', 'plus']} onClick={() => history.push(`${url}/create`)}>
              Create Account
            </Button>
          }
        >
          <AccountsList userAccounts={userAccounts} />
        </TabPage>
      </Render>
      <Switch>
        <Route path={`${path}/create`}>
          <AccountCreateUpdate
            isOpen={true}
            toggle={() => history.push('/my-profile/accounts')}
            onCompleted={() => history.push('/my-profile/accounts')}
            refetchQueries={[{ query: GET_CURRENT_USER }]}
          />
        </Route>
      </Switch>
    </>
  );
}
