import { gql, useSubscription } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';
import { SendDocumentsJobContext } from './context';
import { useResettableMutation } from '../../hooks/useResettableMutation';
import { useHistory } from 'react-router-dom';

const START_JOB = gql`
  mutation startSendDocumentsJob($documentRelationshipIds: [ID]!, $activeOn: Date, $policyId: ID, $clientId: ID) {
    sendDocumentsEmails(
      documentRelationshipIds: $documentRelationshipIds
      activeOn: $activeOn
      policyId: $policyId
      clientId: $clientId
    )
  }
`;

const ON_SEND_DOCUMENTS_JOB_PROGRESS = gql`
  subscription jobProgress($jobId: ID!) {
    job: sendDocumentsJobProgress(jobId: $jobId) {
      jobId
      finished
      message
      errors
      emailCount
      progress
      insured {
        firstName
        lastName
      }
    }
  }
`;

export default function SendDocumentsJobProvider({ children }) {
  const history = useHistory();
  const [jobId, setJobId] = useState(null);
  const [jobErrors, setJobErrors] = useState([]);
  const [finished, setJobFinished] = useState(true);
  const [percentComplete, setPercentComplete] = useState(0);
  const [message, setMessage] = useState(null);
  const [insured, setInsured] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [policyId, setPolicyId] = useState(null);
  const [clientId, setClientId] = useState(null);

  const clearJobId = useCallback(() => {
    setJobId(null);
    setJobErrors([]);
  }, []);

  useEffect(() => {
    if (!!jobId && !!finished) {
      setTimeout(() => clearJobId(), 10000);
    }
  }, [finished, clearJobId, jobId]);

  const [startDocumentsJob, { loading, error, reset }] = useResettableMutation(START_JOB, {
    onCompleted: data => {
      setJobFinished(false);
      setJobId(data?.sendDocumentsEmails);
      history.push(redirectUrl);
    },
    onError: () => setTimeout(() => reset(), 10000)
  });

  const startSendDocumentsJob = ({ documentRelationshipIds, activeOn, redirectUrl, clientId, policyId }) => {
    setJobFinished(false);
    setRedirectUrl(redirectUrl);
    setPolicyId(policyId);
    setClientId(clientId);
    startDocumentsJob({
      variables: { documentRelationshipIds, activeOn, policyId, clientId }
    });
  };

  useSubscription(ON_SEND_DOCUMENTS_JOB_PROGRESS, {
    skip: !jobId,
    variables: { jobId },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      const job = data?.job;
      const { errors, message, insured, finished, progress } = job;
      setJobErrors(errors);
      setJobFinished(finished);
      setPercentComplete(progress);
      setInsured(insured);
      setMessage(message);
    }
  });

  return (
    <SendDocumentsJobContext.Provider
      value={{
        startSendDocumentsJob,
        jobId,
        policyId,
        clientId,
        clearJobId,
        loading,
        error,
        jobErrors,
        finished,
        progress: percentComplete,
        message,
        insured
      }}
    >
      {children}
    </SendDocumentsJobContext.Provider>
  );
}
