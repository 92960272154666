import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';

export const GET_PRODUCT_FEES = gql`
  query ProductFees($id: ID!) {
    product(id: $id) {
      id
      fees {
        id
        name
        rate
        rateMode
        rateModeType
        appliesToAllPricingOptions
        productPricingIds
        multiplierFeeIds
        tags
        prorated
      }
    }
  }
`;

export default function useFees({ productId, onCompleted = () => {}, onError = () => {} }) {
  const { loading, error, data } = useQuery(GET_PRODUCT_FEES, {
    variables: { id: productId },
    onCompleted,
    onError
  });

  return {
    loading,
    error,
    availableTags: [],
    fees: data?.product?.fees
  };
}
