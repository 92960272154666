import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledInputSearchContainer = styled.div.attrs({
  className: 'd-flex justify-content-start align-items-center'
})`
  background: ${props => props.theme.gray100};
  border-radius: 3px;
  overflow: hidden;
  &:hover {
    background: ${props => props.theme.gray200};
  }
`;

const StyledInputSearch = styled.input.attrs({ className: 'px-2 py-1' })`
  border: 0 !important;
  width: 100%;
  background: transparent !important;
  &:hover {
    border: 0 !important;
  }
  &:focus {
    outline: 0;
  }
  &::placeholder {
    color: ${props => props.theme.gray500};
  }
`;

const StyledSearchIcon = styled(FontAwesomeIcon).attrs({
  className: ''
})`
  color: ${props => props.theme.gray500};
`;

const StyledSearchButton = styled.button.attrs({
  className: 'py-1 px-3 border-0'
})`
  background: transparent;
  &:focus {
    outline: 0 !important;
  }
  &:hover {
    ${StyledSearchIcon} {
      color: ${props => props.theme.gray600};
    }
  }
  &:active {
    ${StyledSearchIcon} {
      color: ${props => props.theme.gray800};
    }
  }
`;

export default function InputSearch(props) {
  return (
    <StyledInputSearchContainer>
      <StyledInputSearch type="search" {...props} />
      <StyledSearchButton>
        <StyledSearchIcon icon={['far', 'search']} />
      </StyledSearchButton>
    </StyledInputSearchContainer>
  );
}
