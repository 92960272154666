import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { TrLoader, TrPlaceholder } from '@oforce/global-components';

import { Table } from '../../../components/table-2.0';
import { TrGraphQLErrors } from '../../../components';

import FeeRow from './FeeRow';

export const GET_POLICY_FEES = gql`
  query PolicyFees($id: ID!) {
    policy(id: $id) {
      id
      fees {
        id
        name
        rate
        tags
        rateMode
        rateModeType
        appliesToAllPricingOptions
        policyPricingIds
        multiplierFeeIds
        prorated
      }
    }
  }
`;

export default function FeesTable({ policy }) {
  const { data, loading, error } = useQuery(GET_POLICY_FEES, {
    variables: { id: policy?.id }
  });
  const fees = data?.policy?.fees;
  const totalColCount = 4;

  return (
    <Table>
      <thead>
        <tr>
          <th>Fee</th>
          <th>Rate Mode</th>
          <th>Applies to</th>
          <th>Prorate</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {loading && <TrLoader colSpan={totalColCount} />}
        {!loading && error && <TrGraphQLErrors colSpan={totalColCount} error={error} />}
        {!loading && !error && fees?.length === 0 && (
          <TrPlaceholder colSpan={totalColCount} className="bg-gray-100 border-bottom border-width-2 border-gray-300">
            No Fees
          </TrPlaceholder>
        )}
        {!loading &&
          !error &&
          fees?.length > 0 &&
          fees?.map(fee => <FeeRow key={fee?.id} policy={policy} accountId={policy?.accountId} fee={fee} />)}
      </tbody>
    </Table>
  );
}
