import React from 'react';
import { Modal } from '@oforce/global-components';

import { CopyableBadge } from '../../../components';

import { useMappableInvoiceDocumentFields } from '../../invoiceTemplate/hooks';

export default function MappableFields({ open, toggle }) {
  const { fields, loading } = useMappableInvoiceDocumentFields();

  return (
    <Modal isOpen={open} toggle={toggle} title="Mappable Fields" loading={loading} showFooter={false} scrollable>
      {fields?.map((field, i) => (
        <CopyableBadge key={i} className="mb-2 mr-2 cursor-pointer" color={'blue'} text={field} />
      ))}
    </Modal>
  );
}
