import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';

export const REMOVE_ACCOUNT_USER = gql`
  mutation removeAccountUser($accountId: ID!, $userId: ID!) {
    removeAccountUser(accountId: $accountId, userId: $userId) {
      id
      user {
        id
      }
      account {
        id
      }
    }
  }
`;

const removeAccountUserFromCache =
  accountId =>
  (cache, { data: { removeAccountUser } }) => {
    const targetAccount = { __typename: 'Account', id: accountId };

    const { id } = removeAccountUser;
    cache.modify({
      id: cache.identify(targetAccount),
      fields: {
        users(existingUsers = [], { readField }) {
          return existingUsers.filter(user => id !== readField('id', user));
        }
      }
    });
  };

export default function useRemoveAccountUser({ onCompleted = () => {}, onError = () => {}, accountId }) {
  const [remove, { loading, error, reset }] = useMutation(REMOVE_ACCOUNT_USER, {
    onError,
    onCompleted({ removeAccountUser: { account, user } }) {
      onCompleted && onCompleted({ accountId: account.id, userId: user.id });
    },
    update: removeAccountUserFromCache(accountId)
  });

  return {
    removeAccountUser: ({ userId }) => remove({ variables: { userId, accountId } }),
    loading,
    error,
    reset
  };
}
