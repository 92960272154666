import React from 'react';
import { ReactSelectField } from '@oforce/global-components';

import { FormSection } from '../../../components';

export default function ProductDocumentPricingFields({ formik, formOptions }) {
  return (
    <FormSection title="Pricing Options">
      <ReactSelectField
        required
        label="Applies to"
        options={formOptions?.documentAppliesTo.options}
        name="appliesToAllPricingOptions"
      />
      {!formik?.values?.appliesToAllPricingOptions?.value && (
        <ReactSelectField
          required
          label="Selected Pricing Options"
          name="pricingOptions"
          placeholder="Select..."
          isMulti
          options={formOptions?.productPricing?.options}
          isLoading={formOptions?.productPricing?.loading}
          clearable
        />
      )}
    </FormSection>
  );
}
