import { gql, useQuery } from '@apollo/client';

const POLICY_RENEWED_FROM = gql`
  query PolicyRenewedFrom($policyId: ID!) {
    policy(id: $policyId) {
      id
      name
      backdate
    }
  }
`;

const CURRENT_POLICY = gql`
  query CurrentPolicy($policyId: ID!) {
    policy(id: $policyId) {
      id
      effectiveDate
      renewedFromPolicyId
    }
  }
`;

export default function useRenewedFromPolicy({ policy }) {
  const { data } = useQuery(CURRENT_POLICY, {
    variables: { policyId: policy?.id },
    skip: !policy?.id,
    fetchPolicy: 'cache-and-network'
  });

  const {
    data: renewedFromPolicy,
    loading: renewLoading,
    error: renewError
  } = useQuery(POLICY_RENEWED_FROM, {
    variables: { policyId: data?.policy?.renewedFromPolicyId },
    skip: !data?.policy?.renewedFromPolicyId,
    fetchPolicy: 'cache-and-network'
  });

  return {
    loading: renewLoading,
    error: renewError,
    currentPolicyEffectiveDate: data?.policy?.effectiveDate,
    renewedFromPolicy: renewedFromPolicy?.policy || {}
  };
}
