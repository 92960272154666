import { createContext } from 'react';

export const InitialState = {
  startSyncPricingOptionsJob: () => {},
  clearJobId: () => {},
  jobId: null,
  productId: null,
  message: null,
  loading: false,
  jobErrors: []
};

export const SyncPricingOptionsJobContext = createContext(InitialState);

SyncPricingOptionsJobContext.displayName = 'SyncPricingOptionsJobContext';
