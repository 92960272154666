export const isEmpty = obj => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export const hasNoValues = obj => {
  for (var key in obj) {
    if (obj[key]) return false;
  }
  return true;
};

export const cleanEmptyProperties = obj => {
  for (var key in obj) {
    if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
      delete obj[key];
    }
  }
  return obj;
};

export const groupBy = (xs, property) =>
  xs.reduce((acc, x) => {
    (acc[x[property]] = acc[x[property]] || []).push(x);
    return acc;
  }, []);

export const asInt = num => {
  const val = parseInt(num);
  if (isNaN(val)) {
    return null;
  }
  return val;
};

export const convertToInt = value => (value ? parseInt(value.replace(/[^\d.]/g, '')) : 0);
export const convertToFloat = value => (value ? parseFloat(value.replace(/[^\d.]/g, '')) : 0);

export const convertPercentageToDecimal = value => {
  const percentage = value ? parseFloat(value.replace(/[^\d.]/g, '')) / 100 : 0;
  if (isNaN(percentage)) {
    throw Error(`invalid percentage value: ${value}`);
  }
  return parseFloat(percentage.toFixed(8));
};

export const convertDecimalToPercentage = decimal => {
  const percentage = decimal ? parseFloat(decimal.toString().replace(/[^\d.]/g, '')) * 100 : 0;
  if (isNaN(percentage)) {
    throw Error(`invalid decimal value: ${decimal}`);
  }
  return `${parseFloat(percentage.toFixed(6))}%`;
};

export const getProp = (obj, prop) => {
  if (typeof obj !== 'object') return null;
  if (typeof prop !== 'string') return null;

  prop = prop.replace(/\[["'`](.*)["'`]\]/g, '.$1');

  return prop.split('.').reduce(function (prev, curr) {
    return prev ? prev[curr] : undefined;
  }, obj);
};

export function dropNullProps(props = {}) {
  let result = {};
  for (let prop in props) {
    if (props[prop]) {
      result[prop] = props[prop];
    }
  }
  return result;
}
