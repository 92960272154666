import { gql } from '@apollo/client';
import { useState } from 'react';
import { usePaginatedQuery } from '../../../hooks';
import { dropNullProps } from '../../../utils';

export const LIST_NOTIFICATION_JOB = gql`
  query listNotificationJob(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $jobId: ID
    $distinctJobs: Boolean
    $searchBy: [NotificationSearchByInput] = []
    $status: NotificationStatus
  ) {
    listNotificationJob: listNotifications(
      after: $after
      before: $before
      first: $first
      last: $last
      jobId: $jobId
      distinctJobs: $distinctJobs
      searchBy: $searchBy
      status: $status
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        node {
          id
          jobId
          failureReason
          templateGroup
          recipientEmail
          recipientName
          status
          subject
          insured {
            id
            firstName
            lastName
            businessName
          }
        }
      }
    }
  }
`;

export default function useListNotificationJob({ pageSize = 20, initialFilters = {} }) {
  const [filters, setFilters] = useState({ ...initialFilters });

  const { data, loading, error, refetch, paginationProps, queryProps, loadFirstPage } = usePaginatedQuery(
    LIST_NOTIFICATION_JOB,
    {
      variables: dropNullProps({ ...filters }),
      pathToPageInfo: 'listNotificationJob',
      pageSize,
      fetchPolicy: 'cache-and-network'
    }
  );

  return {
    notificationJob: data?.listNotificationJob?.edges?.map(({ node }) => node),
    loading,
    error,
    refetch,
    paginationProps,
    queryProps,
    filters,
    changeFilters(filters) {
      setFilters(state => {
        let newState = { ...state, ...filters };
        loadFirstPage(newState);
        return newState;
      });
    }
  };
}
