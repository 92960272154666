import { gql, useQuery } from '@apollo/client';

export const GET_UNCLAIMED_INVITATIONS = gql`
  query Invitations {
    user: currentUser {
      id
      accountInvitations(status: PENDING) {
        id
        status
        role
        opened
        created
        updated
        account {
          id
          name
        }
      }
      policyInvitations(status: PENDING) {
        id
        status
        role
        opened
        created
        updated
        policy {
          id
          name
        }
        account {
          id
          name
        }
      }
    }
  }
`;

export default function useUnclaimedInvitations() {
  const { data, loading, error } = useQuery(GET_UNCLAIMED_INVITATIONS, { fetchPolicy: 'cache-and-network' });

  const accountInvites = data?.user?.accountInvitations?.reduce((acc, invite, i) => {
    return [...acc, { ...invite, type: 'ACCOUNT' }];
  }, []);

  const invitations = data?.user?.policyInvitations
    ?.reduce((acc, invite, i) => {
      return [...acc, { ...invite, type: 'POLICY' }];
    }, accountInvites)
    .sort((a, b) => a?.created < b?.created);

  return {
    invitations,
    unopenedInvitationsCount: invitations?.filter(x => !!x.opened)?.length || 0,
    loading,
    error
  };
}
