import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { TrPlaceholder, TrLoader, Button } from '@oforce/global-components';
import { Table } from '../../../components/table-2.0';
import { BoxContainer, GraphQLErrors, RenderData, TrGraphQLErrors } from '../../../components';
import { TablePagination } from '../../../components/tables';
import NotificationJobRow from './NotificationJobRow';
import NotificationJobFilter from './NotificationJobFilter';
import { useSyncNotifications, useTotalNotificationsCount } from '../hooks';
import { toHeaderCase } from 'js-convert-case';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDownloadFailedDocumentsJobContext, useNotificationStatusReportJobContext } from '../../../context';

export default function NotificationJobTable({
  notificationJob,
  loading,
  error,
  paginationProps,
  filters,
  changeFilters,
  notificationStatusOptions
}) {
  const colSpan = 6;
  const { jobId } = useParams();
  const { syncNotifications, loading: loadingSync, error: errorSync } = useSyncNotifications({ jobId });

  const {
    totalNotificationsCount,
    loading: loadingCount,
    error: errorCount
  } = useTotalNotificationsCount({ initialFilters: { ...filters } });

  const filterStatus = toHeaderCase(filters?.status);

  const {
    loading: loadingNotificationStatusReport,
    error: errorNotificationStatusReport,
    startNotificationStatusReportJob
  } = useNotificationStatusReportJobContext();

  const {
    loading: loadingDownloadFailedDocuments,
    error: errorDownloadFailedDocuments,
    startDownloadFailedDocumentsJob
  } = useDownloadFailedDocumentsJobContext();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <NotificationJobFilter
          {...filters}
          notificationStatusOptions={notificationStatusOptions}
          onChange={changeFilters}
        />
        <div className="d-flex flex-row align-items-end">
          <Dropdown isOpen={dropdownOpen} toggle={toggle} color="info" direction="down">
            <DropdownToggle className="ml-2 btn btn-outline-info btn-sm">
              <FontAwesomeIcon icon={['far', 'cog']} /> Actions
            </DropdownToggle>
            <DropdownMenu style={{ minWidth: '260px', marginRight: '2.8rem' }}>
              <DropdownItem
                onClick={() => startDownloadFailedDocumentsJob({ notificationJobId: jobId })}
                disabled={loadingDownloadFailedDocuments}
              >
                <FontAwesomeIcon icon={['far', 'arrow-to-bottom']} className="pr-1" />
                Download Failed Documents
              </DropdownItem>
              <DropdownItem
                onClick={() => startNotificationStatusReportJob({ notificationJobId: jobId })}
                disabled={loadingNotificationStatusReport}
              >
                <FontAwesomeIcon icon={['far', 'arrow-to-bottom']} className="pr-1" />
                Download Report
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <Button
            color="info"
            size="sm"
            icon={['far', 'sync-alt']}
            className="ml-2"
            loading={loadingSync}
            loadingText="Syncing"
            onClick={() => syncNotifications(jobId)}
          >
            Sync
          </Button>
        </div>
      </div>
      <GraphQLErrors error={errorSync || errorNotificationStatusReport || errorDownloadFailedDocuments} />
      <BoxContainer>
        <Table>
          <thead>
            <tr>
              <th>Template</th>
              <th>Name</th>
              <th>Email</th>
              <th>Subject</th>
              <th>Status</th>
              <th>Reason for Failure</th>
            </tr>
          </thead>
          <tbody>
            <RenderData
              data={notificationJob}
              loading={loading}
              error={error}
              loadingComponent={<TrLoader colSpan={colSpan} />}
              errorComponent={<TrGraphQLErrors error={error} colSpan={colSpan} />}
              placeholderComponent={<TrPlaceholder colSpan={colSpan}>No Notifications for Job</TrPlaceholder>}
            >
              {renderData =>
                renderData?.map(notification => (
                  <NotificationJobRow key={notification?.id} notification={notification} />
                ))
              }
            </RenderData>
          </tbody>
        </Table>
      </BoxContainer>
      <div className="d-flex align-items-center flex-wrap justify-content-between mt-2 font-weight-bold fs-xs">
        <span />
        <span>
          {!loadingCount &&
            !errorCount &&
            (!!filterStatus
              ? `Total ${filterStatus}: ${totalNotificationsCount}`
              : `Total: ${totalNotificationsCount}`)}
        </span>
      </div>
      <TablePagination {...paginationProps} loading={loading} />
    </>
  );
}
