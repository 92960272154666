import React from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { Button } from '@oforce/global-components';

import { GraphQLErrors, Render } from '../../components';
import { useDocumentDownloadJobContext } from '../../context';

import { ProductDetailsView } from './components';
import { ProductCreateUpdate } from '../../views';
import { TabPage } from '../../components/tabs';
import { HideFromRoles, ShowForTypes, useActiveAccount } from '../../shared/activeAccount';

export default function ProductDetails({ product }) {
  const history = useHistory();
  const { url, path } = useRouteMatch();

  const {
    TYPES: { CARRIER },
    ROLES: { POLICY_MANAGER, EXPOSURE_MANAGER }
  } = useActiveAccount();

  const {
    startDownloadAllProductExposuresJob,
    jobId,
    loading: downloadLoading,
    error: downloadError
  } = useDocumentDownloadJobContext();

  return (
    <Switch>
      <Route exact path={path}>
        <TabPage
          buttons={
            <ShowForTypes types={[CARRIER]}>
              <HideFromRoles roles={[POLICY_MANAGER, EXPOSURE_MANAGER]}>
                <Button
                  color="info"
                  outline
                  className="ml-2"
                  loading={downloadLoading}
                  icon={['far', 'file-export']}
                  disabled={!!jobId}
                  onClick={() => startDownloadAllProductExposuresJob(product?.id)}
                >
                  Export All Exposures
                </Button>
                <Button
                  color="info"
                  className="ml-2"
                  onClick={() => history.push(`${url}/update`)}
                  icon={['far', 'pen']}
                >
                  Edit
                </Button>
              </HideFromRoles>
            </ShowForTypes>
          }
        >
          <Render if={downloadError}>
            <div className="p-3">
              <GraphQLErrors error={downloadError} />
            </div>
          </Render>

          <ProductDetailsView product={product} />
        </TabPage>
      </Route>
      <Route path={`${path}/update`}>
        <ProductCreateUpdate product={product} />
      </Route>
    </Switch>
  );
}
