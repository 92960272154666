import React, { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { usePolicyRenewalJobContext } from '../../context';
import { ToastContent } from '../../components/react-toastify';
import { useHistory } from 'react-router-dom';
import { Button } from '@oforce/global-components';

export default function PolicyRenewalJob() {
  const { jobId, renewedPolicyId, message, finished, clearJobId, error, jobErrors = [] } = usePolicyRenewalJobContext();
  const history = useHistory();
  const toastId = useRef(null);

  useEffect(() => {
    if (!toast.current && jobId) {
      toastId.current = toast(<Renewing />, {
        hideProgressBar: false,
        pauseOnHover: true,
        autoClose: false
      });
      history.push(`/policies`);
    }
  }, [toastId, jobId, clearJobId, history]);

  useEffect(() => {
    if (!toastId.current) {
      return;
    }

    if (error || jobErrors.length !== 0) {
      toast.update(toastId.current, {
        render: () => <ErrorRenewingPolicy error={jobErrors || error} />,
        autoClose: 4000,
        progressClassName: 'bg-danger'
      });
    } else if (!finished) {
      toast.update(toastId.current, {
        render: () => <Renewing message={message} />,
        autoClose: false
      });
    } else if (finished) {
      toast.update(toastId.current, {
        render: () => <PolicyRenewalFinished message={message} renewedPolicyId={renewedPolicyId} errors={jobErrors} />,
        autoClose: 4000,
        progressClassName: 'bg-success'
      });
    }
    // eslint-disable-next-line
  }, [toastId, finished, message, error, jobErrors]);

  return <></>;
}

const Renewing = ({ message }) => {
  let title = message ?? 'Preparing Policy Renewal';

  return (
    <ToastContent
      title={title}
      icon={['far', 'spinner-third']}
      iconProps={{ spin: true }}
      message="Allow us some time to process the policy renewal."
    />
  );
};

const ErrorRenewingPolicy = ({ error }) => {
  return (
    <ToastContent
      title="Error Renewing Policy"
      titleProps={{ className: 'text-danger' }}
      icon={['fas', 'times-circle']}
      message={error.message || error}
    />
  );
};

const PolicyRenewalFinished = ({ message, renewedPolicyId, errors }) => {
  const hadErrors = errors && errors.length > 0;
  const history = useHistory();

  return (
    <ToastContent
      title={message || 'Policy Renewal Finished'}
      titleProps={{ className: !hadErrors ? 'text-success' : 'text-danger' }}
      icon={!hadErrors ? ['fas', 'check-circle'] : ['far', 'exclamation-triangle']}
      message={!hadErrors ? 'Your Policy Renewed Sucessfully' : 'Your Policy Renewal Failed, Please Contact Support'}
    >
      {!hadErrors && (
        <Button
          color="link"
          size="sm"
          icon={['far', 'search']}
          className="mr-2"
          onClick={() => history.push(`/policies/${renewedPolicyId}`)}
        >
          View Renewed Policy
        </Button>
      )}
    </ToastContent>
  );
};
