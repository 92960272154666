import React from 'react';
import { Button, Render } from '@oforce/global-components';

import { BoxContainer } from '../../../components';
import { humanFriendlyDate } from '../../../utils/formatting';

export default function InvitationTemplate({
  title,
  date,
  children,
  onAccept,
  acceptButtonProps,
  acceptButtonText = 'Accept Invitation',
  onDecline,
  declineButtonProps,
  declineButtonText = 'Decline Invitation'
}) {
  return (
    <BoxContainer className="p-4 p-md-5 mb-3">
      <Render if={!!date}>
        <div className="text-muted font-italic mb-3">{humanFriendlyDate(date)}</div>
      </Render>
      <h3 style={{ letterSpacing: '-1px' }}>{title}</h3>
      <div className="mb-5">{children}</div>

      <div className="d-flex">
        <Button color="success" className="mr-3" onClick={onAccept} {...acceptButtonProps}>
          {acceptButtonText}
        </Button>
        <Button color="secondary" outline onClick={onDecline} {...declineButtonProps}>
          {declineButtonText}
        </Button>
      </div>
    </BoxContainer>
  );
}
