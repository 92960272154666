import React, { useEffect, useState } from 'react';
import { TrLoader, TrPlaceholder, RenderData } from '@oforce/global-components';
import TrGraphQLErrors from '../TrGraphQLErrors';
import AccountWebhookRow from './AccountWebhookRow';

import { BoxContainer } from '..';
import { Table } from '../table-2.0';

export default function AccountWebhooksTable({ webhooks, loading, error, className }) {
  const [mutationError, setError] = useState(null);
  const totalColCount = 3;

  useEffect(() => {
    if (mutationError) {
      setTimeout(() => setError(null), 10000);
    }
  }, [mutationError]);

  return (
    <BoxContainer>
      <Table className={className}>
        <thead>
          <tr>
            <th>Url</th>
            <th>Events</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <RenderData
            data={webhooks}
            loading={loading}
            error={error}
            loadingComponent={<TrLoader colSpan={totalColCount} />}
            errorComponent={<TrGraphQLErrors colSpan={totalColCount} error={error || mutationError} />}
            placeholderComponent={<TrPlaceholder colSpan={totalColCount}>None</TrPlaceholder>}
          >
            {webhooks =>
              webhooks?.map(webhook => (
                <AccountWebhookRow key={webhook?.id} webhook={webhook} onError={error => setError(error)} />
              ))
            }
          </RenderData>
        </tbody>
      </Table>
    </BoxContainer>
  );
}
