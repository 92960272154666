import React from 'react';
import { Td, Tr } from '@oforce/global-components';

import {
  CellActions,
  CellOwnerContact,
  CellPricingOptionAndAddOns,
  CellCoverageDates,
  CellTotal,
  CellVehicleInfo,
  ExposureElectionStatusBadge
} from '../../../exposureElections/components';
import { useActiveAccount  } from '../../../../shared/activeAccount';
import { HideFromRolesAndTypes } from '../../../../shared/activeAccount/components';

export default function VehicleCoverageTableRow({ coverage, policy, fetchNewestCoverages }) {
  const {
    ROLES: { OBSERVER },
    TYPES: { CLAIMS_ADJUSTER }
  } = useActiveAccount();

  return (
    <Tr className="bg-white">
      <Td>
        <ExposureElectionStatusBadge election={coverage} />
      </Td>
      <Td>
        <CellVehicleInfo {...coverage} />
      </Td>
      <Td>
        <CellOwnerContact {...coverage} />
      </Td>
      <Td>
        <CellPricingOptionAndAddOns {...coverage} />
      </Td>
      <CellTotal colSpan="1" election={coverage} />
      <CellCoverageDates election={coverage} />
      <HideFromRolesAndTypes roles={[OBSERVER]} types={[CLAIMS_ADJUSTER]}>
        <Td>
          <CellActions election={coverage} policy={policy} fetchNewestInsureds={fetchNewestCoverages} />
        </Td>
      </HideFromRolesAndTypes>
    </Tr>
  );
}
