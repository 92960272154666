import { gql, useQuery } from '@apollo/client';

export const GET_CLIENT_DOCUMENTS = gql`
  query clientDocuments($id: ID!) {
    clientDocuments(clientId: $id) {
      id
      name
      clientId
      effectiveDate
      expirationDate
      document {
        id
        url
        type
        filename
      }
      policies {
        id
        name
        archived
        renewedToPolicyId
        effectiveStatus
      }
    }
  }
`;

export default function useClientDocuments({ clientId, onCompleted = () => {}, onError = () => {} }) {
  const { loading, error, data } = useQuery(GET_CLIENT_DOCUMENTS, {
    variables: { id: clientId },
    onCompleted,
    onError
  });

  return {
    loading,
    error,
    documents: data?.clientDocuments || []
  };
}
