import { RenderData, TrLoader, TrPlaceholder } from '@oforce/global-components';
import { useState } from 'react';
import { BoxContainer, Table, TrGraphQLErrors } from '../../../components';
import { TablePagination } from '../../../components/tables';
import { useVehicles } from '../hooks';
import VehicleRow from './VehicleRow';
import VehiclesFilters from './VehiclesFilters';

export default function VehiclesTable() {
  const colSpan = 8;

  const orderOptions = [
    { label: 'VIN', value: 'VIN' },
    { label: 'Gross Vehicle Weight', value: 'VEHICLE_WEIGHT' },
    { label: 'Unit Number', value: 'UNIT_NUMBER' },
    { label: 'Vehicle Value', value: 'VALUE' }
  ];

  const [filters, setFilters] = useState({ status: 'ACTIVE', ordering: orderOptions[0]?.value });

  const { vehicles, loading, error, paginationProps } = useVehicles({
    filters
  });

  return (
    <>
      <VehiclesFilters orderOptions={orderOptions} filters={filters} setFilters={setFilters} />
      <BoxContainer>
        <Table responsive>
          <thead>
            <tr>
              <th></th>
              <th>Vehicle</th>
              <th>Active Elections</th>
              <th>Owner/Business Name</th>
              <th>Dispatch Location</th>
              <th>Unit Number</th>
              <th>Gross Vehicle Weight</th>
              <th>Vehicle Value</th>
            </tr>
          </thead>
          <tbody>
            <RenderData
              data={vehicles}
              loading={loading}
              error={error}
              loadingComponent={<TrLoader colSpan={colSpan} />}
              errorComponent={<TrGraphQLErrors colSpan={colSpan} error={error} />}
              placeholderComponent={<TrPlaceholder colSpan={colSpan}>No Vehicles</TrPlaceholder>}
            >
              {vehicles => vehicles?.map(vehicle => <VehicleRow vehicle={vehicle} key={vehicle?.id} />)}
            </RenderData>
          </tbody>
        </Table>
      </BoxContainer>
      <TablePagination {...paginationProps} loading={loading} />
    </>
  );
}
