import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { PageHeader, PageTitle, PageBody } from '../../components/page-components';

import { TransactionsList } from './components';

export default function Transactions() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Helmet>
          <title>Transactions • IC Insure</title>
        </Helmet>
        <PageHeader>
          <PageTitle>Transactions</PageTitle>
        </PageHeader>
        <PageBody>
          <TransactionsList />
        </PageBody>
      </Route>
    </Switch>
  );
}
