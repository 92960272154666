import React from 'react';
import { Badge, Render, TrPlaceholder } from '@oforce/global-components';
import { Table } from '.';
import styled from 'styled-components';
import { UncontrolledTooltip } from 'reactstrap';
import { sentenceToSnakeCase } from '../../utils/formatting';

const LabelCell = styled.td`
  width: 150px;
`;
const ValueCell = styled.td`
  text-align: start;
`;

export default function CellTable({ rows }) {
  const colSpan = 2;

  return (
    <div className="d-flex">
      <Table>
        <tbody>
          {rows.length === 0 ? (
            <TrPlaceholder colSpan={colSpan} className="fs-xs">
              None
            </TrPlaceholder>
          ) : (
            rows.map(
              ({ label, value, numState = null, stateList = false, id = null, labelIncludeExclude = null }, i) => {
                return (
                  <tr key={`${label}-${value}-${i}`}>
                    <LabelCell className="p-0 font-weight-300 fs-xs text-muted">{label}</LabelCell>
                    <Render if={!stateList}>
                      <ValueCell className="p-0 font-weight-400 fs-xs">{value}</ValueCell>
                    </Render>
                    <Render if={stateList}>
                      <ValueCell className="p-0 font-weight-400 fs-xs">
                        <Badge
                          id={`requiredStatesTooltipId-${sentenceToSnakeCase(label)}-${id}-${i}`}
                          size="sm"
                          color="secondary"
                          className="cursor-pointer mt-1 mb-1"
                          iconAfter={['far', 'info-circle']}
                        >
                          {labelIncludeExclude} ({numState}) States
                        </Badge>
                        <UncontrolledTooltip
                          target={`requiredStatesTooltipId-${sentenceToSnakeCase(label)}-${id}-${i}`}
                          placement="bottom"
                        >
                          {value}
                        </UncontrolledTooltip>
                      </ValueCell>
                    </Render>
                  </tr>
                );
              }
            )
          )}
        </tbody>
      </Table>
    </div>
  );
}
