import React from 'react';
import { Steps } from 'react-step-builder';
import { InsuredDetailsStep, VehicleStep, VinValidationStep, SubmitStep, VehicleStakeholdersStep } from '.';
import InsuredStakeholdersStep from './InsuredStakeholdersStep';
import {
  INSURED_DETAILS,
  VEHICLE_DETAILS,
  VIN_VALIDATION,
  VEHICLE_STAKEHOLDERS_DETAILS,
  INSURED_STAKEHOLDERS_DETAILS,
  SUBMIT
} from './stepConstants';

export default function ExposureSteps({ formProps, stepsToRender }) {
  const insuredDetailsStep = <InsuredDetailsStep key={INSURED_DETAILS} {...formProps} />;
  const insuredStakeholdersStep = <InsuredStakeholdersStep key={INSURED_STAKEHOLDERS_DETAILS} {...formProps} />;
  const vinValidationStep = <VinValidationStep key={VIN_VALIDATION} {...formProps} />;
  const vehicleDetailsStep = (
    <VehicleStep disableVinUpdate={stepsToRender.includes(VIN_VALIDATION)} key={VEHICLE_DETAILS} {...formProps} />
  );
  const vehicleStakeholdersStep = <VehicleStakeholdersStep key={VEHICLE_STAKEHOLDERS_DETAILS} {...formProps} />;
  const submitStep = <SubmitStep key={SUBMIT} {...formProps} />;
  let stepComponents = [];
  if (stepsToRender.includes(INSURED_DETAILS)) stepComponents.push(insuredDetailsStep);
  if (stepsToRender.includes(INSURED_STAKEHOLDERS_DETAILS)) stepComponents.push(insuredStakeholdersStep);
  if (stepsToRender.includes(VIN_VALIDATION)) stepComponents.push(vinValidationStep);
  if (stepsToRender.includes(VEHICLE_DETAILS)) stepComponents.push(vehicleDetailsStep);
  if (stepsToRender.includes(VEHICLE_STAKEHOLDERS_DETAILS)) stepComponents.push(vehicleStakeholdersStep);
  if (stepsToRender.includes(SUBMIT)) stepComponents.push(submitStep);

  return <Steps>{stepComponents}</Steps>;
}
