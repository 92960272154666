import React, { useState } from 'react';
import { Td, Tr, TrPlaceholder, TrLoader, RenderData } from '@oforce/global-components';
import { Table } from '../../../components/table-2.0';
import { TrGraphQLErrors } from '../../../components';
import { CustomInput } from 'reactstrap';

export default function SyncPoliciesTable({ allPolicyIds, policies, loading, error, formik }) {
  const colSpan = 2;
  const [selectAll, setSelectAll] = useState(true);

  const toggleSelectAll = selectAll => {
    !selectAll ? formik?.setFieldValue('policyIds', allPolicyIds) : formik?.setFieldValue('policyIds', []);
    setSelectAll(!selectAll);
  };

  return (
    <Table responsive>
      <thead>
        <tr>
          <th>
            <CustomInput
              id="toggleSelectAllpolicyIds"
              type="checkbox"
              checked={selectAll}
              onChange={() => toggleSelectAll(selectAll)}
              className="mb-1"
            />
          </th>
          <th>Name</th>
        </tr>
      </thead>
      <tbody>
        <RenderData
          data={policies}
          loading={loading}
          error={error}
          loadingComponent={<TrLoader colSpan={colSpan} />}
          errorComponent={<TrGraphQLErrors colSpan={colSpan} error={error} />}
          placeholderComponent={<TrPlaceholder colSpan={colSpan}>No Policies</TrPlaceholder>}
          style={{ border: '0px!important' }}
        >
          {policies =>
            policies?.map(policy => (
              <Tr key={policy?.id}>
                <Td>
                  <CustomInput
                    id={policy?.id}
                    type="checkbox"
                    checked={formik?.values?.policyIds?.includes(policy?.id) || false}
                    onChange={() => {
                      if (formik?.values?.policyIds?.includes(policy?.id)) {
                        formik?.setFieldValue(
                          'policyIds',
                          formik?.values?.policyIds?.filter(policyId => policyId !== policy?.id)
                        );
                      } else {
                        formik?.setFieldValue('policyIds', [...formik?.values?.policyIds, policy?.id]);
                      }
                    }}
                  />
                </Td>
                <Td>{policy?.name}</Td>
              </Tr>
            ))
          }
        </RenderData>
      </tbody>
    </Table>
  );
}
