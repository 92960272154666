import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { DeleteModal } from '../../../components/modals';
import { FieldMessage } from '@oforce/global-components';
import { LargeIcon } from '../../../components/message-box';

const HARD_DELETE_PRODUCT_DOCUMENT = gql`
  mutation hardDeleteProductDocument($productDocumentId: ID!) {
    hardDeleteProductDocument(productDocumentId: $productDocumentId) {
      id
      documents {
        id
        name
        filename
        url
        appliesToAllPricingOptions
        pricingOptionIds
        type
        stateSource
        appliesToStates
      }
    }
  }
`;

export default function HardDeleteProductDocument({ doc, isOpen, toggle }) {
  const [hardDeleteProductDocument, { loading, error }] = useMutation(HARD_DELETE_PRODUCT_DOCUMENT, {
    onCompleted: toggle,
    onError: () => {}
  });

  return (
    <DeleteModal
      isOpen={isOpen}
      toggle={toggle}
      entityType="Product Document"
      entityName={doc?.name}
      deleting={loading}
      error={error}
      submitButtonText={'Delete'}
      cancelButtonText={'Cancel'}
      onSubmit={() => hardDeleteProductDocument({ variables: { productDocumentId: doc?.id } })}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }} className="mt-3 mb-3">
        <LargeIcon icon="exclamation-triangle" color="danger" style={{ marginRight: '8px' }} />
        <FieldMessage color="danger">
          <div className="fs-md pt-0">
            This will <b>permanently delete</b> every instance of the product document and cannot be undone.
          </div>
        </FieldMessage>
      </div>
    </DeleteModal>
  );
}
