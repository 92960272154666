import { React } from 'react';
import { useToggle } from '@oforce/global-components';

import { TerminateInsuredElections } from '../containers';
import ButtonWithTooltip from '../../../components/ButtonWithTooltip';

export default function TerminateAllButton({ insured, insuredTypeElections = [], vehicleTypeElections = [] }) {
  const [isOpen, toggle] = useToggle(false);

  const hasOutOfCompliance =
    [...insuredTypeElections, ...vehicleTypeElections].filter(election => election?.compliance?.inCompliance === false)
      .length > 0;

  return (
    <>
      <ButtonWithTooltip
        id="terminate-all-button-span"
        disabled={hasOutOfCompliance}
        color="info"
        onClick={toggle}
        className="ml-3"
        tooltipText={'At least one exposure election is out of compliance.'}
      >
        Update All Termination Dates
      </ButtonWithTooltip>

      {isOpen && <TerminateInsuredElections isOpen={isOpen} toggle={toggle} insuredId={insured?.id} />}
    </>
  );
}
