import React from 'react';
import { Button } from '@oforce/global-components';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { GraphQLErrors } from '..';

export default function ConfirmModal({
  isOpen,
  toggle,
  onConfirm = () => {},
  onCancel,
  confirmButtonText = 'Yes, Continue',
  confirmButtonProps,
  cancelButtonText = 'No, Cancel',
  cancelButtonProps,
  icon = ['far', 'exclamation-triangle'],
  iconColor = 'warning',
  children,
  title = 'Are you sure?',
  error,
  ...rest
}) {
  return (
    <Modal isOpen={isOpen} toggle={toggle} {...rest} centered>
      <ModalBody>
        <div className="text-center">
          <FontAwesomeIcon icon={icon} className={`text-${iconColor} mb-3`} size="2x" />
          <h4 className="mb-4">{title}</h4>
        </div>
        <GraphQLErrors error={error} />
        <div className="text-left">{children}</div>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between border-top-0">
        <Button color="secondary" onClick={onCancel || toggle} {...cancelButtonProps}>
          {cancelButtonText}
        </Button>
        <Button color="primary" onClick={onConfirm} {...confirmButtonProps}>
          {confirmButtonText}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
