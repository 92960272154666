import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import { FieldLabel } from '@oforce/global-components';

const StyledSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 77px;
  height: 20px;
  background-color: ${props => props.theme.gray400};
  border-radius: 1000px;
  cursor: pointer;

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    background: white;
    padding: 0px 14px;
    margin-top: 2px;
    margin-left: 2px;
    height: 16px;
    width: 37px;
    border-radius: 1000px;
    svg {
      margin-top: -4px;
    }
  }

  .slider:before {
    position: absolute;
    left: 4px;
    bottom: 4px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + .slider {
    -webkit-transform: translateX(36px);
    -ms-transform: translateX(36px);
    transform: translateX(36px);
  }

  input:checked {
    ~ .right-side-icon {
      color: ${props => props.theme.gray900};
    }
    ~ .left-side-icon {
      color: ${props => props.theme.gray600};
    }
  }

  input {
    ~ .right-side-icon {
      color: ${props => props.theme.gray600};
      position: absolute;
      right: 15px;
      top: 4px;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
    ~ .left-side-icon {
      color: ${props => props.theme.gray900};
      position: absolute;
      left: 16px;
      top: 3px;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
`;

export default function InputTypeSwitchLabel({ id, name, label, checked, onChange, ...rest }) {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <FieldLabel className="d-inline-flex" name={label} for={name} {...rest} />
      <StyledSwitch id={id}>
        <input type="checkbox" checked={checked} onChange={onChange} />
        <span className="slider"></span>
        <FontAwesomeIcon className="left-side-icon" icon={['fas', 'dollar-sign']} size="sm" />
        <FontAwesomeIcon className="right-side-icon fs-xs" icon={['fas', 'percent']} />
      </StyledSwitch>
    </div>
  );
}
