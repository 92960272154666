import styled from 'styled-components';
import { transparentize } from 'polished';
import { Render } from '@oforce/global-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledSearchIcon = styled.label.attrs({
  className: 'd-flex align-self-stretch align-items-center justify-content-center px-2 mb-0'
})`
  cursor: text;
  color: ${props => props.theme.gray500};
`;
const StyledInputContainer = styled.div.attrs({ className: 'd-flex align-items-center' })`
  background: white;
  border: 2px solid ${props => props.theme.gray400};
  border-radius: 4px;
  overflow: hidden;
  &:hover {
    border: 2px solid ${props => props.theme.gray500};
    ${StyledSearchIcon} {
      color: ${props => props.theme.gray700};
    }
  }
  &:focus-within {
    border: 2px solid ${props => props.theme.info};
    box-shadow: 0px 0px 0px 3px ${props => transparentize(0.85, props.theme.info)};
    ${StyledSearchIcon} {
      color: ${props => props.theme.info};
    }
  }
`;

const StyledClearButton = styled.button.attrs({
  className: 'd-flex align-items-center justify-content-center'
})`
  background: white;
  border: 0;
  border-radius: 1000px !important;
  width: 22px;
  height: 22px;
`;

const StyledInputClear = styled.div.attrs({
  className: 'px-2 d-flex align-self-stretch align-items-center justify-content-center'
})`
  position: relative;
  cursor: pointer;
  color: ${props => props.theme.gray500};

  &:hover {
    ${StyledClearButton} {
      color: ${props => props.theme.gray600};
      background: ${props => props.theme.gray300};
    }
  }

  &:active {
    ${StyledClearButton} {
      color: ${props => props.theme.gray700};
      background: ${props => props.theme.gray400};
    }
  }
`;

const StyledInput = styled.input.attrs({ className: 'flex-fill' })`
  padding: 0.5rem 0 0.25rem 0;
  height: 38px;
  border: 0;
  outline: 0;
`;

export default function FilterSearch({ id, iconSize, value = '', onClear, className, bottomMargin = 'mb-3', ...rest }) {
  return (
    <StyledInputContainer className={`${className} ${bottomMargin}`}>
      <StyledSearchIcon htmlFor={id}>
        <FontAwesomeIcon size={iconSize} icon={['far', 'search']} fixedWidth />
      </StyledSearchIcon>
      <StyledInput id={id} value={value} type="text" {...rest} />
      <Render if={!!value}>
        <StyledInputClear>
          <StyledClearButton onClick={onClear}>
            <FontAwesomeIcon icon={['far', 'times']} fixedWidth />
          </StyledClearButton>
        </StyledInputClear>
      </Render>
    </StyledInputContainer>
  );
}
