import React from 'react';
import { Button, Loader } from '@oforce/global-components';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { TabPage } from '../../components/tabs';
import { PolicyPricingCreateUpdate } from '../../views';
import { BoxContainer, GraphQLErrors } from '../../components';
import { ShowForTypes, useActiveAccount } from '../../shared/activeAccount';
import { usePolicyPricings } from './hooks';
import { PricingUpdate, PricingsTable } from './components';
import { HideFromRolesAndTypes } from '../../shared/activeAccount/components';

export default function PolicyPricing({ policy }) {
  const history = useHistory();
  const { url, path } = useRouteMatch();

  const {
    TYPES: { CARRIER, BROKER, CLAIMS_ADJUSTER },
    ROLES: { EXPOSURE_MANAGER, OBSERVER }
  } = useActiveAccount();

  const { pricings, loading, error } = usePolicyPricings({
    policyId: policy?.id
  });

  if (loading) return <Loader className={'loading p-3 p-md-5'} />;
  if (error) return <GraphQLErrors error={error} />;

  return (
    <Switch>
      <Route exact path={path}>
        <TabPage
          title="Pricing"
          buttons={
            <ShowForTypes types={[CARRIER, BROKER]}>
              <HideFromRolesAndTypes types={[CLAIMS_ADJUSTER]} roles={[EXPOSURE_MANAGER, OBSERVER]}>
                <Button color="info" icon={['far', 'plus']} className="ml-2" onClick={() => history.push(`${url}/new`)}>
                  Create
                </Button>
              </HideFromRolesAndTypes>
            </ShowForTypes>
          }
        >
          <BoxContainer>
            <PricingsTable pricings={pricings} policy={policy} />
          </BoxContainer>
        </TabPage>
      </Route>
      <Route path={`${url}/new`}>
        <PolicyPricingCreateUpdate policyId={policy?.id} />
      </Route>
      <Route path={`${url}/:pricingId/update`}>
        <PricingUpdate pricings={pricings} policyId={policy?.id} />
      </Route>
    </Switch>
  );
}
