import React from 'react';
import { Button } from '@oforce/global-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDocumentDownloadJobContext } from '../../../context';

export default function UpdateExposuresFileTemplateLink({ policy }) {
  const { startExposureUpdateTemplateJob, jobId, loading } = useDocumentDownloadJobContext();

  if (loading)
    return (
      <>
        <FontAwesomeIcon icon={['far', 'spinner-third']} spin className="mr-2 mb-1" />
        Downloading Template
      </>
    );

  return (
    <Button
      color="link"
      size="md"
      iconAfter={['far', 'arrow-to-bottom']}
      loading={loading}
      loadingText="Preparing Template..."
      disabled={!!jobId}
      onClick={() => startExposureUpdateTemplateJob(policy?.id)}
    >
      Download Exposure Update Template
    </Button>
  );
}
