import React from 'react';
import cn from 'classnames';

export default function PageHeader({ withTabs, className, children }) {
  const borderClasses = 'border-bottom border-width-2 border-gray-400';
  const backgroundClasses = 'bg-white';

  const classes = cn('pt-2', { [borderClasses]: !withTabs }, backgroundClasses, className);

  return <div className={classes}>{children}</div>;
}
