import React from 'react';

import { TabPage } from '../../components/tabs';
import { Render } from '../../components';

import { InsuredRequiredFields, VehicleRequiredFields } from './containers';
import { useInsuranceType } from '../policy/hooks';
import { useActiveAccount } from '../../shared/activeAccount';

export default function PolicyRequiredFields({ policy }) {
  const { insuranceType } = useInsuranceType({ policyId: policy?.id });
  const {
    roleIsBelow,
    typeIsBelow,
    ROLES: { COLLABORATOR },
    TYPES: { BROKER }
  } = useActiveAccount();

  // require the state_of_registration field if there are one or more insurance type state fees
  const requireStateOfRegistration = insuranceType?.stateFees?.length > 0 && !!policy?.includeStateFees;
  const insuranceTypeRequiresVehicle = insuranceType?.requireVehicle;

  // if role is exposure manager or policy manager, then role is below collaborator
  const roleBelowCollaborator = roleIsBelow(COLLABORATOR);
  const typeBelowBroker = typeIsBelow(BROKER);

  return (
    <TabPage title="Required Fields" showBackButton>
      <InsuredRequiredFields
        policyId={policy?.id}
        roleBelowCollaborator={roleBelowCollaborator}
        typeBelowBroker={typeBelowBroker}
      />

      <Render if={insuranceTypeRequiresVehicle}>
        <VehicleRequiredFields
          policyId={policy?.id}
          requireStateOfRegistration={requireStateOfRegistration}
          roleBelowCollaborator={roleBelowCollaborator}
          typeBelowBroker={typeBelowBroker}
        />
      </Render>
    </TabPage>
  );
}
