import React from 'react';
import { useHistory } from 'react-router-dom';
import { INSURANCE_TYPES } from '../configurationInsuranceTypes';
import { CoverageForm } from '../coverages/components';

export default function InsuredCreate() {
  const history = useHistory();

  return (
    <CoverageForm
      title="Create Insured"
      onCancel={() => history.push('/insureds')}
      renderSelectOrCreateVehicleStep={false}
      renderSelectOrCreateInsuredStep={false}
      policyInsuranceTypes={[INSURANCE_TYPES.INSURED]}
    />
  );
}
