import React from 'react';
import styled from 'styled-components';
import { transparentize } from 'polished';
import { theme } from '@oforce/global-styles';
const { blue, gray800 } = theme;

const StyledTr = styled.tr`
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
  &:hover {
    background: ${props =>
      props.onClick && transparentize(0.95, blue)} !important;
  }
  &:active {
    color: ${props => (props.onClick ? blue : gray800)};
  }
`;

export default function Tr({ children, placeholderText = 'None', ...rest }) {
  return <StyledTr {...rest}>{children}</StyledTr>;
}
