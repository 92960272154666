import React from 'react';
import { GlobalFonts } from '@oforce/global-styles';
import { Route, Switch } from 'react-router-dom';

import { Graphiql } from './graphiql';
import { usePageViews } from './hooks';
import { GlobalNavigation } from './shared';
import Routes, { AdminRoute } from './routes';
import { useCurrentUser } from './shared/currentUser';
import { Render, AppLayout, ContentLayout } from './components';
import { useGlobalNav } from './shared/globalNavigation/hooks';
import Subscriptions from './views/subscriptions';

function App() {
  usePageViews();
  const { menuItems } = useGlobalNav();
  const { userAccounts, isAdmin, ...currentUser } = useCurrentUser();

  return (
    <>
      <Switch>
        <AdminRoute exact path="/graphiql">
          <Graphiql />
        </AdminRoute>
        <Route path="/">
          <GlobalFonts />
          <AppLayout>
            <Render if={!!currentUser}>
              <GlobalNavigation />
            </Render>
            <Subscriptions />
            <ContentLayout menuItems={menuItems}>
              <Routes />
            </ContentLayout>
          </AppLayout>
        </Route>
      </Switch>
    </>
  );
}

export default App;
