import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Badge } from '@oforce/global-components';

import { LabelValueCell } from '../../../components/table-2.0';
import { formatCurrency, formatDate, titleCase } from '../../../utils/formatting';

import { ProductElectionCell } from '../components';
import { useTransactions } from '.';
import { formatPricingWithLabel } from '../../exposureElections/utils';

export const useTransactionsTable = () => {
  const {
    clients,
    data,
    error,
    hasNextPage,
    hasPreviousPage,
    loadNextPage,
    loadPreviousPage,
    loading,
    setClientFilter,
    setNameFilter,
    setInvoiceIdFilter,
    setSSNFilter,
    setDriversLicenseNumberFilter,
    setDateRange,
    setInvoiceDate
  } = useTransactions();

  const columns = useMemo(
    () => [
      {
        Header: 'Invoice',
        accessor: ({ invoice: { id } }) => id,
        Cell: ({ row: { original } }) => (
          <>
            <Link to={`/invoices/${original?.invoice?.id}`} className="text-info">
              {original?.invoice?.id}
            </Link>
          </>
        ),
        sortType: 'alphanumeric'
      },
      {
        Header: 'Insured',
        Cell: ({ row: { original } }) => (
          <Link to={`/insureds/${original?.insuredId}`} className="text-info">
            {original?.lastName}, {original?.firstName}
          </Link>
        ),
        accessor: ({ firstName, lastName }) => `${lastName}, ${firstName} `,
        sortType: 'basic'
      },
      {
        Header: 'Product Election',
        Cell: ({ row: { original } }) => (
          <ProductElectionCell
            name={original?.productName}
            label={original?.label}
            addOnsSource={original?.addOnsSource}
          />
        ),
        accessor: ({ productName, label }) => `${productName} ${label}`,
        sortType: 'basic'
      },
      {
        Header: 'Premium',
        accessor: ({ premium }) => formatCurrency(premium),
        Cell: ({ row: { original } }) => <LabelValueCell label={formatCurrency(original?.premium)} />,
        sortType: 'alphanumeric'
      },
      {
        Header: 'Premium Factor',
        accessor: ({ premium }) => formatCurrency(premium),
        Cell: ({ row: { original } }) => (
          <FactorCell
            factorLabel={original?.factorLabel}
            factorValue={original?.factorValue}
            premiumSource={original?.premiumSource}
          />
        ),
        sortType: 'alphanumeric'
      },
      {
        Header: 'Fee',
        accessor: ({ fee }) => formatCurrency(fee),
        sortType: 'alphanumeric'
      },
      {
        Header: 'Add-Ons',
        accessor: ({ addOns }) => formatCurrency(addOns),
        sortType: 'alphanumeric'
      },
      {
        Header: 'Client',
        Cell: ({ row: { original } }) => (
          <Link to={`/clients/${original?.invoice?.client?.id}`} className="text-info">
            {original?.invoice?.client?.name}
          </Link>
        ),
        accessor: ({ invoice }) => invoice?.client?.name,
        sortType: 'basic'
      },
      {
        Header: 'CDL/DL',
        Cell: ({ cell }) => <span>{cell.value}</span>,
        accessor: ({ insured }) => insured?.driversLicenseNumber,
        sortType: 'basic'
      },
      {
        Header: 'SSN',
        Cell: ({ row: { original } }) => (original?.ssnLastFour ? `XXX-XX-${original?.ssnLastFour}` : null),
        accessor: ({ ssnLastFour }) => ssnLastFour,
        sortType: 'alphanumeric'
      },
      {
        Header: 'Coverage Start Date',
        Cell: ({ cell }) => <span className="fs-sm font-italic">{cell.value}</span>,
        accessor: ({ coverageDates }) => formatDate(coverageDates?.startDate)
      },
      {
        Header: 'Coverage End Date',
        Cell: ({ cell }) => <span className="fs-sm font-italic">{cell.value}</span>,
        accessor: ({ coverageDates }) => formatDate(coverageDates?.endDate)
      },
      {
        Header: 'Invoiced',
        Cell: ({ cell }) => <span className="fs-sm font-italic">{cell.value}</span>,
        accessor: ({ invoice: { invoiceDate } }) => formatDate(invoiceDate)
      },
      {
        Header: 'Status',
        Cell: ({ row: { original }, cell }) => {
          const color = { requested: 'gray600', paid: 'green' }[original?.status];
          return <Badge color={color}>{cell.value}</Badge>;
        },
        accessor: ({ status }) => titleCase(status),
        sortType: 'basic'
      }
    ],
    []
  );

  const FactorCell = ({ premiumSource: { rate, rateMode, isPercentageFactor } = {}, factorValue, factorLabel }) => {
    const text =
      factorValue && factorLabel
        ? `${formatPricingWithLabel({
            rate,
            rateMode,
            isPercentageFactor,
            factoredField: factorLabel
          })}: ${factorValue}`
        : null;

    return text ? <span className="font-weight-300 fs-xs text-muted">{text}</span> : <EmptyCell />;
  };

  const EmptyCell = ({ text = 'None' }) => <div className="font-weight-300 fs-xs font-italic text-muted">{text}</div>;

  return {
    reactTableProps: {
      data,
      columns,
      defaultColumn: 'Insured',
      initialState: {
        sortBy: [{ id: 'Invoice Id', desc: false }]
      },
      disableSortRemove: true,
      disableMultiSort: true
    },
    clients,
    error,
    hasNextPage,
    hasPreviousPage,
    loadNextPage,
    loadPreviousPage,
    loading,
    setClientFilter,
    setInvoiceIdFilter,
    setNameFilter,
    setDriversLicenseNumberFilter,
    setSSNFilter,
    setDateRange,
    setInvoiceDate
  };
};
