import moment from 'moment';
import { gql, useMutation } from '@apollo/client';
import * as Insured from './../model';
import { ALL_INSURED_STAKEHOLDER_FIELDS } from '../../insuredStakeholder/model/fragments';
import { prepareValuesForSubmit, prepStakeholderAttrs } from '../../stakeholder/utils';

const UPDATE_INSURED = gql`
  mutation updateInsured(
    $id: ID!
    $address: String
    $city: String
    $ssn: String
    $businessName: String
    $businessName2: String
    $country: Country
    $dateOfBirth: Date
    $email: String
    $firstName: String
    $middleName: String
    $lastName: String
    $mobilePhone: String
    $state: String
    $zip: String
    $driversLicenseState: ClientProductState
    $driversLicenseNumber: String
    $dispatchLocation: String
    $beneficiary: InsuredStakeholderInput
  ) {
    updateInsured(
      id: $id
      address: $address
      city: $city
      ssn: $ssn
      businessName: $businessName
      businessName2: $businessName2
      country: $country
      dateOfBirth: $dateOfBirth
      driversLicenseNumber: $driversLicenseNumber
      driversLicenseState: $driversLicenseState
      email: $email
      firstName: $firstName
      middleName: $middleName
      lastName: $lastName
      mobilePhone: $mobilePhone
      state: $state
      zip: $zip
      dispatchLocation: $dispatchLocation
      beneficiary: $beneficiary
    ) {
      id
      firstName
      middleName
      lastName
      businessName
      businessName2
      address
      ssnLastFour
      city
      state
      country
      dateOfBirth
      zip
      email
      mobilePhone
      insertedAt
      updatedAt
      driversLicenseNumber
      driversLicenseState
      dispatchLocation
      elections {
        id
        pricingOption {
          id
          label
        }
      }
      beneficiary {
        ...allInsuredStakeholderFields
      }
    }
  }
  ${ALL_INSURED_STAKEHOLDER_FIELDS}
`;

export default function useUpdateInsuredForm({ insured, formOptions, onCompleted, onError = () => {} }) {
  const [update, { loading, error, reset }] = useMutation(UPDATE_INSURED, {
    onCompleted,
    onError
  });

  const initialValues = Insured.initialValues({ insured, formOptions });

  const handle_ssn = (attrs, ssn) => {
    if (ssn && ssn?.startsWith('X')) {
      return attrs;
    } else if (ssn) {
      return { ...attrs, ssn: ssn?.split('-').join('') };
    }

    return attrs;
  };

  const handleSubmit = ({ dateOfBirth, ssn, state, country, driversLicenseState, ...values }) => {
    const beneficiaryAttrs = prepStakeholderAttrs(values, 'beneficiary');
    const attrs = {
      id: values?.id,
      dateOfBirth: dateOfBirth ? moment(dateOfBirth, 'MM/DD/YYYY').format('YYYY-MM-DD') : null,
      state: state?.value ? state?.value : null,
      driversLicenseState: driversLicenseState?.value ? driversLicenseState?.value : null,
      country: country?.value ? country?.value : '',
      beneficiary: prepareValuesForSubmit(beneficiaryAttrs),
      ...values
    };

    const variables = handle_ssn(attrs, ssn);

    update({ variables });
  };

  return {
    initialValues,
    handleSubmit,
    loading,
    error,
    reset
  };
}
