import React from 'react';
import styled from 'styled-components';

const StyledDot = styled.span.attrs({
  className: 'rounded-pill d-inline-block'
})`
  background: ${props => props.theme[props.color || 'gray400']};
  width: 10px;
  height: 10px;
`;

export default function StatusDot(props) {
  return <StyledDot {...props} />;
}
