import { gql, useQuery } from '@apollo/client';
import { useNormalizeCoverageParameterTypes } from '.';
import { backendNameToLabel } from '../../../utils/formatting';

const PARAM_TYPES = gql`
  query getCoverageParameterTypes {
    types: coverageParameterTypes
    valueTypes: coverageParameterValueTypes
    predefinedCoverageParameters: validatableCoverageParameters {
      label: name
      types: typeOptions
      valueTypes: valueTypeOptions
    }
  }
`;

export default function useCoverageParameterTypes() {
  const { data, loading, error } = useQuery(PARAM_TYPES, { nextFetchPolicy: 'cache-first' });
  const { getValueTypeName } = useNormalizeCoverageParameterTypes();

  const types = data?.types || [];
  const valueTypes = data?.valueTypes || [];
  const predefinedParams = data?.predefinedCoverageParameters || [];
  const predefinedParamLabels = predefinedParams?.map(({ label }) => label) || [];

  const rangeValueTypes = ['CURRENCY', 'INTEGER', 'DATE'];
  const listValueTypes = valueTypes.filter(valueType => valueType !== 'BOOLEAN');

  const createParamOptions = paramLabels => paramLabels.map(value => ({ label: backendNameToLabel(value), value }));

  const createTypeSelectOptions = types =>
    types.filter(value => value !== 'INDIVIDUAL').map(value => ({ label: backendNameToLabel(value), value }));

  const createValueTypeSelectOptions = types => types.map(value => ({ label: getValueTypeName(value), value }));

  const getValueTypeOptions = type => {
    if (type === 'RANGE') return createValueTypeSelectOptions(rangeValueTypes);
    if (type === 'LIST') return createValueTypeSelectOptions(listValueTypes);
    return createValueTypeSelectOptions(valueTypes);
  };

  const getPredefinedTypeOptions = paramLabel => {
    const param = predefinedParams.find(({ label }) => label === paramLabel);
    const typeOptions = createTypeSelectOptions(param.types);
    const valueTypeOptions = createValueTypeSelectOptions(param.valueTypes);

    if (param.types) return [...typeOptions, ...valueTypeOptions];
    return createTypeSelectOptions(types);
  };

  const getPredefinedValueTypeOptions = paramLabel => {
    const param = predefinedParams.find(({ label }) => label === paramLabel);
    if (param.valueTypes) return createValueTypeSelectOptions(param.valueTypes);
    return createValueTypeSelectOptions(valueTypes);
  };

  const predefinedParamsWithOptions = predefinedParams?.reduce((acc, param) => {
    const { label, types, valueTypes } = param;

    return {
      label,
      types,
      valueTypes,
      name: backendNameToLabel(label),
      typeOptions: createTypeSelectOptions(types),
      valueTypeOptions: createValueTypeSelectOptions(valueTypes)
    };
  }, []);

  const typeOptions = createTypeSelectOptions(types);
  const valueTypeOptions = createValueTypeSelectOptions(valueTypes);

  return {
    error,
    loading,
    types,
    valueTypes,
    getValueTypeOptions,
    getPredefinedTypeOptions,
    getPredefinedValueTypeOptions,
    predefinedParamLabels,
    predefinedParamsWithOptions,
    typesNeedingValueType: ['RANGE', 'LIST'],
    typeOptions: [...typeOptions, ...valueTypeOptions],
    valueTypeOptions,
    predefinedParamOptions: createParamOptions(predefinedParamLabels)
  };
}
