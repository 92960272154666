import React from 'react';
import { Form } from 'formik';
import { InputField } from '@oforce/global-components';

export default function VehicleTypeForm({ loading, focusRef }) {
  return (
    <Form>
      <fieldset disabled={loading}>
        <InputField innerRef={focusRef} name="name" label="Name" />
      </fieldset>
    </Form>
  );
}
