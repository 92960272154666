import React from 'react';
import { Row, Col, Alert } from 'reactstrap';
import { Route, Switch, useParams, useRouteMatch, Redirect } from 'react-router-dom';
import { Badge, Loader } from '@oforce/global-components';
import { GraphQLErrors, Render } from '../../components';
import { TabBar, TabLink, TabPane } from '../../components/tabs';
import { PageHeader, PageTitle } from '../../components/page-components';
import { useActiveAccount } from '../../shared/activeAccount';
import { HideFromRolesAndTypes, RouteHiddenFromRolesAndTypes } from '../../shared/activeAccount/components';
import { usePolicy } from './hooks';
import PoliciesContext from '../policies/context';

import {
  PolicyPricing,
  PolicyDetails,
  PolicyManagers,
  PolicyDocuments,
  PolicyInsureds,
  PolicyConfiguration,
  PolicyInvoices
} from '..';

export default function Policy() {
  const { policyId } = useParams();
  const { url, path } = useRouteMatch();
  const { policy, loading, error } = usePolicy({
    id: policyId
  });

  const {
    ROLES: { EXPOSURE_MANAGER, OBSERVER },
    TYPES: { CLAIMS_ADJUSTER }
  } = useActiveAccount();

  if (loading && !policy) return <Loader />;

  if (error) return <GraphQLErrors error={error} />;

  return (
    <PoliciesContext.Provider value={{}}>
      <PageHeader withTabs>
        <PageTitle>
          <span className={policy?.archived ? 'text-gray-700 mr-2' : ''}>{policy?.name}</span>
          <Render if={policy?.archived}>
            <Badge color="secondary" className="font-weight-500">
              Archived
            </Badge>
          </Render>
        </PageTitle>
        <TabBar>
          <TabLink to={`${url}/details`} icon={['far', 'info-square']}>
            Details
          </TabLink>
          <HideFromRolesAndTypes roles={[EXPOSURE_MANAGER, OBSERVER]} types={[CLAIMS_ADJUSTER]}>
            <TabLink to={`${url}/configuration`} icon={['far', 'cog']}>
              Configuration
            </TabLink>
          </HideFromRolesAndTypes>
          <TabLink to={`${url}/pricing`} icon={['far', 'box-usd']}>
            Pricing
          </TabLink>
          <TabLink to={`${url}/documents`} icon={['far', 'file']}>
            Documents
          </TabLink>
          <TabLink to={`${url}/insureds`} icon={['far', 'users']}>
            Insureds
          </TabLink>
          <HideFromRolesAndTypes roles={[OBSERVER]} types={[CLAIMS_ADJUSTER]}>
            {!!policy?.isInvoicing && (
              <TabLink to={`${url}/invoices`} icon={['far', 'file-invoice']}>
                Invoices
              </TabLink>
            )}
            <TabLink to={`${url}/managers`} icon={['far', 'users']}>
              Managers
            </TabLink>
          </HideFromRolesAndTypes>
        </TabBar>
      </PageHeader>

      <Row noGutters className="h-100">
        <Col>
          <TabPane>
            <Render if={policy?.archived}>
              <Alert color="info">
                <span className="font-weight-500">This policy is archived.</span>
                <Render if={!!policy?.archiveNotes}>
                  <br />
                  {policy?.archiveNotes}
                </Render>
              </Alert>
            </Render>
            <Switch>
              <Redirect exact from={`${path}`} to={`${path}/details`} />
              <Route path={`${path}/details`}>
                <PolicyDetails policy={policy} />
              </Route>
              <RouteHiddenFromRolesAndTypes types={[CLAIMS_ADJUSTER]} roles={[OBSERVER]} path={`${path}/configuration`}>
                <PolicyConfiguration policy={policy} />
              </RouteHiddenFromRolesAndTypes>
              <Route path={`${path}/pricing`}>
                <PolicyPricing policy={policy} />
              </Route>
              <Route path={`${path}/documents`}>
                <PolicyDocuments policy={policy} />
              </Route>
              <Route path={`${path}/insureds`}>
                <PolicyInsureds policy={policy} />
              </Route>
              <RouteHiddenFromRolesAndTypes types={[CLAIMS_ADJUSTER]} roles={[OBSERVER]} path={`${path}/invoices`}>
                <PolicyInvoices policy={policy} />
              </RouteHiddenFromRolesAndTypes>
              <RouteHiddenFromRolesAndTypes types={[CLAIMS_ADJUSTER]} roles={[OBSERVER]} path={`${path}/managers`}>
                <PolicyManagers policy={policy} />
              </RouteHiddenFromRolesAndTypes>
            </Switch>
          </TabPane>
        </Col>
      </Row>
    </PoliciesContext.Provider>
  );
}
