import { gql, useMutation } from '@apollo/client';

import { GET_PRODUCT_ADD_ONS } from './useProductAddOns';
import { useAddOnAppliesToOptions, useAddOnRequiredOnOptions } from '../../../views/addOns/hooks';

const CREATE_PRODUCT_ADD_ON = gql`
  mutation createProductAddOn($input: CreateProductAddOnInput!) {
    createProductAddOn(input: $input) {
      __typename
      id
      label
      name
      cost
      appliesToAllPricingOptions
      pricingOptionIds
      requiredOnAllPricingOptions
      requiredForPricingOptionIds
    }
  }
`;

export default function useAddOnCreate({ toggle, product, onError = () => {} }) {
  const [createProductAddOn, { data, loading, error, reset }] = useMutation(CREATE_PRODUCT_ADD_ON, {
    onCompleted: toggle,
    onError,
    refetchQueries: [{ query: GET_PRODUCT_ADD_ONS, variables: { id: product?.id } }]
  });

  const { addOnAppliesToOptions } = useAddOnAppliesToOptions();
  const { addOnRequiredOnOptions } = useAddOnRequiredOnOptions();

  const handleSubmit = ({
    cost,
    label,
    name,
    appliesToAllPricingOptions,
    requiredOnAllPricingOptions,
    pricings,
    requiredForPricings
  }) =>
    createProductAddOn({
      variables: {
        input: {
          productId: product?.id,
          name,
          label,
          cost: formatCost(cost),
          appliesToAllPricingOptions: appliesToAllPricingOptions.value,
          pricingOptionIds: pricings?.map(pricing => pricing?.value),
          requiredOnAllPricingOptions: requiredOnAllPricingOptions.value,
          requiredForPricingOptionIds: requiredForPricings?.map(pricing => pricing?.value)
        }
      }
    });

  function formatCost(cost) {
    if (typeof cost === 'number') return cost;
    return parseFloat(cost.replace('$', ''));
  }

  const initialValues = {
    name: '',
    label: '',
    cost: 0.0,
    appliesToAllPricingOptions: addOnAppliesToOptions.find(x => x.value === true),
    pricings: [],
    requiredOnAllPricingOptions: addOnRequiredOnOptions.find(x => x.value === false),
    requiredForPricings: []
  };

  return {
    data,
    loading,
    error,
    reset,
    initialValues,
    handleSubmit
  };
}
