// ################################
// USE FORM OPTIONS
// This is a hook that makes it easier to compile and manage options for a form.
// You pass it a list of options and their loading/error states, and it will return a clean API that all forms can use.
// This prevents us from having to pass each option and it's loading/error states individually as props to forms, and also keeps us from fetching the options inside forms.
// ################################

export default function useFormOptions({ options = [] }) {
  const formOptions = options?.reduce((acc, optionSet) => {
    return {
      ...acc,
      [optionSet?.name]: {
        options: optionSet?.options || [],
        defaultOptions: optionSet?.defaultOptions || undefined,
        loading: optionSet?.loading || undefined,
        error: optionSet?.error || undefined,
        loadOptions: optionSet?.loadOptions || undefined
      }
    };
  }, {});

  return formOptions;
}

// Example Input
// options: [
//   {
//     name: "companies",
//     options: [...],
//     loading: true,
//     error: undefined
//   },
//   {
//     name: "clientTypes",
//     options: [...],
//     loading: false,
//     error: undefined
//   }
// ]

// Example Return
// const formOptions = useFormOptions({options: [...]});
//
// // formOptions
// {
//   companies: {
//     options: [...],
//     loading: true,
//     error: null
//   },
//   clientTypes: {
//     options: [...],
//     loading: true,
//     error: null
//   },
// }

// This way in your form you can just do:
//
//  <ReactSelectField
//    name="Company"
//    options={formOptions?.companies?.options}
//    isLoading={formOptions?.companies?.loading}
//    error={formOptions?.companies?.error}
//  />
