import { Modal, ReactAsyncSelectField } from '@oforce/global-components';
import { Formik } from 'formik';
import { GraphQLErrors } from '../../../../components';
import useAssignSubcontractRelationship from '../../hooks/useAssignSubcontractRelationship';
import useSubcontractedCoverageFormOptions from '../../hooks/useSubcontractedCoverageFormOptions';
import SubContractedCoverageFormFields from './SubContractedCoverageFormFields';
import { Link } from 'react-router-dom/cjs/react-router-dom';

export default function SubContractedCoverageForm({ election, isOpen, toggle }) {
  const {
    loading: loadingOptions,
    formOptions,
    loadOptions
  } = useSubcontractedCoverageFormOptions({ policyId: election?.policyId, election });

  const { handleSubmit, initialValues, validationSchema, error, loading } = useAssignSubcontractRelationship({
    election,
    toggle
  });

  const CustomMenu = ({ innerRef, innerProps, isDisabled, children }) =>
    !isDisabled ? (
      <div ref={innerRef} {...innerProps} className="customReactSelectMenu">
        {children}
        <Link
          to={{
            pathname: '/coverages/new',
            state: {
              masterId: election?.id,
              renderSelectOrCreateInsuredStep: true,
              renderSelectOrCreateVehicleStep: true,
              election
            }
          }}
        >
          Create
        </Link>
      </div>
    ) : null;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {formik => (
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          title="Add Subcontracted Election"
          submitButtonText="Add"
          onSubmit={formik.handleSubmit}
          submitButtonProps={{
            loading: loading,
            loadingText: 'Assigning...',
            disabled: loadingOptions
          }}
        >
          <GraphQLErrors error={error} />
          <ReactAsyncSelectField
            inputId="subcontractor"
            id="subcontractor"
            name="subcontractor"
            label="Subcontracted Coverage"
            placeholder="Choose or search for coverage..."
            cacheOptions
            loadOptions={loadOptions}
            defaultOptions={formOptions?.coverage?.options}
            defaultValue={formOptions?.coverage?.options[0]}
            loading={loadingOptions}
            noOptionsMessage={CustomMenu}
            required
          />
          <SubContractedCoverageFormFields
            subcontractor={formik?.values?.subcontractor}
            election={election}
            action={'ADD'}
          />
        </Modal>
      )}
    </Formik>
  );
}
