import { createContext } from 'react';

export const InitialState = {
  startNotificationStatusReportJob: () => {},
  clearJobId: () => {},
  jobId: null,
  filename: null,
  url: null,
  message: null,
  loading: false,
  jobErrors: []
};

export const NotificationStatusReportJobContext = createContext(InitialState);

NotificationStatusReportJobContext.displayName = 'NotificationStatusReportJobContext';
