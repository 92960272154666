import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';

const GET_POLICY_INSURANCE_TYPE = gql`
  query policyInsuranceType($id: ID!) {
    policy(id: $id) {
      id
      insuranceType {
        id
        type
        requireVehicle
      }
    }
  }
`;

export default function usePolicyInsuranceType({ policyId, onCompleted = () => {}, onError = () => {} }) {
  const { loading, error, data } = useQuery(GET_POLICY_INSURANCE_TYPE, {
    variables: { id: policyId },
    onCompleted,
    onError
  });

  return {
    insuranceType: data?.policy?.insuranceType,
    loading,
    error
  };
}
