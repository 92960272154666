import { FieldMessage } from '@oforce/global-components';
import { FormSection } from '../../../components';
import { LargeIcon } from '../../../components/message-box';

export default function SyncOptionsInstructions() {
  return (
    <FormSection title="Instructions">
      <p>
        This workflow will sync all the product pricing options you select from this product to the policy pricing
        options on the policies you select.
      </p>
      <p>It will sync the base pricing option fields, vehicle types, and coverage parameters.</p>
      <p>It will not sync add ons, fees, retail commissions, or document assignments.</p>
      <p>
        <b>Please be advised</b>, changes to pricing options will be reflected in the next billing cycle.
      </p>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <LargeIcon icon="exclamation-triangle" color="danger" style={{ marginRight: '8px' }} />
        <FieldMessage color="danger">
          Syncing product pricing options will update your policy pricing options and create any non-existing ones. You
          will not be able to automatically reset those options to their original state.
        </FieldMessage>
      </div>
    </FormSection>
  );
}
