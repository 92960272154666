import React, { useState } from 'react';
import { FilterDropdown, FiltersContainer } from '../../../components';
import InsuredDocumentSearchBy from './InsuredDocumentSearchBy';
import debounce from 'debounce-promise';

export default function InsuredDocumentFilters({ defaults, onChange }) {
  const [filters, setFilters] = useState(defaults);

  const orderOptions = [
    { label: 'Client Name', value: 'CLIENT_NAME' },
    { label: 'Vin', value: 'VIN' }
  ];

  const updateFilter = values => {
    const newFilters = { ...filters, ...values };
    setFilters(newFilters);
    onChange(newFilters);
  };

  return (
    <div>
      <FiltersContainer className="pb-0 mb-0 pt-3">
        <FilterDropdown
          id="ordering"
          className="mr-3"
          headerText="Sort ascending..."
          icon={['far', 'sort-alpha-down']}
          options={orderOptions}
          defaultOption={orderOptions.find(({ value }) => value === filters.ordering)}
          setFilter={option => updateFilter({ ...filters, ordering: option.value })}
          isClearable
        />
        <InsuredDocumentSearchBy onChange={debounce(updateFilter, 500)} />
      </FiltersContainer>
    </div>
  );
}
