import { gql, useQuery } from '@apollo/client';
import { EXPOSURE_RELATED_DOCUMENTS } from '../../document/model/Queries';

export const GET_INSURED_DOCUMENTS = gql`
  query insuredDocuments(
    $id: ID!
    $searchBy: [DocumentRelationshipSearchByInput]
    $ordering: DocumentRelationshipListOrder
  ) {
    insured(id: $id) {
      id
      status
      documents(searchBy: $searchBy, ordering: $ordering) {
        ...exposureRelatedDocuments
      }
    }
  }
  ${EXPOSURE_RELATED_DOCUMENTS}
`;

export default function useInsuredDocuments({ insuredId, filters, status = null }) {
  const { data, loading, error } = useQuery(GET_INSURED_DOCUMENTS, {
    variables: { id: insuredId, status, ...filters }
  });

  return {
    insuredStatus: data?.insured?.status,
    exposureElectionDocuments:
      data?.insured?.documents?.exposureDocuments.map(x => ({
        ...x,
        source: 'EXPOSURE_DOCUMENT',
        exposureDocumentId: x.id
      })) || [],
    documentRelationships:
      data?.insured?.documents?.documentRelationships.map(x => ({
        ...x,
        source: 'DOCUMENT_RELATIONSHIP',
        documentRelationshipId: x.id
      })) || [],
    loading,
    error
  };
}
