import React from 'react';
import { Form } from 'formik';
import { InputField, ReactSelectField } from '@oforce/global-components';
import { GraphQLErrors } from '../../../components';

import { administrativeAreaSelectOptions } from '../../../hooks/forms/useCountries';

import { useFocusOnMount } from '../../../hooks';
import { Col, Row } from 'reactstrap';

export default function ClientForm({ error, formik, loading, formOptions }) {
  const country = formik?.values?.country?.value;
  const focusRef = useFocusOnMount();

  return (
    <>
      <GraphQLErrors error={error} />
      <Form>
        <fieldset disabled={loading}>
          <InputField name="name" innerRef={focusRef} label="Name" placeholder="Enter a client name" />
          <InputField name="email" label="Email" />
          <InputField name="externalId" label="External ID" />
          <InputField name="address1" label="Address Line 1" />
          <InputField name="address2" label="Address Line 2" />
          <Row form>
            <Col>
              <InputField name="city" label="City" />
            </Col>
            <Col>
              <ReactSelectField
                name="state"
                label="State"
                options={administrativeAreaSelectOptions(country, formOptions)}
                isClearable
              />
            </Col>
            <Col>
              <InputField name="zip" label="Zip Code" />
            </Col>
          </Row>
          <ReactSelectField
            id="country"
            name="country"
            label="Country"
            options={formOptions?.country?.options}
            onChange={() => formik?.setFieldValue('state', null)}
          />
        </fieldset>
      </Form>
    </>
  );
}
