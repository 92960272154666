import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { Loader } from '@oforce/global-components';

import { GraphQLErrors } from '../../../components';
import { LargeIcon, Message, MessageBox, Title } from '../../../components/message-box';
import { useCurrentUser } from '../../../shared/currentUser';
import { useActiveAccount } from '../../../shared/activeAccount';

const OPEN_POLICY_INVITATION = gql`
  mutation OpenPolicyInvitation($invitationId: ID!, $userId: ID!) {
    invitation: openPolicyInvitation(invitationId: $invitationId, userId: $userId) {
      id
      role
      policy {
        name
      }
    }
  }
`;

export default function PolicyInvitation({ id }) {
  const history = useHistory();
  const { id: userId } = useCurrentUser();
  const activeAccount = useActiveAccount();

  const [open, { data, loading, error }] = useMutation(OPEN_POLICY_INVITATION, {
    variables: { invitationId: id, userId },
    onCompleted: () => {
      activeAccount.refetchUser();
    },
    onError: () => {}
  });

  useEffect(() => {
    if (id && userId && !loading && !error && !data) {
      open();
    }
  }, [data, error, id, userId, loading, open]);

  useEffect(() => {
    if (!loading && !error && data?.invitation) history.replace(`/my-profile/invitations`);
  }, [data, history, error, loading, id]);

  if (loading) return <Loader />;

  return error ? (
    <div className="h-100 d-flex align-self-center justify-self-center">
      <MessageBox className="align-self-center justify-self-center">
        <LargeIcon icon="exclamation-triangle" color="warning" />
        <Title>Oops!</Title>
        <Message className="mb-3">Oops, it looks like there is a problem with this invitation.</Message>
        <GraphQLErrors error={error} />
      </MessageBox>
    </div>
  ) : (
    <></>
  );
}
