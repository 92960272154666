import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';

const ADD_PRODUCT_FEE_TAG = gql`
  mutation add_tag($tag: String!, $productId: ID!, $feeId: ID!) {
    addProductFeeTag(feeId: $feeId, productId: $productId, tag: $tag) {
      id
      tags
    }
  }
`;

function useOforceAddTag({ productId, feeId }) {
  const [addTag, { loading, error }] = useMutation(ADD_PRODUCT_FEE_TAG, {
    variables: { productId, feeId }
  });

  return { loading, error, addTag: tag => addTag({ variables: { tag } }) };
}

export default useOforceAddTag;
