import React from 'react';
import { BoxItem, Render } from '../../../components';
import CoverageLogsTimeline from './CoverageTimeline/CoverageLogsTimeline';
import GroupHeader from './GroupHeader';

export default function CoverageGroup({ group, setViewingDetails, viewingDetails }) {
  const toggle = () => {
    if (viewingDetails) {
      setViewingDetails(null);
    } else {
      setViewingDetails(group);
    }
  };
  return (
    <BoxItem onClick={toggle} icon={viewingDetails ? ['far', 'angle-down'] : ['fal', 'angle-right']}>
      <div className="d-flex flex-column">
        <GroupHeader {...group} />
        <Render if={viewingDetails}>
          <CoverageLogsTimeline {...group} />
        </Render>
      </div>
    </BoxItem>
  );
}
