import { gql, useQuery } from '@apollo/client';

export const GET_INSURANCE_TYPES = gql`
  query insuranceTypes {
    allInsuranceTypes {
      id
      name
      created
      updated
      type
      requireVehicle
      stateFees {
        id
        fee
        state
        activeMode
        vehicleTypes {
          id
          name
        }
      }
      premiumFactors {
        coverageParameters
        rateModes
      }
    }
  }
`;

export default function useInsuranceTypes() {
  const { data, loading, error } = useQuery(GET_INSURANCE_TYPES);

  return {
    insuranceTypes: data?.allInsuranceTypes,
    options: data?.allInsuranceTypes?.map(type => ({
      value: type.id,
      label: type.name
    })),
    loading,
    error
  };
}
