import React from 'react';
import { LabelValueCell } from '../../../components/table-2.0';
import { convertDecimalToPercentage } from '../../../utils';
import { backendNameToLabel, formatCurrency } from '../../../utils/formatting';

export default function StateFeeRow({ stateFee }) {
  return (
    <tr>
      <td>{stateFee?.state}</td>
      <td>
        <PricingCell fee={stateFee?.fee} mode={stateFee?.activeMode} />
      </td>
    </tr>
  );
}

const PricingCell = ({ fee, mode }) => {
  const label = `${backendNameToLabel(mode)}`;
  const value = mode === 'PERCENTAGE' ? convertDecimalToPercentage(fee) : formatCurrency(fee);
  return <LabelValueCell label={label} value={value} />;
};
