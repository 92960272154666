import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export default function usePageViews() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location]);
}
