import { useHistory } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';

import { COMPANY_CORE_FIELDS } from '../../company/model';
import { GET_COMPANIES } from '../../companies/hooks/useCompanies';

const CREATE_COMPANY = gql`
  mutation CreateCompany(
    $name: String!
    $productRole: String
    $address: String
    $city: String
    $state: String
    $zip: String
    $phone: String
    $email: String
  ) {
    company: createCompany(
      name: $name
      productRole: $productRole
      address: $address
      city: $city
      state: $state
      zip: $zip
      phone: $phone
      email: $email
    ) {
      id
      ...CompanyCoreFields
    }
  }
  ${COMPANY_CORE_FIELDS}
`;

export const UPDATE_COMPANY = gql`
  mutation UpdateCompany(
    $id: ID!
    $name: String!
    $productRole: String
    $address: String
    $city: String
    $state: String
    $zip: String
    $phone: String
    $email: String
  ) {
    company: updateCompany(
      id: $id
      name: $name
      productRole: $productRole
      address: $address
      city: $city
      state: $state
      zip: $zip
      phone: $phone
      email: $email
    ) {
      id
      ...CompanyCoreFields
    }
  }
  ${COMPANY_CORE_FIELDS}
`;

export default function useFormSubmit({ isCreateMode, companyId }) {
  const history = useHistory();

  const [create, { loading: creating, error: createError }] = useMutation(CREATE_COMPANY, {
    refetchQueries: [{ query: GET_COMPANIES }],
    onCompleted: ({ company }) => history.push(`/companies/${company?.id}`),
    onError: () => {}
  });

  const [update, { loading: updating, error: updateError }] = useMutation(UPDATE_COMPANY, {
    refetchQueries: [{ query: GET_COMPANIES }],
    onCompleted: ({ company }) => history.push(`/companies/${company?.id}`),
    onError: () => {}
  });

  const formSubmit = values => {
    const input = {
      ...values,
      state: values?.state?.value
    };

    if (isCreateMode) {
      create({ variables: input });
    } else {
      update({ variables: { id: companyId, ...input } });
    }
  };

  return {
    formSubmit,
    creating,
    createError,
    updating,
    updateError
  };
}
