import { Loader } from '@oforce/global-components';

import { GraphQLErrors, Placeholder, RenderData } from '../../../components';
import { ExposureElectionCard } from '../../../components/exposureElection';

export default function ElectionCards({ elections, loading, error }) {
  return (
    <div className="d-flex flex-wrap">
      <RenderData
        data={elections}
        loading={loading}
        error={error}
        loadingComponent={<Loader className="py-0 d-flex" />}
        errorComponent={<GraphQLErrors error={error} />}
        placeholderComponent={<Placeholder>None</Placeholder>}
      >
        {elections?.map(election => (
          <ExposureElectionCard key={election?.id} election={election} className="mb-4 mr-4 flex-grow-0 w-100" />
        ))}
      </RenderData>
    </div>
  );
}
