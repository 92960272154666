import React from 'react';
import { Button } from '@oforce/global-components';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import {
  PolicyFees,
  PolicyRetailCommissions,
  PolicyRequiredFields,
  PolicyAddOns,
  PolicyNotifications,
  CoverageParameters
} from '..';

import { BoxContainer, BoxItem, BoxItemTitle } from '../../components';
import { HideFromTypes, RouteHiddenFromRolesAndTypes, useActiveAccount } from '../../shared/activeAccount';

export default function PolicyConfiguration({ policy }) {
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const {
    TYPES: { CLIENT }
  } = useActiveAccount();

  // TODO: add counts in the subtitles of each of these sections

  return (
    <>
      <Switch>
        <Route exact path={path}>
          <ConfigurationBox
            title="Coverage Parameters"
            // subtitle="0 coverage parameters configured"
            onConfigure={() => history.push(`${url}/coverage-parameters`)}
          />
          <HideFromTypes types={[CLIENT]}>
            <ConfigurationBox
              title="Retail Commissions"
              // subtitle="0 commissions configured"
              onConfigure={() => history.push(`${url}/retail-commissions`)}
            />
          </HideFromTypes>
          <ConfigurationBox
            title="Fees"
            // subtitle="0 fees configured"
            onConfigure={() => history.push(`${url}/fees`)}
          />
          <ConfigurationBox
            title="Add-Ons"
            // subtitle="0 add-ons configured"
            onConfigure={() => history.push(`${url}/add-ons`)}
          />
          <ConfigurationBox
            title="Required Fields"
            // subtitle="0 required fields configured"
            onConfigure={() => history.push(`${url}/required-fields`)}
          />
          <ConfigurationBox title="Notifications" onConfigure={() => history.push(`${url}/notifications`)} />
        </Route>
        <Route path={`${path}/coverage-parameters`}>
          <CoverageParameters policyId={policy?.id} />
        </Route>
        <RouteHiddenFromRolesAndTypes types={[CLIENT]} path={`${path}/retail-commissions`}>
          <PolicyRetailCommissions policy={policy} />
        </RouteHiddenFromRolesAndTypes>
        <Route path={`${path}/fees`}>
          <PolicyFees policy={policy} />
        </Route>
        <Route path={`${path}/add-ons`}>
          <PolicyAddOns policy={policy} />
        </Route>
        <Route path={`${path}/required-fields`}>
          <PolicyRequiredFields policy={policy} />
        </Route>
        <Route path={`${path}/notifications`}>
          <PolicyNotifications policy={policy} />
        </Route>
      </Switch>
    </>
  );
}

const ConfigurationBox = ({ title, subtitle, onConfigure }) => (
  <BoxContainer className="mb-4">
    <BoxItem size="lg">
      <BoxItemTitle title={title} subtitle={subtitle} />
      <Button color="info" onClick={onConfigure}>
        Configure
      </Button>
    </BoxItem>
  </BoxContainer>
);
