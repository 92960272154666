import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';

export const GET_POLICY_INVOICE_EMAILS = gql`
  query PolicyInvoiceEmails($policyId: ID!) {
    policy(id: $policyId) {
      id
      invoiceEmailAddresses
    }
  }
`;
export default function usePolicyInvoiceEmailAddresses({ policyId }) {
  const { data, loading, error } = useQuery(GET_POLICY_INVOICE_EMAILS, {
    variables: { policyId }
  });

  return {
    invoiceEmailAddresses: data?.policy?.invoiceEmailAddresses || [],
    options: data?.policy?.invoiceEmailAddresses?.map(email => ({ label: email, value: email })) || [],
    loading,
    error
  };
}
