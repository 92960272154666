import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';

const DUPLICATE_PRICING_OPTION = gql`
  mutation DuplicatePricingOption($id: ID!, $name: String, $label: String) {
    duplicatePricingOption(pricingOptionId: $id, label: $label, name: $name) {
      id
      name
      label
    }
  }
`;

export default function useDuplicatePricingOption({ id, refetchQueries = [], onCompleted, onError = () => {} }) {
  const [commit, { loading, error }] = useMutation(DUPLICATE_PRICING_OPTION, {
    refetchQueries,
    variables: { id },
    onCompleted,
    onError
  });

  return {
    loading,
    error,
    duplicatePricingOption: ({ name, label }) => {
      commit({ variables: { name, label } });
    }
  };
}
