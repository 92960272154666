import { useLazyQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { useEffect } from 'react';

export const LIST_EXPOSURE_ELECTIONS_FOR_CONTRACT = gql`
  query ListExposureElectionsForContract(
    $id: ID
    $policyId: ID!
    $first: Int
    $last: Int
    $after: String
    $before: String
    $fullName: String
  ) {
    listExposureElectionsForContract(
      masterExposureElectionId: $id
      policyId: $policyId
      first: $first
      last: $last
      fullName: $fullName
      after: $after
      before: $before
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        node {
          id
          startDate
          hasInvoiced
          pricingOption {
            id
            label
          }
          insured {
            id
            firstName
            lastName
            middleName
            businessName
          }
        }
      }
    }
  }
`;

export default function useExposureElectionsForContract({ numberToDisplay = 10, exposureElectionId, policyId }) {
  const [load, { data, loading, error }] = useLazyQuery(LIST_EXPOSURE_ELECTIONS_FOR_CONTRACT);
  useEffect(() => {
    const variables = { first: numberToDisplay, policyId, id: exposureElectionId };

    load({ variables });
  }, [load, numberToDisplay, policyId, exposureElectionId]);

  const fetchMore = async variables => {
    const { data } = await load({
      variables: { first: numberToDisplay, policyId, id: exposureElectionId, ...variables }
    });
    return data?.listExposureElectionsForContract?.edges?.map(x => x.node);
  };

  return {
    coveragesForContract: data?.listExposureElectionsForContract?.edges?.map(x => x.node) || [],
    loading,
    error,
    fetchMore
  };
}
