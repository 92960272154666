import React from 'react';
import { useParams } from 'react-router-dom';
import { TabPane } from '../../components/tabs';
import { PageHeader, PageTitle } from '../../components/page-components';
import NotificationJobTable from './components/NotificationJobTable';
import useListNotificationJob from './hooks/useListNotificationJob';
import { useNotificationStatusOptions, useTotalNotificationsCount } from './hooks';

export default function NotificationJob() {
  const { jobId } = useParams();
  const { notificationStatusOptions } = useNotificationStatusOptions();
  const defaultNotificationStatus = notificationStatusOptions?.find(({ value }) => value === 'DELIVERED')?.value;

  const {
    totalNotificationsCount,
    loading: loadingCount,
    error: errorCount
  } = useTotalNotificationsCount({ initialFilters: { jobId } });

  const { notificationJob, loading, error, paginationProps, filters, changeFilters } = useListNotificationJob({
    pageSize: 10,
    initialFilters: { jobId: jobId, status: defaultNotificationStatus }
  });

  return (
    <>
      <PageHeader>
        <PageTitle placeholder="No email report found">
          Email Report {!loadingCount && !errorCount && `(${totalNotificationsCount})`}
        </PageTitle>
      </PageHeader>
      <TabPane>
        <NotificationJobTable
          notificationJob={notificationJob}
          loading={loading}
          error={error}
          paginationProps={paginationProps}
          filters={filters}
          changeFilters={changeFilters}
          notificationStatusOptions={notificationStatusOptions}
        />
      </TabPane>
    </>
  );
}
