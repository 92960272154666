import { gql, useMutation } from '@apollo/client';

const UPDATE_VEHICLE_TYPE = gql`
  mutation update($id: Int!, $name: String!) {
    updateVehicleType(id: $id, name: $name) {
      id
      name
    }
  }
`;

export default function useUpdateVehicleType({ onError = () => {}, onCompleted }) {
  const [update, { loading, error }] = useMutation(UPDATE_VEHICLE_TYPE, {
    onError,
    onCompleted
  });

  return {
    updateVehicleType: (id, name) => update({ variables: { id, name } }),
    loading,
    error
  };
}
