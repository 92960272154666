// TODO: Clean up this component a bit and allow for search/filter functionality through the options
import classNames from 'classnames';
import { Button, Render, useToggle } from '@oforce/global-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Alert } from 'reactstrap';
import { useEffect, useState } from 'react';

export default function FilterDropdown({
  id,
  hide,
  setFilter,
  defaultOption,
  headerText = 'Filter by...',
  options,
  icon,
  loading,
  error,
  className,
  children,
  style,
  resetDefault = false
}) {
  const [chosenOption, setChosenOption] = useState(defaultOption);
  const [showDropdown, toggleDropdown] = useToggle();
  const dropdownClasses = classNames(className, { 'd-none': hide });

  const onChooseOption = option => {
    setChosenOption(option);
    setFilter(option);
  };

  useEffect(() => {
    if (resetDefault) {
      setChosenOption(defaultOption);
    }
  }, [resetDefault, defaultOption]);

  return (
    <Dropdown isOpen={showDropdown} toggle={toggleDropdown} className={dropdownClasses}>
      <DropdownToggle
        id={id}
        tag={Button}
        size="sm"
        style={style}
        className="text-nowrap d-flex d-md-inline-flex mr-2 rounded-pill"
        icon={icon || ['far', 'filter']}
        iconAfter={['far', 'angle-down']}
        disabled={loading}
      >
        <div style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {loading ? 'Loading...' : chosenOption?.label}
        </div>
      </DropdownToggle>
      <DropdownMenu style={{ maxHeight: '400px', overflowY: 'auto', zIndex: '999', ...style }}>
        <DropdownItem header style={style}>
          {headerText}
        </DropdownItem>
        {options?.map((option, i) => (
          <RadioDropdownItem
            key={option?.value || i}
            active={chosenOption?.value === option?.value}
            onClick={() => onChooseOption(option)}
          >
            {option?.label}
          </RadioDropdownItem>
        ))}
        <Render if={!!error}>
          <div className="p-3">
            <Alert color="danger" className="mb-0">
              {error?.message}
            </Alert>
          </div>
        </Render>
        {children}
      </DropdownMenu>
    </Dropdown>
  );
}

const RadioDropdownItem = ({ children, active, ...rest }) => (
  <DropdownItem className="py-2" active={active} {...rest}>
    <div className="d-flex">
      <span>
        <FontAwesomeIcon icon={['far', active ? 'dot-circle' : 'circle']} className="mr-2" />
      </span>
      <span className="text-wrap">{children}</span>
    </div>
  </DropdownItem>
);
