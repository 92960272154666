import React from 'react';
import { SearchBy } from '../../../components';
import { useClientSearchByOptions } from '../hooks';

export default function ClientsSearchBy({ onChange, className }) {
  const { clientSearchByOptions, loading } = useClientSearchByOptions();

  const onFilterChange = incomingFilters => {
    const searchBy = incomingFilters.reduce((acc, { field: { value: searchField }, value: searchValue }) => {
      if (!!searchValue) return [...acc, { searchField, searchValue }];
      return acc;
    }, []);

    if (searchBy.length > 0) {
      onChange({ searchBy });
    } else {
      onChange({ searchBy: [] });
    }
  };

  if (loading) return null;

  return (
    <SearchBy
      className={className}
      label={'Search by'}
      onFilterChange={onFilterChange}
      name="clients-search-by"
      fieldOptions={clientSearchByOptions}
      maxFilters={clientSearchByOptions?.length - 1}
      defaultValue={clientSearchByOptions?.find(option => option.value === 'NAME')}
    />
  );
}
