import React from 'react';
import { Route, useRouteMatch, Switch } from 'react-router-dom';
import CoverageView from '../coverage';
import { InsuredCoverages } from './components/InsuredCoverages';

export { INSURED_ELECTIONS_FIELDS } from './hooks/useInsuredElections';

export default function InsuredElections({ insuredId }) {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <InsuredCoverages insuredId={insuredId} />
      </Route>
      <Route path={`${path}/:exposureElectionId`}>
        <CoverageView />
      </Route>
    </Switch>
  );
}
