import React from 'react';
import { DatePicker } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Label, Input } from 'reactstrap';
import { Loader, DataTable } from '@oforce/global-components';

import { FilterDropdown, PagerButtons } from '../../../components';

import { useTransactionsTable } from '../hooks';
import { useFormOptions } from '../../../hooks';

const { RangePicker } = DatePicker;

export default function TransactionsList() {
  const {
    reactTableProps,
    loading,
    clients,
    error,
    setClientFilter,
    setDriversLicenseNumberFilter,
    setNameFilter,
    setInvoiceIdFilter,
    setSSNFilter,
    setDateRange,
    setInvoiceDate,
    hasNextPage,
    hasPreviousPage,
    loadNextPage,
    loadPreviousPage
  } = useTransactionsTable();

  const clientOptions = useFormOptions({
    options: [{ name: 'client', options: [{ label: 'All', value: '' }, ...clients] }]
  });

  return (
    <>
      <div className="mb-3 d-flex justify-content-start align-items-center">
        <div className="mr-3 mb-[-13px]">
          <FilterDropdown
            id="invoices-table-product-filter"
            defaultOption={{ label: 'All', value: '' }}
            setFilter={option => setClientFilter(option.value)}
            options={clientOptions?.client?.options}
          />
        </div>

        <div className="mr-3">
          <SearchTransactions
            label="Search by CDL/DL"
            placeHolder="CDL or Driver's License number..."
            onChange={e => setDriversLicenseNumberFilter(e.target.value)}
          />
        </div>

        <div className="mr-3">
          <SearchTransactions
            label="Search by SSN"
            placeHolder="Last 4 digits..."
            onChange={e => setSSNFilter(e.target.value)}
          />
        </div>
        <div className="mr-3">
          <SearchTransactions
            label="Search by name"
            placeHolder="Insured Name..."
            onChange={e => setNameFilter(e.target.value)}
          />
        </div>
        <div className="mr-3">
          <SearchTransactions
            label="Search by Invoice"
            placeHolder="Invoice Id..."
            onChange={e => setInvoiceIdFilter(e.target.value)}
          />
        </div>
        <div className="mr-3">
          <InvoiceDateFilter onChange={setInvoiceDate} />
        </div>
        <div>
          <ServiceDateFilter onChange={setDateRange} />
        </div>
      </div>
      {loading && !error && <Loader />}
      {!loading && (
        <>
          <DataTable error={error?.message} {...reactTableProps} responsive />
          <PagerButtons
            hasNextPage={hasNextPage}
            hasPreviousPage={hasPreviousPage}
            loadNextPage={loadNextPage}
            loadPreviousPage={loadPreviousPage}
            loading={loading}
          />
        </>
      )}
    </>
  );
}

const SearchTransactions = ({ onChange, label, placeHolder }) => (
  <>
    <Label className="fs-sm font-weight-500 mb-0 d-inline-flex align-items-center">{label ? label : 'Search'}</Label>
    <Input
      type="text"
      bsSize="sm"
      placeholder={placeHolder ? placeHolder : 'Search transactions...'}
      style={{ minWidth: '200px' }}
      onChange={onChange}
    />
  </>
);

const InvoiceDateFilter = ({ onChange }) => (
  <>
    <Label className="fs-sm font-weight-500 mb-0 d-inline-flex align-items-center">Invoice Date</Label>
    <br />
    <DatePicker onChange={onChange} format="MM/DD/YYYY" />
  </>
);

const ServiceDateFilter = ({ onChange }) => (
  <>
    <Label className="fs-sm font-weight-500 mb-0 d-inline-flex align-items-center">Service Date Range</Label>
    <br />
    <RangePicker
      format="MM/DD/YYYY"
      onChange={onChange}
      separator={<FontAwesomeIcon icon={['far', 'long-arrow-alt-right']} className="text-gray-500 ml-0" />}
    />
  </>
);
