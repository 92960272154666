import { useRemovePricing } from '.';

export default function useRemovePricingForm({ pricingId, onCompleted, onError }) {
  const { removePricing, loading, error } = useRemovePricing({
    policyPricingOptionId: pricingId,
    onCompleted,
    onError
  });

  const initialValues = {};

  const handleSubmit = () => removePricing();

  return {
    initialValues,
    handleSubmit,
    loading,
    error
  };
}
