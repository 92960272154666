import moment from 'moment';
import { transformCountryValue } from '../../../utils/yupValidation';

export default function initialValues({ insured, formOptions }) {
  return {
    id: insured?.id,
    dispatchLocation: insured?.dispatchLocation || '',
    address: insured?.address || '',
    city: insured?.city || '',
    businessName: insured?.businessName || '',
    businessName2: insured?.businessName2 || '',
    country: formOptions?.country?.options?.find(o => o.value === transformCountryValue(insured?.country)) || null,
    dateOfBirth: insured?.dateOfBirth ? moment(insured?.dateOfBirth, 'YYYY-MM-DD').format('MM/DD/YYYY') : '',
    email: insured?.email || '',
    firstName: insured?.firstName || '',
    middleName: insured?.middleName || '',
    lastName: insured?.lastName || '',
    mobilePhone: insured?.mobilePhone || '',
    state: formOptions?.allStates?.options?.find(o => o.value === insured?.state) || null,
    zip: insured?.zip || '',
    ssn: insured?.ssnLastFour ? `XXX-XX-${insured.ssnLastFour}` : '',
    driversLicenseState: formOptions?.allStates?.options?.find(o => o.value === insured?.driversLicenseState) || null,
    driversLicenseNumber: insured?.driversLicenseNumber || ''
  };
}
