import React from 'react';

import { Render, Td, Tr } from '../../../components';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { DocumentActions } from '../../document/components';

export default function InsuredDocumentRow({ insuredDocument }) {
  const { document, client, vehicle } = insuredDocument;
  return (
    <Tr>
      <Td>{document?.name}</Td>
      <Td>
        <Link to={`/clients/${client?.id}`}>{client?.name}</Link>
      </Td>
      <Td>
        <VinUnitNumberCell vin={vehicle?.vin} unitNumber={vehicle?.unitNumber} vehicleId={vehicle?.id} />
      </Td>
      <Td>
        <DocumentActions document={insuredDocument} showVersions={insuredDocument.source === 'DOCUMENT_RELATIONSHIP'} />
      </Td>
    </Tr>
  );
}

const VinUnitNumberCell = ({ vin, unitNumber, vehicleId }) => {
  if (!vin && !unitNumber) return <EmptyCell />;

  return (
    <div>
      <Render if={!unitNumber}>
        <div>
          <Link to={`/vehicles/${vehicleId}`}>{vin}</Link> /{' '}
          <span className="font-weight-300 fs-xs font-italic text-muted">None</span>
        </div>
      </Render>
      <Render if={!!unitNumber}>
        <Link to={`/vehicles/${vehicleId}`}>{vin}</Link> / {unitNumber}
      </Render>
    </div>
  );
};

const EmptyCell = ({ text = 'None' }) => <div className="font-weight-300 fs-xs font-italic text-muted">{text}</div>;
