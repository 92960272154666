import React from 'react';
import { gql, useMutation } from '@apollo/client';

import { ConfirmModal, Bold } from '../../../components';

const CANCEL_ACCOUNT_INVITATION = gql`
  mutation cancelAccountInvitation($id: ID!) {
    cancelAccountInvitation(id: $id) {
      id
      status
    }
  }
`;

export default function AccountRevokeInvite({ isOpen, toggle, invitationId, inviteeEmail }) {
  const [revokeInvite, { loading, error, reset }] = useMutation(CANCEL_ACCOUNT_INVITATION, {
    variables: { id: invitationId },
    onCompleted: toggle,
    onError: () => {}
  });

  return (
    <ConfirmModal
      isOpen={isOpen}
      toggle={toggle}
      title="Revoke invite"
      onConfirm={revokeInvite}
      confirmButtonText="Revoke Invite"
      icon={['far', 'ban']}
      iconColor="danger"
      confirmButtonProps={{ color: 'danger', loading, loadingText: 'Revoking invite...' }}
      onOpened={reset}
      error={error}
    >
      Are you sure you want to revoke this invitation to <Bold>{inviteeEmail}</Bold>?
    </ConfirmModal>
  );
}
