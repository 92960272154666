import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { InputField, Loader } from '@oforce/global-components';

import { MultiStepForm, Render, TabPage } from '../../../components';
import { GET_INVOICE } from '../hooks/useInvoice';
import { Alert } from 'reactstrap';
import { ensureFloat } from '../../../utils/formatting';
import { usePolicy } from '../../policy/hooks';
import { ImportFactorValues } from '../../policyInvoices/components/import';
import useRegenerateInvoiceFormSteps from '../hooks/useRegenerateInvoiceFormSteps';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Steps } from 'react-step-builder';

const REGENERATE_INVOICE = gql`
  mutation RegenerateInvoice(
    $invoiceId: Int!
    $notes: String!
    $exposureElectionFactorValues: [ExposureElectionFactorValue]
  ) {
    regenerateInvoice(
      invoiceId: $invoiceId
      notes: $notes
      exposureElectionFactorValues: $exposureElectionFactorValues
    ) {
      voidedInvoiceId
      regeneratedInvoiceId
    }
  }
`;

export default function InvoiceRegenerate({ onCompleted, invoice, onError = () => {} }) {
  const history = useHistory();
  const [factorValues, setFactorValues] = useState(null);

  const { policy } = usePolicy({ id: invoice?.policyId });
  const [regenerateInvoice, { loading, error }] = useMutation(REGENERATE_INVOICE, {
    onCompleted,
    onError,
    refetchQueries: [{ query: GET_INVOICE }]
  });

  const { formSteps } = useRegenerateInvoiceFormSteps({ invoice });

  const handleSubmit = values => {
    regenerateInvoice({
      variables: {
        ...(factorValues && { exposureElectionFactorValues: factorValues }),
        invoiceId: values?.invoiceId,
        notes: values?.notes
      }
    });
  };

  const formatFactorValue = value => {
    if (value === 'N/A') return null;
    else return ensureFloat(value);
  };

  const addFactorValues = validatedRows => {
    const results = validatedRows?.map(({ exposureElectionId, factorValue }) => {
      return { exposureElectionId, factorValue: formatFactorValue(factorValue) };
    });

    setFactorValues(results);
  };

  if (loading) return <Loader />;

  return (
    <TabPage className={'mt-5'}>
      <Render if={!error && !loading && policy?.hasApiFactoredField && !factorValues}>
        <ImportFactorValues policy={policy} onComplete={addFactorValues} />
      </Render>
      <Render if={(!loading && !policy?.hasApiFactoredField) || (policy?.hasApiFactoredField && !!factorValues)}>
        <MultiStepForm
          formSteps={formSteps}
          formSubmit={handleSubmit}
          onCancel={() => history.goBack()}
          showProgressBar={false}
          loading={loading}
          error={error}
          title="Regenerate Invoice"
          submitButtonProps={{
            color: 'info',
            loading,
            loadingText: 'Regenerating...'
          }}
          submitButtonText="Regenerate Invoice"
        >
          {formProps => (
            <Steps>
              <>
                <Alert color="warning">
                  Regenerating an invoice will void the previous invoice. Only proceed if payment has not been
                  processed. If payment has been processed, do not proceed, any updates will be reflected on the
                  following invoice.
                </Alert>
                <fieldset>
                  <InputField type="textarea" label="Note" name="notes" required placeholder="Enter a note..." />
                </fieldset>
              </>
            </Steps>
          )}
        </MultiStepForm>
      </Render>
    </TabPage>
  );
}
