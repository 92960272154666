import React from 'react';
import { InputField } from '@oforce/global-components';

import { FormSection } from '../../../components';
import { ProductSelect } from '../../../components/sharedComponents';
import { ClientSelect } from '../../../views/client/components';

export default function PolicyCreateForm({ formik, focusRef }) {
  return (
    <FormSection title="Policy Details">
      <InputField name="name" label="Name" required innerRef={focusRef} />
      <ClientSelect name="client" label="Client" required isFormik formik={formik} className={'mb-3'} />
      <ProductSelect required isFormik />
    </FormSection>
  );
}
