import download from 'js-file-download';
import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const StyledThumbnail = styled.div.attrs({
  className:
    'fs-sm d-inline-flex align-items-center justify-content-start bg-white border border-gray-400 rounded text-muted px-1 pt-1 mr-2'
})``;

const StyledThumbnailText = styled.div`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

function DocumentDownloader({ url, filename, className }) {
  const downloadFile = useCallback(() => {
    fetch(url)
      .then(response => response.blob())
      .then(file => download(file, filename));
  }, [url, filename]);

  return (
    <StyledThumbnail className={className}>
      <StyledThumbnailText onClick={downloadFile}>
        <FontAwesomeIcon
          fixedWidth
          icon={['far', 'file-download']}
          className="mr-1 mb-1"
        />
        {filename}
      </StyledThumbnailText>
    </StyledThumbnail>
  );
}

export default DocumentDownloader;
