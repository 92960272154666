import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';

const GET_PRODUCT_INSURANCE_TYPE = gql`
  query productInsuranceType($id: ID!) {
    product(id: $id) {
      id
      insuranceType {
        id
        type
        requireVehicle
        stateFees {
          id
        }
      }
    }
  }
`;

export default function useProductInsuranceType({ productId, onCompleted = () => {}, onError = () => {} }) {
  const { loading, error, data } = useQuery(GET_PRODUCT_INSURANCE_TYPE, {
    variables: { id: productId },
    onCompleted,
    onError
  });

  return {
    insuranceType: data?.product?.insuranceType,
    stateFees: data?.product?.insuranceType?.stateFees,
    loading,
    error
  };
}
