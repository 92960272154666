import React from 'react';
import { Helmet } from 'react-helmet';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';

import { PageHeader, PageTitle } from '../../components/page-components';
import { TabBar, TabLink, TabPane } from '../../components/tabs';
import PendingElections from './PendingElections';

export default function PendingElectionsIndex() {
  const { url, path } = useRouteMatch();

  return (
    <>
      <Helmet>
        <title>Pending Elections • IC Insure</title>
      </Helmet>
      <PageHeader withTabs>
        <PageTitle>Pending Elections</PageTitle>
        <TabBar>
          <TabLink to={`${url}`} icon={['far', 'check']}>
            Unclaimed Pending Elections
          </TabLink>
        </TabBar>
      </PageHeader>

      <TabPane>
        <Switch>
          <Redirect exact from={`${path}`} to={`${path}/unclaimed`} />
          <Route path={`${path}/unclaimed`}>
            <PendingElections />
          </Route>
        </Switch>
      </TabPane>
    </>
  );
}
