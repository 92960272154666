import React from 'react';
import { FilterDropdown, FilterLabel } from '../../../components';
import ClientsSearchBy from './ClientsSearchBy';

export default function ClientsFilter({ onChange }) {
  const changeValue = (filterName, value) => onChange({ [filterName]: value });

  return (
    <div className="d-flex align-items-center flex-wrap">
      <FilterLabel>Sort by</FilterLabel>
      <ClientsOrdering changeValue={changeValue} />
      <ClientsSearchBy className={'mr-2'} onChange={onChange} />
    </div>
  );
}

const ClientsOrdering = ({ changeValue }) => {
  const orderOptions = [
    { label: 'Client Name', value: 'CLIENT_NAME' },
    { label: 'Email', value: 'EMAIL' },
    { label: 'External Id', value: 'EXTERNAL_ID' }
  ];

  return (
    <FilterDropdown
      id="ordering"
      className="mr-2"
      headerText="Sort by..."
      icon={['far', 'sort-alpha-down']}
      options={orderOptions}
      defaultOption={orderOptions[0]}
      setFilter={option => changeValue('ordering', option?.value)}
    />
  );
};
