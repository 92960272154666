import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';

const GET_PRODUCT_PRICING_OPTIONS = gql`
  query productPricingOptions($id: ID!) {
    product(id: $id) {
      id
      pricingOptions {
        id
        label
      }
    }
  }
`;

export default function useProductPricingOptions({ productId, onCompleted = () => {}, onError = () => {} }) {
  const { loading, error, data } = useQuery(GET_PRODUCT_PRICING_OPTIONS, {
    variables: { id: productId },
    skip: !productId,
    onCompleted,
    onError
  });

  const selectOptions = data?.product?.pricingOptions?.map(pricing => ({
    label: pricing?.label,
    value: pricing?.id
  }));

  return {
    productPricingOptions: data?.product?.pricingOptions,
    selectOptions,
    loading,
    error
  };
}
