import React, { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useSendDocumentsJobContext } from '../../context';
import { ToastContent } from '../../components/react-toastify';
import { Button, Render } from '@oforce/global-components';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function SendDocumentsJob() {
  const { jobId, policyId, error, jobErrors, finished, progress, insured, message, clearJobId } =
    useSendDocumentsJobContext();
  const toastId = useRef(null);

  useEffect(() => {
    if (!toast.current && jobId) {
      toastId.current = toast(<SendingDocuments />, {
        progress: 0,
        hideProgressBar: false,
        pauseOnHover: true
      });
    }
  }, [toastId, jobId, clearJobId]);

  useEffect(() => {
    if (!toastId.current) {
      return;
    }

    if (error) {
      toast.update(toastId.current, {
        render: () => <ErrorSendingDocuments error={jobErrors || error} />,
        progress: finished ? 0.99 : progress,
        autoClose: 10000,
        progressClassName: 'bg-danger'
      });
    } else if (!finished) {
      toast.update(toastId.current, {
        render: () => <SendingDocuments insured={insured} message={message} />,
        progress: progress,
        autoClose: false,
        progressClassName: 'bg-info'
      });
    } else if (finished) {
      toast.update(toastId.current, {
        render: () => (
          <SendingDocumentsFinished message={message} jobId={jobId} errors={jobErrors} policyId={policyId} />
        ),
        progress: 0.99,
        autoClose: 10000,
        progressClassName: 'bg-success'
      });
      setTimeout(() => {
        toast.dismiss(toastId.current);
      }, 10000);
    }
    // eslint-disable-next-line
  }, [toastId, error, finished, progress, insured, message, jobErrors]);

  return <></>;
}

const SendingDocuments = ({ message, insured }) => {
  let title = message ?? 'Preparing Documents';
  let updatedMessage = !insured ? 'Preparing Documents...' : 'Sending Emails...';

  if (insured) {
    const { firstName, lastName } = insured;
    title = `Recipient: ${firstName} ${lastName}`;
  }

  return (
    <ToastContent title={title} icon={['far', 'spinner-third']} iconProps={{ spin: true }} message={updatedMessage} />
  );
};

const ErrorSendingDocuments = ({ error }) => (
  <ToastContent
    title="Error Sending Document(s)"
    titleProps={{ className: 'text-danger' }}
    icon={['fas', 'times-circle']}
    message={error.message || error}
  />
);

const SendingDocumentsFinished = ({ message, jobId, policyId, errors }) => {
  const hadErrors = errors && errors.length > 0;
  const history = useHistory();

  // TODO: Remove the <Render if=...> when we fix up the notification delivery status reports.
  return (
    <ToastContent
      title={message || 'Sending Documents Finished'}
      titleProps={{ className: 'text-success' }}
      icon={!hadErrors ? ['fas', 'check-circle'] : ['far', 'exclamation-triangle']}
      message={!hadErrors ? 'Your Documents Sent Sucessfully' : 'Some Documents Failed to Send'}
    >
      {hadErrors && errors.map(e => <div key={e}>{e}</div>)}
      <Render if={policyId}>
        <Button
          color="link"
          size="sm"
          icon={['far', 'search']}
          className="mr-2"
          onClick={() => history.push(`/notifications/${jobId}`)}
        >
          View Email Report
        </Button>
      </Render>
    </ToastContent>
  );
};
