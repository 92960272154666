import { gql, useQuery } from '@apollo/client';
import { EXPOSURE_ELECTION_STATUSES } from '../../exposureElections/constants';

const { ACTIVE } = EXPOSURE_ELECTION_STATUSES;

export const GET_VALID_POLICY_EXPOSURE_ELECTIONS_FOR_RENEWAL = gql`
  query validPolicyExposureElectionsForRenewal(
    $policyId: ID!
    $status: ExposureElectionStatus
    $terminationDate: Date
  ) {
    validPolicyExposureElectionsForRenewal(policyId: $policyId, status: $status, terminationDate: $terminationDate) {
      id
    }
  }
`;

export default function useValidPolicyExposureElectionsForRenewal({
  policy,
  onCompleted = () => {},
  onError = () => {}
}) {
  const { data, loading, error } = useQuery(GET_VALID_POLICY_EXPOSURE_ELECTIONS_FOR_RENEWAL, {
    variables: {
      policyId: policy?.id,
      ...(policy?.effectiveStatus === 'EXPIRED' ? { terminationDate: policy?.expirationDate } : { status: ACTIVE })
    },
    onCompleted,
    onError
  });

  return {
    exposureElections: data?.validPolicyExposureElectionsForRenewal,
    loading,
    error
  };
}
