import { FilterDropdown, FilterLabel, FiltersContainer } from '../../../components';
import { exposureStatusOptions } from '../../exposures/utils';
import DashboardFilters from './DashboardFilters';
import VehiclesSearchBy from './VehiclesSearchBy';

export default function VehiclesFilters({ orderOptions, filters, setFilters }) {
  const statusOptions = exposureStatusOptions().filter(status => status?.value !== 'PENDING');

  return (
    <>
      <DashboardFilters />
      <FiltersContainer className="pb-0 mb-3 pt-3">
        <FilterLabel>Sort by</FilterLabel>
        <FilterDropdown
          id="ordering"
          className="mr-3"
          headerText="Sort ascending..."
          icon={['far', 'sort-alpha-down']}
          options={orderOptions}
          defaultOption={orderOptions.find(({ value }) => value === filters.ordering)}
          setFilter={option => setFilters({ ...filters, ordering: option.value })}
          isClearable
        />
        <FilterLabel>Status</FilterLabel>
        <FilterDropdown
          id="election-status"
          className="mr-3"
          headerText="Status..."
          options={statusOptions}
          defaultOption={statusOptions.find(({ label }) => label === 'Active')}
          setFilter={option => {
            setFilters({ ...filters, status: option?.value });
          }}
          isClearable
        />
        <VehiclesSearchBy onChange={setFilters} />
      </FiltersContainer>
    </>
  );
}
