import download from 'js-file-download';
import { gql, useMutation } from '@apollo/client';

const downloadFile = async (url, filename) => {
  await fetch(url)
    .then(response => response.blob())
    .then(file => download(file, filename));
};

const GENERATE_REPORT = gql`
  mutation GenerateReport($id: ID!, $variables: [AccountReportVariableInput]!) {
    url: generateReport(id: $id, variables: $variables)
  }
`;

export default function useGenerateReportFormSubmit({ report }) {
  const variableTypes = report.variables.reduce((acc, { name, type }) => ({ ...acc, [name]: type }), {});

  const [generateReport, { loading, error }] = useMutation(GENERATE_REPORT, {
    onError: () => {},
    onCompleted: ({ url }) => downloadFile(url, `${report?.name}.csv`)
  });

  function formatValue(name, value) {
    const type = variableTypes[name];
    switch (type) {
      case 'Date':
        return value.format('YYYY-MM-DD');
      default:
        return value;
    }
  }

  const formSubmit = ({ id, ...values }) => {
    const reportVariables = Object.keys(values)?.map(key => {
      const value = formatValue(key, values[key]);
      return { name: key, value: value };
    });

    generateReport({
      variables: {
        id,
        variables: reportVariables
      }
    });
  };

  return { formSubmit, loading, error };
}
