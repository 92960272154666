import React from 'react';
import { LabelValueGroup } from '../../../components';

export default function GroupHeader({ coverageStartDate, coverageEndDate, addOnLabels, factorLabel }) {
  const label = () => {
    if (!!coverageStartDate && !!coverageEndDate) return `Active from ${coverageStartDate} - ${coverageEndDate}`;
    if (!!coverageStartDate && !coverageEndDate) return `Active from ${coverageStartDate} - Current`;
    return `Inactive Logs`;
  };

  return (
    <>
      <div className="d-flex flex-column ml-0">
        <LabelValueGroup pad={false} labelWidth="150px" showBorder={false} label={label()} placeholder="" />
        {coverageStartDate && addOnLabels && addOnLabels.length > 0 && (
          <LabelValueGroup
            pad={false}
            labelWidth="150px"
            className={'text-muted'}
            showBorder={false}
            label="with add-ons"
            value={addOnLabels.join(', ')}
          />
        )}
        {coverageStartDate && factorLabel && (
          <LabelValueGroup
            pad={false}
            labelWidth="150px"
            className={'text-muted'}
            label="with factor value"
            value={factorLabel}
          />
        )}
      </div>
    </>
  );
}
