import { useEffect } from 'react';
import { gql, useSubscription } from '@apollo/client';

import { usePaginatedLazyQuery } from '../../../hooks';
import {
  ELECTION_COMPLIANCE_FIELDS,
  ELECTION_INSURED_FIELDS,
  ELECTION_PRICING_OPTION_FIELDS,
  ELECTION_VEHICLE_FIELDS,
  EXPOSURE_ELECTION_FIELDS
} from '../../coverage/model/fragments';

const ON_INSURED_CREATED = gql`
  subscription insuredCreated($policyId: ID!) {
    insured: insuredCreated(policyId: $policyId) {
      id
      elections {
        id
        ...electionInsuredFields
        ...electionVehicleFields
        ...exposureElectionFields
        ...electionComplianceFields
        ...electionPricingOptionFields
      }
    }
  }
  ${ELECTION_INSURED_FIELDS}
  ${ELECTION_VEHICLE_FIELDS}
  ${EXPOSURE_ELECTION_FIELDS}
  ${ELECTION_COMPLIANCE_FIELDS}
  ${ELECTION_PRICING_OPTION_FIELDS}
`;

export const GET_POLICY_EXPOSURE_ELECTIONS = gql`
  query listPolicyExposureElections(
    $policyId: ID!
    $first: Int
    $before: String
    $last: Int
    $after: String
    $ordering: ExposureElectionsListOrder
    $electionStatus: ExposureElectionStatus
    $inCompliance: Boolean
    $complianceStatus: ComplianceViolationStatus
    $searchBy: [ElectionSearchByInput] = []
  ) {
    listExposureElections(
      policyId: $policyId
      first: $first
      before: $before
      last: $last
      after: $after
      ordering: $ordering
      status: $electionStatus
      inCompliance: $inCompliance
      complianceStatus: $complianceStatus
      searchBy: $searchBy
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          ...electionInsuredFields
          ...electionVehicleFields
          ...exposureElectionFields
          ...electionComplianceFields
          ...electionPricingOptionFields
        }
      }
    }
  }

  ${ELECTION_INSURED_FIELDS}
  ${ELECTION_VEHICLE_FIELDS}
  ${EXPOSURE_ELECTION_FIELDS}
  ${ELECTION_COMPLIANCE_FIELDS}
  ${ELECTION_PRICING_OPTION_FIELDS}
`;

export default function usePolicyExposureElections({ policyId, searchBy, electionStatus, ordering, inCompliance }) {
  const [fetchPolicyElections, { data, loading, error, paginationProps, queryProps, loadFirstPage }] =
    usePaginatedLazyQuery(GET_POLICY_EXPOSURE_ELECTIONS, {
      fetchPolicy: 'cache-and-network',
      pathToPageInfo: 'listExposureElections',
      pageSize: 20,
      variables: {
        policyId,
        ordering,
        searchBy,
        electionStatus,
        inCompliance
      }
    });

  useEffect(() => {
    loadFirstPage({
      policyId,
      ordering,
      searchBy,
      electionStatus,
      inCompliance
    });
  }, [loadFirstPage, searchBy, policyId, ordering, electionStatus, inCompliance]);

  useSubscription(ON_INSURED_CREATED, {
    skip: !policyId,
    variables: { policyId },
    onSubscriptionData({ client, subscriptionData: { data } }) {
      const { listExposureElections } = client.readQuery({
        query: GET_POLICY_EXPOSURE_ELECTIONS,
        variables: { ...queryProps }
      });

      const incomingEdges = data?.insured?.elections
        ?.filter(election => election?.policyId === policyId)
        .map(election => ({ node: election, __typename: 'InsuredEdge' }));
      const edgesWithoutIncomingInsured = listExposureElections.edges.filter(
        ({
          node: {
            insured: { id }
          }
        }) => id !== data?.insured?.id
      );
      const edges = [...incomingEdges, ...edgesWithoutIncomingInsured].slice(0, 10);

      client.writeQuery({
        query: GET_POLICY_EXPOSURE_ELECTIONS,
        variables: { ...queryProps },
        data: {
          listExposureElections: {
            ...listExposureElections,
            edges: edges
          }
        }
      });
    }
  });

  return {
    coverages: data?.listExposureElections?.edges?.map(({ node }) => node) || [],
    fetchNewestCoverages: () => {
      fetchPolicyElections({
        skip: !policyId,
        variables: { ...queryProps, policyId, ordering: 'NEWEST_ELECTION' },
        fetchPolicy: 'network-only'
      });
    },
    loading,
    error,
    paginationProps,
    queryProps
  };
}
