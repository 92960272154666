import * as yup from 'yup';
import moment from 'moment';

import { VARIABLE_TYPES } from '../constants';

export default function useGenerateReportFormSteps({ report, formOptions }) {
  const { INT, DECIMAL, ID, FLOAT, STRING, BOOLEAN, DATE, ENUM } = VARIABLE_TYPES;

  const getVariableInitialValue = (name, type) => {
    return {
      [INT]: '',
      [DECIMAL]: '',
      [ID]: '',
      [FLOAT]: '',
      [STRING]: '',
      [BOOLEAN]: false,
      [DATE]: moment(),
      [ENUM]: formOptions[name].options[0]
    }[type];
  };

  const getVariableValidationSchema = (type, required) => {
    return {
      [INT]: required ? yup.string().required('Required') : yup.string(),
      [DECIMAL]: required ? yup.string().required('Required') : yup.string(),
      [ID]: required ? yup.string().required('Required') : yup.string(),
      [FLOAT]: required ? yup.string().required('Required') : yup.string(),
      [STRING]: required ? yup.string().required('Required') : yup.string(),
      [BOOLEAN]: required ? yup.boolean().required('Required') : yup.boolean(),
      [DATE]: required ? yup.date().required('Required') : yup.date(),
      [ENUM]: required ? yup.string().required('Required') : yup.string()
    }[type];
  };

  const getInitialValues = variables =>
    variables?.reduce(
      (acc, variable) => ({ ...acc, [variable?.name]: getVariableInitialValue(variable?.name, variable?.type) }),
      {}
    );

  const getValidationSchema = variables =>
    variables?.reduce(
      (acc, variable) => ({
        ...acc,
        [variable?.name]: getVariableValidationSchema(variable?.type, variable?.required)
      }),
      {}
    );

  const formSteps = [
    {
      name: report?.name,
      initialValues: { id: report?.id, ...getInitialValues(report?.variables) },
      validationSchema: yup.object().shape({
        ...getValidationSchema(report?.variables)
      })
    }
  ];

  return { formSteps };
}
