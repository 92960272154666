import React from 'react';
import { TrLoader, TrPlaceholder } from '@oforce/global-components';

import { Table } from '../../../components/table-2.0';
import { RenderData, TrGraphQLErrors } from '../../../components';

import { FeeRow } from '.';
import { useFees } from '../hooks';
import {
  ACCOUNT_ROLES,
  ACCOUNT_TYPES,
  HideFromRoles,
  ShowForTypes,
  useActiveAccount
} from '../../../shared/activeAccount';

export default function FeesTable({ product }) {
  const { showForTypes, hideFromRoles } = useActiveAccount();
  const { CARRIER } = ACCOUNT_TYPES;
  const { EXPOSURE_MANAGER } = ACCOUNT_ROLES;

  const { fees, loading, error } = useFees({ productId: product?.id });

  const showExtraColumn = showForTypes([CARRIER]) && !hideFromRoles([EXPOSURE_MANAGER]);
  const colSpan = showExtraColumn ? 4 : 3;

  return (
    <Table>
      <thead>
        <tr>
          <th>Fee</th>
          <th>Rate Mode</th>
          <th>Applies to</th>
          <th>Prorate</th>
          <ShowForTypes types={[CARRIER]}>
            <HideFromRoles roles={[EXPOSURE_MANAGER]}>
              <th>Actions</th>
            </HideFromRoles>
          </ShowForTypes>
        </tr>
      </thead>
      <tbody>
        <RenderData
          data={fees}
          loading={loading}
          error={error}
          loadingComponent={<TrLoader colSpan={colSpan} />}
          errorComponent={<TrGraphQLErrors colSpan={colSpan} error={error} />}
          placeholderComponent={<TrPlaceholder colSpan={colSpan}>No fees</TrPlaceholder>}
        >
          {fees => fees?.map(fee => <FeeRow key={fee?.id} product={product} fee={fee} />)}
        </RenderData>
      </tbody>
    </Table>
  );
}
