import React from 'react';
import { Formik } from 'formik';
import { Modal } from '@oforce/global-components';

import { GraphQLErrors } from '../../../components';
import { AddOnForm } from '.';
import { useAddOnValidationSchema } from '../../addOns/hooks';

export default function AddOnCreate({
  isOpen,
  toggle,
  error,
  loading,
  reset,
  initialValues,
  handleSubmit,
  pricingSelectOptions
}) {
  const validationSchema = useAddOnValidationSchema();

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values, { resetForm }) => {
        await handleSubmit(values);
        resetForm(initialValues);
      }}
    >
      {formik => (
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          title="Create Add-On"
          loading={loading}
          onSubmit={formik.handleSubmit}
          submitButtonText="Create Add-On"
          submitButtonDisabled={formik.isSubmitting}
          onOpened={() => {
            formik.resetForm();
            reset();
          }}
        >
          <GraphQLErrors error={error} />
          <AddOnForm formik={formik} pricingSelectOptions={pricingSelectOptions} />
        </Modal>
      )}
    </Formik>
  );
}
