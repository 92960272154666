import React from 'react';
import { Button, Loader } from '@oforce/global-components';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { TabPage } from '../../components/tabs';
import { ProductPricingCreateUpdate, ProductPricingSync } from '../../views';
import { BoxContainer, GraphQLErrors } from '../../components';
import { HideFromRoles, ShowForTypes, useActiveAccount } from '../../shared/activeAccount';
import { PricingsTable, PricingUpdate } from './components';
import { useProductPricings } from './hooks';

export default function ProductPricing({ product }) {
  const history = useHistory();
  const { url, path } = useRouteMatch();

  const {
    TYPES: { CARRIER },
    ROLES: { EXPOSURE_MANAGER }
  } = useActiveAccount();

  const { pricings, loading, error } = useProductPricings({
    productId: product?.id
  });

  if (loading) return <Loader className={'loading p-3 p-md-5'} />;
  if (error) return <GraphQLErrors error={error} />;

  return (
    <Switch>
      <Route exact path={path}>
        <TabPage
          title="Pricing"
          buttons={[
            <Button
              key="syncProductPricingOptions"
              color="info"
              icon={['far', 'sync-alt']}
              className="ml-2"
              onClick={() => history.push(`${url}/sync`)}
            >
              Sync to Policy Pricing Options
            </Button>,
            <ShowForTypes key="newProductPricingOption" types={[CARRIER]}>
              <HideFromRoles roles={[EXPOSURE_MANAGER]}>
                <Button color="info" icon={['far', 'plus']} className="ml-2" onClick={() => history.push(`${url}/new`)}>
                  Create
                </Button>
              </HideFromRoles>
            </ShowForTypes>
          ]}
        >
          <BoxContainer>
            <PricingsTable pricings={pricings} product={product} />
          </BoxContainer>
        </TabPage>
      </Route>
      <Route path={`${url}/new`}>
        <ProductPricingCreateUpdate productId={product?.id} />
      </Route>
      <Route path={`${url}/:pricingId/update`}>
        <PricingUpdate pricings={pricings} productId={product?.id} />
      </Route>
      <Route path={`${url}/sync`}>
        <ProductPricingSync productId={product?.id} />
      </Route>
    </Switch>
  );
}
