import React from 'react';
import VehicleCoverageTableRow from './VehicleCoverageTableRow';
import { RenderData, TrGraphQLErrors } from '../../../../components';
import { TrLoader, TrPlaceholder } from '@oforce/global-components';
import { Table } from '../../../../components/table-2.0';
import { useActiveAccount } from '../../../../shared/activeAccount';
import { HideFromRolesAndTypes } from '../../../../shared/activeAccount/components';

export default function VehiclePolicyCoveragesTable({ coverages, policy, fetchNewestCoverages, loading, error }) {
  const colSpan = 11;
  const {
    ROLES: { OBSERVER },
    TYPES: { CLAIMS_ADJUSTER }
  } = useActiveAccount();

  return (
    <Table>
      <thead>
        <tr>
          <th colSpan="1">Status</th>
          <th colSpan="1">Vehicle</th>
          <th colSpan="1">Owner</th>
          <th colSpan="1">Coverage Option</th>
          <th colSpan="1">Premium</th>
          <th colSpan="1">Coverage Dates</th>
          <HideFromRolesAndTypes roles={[OBSERVER]} types={[CLAIMS_ADJUSTER]}>
            <th colSpan="1">Actions</th>
          </HideFromRolesAndTypes>
        </tr>
      </thead>
      <tbody>
        <RenderData
          data={coverages}
          loading={loading}
          error={error}
          loadingComponent={<TrLoader colSpan={colSpan} className="py-0" />}
          errorComponent={<TrGraphQLErrors colSpan={colSpan} error={error} />}
          placeholderComponent={<TrPlaceholder colSpan={colSpan}>No Coverages</TrPlaceholder>}
        >
          {vehicleCoverages =>
            vehicleCoverages?.map(coverage => (
              <VehicleCoverageTableRow
                key={coverage?.id}
                fetchNewestCoverages={fetchNewestCoverages}
                coverage={coverage}
                policy={policy}
              />
            ))
          }
        </RenderData>
      </tbody>
    </Table>
  );
}
