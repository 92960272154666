import { createContext } from 'react';

export default createContext({
  currentUser: {
    id: '',
    avatar: '',
    name: '',
    email: '',
    isAdmin: true,
    activeAccount: {
      id: '',
      name: '',
      role: '',
      type: ''
    },
    enabledFeatures: []
  }
});
