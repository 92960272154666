import React from 'react';
import { Alert } from 'reactstrap';
import { Steps } from 'react-step-builder';
import { useHistory, useParams } from 'react-router-dom';
import { Render } from '@oforce/global-components';

import { useFormOptions } from '../../hooks';
import { titleCase } from '../../utils/formatting';
import { FormSection, MultiStepForm } from '../../components';

import { VALID_TYPES } from './constants';
import { VariableInputField } from './components';
import { useGenerateReportFormSteps, useGenerateReportFormSubmit } from './hooks';

export default function ReportGenerate({ reports }) {
  const history = useHistory();
  const { reportId } = useParams();

  const report = reports.find(r => r.id === reportId);
  const reportTypesInvalid = report?.variables?.some(({ type }) => !VALID_TYPES.includes(type));

  const formOptions = useFormOptions({
    options: report?.variables?.map(variable => ({
      name: variable?.name,
      options: variable?.possibleValues?.map(o => ({ label: titleCase(o), value: o })) || []
    }))
  });

  const { formSteps } = useGenerateReportFormSteps({ report, formOptions });
  const { formSubmit, loading, error } = useGenerateReportFormSubmit({ report });

  return (
    <MultiStepForm
      title="Generate Report"
      submitButtonText="Generate Report"
      showProgressBar={false}
      onCancel={() => history.push('/reports')}
      submitButtonProps={{ color: 'info', loading, loadingText: 'Generating...' }}
      showButtonsOnTop={false}
      showButtonsOnBottom={true}
      formSteps={formSteps}
      formSubmit={formSubmit}
      error={error}
    >
      {formProps => (
        <Steps>
          <FormSection title={report?.name} className="mb-0" {...formProps}>
            <Render if={reportTypesInvalid}>
              <Alert color="danger">
                Oops, it looks like one or more of the input types for this report are not currently supported. Please
                try generating a new report.
              </Alert>
            </Render>
            <Render if={!reportTypesInvalid}>
              {report?.variables?.map((variable, index) => (
                <VariableInputField
                  {...variable}
                  showFocusRef={index === 0}
                  focusRef={formProps?.focusRef}
                  options={formOptions[variable?.name].options}
                />
              ))}
            </Render>
          </FormSection>
        </Steps>
      )}
    </MultiStepForm>
  );
}
