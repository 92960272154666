import { gql, useMutation } from '@apollo/client';

export const SET_INCLUDE_STATE_FEES = gql`
  mutation StateFees($policyId: ID!, $includeStateFees: Boolean!) {
    setIncludeStateFees(policyId: $policyId, includeStateFees: $includeStateFees) {
      id
      includeStateFees
    }
  }
`;

export default function useSetIncludeStateFees() {
  const [stateFees, { loading, error }] = useMutation(SET_INCLUDE_STATE_FEES, {
    onCompleted: () => {},
    onError: () => {}
  });

  return {
    stateFees: variables => stateFees({ variables }),
    loading,
    error
  };
}
