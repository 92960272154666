import React from 'react';
import { Helmet } from 'react-helmet';
import { Button } from '@oforce/global-components';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';

import { TabPage } from '../../components/tabs';
import { Company, CompanyCreateUpdate } from '../../views';
import { PageBody, PageTitle, PageHeader } from '../../components/page-components';

import { useCompanies } from './hooks';
import { CompaniesTable } from './components';

export default function Companies() {
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const { companies, loading, error } = useCompanies();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Helmet>
          <title>Companies • IC Insure</title>
        </Helmet>
        <PageHeader>
          <PageTitle>Companies</PageTitle>
        </PageHeader>
        <PageBody>
          <TabPage
            buttons={
              <Button color="info" onClick={() => history.push(`${url}/new`)} icon={['far', 'plus']}>
                Create
              </Button>
            }
          >
            <CompaniesTable companies={companies} loading={loading} error={error} />
          </TabPage>
        </PageBody>
      </Route>

      <Route path={`${path}/new`}>
        <Helmet>
          <title>Create Company • IC Insure</title>
        </Helmet>
        <PageHeader>
          <PageTitle>Companies</PageTitle>
        </PageHeader>
        <PageBody>
          <CompanyCreateUpdate />
        </PageBody>
      </Route>
      <Route path={`${path}/:companyId`}>
        <Company />
      </Route>
    </Switch>
  );
}
