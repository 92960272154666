import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';

export const GET_MAPPABLE_INVOICE_DOCUMENT_FIELDS = gql`
  query mappableInvoiceDocumentFields {
    mappableInvoiceDocumentFields
  }
`;

export default function useMappableInvoiceDocumentFields() {
  const { loading, error, data } = useQuery(GET_MAPPABLE_INVOICE_DOCUMENT_FIELDS);

  return {
    loading,
    error,
    fields: data?.mappableInvoiceDocumentFields
  };
}
