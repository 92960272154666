import { gql, useQuery } from '@apollo/client';

import { backendNameToLabel } from '../../../utils/formatting';

const GET_ACCOUNT_TYPES = gql`
  query GetAccountTypes {
    accountTypes
  }
`;

export default function useAccountTypes() {
  const { data, loading, error } = useQuery(GET_ACCOUNT_TYPES);

  const accountTypeOptions =
    data?.accountTypes
      ?.filter(x => !['CLIENT_MANAGER', 'BROKER_MANAGER'].includes(x))
      .map(type => {
        const label = backendNameToLabel(type);

        return { value: type, label };
      }) || [];

  return { accountTypes: data?.accountTypes, accountTypeOptions, loading, error };
}
