import { useCurrentUser } from '../../../shared/currentUser';

export default function useFeeRateModes() {
  const { enabledFeatures } = useCurrentUser();
  const isEnabled = enabledFeatures.includes('PREMIUM_PLUS');

  const feeRateModeOptions = [
    {
      label: 'Flat',
      value: 'FLAT'
    },
    {
      label: 'Percentage of Premium',
      value: 'PERCENTAGE'
    },
    {
      label: 'Percentage of Premium PLUS',
      value: 'PREMIUM_PLUS'
    }
  ];

  const feeRateModeOptionsOriginal = [
    {
      label: 'Flat',
      value: 'FLAT'
    },
    {
      label: 'Percentage of Premium',
      value: 'PERCENTAGE'
    }
  ];

  return {
    feeRateModeOptions: isEnabled ? feeRateModeOptions : feeRateModeOptionsOriginal
  };
}
