import React from 'react';
import { FormGroup } from 'reactstrap';
import { Field, FieldArray, Form } from 'formik';
import {
  Button,
  FieldLabel,
  Input,
  InputField,
  ReactSelectField,
  Render,
  SwitchField
} from '@oforce/global-components';

import { GraphQLErrors } from '../../../components';

export default function AccountForm({ formik, focusRef, isCreateMode, formOptions, roleFromInvitation, error }) {
  return (
    <Form>
      <GraphQLErrors error={error} />
      <InputField name="name" label="Name" required innerRef={focusRef} />
      <ReactSelectField
        label="Account Type"
        name="type"
        required
        options={formOptions?.accountType?.options}
        isLoading={formOptions?.accountType?.loading}
        isDisabled={!isCreateMode || !!roleFromInvitation}
      />
      <SwitchField
        id="allow-report-access-to-client"
        className="mb-3"
        label="Allow Report Access to Client"
        name="allowReportAccessToClient"
        size="sm"
        switchLabel={formik?.values?.allowReportAccessToClient ? 'Yes' : 'No'}
      />
      <Render if={!isCreateMode}>
        <FieldArray name="notificationEmailAddresses">
          {arrayHelpers => (
            <FormGroup>
              <FieldLabel name="Notification Recipients" />
              {formik?.values?.notificationEmailAddresses?.map((email, index) => {
                const arrayLength = formik?.values?.notificationEmailAddresses?.length - 1;
                return (
                  <div className="d-flex justify-content-between mb-2" key={email}>
                    <Field
                      as={Input}
                      name={`notificationEmailAddresses.${index}`}
                      type="email"
                      placeholder="Enter an email address"
                    />
                    <Render if={index !== arrayLength}>
                      <Button
                        color="danger"
                        outline
                        icon={['far', 'minus']}
                        className="ml-2"
                        onClick={() => arrayHelpers.remove(index)}
                      />
                    </Render>
                    <Render if={index === arrayLength}>
                      <Button
                        color="info"
                        outline
                        icon={['far', 'plus']}
                        className="ml-2"
                        onClick={() => {
                          arrayHelpers.push('');
                        }}
                      />
                    </Render>
                  </div>
                );
              })}
            </FormGroup>
          )}
        </FieldArray>
      </Render>
    </Form>
  );
}
