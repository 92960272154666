import { useHistory } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';

import { isUUID } from '../../../utils/regex';
import { convertPercentageToDecimal, convertToFloat } from '../../../utils';
import { fragments } from '../../productPricing/hooks/useProductPricings';
import { GET_PRODUCT_PRICING_OPTIONS_COUNT } from '../../product/hooks/useProduct';
import { useParseCoverageParameters } from '../../coverageParameters/hooks';

export const GET_PRODUCT_FACTORABLE_FIELDS = gql`
  query product($id: ID!) {
    product(id: $id) {
      id
      hasApiFactoredField
    }
  }
`;

export const CREATE_PRICING_OPTION = gql`
  mutation CreateProductPricingOption($input: CreateProductPricingOptionInput!) {
    createPricingOption: createProductPricingOption(input: $input) {
      id
      pricingOptions {
        ...ProductPricingOption
      }
    }
  }
  ${fragments.productPricingOption}
`;

export const UPDATE_PRICING_OPTION = gql`
  mutation UpdateProductPricingOption($input: EditProductPricingOptionInput!) {
    updatePricingOption: editProductPricingOption(input: $input) {
      id
      pricingOptions {
        ...ProductPricingOption
      }
    }
  }
  ${fragments.productPricingOption}
`;

export default function useProductPricingFormSubmit({ coverageParameters, isCreateMode, productId, pricing }) {
  const history = useHistory();
  const { normalizeParameterValuesForSubmit } = useParseCoverageParameters();

  const normalizeParamValues = values => {
    return Object.keys(values).reduce(
      (acc, key) => {
        if (isUUID(key)) {
          const coverageParameters = [...acc.coverageParameters, { id: key, value: values[key].toString() }];
          return { ...acc, coverageParameters };
        }
        return { ...acc, [key]: values[key] };
      },
      { coverageParameters: [] }
    );
  };

  const [create, { loading: creating, error: createError }] = useMutation(CREATE_PRICING_OPTION, {
    onCompleted: () => history.push(`/products/${productId}/pricing`),
    onError: () => {},
    refetchQueries: [{ query: GET_PRODUCT_PRICING_OPTIONS_COUNT, variables: { productId } }]
  });

  const [update, { loading: updating, error: updateError }] = useMutation(UPDATE_PRICING_OPTION, {
    onCompleted: () => history.push(`/products/${productId}/pricing`),
    onError: () => {},
    refetchQueries: [{ query: GET_PRODUCT_FACTORABLE_FIELDS, variables: { id: productId } }]
  });

  function formSubmit({
    percentageMinimum,
    percentageMaximum,
    rateMode,
    factoredField,
    vehicleTypes,
    isPercentageFactor,
    rate,
    label,
    name,
    ...values
  }) {
    const formValues = normalizeParameterValuesForSubmit(pricing ?? { coverageParameters }, { formValues: values });

    const normalizedValues = {
      ...formValues,
      label,
      name,
      percentageMaximum: percentageMaximum !== '' ? convertToFloat(percentageMaximum) : null,
      percentageMinimum: percentageMinimum !== '' ? convertToFloat(percentageMinimum) : null,
      rateMode: rateMode.value,
      factoredField: factoredField ? factoredField?.value : null,
      vehicleTypeIds: vehicleTypes?.map(type => type.value) || [],
      rate: isPercentageFactor ? convertPercentageToDecimal(rate) : convertToFloat(rate),
      isPercentageFactor
    };
    const pricingInput = normalizeParamValues(normalizedValues);

    if (isCreateMode) {
      create({
        variables: { input: { productId, ...pricingInput } }
      });
    } else {
      update({
        variables: { input: { productPricingOptionId: pricing?.id, ...pricingInput } }
      });
    }
  }

  return {
    formSubmit,
    loading: creating || updating,
    error: createError || updateError
  };
}
