import React from 'react';
import { TrLoader, TrPlaceholder } from '@oforce/global-components';
import { Table } from '../../../components/table-2.0';
import { RenderData, TrGraphQLErrors } from '../../../components';
import { DocumentsTableRow } from '.';

export default function DocumentsTable({ documents, loading, error }) {
  const colSpan = 5;

  return (
    <Table>
      <thead>
        <tr>
          <th style={{ width: '30%' }}>Document</th>
          <th style={{ width: '10%' }}>Effective Date</th>
          <th style={{ width: '10%' }}>Expiration Date</th>
          <th style={{ width: '25%' }}>Filename</th>
          <th style={{ width: '20%' }}>Applies to</th>
          <th className={'text-right'} style={{ width: '5%' }}>
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        <RenderData
          data={documents}
          loading={loading}
          error={error}
          loadingComponent={<TrLoader colSpan={colSpan} />}
          errorComponent={<TrGraphQLErrors colSpan={colSpan} error={error} />}
          placeholderComponent={
            <TrPlaceholder colSpan={colSpan} className="bg-gray-100 border-bottom border-width-2 border-gray-300">
              No documents
            </TrPlaceholder>
          }
        >
          {documents => documents?.map(doc => <DocumentsTableRow key={doc.id} clientDocument={doc} />)}
        </RenderData>
      </tbody>
    </Table>
  );
}
