import { gql, useQuery } from '@apollo/client';

export const GET_CLIENT_DOCUMENT = gql`
  query clientDocument($id: ID!) {
    clientDocument(id: $id) {
      id
      name
      clientId
      effectiveDate
      expirationDate
      document {
        id
        url
        type
        filename
      }
      policies {
        id
        name
      }
    }
  }
`;

export default function useClientDocument({ clientDocumentId, onCompleted = () => {}, onError = () => {} }) {
  const { loading, error, data } = useQuery(GET_CLIENT_DOCUMENT, {
    skip: !clientDocumentId,
    variables: { id: clientDocumentId },
    onCompleted,
    onError
  });

  return {
    loading,
    error,
    clientDocument: data?.clientDocument
  };
}
