import React from 'react';
import { Button, useToggle } from '@oforce/global-components';

import { useDownloadFile } from '../../../hooks';
import { BoxContainer, BoxContainerTitle, BoxItem, GraphQLErrors, Render } from '../../../components';

import { UploadInvoiceTemplate } from '.';

export default function DefaultInvoiceTemplate({ template, loading, error }) {
  const [showUploadTemplate, toggleUploadTemplate] = useToggle();
  const { download, loading: downloading } = useDownloadFile({ filename: template?.filename, url: template?.url });

  return (
    <>
      <BoxContainerTitle>
        <div className="d-flex align-items-center justify-content-between">
          Default Invoice Template
          <Button color="link" icon={['far', 'file-upload']} className="p-0" onClick={toggleUploadTemplate}>
            Replace
          </Button>
        </div>
      </BoxContainerTitle>
      <BoxContainer>
        <Render if={!!error}>
          <GraphQLErrors error={error} />
        </Render>
        <BoxItem>
          <Button
            color="link"
            icon={['far', 'arrow-to-bottom']}
            onClick={download}
            loading={downloading || loading}
            loadingText={downloading ? 'Downloading...' : 'Loading...'}
          >
            {template?.filename}
          </Button>
        </BoxItem>
      </BoxContainer>

      <UploadInvoiceTemplate isOpen={showUploadTemplate} toggle={toggleUploadTemplate} />
    </>
  );
}
