import React from 'react';
import cn from 'classnames';
import { Render } from '@oforce/global-components';

export default function FormSection({ title, subtitle, className, optional = false, children }) {
  const formSectionClasses = cn({ 'mb-5': !className }, className);

  return (
    <div className={formSectionClasses}>
      <h6 className={subtitle ? 'mb-1' : 'mb-3'}>
        {title}
        <Render if={optional}>
          <span className="ml-2 text-muted font-weight-400 fs-xs">(optional)</span>
        </Render>
      </h6>
      <Render if={!!subtitle}>
        <div className="font-weight-400">{subtitle}</div>
      </Render>
      <hr />
      {children}
    </div>
  );
}
