import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';

export const GET_ACCOUNT_INVITATION = gql`
  query accountInvitation($invitationId: ID!) {
    accountInvitation(id: $invitationId) {
      id
      role
      status
      emailAddress
      created
      account {
        id
        name
        type
      }
    }
  }
`;

function useAccountInvitation({ invitationId }) {
  const { loading, error, data } = useQuery(GET_ACCOUNT_INVITATION, {
    skip: !invitationId,
    variables: { invitationId }
  });

  return {
    loading,
    error,
    invitation: data?.accountInvitation
  };
}

export default useAccountInvitation;
