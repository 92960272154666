import { gql, useQuery } from '@apollo/client';

const GET_POLICIES_REQUIRED_FIELDS = gql`
  query RequiredFields($policyIds: [ID]!) {
    requiredFields: listPoliciesRequiredFields(policyIds: $policyIds) {
      requiredInsuredFields
      requiredVehicleFields
    }
  }
`;

export default function usePoliciesRequiredFields({ policyIds }) {
  const { data, loading, error } = useQuery(GET_POLICIES_REQUIRED_FIELDS, {
    variables: { policyIds }
  });

  const requiredVehicleFields = data?.requiredFields?.requiredVehicleFields.reduce((acc, fieldName) => {
    acc[fieldName] = true;
    return acc;
  }, {});

  const requiredInsuredFields = data?.requiredFields?.requiredInsuredFields.reduce((acc, fieldName) => {
    acc[fieldName] = true;
    return acc;
  }, {});

  return { requiredVehicleFields, requiredInsuredFields, loading, error };
}
