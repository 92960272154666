import * as yup from 'yup';

import { GET_VEHICLE_TYPES } from '..';
import { useCreateVehicleType } from '.';

export default function useCreateVehicleTypeForm({ onCreation }) {
  const { loading, error, createVehicleType } = useCreateVehicleType({
    getVehicleTypesQuery: GET_VEHICLE_TYPES,
    onCompleted: onCreation
  });

  const initialValues = { name: '' };

  const validationSchema = yup.object({
    name: yup.string().required('Required')
  });

  const handleSubmit = ({ name }) => createVehicleType(name);

  return {
    initialValues,
    validationSchema,
    handleSubmit,
    loading,
    error
  };
}
