import React from 'react';

export default function Td({
  verticalAlign = 'middle',
  children,
  placeholderText = 'None',
  type = 'text',
  colSpan,
  className,
  ...rest
}) {
  return (
    <td
      colSpan={colSpan}
      vertical-align={verticalAlign}
      className={`valign-${verticalAlign} ${className ? className : ''}`}
      {...rest}
    >
      {children || <span className="text-gray-500 fs-xs font-weight-400">{placeholderText}</span>}
    </td>
  );
}
