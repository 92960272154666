import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { Modal } from '@oforce/global-components';

import { dateToClientSideFormat } from '../../../utils/dates';
import { GraphQLErrors } from '../../../components';
import { GET_EXPOSURE_ELECTION_ACTION_PERMISSIONS } from '../../../components/quickActions/useQuickActionPermissions';

export const CLEAR_TERMINATION = gql`
  mutation TerminateExposure($exposureElectionId: ID!) {
    clearPendingTermination(exposureElectionId: $exposureElectionId) {
      id
      terminationDate
      status
    }
  }
`;

const CLEAR_TERMINATION_FRAGMENT = gql`
  fragment ExposureClearTermination on ExposureElection {
    id
    terminationDate
    status
  }
`;

export default function ExposureClearPendingTermination({
  isOpen,
  toggle,
  exposureElectionId,
  terminationDate,
  onError = () => {}
}) {
  const [clearPendingTermination, { loading, error, reset }] = useMutation(CLEAR_TERMINATION, {
    variables: { exposureElectionId },
    onCompleted: toggle,
    onError,
    refetchQueries: [{ query: GET_EXPOSURE_ELECTION_ACTION_PERMISSIONS, variables: { id: exposureElectionId } }],
    update(cache, { data: { clearPendingTermination } }) {
      cache.writeFragment({
        id: `ExposureElection:${exposureElectionId}`,
        fragment: CLEAR_TERMINATION_FRAGMENT,
        data: { ...clearPendingTermination }
      });
    }
  });

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      title="Clear Pending Termination"
      cancelButtonText="No, Cancel"
      submitButtonText="Yes, Clear Pending Termination"
      submitButtonProps={{ color: 'danger', loading, loadingText: 'Clearing...' }}
      onSubmit={clearPendingTermination}
      onOpened={reset}
    >
      <GraphQLErrors error={error} />
      This exposure is scheduled to be terminated on {dateToClientSideFormat(terminationDate)}. Are you sure you want to
      clear this pending termination?
    </Modal>
  );
}
