import React from 'react';
import { Loader, useToggle } from '@oforce/global-components';
import { ExposureElectionChangePricing } from '../../views/exposureElections/components';
import { QuickActionsEditStartDate, QuickActionButton, useQuickActionPermissions } from '.';
import { ElectionData } from '../../views/insuredElections/components';
import {
  ExposureClearPendingTermination,
  ExposureEditStartDate,
  ExposureReactivate,
  ExposureTerminate,
  ExposureUpdateContractType
} from '../../views/exposureElections/containers';
import { HideFromTypes, ACCOUNT_TYPES, useActiveAccount, ShowForRoles, ShowForTypes } from '../../shared/activeAccount';
import {
  ADD_TERM_DATE,
  CHANGE_PRICING_OPTION,
  CLEAR_TERM_DATE,
  OVERRIDE,
  REACTIVATE,
  REJECT,
  UPDATE_START_DATE,
  UPDATE_CONTRACT_TYPE,
  UNREJECT
} from './quickActionConstants';
import { ApprovalModal, RejectModal, UnRejectModal } from '../../views/coverages/components/pendingApproval';
import { FeatureFlag } from '../../shared/activeAccount/components';
import { FLAGGED_FEATURES } from '../../shared/activeAccount/constants';

const { CLIENT } = ACCOUNT_TYPES;

export default function QuickActions({ election, onReactivated, className }) {
  const [showCustomData, toggleCustomData] = useToggle();
  const [showEditStartDate, toggleEditStartDate] = useToggle();
  const [showCancelTermination, toggleCancelTermination] = useToggle();
  const [showTerminateExposure, toggleTerminateExposure] = useToggle();
  const [showReactivateExposure, toggleReactivateExposure] = useToggle();
  const [showChangePricingOption, toggleChangePricingOption] = useToggle();
  const [approvalModalOpen, toggleApprovalModal] = useToggle(false);
  const [rejectModalOpen, toggleRejectModal] = useToggle(false);
  const [unRejectModalOpen, toggleUnRejectModal] = useToggle(false);
  const [contractTypeModalOpen, toggleUpdateContractType] = useToggle(false);

  const currentPricingOption = election?.pricingOption;
  const currentPricingOptionId = currentPricingOption?.id;

  const { actionAllowed, loading, noAllowedActions } = useQuickActionPermissions({
    exposureElectionId: election?.id,
    makeCall: true
  });

  const {
    roleIsAtLeast,
    ROLES: { ADMIN, COLLABORATOR, EXPOSURE_MANAGER },
    TYPES: { BROKER, CARRIER }
  } = useActiveAccount();

  if (loading) return <Loader size="sm" className="p-0 m-0" />;
  if (!loading && noAllowedActions) return <></>;
  return (
    <div className={`d-flex flex-column ${className}`}>
      <ShowForTypes types={[CARRIER, BROKER]}>
        <ShowForRoles roles={[COLLABORATOR, ADMIN]}>
          <>
            <QuickActionButton
              render={actionAllowed(UNREJECT)}
              onClick={toggleUnRejectModal}
              text="Un-Reject"
              textColor="warning"
            />
            <QuickActionButton
              render={actionAllowed(REJECT)}
              onClick={toggleRejectModal}
              text="Reject"
              textColor="danger"
            />
            <QuickActionButton
              render={actionAllowed(OVERRIDE)}
              onClick={toggleApprovalModal}
              text="Approve"
              textColor="success"
            />
          </>
        </ShowForRoles>
      </ShowForTypes>
      <QuickActionsEditStartDate
        render={actionAllowed(UPDATE_START_DATE) && roleIsAtLeast(EXPOSURE_MANAGER)}
        election={election}
        onClick={toggleEditStartDate}
      />
      <QuickActionButton
        render={actionAllowed(REACTIVATE) && roleIsAtLeast(EXPOSURE_MANAGER)}
        onClick={toggleReactivateExposure}
        text="Reactivate"
        color="success"
      />
      <QuickActionButton
        render={actionAllowed(ADD_TERM_DATE) && roleIsAtLeast(EXPOSURE_MANAGER)}
        textColor={election?.terminationDate ? 'info' : 'danger'}
        onClick={toggleTerminateExposure}
        text={election?.terminationDate ? 'Change Termination' : 'Terminate'}
      />

      <HideFromTypes types={[CLIENT]}>
        <QuickActionButton
          render={actionAllowed(CHANGE_PRICING_OPTION) && roleIsAtLeast(EXPOSURE_MANAGER)}
          disabled={!currentPricingOption}
          onClick={toggleChangePricingOption}
          text="Change Pricing"
        />
      </HideFromTypes>
      <QuickActionButton
        render={actionAllowed(CLEAR_TERM_DATE) && roleIsAtLeast(EXPOSURE_MANAGER)}
        onClick={toggleCancelTermination}
        text="Clear termination"
      />
      <FeatureFlag feature={FLAGGED_FEATURES.MASTER_SUB_RELATIONSHIP}>
        <QuickActionButton
          render={actionAllowed(UPDATE_CONTRACT_TYPE) && roleIsAtLeast(EXPOSURE_MANAGER)}
          onClick={toggleUpdateContractType}
          text="Change Contract Type"
        />
      </FeatureFlag>
      <QuickActionButton onClick={toggleCustomData} text="Custom data" />
      <FeatureFlag feature={FLAGGED_FEATURES.MASTER_SUB_RELATIONSHIP}>
        {contractTypeModalOpen && (
          <ExposureUpdateContractType
            election={election}
            isOpen={contractTypeModalOpen}
            toggle={toggleUpdateContractType}
          />
        )}
      </FeatureFlag>
      {showEditStartDate && (
        <ExposureEditStartDate
          election={election}
          isOpen={showEditStartDate}
          toggle={toggleEditStartDate}
          policy={election?.policy}
        />
      )}
      {showTerminateExposure && (
        <ExposureTerminate
          isOpen={showTerminateExposure}
          toggle={toggleTerminateExposure}
          election={election}
          policy={election?.policy}
        />
      )}
      {showCancelTermination && (
        <ExposureClearPendingTermination
          exposureElectionId={election?.id}
          terminationDate={election?.terminationDate}
          isOpen={showCancelTermination}
          toggle={toggleCancelTermination}
        />
      )}
      {showReactivateExposure && (
        <ExposureReactivate
          policy={election?.policy}
          exposureElection={election}
          isOpen={showReactivateExposure}
          toggle={toggleReactivateExposure}
          onCompleted={onReactivated}
        />
      )}
      {showChangePricingOption && (
        <ExposureElectionChangePricing
          currentPricingOptionId={currentPricingOptionId}
          election={election}
          policy={election?.policy}
          isOpen={showChangePricingOption}
          toggle={toggleChangePricingOption}
        />
      )}
      {showCustomData && <ElectionData isOpen={showCustomData} toggle={toggleCustomData} election={election} />}
      {approvalModalOpen && (
        <ApprovalModal
          isOpen={approvalModalOpen}
          toggle={toggleApprovalModal}
          election={election}
          electionId={election.id}
        ></ApprovalModal>
      )}
      {rejectModalOpen && (
        <RejectModal isOpen={rejectModalOpen} toggle={toggleRejectModal} election={election}></RejectModal>
      )}
      {unRejectModalOpen && (
        <UnRejectModal isOpen={unRejectModalOpen} toggle={toggleUnRejectModal} election={election}></UnRejectModal>
      )}
    </div>
  );
}
