import { useStateFeeRateModes } from '../../../components/sharedComponents/pricing';
import { convertPercentageToDecimal, convertToFloat } from '../../../utils';

import { useAddStateFee, useStateFeeValidationSchema } from '.';

export default function useAddStateFeeForm({ insuranceTypeId, onCompleted }) {
  const { addStateFee, loading, error, reset } = useAddStateFee({ onCompleted });
  const { stateFeeRateModeOptions } = useStateFeeRateModes();
  const { validationSchema } = useStateFeeValidationSchema();

  const initialValues = {
    fee: '',
    state: null,
    activeMode: stateFeeRateModeOptions ? stateFeeRateModeOptions[0] : null,
    vehicleTypes: []
  };

  const handleSubmit = ({ activeMode, state, vehicleTypes, fee }) =>
    addStateFee({
      fee: activeMode?.value === 'PERCENTAGE' ? convertPercentageToDecimal(fee) : convertToFloat(fee),
      activeMode: activeMode?.value,
      state: state?.value,
      vehicleTypeIds: vehicleTypes?.map(v => v.value),
      insuranceTypeId
    });

  return { initialValues, handleSubmit, validationSchema, loading, error, reset };
}
