import React from 'react';
import { Loader } from '@oforce/global-components';
import { useParams, useLocation } from 'react-router-dom';

import { GraphQLErrors } from '../../components';

import { useInvoice } from '../invoice/hooks';
import { InvoicePage } from '../invoice/components';

export default function Invoice() {
  const { invoiceId } = useParams();
  const { state: { viewingClientInvoice = true } = {} } = useLocation();
  const { invoice, loading, error } = useInvoice({
    id: invoiceId
  });

  if (loading && !invoice) return <Loader />;

  if (!loading && error) return <GraphQLErrors error={error} />;

  return <InvoicePage viewingClientInvoice={viewingClientInvoice} invoice={invoice} loading={loading} error={error} />;
}
