import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import { Render } from '..';

export default function QuickActionButton({
  id = '',
  text,
  disabled,
  onClick,
  textColor,
  render = true,
  icon = ['far', 'angle-right']
}) {
  return (
    <Render if={render}>
      <Button
        {...(id ? { id: id } : {})}
        disabled={disabled}
        color="link"
        className={`fs-sm d-flex justify-content-between align-items-center p-0 m-0 ${
          textColor ? 'text-' + textColor : ''
        }`}
        onClick={onClick}
      >
        <span>{text}</span>
        <FontAwesomeIcon className="ml-2" icon={icon} />
      </Button>
    </Render>
  );
}
