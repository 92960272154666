import { useLazyQuery } from '@apollo/client';
import { GET_INSURED } from '../../../../insured/hooks/useInsured';

export default function useLazyInsured({ onCompleted }) {
  const [load, { data, loading, error }] = useLazyQuery(GET_INSURED, {
    onCompleted: ({ insured }) => {
      onCompleted && onCompleted(insured);
    }
  });

  const getInsured = id => {
    if (!!id) {
      load({ variables: { id } });
    }
  };

  return {
    insured: data?.insured,
    getInsured,
    loading,
    error
  };
}
