import React, { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useImportExposuresJobContext } from '../../context';
import { ToastContent } from '../../components/react-toastify';

export default function ImportExposuresJob() {
  const { jobId, error, jobErrors, finished, progress, insured, message, clearJobId } = useImportExposuresJobContext();

  const toastId = useRef(null);

  useEffect(() => {
    if (!toast.current && jobId) {
      toastId.current = toast(<Importing />, {
        progress: 0,
        hideProgressBar: false,
        pauseOnHover: true
      });
    }
  }, [toastId, jobId, clearJobId]);

  useEffect(() => {
    if (!toastId.current) {
      return;
    }

    if (error) {
      toast.update(toastId.current, {
        render: () => <ErrorImportingExposures error={jobErrors || error} />,
        progress: finished ? 0.99 : progress,
        autoClose: 10000,
        progressClassName: 'bg-danger'
      });
    } else if (!finished) {
      toast.update(toastId.current, {
        render: () => <Importing insured={insured} message={message} />,
        progress: progress,
        autoClose: false
      });
    } else if (finished) {
      toast.update(toastId.current, {
        render: () => <ImportFinished message={message} errors={jobErrors} />,
        progress: 0.99,
        autoClose: 5000,
        progressClassName: 'bg-success'
      });
    }
    // eslint-disable-next-line
  }, [toastId, error, finished, progress, insured, message, jobErrors]);

  return <></>;

  // dont need to return the container here becasue it is returned in subscriptions
  // return (
  //   <>
  //     <ToastContainer
  //       containerId="importExposuresJobToast"
  //       position="bottom-left"
  //       hideProgressBar={false}
  //       pauseOnHover={true}
  //     />
  //   </>
  // );
}

const Importing = ({ message, insured }) => {
  let title = message ?? 'Preparing Import';

  if (insured) {
    const { firstName, lastName, businessName } = insured;
    title = `Importing: ${firstName} ${lastName} - ${businessName}`;
  }

  if (insured && message === 'Contract Type Updated to Master') {
    const { firstName, lastName, businessName } = insured;
    title = `Assigned as Master: ${firstName} ${lastName} - ${businessName}`;
  }

  if (insured && message === 'Sub Assigned to Master') {
    const { firstName, lastName, businessName } = insured;
    title = `Sub Assigned: ${firstName} ${lastName} - ${businessName}`;
  }

  return (
    <ToastContent
      title={title}
      icon={['far', 'spinner-third']}
      iconProps={{ spin: true }}
      message="Allow us some time to import."
    />
  );
};

const ErrorImportingExposures = ({ error }) => (
  <ToastContent
    title="Error Importing Exposure(s)"
    titleProps={{ className: 'text-danger' }}
    icon={['fas', 'times-circle']}
    message={error.message || error}
  />
);

const ImportFinished = ({ message, errors }) => {
  const hadErrors = errors && errors.length > 0;

  return (
    <ToastContent
      title={message || 'Import Finished'}
      titleProps={{ className: 'text-success' }}
      icon={!hadErrors ? ['fas', 'check-circle'] : ['far', 'exclamation-triangle']}
      message={!hadErrors ? 'Your Exposures Imported Sucessfully' : 'Some Rows Failed to Import'}
    >
      {hadErrors && 'It is Important that you run these failed imports separately to prevent duplicates'}
      {hadErrors && errors.map(e => <div key={e}>{e}</div>)}
    </ToastContent>
  );
};
