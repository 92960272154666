import * as yup from 'yup';

export const clientSchema = yup.object({
  name: yup.string().required('Required')
});

export const updateClientSchema = yup.object({
  name: yup.string().required('Required'),
  externalId: yup.string(),
  address1: yup.string(),
  address2: yup.string(),
  city: yup.string(),
  state: yup.object().nullable(),
  zip: yup.string(),
  country: yup.object().nullable()
});
