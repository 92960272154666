import React from 'react';
import { Steps } from 'react-step-builder';
import { useHistory } from 'react-router-dom';
import { GraphQLErrors, MultiStepForm, Render } from '../../components';
import { useGetCoverageParameters } from '../../views/coverageParameters/hooks';
import { useProductInsuranceType } from '../../components/sharedComponents/hooks';
import { useProductPricingFormOptions, useProductPricingFormSteps, useProductPricingFormSubmit } from './hooks';
import { Loader } from '@oforce/global-components';

import {
  ProductPricingDetailsFields,
  ProductPricingPricingFields,
  ProductPricingCoverageParametersFields
} from './components';

export default function ProductPricingCreateUpdate({ productId, pricing }) {
  const history = useHistory();
  const {
    insuranceType,
    loading: gettingInsuranceType,
    error: insuranceTypeError
  } = useProductInsuranceType({
    productId
  });

  const isCreateMode = !pricing;
  const insuranceTypeRequiresVehicle = insuranceType?.requireVehicle;
  const { data, loading: loadingCoverageParams, error: coverageParamError } = useGetCoverageParameters({ productId });
  const coverageParameters = data?.product?.coverageParameters;
  const { formOptions } = useProductPricingFormOptions({ productId });

  const { formSteps } = useProductPricingFormSteps({
    formOptions,
    isCreateMode,
    insuranceTypeRequiresVehicle,
    pricing,
    coverageParameters,
    insuranceType
  });

  const { formSubmit, loading, error } = useProductPricingFormSubmit({
    productId,
    pricing,
    isCreateMode,
    coverageParameters
  });

  const filterFormikValues = formikValues =>
    Object.keys(formikValues).reduce((acc, key) => {
      return !key.startsWith(`stateMode-`) && !key.startsWith('tempStateOptions-')
        ? { ...acc, [key]: formikValues[key] }
        : acc;
    }, {});

  if (insuranceTypeError || coverageParamError)
    return <GraphQLErrors error={insuranceTypeError || coverageParamError} />;
  if (gettingInsuranceType || loadingCoverageParams) return <Loader />;

  return (
    <MultiStepForm
      formOptions={formOptions}
      formSteps={formSteps}
      formSubmit={formikValues => {
        const values = filterFormikValues(formikValues);
        formSubmit(values);
      }}
      formProps={{ isCreateMode, insuranceTypeRequiresVehicle, productId, coverageParameters }}
      title={`${isCreateMode ? 'Create' : 'Edit'} Product Pricing`}
      onCancel={() => history.push(`/products/${productId}/pricing`)}
      submitButtonText={isCreateMode ? 'Create' : 'Save'}
      submitButtonProps={{ loading, loadingText: isCreateMode ? 'Creating...' : 'Saving...' }}
      showButtonsOnBottom
      loading={loading}
      error={error}
    >
      {formProps => (
        <>
          <Render if={(!coverageParameters || coverageParameters?.length === 0) && !insuranceTypeRequiresVehicle}>
            <Steps>
              <ProductPricingDetailsFields {...formProps} />
              <ProductPricingPricingFields {...formProps} />
            </Steps>
          </Render>

          <Render if={coverageParameters?.length > 0 || insuranceTypeRequiresVehicle}>
            <Steps>
              <ProductPricingDetailsFields {...formProps} />
              <ProductPricingPricingFields {...formProps} />
              <ProductPricingCoverageParametersFields {...formProps} />
            </Steps>
          </Render>
        </>
      )}
    </MultiStepForm>
  );
}
