import { useState } from 'react';
import { BoxContainer, FilterLabel, FormSection, PagerButtons, TrGraphQLErrors } from '../../../components';
import { Table } from '../../../components/table-2.0';
import { RangePicker, RenderData, Td, Tr, TrLoader, TrPlaceholder } from '@oforce/global-components';
import { Alert, CustomInput } from 'reactstrap';
import { formatDate } from '../../../utils/formatting';
import useInvoices from '../hooks/useInvoices';
import InvoicesSearchBy from './InvoicesSearchBy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function InvoicesSelection({ formik }) {
  const colSpan = 6;

  const [searchByFilters, setSearchByFilters] = useState({ searchBy: [] });
  const [serviceDateFilters, setServiceDateFilters] = useState({ serviceStartDate: null, serviceEndDate: null });

  const { invoices, loading, error, hasNextPage, hasPreviousPage, loadNextPage, loadPreviousPage } = useInvoices({
    numberToDisplay: 10,
    ...searchByFilters,
    ...serviceDateFilters
  });

  const setDateFilter = dates => {
    if (dates) {
      const [startDate, endDate] = dates;
      let serviceStartDate = null;
      let serviceEndDate = null;
      if (startDate) serviceStartDate = startDate.format('YYYY-MM-DD');
      if (endDate) serviceEndDate = endDate.format('YYYY-MM-DD');
      setServiceDateFilters({ serviceStartDate, serviceEndDate });
    } else {
      setServiceDateFilters({ serviceStartDate: null, serviceEndDate: null });
    }
  };

  return (
    <FormSection title="Select Invoices">
      <Alert color="info" className="mb-2">
        {`Combined invoices report will group all transactions across selected invoices by insured.`}
      </Alert>
      <div className="d-flex flex-row flex-wrap">
        <DateRangeFilter onChange={setDateFilter} />
        <InvoicesSearchBy onChange={setSearchByFilters} />
      </div>
      <BoxContainer>
        <Table responsive>
          <thead>
            <tr>
              <th></th>
              <th>Invoice ID</th>
              <th>Client Name</th>
              <th>Insurance Type</th>
              <th>Service Dates Range</th>
              <th>Policy Name</th>
            </tr>
          </thead>
          <tbody>
            <RenderData
              data={invoices}
              loading={loading}
              error={error}
              loadingComponent={<TrLoader colSpan={colSpan} />}
              errorComponent={<TrGraphQLErrors colSpan={colSpan} error={error} />}
              placeholderComponent={<TrPlaceholder colSpan={colSpan}>No Invoices</TrPlaceholder>}
            >
              {invoices =>
                invoices?.map((invoice, i) => (
                  <Tr key={i}>
                    <Td>
                      <CustomInput
                        id={invoice?.id}
                        type="checkbox"
                        checked={formik?.values?.invoiceIds?.includes(invoice?.id)}
                        onChange={() => {
                          if (formik?.values?.invoiceIds?.includes(invoice?.id)) {
                            formik?.setFieldValue(
                              'invoiceIds',
                              formik?.values?.invoiceIds.filter(invoice_id => invoice_id !== invoice?.id)
                            );
                          } else {
                            formik?.setFieldValue('invoiceIds', [...formik?.values?.invoiceIds, invoice?.id]);
                          }
                        }}
                        className="mb-1"
                      />
                    </Td>
                    <Td>{invoice?.id}</Td>
                    <Td>{invoice?.client?.name}</Td>
                    <Td>{invoice?.clientProduct?.insuranceType?.name}</Td>
                    <Td>
                      {formatDate(invoice?.serviceStartDate)} – {formatDate(invoice?.serviceEndDate)}
                    </Td>
                    <Td>{invoice?.clientProduct?.name}</Td>
                  </Tr>
                ))
              }
            </RenderData>
          </tbody>
        </Table>
      </BoxContainer>
      <PagerButtons
        loading={loading}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        loadNextPage={loadNextPage}
        loadPreviousPage={loadPreviousPage}
      />
    </FormSection>
  );
}

const DateRangeFilter = ({ onChange }) => (
  <div className="d-flex flex-column mr-2 mb-3">
    <FilterLabel className="font-weight-500">Filter by Date</FilterLabel>
    <RangePicker
      style={{ minHeight: '38px' }}
      format="MM/DD/YYYY"
      onChange={onChange}
      separator={<FontAwesomeIcon icon={['far', 'long-arrow-alt-right']} className="text-gray-500 ml-0" />}
    />
  </div>
);
