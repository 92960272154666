import moment from 'moment';
import { formatCurrency, normalizeNumber } from '../../../../utils/formatting';

export default function useSubmitValues() {
  const normalizeParameterValuesForSubmit = ({ coverageParameters }, { formValues }) => {
    const submitValues = {};
    const parameters = coverageParameters.reduce(
      (acc, { id, type, valueType }) => ({ ...acc, [id]: { type, valueType } }),
      {}
    );

    for (let parameterId in formValues) {
      const value = formValues[parameterId];

      if (value === null || value === '' || value === undefined) continue;
      if (Array.isArray(value) && value.length === 0) continue;
      if (Array.isArray(value) && value.length === 1 && !value[0]) continue;
      if (Array.isArray(value) && value.length === 2 && !value[0] && !value[1]) continue;
      if (
        !Array.isArray(value) &&
        typeof value === 'object' &&
        Object.keys(value).includes('min') &&
        !value.min &&
        !value.max
      )
        continue;

      const { type, valueType } = parameters[parameterId];

      switch (type) {
        case 'RANGE':
          submitValues[parameterId] = prepRangeForSubmit({ value, valueType });
          break;

        case 'LIST':
          submitValues[parameterId] = prepListForSubmit({ valueType, value });
          break;

        case 'INDIVIDUAL':
          switch (valueType) {
            case 'CURRENCY':
              submitValues[parameterId] = prepCurrencyForSubmit(value);
              break;

            case 'STATE':
              submitValues[parameterId] = prepStateForSubmit(value);
              break;

            case 'INTEGER':
              submitValues[parameterId] = prepIntegerForSubmit(value);
              break;

            case 'BOOLEAN':
              submitValues[parameterId] = prepBooleanForSubmit(value);
              break;

            case 'DATE':
              submitValues[parameterId] = prepDateForSubmit(value);
              break;

            case 'STRING':
              submitValues[parameterId] = prepStringForSubmit(value);
              break;

            default:
              break;
          }
          break;

        default:
          break;
      }
    }

    return submitValues;
  };

  const prepCurrencyForSubmit = value => normalizeNumber(value);
  const prepStateForSubmit = ({ value }) => value;
  const prepIntegerForSubmit = value => normalizeNumber(value);
  const prepBooleanForSubmit = ({ value }) => value;
  const prepDateForSubmit = value => moment(value, 'MM/DD/YYYY').format('YYYY-MM-DD');
  const prepStringForSubmit = value => value;

  const prepListForSubmit = ({ valueType, value }) => {
    let list = [];
    if (Array.isArray(value)) {
      switch (valueType) {
        case 'DATE':
          list = value.map(x => {
            return moment(x, 'MM/DD/YYYY').format('YYYY-MM-DD');
          });
          return JSON.stringify(list);

        case 'STATE':
          list = value.map(({ value: state }) => {
            return state;
          });
          return JSON.stringify(list);

        case 'CURRENCY':
          list = value.map(x => {
            return formatCurrency(x?.value);
          });
          return JSON.stringify(list);

        case 'INTEGER':
          list = value.map(x => {
            return normalizeNumber(x?.value);
          });
          return JSON.stringify(list);

        case 'STRING':
          list = value.map(x => {
            return x?.value;
          });
          return JSON.stringify(list);

        default:
          return '';
      }
    }
    return '';
  };

  const prepRangeForSubmit = ({ valueType, value }) => {
    if (!value) return;
    switch (valueType) {
      case 'DATE':
        const [start, end] = value;
        if (!start && !end) return '';
        return JSON.stringify({ min: start, max: end });

      case 'CURRENCY':
        return JSON.stringify({ min: normalizeNumber(value.min), max: normalizeNumber(value.max) });

      case 'INTEGER':
        return JSON.stringify({ min: normalizeNumber(value.min), max: normalizeNumber(value.max) });

      default:
        return JSON.stringify({ min: '', max: '' });
    }
  };

  return {
    normalizeParameterValuesForSubmit
  };
}
