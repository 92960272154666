import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';

export const GET_ACCOUNT_TAGS = gql`
  query accountTags($accountId: ID!) {
    accountTags(accountId: $accountId){
      id
      name      
    }
  }
`;

function useAccountTags({ accountId }) {
  const { loading, error, data } = useQuery(GET_ACCOUNT_TAGS, {
    skip: !accountId,
    variables: { accountId }
  });

  return {
    loading,
    error,
    tags: data?.accountTags,
    tagOptions: data?.accountTags?.map(({ name }) => ({ label: name, value: name })) || []
  };
}

export default useAccountTags;
