import React from 'react';
import { Tooltip } from 'reactstrap';
import { useToggle } from '../../../hooks';

export default function RetailCommissionPricingOptionsTooltip({ retailCommission, pricingOptions }) {
  const [showPricingOptionsPopover, togglePricingOptionsPopover] = useToggle();

  const pricings = retailCommission?.appliesToAllPricing
    ? pricingOptions
    : pricingOptions?.filter(x => retailCommission?.pricingOptionIds.includes(x?.id));

  const pricingOptionCount = pricings?.length;

  return (
    <Tooltip
      target={`a-${retailCommission?.id}`}
      placement="bottom"
      isOpen={showPricingOptionsPopover}
      toggle={togglePricingOptionsPopover}
    >
      <div>
        {pricings?.slice(0, 5).map(p => (
          <div key={p?.id}>{p?.label}</div>
        ))}
        {pricingOptionCount > 5 && <div>...and {pricingOptionCount - 5} more.</div>}
      </div>
    </Tooltip>
  );
}
