import React from 'react';
import { FilterDropdown } from '../../../components';

export default function NotificationStatusFilter({ notificationStatusOptions, onChange }) {
  const statusOptions = [{ label: 'All', value: null }].concat(notificationStatusOptions);

  return (
    <FilterDropdown
      id="notification-delivery-status"
      className="mr-3"
      headerText="Status..."
      options={statusOptions}
      defaultOption={statusOptions?.find(({ value }) => value === 'DELIVERED')}
      setFilter={option => onChange({ status: option?.value })}
    />
  );
}
