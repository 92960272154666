export default function useFeeAppliesToOptions() {
  const feeAppliesToOptions = [
    {
      label: 'All Pricing Options',
      value: true
    },
    {
      label: 'Selected Pricing Options',
      value: false
    }
  ];

  return { feeAppliesToOptions };
}
