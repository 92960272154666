import React from 'react';
import moment from 'moment';
import { Alert, Col, FormGroup, Row } from 'reactstrap';
import { DatePickerField } from '@oforce/global-components';
import { useHistory } from 'react-router-dom';
import {
  ReactCreatableSelectField,
  FieldLabel,
  Badge,
  ReactSelectField,
  Render,
  Button,
  useToggle
} from '@oforce/global-components';

import { ConfirmModal, FormSection } from '../../../components';
import { titleCase } from '../../../utils/formatting';

import { GenerateInvoiceFormDates } from '.';
import { transformDate } from '../../../utils/yupValidation';
import { ShowForTypes, useActiveAccount, ShowForRoles } from '../../../shared/activeAccount';
import useBackdateInvoices from '../hooks/useBackdateInvoices';
import { usePolicyInvoiceInfo } from '../hooks';

export default function GenerateInvoiceForm({ formik, formOptions, policy }) {
  const [showConfirmModal, toggleConfirmModal] = useToggle();
  const history = useHistory();

  const { backdateInvoices, loading } = useBackdateInvoices({
    policyId: policy?.id,
    onCompleted: () => history.push(`/policies/${policy?.id}/details`)
  });

  const { canGenerateOffCycleInvoice } = usePolicyInvoiceInfo({ policy });

  const automateInvoiceGeneration = formik?.values?.automateInvoiceGeneration;
  const invoiceGenerationAlreadyScheduled = formik?.values?.invoiceGenerationAlreadyScheduled;
  const pricingRateType = formik?.values?.pricingRateType;
  const effectiveDate = formik?.values?.effectiveDate;
  const generateAt = policy?.nextInvoiceServiceDates?.generateAt;
  const activeExposureCount = policy?.activeExposureCount;
  const scheduledInvoiceGenerationDate = {
    date: formik?.values?.scheduledInvoiceGenerationDate?.date,
    time: formik?.values?.scheduledInvoiceGenerationDate?.time
  };

  const str = generateAt && transformDate(generateAt);
  const diff = str && moment().diff(str, 'days');

  const {
    ROLES: { OWNER, COLLABORATOR, POLICY_MANAGER },
    TYPES: { CLIENT, BROKER, CARRIER }
  } = useActiveAccount();

  const onConfirm = () => {
    toggleConfirmModal();
    backdateInvoices();
  };

  return (
    <>
      <Render if={automateInvoiceGeneration && !canGenerateOffCycleInvoice}>
        <Render if={activeExposureCount === 0}>
          <Alert color="info" className="mb-0">
            {`Invoices are set to automatically generate for this policy but since there are no active exposures electing coverage, no invoices will be generated.`}
          </Alert>
        </Render>
        <Render if={diff > 0 && activeExposureCount > 0}>
          <Alert color="info" className="mb-0">
            {`Invoices are set to automatically generate for this policy but invoices need to be backdated starting ${scheduledInvoiceGenerationDate?.date}.`}
          </Alert>
          <ShowForTypes types={[CLIENT, BROKER, CARRIER]}>
            <ShowForRoles roles={[OWNER, COLLABORATOR, POLICY_MANAGER]}>
              <Button loading={loading} onClick={toggleConfirmModal} className="mt-3 w-100 p-2" color="success">
                Backdate Invoices
              </Button>
            </ShowForRoles>
          </ShowForTypes>
        </Render>
        <Render if={diff <= 0 && activeExposureCount > 0}>
          <Alert color="info" className="mb-0">
            {`Invoices are set to automatically generate for this policy. The next invoice will be generated on ${scheduledInvoiceGenerationDate?.date} at ${scheduledInvoiceGenerationDate?.time}.`}
          </Alert>
        </Render>
      </Render>
      <Render if={!automateInvoiceGeneration || canGenerateOffCycleInvoice}>
        <Row className="mb-0">
          <Col>
            <FormGroup className="text-center mb-0">
              <FieldLabel>Policy Rate Type</FieldLabel>
              <Badge color="muted">{titleCase(pricingRateType)}</Badge>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup className="text-center mb-0">
              <FieldLabel>Policy Effective Date</FieldLabel>
              <Render if={!!effectiveDate}>
                <Badge color="muted">{moment(effectiveDate).format('MM-DD-YYYY')}</Badge>
              </Render>
              <Render if={!effectiveDate}>
                <Badge color="gray500">None</Badge>
              </Render>
            </FormGroup>
          </Col>
        </Row>
        <hr className="mb-4" />
        <FormSection title="Invoice Settings" className="mb-0">
          <DatePickerField name="invoiceDate" label="Invoice Date" format="MM/DD/YYYY" fullWidth />
          <Render if={!automateInvoiceGeneration && !canGenerateOffCycleInvoice}>
            <ReactSelectField
              label="Generate invoice for"
              name="intervalType"
              options={
                pricingRateType === 'WEEKLY'
                  ? formOptions?.weeklyIntervalType?.options
                  : formOptions?.monthlyIntervalType?.options
              }
            />
          </Render>
          <Render if={!canGenerateOffCycleInvoice}>
            <GenerateInvoiceFormDates formik={formik} disabled={automateInvoiceGeneration} />
          </Render>
          <ReactCreatableSelectField
            name="invoiceEmailAddresses"
            label="Invoice Recipient(s)"
            placeholder="Enter Emails..."
            options={formOptions?.invoiceEmailAddresses?.options}
            onCreateOption={value => {
              formik?.setFieldValue('invoiceEmailAddresses', [
                ...formik?.values?.invoiceEmailAddresses,
                { value: value, label: value }
              ]);
            }}
            isMulti
            isDisabled={automateInvoiceGeneration || invoiceGenerationAlreadyScheduled}
          />
        </FormSection>
      </Render>
      <ConfirmModal isOpen={showConfirmModal} toggle={toggleConfirmModal} onConfirm={onConfirm}>
        {`Backdating invoices will generate all invoices from ${scheduledInvoiceGenerationDate?.date} until now. This can not be undone.`}
      </ConfirmModal>
    </>
  );
}
