import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useHistory } from 'react-router-dom';
import { FeatureFlag, HideFromRolesAndTypes } from '../../shared/activeAccount/components';
import { formatBarePremium, formatDate, formatPremium } from '../../utils/formatting';
import { ExposureElectionStatusBadge } from '../../views/exposureElections/components';
import { normalizeInsuredFullNameLastFirst } from '../../views/insured/utils';
import { BoxContainer, BoxContainerTitle, BoxHeader } from '../box';
import LabelValueGroup from '../LabelValueGroup';
import Render from '../Render';
import { FLAGGED_FEATURES } from '../../shared/activeAccount/constants';
import { Row, Col } from 'reactstrap';
import { QuickActions } from '../quickActions';
import { makeModelYear } from '../../views/vehicle/utils';
import { useActiveAccount } from '../../shared/activeAccount';
const { MASTER_SUB_RELATIONSHIP } = FLAGGED_FEATURES;

export default function InsuredElectionCard({ election, insured, className }) {
  const history = useHistory();
  const electionInsured = insured ? insured : election?.insured;
  const redirectToReactivatedExposure = ({ reactivateExposureElection: { id } }) => {
    history.push(`/insureds/${electionInsured?.id}/elections/${id}`);
  };
  const {
    ROLES: { OBSERVER },
    TYPES: { CLAIMS_ADJUSTER }
  } = useActiveAccount();

  const insuranceTypeRequiresVehicle = election?.policy?.insuranceType?.requireVehicle;

  return (
    <BoxContainer className={`px-3 py-2 ${className}`}>
      <BoxHeader className="py-1 d-flex justify-content-between">
        <div className="d-flex">
          <BoxContainerTitle>{election?.policy?.insuranceType?.name}</BoxContainerTitle>
          <ExposureElectionStatusBadge election={election} className="ml-3" />{' '}
        </div>
        <Link to={`/insureds/${electionInsured?.id}/elections/${election?.id}`}>
          Show
          <FontAwesomeIcon className="ml-2" icon={['far', 'angle-right']} />
        </Link>
      </BoxHeader>
      <Row>
        <Col sm="9">
          <FeatureFlag feature={MASTER_SUB_RELATIONSHIP}>
            <Render if={election?.contractType === 'SUB'}>
              <LabelValueGroup
                pad={false}
                className={'pt-2 pl-1 text-muted'}
                label="Master Contract"
                value={
                  <Link
                    to={`/insureds/${election?.masterElection?.insured?.id}/elections/${election?.masterElection?.id}`}
                  >
                    {normalizeInsuredFullNameLastFirst(election?.masterElection?.insured)}
                  </Link>
                }
              />
            </Render>
          </FeatureFlag>
          <LabelValueGroup
            className={'pt-2 pl-1 text-muted'}
            pad={false}
            label="Start Date"
            value={formatDate(election?.startDate)}
          />
          <LabelValueGroup
            className={'pt-2 pl-1 text-muted'}
            pad={false}
            label="Termination Date"
            value={formatDate(election?.terminationDate)}
          />
          <LabelValueGroup
            className={'pt-1 pl-1 text-muted'}
            pad={false}
            label="Premium"
            value={formatBarePremium(election)}
          />
          <LabelValueGroup
            className={'pt-1 pl-1 text-muted'}
            pad={false}
            label="Premium Total"
            value={formatPremium(election)}
          />
          <LabelValueGroup
            pad={false}
            className={'pt-1 pl-1 text-muted'}
            label="Insured"
            value={normalizeInsuredFullNameLastFirst(electionInsured)}
          />
          {insuranceTypeRequiresVehicle && (
            <>
              <LabelValueGroup
                pad={false}
                className={'pt-1 pl-1 text-muted'}
                label="Vehicle"
                value={makeModelYear(election?.vehicle)}
              />
              <LabelValueGroup
                pad={false}
                className={'pt-1 pl-1 text-muted'}
                label="VIN"
                value={election?.vehicle?.vin}
              />
              <LabelValueGroup
                pad={false}
                className={'pt-1 pl-1 text-muted'}
                label="Unit Number"
                value={election?.vehicle?.unitNumber}
              />
            </>
          )}
        </Col>
        <Col sm="3">
          <HideFromRolesAndTypes roles={[OBSERVER]} types={[CLAIMS_ADJUSTER]}>
            <BoxContainerTitle className="fs-sm mt-1">Quick Actions</BoxContainerTitle>
            <QuickActions className="ml-2 mt-0" election={election} onReactivated={redirectToReactivatedExposure} />
          </HideFromRolesAndTypes>
        </Col>
      </Row>
    </BoxContainer>
  );
}
