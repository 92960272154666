import React from 'react';
import { Form } from 'formik';
import { InputField, InputPercentageField, ReactSelectField } from '@oforce/global-components';
import { GraphQLErrors, Render } from '../../../components';

export default function RetailCommissionForm({ formik, formOptions, focusRef, error }) {
  const appliesToAllPricing = formik?.values?.appliesToAllPricing?.value;

  return (
    <Form>
      <GraphQLErrors error={error} />
      <InputField required label="Name" name="name" placeholder="Enter a commission name" innerRef={focusRef} />
      <ReactSelectField
        label="Rate Mode"
        isDisabled
        options={formOptions?.rateMode?.options}
        isLoading={formOptions?.rateMode?.loading}
        name="rateMode"
      />
      <InputPercentageField label="Rate" required placeholder="Enter a percentage" name="rate" />
      <ReactSelectField
        required
        label="Applies to"
        options={formOptions?.commissionAppliesTo?.options}
        name="appliesToAllPricing"
      />
      <Render if={appliesToAllPricing}>
        <div className="fs-xs">
          Setting a commission to apply to all pricing options will override any previous commission settings.
        </div>
      </Render>
      <Render if={!appliesToAllPricing}>
        <ReactSelectField
          label="Selected Pricing Options"
          name="pricings"
          required
          options={formOptions?.pricing?.options}
          isLoading={formOptions?.pricing?.loading}
          placeholder="Select..."
          isMulti
          isClearable
        />
      </Render>
    </Form>
  );
}
