import { useMemo } from 'react';
import { format as formatDate, parseISO } from 'date-fns';
import { format as formatZonedDate, utcToZonedTime } from 'date-fns-tz';

const defaultOptions = {
  dateFormat: 'EEEE MMMM do',
  timeFormat: 'hh:mm aaa',
  timeZones: []
};

export default function useDateFormatting(dateInput, options = defaultOptions) {
  const { dateFormat, timeFormat, timeZones } = {
    ...defaultOptions,
    ...options
  };

  const { utc, local, ...zones } = useMemo(() => {
    if (!dateInput) return {};

    function getTimeZone(tz) {
      const zoned = utcToZonedTime(dateInput, tz);
      const zoneDate = formatZonedDate(zoned, dateFormat);
      const zoneTime = formatZonedDate(zoned, timeFormat);
      return { date: zoneDate, time: zoneTime };
    }

    const localized = parseISO(dateInput);

    const localizedDate = formatDate(localized, dateFormat);
    const localizedTime = formatDate(localized, timeFormat);

    const utc = getTimeZone('UTC');

    const defaultTimeZones = {
      utc: { ...utc },
      local: { date: localizedDate, time: localizedTime }
    };

    const values = timeZones.reduce((acc, tz) => {
      const zone = getTimeZone(tz);
      return { ...acc, [tz]: { ...zone } };
    }, defaultTimeZones);

    return values;
  }, [dateInput, dateFormat, timeFormat, timeZones]);

  return { utc, local, ...zones };
}
