import { gql, useMutation } from '@apollo/client';

export const ADD_ACCOUNT_REPORT = gql`
  mutation AddAccountReport($accountId: ID!, $name: String!, $query: String!, $projectionModule: String) {
    report: addAccountReport(accountId: $accountId, name: $name, query: $query, projectionModule: $projectionModule) {
      id
      name
      query
      variables {
        name
        required
        type
        isListType
      }
    }
  }
`;

export default function useCreateReportFormSubmit({ onCompleted }) {
  const [createReport, { loading, error }] = useMutation(ADD_ACCOUNT_REPORT, {
    onError: () => {},
    onCompleted
  });

  const formSubmit = values => {
    createReport({
      variables: {
        accountId: values?.accountId,
        name: values?.name,
        query: values?.report?.value?.query,
        projectionModule: values?.report?.value?.projectionModule
      }
    });
  };

  return { formSubmit, loading, error };
}
