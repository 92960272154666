import React from 'react';
import { Link } from 'react-router-dom';
import { Render } from '@oforce/global-components';
import { useVehicleMakeModelYear } from '../../views/vehicle/hooks';

export default function VehicleInfoCell({ vehicle, className }) {
  const { hasMakeModelYear, makeModelYear } = useVehicleMakeModelYear(vehicle);

  if (!vehicle) return <EmptyCell />;

  return (
    <div className={className}>
      <Render if={hasMakeModelYear}>
        <Link to={`/vehicles/${vehicle?.id}`}>{makeModelYear}</Link>
        <br /> <span className="text-muted">{vehicle?.vin.toUpperCase()}</span>
      </Render>
      <Render if={!hasMakeModelYear}>
        <Link to={`/vehicles/${vehicle?.id}`}>{vehicle?.vin.toUpperCase()}</Link>
      </Render>
    </div>
  );
}

const EmptyCell = ({ text = 'None' }) => <div className="font-weight-300 fs-xs font-italic text-muted">{text}</div>;
