import { gql, useSubscription } from '@apollo/client';
import { useActiveAccount } from '../../../shared/activeAccount';

const SUBSCRIPTION = gql`
  subscription complianceChanged($accountId: ID!) {
    change: exposureComplianceChanged(accountId: $accountId) {
      exposureElectionId
      compliance {
        inCompliance
        rejected
        violations {
          id
          status
          source
          type
          field
          reason
        }
      }
    }
  }
`;

const EXPOSURE_FRAGMENT = gql`
  fragment ExposureCompliance on ExposureElection {
    compliance {
      inCompliance
      rejected
      violations {
        id
        status
        source
        type
        field
        reason
      }
    }
  }
`;

export default function useExposureComplianceChanges() {
  const { id } = useActiveAccount();
  useSubscription(SUBSCRIPTION, {
    variables: { accountId: id },
    skip: !id,
    onSubscriptionData({ client, subscriptionData: { data } }) {
      const {
        change: { exposureElectionId, compliance }
      } = data;

      client.writeFragment({
        id: `ExposureElection:${exposureElectionId}`,
        fragment: EXPOSURE_FRAGMENT,
        data: { compliance }
      });
    }
  });
}
