import React, { useState } from 'react';
import { VehicleCoveragesTable } from '.';
import { TabPage } from '../../../components';
import { useVehicleCoverages } from '../hooks';
import { Button } from '@oforce/global-components';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { CoverageStatusFilter } from '../../../components/filtering';
import { EXPOSURE_ELECTION_STATUSES } from '../../exposureElections/constants';
import { useActiveAccount } from '../../../shared/activeAccount';
import { HideFromRolesAndTypes } from '../../../shared/activeAccount/components';
const { ACTIVE } = EXPOSURE_ELECTION_STATUSES;

export default function VehicleCoverages({ vehicleId }) {
  const history = useHistory();
  const { url } = useRouteMatch();

  const {
    ROLES: { OBSERVER },
    TYPES: { CLAIMS_ADJUSTER }
  } = useActiveAccount();
  const [filters, setFilters] = useState({ status: ACTIVE, inCompliance: true, complianceStatus: null });
  const { coverages, loading, error } = useVehicleCoverages({ vehicleId, filters });

  return (
    <TabPage
      title="Coverages"
      className="flex-grow-1 mb-2 mt-3"
      contentClassName="mt-1"
      titleClassName="mb-1"
      buttonClassName="w-100"
      buttons={
        <div className="d-flex flex-row w-100 justify-content-between align-items-end">
          <CoverageStatusFilter
            hideLabel
            onChange={setFilters}
            defaultStatus={ACTIVE}
            style={{ minHeight: '15px', fontSize: 'small' }}
            className="ml-3 mt-2"
          />
          <HideFromRolesAndTypes roles={[OBSERVER]} types={[CLAIMS_ADJUSTER]}>
            <Button color="link" onClick={() => history.push(`${url}/add-coverage`)} icon={['far', 'plus']}>
              Create
            </Button>
          </HideFromRolesAndTypes>
        </div>
      }
    >
      <VehicleCoveragesTable coverages={coverages} loading={loading} error={error} vehicleId={vehicleId} />
    </TabPage>
  );
}
