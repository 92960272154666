import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const FullPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  overflow: hidden;
`;

export const MessageBox = styled.div`
  background: white;
  padding: 1.25rem;
  margin: 1.25rem;
  border-radius: 0.25rem;
  border: 2px solid ${props => props.theme.gray400};
  text-align: center;
  box-sizing: border-box;
  font-family: 'Sailec', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export const Title = styled.h1`
  font-size: 22px !important;
  margin-bottom: 0px;
  font-weight: 500;
`;

export const Message = styled.div`
  color: ${props => props.theme.gray600};
`;

export const IconContainer = styled.div`
  padding-bottom: 1rem;
  color: ${props => props.theme[props.color || 'gray600']};
`;

export const LargeIcon = ({ icon, color, ...rest }) => (
  <IconContainer color={color}>
    <FontAwesomeIcon icon={icon} size="2x" {...rest} />
  </IconContainer>
);
