import { gql } from '@apollo/client';

export const EXPOSURE_ELECTION_FIELDS = gql`
  fragment exposureElectionFields on ExposureElection {
    id
    policyId
    productId
    startDate
    terminationDate
    status
    total
    electionData
    pricingRateType
    enableCustomStartDates
    insuredId
    premiumTotal
    masterCertificateNumber
    contractType
  }
`;
export const ELECTION_INSURED_FIELDS = gql`
  fragment electionInsuredFields on ExposureElection {
    id
    insured {
      id
      firstName
      middleName
      lastName
      businessName
      mobilePhone
      email
    }
  }
`;

export const ELECTION_VEHICLE_FIELDS = gql`
  fragment electionVehicleFields on ExposureElection {
    id
    vehicle {
      id
      vin
      vehicleYear
      vehicleMake
      vehicleModel
      unitNumber
    }
  }
`;

export const ELECTION_PRICING_OPTION_FIELDS = gql`
  fragment electionPricingOptionFields on ExposureElection {
    id
    addOns {
      id
      label
    }
    pricingOption {
      id
      label
      rate
      rateMode
      factoredField
      isPercentageFactor
    }
  }
`;

export const ELECTION_COMPLIANCE_FIELDS = gql`
  fragment electionComplianceFields on ExposureElection {
    id
    compliance {
      inCompliance
      rejected
      violations {
        field
        id
        reason
        source
        status
        type
      }
    }
  }
`;
