function readResponse(response) {
  try {
    return JSON.parse(response);
  } catch (e) {
    return response;
  }
}

export default function useFetcher({ apiUrl, accessToken }) {
  const input = `${apiUrl}`;

  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  };

  return {
    fetcher: (params) =>
      fetch(input, { ...options, body: JSON.stringify(params) })
        .then((x) => x.text())
        .then(readResponse),
  };
}
