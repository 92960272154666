import { normalizeInsuredFullNameLastFirst } from '../../views/insured/utils';
import { makeModelYear } from '../../views/vehicle/utils';
import { BoxContainer, BoxContainerTitle, BoxHeader } from '../box';
import LabelValueGroup from '../LabelValueGroup';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatAddonsList, formatBarePremium, formatCurrency, formatDate, formatPremium } from '../../utils/formatting';
import { ExposureElectionStatusBadge } from '../../views/exposureElections/components';
import { FeatureFlag, HideFromRolesAndTypes } from '../../shared/activeAccount/components';
import { Render } from '@oforce/global-components';
import { FLAGGED_FEATURES } from '../../shared/activeAccount/constants';
import { QuickActions } from '../quickActions';
import { Col, Row } from 'reactstrap';
import { useActiveAccount } from '../../shared/activeAccount';
const { MASTER_SUB_RELATIONSHIP } = FLAGGED_FEATURES;

export default function VehicleElectionCard({ election, insured, className }) {
  const history = useHistory();
  const electionInsured = insured ? insured : election?.insured;

  const redirectToReactivatedExposure = ({ reactivateExposureElection: { id } }) => {
    history.push(`/insureds/${electionInsured?.id}/elections/${id}`);
  };

  const {
    ROLES: { OBSERVER },
    TYPES: { CLAIMS_ADJUSTER }
  } = useActiveAccount();

  return (
    <BoxContainer className={`px-3 py-2 ${className}`}>
      <BoxHeader className="py-1 d-flex justify-content-between">
        <div className="d-flex">
          <BoxContainerTitle>{election?.policy?.insuranceType?.name}</BoxContainerTitle>
          <ExposureElectionStatusBadge election={election} className="ml-3" />{' '}
        </div>
        <Link to={`/insureds/${electionInsured?.id}/elections/${election?.id}`}>
          Show
          <FontAwesomeIcon className="ml-2" icon={['far', 'angle-right']} />
        </Link>
      </BoxHeader>
      <Row>
        <Col sm="9">
          <FeatureFlag feature={MASTER_SUB_RELATIONSHIP}>
            <Render if={election?.contractType === 'SUB'}>
              <LabelValueGroup
                pad={false}
                className={'pt-2 pl-1 text-muted'}
                label="Master Contract"
                value={
                  <Link
                    to={`/insureds/${election?.masterElection?.insured?.id}/elections/${election?.masterElection?.id}`}
                  >
                    {normalizeInsuredFullNameLastFirst(election?.masterElection?.insured)}
                  </Link>
                }
              />
            </Render>
          </FeatureFlag>
          <LabelValueGroup
            className={'pt-2 pl-1 text-muted'}
            pad={false}
            label="Start Date"
            value={formatDate(election?.startDate)}
          />
          <LabelValueGroup
            className={'pt-2 pl-1 text-muted'}
            pad={false}
            label="Termination Date"
            value={formatDate(election?.terminationDate)}
          />
          <LabelValueGroup
            pad={false}
            className={'pt-1 pl-1 text-muted'}
            label="Premium"
            value={formatBarePremium(election)}
          />
          <LabelValueGroup
            pad={false}
            className={'pt-1 pl-1 text-muted'}
            label="Premium Total"
            value={formatPremium(election)}
          />
          <LabelValueGroup
            pad={false}
            className={'pt-1 pl-1 text-muted'}
            label="Insured"
            value={normalizeInsuredFullNameLastFirst(electionInsured)}
          />
          <LabelValueGroup
            pad={false}
            className={'pt-1 pl-1 text-muted'}
            label="Vehicle"
            value={makeModelYear(election?.vehicle)}
          />
          {election?.vehicle?.value && (
            <LabelValueGroup
              pad={false}
              className={'pt-1 pl-1 text-muted'}
              label="Vehicle Value"
              value={formatCurrency(election?.vehicle?.value)}
            />
          )}
          {formatAddonsList(election) && (
            <LabelValueGroup
              pad={false}
              className={'pt-1 pl-1 text-muted'}
              label="Add Ons"
              value={formatAddonsList(election)}
            />
          )}

          <LabelValueGroup pad={false} className={'pt-1 pl-1 text-muted'} label="VIN" value={election?.vehicle?.vin} />
          <LabelValueGroup
            pad={false}
            className={'pt-1 pl-1 text-muted'}
            label="Unit Number"
            value={election?.vehicle?.unitNumber}
          />
        </Col>
        <Col sm="3">
          <HideFromRolesAndTypes roles={[OBSERVER]} types={[CLAIMS_ADJUSTER]}>  
            <BoxContainerTitle className="fs-sm mt-1">Quick Actions</BoxContainerTitle>
            <QuickActions className="ml-2 mt-0" election={election} onReactivated={redirectToReactivatedExposure} />
          </HideFromRolesAndTypes>
        </Col>
      </Row>
    </BoxContainer>
  );
}
