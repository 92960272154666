import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { VALIDATED_UPDATE_ROW_FIELDS } from '../model/constants';

const VALIDATE_ROW = gql`
  mutation ValidateUpdateRow(
    $row: Json!
    $neighborActiveDates: [NeighborActiveDatesInput]
    $masterActiveDates: MasterActiveDatesInput
    $requiredFields: [String]!
  ) {
    validateExposureUpdateRow(
      row: $row
      neighborActiveDates: $neighborActiveDates
      masterActiveDates: $masterActiveDates
      requiredFields: $requiredFields
    ) {
      ...validatedUpdateRowFields
    }
  }
  ${VALIDATED_UPDATE_ROW_FIELDS}
`;

export default function useRowValidation({ onCompleted = () => {}, onError = () => {} }) {
  const [loadingRowId, setLoadingRowId] = useState(null);

  const [validateRow, { loading, error }] = useMutation(VALIDATE_ROW, {
    onCompleted: ({ validateExposureUpdateRow }) => {
      onCompleted && onCompleted(validateExposureUpdateRow);
      setLoadingRowId(null);
    },
    onError
  });

  const getMasterActiveDates = (row, masterRow) => {
    return {
      exposureElectionId: row?.exposureElectionId,
      masterId: masterRow?.exposureElectionId,
      masterStartDate: masterRow?.startDate,
      masterTerminatonDate: masterRow?.terminationDate
    };
  };

  const getNeighborActiveDates = currentNeighbors => {
    if (!currentNeighbors) return [];
    return currentNeighbors?.map(
      ({ exposureElectionId, location, neighborId, neighborStartDate, neighborTerminationDate }) => {
        return {
          exposureElectionId,
          location,
          neighborId,
          neighborStartDate,
          neighborTerminationDate
        };
      }
    );
  };

  const validateSingleRow = (row, rows = [], validatedRows = []) => {
    const masterRow = validatedRows?.find(
      masterRow => row?.masterActiveDates?.masterId === masterRow?.exposureElectionId
    );

    const masterActiveDates = !!masterRow ? getMasterActiveDates(row, masterRow) : row?.masterActiveDates;

    setLoadingRowId(row?.rowId);

    const variables = {
      masterActiveDates,
      requiredFields: row?.requiredFields,
      neighborActiveDates: getNeighborActiveDates(row?.neighborActiveDates),
      row: JSON.stringify(row)
    };

    validateRow({ variables });
  };

  return {
    validateRow: validateSingleRow,
    loading: loading && loadingRowId,
    error
  };
}
