import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { backendNameToLabel } from '../../../utils/formatting';

const GET_PREMIUM_RATE_MODES = gql`
  query premiumRateModes {
    premiumRateModes
  }
`;

export default function usePremiumRateModes() {
  const { loading, error, data } = useQuery(GET_PREMIUM_RATE_MODES);

  return {
    loading,
    error,
    premiumRateModes: data?.premiumRateModes,
    premiumRateModeOptions: data?.premiumRateModes?.map(mode => ({
      label: backendNameToLabel(mode),
      value: mode
    }))
  };
}
