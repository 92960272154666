import React from 'react';
import { Helmet } from 'react-helmet';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { TabPage } from '../../components/tabs';
import { PageBody, PageTitle, PageHeader } from '../../components/page-components';
import { useListNotificationJobs } from './hooks';
import NotificationJobsTable from './components/NotificationJobsTable';
import NotificationJob from '../notificationJob';

export default function NotificationJobs() {
  const { path } = useRouteMatch();
  const { notificationJobs, loading, error, paginationProps, filters, changeFilters } = useListNotificationJobs({
    pageSize: 10
  });

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Helmet>
          <title>Notifications • IC Insure</title>
        </Helmet>
        <PageHeader>
          <PageTitle>Notifications</PageTitle>
        </PageHeader>
        <PageBody>
          <TabPage>
            <NotificationJobsTable
              notificationJobs={notificationJobs}
              loading={loading}
              error={error}
              paginationProps={paginationProps}
              filters={filters}
              changeFilters={filters => changeFilters({ ...filters })}
            />
          </TabPage>
        </PageBody>
      </Route>
      <Route path={`${path}/:jobId`}>
        <NotificationJob />
      </Route>
    </Switch>
  );
}
