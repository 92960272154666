import React from 'react';
import { Route } from 'react-router-dom';

import { useCurrentUser } from '../shared/currentUser';
import { AccessDeniedPage } from '../components/pages';

export default function AdminRoute({ children, ...rest }) {
  const { isAdmin } = useCurrentUser();
  return isAdmin ? <Route {...rest}>{children}</Route> : <AccessDeniedPage />;
}
