import React, { useEffect, useState, useRef } from 'react';
import { validate } from 'jsonschema';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/ext-beautify';

const tryParse = value => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return false;
  }
};

export function validateJson(schema, value) {
  if (!value) return {valid: true, errors: []}
  const parsed = tryParse(value);

  if(parsed){
    return validate(parsed, schema);
  }
  return {valid: false, errors: [{path: ["{"], instance: "json"}]}
}

export default function JsonInput({ schema, value, onChange }) {
  const [annotations, setAnnotations] = useState([]);

  const editor = useRef();

  useEffect(() => {
    const response = validateJson(schema, value);
    const annotations = errorsToAnnotations(response, editor.current.editor);
    setAnnotations(annotations);
  }, [value, schema]);

  const errorsToAnnotations = ({ errors }, editor) => {
    const annotations = errors.map(({ path, instance }) => {
      const { start = {} } =
        editor.find(path[0], { preventScroll: true }) || {};
      return { row: start.row, type: 'error', text: `Invalid value: ${instance}` };
    });

    return annotations;
  };

  return (
    <AceEditor
      ref={editor}
      mode="json"
      value={value}
      onChange={onChange}
      setOptions={{
        wrapBehavioursEnabled: true,
        tabSize: 2,
        useWorker: false,
        highlightActiveLine: false,
        highlightSelectedWord: false
      }}
      annotations={annotations}
    />
  );
}
