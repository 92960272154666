import { gql, useQuery } from '@apollo/client';

export const fragments = {
  productPricingOption: gql`
    fragment ProductPricingOption on ProductPricingOption {
      id
      name
      label
      rateMode
      rate
      isPercentageFactor
      percentageMinimum
      percentageMaximum
      factoredField
      retailCommission {
        id
        name
        rate
        rateMode
      }
      coverageParameters {
        id
        cacheId
        name
        type
        valueType
        value
      }
      fees {
        id
        name
        tags
        rateMode
        rate
      }
      vehicleTypes {
        id
        name
      }
    }
  `
};

export const GET_PRODUCT_PRICINGS = gql`
  query getProductPricings($id: ID!) {
    product(id: $id) {
      id
      pricingOptions {
        ...ProductPricingOption
      }
    }
  }
  ${fragments.productPricingOption}
`;

export default function useProductPricings({ productId }) {
  const { data, loading, error } = useQuery(GET_PRODUCT_PRICINGS, {
    fetchPolicy: 'network-only',
    variables: { id: productId }
  });
  return {
    pricings: data?.product?.pricingOptions,
    loading: loading,
    error: error
  };
}
