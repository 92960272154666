import * as yup from 'yup';
import useCoverageParameterTypes from './useCoverageParameterTypes';

export default function useCoverageParametersFormSteps({ coverageParameter }) {
  const { predefinedParamOptions, typesNeedingValueType } = useCoverageParameterTypes();

  const formSteps = [
    {
      name: 'Category',
      initialValues: {
        isPredefined: !!coverageParameter?.isPredefined
      },
      validationSchema: yup.object().shape({
        isPredefined: yup.boolean()
      })
    },
    {
      name: 'Details',
      initialValues: {
        name: coverageParameter?.name || '',
        label: coverageParameter?.label || null,
        predefinedCoverageParameter: predefinedParamOptions?.find(({ value }) => value === coverageParameter?.label)
      },
      validationSchema: yup.object().shape({
        name: yup.string().when('isPredefined', { is: false, then: schema => schema.required('Required') }),
        label: yup.string().nullable(),
        predefinedCoverageParameter: yup
          .object()
          .nullable()
          .when('isPredefined', { is: true, then: schema => schema.required('Required') })
      })
    },
    {
      name: 'Types',
      initialValues: {
        type: null,
        valueType: null,
        validate: coverageParameter?.validate === false ? false : true
      },
      validationSchema: yup.object().shape({
        type: yup.object().nullable().required('Required'),
        valueType: yup
          .object()
          .nullable()
          .when('type', (option, schema) =>
            option?.value && typesNeedingValueType.includes(option?.value) ? schema.required('Required') : schema
          ),
        validate: yup.boolean().when('isPredefined', { is: true, then: schema => schema.required('Required') })
      })
    }
  ];

  return { formSteps };
}
