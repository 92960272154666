import { gql, useQuery } from '@apollo/client';

export const GET_POLICY_EXPOSURE_ELECTIONS = gql`
  query policyExposureElections($policyId: ID!) {
    policyExposureElections(policyId: $policyId) {
      id
      status
      insured {
        email
      }
    }
  }
`;

export default function usePolicyExposureElections({ policyId, onCompleted = () => {}, onError = () => {} }) {
  const { loading, error, data } = useQuery(GET_POLICY_EXPOSURE_ELECTIONS, {
    variables: { policyId },
    onCompleted,
    onError
  });

  return { exposureElections: data?.policyExposureElections, loading, error };
}
