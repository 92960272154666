import { gql, useQuery } from '@apollo/client';

export const LIST_ELECTION_INSURED_IDS = gql`
  query ElectionInsuredIds($policyId: ID) {
    listElectionInsuredIds(policyId: $policyId) {
      id
      insuredId
    }
  }
`;

export default function useElectionInsuredIds({ policyId }) {
  const { data, loading, error } = useQuery(LIST_ELECTION_INSURED_IDS, { variables: { policyId } });

  return {
    loading,
    error,
    electionInsuredIds: data?.listElectionInsuredIds?.reduce((acc, { id, insuredId }) => {
      return { ...acc, [id]: insuredId };
    }, {})
  };
}
