import React from 'react';
import styled from 'styled-components';

import { StyledFormFeedback } from './FormFeedback';

const StyledInput = styled.input`
  border-radius: 3px;
  padding: 3px 8px;
  margin-left: -10px !important;
  background: white;
  border: 1px solid ${(props) => props.theme.gray400};
  width: calc(100% + 20px);
  box-sizing: border-box;

  &::placeholder {
    color: ${(props) => props.theme.gray500} !important;
  }
  &:read-only {
    background: transparent;
    border: 1px solid transparent;
    &:hover {
      background: transparent !important;
      border: 1px solid transparent;
    }
  }
  &:focus {
    outline: 0;
  }

  &.is-invalid {
    border: 1px solid ${(props) => props.theme.red} !important;

    + ${StyledFormFeedback} {
      display: block !important;
    }
  }
`;

export default React.forwardRef(
  (
    {
      value,
      name,
      id,
      type,
      disabled,
      readOnly,
      invalid,
      className,
      onChange,
      onBlur,
      placeholder,
    },
    ref
  ) => (
    <StyledInput
      value={value}
      name={name}
      id={id}
      type={type}
      disabled={disabled}
      readOnly={readOnly}
      onChange={onChange}
      onBlur={onBlur}
      invalid={invalid}
      placeholder={placeholder}
      className={`${className} ${invalid ? 'is-invalid' : ''}`}
      ref={ref}
    />
  )
);
