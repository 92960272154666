import * as yup from 'yup';
import React from 'react';
import moment from 'moment';
import { Formik, Form } from 'formik';
import { gql, useMutation } from '@apollo/client';

import { useListInsuredClients } from '../hooks/';
import { GET_INSURED_ELECTIONS } from '../../insuredElections/hooks/useInsuredElections';
import { Bold, GraphQLErrors, Render } from '../../../components';
import { Modal, DatePickerField, FieldMessage, ReactSelectField, InputField } from '@oforce/global-components';

const TERMINATE_INSURED_ELECTIONS = gql`
  mutation terminateInsuredExposureElections($insuredId: ID!, $terminationDate: Date!, $clientId: ID!, $note: String) {
    terminateInsuredExposureElections(
      insuredId: $insuredId
      terminationDate: $terminationDate
      clientId: $clientId
      note: $note
    ) {
      id
    }
  }
`;

export default function TerminateInsuredElections({ isOpen, toggle, insuredId }) {
  const [terminateInsuredElections, { loading, error, reset }] = useMutation(TERMINATE_INSURED_ELECTIONS, {
    onCompleted: toggle,
    refetchQueries: [{ query: GET_INSURED_ELECTIONS, variables: { id: insuredId } }],
    onError: () => {}
  });

  const initialValues = {
    terminationDate: moment(),
    insuredId: insuredId,
    client: null,
    note: ''
  };

  const validationSchema = yup.object({
    insuredId: yup.string(),
    terminationDate: yup.date().required('Required'),
    client: yup.object().nullable(),
    note: yup.string().max(255, 'Must be 255 characters or less')
  });

  const {
    clients,
    clientOptions,
    loading: listInsuredClientsLoading,
    error: listInsuredClientsError
  } = useListInsuredClients({ insuredId });

  const hasMultipleClients = clients => {
    if (clients?.length > 1) return true;
    else return false;
  };

  const handleSubmit = ({ insuredId, terminationDate, client, note }) => {
    if (clientOptions?.length === 1) client = clientOptions[0];
    terminateInsuredElections({
      variables: {
        insuredId: insuredId,
        terminationDate: moment(terminationDate).format('YYYY-MM-DD'),
        clientId: client?.value,
        note
      }
    });
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {formik => {
        const isFutureDate = moment(formik?.values?.terminationDate) > moment();

        return (
          <Modal
            isOpen={isOpen}
            toggle={toggle}
            title="Terminate All Elections on a Specific Date"
            loading={loading || listInsuredClientsLoading}
            onSubmit={formik.handleSubmit}
            submitButtonText={isFutureDate ? 'Schedule Termination' : 'Terminate'}
            onOpened={() => {
              formik.handleReset();
              reset();
            }}
            scrollable
            submitButtonProps={{
              color: 'danger',
              disabled: loading,
              loadingText: isFutureDate ? 'Scheduling termination...' : 'Terminating...'
            }}
          >
            <GraphQLErrors error={error || listInsuredClientsError} />
            <Form>
              <Render if={hasMultipleClients(clients)}>
                <ReactSelectField options={clientOptions} label="Client" name="client" />
              </Render>
              <DatePickerField
                required
                fullWidth
                name="terminationDate"
                label="Terminate on"
                format="MM/DD/YYYY"
                allowClear={false}
                inputReadOnly={true}
                messageAfterProps={{ icon: ['far', 'info-circle'] }}
              />
              <FieldMessage icon={['far', 'info-circle']}>
                This will terminate all exposure elections under this insured, and{' '}
                <Bold>it will update termination dates for prior elections</Bold>. If terminating a master election,{' '}
                <Bold> all sub-elections will be terminated </Bold> on the same date. Are you sure you want to continue?
              </FieldMessage>
              <FieldMessage icon={['far', 'info-circle']}>
                If policy is expired and chosen termination date is AFTER the policy's expiration date, the policy
                expiration date will be used as the termination date.
              </FieldMessage>
              <br />
              <InputField type="textarea" label="Note" name="note" placeholder="Enter a note..." />
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
}
