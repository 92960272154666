import React from 'react';
import { FormSection } from '../../../components';

export default function BulkEmailConfirmation({ formik }) {
  const totalDocuments = formik?.values?.documentIds.length;
  const totalInsureds = formik?.values?.insuredIds.length;

  return (
    <FormSection title="Confirm Recipients">
      <div className="pt-5 pb-5 pl-5">
        You are about to send <b>{totalDocuments}</b> documents to <b>{totalInsureds}</b> recipients. <br /> Are you
        sure you would like to send?
      </div>
    </FormSection>
  );
}
