import React from 'react';
import { Formik } from 'formik';
import { Modal } from '@oforce/global-components';

import { GraphQLErrors } from '../../../components';

import { useRemoveFeeForm } from '../hooks';

export default function RemoveFee({ isOpen, toggle, productId, fee }) {
  const { initialValues, validationSchema, handleSubmit, loading, error } = useRemoveFeeForm({
    feeId: fee?.id,
    productId,
    onCompleted: toggle
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {formik => (
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          title="Remove Fee"
          onSubmit={formik.handleSubmit}
          submitButtonDisabled={formik.isSubmitting}
          submitButtonProps={{ color: 'danger', loadingText: 'Deleting...' }}
          submitButtonText={`Remove ${fee?.name}`}
          loading={loading}
          onOpened={() => formik.handleReset()}
        >
          <GraphQLErrors error={error} />
          Are you sure you want to remove {fee?.name}?
        </Modal>
      )}
    </Formik>
  );
}
