import React from 'react';
import { Badge } from '@oforce/global-components';

import { titleCase } from '../../../utils/formatting';

export default function InvoiceStatus({ status = 'unpaid' }) {
  return (
    <Badge size="sm" color={{ unpaid: 'gray600', paid: 'green' }[status]}>
      {titleCase(status)}
    </Badge>
  );
}
