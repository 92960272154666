import * as yup from 'yup';
import { toHeaderCase } from 'js-convert-case';

import { useProrateSettings } from '../../../views/pricing/hooks';
import { guardLimit, guardTerminationLimit } from '../../exposureElections/hooks';
import { billingRuleErrorMessage, isBillingRuleAllowed, SNAPSHOT_CUSTOM_DAY } from '../../product/utils';

export default function useProductFormSteps({ product, formOptions }) {
  const pricingIsExternallyFactored = product?.hasApiFactoredField;

  const {
    PRORATE_SETTINGS: { NO_PRORATE }
  } = useProrateSettings();

  const normalizeCompany = role => {
    const company = product?.companies?.find(company => company.role === role);
    return company ? { label: company.name, value: company.companyId } : null;
  };

  const formSteps = [
    {
      name: 'Product Details',
      initialValues: {
        name: product?.name || '',
        insuranceType: product?.insuranceType
          ? {
              label: product?.insuranceType?.name,
              value: product?.insuranceType?.id
            }
          : null,
        insuranceCarrier: normalizeCompany('INSURANCE_CARRIER') || null,
        retailBroker: normalizeCompany('RETAIL_BROKER') || null,
        externalId: product?.externalId || '',
        enableCustomStartDates: !!product?.enableCustomStartDates ? product?.enableCustomStartDates : false,
        enableCustomTerminationDates: !!product?.enableCustomTerminationDates
          ? product?.enableCustomTerminationDates
          : false,
        startDateBackdatingLimit: guardLimit(product?.startDateBackdatingLimit),
        terminationDateBackdatingLimit: guardTerminationLimit(product?.terminationDateBackdatingLimit),
        apiOnlyExposureUpdates: !!product?.apiOnlyExposureUpdates ? true : false,
        backdate: !!product?.backdate ? true : false,
        ratingZoneSource: product?.ratingZoneSource
          ? {
              label: toHeaderCase(product?.ratingZoneSource),
              value: product?.ratingZoneSource
            }
          : formOptions?.ratingZoneSource?.options[0]
      },
      validationSchema: yup.object().shape({
        name: yup.string().required('Required'),
        insuranceType: yup.object().nullable().required('Required'),
        insuranceCarrier: yup.object().nullable().required('Required'),
        retailBroker: yup.object().nullable(),
        externalId: yup.string(),
        enableCustomStartDates: yup.bool().required('Required'),
        enableCustomTerminationDates: yup.bool().required('Required'),
        startDateBackdatingLimit: yup.number().required('Required'),
        terminationDateBackdatingLimit: yup.number().required('Required'),
        apiOnlyExposureUpdates: yup.bool().required('Required'),
        backdate: yup.bool(),
        ratingZoneSource: yup.object().required('Required')
      })
    },
    {
      name: 'Product Custom Data',
      initialValues: {
        customData: product?.customData || {}
      },
      validationSchema: yup.object().shape({
        customData: yup.object()
      })
    },
    {
      name: 'Product Master Certificate Numbers',
      initialValues: {
        enableMasterCertificateNumbers: !!product?.masterCertificate?.startNumber,
        masterCertificatePrefix: product?.masterCertificate?.prefix || '',
        masterCertificateSuffix: product?.masterCertificate?.suffix || '',
        masterCertificateStartNumber: product?.masterCertificate?.startNumber || '',
        masterCertificateEndNumber: product?.masterCertificate?.endNumber || ''
      },
      validationSchema: yup.object().shape({
        enableMasterCertificateNumbers: yup.bool().required('Required'),
        masterCertificatePrefix: yup.string(),
        masterCertificateSuffix: yup.string(),
        masterCertificateStartNumber: yup.number().when('enableMasterCertificateNumbers', {
          is: true,
          then: schema =>
            schema
              .when('masterCertificateEndNumber', (endNumber, schema) =>
                schema
                  .moreThan(0, 'Start number must be greater than 0')
                  .lessThan(endNumber, 'Start number must be less than end number')
              )
              .required('Required')
        }),
        masterCertificateEndNumber: yup.number().when('enableMasterCertificateNumbers', {
          is: true,
          then: schema =>
            schema
              .when('masterCertificateStartNumber', (startNumber, schema) =>
                schema
                  .moreThan(startNumber, 'End number must be greater than start number')
                  .lessThan(2147483647, 'End number must be less than 2147483647')
              )
              .required('Required')
        })
      })
    },
    {
      name: 'Product Pricing',
      initialValues: {
        pricingRateType:
          formOptions?.pricingRate?.options?.find(x => x.value === product?.pricingRateType) ||
          formOptions?.pricingRate?.options[0],
        enableProrating: !!product?.prorateSetting ? product?.prorateSetting !== NO_PRORATE : false,
        prorateSetting: formOptions?.proratingSettings?.options?.find(x => x.value === product?.prorateSetting) || null,
        prorateAddons: !!product ? product?.prorateAddons : true,
        pricingIsExternallyFactored
      },
      validationSchema: yup.object().shape({
        pricingRateType: yup.object().nullable().required('Required'),
        enableProrating: yup.bool(),
        prorateAddons: yup.bool(),
        prorateSetting: yup.mixed().when('enableProrating', {
          is: true,
          then: yup.object().nullable().required('Required'),
          otherwise: yup.object().nullable()
        })
      })
    },
    {
      name: 'Product Invoicing',
      initialValues: {
        billingRule: !!product?.billingRule
          ? formOptions?.billingRule?.options?.find(option => option.value === product.billingRule)
          : formOptions?.billingRule?.options[0],
        snapshotCustomDay: product?.snapshotCustomDay,
        automateInvoiceGeneration: product?.automateInvoiceGeneration || false,
        invoiceGenerationDay: product?.invoiceGenerationDay || '',
        invoicingSchedule:
          formOptions?.invoicingSchedule?.options?.find(o => o.value === product?.invoicingSchedule) ||
          formOptions?.invoicingSchedule?.options[0],
        invoiceDueDay: product?.invoiceDueDay || '',
        onePremiumRequired: !!product?.onePremiumRequired ? true : false
      },
      validationSchema: yup.object().shape({
        billingRule: yup
          .object()
          .test({
            name: 'billingRuleInvalid',
            test: function test(value) {
              if (isBillingRuleAllowed(this.parent)) return true;
              else return this.createError({ path: this.path, message: billingRuleErrorMessage(this.parent) });
            }
          })
          .required('Billing Rule Required'),
        snapshotCustomDay: yup
          .number()
          .when('billingRule', {
            is: billingRule => billingRule.value === SNAPSHOT_CUSTOM_DAY,
            then: schema => schema.required('Required')
          })
          .min(1, 'Must be greater than 1')
          .max(28, 'Must be less than 28')
          .nullable(),
        automateInvoiceGeneration: yup.bool().required('Required'),
        invoiceGenerationDay: yup.number().min(1, 'Must be greater than 1').max(28, 'Must be less than 28').nullable(),
        invoicingSchedule: yup.object(),
        invoiceDueDay: yup.number().min(1, 'Must be greater than 1').max(28, 'Must be less than 28').nullable(),
        onePremiumRequired: yup.bool()
      })
    }
  ];

  return { formSteps };
}
