import { gql, useQuery } from '@apollo/client';

const ELECTION_FIELDS = gql`
  fragment electionFields on ExposureElection {
    id
    insuredId
    vehicleId
    startDate
    terminationDate
    total
    status
    insured {
      firstName
      lastName
      businessName
    }
  }
`;

const POLICY_FIELDS = gql`
  fragment policyFields on Policy {
    name
    client {
      id
      name
    }
    insuranceType {
      id
      name
      normalizedName
    }
  }
`;

const COMPLIANCE_FIELDS = gql`
  fragment complianceFields on ExposureElectionCompliance {
    inCompliance
    violations {
      id
      field
      reason
      source
    }
    rejected
  }
`;

const GET_VEHICLE_COVERAGES = gql`
  query listVehicleCoverages(
    $vehicleId: ID!
    $inCompliance: Boolean
    $complianceStatus: ComplianceViolationStatus
    $status: ExposureElectionStatus
  ) {
    vehicleCoverages(
      vehicleId: $vehicleId
      inCompliance: $inCompliance
      complianceStatus: $complianceStatus
      status: $status
    ) {
      ...electionFields
      policy {
        ...policyFields
      }
      pricingOption {
        id
        rate
        rateMode
        factoredField
        isPercentageFactor
      }
      compliance {
        ...complianceFields
      }
      vehicle {
        unitNumber
      }
    }
  }
  ${POLICY_FIELDS}
  ${ELECTION_FIELDS}
  ${COMPLIANCE_FIELDS}
`;

export default function useVehicleCoverages({ vehicleId, filters }) {
  const { data, error, loading } = useQuery(GET_VEHICLE_COVERAGES, {
    variables: { vehicleId, ...filters },
    skip: !vehicleId
  });
  const coverages = data?.vehicleCoverages || [];
  return { coverages, error, loading };
}
