import React from 'react';
import { Switch, Route, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import { Loader } from '@oforce/global-components';
import { PageTitle, PageHeader } from '../../components/page-components';
import { TabPane, TabBar, TabLink } from '../../components/tabs';
import { GraphQLErrors } from '../../components';
import { InsuredDetails, InsuredDocuments, InsuredStakeholdersUpdate, InsuredUpdate } from './components';
import { useInsured } from './hooks';
import { InsuredVehicles, InsuredElections } from '../../views';
import { normalizeInsuredFullName } from './utils';
import { CoverageForm } from '../coverages/components';
import { INSURANCE_TYPES } from '../configurationInsuranceTypes';

export default function Insured() {
  const history = useHistory();
  const { insuredId } = useParams();
  const { url, path } = useRouteMatch();
  const { insured, loading, error } = useInsured({ id: insuredId });
  const fullName = normalizeInsuredFullName(insured);

  if (loading) return <Loader />;
  if (error) return <GraphQLErrors error={error} />;

  return (
    <>
      <PageHeader withTabs>
        <PageTitle>{fullName}</PageTitle>
        <TabBar>
          <TabLink exact to={`${url}`} icon={['fas', 'user']}>
            Homepage
          </TabLink>
          <TabLink to={`${url}/vehicles`} icon={['far', 'cars']}>
            Vehicles
          </TabLink>
          <TabLink to={`${url}/elections`} icon={['far', 'ballot-check']}>
            Coverages
          </TabLink>
          <TabLink to={`${url}/documents`} icon={['far', 'file-contract']}>
            Documents
          </TabLink>
        </TabBar>
      </PageHeader>

      <TabPane>
        <Switch>
          <Route exact path={`${path}`}>
            <InsuredDetails insured={insured} />
          </Route>
          <Route path={`${path}/vehicles`}>
            <InsuredVehicles insured={insured} />
          </Route>
          <Route path={`${path}/elections`}>
            <InsuredElections insuredId={insured?.id} />
          </Route>
          <Route path={`${path}/documents`}>
            <InsuredDocuments insuredId={insured?.id} />
          </Route>
          <Route path={`${path}/add-coverage`}>
            <CoverageForm
              title="Create Vehicle Coverage"
              onCancel={() => history.push(url)}
              onCompleted={() => history.push(url)}
              insured={insured}
              policyInsuranceTypes={[INSURANCE_TYPES.VEHICLE]}
            />
          </Route>
          <Route path={`${path}/new-insured-election`}>
            <CoverageForm
              title="Create Insured Coverage"
              onCancel={() => history.push(url)}
              onCompleted={() => history.push(url)}
              insured={insured}
              renderSelectOrCreateVehicleStep={false}
              policyInsuranceTypes={[INSURANCE_TYPES.INSURED]}
            />
          </Route>
          <Route path={`${path}/edit-stakeholders`}>
            <InsuredStakeholdersUpdate
              insured={insured}
              onCancel={() => history.push(url)}
              onCompleted={() => history.push(url)}
            />
          </Route>
          <Route path={`${path}/edit`}>
            <InsuredUpdate insured={insured} onCancel={() => history.push(url)} onCompleted={() => history.push(url)} />
          </Route>
        </Switch>
      </TabPane>
    </>
  );
}
