import React from 'react';
import { FieldLabel, Render } from '@oforce/global-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function VehicleInfo({ vin, vehicleYear, vehicleMake, vehicleModel, vehicleWeight, vehicleType }) {
  return (
    <div className="p-3">
      <div className="text-success mb-1 d-flex fs-sm align-items-center">
        <FontAwesomeIcon icon={['far', 'check']} className="mr-1 mb-1" />
        Vehicle found
      </div>
      <h6 className="mb-1">
        {vehicleYear} {vehicleMake} {vehicleModel}
      </h6>
      <div className="text-muted fs-sm">{vin}</div>
      <Render if={!!vehicleType}>
        <hr />
        <FieldLabel>Vehicle Type</FieldLabel>
        <div>{vehicleType}</div>
      </Render>
      <Render if={!!vehicleWeight}>
        <hr />
        <FieldLabel>Vehicle Weight</FieldLabel>
        <div>{vehicleWeight}</div>
      </Render>
    </div>
  );
}
