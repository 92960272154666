import React from 'react';
import { Link } from 'react-router-dom';
import { CustomDropdownItem } from '@oforce/global-components';
import VehicleInfoCell from '../../components/tables/VehicleInfoCell';
import { CellOwnerContact } from '../exposureElections/components';
import { Td, Tr, ActionDropdown } from '../../components';
import useClaimElection from './useClaimElection';
import PendingElectionBadge from './PendingElectionBadge';
import useArchiveElection from './useArchiveElection';

export default function PendingElectionRow({ pendingElection, refetch }) {
  const { policy, pricingOption, externalId, insured, vehicle, errors, archived, claimed } = pendingElection;
  const hasErrors = errors?.length > 0;
  const { claim, loading } = useClaimElection({ pendingElectionId: pendingElection?.id, onCompleted: () => refetch() });
  const { archive, loading: archiveLoading } = useArchiveElection({
    pendingElectionId: pendingElection?.id,
    onCompleted: () => refetch()
  });

  return (
    <Tr>
      <Td>
        <PendingElectionBadge pendingElection={pendingElection} />
      </Td>
      <Td>{externalId}</Td>
      <Td>
        <Link to={`/policies/${policy?.id}`}>
          <span className="font-weight-500">{policy?.name}</span>
        </Link>
      </Td>
      <Td>{pricingOption?.label}</Td>
      <Td>
        <CellOwnerContact insured={insured} />
      </Td>
      <Td>
        <VehicleInfoCell vehicle={vehicle} />
      </Td>
      <Td>
        {hasErrors ? (
          errors?.map((error, i) => {
            return (
              <span key={`error-str-${i}`}>
                {error}
                <br></br>
              </span>
            );
          })
        ) : (
          <EmptyCell />
        )}
      </Td>
      <Td align="right">
        <ActionDropdown>
          <CustomDropdownItem
            loading={loading}
            disabled={errors.length > 0 || archived || claimed}
            color="success"
            icon={['far', 'check']}
            onClick={claim}
          >
            Claim Election
          </CustomDropdownItem>
          <CustomDropdownItem
            loading={archiveLoading}
            disabled={archived || claimed}
            color="warning"
            icon={['far', 'check']}
            onClick={archive}
          >
            Archive Election
          </CustomDropdownItem>
        </ActionDropdown>
      </Td>
    </Tr>
  );
}

const EmptyCell = ({ text = 'None' }) => <div className="font-weight-300 fs-xs font-italic text-muted">{text}</div>;
