import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useActiveAccount } from '..';

export default function RouteHiddenFromRolesAndTypes({ children, types = 'NONE', roles = 'NONE', ...rest }) {
  const { hideFromTypes, hideFromRoles } = useActiveAccount();

  const hideFromRole = () => {
    if (roles === 'NONE') {
      return false;
    } else {
      return hideFromRoles(roles);
    }
  };

  const hideFromType = () => {
    if (types === 'NONE') {
      return false;
    } else {
      return hideFromTypes(types);
    }
  };

  return hideFromType() || hideFromRole() ? <Redirect to="/access-denied" /> : <Route {...rest}>{children}</Route>;
}
