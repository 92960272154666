import React from 'react';
import { FormGroup } from 'reactstrap';
import { Field, getIn } from 'formik';
import { FieldError, FieldLabel, Input, Loader } from '@oforce/global-components';

import { useVinValidationStep } from './hooks';
import { RenderData, FormSection, GraphQLErrors, Placeholder } from '../../../../components';

import { VehicleInfo } from '.';

export default function VinValidationStep({ formik, formOptions }) {
  const setDisabledVehicleFields = fields => {
    formik?.setFieldValue('VinApiFields', fields);
  };

  const { data, loading, error, handleVinChange } = useVinValidationStep({
    formik,
    formOptions,
    setDisabledVehicleFields
  });

  return (
    <FormSection
      title="VIN Validation"
      subtitle="Enter the VIN number for the vehicle you want to add."
      className="mb-3"
    >
      <FormGroup className="mb-5">
        <FieldLabel required>Vin #</FieldLabel>
        <Field name="vin">
          {({ form, field }) => (
            <>
              <Input
                name={field?.name}
                value={form.values[field.name]}
                disabled={loading}
                readOnly={loading}
                required
                placeholder="Enter a valid VIN number"
                invalid={getIn(form.errors, field.name) && getIn(form.touched, field.name)}
                {...field}
                onChange={handleVinChange}
              />
            </>
          )}
        </Field>
        <FieldError name="vin" />
      </FormGroup>

      <h6 className="mb-1">Vehicle Information</h6>
      <div className="border rounded border-gray-400">
        <RenderData
          data={data?.vehicleInfo}
          loading={loading}
          error={error}
          loadingComponent={<Loader />}
          errorComponent={
            <div className="p-3">
              <GraphQLErrors className="mb-0" error={error} />
            </div>
          }
          placeholderComponent={<Placeholder>No results</Placeholder>}
        >
          {vehicleInfo => {
            return <VehicleInfo {...vehicleInfo} />;
          }}
        </RenderData>
      </div>
    </FormSection>
  );
}
