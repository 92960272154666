import { gql, useQuery } from '@apollo/client';

export const GET_DOCUMENT_FIELD_TOOLTIPS = gql`
  query GetDocumentFieldTooltips {
    documentFieldTooltips {
      doc
      example
      name
    }
  }
`;

export default function useDocumentTooltips() {
  const { loading, error, data } = useQuery(GET_DOCUMENT_FIELD_TOOLTIPS);

  return {
    loading,
    error,
    tooltips: data?.documentFieldTooltips
  };
}
