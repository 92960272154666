import React from 'react';
import { Steps } from 'react-step-builder';
import { MultiStepForm } from '../../../components';
import { useHistory } from 'react-router-dom';
import { useInsuredFormOptions, useInsuredFormSteps, useUpdateInsuredForm } from '../hooks';
import InsuredFields from './InsuredFields';
import { InsuredStakeholdersStep } from '../../coverages/components/steps';

export default function InsuredUpdate({ insured, onCompleted, onCancel }) {
  const history = useHistory();

  const requiredFields = insured?.requiredFields.reduce((acc, field) => {
    return { ...acc, [field]: true };
  }, {});

  const formOptions = useInsuredFormOptions();
  const { formSteps } = useInsuredFormSteps({ requiredFields, insured, formOptions });

  const { handleSubmit, loading, error } = useUpdateInsuredForm({
    formOptions,
    insured,
    onCompleted
  });

  return (
    <MultiStepForm
      formOptions={formOptions}
      formSteps={formSteps}
      formSubmit={handleSubmit}
      formProps={{
        isCreateMode: false
      }}
      title={'Edit Insured'}
      onCancel={() => (onCancel ? onCancel(insured) : history.goBack())}
      submitButtonText={'Save'}
      submitButtonProps={{ loading, loadingText: 'Saving...' }}
      showButtonsOnBottom
      error={error}
    >
      {formProps => (
        <Steps>
          <InsuredFields {...formProps} requiredFields={requiredFields} />
          <InsuredStakeholdersStep {...formProps} requiredInsuredFields={requiredFields} />
        </Steps>
      )}
    </MultiStepForm>
  );
}
