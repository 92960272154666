import { useEditFee, useFeeValidationSchema, useFeeAppliesToOptions } from '.';

import { formatCurrency } from '../../../utils/formatting';
import { useAccountTags } from '../../../views/account/hooks';
import { useActiveAccount } from '../../../shared/activeAccount';
import {
  useFeeRateModes,
  useProductFeeOptions,
  useProductPricingOptions
} from '../../../components/sharedComponents/pricing';
import { convertDecimalToPercentage, convertPercentageToDecimal, convertToFloat } from '../../../utils';

export default function useEditFeeForm({ fee, product, onCompleted, onError }) {
  const { id: activeAccountId } = useActiveAccount();
  const { editFee, loading, error, reset } = useEditFee({
    product,
    feeId: fee?.id,
    onCompleted,
    onError
  });

  const validationSchema = useFeeValidationSchema();
  const { feeRateModeOptions: rateModeOptions } = useFeeRateModes();
  const { feeAppliesToOptions } = useFeeAppliesToOptions();
  const { tagOptions } = useAccountTags({ accountId: activeAccountId });
  const { selectOptions: options } = useProductPricingOptions({ productId: product?.id });
  const { selectOptions: feeOptions } = useProductFeeOptions({ productId: product?.id });

  const rateMode =
    fee?.rateModeType === 'PREMIUM_PLUS'
      ? rateModeOptions?.find(({ value }) => value === 'PREMIUM_PLUS')
      : rateModeOptions?.find(({ value }) => value === fee?.rateMode);

  const initialValues = {
    name: fee?.name || '',
    rate: fee?.rateMode !== 'FLAT' ? convertDecimalToPercentage(fee?.rate) : formatCurrency(fee?.rate),
    rateMode,
    tags: tagOptions.filter(tag => fee?.tags?.includes(tag.value)),
    appliesToAllPricingOptions: feeAppliesToOptions.find(x => x.value === fee?.appliesToAllPricingOptions),
    productPricings: options?.filter(x => fee?.productPricingIds?.includes(x.value)),
    multipliers: feeOptions?.filter(x => fee?.multiplierFeeIds?.includes(x.value)),
    prorated: fee?.prorated
  };

  const handleSubmit = ({
    name,
    rate,
    rateMode,
    appliesToAllPricingOptions,
    productPricings,
    multipliers,
    tags,
    prorated
  }) => {
    const rateModeValue = rateMode.value === 'PREMIUM_PLUS' ? 'PERCENTAGE' : rateMode.value;
    const rateModeType = rateMode.value === 'PREMIUM_PLUS' ? 'PREMIUM_PLUS' : 'PREMIUM';

    editFee({
      name: name,
      rateModeType,
      rate: rateModeValue === 'PERCENTAGE' ? convertPercentageToDecimal(rate) : convertToFloat(rate),
      rateMode: rateModeValue,
      appliesToAllPricingOptions: appliesToAllPricingOptions.value,
      productPricingIds: productPricings?.map(pricing => pricing?.value),
      multiplierFeeIds: multipliers?.map(fee => fee?.value),
      tags: tags?.map(tags => tags?.value),
      prorated
    });
  };

  return { initialValues, handleSubmit, validationSchema, loading, error, reset };
}
