import { useState, useEffect } from 'react';
import { backendNameToLabel } from '../../../utils/formatting';

export const getRequiredFieldLabel = name => {
  // We had to add this clause because it labelled Middle Name as Mdle Name
  if (name === 'middle_name') return backendNameToLabel(name);
  if (name === 'ssn') return 'SSN'
  else {
    const friendlyName = name.replace('id', '').replace('1', '');
    return backendNameToLabel(friendlyName);
  }
};

export default function useNormalizeRequiredFields({ fields, requireStateOfRegistration }) {
  const [alwaysRequiredFields, setAlwaysRequiredFields] = useState([]);

  useEffect(() => {
    const INITIAL_REQUIRED_FIELDS = ['vin'];

    if (requireStateOfRegistration) {
      setAlwaysRequiredFields([...INITIAL_REQUIRED_FIELDS, 'state_of_registration']);
    } else {
      setAlwaysRequiredFields(INITIAL_REQUIRED_FIELDS);
    }
  }, [requireStateOfRegistration, setAlwaysRequiredFields]);

  const normalizedFields =
    fields &&
    Object.keys(fields)?.reduce((acc, name) => {
      return [
        ...acc,
        {
          name,
          label: getRequiredFieldLabel(name),
          required: fields[name],
          alwaysRequired: alwaysRequiredFields.includes(name)
        }
      ];
    }, []);

  const sortedFields = normalizedFields?.sort(a => (a.alwaysRequired ? -1 : 1)) || [];

  return fields ? sortedFields : [];
}
