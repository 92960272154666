import { gql, useMutation } from '@apollo/client';
import { SERVER_SIDE_DATE_FORMAT } from '../../../utils/dates';

const UPDATE_DOCUMENT = gql`
  mutation UpdateDocument($input: UpdateClientDocumentInput) {
    updateClientDocument(input: $input) {
      id
      name
      effectiveDate
      expirationDate
      document {
        id
        url
        type
        name
        filename
      }
      policies {
        id
        name
      }
    }
  }
`;

export default function useClientDocumentUpdateSubmit({
  clientDocumentId,
  onCompleted = () => {},
  onError = () => {}
}) {
  const [update, { loading, error }] = useMutation(UPDATE_DOCUMENT, {
    onCompleted,
    onError
  });

  const formSubmit = values => {
    const input = {
      clientDocumentId,
      name: values?.name,
      type: values?.type?.value,
      policyIds: values?.policies?.map(option => option?.value),
      effectiveDate: values?.effectiveDate.format(SERVER_SIDE_DATE_FORMAT),
      expirationDate: !!values?.expirationDate ? values?.expirationDate.format(SERVER_SIDE_DATE_FORMAT) : null
    };

    update({
      variables: { input }
    });
  };

  return { formSubmit, loading, error };
}
