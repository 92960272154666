import React from 'react';
import { Link } from 'react-router-dom';

import { useToggle } from '@oforce/global-components';
import { CellCoverageDates, InsuranceTypeBadge } from '../../../exposureElections/components';
import { Bold, ConfirmModal, Td, Tr } from '../../../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatPremium } from '../../../../utils/formatting';
import { normalizeInsuredFullName } from '../../../insured/utils';
import {
  electionStatusString,
  statusBadgeColors
} from '../../../exposureElections/components/ExposureElectionStatusBadge';
import { theme } from '@oforce/global-styles';
import useRemoveSubcontractRelationship from '../../hooks/useRemoveSubcontractRelationship';
import SubContractedCoverageFormFields from './SubContractedCoverageFormFields';
const { red } = theme;

export default function SubcontractorsRow({ election, subcontractor }) {
  const [isOpen, toggle] = useToggle();
  const { handleSubmit, loading, error } = useRemoveSubcontractRelationship({ election, toggle });
  const subName = normalizeInsuredFullName(subcontractor.insured);

  return (
    <>
      <Tr key={subcontractor.id}>
        <Td>
          <InsuranceTypeBadge
            insuranceType={subcontractor?.policy?.insuranceType}
            color={statusBadgeColors[electionStatusString(subcontractor)]}
          />
        </Td>
        <Td className="cursor-pointer">
          <Link to={`/insureds/${subcontractor?.insuredId}/elections/${subcontractor.id}`}>
            <div>{normalizeInsuredFullName(subcontractor?.insured)}</div>
          </Link>
        </Td>
        <CellCoverageDates election={subcontractor} />
        <Td>{subcontractor?.pricingOption?.label}</Td>
        <Td>{formatPremium(subcontractor)}</Td>
        <Td>
          <FontAwesomeIcon onClick={toggle} color={red} className="ml-2 cursor-pointer" icon={['far', 'trash-alt']} />
        </Td>
      </Tr>

      {isOpen && (
        <ConfirmModal
          isOpen={isOpen}
          toggle={toggle}
          title="Remove subcontractor"
          onConfirm={() => handleSubmit(subcontractor.id)}
          confirmButtonText="Remove"
          iconColor="danger"
          confirmButtonProps={{ color: 'danger', loading, loadingText: 'Removing...' }}
          error={error}
        >
          Are you sure you want to remove <Bold>{subName}</Bold> as a subcontractor?
          <SubContractedCoverageFormFields
            className="mt-3"
            election={election}
            subcontractor={{ ...subcontractor, value: subcontractor.id }}
            action="REMOVE"
          />
        </ConfirmModal>
      )}
    </>
  );
}
