import React, { useRef } from 'react';

import { Tooltip } from 'reactstrap';
import { Button, useToggle } from '@oforce/global-components';

export default function ButtonWithTooltip({ children, disabled, tooltipText, id, ...rest }) {
  const [showTooltip, toggleTooltip] = useToggle();
  const buttonRef = useRef(null);

  return (
    <>
      {disabled ? (
        <div id={`tooltip-target-${id}`} ref={buttonRef} href="#">
          <Button {...rest} style={{ pointerEvents: 'none' }} disabled={true} ref={buttonRef}>
            {children}
          </Button>
        </div>
      ) : (
        <Button {...rest} id={id}>
          {children}
        </Button>
      )}
      {disabled && (
        <Tooltip
          target={`tooltip-target-${id}`}
          placement="bottom"
          disabled={!disabled}
          isOpen={showTooltip}
          toggle={toggleTooltip}
        >
          {tooltipText}
        </Tooltip>
      )}
    </>
  );
}
