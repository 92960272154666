import moment from 'moment';
import { transformDate } from '../../../../../utils/yupValidation';
import { prepStakeholderAttrs } from '../../../../stakeholder/utils';
import {
  VEHICLE_STAKEHOLDERS_DETAILS,
  INSURED_DETAILS,
  VEHICLE_DETAILS,
  INSURED_STAKEHOLDERS_DETAILS
} from '../stepConstants';
import { hasNoValues } from '../../../../../utils';
// If we didn't include the step in the form, that means we didn't need any NEW data
// therefore, we will pass all the existing data

export function prepareInsuredAttrs(values, stepsToRender, insured) {
  const insuredDetails = prepareInsuredDetails(values, stepsToRender, insured);
  const beneficiaryDetails = prepareInsuredStakeholderDetails(
    values,
    stepsToRender,
    insured?.beneficiary,
    'beneficiary'
  );
  return { ...insuredDetails, beneficiary: beneficiaryDetails };
}

export function prepareElectionAttrs(coverages) {
  return coverages?.map(values => {
    return {
      startDate: values?.startDate === '' ? null : moment(values?.startDate).format('YYYY-MM-DD'),
      policyId: values?.policy?.value,
      addOnIds: values?.addOns?.map(x => x?.value),
      policyPricingOptionId: values?.pricingOption?.value
    };
  });
}

export function prepareVehicleAttrs(values, stepsToRender, vehicle) {
  const vehicleDetails = prepareVehicleDetails(values, stepsToRender, vehicle);
  const certHolderDetails = prepareVehicleStakeholderDetails(values, stepsToRender, vehicle?.certHolder, 'certHolder');
  const lossPayeeDetails = prepareVehicleStakeholderDetails(values, stepsToRender, vehicle?.lossPayee, 'lossPayee');
  const lienholderDetails = prepareVehicleStakeholderDetails(values, stepsToRender, vehicle?.lienholder, 'lienholder');
  const additionalInsuredDetails = prepareVehicleStakeholderDetails(
    values,
    stepsToRender,
    vehicle?.additionalInsured,
    'additionalInsured'
  );

  const vehicleInfo = {
    ...vehicleDetails,
    additionalInsured: hasNoValues(additionalInsuredDetails) ? null : additionalInsuredDetails,
    certHolder: hasNoValues(certHolderDetails) ? null : certHolderDetails,
    lossPayee: hasNoValues(lossPayeeDetails) ? null : lossPayeeDetails,
    lienholder: hasNoValues(lienholderDetails) ? null : lienholderDetails
  };

  const includeVehicleInfo = stepsToRender.includes(VEHICLE_DETAILS) || !!vehicle;

  return includeVehicleInfo && { vehicleInfo };
}
export function prepareInsuredDetails(values, stepsRendered, insured) {
  const stepIncluded = stepsRendered.includes(INSURED_DETAILS);
  return stepIncluded ? insuredDetails(values) : existingInsuredDetails(insured);
}

export function prepareVehicleDetails(values, stepsRendered, vehicle) {
  const stepIncluded = stepsRendered.includes(VEHICLE_DETAILS);
  return stepIncluded ? vehicleDetails(values) : existingVehicleDetails(vehicle);
}

export function prepareVehicleStakeholderDetails(values, stepsRendered, stakeholder, stakeholderType) {
  const stepIncluded = stepsRendered.includes(VEHICLE_STAKEHOLDERS_DETAILS);
  return stepIncluded
    ? vehicleStakeholderDetails(values, stakeholderType)
    : existingVehicleStakeholderDetails(stakeholder);
}

export function prepareInsuredStakeholderDetails(values, stepsRendered, stakeholder, stakeholderType) {
  const stepIncluded = stepsRendered.includes(INSURED_STAKEHOLDERS_DETAILS);
  return stepIncluded
    ? insuredStakeholderDetails(values, stakeholderType)
    : existingInsuredStakeholderDetails(stakeholder);
}

export function prepareInsuredDetails_NoStep(values) {
  return insuredDetails_noOption(values);
}

export function prepareVehicleDetails_NoStep(values) {
  return vehicleDetails_noOption(values);
}

export function prepareVehicleStakeholderDetails_NoStep(values, stakeholderType) {
  return vehicleStakeholderDetails_noOption(values, stakeholderType);
}

export function prepareInsuredStakeholderDetails_NoStep(values, stakeholderType) {
  return insuredStakeholderDetails_noOption(values, stakeholderType);
}

export function prepareElectionDetails_NoStep(values) {
  return prepareElectionDetails(values);
}

const insuredDetails = values => {
  const ssnValue = values?.ssn?.startsWith('X') ? values?.encryptedSsn : values?.ssn?.split('-').join('');
  const valuesDob = values?.dateOfBirth ? moment(values?.dateOfBirth, 'MM/DD/YYYY').format('YYYY-MM-DD') : null;

  return {
    existingInsuredId: values?.existingInsuredId,
    firstName: values?.firstName,
    middleName: values?.middleName,
    lastName: values?.lastName,
    businessName: values?.businessName,
    businessName2: values?.businessName2,
    ssn: ssnValue,
    dateOfBirth: valuesDob,
    email: values?.email,
    mobilePhone: values?.mobilePhone,
    address: values?.address,
    city: values?.city,
    state: values?.state?.value,
    zip: values?.zip,
    country: values?.country?.value,
    driversLicenseState: values?.driversLicenseState?.value,
    driversLicenseNumber: values?.driversLicenseNumber,
    dispatchLocation: values?.dispatchLocation
  };
};

const existingInsuredDetails = insured => {
  const insuredDob = insured?.dateOfBirth ? moment(insured?.dateOfBirth, 'YYYY-MM-DD').format('YYYY-MM-DD') : null;

  return {
    existingInsuredId: insured?.id,
    ssn: insured?.encryptedSsn,
    firstName: insured?.firstName,
    middleName: insured?.middleName,
    lastName: insured?.lastName,
    businessName: insured?.businessName,
    businessName2: insured?.businessName2,
    dateOfBirth: insuredDob,
    email: insured?.email,
    mobilePhone: insured?.mobilePhone,
    address: insured?.address,
    city: insured?.city,
    state: insured?.state,
    zip: insured?.zip,
    country: insured?.country,
    driversLicenseState: insured?.driversLicenseState,
    driversLicenseNumber: insured?.driversLicenseNumber,
    dispatchLocation: insured?.dispatchLocation
  };
};

const insuredDetails_noOption = values => {
  const ssnValue = values?.ssn && values?.ssn?.split('-').join('');
  const valuesDob = values?.dateOfBirth ? moment(values?.dateOfBirth, 'YYYY-MM-DD').format('YYYY-MM-DD') : null;

  return {
    existingInsuredId: values?.existingInsuredId,
    firstName: values?.firstName,
    middleName: values?.middleName,
    lastName: values?.lastName,
    businessName: values?.businessName,
    businessName2: values?.businessName2,
    ssn: ssnValue,
    dateOfBirth: valuesDob,
    email: values?.email,
    mobilePhone: values?.mobilePhone,
    address: values?.address,
    city: values?.city,
    state: values?.state,
    zip: values?.zip,
    country: values?.country,
    driversLicenseState: values?.driversLicenseState,
    driversLicenseNumber: values?.driversLicenseNumber,
    dispatchLocation: values?.dispatchLocation
  };
};

const vehicleDetails = values => {
  return {
    vehicleMake: values?.vehicleMake,
    vehicleYear: (values?.vehicleYear && Number(values?.vehicleYear)) || null,
    vehicleModel: values?.vehicleModel,
    vin: values?.vin,
    value: values?.value || null,
    vehicleWeight: values?.vehicleWeight || null,
    vehicleTypeId: values?.vehicleType?.value,
    garagedState: values?.garagedState?.value,
    stateOfRegistration: values?.stateOfRegistration?.value,
    garagedZip: values?.garagedZip,
    weightClassId: values?.weightClass?.value,
    commercialAutoCoverageTypeId: values?.commercialAutoCoverageType?.value,
    mileage: values?.mileage?.toString(),
    unitNumber: values?.unitNumber?.toString(),
    fleetNumber: values?.fleetNumber?.toString(),
    serialNumber: values?.serialNumber?.toString(),
    isTractor: values?.isTractor,
    notes: values?.notes
  };
};

const vehicleDetails_noOption = values => {
  return {
    vehicleMake: values?.vehicleMake,
    vehicleYear: (values?.vehicleYear && Number(values?.vehicleYear)) || null,
    vehicleModel: values?.vehicleModel,
    vin: values?.vin,
    value: values?.value || null,
    vehicleWeight: values?.vehicleWeight || null,
    vehicleTypeId: values?.vehicleTypeId,
    garagedState: values?.garagedState,
    stateOfRegistration: values?.stateOfRegistration,
    garagedZip: values?.garagedZip,
    weightClassId: values?.weightClassId,
    commercialAutoCoverageTypeId: values?.commercialAutoCoverageTypeId,
    mileage: values?.mileage?.toString(),
    unitNumber: values?.unitNumber?.toString(),
    fleetNumber: values?.fleetNumber?.toString(),
    serialNumber: values?.serialNumber?.toString(),
    isTractor: values?.isTractor,
    notes: values?.notes
  };
};

const existingVehicleDetails = vehicle => {
  return {
    vehicleMake: vehicle?.vehicleMake,
    vehicleYear: vehicle?.vehicleYear && Number(vehicle?.vehicleYear),
    vehicleModel: vehicle?.vehicleModel,
    vin: vehicle?.vin,
    value: vehicle?.value,
    vehicleWeight: vehicle?.vehicleWeight,
    vehicleTypeId: vehicle?.vehicleType?.id,
    garagedState: vehicle?.garagedState,
    stateOfRegistration: vehicle?.stateOfRegistration,
    garagedZip: vehicle?.garagedZip,
    weightClassId: vehicle?.weightClass?.id,
    commercialAutoCoverageTypeId: vehicle?.commercialAutoCoverageType?.id,
    mileage: vehicle?.mileage?.toString(),
    unitNumber: vehicle?.unitNumber?.toString(),
    fleetNumber: vehicle?.fleetNumber?.toString(),
    serialNumber: vehicle?.serialNumber?.toString(),
    isTractor: vehicle?.isTractor,
    notes: vehicle?.notes
  };
};

const vehicleStakeholderDetails = (values, stakeholderType) => {
  const attrs = prepStakeholderAttrs(values, stakeholderType);

  return {
    name: attrs?.name,
    address1: attrs?.address1,
    address2: attrs?.address2,
    city: attrs?.city,
    state: attrs?.state?.value,
    zip: attrs?.zip,
    email: attrs?.email,
    phone: attrs?.phone
  };
};

const vehicleStakeholderDetails_noOption = (values, stakeholderType) => {
  const attrs = prepStakeholderAttrs(values, stakeholderType);

  return {
    name: attrs?.name,
    address1: attrs?.address1,
    address2: attrs?.address2,
    city: attrs?.city,
    state: attrs?.state,
    zip: attrs?.zip,
    email: attrs?.email,
    phone: attrs?.phone
  };
};

const existingInsuredStakeholderDetails = stakeholder => {
  return {
    name: stakeholder?.name,
    relationship: stakeholder?.relationship,
    address1: stakeholder?.address1,
    address2: stakeholder?.address2,
    city: stakeholder?.city,
    state: stakeholder?.state,
    zip: stakeholder?.zip,
    email: stakeholder?.email,
    phone: stakeholder?.phone
  };
};

const insuredStakeholderDetails = (values, stakeholderType) => {
  const attrs = prepStakeholderAttrs(values, stakeholderType);

  return {
    name: attrs?.name,
    relationship: attrs?.relationship,
    address1: attrs?.address1,
    address2: attrs?.address2,
    city: attrs?.city,
    state: attrs?.state?.value,
    zip: attrs?.zip,
    email: attrs?.email,
    phone: attrs?.phone
  };
};

const insuredStakeholderDetails_noOption = (values, stakeholderType) => {
  const attrs = prepStakeholderAttrs(values, stakeholderType);

  return {
    name: attrs?.name,
    relationship: attrs?.relationship,
    address1: attrs?.address1,
    address2: attrs?.address2,
    city: attrs?.city,
    state: attrs?.state,
    zip: attrs?.zip,
    email: attrs?.email,
    phone: attrs?.phone
  };
};

const existingVehicleStakeholderDetails = stakeholder => {
  return {
    name: stakeholder?.name,
    address1: stakeholder?.address1,
    address2: stakeholder?.address2,
    city: stakeholder?.city,
    state: stakeholder?.state,
    zip: stakeholder?.zip,
    email: stakeholder?.email,
    phone: stakeholder?.phone
  };
};

const prepareElectionDetails = values => {
  const startDate = transformDate(values?.startDate);
  const terminationDate = transformDate(values?.terminationDate);

  return {
    startDate: startDate ? startDate : null,
    terminationDate: terminationDate ? terminationDate : null,
    policyId: values?.policyId,
    addOnIds: values?.addOnIds,
    policyPricingOptionId: values?.policyPricingOptionId,
    masterId: values?.masterId,
    contractType: values?.contractType
  };
};
