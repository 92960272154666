import React from 'react';
import { Steps } from 'react-step-builder';
import { MultiStepForm } from '../../components';
import { SelectPoliciesStep, SelectPricingOptionsStep, SyncOptionsInstructions } from './components';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useProductPricings } from '../productPricing/hooks';
import { useProductPolicies, useSyncPricingOptionsFormSteps } from './hooks';
import { useSyncPricingOptionsJobContext } from '../../context';

export default function ProductPricingSync({ productId }) {
  const history = useHistory();
  const { pricings: productPricingOptions } = useProductPricings({ productId });
  const { policies } = useProductPolicies({
    pageSize: 1000,
    filters: { productId, expired: false }
  });
  const allProductPricingOptionIds = productPricingOptions?.map(({ id }) => id);
  const allPolicyIds = policies?.map(({ id }) => id);

  const { startSyncPricingOptionsJob, loading, error } = useSyncPricingOptionsJobContext();

  const { formSteps } = useSyncPricingOptionsFormSteps({ allProductPricingOptionIds, allPolicyIds });

  return (
    <MultiStepForm
      formSteps={formSteps}
      formSubmit={({ productPricingOptionIds, policyIds }) => {
        startSyncPricingOptionsJob(productId, productPricingOptionIds, policyIds);
      }}
      formProps={{ productId }}
      title="Sync Product Pricing Options"
      onCancel={() => history.push(`/products/${productId}/pricing`)}
      submitButtonText="Sync"
      submitButtonProps={{ loading, loadingText: 'Syncing...' }}
      showButtonsOnBottom
      loading={loading}
      error={error}
    >
      {formProps => (
        <Steps>
          <SyncOptionsInstructions />
          <SelectPricingOptionsStep allProductPricingOptionIds={allProductPricingOptionIds} {...formProps} />
          <SelectPoliciesStep allPolicyIds={allPolicyIds} {...formProps} />
        </Steps>
      )}
    </MultiStepForm>
  );
}
