import React from 'react';
import { Steps } from 'react-step-builder';
import { useHistory } from 'react-router-dom';

import { MultiStepForm } from '../../../components';

import { Details, Policies } from '.';
import { useClientDocumentFormOptions, useClientDocumentFormSteps, useClientDocumentFormSubmit } from '../hooks';

export default function UploadDocument({ clientId }) {
  const history = useHistory();

  const { formOptions } = useClientDocumentFormOptions({ clientId });
  const { formSteps } = useClientDocumentFormSteps({ formOptions });
  const { formSubmit, loading, error } = useClientDocumentFormSubmit({
    clientId,
    onCompleted: () => history.push(`/clients/${clientId}/documents`)
  });

  return (
    <MultiStepForm
      formOptions={formOptions}
      formSteps={formSteps}
      formSubmit={formSubmit}
      title={'Upload'}
      onCancel={() => history.push(`/clients/${clientId}/documents`)}
      submitButtonText={'Upload'}
      submitButtonProps={{ loading, loadingText: 'Uploading...' }}
      showButtonsOnBottom
      error={error}
    >
      {formProps => (
        <Steps>
          <Details {...formProps} />
          <Policies {...formProps} />
        </Steps>
      )}
    </MultiStepForm>
  );
}
