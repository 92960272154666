import { snakeCase } from 'snake-case';
import { pascalCase } from 'pascal-case';
import { addLeftWhitespace } from 'graphiql-code-exporter/lib/utils';

const namespace = ({ apiName }) => `Oforce.${apiName}`;

export default function generate({ operationDataList, context }) {
  const body = operationDataList
    .map((x) => x.query)
    .join('\n\n')
    .trim();

  const functions = operationDataList
    .map(
      (x) =>
        `def ${snakeCase(
          x.name
        )}(variables \\\\ %{}), do: execute(@document, "${x.name}", variables)`
    )
    .join('\n\n');

  return `
defmodule ${namespace(context)} do
  use Oforce.${pascalCase(context.apiName)}.GraphQLClient

  @document """
${addLeftWhitespace(body, 4)}
  """

${addLeftWhitespace(functions, 2)}
end
      `;
}
