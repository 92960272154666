import styled from 'styled-components';
import React from 'react';

import { Td, Tr } from '../../../components';
import { DocumentActions } from '.';

export default function ElectionDocumentRow({ electionDocument }) {
  const { document } = electionDocument;

  return (
    <Tr>
      <Td>
        <StyledFilename>{document?.name}</StyledFilename>
      </Td>
      <Td>
        <DocumentActions
          document={electionDocument}
          showVersions={electionDocument.source === 'DOCUMENT_RELATIONSHIP'}
        />
      </Td>
    </Tr>
  );
}

const StyledFilename = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
