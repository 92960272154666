import React from 'react';
import { TrLoader, TrPlaceholder } from '@oforce/global-components';
import { usePolicyPricingOptions } from '../../../components/sharedComponents/pricing';
import { Table } from '../../../components/table-2.0';
import { RenderData, TrGraphQLErrors } from '../../../components';
import { DocumentsTableRow } from '.';

export default function DocumentsTable({ policy, documents, loading, error }) {
  const { policyPricingOptions: pricingOptions } = usePolicyPricingOptions({ policyId: policy?.id });
  const colSpan = 5;

  return (
    <Table>
      <thead>
        <tr>
          <th>Document</th>
          <th>Effective Date</th>
          <th>Expiration Date</th>
          <th>Source</th>
          <th>Applies to</th>
          <th>State-Specific Requirements</th>
          <th />
        </tr>
      </thead>
      <tbody>
        <RenderData
          data={documents}
          loading={loading}
          error={error}
          loadingComponent={<TrLoader colSpan={colSpan} />}
          errorComponent={<TrGraphQLErrors colSpan={colSpan} error={error} />}
          placeholderComponent={
            <TrPlaceholder colSpan={colSpan} className="bg-gray-100 border-bottom border-width-2 border-gray-300">
              No documents
            </TrPlaceholder>
          }
        >
          {documents =>
            documents?.map(doc => (
              <DocumentsTableRow
                key={doc?.id}
                policy={policy}
                pricingOptions={pricingOptions}
                doc={doc}
                disabled={doc?.excludeFromAllPolicyPricingOptions}
              />
            ))
          }
        </RenderData>
      </tbody>
    </Table>
  );
}
