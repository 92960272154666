import { Loader } from '@oforce/global-components';
import { TabPage } from '../../../components';
import { useInsuredElections } from '../hooks';
import { ElectionCards } from './';

export function InsuredCoverages({ insuredId }) {
  const { insuredElections, loading, error } = useInsuredElections({ insuredId });
  const filterByInsuranceType = (elections, type) =>
    elections?.filter(election => election.policy?.insuranceType?.type === type);
  const insuredTypeElections = filterByInsuranceType(insuredElections, 'INSURED');
  const vehicleTypeElections = filterByInsuranceType(insuredElections, 'VEHICLE');

  if (loading && !insuredElections) {
    return <Loader />;
  }

  return (
    <div>
      {insuredTypeElections.length > 0 && (
        <TabPage title="Bodily Coverages">
          <ElectionCards elections={insuredTypeElections} loading={loading} error={error} />
        </TabPage>
      )}

      {vehicleTypeElections.length > 0 && (
        <TabPage title="Vehicle Coverages">
          <ElectionCards elections={vehicleTypeElections} loading={loading} error={error} />
        </TabPage>
      )}
    </div>
  );
}
