import { Modal } from '@oforce/global-components';
import { Formik } from 'formik';
import { GraphQLErrors } from '../../../components';
import { useSendDocumentEmail } from '../hooks';
import { DocumentEmailFields } from '.';
import * as yup from 'yup';
import moment from 'moment';

export default function SendDocumentEmailModal({ show, toggle, document, activeOn, showActiveOn }) {
  const { sendEmail, loading, error } = useSendDocumentEmail({ document, activeOn, onCompleted: toggle });
  const {
    insured: { email },
    document: { name: documentName }
  } = document;

  const validationSchema = yup.object().shape({
    emailAddresses: yup.array().of(
      yup.object().shape({
        value: yup.string().email(({ value }) => `${value} is not a valid email`)
      })
    ),
    subject: yup.string().required('Required'),
    body: yup.string().required('Required'),
    activeOn: yup.date()
  });

  const initialEmailOptions = email ? [{ label: email, value: email }] : [];

  const initialValues = {
    emailAddresses: initialEmailOptions,
    subject: documentName,
    body: 'Download your document below.',
    activeOn: showActiveOn ? moment() : activeOn
  };

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={sendEmail}>
      {formik => (
        <Modal
          isOpen={show}
          toggle={toggle}
          title="Send Document"
          submitButtonText="Send"
          onSubmit={formik.handleSubmit}
          loading={loading}
          submitButtonProps={{ loadingText: 'Sending...' }}
          onOpened={() => {
            formik.resetForm({ values: initialValues });
          }}
        >
          <GraphQLErrors error={error} />
          <DocumentEmailFields initialEmailOptions={initialEmailOptions} showActiveOn={showActiveOn} />
        </Modal>
      )}
    </Formik>
  );
}
