import { gql, useQuery } from '@apollo/client';

export const COUNT_VEHICLES = gql`
  query VehiclesCount($electionStatus: ExposureStatus) {
    getVehiclesCount(status: $electionStatus)
  }
`;

export default function useCountVehicles({ electionStatus = 'ACTIVE' }) {
  const { data, loading, error } = useQuery(COUNT_VEHICLES, { variables: { electionStatus } });

  return {
    vehiclesCount: data?.getVehiclesCount,
    loading,
    error
  };
}
