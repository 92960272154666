import React from 'react';
import { Td } from '@oforce/global-components';
import { toHeaderCase } from 'js-convert-case';

export default function CellCoverageType({ election }) {
  return (
    <Td>
      <span className="font-weight-500">{toHeaderCase(election?.contractType)}</span>
    </Td>
  );
}
