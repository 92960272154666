import { transparentize } from 'polished';
import { theme } from '@oforce/global-styles';
const { blue, red, gray100, gray400, gray500, gray800, gray900 } = theme;

export const useReactSelectStyles = props => {
  const { className, invalid } = props;

  const customTheme = theme => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: blue,
      primary75: transparentize(0.5, blue),
      primary50: transparentize(0.8, blue),
      primary25: transparentize(0.9, blue),
      neutral5: gray100,
      neutral10: gray400,
      neutral20: gray400, // border color
      neutral30: gray500, // border hover color
      neutral40: gray500, // dropdown angle
      neutral50: gray500, // placeholder text
      neutral60: gray800,
      neutral70: gray900,
      neutral80: gray900,
      neutral90: gray900
    }
  });

  const customStyles = {
    container: styles => {
      return {
        ...styles,
        minWidth: '225px'
      };
    },
    menu: styles => ({
      ...styles,
      zIndex: 9999
    }),
    control: styles => ({
      ...styles,
      '&:hover': invalid ? red : styles.borderColor,
      borderColor: invalid ? red : styles.borderColor,
      borderRadius: '3px',
      minHeight: '34px'
    }),
    valueContainer: styles => ({
      ...styles,
      paddingTop: '0px !important',
      paddingBottom: '0px !important'
    }),
    singleValue: styles => ({
      ...styles,
      marginLeft: '1px !important',
      marginRight: '1px !important'
    }),
    dropdownIndicator: styles => ({ ...styles, padding: '6px 8px' }),
    clearIndicator: styles => ({ ...styles, padding: '6px 8px' }),
    multiValueLabel: styles => ({ ...styles, padding: '0px 3px' })
  };

  const customClassName = `${className} text-nowrap`;

  return {
    customTheme,
    customStyles,
    customClassName
  };
};
