import { useToggle } from '@oforce/global-components';
import ApprovalModal from './ApprovalModal';
import RejectModal from './RejectModal';
import { ShowForRoles, ShowForTypes, useActiveAccount } from '../../../../shared/activeAccount';

export default function RejectApproveButtons({ election }) {
  const [approvalModalOpen, toggleApprovalModal] = useToggle(false);
  const [rejectModalOpen, toggleRejectModal] = useToggle(false);

  const {
    ROLES: { ADMIN, COLLABORATOR },
    TYPES: { BROKER, CARRIER }
  } = useActiveAccount();

  return (
    <ShowForTypes types={[CARRIER, BROKER]}>
      <ShowForRoles roles={[COLLABORATOR, ADMIN]}>
        <div className="btn-group">
          <div className="btn btn-link text-success" onClick={toggleApprovalModal}>
            Approve
          </div>
          <button type="button" className="btn btn-dark mr-0 ml-0 pr-0 pl-0" disabled></button>
          <div className="btn btn-link text-danger" onClick={toggleRejectModal}>
            Reject
          </div>
        </div>
        {approvalModalOpen && (
          <ApprovalModal
            isOpen={approvalModalOpen}
            toggle={toggleApprovalModal}
            election={election}
            electionId={election.id}
          ></ApprovalModal>
        )}
        {rejectModalOpen && (
          <RejectModal isOpen={rejectModalOpen} toggle={toggleRejectModal} election={election}></RejectModal>
        )}
      </ShowForRoles>
    </ShowForTypes>
  );
}
