import { gql } from '@apollo/client';

import { useCurrentUser } from '../../currentUser';
import { ACCOUNT_ROLES, ACCOUNT_TYPES } from '..';
import { ROLE_POWERS, TYPE_POWERS } from '../constants';
import { useCallback } from 'react';

export const ACTIVE_ACCOUNT_FIELDS = gql`
  fragment ActiveAccountFields on User {
    # TODO: I think this should just be "activeAccount { id, type, role, name }" instead
    activeAccountId
    activeAccountType
    activeAccountRole
    activeAccountName
    isAdmin
  }
`;

export default function useActiveAccount() {
  const { activeAccount, isAdmin, hasClientCumulioReports } = useCurrentUser();

  const activeAccountRole = activeAccount?.role;
  const activeAccountType = activeAccount?.type;

  const userHasRoles = useCallback(
    (roles = []) => {
      return roles?.includes(activeAccountRole);
    },
    [activeAccountRole]
  );

  const showForRoles = useCallback(
    (roles = []) => {
      if (isAdmin) {
        return true;
      } else {
        return userHasRoles(roles);
      }
    },
    [isAdmin, userHasRoles]
  );

  const hideFromRoles = useCallback(
    (roles = []) => {
      if (isAdmin) {
        return false;
      } else {
        return userHasRoles(roles);
      }
    },
    [isAdmin, userHasRoles]
  );

  const userHasTypes = useCallback(
    (types = []) => {
      return types?.includes(activeAccountType);
    },
    [activeAccountType]
  );

  const showForTypes = useCallback(
    (types = []) => {
      if (isAdmin) {
        return true;
      } else {
        return userHasTypes(types);
      }
    },
    [userHasTypes, isAdmin]
  );

  const hideFromTypes = useCallback(
    (types = []) => {
      if (isAdmin) {
        return false;
      } else {
        return userHasTypes(types);
      }
    },
    [isAdmin, userHasTypes]
  );

  const hideFromRolesAndTypes = useCallback(
    ({ types = [], roles = [] }) => {
      if (isAdmin) {
        return false;
      } else {
        return userHasTypes(types) || userHasRoles(roles);
      }
    },
    [isAdmin, userHasTypes, userHasRoles]
  );

  function roleIsAtLeast(role) {
    return (ROLE_POWERS[activeAccountRole] || 2) >= ROLE_POWERS[role];
  }

  function roleIsBelow(role) {
    return (ROLE_POWERS[activeAccountRole] || 2) < ROLE_POWERS[role];
  }

  function typeIsAtLeast(type) {
    return (TYPE_POWERS[activeAccountType] || 3) >= TYPE_POWERS[type];
  }

  function typeIsBelow(type) {
    return (TYPE_POWERS[activeAccountType] || 3) < TYPE_POWERS[type];
  }

  return {
    ...activeAccount,
    id: activeAccount?.id,
    name: activeAccount?.name,
    role: activeAccount?.role,
    type: activeAccount?.type,
    setActiveAccountId: activeAccount?.setActiveAccountId,
    isAdmin,
    hasClientCumulioReports,

    userHasRoles,
    showForRoles,
    hideFromRoles,
    roleIsAtLeast,
    roleIsBelow,

    userHasTypes,
    showForTypes,
    hideFromTypes,
    typeIsAtLeast,
    typeIsBelow,

    hideFromRolesAndTypes,

    ACCOUNT_TYPES,
    TYPES: ACCOUNT_TYPES,
    ACCOUNT_ROLES,
    ROLES: ACCOUNT_ROLES
  };
}
