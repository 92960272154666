import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { DeleteModal } from '../../../components/modals';
import { FieldMessage } from '@oforce/global-components';
import { LargeIcon } from '../../../components/message-box';
import { GET_CLIENT_DOCUMENTS } from '../hooks/useClientDocuments';

const DELETE_CLIENT_DOCUMENT = gql`
  mutation DeleteDocument($clientDocumentId: ID!) {
    deleteClientDocument(clientDocumentId: $clientDocumentId) {
      id
      name
      clientId
      document {
        id
        url
        type
        filename
      }
      policies {
        id
        name
      }
    }
  }
`;

export default function DeleteDocument({ doc, isOpen, toggle }) {
  const [deleteDoc, { loading, error }] = useMutation(DELETE_CLIENT_DOCUMENT, {
    onCompleted: toggle,
    onError: () => {},
    refetchQueries: [{ query: GET_CLIENT_DOCUMENTS, variables: { id: doc?.clientId } }]
  });

  return (
    <DeleteModal
      isOpen={isOpen}
      toggle={toggle}
      entityType="Client Document"
      entityName={doc?.name}
      deleting={loading}
      error={error}
      submitButtonText={'Delete'}
      cancelButtonText={'Cancel'}
      onSubmit={() => deleteDoc({ variables: { clientDocumentId: doc?.id } })}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }} className="mt-3 mb-3">
        <LargeIcon icon="exclamation-triangle" color="danger" style={{ marginRight: '8px' }} />
        <FieldMessage color="danger">
          <div className="fs-md pt-0">
            This will <b>permanently delete</b> every instance and version of the client document. This cannot be
            undone.
          </div>
        </FieldMessage>
      </div>
    </DeleteModal>
  );
}
