import { gql, useQuery } from '@apollo/client';

export const COUNT_EXPOSURE_ELECTIONS = gql`
  query countExposureElections(
    $policyId: ID
    $electionStatus: ExposureElectionStatus
    $inCompliance: Boolean
    $complianceStatus: ComplianceViolationStatus
  ) {
    countExposureElections(
      policyId: $policyId
      status: $electionStatus
      inCompliance: $inCompliance
      complianceStatus: $complianceStatus
    ) 
  }

`;

export default function useExposureElectionCount({ policyId, status: electionStatus, inCompliance, complianceStatus }) {
  const { data } =
    useQuery(COUNT_EXPOSURE_ELECTIONS, {
      variables: {
        policyId,
        electionStatus,
        inCompliance,
        complianceStatus
      }
    });

  return {
    count: data?.countExposureElections
  };
}
