import React from 'react';
import { Formik } from 'formik';
import { Modal, ReactSelectField } from '@oforce/global-components';
import { GraphQLErrors } from '../../../components';

import { useExposureContractTypeOptions, useExposureUpdateContractType } from '../hooks';

export default function ExposureUpdateContractType({ election, isOpen, toggle }) {
  const { loading, error, reset, initialValues, validationSchema, handleSubmit } = useExposureUpdateContractType(
    election,
    toggle
  );

  const { contractTypeOptions, loading: optionsLoading } = useExposureContractTypeOptions();

  if (optionsLoading) return null;
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {formik => {
        return (
          <Modal
            isOpen={isOpen}
            toggle={toggle}
            title="Change Contract Type"
            loading={loading}
            onSubmit={formik.handleSubmit}
            submitButtonText={'Edit'}
            onOpened={() => {
              formik.handleReset();
              reset();
            }}
            submitButtonProps={{
              color: 'info',
              disabled: loading,
              loadingText: 'Saving...'
            }}
          >
            <GraphQLErrors error={error} />
            <ReactSelectField
              required
              options={contractTypeOptions}
              name="contractType"
              label="Contract Type"
              placeholder="Choose Type..."
            />
          </Modal>
        );
      }}
    </Formik>
  );
}
