import GraphiQL from 'graphiql';
import GraphiQLExplorer from 'graphiql-explorer';
import CodeExporter from 'graphiql-code-exporter';
import React, { useEffect, useRef, useState } from 'react';
import { buildClientSchema, getIntrospectionQuery } from 'graphql';
import snippets from './snippets';

import useToggle from './hooks/useToggle';

export default function Ide({ apiUrl, apiName, accessToken, fetcher, logout }) {
  const [explorer, toggleExplorer] = useToggle(true);
  const [exporter, toggleExporter] = useToggle(false);
  const [query, setQuery] = useState(localStorage.getItem('graphiql:query'));
  const [schema, setSchema] = useState(null);

  const graphiql = useRef(null);

  useEffect(() => {
    fetcher({ query: getIntrospectionQuery() }).then(result => {
      setSchema(buildClientSchema(result.data));
    });
  }, [fetcher]);

  useEffect(() => {
    localStorage.setItem('graphiql:query', query);
  }, [query]);

  return (
    <div className="graphiql-container">
      <GraphiQLExplorer
        schema={schema}
        query={query}
        onEdit={setQuery}
        explorerIsOpen={explorer}
        onToggleExplorer={toggleExplorer}
      />
      <GraphiQL ref={graphiql} fetcher={fetcher} schema={schema} query={query} onEditQuery={setQuery}>
        <GraphiQL.Toolbar>
          <GraphiQL.Button
            onClick={() => graphiql.current.handlePrettifyQuery()}
            label="Prettify"
            title="Prettify Query (Shift-Ctrl-P)"
          />
          <GraphiQL.Button
            onClick={() => graphiql.current.handleToggleHistory()}
            label="History"
            title="Show History"
          />
          <GraphiQL.Button onClick={toggleExplorer} label="Explorer" title="Toggle Explorer" />
          <GraphiQL.Button onClick={toggleExporter} label="Code Exporter" title="Toggle Code Exporter" />
          <GraphiQL.Button title="Back To App" label="Back To App" onClick={() => (window.location = '/')} />
        </GraphiQL.Toolbar>
      </GraphiQL>
      {exporter && (
        <CodeExporter
          hideCodeExporter={toggleExporter}
          snippets={snippets}
          serverUrl={apiUrl}
          query={query}
          context={{ apiName, accessToken }}
          codeMirrorTheme="tomorrow-night-bright"
        />
      )}
    </div>
  );
}
