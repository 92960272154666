import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';

const REMOVE_POLICY_PRICING = gql`
  mutation removePolicyPricingOption($policyPricingOptionId: ID!) {
    removePolicyPricingOption(policyPricingOptionId: $policyPricingOptionId) {
      id
      pricingOptions {
        id
        name
        label
        rateMode
        rate
        percentageMinimum
        percentageMaximum
        coverageParameters {
          id
          cacheId
          name
          type
          value
        }
      }
    }
  }
`;

export default function useRemovePricing({ policyPricingOptionId, onCompleted = () => {}, onError = () => {} }) {
  const [remove, { loading, error }] = useMutation(REMOVE_POLICY_PRICING, {
    variables: { policyPricingOptionId },
    onCompleted,
    onError
  });

  return {
    removePricing: remove,
    loading,
    error
  };
}
