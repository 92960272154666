import React from 'react';
import { Button } from '@oforce/global-components';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';

import { RenderData } from '../../components';
import { TabPage } from '../../components/tabs';
import { BoxContainer, BoxItem, BoxLoader, BoxErrors } from '../../components/box';
import { HideFromRoles, ShowForTypes, useActiveAccount } from '../../shared/activeAccount';

import { CreateUpdate } from './containers';
import { useGetCoverageParameters } from './hooks';
import { CoverageParameterBoxItem } from './components';

export default function CoverageParameters({ productId, policyId }) {
  const { data, loading, error, isProduct } = useGetCoverageParameters({ productId, policyId });

  const {
    TYPES: { CARRIER },
    ROLES: { EXPOSURE_MANAGER }
  } = useActiveAccount();

  const { url, path } = useRouteMatch();
  const history = useHistory();
  const coverageParameters = isProduct ? data?.product?.coverageParameters : data?.policy?.policyCoverageParameters;
  const backUrl = productId ? `/products/${productId}/configuration` : `/policies/${policyId}/configuration`;

  return (
    <Switch>
      <Route exact path={path}>
        <TabPage
          title="Coverage Parameters"
          showBackButton
          onBack={() => history.push(backUrl)}
          buttons={
            <ShowForTypes types={[CARRIER]}>
              <HideFromRoles roles={[EXPOSURE_MANAGER]}>
                <Button
                  color="info"
                  icon={['far', 'plus']}
                  className="ml-2"
                  onClick={() => history.push(`${url}/create`)}
                >
                  Create
                </Button>
              </HideFromRoles>
            </ShowForTypes>
          }
        >
          <BoxContainer>
            <RenderData
              data={coverageParameters}
              loading={loading}
              error={error}
              placeholderComponent={
                <BoxItem size="lg" justifyContent="center">
                  <div className="text-muted">No coverage parameters</div>
                </BoxItem>
              }
              loadingComponent={<BoxLoader />}
              errorComponent={<BoxErrors error={error} />}
            >
              {coverageParameters =>
                coverageParameters?.map(coverageParameter => (
                  <CoverageParameterBoxItem
                    key={coverageParameter?.id}
                    coverageParameter={coverageParameter}
                    productId={productId}
                    isProduct={isProduct}
                    policyId={policyId}
                  />
                ))
              }
            </RenderData>
          </BoxContainer>
        </TabPage>
      </Route>
      <Route path={`${url}/create`}>
        <CreateUpdate isProduct={isProduct} productId={productId} policyId={policyId} />
      </Route>

      <Route path={`${url}/:coverageParameterId/update`}>
        <CreateUpdate
          isProduct={isProduct}
          productId={productId}
          policyId={policyId}
          coverageParameters={coverageParameters}
        />
      </Route>
    </Switch>
  );
}
