import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';

const GET_PRICING_OPTIONS = gql`
  query getPricingOptions($policyId: ID!) {
    policy(id: $policyId) {
      id
      pricingOptions {
        id
        name
        label
      }
    }
  }
`;

export default function usePolicyPricingOptions({ policyId }) {
  const skip = policyId === undefined || policyId === null ? true : false;

  const { data, loading, error } = useQuery(GET_PRICING_OPTIONS, {
    variables: { policyId },
    skip,
    fetchPolicy: skip ? 'cache-only' : 'cache-first'
  });

  const options = data?.policy?.pricingOptions?.map(pricing => ({
    label: pricing?.label,
    value: pricing?.id,
    ...pricing
  }));

  return { pricingOptions: data?.policy?.pricingOptions, options, loading, error };
}
