import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { useCountExposureElections, useExposureElectionCount } from '../../coverages/hooks';
import React from 'react';
import styled from 'styled-components';
import { Card } from '../../../components';
import { toHeaderCase } from 'js-convert-case';

const CardWrapper = styled.div`
  box-shadow: ${props => (props.isActive ? `0px 3px 0px 0px ${props.theme.blue}` : '')};
  border-radius: 10px;
`;

export default function DashboardFilters({ filters }) {
  const { activeInsureds, activeVehicles, openViolations } = useCountExposureElections();
  const { count } = useExposureElectionCount(filters);
  const statusName = filters?.status
    ? toHeaderCase(filters?.status)
    : filters?.complianceStatus === 'REJECTED'
    ? 'Rejected'
    : ' All';

  return (
    <Row className="mb-3">
      <Col>
        <Link to="/coverages/pending-approvals" style={{ textDecoration: 'none' }}>
          <Card
            title={'Pending Approval'}
            name="pending-approval"
            tooltip="Insurance selections that need to be reviewed"
            className="flex-grow-1 flex-shrink-1"
          >
            <h1 className="mb-0">{openViolations}</h1>
          </Card>
        </Link>
      </Col>

      <Col>
        <CardWrapper>
          <Card
            title={`${statusName} Elections`}
            name="active-exposures"
            tooltip={`Number of ${statusName} coverages`}
            className="flex-grow-1 flex-shrink-1"
          >
            <h1 className="mb-0">{count}</h1>
          </Card>
        </CardWrapper>
      </Col>
      <Col>
        <CardWrapper>
          <Card
            title={'Insured Elections'}
            name="insureds"
            tooltip="Number of active bodily coverages"
            className="flex-grow-1 flex-shrink-1"
          >
            <h1 className="mb-0">{activeInsureds}</h1>
          </Card>
        </CardWrapper>
      </Col>

      <Col>
        <CardWrapper>
          <Card
            title={'Vehicle/Unit Elections'}
            name="units"
            tooltip="Number of active vehicle coverages"
            className="flex-grow-1 flex-shrink-1"
          >
            <h1 className="mb-0">{activeVehicles}</h1>
          </Card>
        </CardWrapper>
      </Col>
    </Row>
  );
}
