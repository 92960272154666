import React from 'react';
import styled, { keyframes } from 'styled-components';

const load = keyframes`
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
`;

const StyledSkeletonContent = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: ${props => props.contentWidth};
    background: linear-gradient(to right, transparent 0%, ${props => props.theme.gray100} 50%, transparent 100%);
    animation: ${load} 1s cubic-bezier(0.4, 0.8, 0.1, 0.2) infinite;
  }
`;

export default function SkeletonContent({ contentWidth }) {
  return <StyledSkeletonContent contentWidth={contentWidth} />;
}
