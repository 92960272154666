import React, { useRef } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Modal, InputField } from '@oforce/global-components';

import { GraphQLErrors } from '../../../components';
import { GET_POLICY } from '../../../views/policy/hooks/usePolicy';

const INVITE_ACCOUNT_TO_POLICY = gql`
  mutation InviteAccountToPolicy($accountId: ID, $emailAddress: String, $policyId: ID!, $role: PolicyInvitationRole!) {
    inviteAccountToPolicy(accountId: $accountId, emailAddress: $emailAddress, policyId: $policyId, role: $role) {
      id
      account {
        id
      }
    }
  }
`;

export default function InviteAccountToPolicy({
  accountType,
  invitationRole,
  isOpen,
  toggle,
  policyId,
  onError = () => {}
}) {
  const [inviteAccountToPolicy, { loading, error }] = useMutation(INVITE_ACCOUNT_TO_POLICY, {
    variables: { role: invitationRole, policyId },
    onCompleted: toggle,
    onError,
    refetchQueries: [{ query: GET_POLICY, variables: { id: policyId } }]
  });

  const focusRef = useRef(null);

  return (
    <Formik
      initialValues={{
        emailAddress: ''
      }}
      validationSchema={yup.object({
        emailAddress: yup.string().required('Required')
      })}
      enableReinitialize
      onSubmit={async ({ emailAddress, accountId }) => {
        await inviteAccountToPolicy({ variables: { emailAddress, accountId } });
      }}
    >
      {formik => (
        <Modal
          centered
          onOpened={() => {
            formik.handleReset();
            focusRef?.current?.focus();
          }}
          onSubmit={formik.handleSubmit}
          isOpen={isOpen}
          toggle={toggle}
          title={`Invite ${accountType} Account`}
          submitButtonProps={{ color: 'info', loading }}
          submitButtonText="Send Invite"
        >
          <GraphQLErrors error={error} />
          <Form>
            <InputField name="emailAddress" label="Email" placeholder="Enter email..." innerRef={focusRef} />
          </Form>
        </Modal>
      )}
    </Formik>
  );
}
