import React, { useEffect, useState } from 'react';
import { ReactCreatableSelect, ReactCreatableSelectField } from '@oforce/global-components';

import { useClientSelect, useCreateClient } from '../hooks';

export default function ClientSelect({ isFormik, selectedClientId, onChange, emptyOption, formik, ...rest }) {
  const [selectedId, setSelectedId] = useState(selectedClientId ? selectedClientId : '');

  useEffect(() => {
    setSelectedId(selectedClientId);
  }, [selectedClientId]);

  const { clientOptions = [] } = useClientSelect({ emptyOption });

  const { createClient } = useCreateClient({
    onCompleted: ({ createClient: { id, name } }) =>
      isFormik ? formik?.setFieldValue('client', { value: id, label: name }) : setSelectedId(id)
  });

  const selectedOption = clientOptions.find(option => option.value === selectedId);

  if (isFormik) {
    return (
      <ReactCreatableSelectField
        options={clientOptions}
        name="client"
        label="Client"
        onCreateOption={name => createClient(name)}
        placeholder="Choose or Create Client..."
        {...rest}
      />
    );
  }

  return (
    <ReactCreatableSelect
      options={clientOptions}
      name="client"
      label="Client"
      placeholder="Choose Client..."
      onCreateOption={name => createClient(name)}
      value={selectedOption}
      onChange={onChange}
      {...rest}
    />
  );
}
