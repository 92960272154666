import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@oforce/global-components';
import { useHistory } from 'react-router-dom';

import { Render } from '..';

export default function TabPage({
  size = 'fw',
  centered,
  title,
  subtitle,
  showBackButton = false,
  onBack,
  backButtonText = 'Back',
  buttons,
  showButtonsOnBottom = false,
  showButtonsOnTop = true,
  children,
  className,
  buttonClassName,
  contentClassName,
  titleClassName = 'mb-3',
  marginBottom = 'mb-5'
}) {
  const history = useHistory();
  const onClick = onBack ? onBack : () => history.goBack();

  const width =
    {
      fw: '100%',
      sm: '580px',
      md: '780px',
      lg: '1190px'
    }[size] || 'fw';

  return (
    <div
      className={`d-flex ${centered ? 'justify-content-center' : ''} ${marginBottom && marginBottom} ${
        className && className
      }`}
    >
      <div style={{ width, maxWidth: '100%' }}>
        <Render if={!!title || showBackButton || !!buttons}>
          <div className={`d-flex align-items-end justify-content-between ${titleClassName && titleClassName}`}>
            <span className="d-flex flex-column">
              <h5 className="mb-0">{title}</h5>
              <Render if={!!subtitle}>
                <p className="mt-1 mb-0">{subtitle}</p>
              </Render>
            </span>

            <span className={`d-flex flex-row ${buttonClassName && buttonClassName}`}>
              {showBackButton && (
                <Button color="info" outline onClick={onClick}>
                  {backButtonText}
                </Button>
              )}
              <Render if={!!buttons && showButtonsOnTop}>{buttons}</Render>
            </span>
          </div>
        </Render>
        {children}
        <div className={`d-flex align-items-center justify-content-end mt-3 ${contentClassName && contentClassName}`}>
          <Render if={showButtonsOnBottom}>{buttons}</Render>
        </div>
      </div>
    </div>
  );
}

TabPage.propTypes = {
  size: PropTypes.oneOf(['fw', 'sm', 'md', 'lg'])
};
