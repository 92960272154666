import * as yup from 'yup';
import { Formik } from 'formik';
import React, { useRef } from 'react';

import { useFormOptions } from '../../hooks';
import { CreateUpdateModal } from '../../components/modals';

import { AccountForm } from './components';
import { useAccountTypes, useFormSubmit } from './hooks';
import { useLocation } from 'react-router-dom';

export default function AccountCreateUpdate({ isOpen, toggle, account, onCompleted = () => {}, refetchQueries }) {
  const isCreateMode = !account;
  const location = useLocation();
  const roleFromInvitation = location?.state?.roleFromInvitation;

  const hasNotificationEmailAddresses = account?.notificationEmailAddresses?.length > 0;

  const { accountTypeOptions, loading } = useAccountTypes();
  const formOptions = useFormOptions({ options: [{ name: 'accountType', options: accountTypeOptions, loading }] });

  const initialValues = {
    name: account?.name || '',
    type: roleFromInvitation
      ? formOptions?.accountType?.options.find(o => o.value === roleFromInvitation)
      : formOptions?.accountType?.options?.find(o => o.value === account?.type) || formOptions?.accountType?.options[0],
    notificationEmailAddresses: hasNotificationEmailAddresses ? account?.notificationEmailAddresses : [''],
    allowReportAccessToClient: account?.allowReportAccessToClient ? true : false
  };

  const validationSchema = yup.object({
    name: yup.string().required('Required'),
    type: yup.object({ label: yup.string(), value: yup.string() }).nullable().required('Required'),
    allowReportAccessToClient: yup.boolean()
  });

  const { formSubmit, creating, createError, updating, updateError } = useFormSubmit({
    account,
    onCompleted,
    refetchQueries,
    isCreateMode
  });

  const focusRef = useRef(null);

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={formSubmit} enableReinitialize>
      {formik => (
        <CreateUpdateModal
          isOpen={isOpen}
          toggle={toggle}
          isCreateMode={isCreateMode}
          creating={creating}
          updating={updating}
          entityName="Account"
          onSubmit={formik.handleSubmit}
          submitButtonProps={{ color: 'success', loading: creating ?? updating }}
          onOpened={() => {
            formik.resetForm();
            focusRef?.current?.focus();
          }}
        >
          <AccountForm
            formik={formik}
            formOptions={formOptions}
            isCreateMode={isCreateMode}
            focusRef={focusRef}
            roleFromInvitation={roleFromInvitation}
            error={createError ?? updateError}
          />
        </CreateUpdateModal>
      )}
    </Formik>
  );
}
